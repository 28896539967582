import React, { Component } from 'react';
import { compose } from 'recompose';
import { ValueFormatters } from 'mk-pattern-library-react';

import withTranslate from 'shared/hoc/withTranslate';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import { millionFormatter, dateFormatter } from '../../SharedTableComponents/formatters';
import { numberFormatters } from 'shared/functions/formatters'

import OrderBookTable from '../../shared/table/OrderbookTable';
import Footer from './FullOrderBookFooter';
import SortState, { SORT } from 'shared/components/SortState';
import baseColumnProps from '../../SharedTableComponents/baseColumnProps';
import { tableEllipsis } from 'shared/styles/mixins/ellipsis';
import { typography } from 'shared/styles/variables/theme';
import { StatusIcon } from 'mk-pattern-library-react/components/SvgIcon/SvgIcon';

import {
  cellStyle,
  investorCellStyle,
  headerStyle,
  sortedHeaderStyle,
  centerAlignedHeaderStyle,
  centerAlignedCellStyle,
  rightAlignedCellStyle,
  rightAlignedHeaderStyle
} from '../../shared/table/styles';

const Columns = (props, sort) => {
  const defaultColumnProps = baseColumnProps(sort);

  const columns = [{
    ...defaultColumnProps,
    attrs: {
      role: "rowheader",
    },
    dataField: 'investorLongName',
    text: props.investorText,
    headerClasses: '',
    style: investorCellStyle,
    headerStyle: headerStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 3,
    footerFormatter: (value) =>
      <div style={{position: 'relative'}}>
        Total (m) <span style={{position: 'absolute', right:10}}>
          {ValueFormatters.millionFormatter(props.currentTrancheData.trancheTotalOrderAmount || 0)}
        </span>
      </div>
  }, {
    ...defaultColumnProps,
    dataField: 'softAmount',
    text: props.softText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: rightAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: millionFormatter,
    footerFormatter: () =>
      <Footer
        value={props.currentTrancheData.trancheTotalSoft || 0}
      />
  }, {
    ...defaultColumnProps,
    dataField: 'firmAmount',
    text: props.firmText,
    headerClasses: '',
    style: {
      ...rightAlignedCellStyle,
      borderRight: '1px solid rgb(216, 216, 216)',
    },
    headerStyle: rightAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: millionFormatter,
    footerFormatter: () =>
      <Footer
        value={props.currentTrancheData.trancheTotalFirm || 0}
      />
  }];

  // Order Detail
  if(props.viewType === 'orderDetail') {
    columns.push({
      ...defaultColumnProps,
      dataField: 'spread',
      text: props.spreadText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: rightAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
    }, {
      ...defaultColumnProps,
      dataField: 'limitSecurity',
      text: props.limitSecurityText,
      headerClasses: '',
      style: cellStyle,
      headerStyle: headerStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
    }, {
      ...defaultColumnProps,
      dataField: 'hedge',
      text: props.hedgeText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: (value, data, index) => (
        data['hedge'] === true ? <StatusIcon
          aria-hidden={false}
          isCheckedIndicator
          isDisabled={data['hedge'] === false}
          title={props.hedgeText}
        /> : undefined
      ),
    }, {
      ...defaultColumnProps,
      dataField: 'allocationAmount',
      text: props.allocationAmountText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: headerStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: millionFormatter,
      footerFormatter: (value) =>
        <Footer
          value={props.currentTrancheData.trancheTotalAllocation || 0}
        />
    }, {
      ...defaultColumnProps,
      dataField: 'allocationPercent',
      text: props.allocationPercentText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: headerStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: numberFormatters.oneDecimalPointPercent,
    });
  }

  if(props.viewType === 'investorType') {
    columns.push({
      ...defaultColumnProps,
      dataField: 'investorRegion',
      text: props.regionText,
      headerClasses: '',
      style: {
        ...cellStyle,
        ...tableEllipsis
      },
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
    }, {
      ...defaultColumnProps,
      dataField: 'investorCountry',
      text: props.countryText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
    }, {
      ...defaultColumnProps,
      dataField: 'investorType',
      text: props.investorTypeText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
    });
  }

  if(props.viewType === 'engagementLevel') {
    columns.push({
      ...defaultColumnProps,
      dataField: 'keyInvestor',
      text: props.keyInvestorText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: (value, data, index) => (
        ValueFormatters.booleanFormatter(null, data, 'keyInvestor', { fontSize: typography.sizes['16px'] })
      ),
    }, {
      ...defaultColumnProps,
      dataField: 'historicalInvestor',
      text: props.historicalInvestorText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: (value, data, index) => (
        ValueFormatters.booleanFormatter(null, data, 'historicalInvestor', { fontSize: typography.sizes['16px'] })
      ),
    }, {
      ...defaultColumnProps,
      dataField: 'netRoadshow',
      text: props.onlineRoadshowText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: (value, data, index) => (
        ValueFormatters.booleanFormatter(null, data, 'netRoadshow', { fontSize: typography.sizes['16px'] })
      ),
    }, {
      ...defaultColumnProps,
      dataField: 'physicalRoadshow',
      text: props.physicalRoadshowText,
      headerClasses: '',
      style: centerAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.5,
      formatter: (value, data, index) => (
        ValueFormatters.booleanFormatter(null, data, 'physicalRoadshow', { fontSize: typography.sizes['16px'] })
      ),
    });
  }

  columns.push({
    ...defaultColumnProps,
    dataField: 'externalSourceCreated',
    text: props.createdText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: headerStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
  }, {
    ...defaultColumnProps,
    dataField: 'externalSourceLastModified',
    text: props.modifiedText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: headerStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
  });

  return columns;
}

class FullOrderBookTable extends Component {
  componentWillMount() {
    this.sort = new SortState('investorLongName', SORT.ASCENDING, this.props.columnSortEvent, this.props.onSort);
  }

  render() {
    return (
      <OrderBookTable
        caption={this.props.tableCaption()}
        data={this.props.data}
        keyField="orderId"
        columns={Columns(this.props, this.sort)}
      />
    );
  }
}

export default compose(
  withTranslate((translate, props) => ({
    investorText: translate('orders.investor', 'Investor'),
    softText: translate('orders.soft', 'Soft') + '(m)',
    firmText: translate('orders.firm', 'Firm') + '(m)',
    spreadText: translate('orders.spread', 'Spread'),
    limitSecurityText: translate('orders.limit_security', 'Limit Security'),
    hedgeText: translate('orders.hedge', 'Hedge'),
    allocationAmountText: translate('orders.allocation', 'Allocation'),
    allocationPercentText: translate('orders.allocation_percent', 'Allocation Percent'),
    regionText: translate('orders.region', 'Region'),
    countryText: translate('orders.country', 'Country'),
    investorTypeText: translate('orders.type', 'Type'),
    keyInvestorText: translate('orders.key_investor', 'Key Investor'),
    historicalInvestorText: translate('orders.historical_investor', 'Historical Investor'),
    onlineRoadshowText: translate('orders.online_roadshow', 'Online Roadshow'),
    physicalRoadshowText: translate('orders.physical_roadshow', 'Physical Roadshow'),
    createdText: translate('orders.created', 'Created'),
    modifiedText: translate('orders.modified', 'Modified'),
    tableCaption: () => {
      if (props.viewType === 'orderDetail') {
        return translate('orders.order_details_table_caption', 'Table showing order details');
      }
      if (props.viewType === 'investorType') {
        return translate('orders.investor_type_table_caption', 'Table showing investor types')
      }
      if (props.viewType === 'engagementLevel') {
        return translate('orders.engagement_level_table_caption', 'Table showing enagagement levels');
      }
      return null;
    }
  })),
  withAnalytics((ev, view) => ({
    columnSortEvent: (column, sort) => {
      ev(events.FULL_ORDERBOOK_SORT(column, sort));
    }
  })),
)(FullOrderBookTable);
