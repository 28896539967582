import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import { ValueFormatters, Flag } from 'mk-pattern-library-react';
import TranslationText from 'translations/TranslationText';
import ButtonGroup from 'shared/components/Buttons/ButtonGroup';

const OrderBookEcmHeader = ({
    headerDisplayData,
    dateReceived,
    defaultButtonGroup
}) => {
    return(
        <Row>
            <Col sm={6} style={{paddingTop: 10}}>
                <FeatureHeading className="text-xxlarge text-univ-bd">{headerDisplayData.mainName} <Flag>{`${headerDisplayData.flagStatus}`}</Flag></FeatureHeading>
                {
                    <HeadingDescription top={2}>{headerDisplayData.description}</HeadingDescription>
                }
            </Col>
            <Col sm={6}>
                <div style={{ marginTop: '25px', textAlign: 'right' }}>
                    <p className="text-timestamp" style={{ marginBottom: '10px', lineHeight:1 }}>

                        {ValueFormatters.dateFormatter('dayMonth', headerDisplayData.currentStatusDate) === '' ? '' : ` ${ValueFormatters.dateFormatter('dayMonth', headerDisplayData.currentStatusDate)}`}

                        <TranslationText
                            dictionaryKey={"common.data_as_of_<date_time>"}
                            replace={[
                                ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', dateReceived)]
                            ]}
                            defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', dateReceived)}`}
                        />
                    </p>
                    <div style={{ marginBottom:15, marginTop:0, position: 'relative', bottom: '-3px' }} className="text-large">
                        <ButtonGroup
                            size="small"
                            buttons={defaultButtonGroup}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
};

export default OrderBookEcmHeader;