import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';
import provideTranslationStringForTitle from '../shared/provideTranslationStringForTitle';
import Table from 'shared/components/Table';
import { numberFormatters } from 'shared/functions/formatters';

import  {
  firstHeaderAlignedCenterStyle,
  otherRowAlignedCenter,
  otherRowAlignedLeft
} from 'shared/components/Table';

const TargetSizeContainer = styled.div`
  margin: 10px 0;
`;

const transformData = (data, props) => {
  const series = data.series;
  const values = series.filter(item => item.seriesType === "Plot");

  const transformedData = values.map((row, index) => ({
    "Title": values[index].seriesTitle,
    ...data.categoryAxis.categoryLabel.reduce((acc, curr, i) => ({
      ...acc,
      [i]: values[index].data[i].value
    }), {})
  }))

  const minPlot = series.filter(item => item.seriesTitle === "Demand Min")[0];
  const maxPlot = series.filter(item => item.seriesTitle === "Demand Max")[0];

  if (minPlot && maxPlot) {
    transformedData.push({
      'Title': props.demandUncertaintyText,
      ...data.categoryAxis.categoryLabel.reduce((acc, curr, i) => ({
        ...acc,
        [i]: maxPlot.data[i].value - minPlot.data[i].value,
      }), {})
    })
  }

  return transformedData;
}

const Columns = (props, title) => {
  const columns = [{
    dataField: 'Title',
    text: title,
    columnFlex: 2,
    headerStyle: {
      ...firstHeaderAlignedCenterStyle,
      width: 200,
    },
    style: {
      ...otherRowAlignedLeft,
      width: 200,
    },
    attrs: {
      role: 'rowheader',
    },
  }]

  columns.push(...props.series.categoryAxis.categoryLabel.map((column, i) => ({
    dataField: i,
    text: column,
    columnFlex: 1,
    style: {
      ...otherRowAlignedCenter,
    },
    headerStyle: firstHeaderAlignedCenterStyle,
    formatter: numberFormatters.oneDecimalPoint,
  })));

  return columns;
};

const PriceDemandTable = props => {
  const trancheCurrency = props.series.series.find(s => s.seriesTitle === 'Tranche Currency').data.map(d => d.value)[0];
  const demandUnit = props.series.series.find(s => s.seriesTitle === 'Demand Unit').data.map(d => d.value)[0];
  const targetSize = props.series.series.find(s => s.seriesTitle === 'Target Size').data.map(d => d.value)[0];

  const title = provideTranslationStringForTitle(props.series.valueAxis.valueTitle, props.language);

  return (
    <div>
      {
        !!targetSize && (
          <TargetSizeContainer>
            {props.tableTitle(targetSize, trancheCurrency, demandUnit)}
          </TargetSizeContainer>
        )
      }

      <Table
        classes='fixed standard'
        data={transformData(props.series, props)}
        bordered={false}
        keyField={'Title'}
        columns={Columns(props, title)}
        caption={props.tableCaption}
      />
    </div>
  )
}

export default compose(
  withTranslate((translate, props) => ({
    tableCaption: translate('', `Price demand data for ${props.series.pageTitle}`),
    tableTitle: (targetSize, currency, unit) => translate(
      'investor_feedback.target_size_text',
      `Tranche Target Size ${targetSize || ''}${unit || ''} ${currency || ''}`,
      [
        ['<target_size>', targetSize  || ''],
        ['<unit>', unit || ''],
        ['<currency>', currency || '']
      ]
    ),
    demandUncertaintyText: translate('', 'Demand Uncertainty'),
  }))
)(PriceDemandTable);
