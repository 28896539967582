import { lifecycle, compose } from 'recompose';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

//
// Accessibility solution
//
const EnhancedRespEllipsis = compose(
    lifecycle({
        componentDidMount() {
            const elements = Array.from(document.querySelectorAll("body > div.LinesEllipsis-canvas"));
            if (elements.length) {
                elements.forEach(element => {
                    if (element) {
                        element.style.visibility = 'hidden';
                        element.setAttribute('aria-hidden', true);
                    }
                })
            }
        }
    }),
    responsiveHOC(),
)(LinesEllipsis);

export default EnhancedRespEllipsis;