export const SORT = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
}

export default class SortState {
  constructor(defaultColumn, defaultDirection, logEventFn, dispatchAction) {
    this.column = defaultColumn;
    this.direction = defaultDirection;
    this.logEventFn = logEventFn;
    this.dispatchAction = dispatchAction;
  }

  set = (column, direction) => {
    this.column = column;
    this.direction = direction;

    this.dispatchAction && this.dispatchAction(this.column, this.direction)

    this.logEventFn && this.logEventFn(column, direction);
  }

  setFromHeader = (column, order) => {
    this.column = column;
    this.direction = order === 'asc' ? SORT.ASCENDING : SORT.DESCENDING;
  }

  getAriaLabel = (column) => ({
    'aria-sort': this.column === column.dataField ? this.direction : 'none',
  })

  getHeaderAttributes = (column) => ({
    'aria-sort': this.column === column.dataField ? this.direction : 'none',
    'data-field': column.dataField,
  })
}
