import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from "styled-components";
import { typography } from "shared/styles/variables/theme";
import { Icon } from 'mk-pattern-library-react';
import { leftAlignedHeaderStyle, leftAlignedCellStyle, centerAlignedCellStyle } from 'OrderBook/shared/table/styles';
import Table from 'OrderBook/dcm/TableContainer/Table';
import TableHead from 'OrderBook/dcm/TableContainer/TableHead';
import TableBody from 'OrderBook/dcm/TableContainer/TableBody';
import TableRow from 'OrderBook/dcm/TableContainer/TableRow';
import TableHeaderCell from 'OrderBook/dcm/TableContainer/TableHeaderCell';
import TableColumnCell from 'OrderBook/dcm/TableContainer/TableColumnCell';
import { dateFormatter } from 'OrderBook/SharedTableComponents/formatters';
import DealInformationCommentBoxContainer from '../DealInformation/DealInformationCommentBoxContainer'
import { getIsvCurrentDeal, isIsvExternalUser, getIsvCurrentDealId, selectLatestCommentsDataForCurrentDeal, selectedEcb2ReturnDataForCurrentDeal } from "Isv/selectors";
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import { getLatestCommentsByDealId, setTitleColumnWidth, getECB2ReturnDataForCurrentDeal, handleGetLatestCommentsByCommentsId, getLatestCommentsByCommentId, handleDocumentApproveReject, handleECB2ReturnDataForCurrentDeal, handleGetLatestCommentsByDealIdResponse } from 'Isv/actions/IsvDocumentsAction';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const tableHeader = {
  color: "#333333",
  fontWeight: "600",
  fontSize: `${typography.sizes["12px"]}`,
  height: "50px",
  verticalAlign: "middle",
};

const fontSizeDefault = {
  fontSize: `${typography.sizes["12px"]}`,
  height: `50px`,
};

const deleteCellwidth = {
  width: '2%'
}

const commentsHeader = {
  width: "15%",
};

const uploadCellwidth = {
  width: "8%",
};

const toggleButton = {
  fontSize: '0.75rem',
  textTransform: 'none',
  padding: '5px 10px',
  display: 'block',
  border: '2px solid #626262',
  color: '#000',
  width: '80px',
  outline: 'none',
  backgroundColor: '#ffffff'
}

const approveButton = {
  borderRight: 'unset'
}

const selectedToggleButton = {
  ...toggleButton,
  backgroundColor: '#e4eaec'
}
const iconStyles = {
  width: 22,
  position: 'relative',
  display: 'inline-block',
  color: 'rgb(77, 100, 116)',
  cursor: 'pointer',
}

const IconContainer = styled.span`
  display: inline-block;
  width: 30px;
`

const LinkTableRowColumn = styled.span`
  color: #305a85;
`;

const InformationTitleColumn = styled.span`
  cursor: ${(props) => props.cursor ? 'pointer' : 'default'};
`;

const FileNameText = styled.div`
   color: black;
   font-size:10px;
`;

const UpArrowContainer = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ApproveRejectText = styled.span``

const UpArrowIcon = styled.div`
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid black;
`;

const disabledButton = {
  color: 'rgba(0, 0, 0, 0.26)',
  backgroundColor: 'rgba(0, 0, 0, 0.12)'
}

const ECB2ReturnTable = ({ 
  selectedDealData,
  isIsvExternalUser,
  isIsvCsmUser,
  dealId,
  getLatestCommentsByDealId,
  latestCommentsDataForCurrentDeal,
  setTitleColumnWidth,
  viewDocument,
  handleOpenUploadOverlay,
  getECB2ReturnDataForCurrentDeal,
  selectedEcb2ReturnDataForCurrentDeal,
  isIsvRRTeamUser,
  handleGetLatestCommentsByCommentsId,
  getLatestCommentsByCommentId,
  handleDocumentApproveReject,
  handleECB2ReturnDataForCurrentDeal,
  handleGetLatestCommentsByDealIdResponse,
}) => {
  const [ecb2ReturnTableData, setEcb2ReturnTableData] = useState(null);
  const [isRejectButtonClick, setIsRejectButtonClick] = useState(false);
  const [selectedLastCommentTitle, setSelectedLastCommentTitle] = useState(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);
  const [ecb2ReturnDocumentStatus, setEcb2ReturnDocumentStatus] = useState('');
  const [selectedDealEcb2ReturnDocumentData, setSelectedDealEcb2ReturnDocumentData] = useState(null);
  const [selectedLastCommentId, setSelectedLastCommentId] = useState(null);
  const [isRRApproved, setIsRRApproved] = useState(null)

  const titleTableColumnWidth = useRef()
  
  useEffect(() => {
    handleECB2ReturnDataForCurrentDeal(null);
    getECB2ReturnDataForCurrentDeal(dealId);
  },[])

  useEffect(() => {
    handleGetLatestCommentsByDealIdResponse(null);
    getLatestCommentsByDealId(dealId,  isIsvExternalUser ? true : null, true);
  }, [isIsvExternalUser])
  

  useEffect(() => {
    let clientDocument = selectedEcb2ReturnDataForCurrentDeal && selectedEcb2ReturnDataForCurrentDeal.clientDocumentFile.length > 0 && selectedEcb2ReturnDataForCurrentDeal.clientDocumentFile;
    let dealDocumentMapping = selectedEcb2ReturnDataForCurrentDeal && selectedEcb2ReturnDataForCurrentDeal.dealDocumentMapping.length > 0 && selectedEcb2ReturnDataForCurrentDeal.dealDocumentMapping;
    let mergedData = clientDocument && dealDocumentMapping && clientDocument.map((x) => { return {...dealDocumentMapping[0],...x}})
    
    if (mergedData && mergedData.length) {
      setEcb2ReturnTableData(mergedData.reverse());
    }

    if (titleTableColumnWidth) {
      const titleColumnWidth = titleTableColumnWidth.current.childNodes[0].children[0].childNodes[0].childNodes[0].offsetWidth;
      setTitleColumnWidth(titleColumnWidth);
    }
  }, [selectedEcb2ReturnDataForCurrentDeal])

  const viewSelectedDocument = (clientDocument, adminDocument) => {
    const clientDocFile = clientDocument.uniqueId ? clientDocument.fileName : adminDocument.fileName;
    viewDocument(clientDocument.uniqueId && clientDocument.clientFileId, clientDocument.title, clientDocFile, !clientDocument.uniqueId && adminDocument.documentId);
  }

  const handleDocumentStatusChange = (e, newStatus, index, dealEcb2ReturnDocumentData) => {
    setEcb2ReturnDocumentStatus(newStatus)
    setSelectedDocumentIndex(index);
    setSelectedDealEcb2ReturnDocumentData(dealEcb2ReturnDocumentData);
    setSelectedLastCommentId(dealEcb2ReturnDocumentData.lastCommentId)
    setSelectedLastCommentTitle(dealEcb2ReturnDocumentData.lastCommentTitle);

    setIsRRApproved(dealEcb2ReturnDocumentData.isRrApproved);
    if (newStatus === "approve") {
        const postData = getApproveRejectPostData(dealEcb2ReturnDocumentData);
        handleDocumentApproveReject(postData);
    } else {
        if((isIsvRRTeamUser && dealEcb2ReturnDocumentData.isRrApproved !== false)) {
          setIsRejectButtonClick(true);
          handleGetLatestCommentsByCommentsId(null);
          dealEcb2ReturnDocumentData.lastCommentId && getLatestCommentsByCommentId(dealEcb2ReturnDocumentData.lastCommentId, isIsvExternalUser ? true : null, true);
        }
    }
  };

  const getApproveRejectPostData = (dealEcb2ReturnDocumentData) => {
    const postData = {
      clientFileId: dealEcb2ReturnDocumentData.clientFileId,
      isRrApproved: dealEcb2ReturnDocumentData.status,
      dealId: selectedDealData.dealId,
      fromEcb2Return: true,
      ecbReturnStatus: dealEcb2ReturnDocumentData.status,
    }
    return postData
  }

  const onDialogClose = (comment) => {
    setIsRejectButtonClick(false);
    if(!comment && (isRRApproved && isIsvRRTeamUser)) {
      const postData = {
        clientFileId: selectedDealEcb2ReturnDocumentData.clientFileId,
        isRrApproved: null,
        fromEcb2Return: true,
        dealId: selectedDealData.dealId,
        ecbReturnStatus: selectedDealEcb2ReturnDocumentData.ecbReturnStatus,
      }
      handleDocumentApproveReject(postData);
    }
  }

  const handleRejectCommentPosted = () => { 
    const postData = getApproveRejectPostData(selectedDealEcb2ReturnDocumentData);
    handleDocumentApproveReject(postData);
  }
  
  return (
    <div className="react-bootstrap-table" style={{ maxHeight:`calc(100vh - 280px)`, overflow:"auto"}} ref={titleTableColumnWidth}>
      <Table className="Table standard-summary-table">
        <TableHead style={{ width: `100%`, zIndex:999, position:"sticky", top:0 }}>
          <TableRow>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader,
              }}
            >
              Title
            </TableHeaderCell>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader,
              }}
            >
              Comments
            </TableHeaderCell>
            {isIsvRRTeamUser &&
              <TableHeaderCell
                style={{
                  ...leftAlignedHeaderStyle,
                  ...tableHeader,
                  ...uploadCellwidth
                }}
              >
                Approve / Reject
              </TableHeaderCell>
            }
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...uploadCellwidth,
              }}
            >
              Upload Date
            </TableHeaderCell>
            {isIsvExternalUser && (
              <TableHeaderCell
                style={{
                  ...centerAlignedCellStyle,
                  ...tableHeader,
                  ...deleteCellwidth,
                }}
              >
                Upload
              </TableHeaderCell>
            )}
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...uploadCellwidth,
              }}
            >
              Deadline
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {ecb2ReturnTableData &&
            ecb2ReturnTableData.length > 0 &&
            ecb2ReturnTableData.map((doc, index) => {
              const isClientFile = doc && doc.fileName;
              const isDocumentfile = doc.document && doc.document.fileName;
              const lastComment =
              latestCommentsDataForCurrentDeal &&
              latestCommentsDataForCurrentDeal.length > 0 &&
              latestCommentsDataForCurrentDeal.find(
                (item) => item.title === ((doc.title) || (doc.document && doc.document.title)));
                const isRrApproved =
                isIsvRRTeamUser && doc.fileName && doc.isRrApproved
                  ? true
                  : isIsvRRTeamUser && doc && doc.isRrApproved === false
                  ? false
                  : null;

              const isToggleButtonDisabled = doc && doc.isClientDocReviewed === null ;
              const isUploadDisabled = !(doc.ecbReturnStatus === null || (doc.ecbReturnStatus === false && doc.isRrDocReviewed  === true) ||  (doc.ecbReturnStatus === true && doc.isClientDocReviewed === null));
              const isEcb2ReturnApproved = doc && doc.isRrDocReviewed;
              
              return (
                <TableRow className={isEcb2ReturnApproved ? 'approvedTableRow': isEcb2ReturnApproved === false ? 'approvedTableRow rejectedTableRow': 'normalTableRow'}>
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault,
                    }}
                  >
                    <LinkTableRowColumn id={`link-doc`}>
                      <InformationTitleColumn
                        cursor={isDocumentfile || isClientFile}
                        onClick={() => viewSelectedDocument(doc, doc.document)}
                      >
                          {`${doc.title}`}
                        </InformationTitleColumn>
                       {doc.fileName && <FileNameText>{doc.fileName}</FileNameText>}
                    </LinkTableRowColumn>
                  </TableColumnCell>
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault,
                    }}
                  >
                    <DealInformationCommentBoxContainer
                      selectedDealData={selectedDealData}
                      title={
                        isRejectButtonClick
                          ? selectedLastCommentTitle
                          : doc.title ||
                            (doc && doc.document && doc.document.title)
                      }
                      lastComment={lastComment}
                      isAdditionalComment={false}
                      fromEcb2Return={true}
                      isRejectButtonClick={isRejectButtonClick}
                      onCloseDialog={onDialogClose}
                      onRejectCommentPosted={handleRejectCommentPosted}
                      selectedLastCommentId={selectedLastCommentId}
                      commentRow={index}
                    />
                  </TableColumnCell>
                  {isIsvRRTeamUser && (
                    <TableColumnCell
                      style={{
                        ...centerAlignedCellStyle,
                        ...fontSizeDefault,
                      }}
                    >
                      <ToggleButtonGroup
                        value={
                          index === selectedDocumentIndex ? ecb2ReturnDocumentStatus : ""
                        }
                        exclusive
                        onChange={(e, value) =>
                          handleDocumentStatusChange(e, value, index, {
                            clientFileId: doc.clientFileId,
                            status: value === "approve" ? true : false,
                            isRrApproved: doc.isRrApproved,
                            lastCommentId:
                              lastComment && lastComment.commentId
                                ? lastComment.commentId
                                : 0,
                            lastCommentTitle:
                              doc.title ||
                              (lastComment && lastComment.title),
                              ecbReturnStatus: doc.ecbReturnStatus,
                              isRrFinalStatus: doc.isRrFinalStatus
                          })
                        }
                      >
                        <ToggleButton
                          value="approve"
                          style={
                            isToggleButtonDisabled
                              ? {
                                  ...toggleButton,
                                  ...approveButton,
                                  ...disabledButton,
                                }
                              : isRrApproved
                              ? { ...selectedToggleButton, ...approveButton }
                              : { ...toggleButton, ...approveButton }
                          }
                          disabled={isToggleButtonDisabled}
                        >
                          <ApproveRejectText> Approve </ApproveRejectText>
                          {isRrApproved && (
                            <UpArrowContainer>
                              <UpArrowIcon />
                            </UpArrowContainer>
                          )}
                        </ToggleButton>
                        <ToggleButton
                          value="reject"
                          style={
                            isToggleButtonDisabled
                              ? { ...toggleButton, ...disabledButton }
                              : isRrApproved === false
                              ? selectedToggleButton
                              : toggleButton
                          }
                          disabled={isToggleButtonDisabled}
                          disableFocusRipple={true}
                        >
                          <ApproveRejectText> Reject </ApproveRejectText>
                          {isRrApproved === false && (
                            <UpArrowContainer>
                              <UpArrowIcon />
                            </UpArrowContainer>
                          )}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </TableColumnCell>
                  )}
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault,
                    }}
                  >
                    {dateFormatter("dayMonthYearGMTtoISTZone", doc.createdDate)}
                  </TableColumnCell>
                  {
                    isIsvExternalUser &&
                    <TableColumnCell
                      style={{
                        ...centerAlignedCellStyle,
                        ...fontSizeDefault,
                      }}
                    >
                      <IconContainer onClick={() => isUploadDisabled ? null : handleOpenUploadOverlay(true, doc, doc.title, true)}>
                        <Icon
                          style={{
                            ...iconStyles,
                            opacity: isUploadDisabled ? 0.2 : 1,
                            cursor: isUploadDisabled ? 'default' : 'pointer'
                          }}
                          iconName="Upload"
                        />
                      </IconContainer>
                    </TableColumnCell>
                  }
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault,
                    }}
                  >
                    {dateFormatter('localDate', doc.ecbDeadlineDate)}
                  </TableColumnCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default compose(
  connect(
    (state) => ({
      selectedDealData: getIsvCurrentDeal(state),
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvCsmUser: isIsvCsmUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      dealId: getIsvCurrentDealId(state),
      latestCommentsDataForCurrentDeal: selectLatestCommentsDataForCurrentDeal(state),  
      selectedEcb2ReturnDataForCurrentDeal: selectedEcb2ReturnDataForCurrentDeal(state),
    }),
    (dispatch) => ({
      getLatestCommentsByDealId: (dealId, isClientScreen, IsECBReturnVisible) => {
        dispatch(getLatestCommentsByDealId(dealId, isClientScreen, IsECBReturnVisible));
      },
      setTitleColumnWidth: (width) => {
        dispatch(setTitleColumnWidth(width))
      },
      getECB2ReturnDataForCurrentDeal: (dealId) => {
        dispatch(getECB2ReturnDataForCurrentDeal(dealId))
      },
      handleDocumentApproveReject: (data) => {
        dispatch(handleDocumentApproveReject(data));
      },
      getLatestCommentsByCommentId: (commentId, isClientScreen, IsECBReturnVisible) => {
        dispatch(getLatestCommentsByCommentId(commentId, isClientScreen, IsECBReturnVisible));
      },
      handleGetLatestCommentsByCommentsId: (data) => {
        dispatch(handleGetLatestCommentsByCommentsId(data))
      },
      handleECB2ReturnDataForCurrentDeal: (data) => {
        dispatch(handleECB2ReturnDataForCurrentDeal(data))
      },
      handleGetLatestCommentsByDealIdResponse: (data) => {
        dispatch(handleGetLatestCommentsByDealIdResponse(data))
      },
    })
  )
)(ECB2ReturnTable);