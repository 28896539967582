import React, { PureComponent } from 'react';
import { ButtonGroup } from 'react-bootstrap';
// TODO
import { HsbcButton } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

const btnInlineStyles = { width: 'auto', paddingLeft: 20, paddingRight: 20 };

const renderButtonText = (chartPageTitle) => {
    const key = mapThemeTypeOrSentimentToKey(chartPageTitle);

    return key
    ? (
        <TranslationText
            dictionaryKey={mapThemeTypeOrSentimentToKey(chartPageTitle)}
            defaultText={chartPageTitle}
        />
        )
    : chartPageTitle;
}

const buttonGroupInlineStyles = { };

class Buttons extends PureComponent {
    render() {
        return (
            <ButtonGroup style={{...buttonGroupInlineStyles, ...this.props.style}}>
                {
                    this.props.chartNames.map((chartName, index) => (
                        <HsbcButton
                            id={`btn-${chartName.toLowerCase()}`}
                            className={`btn-segment ${this.props.activeIndex === index && 'active'}`}
                            style={btnInlineStyles}
                            onClick={() => this.props.buttonClicked(index, chartName)}
                            aria-pressed={this.props.activeIndex === index}
                        >
                            {
                                renderButtonText(chartName)
                            }
                        </HsbcButton>
                    ))
                }
          </ButtonGroup>
        );
    }
}

export default Buttons;
