import React from 'react';
import { Grid } from 'react-bootstrap';
import IsvNavMain from '../IsvNavMain/IsvNavMain';
import { allowedUiDealFeatures } from '../../../Isv/constants/isvNavLinks';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Scrollable from 'shared/components/Scrollable';
import '../../styles/application.scss';
import { closeSnackBar } from 'Isv/actions/SnackBarAction'
import { getIsvCurrentDeal, selectSnackBarStatus, selectSnackBarMessage } from 'Isv/selectors';

const CONTAINER_STYLE = {
  position: 'absolute',
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
}

const FLUID_STYLE = {
  padding: '0 40px',
  minHeight: 'calc(100% - 100px)',
}

class IsvAppContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: window.innerHeight,
    };

    this.updateHeight = this.updateHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateHeight);
    window.addEventListener("orientationChange", this.updateHeight);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
    window.removeEventListener("orientationChange", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  render() {
    const { props } = this;
    return (
      <Scrollable scrollHeight={this.state.height}>
        <div onClick={props.detectUserInteraction} style={ CONTAINER_STYLE }>
            <IsvNavMain
              logout={props.logout}
              links={allowedUiDealFeatures}
              user={props.user}
              company={props.company}
              project={props.project}
              popoverElement={props.popoverElement}
            />
          <Grid
              role="main"
              fluid={props.fluid}
              className="mydeal-app-content"
              style={FLUID_STYLE}
            >
              {props.children}
            </Grid>
          <Snackbar
            open={props.open}
            message={props.message}
            onClose={props.closeSnackBar}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          />
        </div>
      </Scrollable>
    );
  }
}

export default compose(
  connect(
    state => ({
      isvDealData: getIsvCurrentDeal(state),
      open: selectSnackBarStatus(state),
      message: selectSnackBarMessage(state)
    }),
    (dispatch) => ({
      closeSnackBar: () => {
        dispatch(closeSnackBar())
      }
    })
  )
)(IsvAppContainer);

IsvAppContainer.defaultProps = {
  links: [{ url: '/', title: 'Dashboard' }],
};
