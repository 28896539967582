import { call, put, cancel, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import Types from 'actions/Types';

import { apiFailure } from 'actions/UtilityActions';

export default (api) => {
    function* worker(action) {
        try {
            const { type, params } = action;
            switch (type) {
                /*
                 * We used switch to have future possibility to handle in few ways diff actions for files
                 */
                case Types.API_REQUEST_DOC: {
                    const apiType = api.getDataDownload();

                    const isIe = typeof window.navigator.msSaveOrOpenBlob !== 'undefined';
                    const isGoodBrowser = navigator.userAgent.indexOf('GoodAccess') > -1 ? true : false;

                    if (isGoodBrowser) {
                        window.open(api.baseURL + action.endPoint);
                        break;
                    }

                    const response = yield (call(apiType.req, action.endPoint, params));

                    const blobData = response.data;
                    const fileName = action.documentName.indexOf('.pdf') > -1 ? `${action.documentName}` : `${action.documentName}.pdf`;

                    if (isIe) {
                        // if is IE or mobile - ask user for action - save or open
                        window.navigator.msSaveOrOpenBlob(blobData, fileName);
                    } else {
                        const objectUrl = window.URL.createObjectURL(blobData);
                        window.open(objectUrl);
                        // window.URL.revokeObjectURL(objectUrl);
                    }
                    break;
                }
                default: {
                    /*
                     * Currently we're not handling any other requests for files
                     */
                    break;
                }
            }
        } catch (error) {
            yield put(apiFailure(`bad call4${error}`));
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            Types.API_REQUEST_DOC,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
