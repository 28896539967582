import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { compose } from 'recompose';
// TODO
import { ScrollingNav, Checkbox } from 'mk-pattern-library-react';
import { getIssuerName, selectIsAmended } from 'selectors';

import { featurePageIds } from 'constants/featurePages';
import { ALL_TRANCHES_RECORD } from 'constants/orders';
import TranslationText from 'translations/TranslationText';
import withTranslate from 'shared/hoc/withTranslate';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';
import { HeadingDescription } from 'shared/styles/components/headings';
import {
    includeAmendedOrders,
    resetOrderBookDropDowns,
    filterOrderSummary,
    filterAmendedOrderSummary,
    filterBookEvolutionSummary,
    getCurrentActiveSummaryTab,
    refreshFullOrderBook,
} from 'actions/OrderBookActions';
import OrderBookEcmHeader from 'OrderBook/ecm/OrderBookEcmHeader';
import OrderBookDcmHeader from 'OrderBook/dcm/OrderBookDcmHeader';
import { ALL_TRANCHES, ALL_INVESTORS, DEFAULT_TIME_INTERVAL, ORDER_SUMMARY_TABS } from 'constants/orders';


const OrderbookHeader = ({
    headerData,
    dateReceived,
    dealType,
    dealId,
    fullOrderbook,
    trancheTitles,
    trancheClick,
    trancheActive,
    issuerName,
    orderSummary,
    amendedSummary,
    bookEvolution,
    viewFullOrderBook,
    viewSummary,
    viewAmendedOrderSummary,
    viewBookEvolution,
    stickyToggleFullOrderBook,
    stickyToggleSummary,
    stickyAmendedOrderSummary,
    stickyBookEvolution,
    fullOrderBookEvent,
    orderSummaryEvent,
    amendedOrderEvent,
    bookEvolutionEvent,
    carouselEvent,
    isAmended,
    includeAmendedOrders,
    resetOrderBookDropDowns,
    filterOrderSummary,
    filterAmendedOrderSummary,
    filterBookEvolutionSummary,
    getCurrentActiveSummaryTab,
    refreshFullOrderBook,
}) => {
    let headerDisplayData = {};

    if (dealType === 'ecm') {
        headerDisplayData.mainName          = headerData.dealName;
        headerDisplayData.flagStatus        = headerData.dealFlagStatus;
        headerDisplayData.description       = headerData.dealDescription;
        headerDisplayData.currentStatusDate = headerData.orderbookStatusDetail.currentStatus.date;
    }
    else {
        headerDisplayData.mainName          = headerData.trancheNameShort;
        headerDisplayData.flagStatus        = headerData.trancheFlagStatus;
        headerDisplayData.description       = headerData.trancheDescription;
        headerDisplayData.currentStatusDate = headerData.orderbookStatusDetail.currentStatus.date;
        if(trancheActive && trancheActive.id === ALL_TRANCHES_RECORD.id) {
            headerDisplayData.mainName          = 'Book Evolution';
            headerDisplayData.description       =  <HeadingDescription top={2} className="text-normal"> 
                <Checkbox
                    label={
                        <TranslationText
                            dictionaryKey="orders.isAmendedOrders"
                            defaultText="Include Amended Orders"
                        />}
                    onCheckedChange={includeAmendedOrders}
                    checked={isAmended}
                />
            </HeadingDescription>;
        }
    }

    includeAmendedOrders = checked => {
        includeAmendedOrders(checked);
    }
    const defaultButtonGroup = [
        {
            id:'btn-obh-f',
            active: fullOrderbook,
            text: stickyToggleFullOrderBook,
            label: viewFullOrderBook,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_FULL_PAGE);
                fullOrderBookEvent();
            },
        },
        {
            id:'btn-obh-s',
            active: !fullOrderbook,
            text: stickyToggleSummary,
            label: viewSummary,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_SUMMARY_PAGE);
                orderSummaryEvent();
            },
        }
    ];

    const allTranchesButtonGroup = [
        {
            id:'btn-obh-f-at',
            active: fullOrderbook,
            text: stickyToggleFullOrderBook,
            label: viewFullOrderBook,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_FULL_PAGE);
                fullOrderBookEvent();
                getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.FULLORDERBOOK);
                refreshFullOrderBook(dealId)
            },
        },
        {
            id:'btn-obh-s-at',
            active: orderSummary,
            text: stickyToggleSummary,
            label: viewSummary,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_SUMMARY_PAGE);
                orderSummaryEvent();
                getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.ORDERBOOKSUMMARY);
                resetOrderBookDropDowns();
                filterOrderSummary(dealId, DEFAULT_TIME_INTERVAL.key, ALL_INVESTORS.key, ALL_TRANCHES.key)
            },
        },
        {
            id: 'btn-obh-aos',
            active: amendedSummary,
            text: stickyAmendedOrderSummary,
            label: viewAmendedOrderSummary,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_AMENDED_PAGE);
                amendedOrderEvent();
                getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.AMENDEDORDERSUMMARY);
                resetOrderBookDropDowns();
                filterAmendedOrderSummary(dealId, ALL_INVESTORS.key, ALL_TRANCHES.key);
            },
        },
        {
            id:'btn-obh-be',
            active: bookEvolution,
            text: stickyBookEvolution,
            label: viewBookEvolution,
            action: () => {
                browserHistory.push(featurePageIds.ORDERBOOK_EVOLUTION_PAGE);
                bookEvolutionEvent();
                getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.ORDEREVOLUTION);
                resetOrderBookDropDowns();
            },
        }
    ];

    useEffect(
        () => {
            if (dealType === 'dcm') {
                if ((trancheActive.id !== ALL_TRANCHES_RECORD.id) && (amendedSummary || bookEvolution)) {
                    browserHistory.push(featurePageIds.ORDERBOOK_SUMMARY_PAGE);
                }
            }
        },
        [trancheActive, dealType]
    )

    return (
        <div style={{ backgroundColor: '#fff', paddingTop: '1px' }}>
            {trancheTitles && (
                <Row>
                    <Col sm={12} style={{ marginTop: '18px' }}>
                        <div >
                            <ScrollingNav
                                className="btn-scrolling-nav"
                                titles={
                                    trancheTitles.length >= 1 ?
                                        [
                                            ...trancheTitles,
                                            ALL_TRANCHES_RECORD
                                        ] : trancheTitles
                                }
                                btnWidth='auto'
                                handleClick={(...a) => {
                                    trancheClick(...a);
                                    carouselEvent();
                                }}
                                active={trancheActive}
                                issuerName={issuerName}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {
                dealType === 'ecm' &&
                <OrderBookEcmHeader
                    defaultButtonGroup={defaultButtonGroup}
                    headerDisplayData={headerDisplayData}
                />
            }
            {
                dealType === 'dcm' &&
                <OrderBookDcmHeader
                    defaultButtonGroup={defaultButtonGroup}
                    allTranchesButtonGroup={allTranchesButtonGroup}
                    headerDisplayData={headerDisplayData}
                    dateReceived={dateReceived}
                    trancheActive={trancheActive}
                    fullOrderbook={fullOrderbook}
                />
            }
            {!fullOrderbook && (<Row>
                <Col sm={12}>
                    <div style={{ borderBottom: '1px solid #C9C9C9' }}></div>
                </Col>
            </Row>)}
        </div>
    );
};

export default compose(
    withAnalytics(
        (ev) => ({
            carouselEvent: () => ev(events.ORDERS_SUMMARY_CAROUSEL_BUTTON),
            fullOrderBookEvent: () => ev(events.ORDERS_SUMMARY_FULL_ORDERBOOK_BUTTON),
            orderSummaryEvent: () => ev(events.FULL_ORDERBOOK_SUMMARY_BUTTON),
            amendedOrderEvent: () => ev(events.ORDERBOOK_AMENDED_SUMMARY_BOTTON),
            bookEvolutionEvent: () => ev(events.BOOK_EVOLUTION_SUMMARY_BOTTON)
        })
    ),
    withTranslate(
        translate => ({
            viewFullOrderBook: translate('', 'View full order book'),
            viewSummary: translate('', 'View order summary'),
            viewAmendedOrderSummary: translate('', 'View Amended Order Summary'),
            viewBookEvolution: translate('', 'View Book Evolution'),
            stickyToggleFullOrderBook: translate('orders.full_order_book', 'Full Order Book'),
            stickyToggleSummary: translate('orders.order_book_summary', 'Order Book Summary'),
            stickyAmendedOrderSummary: translate('orders.amended_order_summary', 'Amended Order Summary'),
            stickyBookEvolution: translate('orders.order_evolution', 'Order Evolution'),
        })
    ),
    connect(
        state => ({
            issuerName: getIssuerName(state),
            isAmended: selectIsAmended(state),
            dealId: state.utility.dealsData.dealId,
        }),
        dispatch => ({
            includeAmendedOrders: checked => {
                dispatch(includeAmendedOrders(checked));
            },
            resetOrderBookDropDowns: (dealId) => dispatch(resetOrderBookDropDowns(dealId)),
            refreshFullOrderBook: (dealId) => dispatch(refreshFullOrderBook(dealId)),
            filterOrderSummary: (dealId, interval, investorId, trancheId, loader) => dispatch(filterOrderSummary(dealId, investorId, trancheId, interval, loader)),
            filterAmendedOrderSummary: (dealId, investorId, trancheId) => dispatch(filterAmendedOrderSummary(dealId, investorId, trancheId)),
            filterBookEvolutionSummary: (dealId, investorId, trancheId, interval, loader) => dispatch(filterBookEvolutionSummary(dealId, investorId, trancheId, interval, loader)),
            getCurrentActiveSummaryTab: (activeTab) => dispatch(getCurrentActiveSummaryTab(activeTab))
        })
    )
)(OrderbookHeader);
