/*
 * Call In order to get any specific info regarding the deal such as navigation features
 */
const DEAL = 'deal';

const PRICING = '/pricing';

const INVESTORS = '/investors';

const ROADSHOW = '/calendar';

const ORDERBOOK = '/orderbook';

const MY_DOCS = '/documents';

const INVESTOR_FEEDBACK = '/investorFeedback';

const SETTINGS = '/settings';



export const mapDealFeatureToPageTitle = dealFeature => {
    switch (dealFeature) {
        case PRICING: return 'Pricing - HSBC MyDeal';
        case INVESTORS: return 'Investors - HSBC MyDeal';
        case ROADSHOW: return 'Roadshow - HSBC MyDeal';
        case ORDERBOOK: return 'Orderbook - HSBC MyDeal';
        case MY_DOCS: return 'Documents - HSBC MyDeal';
        case INVESTOR_FEEDBACK: return 'Investor Feedback - HSBC MyDeal';
        default: return 'HSBC - MyDeal';
    }
}

/* Note : If visibleInHeroMenu & visibleInMainMenu false from API/adminSide it will be not visible from UI as well
Change values to false only if from API/adminSide enabled feature we want to disable from UI
Change will impact on both ECM/DCM for all deals */
export const allowedUiDealFeatures = [
  {
    id: DEAL,
    url: '/'
  }, {
    id: 'pricing',
    url: PRICING,
    visibleInHeroMenu: true,
    visibleInMainMenu: true
  }, {
    id: "calendar",
    url: ROADSHOW,
    visibleInHeroMenu: true,
    visibleInMainMenu: true
  }, {
    id: "investorFeedback",
    url: INVESTOR_FEEDBACK,
    visibleInHeroMenu: true,
    visibleInMainMenu: true
  }, {
    id: "orders",
    url: ORDERBOOK,
    visibleInHeroMenu: true,
    visibleInMainMenu: true
  }, {
    id: "documents",
    url: MY_DOCS,
    visibleInHeroMenu: true,
    visibleInMainMenu: true
  },
];

export default {
    DEAL,
    PRICING,
    INVESTORS,
    ROADSHOW,
    ORDERBOOK,
    MY_DOCS,
    INVESTOR_FEEDBACK,
    SETTINGS,
};
