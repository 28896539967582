import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from "styled-components";
import { Icon } from 'mk-pattern-library-react';
import { typography } from "shared/styles/variables/theme";
import { leftAlignedHeaderStyle, leftAlignedCellStyle, centerAlignedCellStyle } from 'OrderBook/shared/table/styles';
import Table from 'OrderBook/dcm/TableContainer/Table';
import TableHead from 'OrderBook/dcm/TableContainer/TableHead';
import TableBody from 'OrderBook/dcm/TableContainer/TableBody';
import TableRow from 'OrderBook/dcm/TableContainer/TableRow';
import TableHeaderCell from 'OrderBook/dcm/TableContainer/TableHeaderCell';
import TableColumnCell from 'OrderBook/dcm/TableContainer/TableColumnCell';
import { dateFormatter } from 'OrderBook/SharedTableComponents/formatters';
import { getIsvCurrentDeal, isIsvExternalUser, getIsvCurrentDealId, selectLatestCommentsDataForCurrentDeal } from "Isv/selectors";
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { handleDocumentApproveReject, handleClientDocuments, getLatestCommentsByDealId, getLatestCommentsByCommentId, handleGetLatestCommentsByCommentsId, handleGetLatestCommentsByDealIdResponse, setTitleColumnWidth } from 'Isv/actions/IsvDocumentsAction';
import DealInformationCommentBoxContainer from './DealInformationCommentBoxContainer'
import {
  NEXT_ACTION_OWNER,
  WORKFLOW_STATUS,
  ISV_DOCUMENT_REVIEW_STATUS,
  ISV_DOCUMENT_TYPE,
} from 'Isv/constants/isvDocumentReviewStatus'

const tableHeader = {
  color: '#333333',
  fontWeight: '600',
  fontSize: `${typography.sizes["12px"]}`,
  height: '50px',
  verticalAlign: 'middle'
}

const fontSizeDefault = {
  fontSize: `${typography.sizes["12px"]}`,
  height: `50px`
}

const iconStyles = {
  width: 22,
  position: 'relative',
  display: 'inline-block',
  color: 'rgb(77, 100, 116)',
}

const toggleButton = {
  fontSize: '0.75rem',
  textTransform: 'none',
  padding: '5px 10px',
  display: 'block',
  border: '2px solid #626262',
  color: '#000',
  width: '80px',
  outline: 'none',
  backgroundColor: '#ffffff'
}

const approveButton = {
  borderRight: 'unset'
}

const selectedToggleButton = {
  ...toggleButton,
  backgroundColor: '#e4eaec'
}

const commentsHeader = {
  width: '15%'
}

const uploadCellwidth = {
  width: '8%'
}

const deleteCellwidth = {
  width: '2%'
}

const disabledButton = {
  color: 'rgba(0, 0, 0, 0.26)',
  backgroundColor: 'rgba(0, 0, 0, 0.12)'
}

const commentsColumn = {
  maxWidth: '100px'
}

const LinkTableRowColumn = styled.span`
  color: #305a85;
`;

const IconContainer = styled.span`
  display: inline-block;
  width: 30px;
`

const UpArrowContainer = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const FileNameText = styled.div`
   color: black;
   font-size:10px;
`;

const ApproveRejectText = styled.span``

const UpArrowIcon = styled.div`
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid black;
`

const InformationTitleColumn = styled.span`
  cursor: ${(props) => props.cursor ? 'pointer' : 'default'};
`

const DealInformationTable = ({
  viewDocument,
  handleOpenUploadOverlay,
  selectedDealData,
  isIsvRRTeamUser,
  isIsvExternalUser,
  handleDocumentApproveReject,
  handleDeleteConfirmationDialogue,
  handleClientDocuments,
  isIsvCsmUser,
  dealId,
  getLatestCommentsByDealId,
  latestCommentsDataForCurrentDeal,
  getLatestCommentsByCommentId,
  handleGetLatestCommentsByCommentsId,
  handleGetLatestCommentsByDealIdResponse,
  setTitleColumnWidth,
}) => {

  const [documentStatus, setDocumentStatus] = useState('');
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);
  const [dealInformationTableData, setDealInformationTableData] = useState(null);
  const [isClientSupportingDocument, setClientSupportingDocument] = useState(false)
  const [isRejectButtonClick, setIsRejectButtonClick] = useState(false);
  const [selectedDealDocumentData, setSelectedDealDocumentData] = useState(null);
  const [selectedLastCommentId, setSelectedLastCommentId] = useState(null);
  const [selectedLastCommentTitle, setSelectedLastCommentTitle] = useState(null);
  const [isCSMApproved, setIsCSMApproved] = useState(null)
  const [isRRApproved, setIsRRApproved] = useState(null)
  const titleTableColumnWidth = useRef()

  useEffect(() => {
    handleGetLatestCommentsByDealIdResponse(null)
    getLatestCommentsByDealId(dealId, isIsvExternalUser ? true : null, false);
    let latestTableData = [];
    selectedDealData.clientDocumentFile && selectedDealData.clientDocumentFile.length && selectedDealData.clientDocumentFile.map((item) => item.isClientSupportingDocument && latestTableData.push(item))
    latestTableData.length && setClientSupportingDocument(true)
    latestTableData = selectedDealData && selectedDealData.dealDocumentMapping && (selectedDealData.dealDocumentMapping.filter(doc => doc.document && doc.document.category && doc.document.category.documentCategoryName !== ISV_DOCUMENT_TYPE.ECB_RETURN_DOCUMENT)).concat(latestTableData);
    setDealInformationTableData(latestTableData);
    handleClientDocuments(latestTableData);
    if (titleTableColumnWidth) {
      const titleColumnWidth = titleTableColumnWidth.current.childNodes[0].children[0].childNodes[0].childNodes[0].offsetWidth;
      setTitleColumnWidth(titleColumnWidth);
    }
  }, [selectedDealData && selectedDealData.dealDocumentMapping])

  useEffect(() => {
     handleGetLatestCommentsByDealIdResponse(null);
     getLatestCommentsByDealId(dealId, isIsvExternalUser ? true : null, false);
  },[])

  const viewSelectedDocument = (clientDocument, adminDocument, dealDocumentId) => {
    let clientFieldId = clientDocument && clientDocument.clientFileId? clientDocument.clientFileId:null
    const fileSelected = clientFieldId ? clientDocument : adminDocument
    if(fileSelected.fileName) {
      viewDocument(clientFieldId, fileSelected.title, fileSelected.fileName, dealDocumentId);
      }
  }

  const handleDocumentStatusChange = (e, newStatus, index, dealDocumentData) => {
    setDocumentStatus(newStatus)
    setSelectedDocumentIndex(index);
    setSelectedDealDocumentData(dealDocumentData);
    setSelectedLastCommentId(dealDocumentData.lastCommentId)
    setSelectedLastCommentTitle(dealDocumentData.lastCommentTitle);
    setIsCSMApproved(dealDocumentData.isCsmApproved);
    setIsRRApproved(dealDocumentData.isRrApproved);

    if (newStatus === "approve") {
        const postData = getApproveRejectPostData(dealDocumentData);
        handleDocumentApproveReject(postData);
    } else {
        if((isIsvCsmUser && dealDocumentData.isCsmApproved !== false) || (isIsvRRTeamUser && dealDocumentData.isRrApproved !== false)) {
          setIsRejectButtonClick(true);
          handleGetLatestCommentsByCommentsId(null);
          dealDocumentData.lastCommentId && getLatestCommentsByCommentId(dealDocumentData.lastCommentId, isIsvCsmUser || isIsvRRTeamUser ? null : (isIsvExternalUser ? true : false), false);
        }
    }
  };

  const getApproveRejectPostData = (dealDocumentData) => {
    const postData = {
      clientFileId: dealDocumentData.clientFileId,
      isCsmApproved: isIsvCsmUser ? dealDocumentData.status : dealDocumentData.isCsmApproved,
      isRrApproved: !isIsvCsmUser ? dealDocumentData.status : dealDocumentData.isRrApproved,
      selectedDealData: selectedDealData,
      isIsvCsmUser: isIsvCsmUser
    }
    return postData
  }

  const onDialogClose = (comment) => {
    setIsRejectButtonClick(false);
    setDocumentStatus('')
    if(!comment && ((isIsvCsmUser && isCSMApproved) || (isRRApproved && isIsvRRTeamUser))) {
      const postData = {
        clientFileId: selectedDealDocumentData.clientFileId,
        isCsmApproved: (isIsvCsmUser && isCSMApproved) ? null : isCSMApproved,
        isRrApproved: (!isIsvCsmUser && isRRApproved) ? null : isRRApproved,
        selectedDealData: selectedDealData,
        isIsvCsmUser: isIsvCsmUser
      }
      handleDocumentApproveReject(postData);
    }
  }

  const handleRejectCommentPosted = () => { 
    const postData = getApproveRejectPostData(selectedDealDocumentData);
    handleDocumentApproveReject(postData);
  }

  return (
  <div className="react-bootstrap-table" ref={titleTableColumnWidth}>
      <Table className='Table standard-summary-table'>
        <TableHead style={{ width: `100%` }}>
          <TableRow>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader
              }}
            >
              Title
            </TableHeaderCell>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader
              }}
            >
              Comments
            </TableHeaderCell>
            {!isIsvExternalUser &&
              <TableHeaderCell
                style={{
                  ...leftAlignedHeaderStyle,
                  ...tableHeader,
                  ...uploadCellwidth
                }}
              >
                Approve / Reject
              </TableHeaderCell>
            }
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...uploadCellwidth
              }}
            >
              Upload Date
            </TableHeaderCell>
            {
              !isIsvRRTeamUser &&
              <>
                <TableHeaderCell
                  style={{
                    ...centerAlignedCellStyle,
                    ...tableHeader,
                    ...deleteCellwidth
                  }}
                >
                  Upload
              </TableHeaderCell>
                <TableHeaderCell
                  style={{
                    ...centerAlignedCellStyle,
                    ...tableHeader,
                    ...deleteCellwidth
                  }}
                >
                  Delete
              </TableHeaderCell>
              </>
            }
          </TableRow>
        </TableHead>
        {dealInformationTableData && dealInformationTableData.length ?
          <TableBody>
            {dealInformationTableData.map((doc, index) => {
              const isDocument = doc.document && doc.document.fileName;
              const isSupportingDocument = doc.isClientSupportingDocument;
              const isClientFile = selectedDealData.clientDocumentFile.find(item => item.title === (doc.document && doc.document.title)) || (isSupportingDocument && doc)
              const isCsmApproved =
              isIsvCsmUser && isClientFile && isClientFile.isCsmApproved
                ? true
                : isIsvCsmUser && isClientFile && isClientFile.isCsmApproved === false
                ? false
                : null;
            const isRrApproved =
              isIsvRRTeamUser && isClientFile && isClientFile.isRrApproved
                ? true
                : isIsvRRTeamUser && isClientFile && isClientFile.isRrApproved === false
                ? false
                : null;
            const isToggleButtonDisabled = selectedDealData && isClientFile && (                 
                    (selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT) ||
                    (isIsvCsmUser && isClientFile.isCsmDocReviewed !== null) ||
                    (isIsvRRTeamUser && (isClientFile.isRrDocReviewed !== null || selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CSM))
                  )

              const isCsmApprovedInformation = selectedDealData && isClientFile && (
                (isIsvExternalUser && isClientFile.isClientDocReviewed) ||
                (isIsvCsmUser && isClientFile.isCsmApproved && isClientFile.isCsmDocReviewed) ||
                (isIsvRRTeamUser && isClientFile.isRrApproved && isClientFile.isRrDocReviewed)
                )

              const lastComment =
              latestCommentsDataForCurrentDeal &&
              latestCommentsDataForCurrentDeal.length > 0 &&
              latestCommentsDataForCurrentDeal.find(
                (item) => item.title === ((doc.document && doc.document.title) || (isSupportingDocument && doc && doc.title))
              );
              const isSubmittedForReview = selectedDealData && selectedDealData.status.nextAction !== NEXT_ACTION_OWNER.CLIENT;  

              const isCsmRejected = isClientFile && selectedDealData && (
                                    ( isIsvExternalUser && isClientFile.isClientDocReviewed === false) ||
                                    ( isIsvCsmUser && isClientFile.isCsmApproved === false && isClientFile.isCsmDocReviewed === false) ||
                                    ( isIsvRRTeamUser && isClientFile.isRrApproved === false && isClientFile.isRrDocReviewed === false))
           
              const isEnableUploadAndDeleteForCsmUser = isIsvCsmUser && selectedDealData && selectedDealData.status && selectedDealData.status.workflowName === WORKFLOW_STATUS.DRAFT_ECB_SUBMISSION && ((isClientFile && isClientFile.isCsmFinalStatus !== true) || !isClientFile ) && (selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.PENDING_ONE_WAY_LETTER || selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.PENDING_FIRST_REVIEW) && !isSupportingDocument;
                return (
                  <TableRow key={index} className={(isCsmApprovedInformation ? 'approvedTableRow' : isCsmRejected ? 'approvedTableRow rejectedTableRow': 'normalTableRow')}>
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault
                    }}
                  >
                    <LinkTableRowColumn
                      id={`link-doc`}
                    >
                        <InformationTitleColumn
                          cursor={isDocument || isClientFile || isSupportingDocument}
                          onClick={() => viewSelectedDocument(isClientFile, doc.document, doc.documentId)}                     >
                          {(doc.document && doc.document.title) || doc.title}
                        </InformationTitleColumn>
                      <FileNameText>{isClientFile && isClientFile.fileName}</FileNameText>
                    </LinkTableRowColumn>
                  </TableColumnCell>
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...commentsColumn,
                      ...fontSizeDefault
                    }}
                  >
                    <DealInformationCommentBoxContainer
                      selectedDealData={selectedDealData}
                      title={isRejectButtonClick ? selectedLastCommentTitle : (doc.document && doc.document.title) || doc.title}
                      lastComment={lastComment}
                      isRejectButtonClick={isRejectButtonClick}
                      onCloseDialog={onDialogClose}
                      isAdditionalComment={false}
                      onRejectCommentPosted={handleRejectCommentPosted}
                      selectedLastCommentId={selectedLastCommentId}
                      rejectToggleClicked={documentStatus}
                      commentRow={index}
                    />
                  </TableColumnCell>
                  {!isIsvExternalUser &&
                    <TableColumnCell
                      style={{
                        ...centerAlignedCellStyle,
                        ...fontSizeDefault,
                      }}
                    >
                     <ToggleButtonGroup
                        value={index === selectedDocumentIndex ? documentStatus : ""}
                        exclusive
                        onChange={(e, value) =>
                          handleDocumentStatusChange(e, value, index, {
                            clientFileId: isClientFile.clientFileId,
                            status: value === "approve" ? true : false,
                            isCsmApproved: isClientFile.isCsmApproved,
                            isRrApproved: isClientFile.isRrApproved,
                            lastCommentId: lastComment && lastComment.commentId ? lastComment.commentId : 0,
                            lastCommentTitle: ((doc.document && doc.document.title && doc.document.title) || (doc.title)) || (lastComment && lastComment.title)
                          })
                        }
                      >
                        <ToggleButton
                          value="approve"
                          style={
                            !isClientFile || isToggleButtonDisabled
                              ? { ...toggleButton, ...approveButton, ...disabledButton }
                              : isCsmApproved || isRrApproved
                              ? { ...selectedToggleButton, ...approveButton }
                              : { ...toggleButton, ...approveButton }
                          }
                          disabled={!isClientFile || isToggleButtonDisabled}>
                          <ApproveRejectText> Approve </ApproveRejectText>
                          {(isCsmApproved || isRrApproved) && (
                            <UpArrowContainer>
                              <UpArrowIcon />
                            </UpArrowContainer>
                          )}
                        </ToggleButton>
                        <ToggleButton
                          value="reject"
                          style={
                            !isClientFile || isToggleButtonDisabled
                              ? { ...toggleButton, ...disabledButton }
                              : isCsmApproved === false || isRrApproved === false
                              ? selectedToggleButton
                              : toggleButton
                          }
                          disabled={!isClientFile || isToggleButtonDisabled }
                          disableFocusRipple={true}
                        >
                          <ApproveRejectText> Reject </ApproveRejectText>
                          {(isCsmApproved === false || isRrApproved === false) && (
                            <UpArrowContainer>
                                <UpArrowIcon />
                            </UpArrowContainer>
                          )}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </TableColumnCell>
                  }
                  <TableColumnCell
                    style={{
                      ...leftAlignedCellStyle,
                      ...fontSizeDefault
                    }}
                  >
                  {dateFormatter( isClientFile ? "dayMonthYearGMTtoISTZone" : "dayMonthYearISTZone", isClientFile ? isClientFile.createdDate : doc.createdDate)}
                  </TableColumnCell>
                    {
                      !isIsvRRTeamUser &&
                      <>
                        <TableColumnCell
                          style={{
                            ...centerAlignedCellStyle,
                            ...fontSizeDefault
                          }}
                        >
                          <IconContainer onClick={isEnableUploadAndDeleteForCsmUser && isIsvCsmUser ? () => handleOpenUploadOverlay(true, isClientFile ? isClientFile : doc.document, isClientFile ? isClientFile.title : doc.document.title) :
                            () => !isIsvCsmUser && !isCsmApprovedInformation && !isSubmittedForReview && handleOpenUploadOverlay(true, isClientFile ? isClientFile : doc.document, isClientFile ? isClientFile.title : doc.document.title)}>
                            <Icon
                              style={{
                                ...iconStyles,
                                cursor: (!isEnableUploadAndDeleteForCsmUser && isIsvCsmUser) ? 'default' : (!isIsvCsmUser && (isCsmApprovedInformation || isSubmittedForReview)) ? 'default' : 'pointer',
                                opacity: (!isEnableUploadAndDeleteForCsmUser && isIsvCsmUser) ? 0.2 : (!isIsvCsmUser && (isCsmApprovedInformation || isSubmittedForReview)) ? 0.2 : 1,
                              }}
                              iconName='Upload' />
                          </IconContainer>
                        </TableColumnCell>
                        <TableColumnCell
                          style={{
                            ...centerAlignedCellStyle,
                            ...fontSizeDefault
                          }}
                        >
                          {
                            isClientSupportingDocument ?
                              <IconContainer onClick={isEnableUploadAndDeleteForCsmUser && isIsvCsmUser ? () => handleDeleteConfirmationDialogue(true, isClientFile.clientFileId) :
                                () => !isIsvCsmUser && !isCsmApprovedInformation && !isSubmittedForReview && handleDeleteConfirmationDialogue(true, isClientFile.clientFileId)}>
                                <Icon
                                  style={{
                                    ...iconStyles,
                                    width: 12,
                                    marginRight: 0,
                                    cursor: (!isEnableUploadAndDeleteForCsmUser && isIsvCsmUser) ? 'default' : (!isIsvCsmUser && (isCsmApprovedInformation || isSubmittedForReview)) ? 'default' : 'pointer',
                                    visibility: isSupportingDocument ? '' : 'hidden',
                                    opacity: (!isEnableUploadAndDeleteForCsmUser && isIsvCsmUser) ? 0.2 : (!isIsvCsmUser && (isCsmApprovedInformation || isSubmittedForReview)) ? 0.2 : 1,
                                  }}
                                  iconName="closeX" />
                              </IconContainer> : null
                          }
                        </TableColumnCell>
                      </>
                    }
                </TableRow>
              );
            })}
          </TableBody> : null
        }
      </Table>
    </div>
  )
}

export default compose(
  connect(
    (state) => ({
      selectedDealData: getIsvCurrentDeal(state),
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvCsmUser: isIsvCsmUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      dealId: getIsvCurrentDealId(state),
      latestCommentsDataForCurrentDeal: selectLatestCommentsDataForCurrentDeal(state),
    }),
    (dispatch) => ({
      handleDocumentApproveReject: (data) => {
        dispatch(handleDocumentApproveReject(data));
      },
      handleClientDocuments: (data) => {
        dispatch(handleClientDocuments(data));
      },
      getLatestCommentsByDealId: (dealId, isClientScreen, isEcbReturn) => {
        dispatch(getLatestCommentsByDealId(dealId, isClientScreen, isEcbReturn));
      },
      getLatestCommentsByCommentId: (commentId, isClientScreen, isEcbReturn) => {
        dispatch(getLatestCommentsByCommentId(commentId, isClientScreen, isEcbReturn));
      },
      handleGetLatestCommentsByCommentsId: (data) => {
        dispatch(handleGetLatestCommentsByCommentsId(data))
      },
      handleGetLatestCommentsByDealIdResponse: (data) => {
        dispatch(handleGetLatestCommentsByDealIdResponse(data))
      },
      setTitleColumnWidth: (width) => {
        dispatch(setTitleColumnWidth(width))
      }
    })
  )
)(DealInformationTable);