import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import TranslationText from 'translations/TranslationText';
import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';
import { HSBC_RAG_GREEN, HSBC_RAG_AMBER, HSBC_RAG_RED } from 'constants/colors';
import { ValueFormatters } from 'mk-pattern-library-react';

import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

const commentStyles = {
  whiteSpace: 'pre-line',
};

const ThemeContainer = styled.div`
  background-color: #F7F7F7;
  border-left: solid 7px ${props => {
    switch(props.themeType) {
      case 'positive' :
        return HSBC_RAG_GREEN
      case 'negative' :
        return HSBC_RAG_RED
      case 'valuation' :
        return HSBC_RAG_AMBER
      default :
        return 
    }
  }};
  visibility: ${props => props.count === 0 ? 'hidden' : 'visible'}
  padding-left: 10px;
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 5px;
`;

const TrancheInfoContainer = styled.div`
  background-color: #F7F7F7;
  margin: 0.2rem;
  display: inline-block;
  padding: 0.5rem;
  text-align: left;
`;

const FeedbackDetailFeedback = (props) => {
  const { feedback } = props;

  const isECMSingleTranche = !!(
    !props.isDCM &&
    feedback.feedbackTranchePriceDemand &&
    feedback.feedbackTranchePriceDemand.length === 1 &&
    feedback.feedbackTranchePriceDemand[0].priceDemand
  );

  const ecmValuation = !!(
    isECMSingleTranche &&
    feedback.feedbackTranchePriceDemand[0].priceDemand.valuation
  );

  return (
    <Row className="feedback-detail-feedback" style={{ margin: 0}}>
    <Col>
      <Row>
      <Col sm={10} className="text-normal">
        <Row sm={9} style={{paddingTop: '0.1rem'}}>
          <Col sm={12}>
            <h4>
              {
                feedback.investorType && (<span>{feedback.investorType}&nbsp;</span>)
              }
              {
                feedback.investorName && (<span className="text-timestamp" style={{ wordWrap: 'break-word' }}>{feedback.investorName}</span>)
              }
            </h4>
          </Col>
        </Row>
        <Row sm={9} style={{ padding: '0.1rem 0 0.1rem 0' }}>
            <Col sm={12}>
              <div className="text-timestamp text-univ-lt">
                  <span>
                    <TranslationText
                      dictionaryKey={"investor_feedback.feedback_recorded_by"}
                      defaultText={"Feedback recorded by"}
                    />
                    :&nbsp;
                    <span>{feedback.banksRecorded}</span>
                  </span>
                  {
                    feedback.cityRecorded && (
                      <span>
                        &nbsp;{feedback.cityRecorded}
                      </span>
                    )
                  }
                  <span>
                    &nbsp;on {ValueFormatters.dateFormatter('dayMonthYearZoneShort', feedback.created)}
                  </span>
                </div>
            </Col>
        </Row>
        <Row sm={9} >
          <Col sm={12}>
            {
              feedback.comment && (
                <div style={commentStyles}>
                  <ResponsiveEllipsis
                    style={{ whiteSpace: 'pre-wrap' }}
                    text={feedback.comment.trim()}
                    maxLine="5"
                    trimRight
                    basedOn="letters"
                  />
                </div>
              )
            }
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <ThemeContainer themeType='positive' count={feedback.themes.positive.length}>
          {feedback.themes.positive.length }&nbsp;
          <TranslationText dictionaryKey={mapThemeTypeOrSentimentToKey('Positive')} defaultText={feedback.sentiment}/>&nbsp;
          <TranslationText dictionaryKey={"investor_feedback.themes"} defaultText={"Themes"} />
        </ThemeContainer>
        <ThemeContainer themeType='negative' count={feedback.themes.negative.length}>
          {feedback.themes.negative.length }&nbsp;
          <TranslationText dictionaryKey={mapThemeTypeOrSentimentToKey('Concern')} defaultText={feedback.sentiment}/>&nbsp;
          <TranslationText dictionaryKey={"investor_feedback.themes"} defaultText={"Themes"} />
        </ThemeContainer>
        <ThemeContainer themeType='valuation' count={feedback.themes.valuation.length}>
          {feedback.themes.valuation.length }&nbsp;
          <TranslationText dictionaryKey={mapThemeTypeOrSentimentToKey('Valuation')} defaultText={feedback.sentiment}/>&nbsp;
          <TranslationText dictionaryKey={"investor_feedback.themes"} defaultText={"Themes"} />
        </ThemeContainer>
      </Col>
    </Row>
    {
      props.isDCM && feedback.feedbackTranchePriceDemand && feedback.feedbackTranchePriceDemand.length && (
        <Row sm={12}>
            <Col sm={12} className="text-normal">
              {
                feedback.feedbackTranchePriceDemand.map(tranche =>
                    (tranche.priceDemand.min || tranche.priceDemand.max || tranche.priceDemand.valuation) && (
                      <TrancheInfoContainer>
                        <div>
                        {
                            `${tranche.priceDemand.min ? tranche.priceDemand.min : ''}${
                              tranche.priceDemand.min && tranche.priceDemand.max ? ' - ' : ''}${
                              tranche.priceDemand.max || ''} ${tranche.priceDemand.valuation && (tranche.priceDemand.min || tranche.priceDemand.max) ? '@ ' : ''}
                              ${tranche.priceDemand.valuation ? tranche.priceDemand.valuation : ''}`
                        }
                        </div>
                        <div>{tranche.trancheShortName}</div>
                      </TrancheInfoContainer>
                    )
                )
              }
            </Col>
        </Row>
      )
    }
    {
      
      !props.isDCM && (ecmValuation || (feedback.feedbackTranchePriceDemand[0].priceDemand.min || feedback.feedbackTranchePriceDemand[0].priceDemand.max)) && (
          <TrancheInfoContainer>
            <div>
              <TranslationText
                dictionaryKey={"investor_feedback.valuation"}
                defaultText={"Valuation"}
              /> : { ecmValuation && feedback.feedbackTranchePriceDemand[0].priceDemand.valuation }
            </div>
            <div>
            <TranslationText
              dictionaryKey={"investor_feedback.ioi"}
              defaultText={"IoI"}
            /> : {`${feedback.feedbackTranchePriceDemand[0].priceDemand.min ? feedback.feedbackTranchePriceDemand[0].priceDemand.min : ''}${
              feedback.feedbackTranchePriceDemand[0].priceDemand.min && feedback.feedbackTranchePriceDemand[0].priceDemand.max ? ' - ' : ''}${
              feedback.feedbackTranchePriceDemand[0].priceDemand.max || ''}`}
            </div>
          </TrancheInfoContainer>

      )
    }
    </Col>
    </Row>
  );
};

export default FeedbackDetailFeedback;
