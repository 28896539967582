import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PopupSystem from "shared/components/PopupSystem";
import Popup,{ Divider }  from "shared/components/Popup";
import Scrollable from "shared/components/Scrollable";
import { openSiteText } from "actions/LoginActions";
import { getSelectedLanguage, makeGetSiteTextSelector } from "selectors";
import logo from "mk-pattern-library-react/images/HSBC_Logo.svg";
import { acceptOneWayLetter, acceptLetterClick } from "Isv/actions/IsvUtilityActions";
import { getIsvCurrentDeal } from '../Isv/selectors';

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    ${Divider} {
        display: block;
        margin: 1rem 0;
    }
`;

const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
`;

const LogoIcon = styled.img`
  height: 71px;
  margin-top: 33px;
`;

const textStyle = {
  marginTop: "-44px",
  marginLeft: "78px",
  fontWeight: "bolder",
};

const popupStyle = {
  marginTop:'-55px',
};

class OneWayLetter extends React.PureComponent {
  componentDidMount = () => {
    this.props.openOneWayLetter(
      this.props.modalID,
      this.props.langCode,
      this.props.siteTextType
    );
  };
  cancelOneWayLetter = () => {
    this.props.cancelOneWayLetter();
  };

  acceptOneWayLetter = () => {
    this.props.acceptOneWayLetter(this.props.dealId );
  };
  
  render() {
    return (
      <>
   { !this.props.acceptedBy && !this.props.startDate ? ( 
      <PopupSystem
        centered
        backgroundLayer
        modalID={this.props.modalID}
        isModalOpen={true}
        closeIcon
        closeModal={this.cancelOneWayLetter}
        content={
          <Popup
            static
            width={this.props.width || 500}
            primaryButton
            primaryButtonStyle={{ padding: "14px 27px" }}
            primaryButtonContent="Next"
            primaryButtonAction={this.acceptOneWayLetter}
            primaryButtonKey="common.next"
          >
            <ModalContent style={popupStyle}>
              <p style={{ height: 84.5, marginLeft: "-4px" }}>
                <LogoIcon src={logo} />
                <p style={textStyle}>HSBC</p>
              </p>
              <Scrollable           
                scrollHeight={400}
                >
                <ScrollableContent>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.siteText }}
                  />
                </ScrollableContent>
              </Scrollable>
            </ModalContent>
          </Popup>
        }
      />
      ):''}
       </>
    );
   
  }
}
const makeMapStateToProps = (state, ownProps) => {
  const getSiteText = makeGetSiteTextSelector(ownProps.siteTextType.name);
  const mapStateToProps = (state, ownProps) => ({
    langCode: getSelectedLanguage(state),
    siteTextType: ownProps.siteTextType,
    siteText: getSiteText(state),
    "aria-label": ownProps["aria-label"],
    oneWayLetter: state.isvUtility.dealSwitch,
    acceptedBy:getIsvCurrentDeal(state).acceptedBy,
    startDate:getIsvCurrentDeal(state).startDate,
    currentDealId:getIsvCurrentDeal(state).dealId,
    
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps, (dispatch) => ({
  openOneWayLetter: (modalId, langCode, siteTextType) => {
    dispatch(openSiteText(langCode, siteTextType));
  },
  acceptOneWayLetter: (dealId) => {
    dispatch(acceptLetterClick(dealId))
    dispatch(acceptOneWayLetter(dealId))
  },
  cancelOneWayLetter:(dealId) => {
    dispatch(acceptOneWayLetter(dealId))
  }
}))(OneWayLetter);