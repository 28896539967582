import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { compose } from 'recompose'

import withTranslate from 'shared/hoc/withTranslate'

import { buttonKeyHandler } from 'util/onKeyPress';

import {
    FloatedRightSpan,
    ListItemContainer,
    listGroupItemInlineStyles
} from './CategoryListItem.styles.js';

const mappingsForCategoryTypes = categoryType => {
    switch (categoryType) {
        case 'Investor Profiles':
            return 'my_docs.investor_profiles';
        case 'Deal Update Materials':
            return 'my_docs.deal_update_materials';
        case 'Pricing':
            return 'my_docs.pricing';
        case 'RoadShow Schedule':
            return 'my_docs.roadshow_schedule';
        case 'Feedback':
            return 'my_docs.feedback';
        case 'List of Parties':
            return 'my_docs.list_of_parties';
        case 'Prospectus':
            return 'my_docs.prospectus';
        case 'Other':
            return 'my_docs.other';
        case 'Presentations':
            return 'my_docs.presentations';
        default: return '';
    }
}

class CategoryListItem extends React.PureComponent {
    categoryClick = () => {
        this.props.handleClick(this.props.category.documentCategoryId, this.props.category.documentCategoryName);
    }

    categoryPress = e => {
        buttonKeyHandler(e, () => this.props.handleClick(this.props.category.documentCategoryId, this.props.category.documentCategoryName));
    }

    render() {
        return (
            <ListGroupItem
                id={`c-l-i-${this.props.id}`}
                aria-selected={this.props.isActive}
                aria-label={this.props.listItemAriaLabel}
                tabIndex={0}
                onClick={this.categoryClick}
                onKeyDown={this.categoryPress}
                style={listGroupItemInlineStyles}
                className={`day-view-cell ` + (this.props.isActive ? 'active' :'')}
            >
                <ListItemContainer>
                    <span className="text-univ-lt text-large">
                        { this.props.documentCategoryText }
                    </span>
                    <FloatedRightSpan className="text-univ-bd text-medium">
                        { this.props.category.activeDocumentCount }
                    </FloatedRightSpan>
                </ListItemContainer>
            </ListGroupItem>
        )
    }
}

export default compose(
    withTranslate((translate, props) => ({
        documentCategoryText: translate(
            mappingsForCategoryTypes(props.category.documentCategoryName),
            props.category.documentCategoryName
        ),
        listItemAriaLabel: translate(
            'my_docs.display_documents_from_category_<category_name>',
            `Display documents from category: ${props.category.documentCategoryName}`,
            [
                ['<category_name>', props.category.documentCategoryName]
            ]
        )
    }))
)(CategoryListItem);