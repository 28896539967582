import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { dateFormatter } from '../../OrderBook/SharedTableComponents/formatters';
import Table from 'shared/components/Table';
import {
  firstHeaderAlignedLeftStyle,
  otherRowAlignedLeft,
} from 'shared/components/Table';
import { selectInvestorTypeDropdown } from 'actions/OrderBookActions';
import { selectConfirmationBannerStatus, getIsvCurrentDealId, isIsvExternalUser, selectedEcb2ReturnStatus } from 'Isv/selectors'
import styled from 'styled-components';
import ConfirmationDialog from '../components/common/ConfirmationDialog'
import { handleConfirmationDialogue, handlePostDashboardData } from 'Isv/actions/IsvInformationBannerAction';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import calender from "Isv/images/calender.png";
import { SvgIcon } from 'shared/components/Icon';
import { browserHistory } from 'react-router';
import {
  NEXT_ACTION_OWNER,
  ISV_DOCUMENT_REVIEW_STATUS,
} from 'Isv/constants/isvDocumentReviewStatus'

const widthAlign = (isIsvCsmUser, isIsvRRTeamUser) => {
  return {
    width: (isIsvCsmUser && !isIsvRRTeamUser) ? '180px' : '122px'
  }
}

const disabledLRNRef = {
    opacity: '0.4',
    pointerEvents: 'none'
}

const dialogAction = {
  paddingRight: '0px'
}

const lrnReferenceInput = {
  fontSize: '14px'
}

const lrnReferenceCancelButton = {
  color: 'grey'
}

const svgIconStyle = {
  position: 'absolute',
  right: '-25px', 
  transform: 'translate(-50%,-50%)', 
  top: '50%', 
  zIndex: 1, 
  marginTop: '7px'
}

const TableContainer = styled.div``

const DealTextWrapper = styled.p`
  font-size: 26px;
  font-weight: bold;
  margin-top: 15px
`

const DatePickerWrapper = styled.div`
  padding-left: 6px;
  .MuiFormControl-root {
    margin-left: -2px;
    padding: 2px 7px 2px 10px;
    border-radius: 2px;
    border: 1px solid;
    fieldset, legend { border: none }
    .MuiOutlinedInput-root{
      font-size: 12px;
      padding-right: 0;
      .MuiButtonBase-root{
        outline: none;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 16px;
          width: 16px;
          background-image: url(${calender});
          background-size: contain;
          right: 2px;
          top: 50%;
          transform: translate(-50%,-50%);
        }
        &::before {
          content: '';
          position: absolute;
          height: 16px;
          width: 1px;
          background: #000;
          left: 3px;
          top: 50%;
          transform: translate(-50%,-50%);
        }
        svg { visibility: hidden }
      }
      input[type='text'] { padding: 0; margin-left: -6px; max-width: 112px }
    }
  }
`

const SvgIconContainer = styled.div`
  .picker {
    position: relative;
  }
`
const LrnReferenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`
const LrnReferenceTextWrapper = styled.div`
   padding: 20px;
`
const LrnReferenceButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`

class LrnReferenceField extends Component {
  render() {
    const { data: { maturityDate }, isIsvCsmUser, handleEdit, isIsvRRTeamUser, data} = this.props;
    const isLrnReferenceEditable = data && data.status && (data.status.statusName === 'Submitted to RBI' || data.status.statusName === 'Completed') && data.status.workflowName === 'Final ECB Submission';
    return (
      (isIsvCsmUser || isIsvRRTeamUser) &&(<SvgIconContainer>
        <div className='picker'>
          <span onClick={(event) => isLrnReferenceEditable ? handleEdit(event,maturityDate) : null}>
            <SvgIcon
              color='#000'
              icon='pencil-edit'
              size='large'
              style={isLrnReferenceEditable ? {cursor: (isIsvCsmUser || isIsvRRTeamUser) ? 'pointer' : 'no-drop', ...svgIconStyle } : {...svgIconStyle, ...disabledLRNRef }}
              aria-hidden
            />
          </span>
        </div>
      </SvgIconContainer>)
    )
  }
}

class DatePicker extends Component {

  state = {
    isDatePickerOpen: false
  }

  handleOpen = () => {
    this.setState({ isDatePickerOpen: true })
  }

  handleClose = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const history = browserHistory.getCurrentLocation();
    const { maturityDate, status, isFinalSubmission } = this.props.data;
    if ((((maturityDate == null && isFinalSubmission) || (currentDate > new Date(maturityDate))) && (history && history.state && history.state.fromDealInformation)) && (this.props.isIsvCsmUser && status &&
      status.statusName !== "Completed" && status.nextAction === "CSM" 
    )) {
      this.setState({ isDatePickerOpen: true });
    } else 
    { 
      this.setState({ isDatePickerOpen: false }); }
  };

  render() {
    return (
      <DatePickerWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            value={this.props.value}
            open={this.state.isDatePickerOpen}
            inputFormat="dd MMMM yyyy"
            onChange={this.props.onChange}
            onAccept={this.props.onAccept}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            disablePast
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "dd/mm/yyyy",
                  readOnly: true,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </DatePickerWrapper>
    )
  }
}

class DashboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maturityDate: null,
      isMaturityDatePassed: false,
      isMaturityDateUpdateWarning: false,
      anchorEl: null,
      lrnReference: '',
      isLrnReferenceAlreadyAdded: false,
      hasMaturityDate: null,
      isDatePickerOpen: false,
    }
    this.childRef = React.createRef();
}

  componentDidMount() {
    this.setState({ maturityDate: this.props.data.maturityDate });
    const history = browserHistory.getCurrentLocation();
    this.showMaturityDateWarning(history && history.state && history.state.fromDealInformation);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.dealId !== this.props.dealId) {
      const history = browserHistory.getCurrentLocation();
      this.showMaturityDateWarning(history && history.state && history.state.fromDealInformation);
      this.setState({ maturityDate: null, lastSelectedValue: null, lrnReference: '' });
    }
  }

  Columns = (props, lrnRef) => {
    const columns = [
      {
        attrs: {
          role: 'rowheader',
        },
        dataField: 'referenceId',
        text: 'Reference ID',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
          width:'105px'
        },
      },
      {
        dataField: 'projectName',
        text: 'Deal Name',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: firstHeaderAlignedLeftStyle,
      },
      {
        dataField: 'clientName',
        text: 'Client Name',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: firstHeaderAlignedLeftStyle,
      },
      {
        dataField: 'startDate',
        text: 'Deal Start Date',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
          width: '122px'
        },
        formatter: (value) => dateFormatter('localDate', value),
      },
      {
        dataField: 'maturityDate',
        text: 'Maturity Date',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
          ...widthAlign(props.isIsvCsmUser, props.isIsvRRTeamUser)
        },
        formatter: (value) => {
          return props.isIsvCsmUser && !props.isIsvRRTeamUser ? 
            <DatePicker
              onAccept={this.handleOnAccept}
              ref={this.childRef}
              onChange={this.handleChangeDatePicker}
              value={value}
              {...props}
            />
           : dateFormatter('localDate', value)
        }
      },
      {
        dataField: 'status.workflowName',
        text: 'Workflow Name',
        headerClasses: '',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: firstHeaderAlignedLeftStyle,
      },
      {
        dataField: 'status.statusName',
        text: 'Workflow Status',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
        }  
      },
      {
        dataField: 'workflowStatusDays',
        text: 'Days @ Workflow status',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
          width:'115px'
        },
        formatter: (value) => (value && value !== 0) ? (value > 1 ? `${value} Days` : `${value} Day` ) : value
      },
      {
        dataField: 'dealAge',
        text: 'Deal Age',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
        },
        formatter: (value) => (value && value !== 0) ? (value > 1 ? `${value} Days` : `${value} Day` ) : value
      },
    ]
    if (!props.isIsvExternalUser) {
      columns.push(
        {
          dataField: 'titanDealId',
          text: 'Titan Deal ID',
          style: {
            ...otherRowAlignedLeft,
          },
          headerStyle: firstHeaderAlignedLeftStyle,
        },
      )
    }
    columns.push(
      {
        dataField: 'lrnReference',
        text: 'LRN Reference',
        style: {
          ...otherRowAlignedLeft,
        },
        headerStyle: {
          ...firstHeaderAlignedLeftStyle,
        },
        formatter: () => {
          return ( <LrnReferenceContainer><div>{lrnRef}</div> <LrnReferenceField handleEdit={this.handleEdit} {...props} /></LrnReferenceContainer>)
        
        },
      },
    )
    if (!props.isIsvExternalUser) {
      columns.push(
        {
          dataField: 'status.nextAction',
          text: 'Next Action Owner',
          style: {
            ...otherRowAlignedLeft,
          },
          headerStyle: {
            ...firstHeaderAlignedLeftStyle,
          },
        }
      )
    }

    if (props.isECBStatusEnabled) {
      columns.push(
        {
          dataField: 'status.ecB2ReturnStatus',
          text: 'ECB 2 Return Status',
          style: {
            ...otherRowAlignedLeft,
          },
          headerStyle: {
            ...firstHeaderAlignedLeftStyle,
          }
        }
      )
    }

    return columns;
  };

  handleEdit = (event) => {
    const lrnReference = this.state.lrnReference || this.props.data.lrnReference;
    if (lrnReference) {
      this.setState({ isLrnReferenceAlreadyAdded: true });
      this.props.handleConfirmationDialogue(true);
    } else this.setState({ isEditLrnReferenceNo: true });
    this.setState({ anchorEl: event.currentTarget, lrnReference: lrnReference });
  }

  handleChangeDatePicker = () => {
    this.setState({ isMaturityDatePassed: false , hasMaturityDate: true})
    return
  }

  handleOnAccept = (date) => {
    const isMaturityDateAlready = this.state.maturityDate || this.props.data.maturityDate;
    this.setState({ maturityDate: date, hasMaturityDate: true });
    const history = browserHistory.getCurrentLocation();
    const fromDealInfo = history && history.state && history.state.fromDealInformation;
    if (isMaturityDateAlready && (fromDealInfo === false || fromDealInfo === undefined)) {
      this.props.handleConfirmationDialogue(true)
    } else {
      this.handlePostDashboardData(date);
    }
  }

  showMaturityDateWarning = (fromDealInformation) => {
    const { data: { maturityDate, status, isFinalSubmission }, isIsvCsmUser, isIsvRRTeamUser, handleConfirmationDialogue, } = this.props;
    if (maturityDate && !isIsvRRTeamUser) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if ((currentDate > new Date(maturityDate)) && isIsvCsmUser && status && ((status.statusName !== ISV_DOCUMENT_REVIEW_STATUS.MATURED) && (status.statusName !== ISV_DOCUMENT_REVIEW_STATUS.COMPLETED))) {
        handleConfirmationDialogue(true);
        this.setState({ isMaturityDatePassed: true , hasMaturityDate: true })
      }
    }
    if (maturityDate == null && isIsvCsmUser && status && status.statusName !== ISV_DOCUMENT_REVIEW_STATUS.COMPLETED && 
        status.nextAction === NEXT_ACTION_OWNER.CSM && isFinalSubmission && fromDealInformation) {  
          handleConfirmationDialogue(true);
          this.setState({ hasMaturityDate: false })
    }
  }

  handleMaturityDateDialog = () => {
    const { data: { maturityDate, status, isFinalSubmission }, isIsvCsmUser, } = this.props;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (isIsvCsmUser && status && status.statusName !== ISV_DOCUMENT_REVIEW_STATUS.COMPLETED && 
       ((maturityDate == null && isFinalSubmission && status.nextAction === NEXT_ACTION_OWNER.CSM) || (currentDate > new Date(maturityDate)))) {
        this.props.handleConfirmationDialogue(false, false);
        this.childRef && this.childRef.current.handleOpen();
     }
     else this.props.handleConfirmationDialogue(false, false);
  }
  
  handleCloseConfirmation = () => {
    this.props.handleConfirmationDialogue(false, false);
    const lastSelectedValue = this.state.lastSelectedValue || this.props.data.maturityDate
    this.setState({ maturityDate: lastSelectedValue , isLrnReferenceAlreadyAdded: false });
  }

  handleOkConfirmation = () => {
    this.props.handleConfirmationDialogue(false, false);
    this.handlePostDashboardData(this.state.maturityDate, this.props.data.lrnReference);
  }

  handlePostDashboardData = (date, lrnReference) => {
    const postedData = {
      dealId: this.props.dealId,
      maturityDate: date ? new Date(date).toDateString() : null,
      lrnReference: lrnReference,
    }
    this.props.handlePostDashboardData(postedData);
    this.setState({ lastSelectedValue: date })
  }

  handleLrnPopoverClose = () => {
    this.handleSaveLrnReference();
  } 

  handleLrnRefNoChange = (event) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(event.target.value)) this.setState({ lrnReference: event.target.value });
    else return
  }

  handleSaveLrnReference = () => {
    const { lrnReference } = this.state;
    if (lrnReference && lrnReference.length >= 7) this.handlePostDashboardData(this.props.data.maturityDate, lrnReference);
    else if (lrnReference && lrnReference.length < 7) this.setState({ lrnReference: this.props.data.lrnReference });
    this.setState({ anchorEl: null, isLrnReferenceAlreadyAdded: false, isEditLrnReferenceNo: false });
  }

  handleLrnRefCancel = () => {
    this.setState({ anchorEl: null, isLrnReferenceAlreadyAdded: false, isEditLrnReferenceNo: false, lrnReference: this.props.data.lrnReference });
  }

  handleLrnRefernceDialog = () => {
    this.setState({ isEditLrnReferenceNo: true });  
    this.props.handleConfirmationDialogue(false, false);
  }

  render() {
    const tableData = [].concat({ ...this.props.data });
    const { isMaturityDatePassed, lrnReference, isEditLrnReferenceNo, anchorEl, isLrnReferenceAlreadyAdded, hasMaturityDate } = this.state;
    const { isOpen, isIsvCsmUser, isIsvRRTeamUser } = this.props;
    const open = Boolean(anchorEl);
    return (
      <>
        <TableContainer>
          <DealTextWrapper>Deal</DealTextWrapper>
          <Table
            classes='standard'
            keyField='dealId'
            data={tableData}
            columns={this.Columns(this.props, tableData && tableData[0].lrnReference)}
            id="tableDashboardContainer"
          />
        </TableContainer>
        <ConfirmationDialog
          open={isOpen}
          confirmButtonText={isLrnReferenceAlreadyAdded ? 'Continue' : 'OK'}
          declineButtonText={isLrnReferenceAlreadyAdded ? 'Cancel' : (isMaturityDatePassed || hasMaturityDate === false) ? '' : 'Cancel'}
          header={'Warning'}
          subHeader={isLrnReferenceAlreadyAdded ? "LRN has already been added, do you want to proceed with editing?" : isMaturityDatePassed ? 'Maturity date has passed and deal has not been completed, please update the maturity date.': hasMaturityDate === false ? 'Maturity Date must be set to submit information to RR Team for final review' : 'Maturity date is being changed, please confirm you wish to proceed making this change'}
          onDeclineClick={this.handleCloseConfirmation}
          onConfirmClick={isLrnReferenceAlreadyAdded ? this.handleLrnRefernceDialog : isMaturityDatePassed || hasMaturityDate === false ? this.handleMaturityDateDialog : this.handleOkConfirmation}
          isCancelButtonNotRequired={isLrnReferenceAlreadyAdded ? false : isMaturityDatePassed || hasMaturityDate === false ? true : false}
          dialogActionStyle={isLrnReferenceAlreadyAdded ? null : isMaturityDatePassed || hasMaturityDate === false? dialogAction : null}          
        />
        {isEditLrnReferenceNo && (isIsvCsmUser || isIsvRRTeamUser) &&
          <Popover
            id={"popoverId"}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleLrnPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <LrnReferenceTextWrapper>
              <TextField 
                id="lrnReference" 
                label="LRN Reference No"
                variant="standard" 
                onChange={this.handleLrnRefNoChange} 
                value={lrnReference} 
                inputProps={{ maxLength :100, style: lrnReferenceInput}}
                InputLabelProps={{ style: lrnReferenceInput }}
              />
              <LrnReferenceButtonContainer >
                <Button onClick={this.handleLrnRefCancel} style={lrnReferenceCancelButton}> Cancel </Button>
                <Button onClick={this.handleSaveLrnReference} disabled={lrnReference === null || lrnReference === "" || (lrnReference && lrnReference.length < 7)}> Save </Button>
              </LrnReferenceButtonContainer>
            </LrnReferenceTextWrapper>
          </Popover>  
        }
      </>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      isIsvExternalUser: isIsvExternalUser(state),
      isOpen: selectConfirmationBannerStatus(state),
      dealId: getIsvCurrentDealId(state),
      isECBStatusEnabled: selectedEcb2ReturnStatus(state),
    }),
    (dispatch) => ({
      selectInvestorTypeDropdown: (investorType) =>
        dispatch(selectInvestorTypeDropdown(investorType)),
      handleConfirmationDialogue: (value, isopen) => {
        dispatch(handleConfirmationDialogue(value, isopen))
      },
      handlePostDashboardData: (data) => {
        dispatch(handlePostDashboardData(data))
      }
    })
  )
)(DashboardTable);
