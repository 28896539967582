export const ALL_TRANCHES_RECORD = {
  title: 'Book Evolution',
  id: 'aT'
};

export const ORDER_STATUS = {
  DELETED: 'Deleted',
  CANCELLED: 'Cancelled',
  INACTIVE: 'InActive',
  ACTIVE: 'Active',
}

export const ORDER_SUMMARY_TABS = {
  FULLORDERBOOK: 'FullOrderBook',
  ORDERBOOKSUMMARY: 'OrderBookSummary',
  AMENDEDORDERSUMMARY:'AmendedOrderSummary',
  ORDEREVOLUTION:'OrderEvolution'
}

export const ALL_TRANCHES = { key: 0, value: 'ALL', label: 'ALL' }
export const ALL_INVESTORS = { key: 0, value: 'ALL', label: 'ALL' }
export const DEFAULT_TIME_INTERVAL = { key: 15, value: '15 Min' };
export const DEFAULT_ALL = { value: 'ALL', label: 'ALL' };
export const DEFAULT_ALL_DAY = {key:81, value: 'ALL DAY', label: 'ALL DAY' };
export const DEFAULT_MIN = { key: 0, value: "00", label: "00" };
export const TIME_INTERVALS = [
  { key: 15, value: '15 Min', label: '15 Min' },
  { key: 30, value: '30 Min', label: '30 Min' },
  { key: 60, value: '60 Min', label: '60 Min' },
];

export const TIME_RANGES = [
  DEFAULT_ALL_DAY,
  { key: 8, value: '00:00 to 08:00', label: '00:00 to 08:00' },
  { key: 16,value: '08:00 to 16:00', label: '08:00 to 16:00' },
  { key: 0, value: '16:00 to 23:59', label: '16:00 to 00:00' },
  { key: 1, value: 'From specific time', label: ' From specific time' },
];

export const STATIC_MINUTES = [
  DEFAULT_MIN,
  { key: 15, value: '15', label: '15' },
  { key: 30, value: '30', label: '30' },
  { key: 45, value: '45', label: '45' },
];