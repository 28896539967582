import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import _ from "lodash";

import { TileContentInner, EmptyContent } from "Landing/dcm/Tiles/styles.js";
import { openOrderSummaryDetailView } from "actions/OrderBookActions";
import {
  leftAlignedCellStyle,
  rightAlignedCellStyle,
  leftAlignedHeaderStyle,
  rightAlignedHeaderStyle,
} from "../../shared/table/styles";
import styled from "styled-components";
import { dateFormatter } from "../../SharedTableComponents/formatters";
import Table from "shared/components/Table";
import { Sorters } from 'mk-pattern-library-react';
import { ValueFormatters } from 'mk-pattern-library-react';
const OrderSummaryTableContainer = styled.div`
  position: relative;
`;

const OrderSummaryTable = ({
  dateText,
  timeIntervalText,
  orderSummaryData,
  openOrderSummaryDetailView,
  orderSummaryList,
  notAvailable,
  trancheLists,
}) => {
  const cursorStyle = (cursorStyle) => {
    return {
      cursor: `${cursorStyle}`,
    };
  };

  const getTableColumnHeader = (tableColumnKeys, index) => {
    const columnHeader = trancheLists.filter(
      (col) => col.key === tableColumnKeys[index]
    );
    if (index === 0) return dateText;
    else if (columnHeader && columnHeader.length)
      return columnHeader[0].value + " (m)";
    else return timeIntervalText;
  };

  const getSortedColumns = (tableColumnKeys) => {
    const numberTranches = tableColumnKeys.filter(
      (item) => Number(item) === parseInt(item)
    );
    const stringTranches = tableColumnKeys
      .filter((item) => Number(item) !== parseInt(item))
      .sort();
    return stringTranches.concat(numberTranches);
  };

  const Columns = (columnData) => {
    const tableColumnKeys = _.keys(columnData[0]);
    const sortedColumns = getSortedColumns(tableColumnKeys);

    const columns = sortedColumns.map((item, index) => {
      return {
        dataField: item,
        text: getTableColumnHeader(sortedColumns, index),
        style:
          item === "date" || item === "timeInterval"
            ? {
              ...leftAlignedCellStyle,
              ...cursorStyle("auto"),
            }
            : {
              ...rightAlignedCellStyle,
              ...cursorStyle("pointer"),
            },
        headerStyle:
          item === "date" || item === "timeInterval"
            ? leftAlignedHeaderStyle
            : rightAlignedHeaderStyle,
        columnFlex: 1,
        formatter: !(item === "date" || item === "timeInterval") ? ValueFormatters.commaDecimalFormatter : null,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            row.timeInterval !== "Total" &&
              (column.dataField !== "timeInterval" &&
                column.dataField !== "date") &&
              getOrderSummaryDetails(column, rowIndex);
          },
        },
      };
    });
    return columns;
  };

  const getOrderSummaryDetails = (column, rowIndex) => {
    const selectedIndividualOrder = [];
    const individualOrders = orderSummaryData.individualOrders;
    const structuredIndividualOrders = individualOrders
      .map((item) => item.orders)
      .reduce((acc, val) => acc.concat(val), []);

    structuredIndividualOrders
      .sort((a, b) => Sorters.doubleSort('timeInterval', a, b, 'asc', 'externalSourceLastModified'))

    orderSummaryList.forEach((order, index) => {
      if (orderSummaryList[index].date === "") {
        orderSummaryList[index].date = orderSummaryList[index - 1].date;
      }
    });

    structuredIndividualOrders.forEach((order) => {
      if (
        new Date(
          dateFormatter("localDate", order.externalSourceLastModified)
        ) <= new Date(orderSummaryList[rowIndex].date)
      ) {
        if (
          new Date(
            dateFormatter("localDate", order.externalSourceLastModified)
          ) < new Date(orderSummaryList[rowIndex].date)
        ) {
          selectedIndividualOrder.push(order);
        } else if (
          order.timeInterval <= orderSummaryList[rowIndex].timeInterval
        ) {
          selectedIndividualOrder.push(order);
        }
      }
    });

    const selectedColumnOrders =
      selectedIndividualOrder.length &&
      selectedIndividualOrder.filter(
        (order) => order.trancheId === JSON.parse(column.dataField)
      );
    selectedColumnOrders.length &&
      openOrderSummaryDetailView(selectedColumnOrders);
  };
  
  return (
    <React.Fragment>
      {orderSummaryList.length ? (
        <OrderSummaryTableContainer>
          <Table
            id={"order-summary-table"}
            bordered={false}
            classes="summary-table standard-summary-table"
            columns={Columns(orderSummaryList)}
            data={orderSummaryList}
            keyField="Order Summary"
          />
        </OrderSummaryTableContainer>
      ) : (
          <TileContentInner>
            <EmptyContent>{notAvailable}</EmptyContent>
          </TileContentInner>
        )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openOrderSummaryDetailView: (summaryData) =>
    dispatch(openOrderSummaryDetailView(summaryData)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(OrderSummaryTable);
