import React from "react";
import styled from "styled-components";
import { SvgIcon } from "shared/components/Icon";

const InformationBanner = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-style: solid;
  background-color: rgb(48, 90, 133);
  border-color: rgb(48, 90, 133);
  color: rgb(255, 255, 255);
  padding: 15px 9px;
  border-width: 1px;
  margin: 15px 0;
`;

const InformationBannerWrapper = styled.span`
  display: inline-flex;
  width: 100%;
`

const InformationIconWrapper = styled.span`
  display: flex;
  position: relative;
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;
  align-self: baseline;
  margin-left: 10px;
  margin-right: 10px;
`

const InformationContentWrapper = styled.span`
  margin: 0px;
  padding: 0px 0.625rem 0px 0px;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  flex: 1 1 auto;
  align-self: flex-end;
`

const BannerText = styled.span`
  margin-left: 5px
`;

const BannerCloseIcon = styled.span`
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  position: relative;
  width: 32px;
`;

const informationIconStyle = {
  fill: '#fff',
  width: '32',
  height: '32',
  position: 'absolute',
  top: '0',
  left: '0',
};

const closeIconStyle = {
  fill: '#fff',
  width: '32',
  height: '32',
  position: 'absolute',
  top: '0',
  right: '0',
};

const IsvInformationBanner = ({
  bannerText,
  bannerHeading,
  handleCloseBanner,
  onCloseOverlay,
}) => {
  return (
    <InformationBanner>
      <InformationBannerWrapper>
        <InformationIconWrapper>
          <SvgIcon
            icon="info-icon"
            aria-hidden
            style={informationIconStyle}
          />
        </InformationIconWrapper>
        <InformationContentWrapper>
          <b>{bannerHeading}</b><BannerText>{bannerText}</BannerText>
        </InformationContentWrapper>
        <BannerCloseIcon onClick={() => handleCloseBanner(onCloseOverlay)}>
          <SvgIcon
            icon="close-icon"
            aria-hidden
            style={closeIconStyle}
          />
        </BannerCloseIcon>
      </InformationBannerWrapper>
    </InformationBanner>
  );
};

export default IsvInformationBanner;
