import React, { PureComponent } from 'react';
import styled from 'styled-components';
import MDSpinner from 'react-md-spinner';

import { typography, fontColors } from 'shared/styles/variables/theme';
import flexbox from 'shared/styles/mixins/flexbox';

export const FeaturePageLoadingContainer = styled.div`
    position: fixed;
    left: 0;

    height: calc(100vh - 100px);
    width: 100vw;
`;

const LoadingContainer = styled.div`
    ${flexbox('column', 'center', 'center')}
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    background: ${props => props.checkBackground ? '' : 'rgba(0, 0, 0, .4)'};
    
    p {
        font-size: ${typography.sizes['12px']};
        color: ${fontColors.white};
    }
`;

const LoadingText = styled.p`
    width: 100%;
    text-align: center;
`;

const spinnerInlineStyles = {
    margin: 10,
    bottom: 2,
}

const sizes = {
    def: {
      value: 60,
      border: 3,
    },
    xsmall: {
      value: 16,
      border: 1,
    },
    small: {
      value: 30,
      border: 2,
    },
    large: {
      value: 120,
      border: 6,
    },
};

class Loading extends PureComponent {
    render() {
        return (
            <LoadingContainer checkBackground = {this.props.hasBackground}>
                <MDSpinner
                    size={sizes[this.props.size].value}
                    singleColor={'#D8D8D8'}
                    borderSize={sizes[this.props.size].border}
                    style={spinnerInlineStyles}
                />
                {
                    this.props.text && (
                        <LoadingText>{this.props.text}</LoadingText>
                    )
                }
            </LoadingContainer>
        )
    }
}

Loading.defaultProps = {
    size: 'def',
    // to cover whole container
    coverContainer: true,
}

export default Loading;
