import React from 'react';
import ReactDOM from 'react-dom';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Text } from 'recharts';
import { ColorChip, VisualisationColors } from 'mk-pattern-library-react';

import TranslationText, { translationString } from 'translations/TranslationText';

import withLanguage from 'translations/withLanguage';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

import { typography } from 'shared/styles/variables/theme';

const visualisationColors = VisualisationColors.asArray;

const legendKeyStyle = {
  width: '100%',
  paddingBottom: '5px'
};

const chartAxisText = {
  fontSize: typography.sizes['12px'],
  fontWeight: 600,
  textAlign: 'center',
  textIndent: 62,
};

const renderLegend = (colors, keyTitle, props) => {
  const { payload } = props;
  const result =  (
    <div style={{ textAlign: 'left' }}>
      <h3 style={legendKeyStyle}>
        <TranslationText
          dictionaryKey={"investor_feedback.distribution_chart_title"}
          replace={[
            ["<theme_type>", translationString(mapThemeTypeOrSentimentToKey(keyTitle), props.language, keyTitle)]
          ]}
          defaultText={`Distribution of ${translationString(mapThemeTypeOrSentimentToKey(keyTitle), props.language, keyTitle)} Themes by Date`}
        />
      </h3>
      <ul className="recharts-default-legend" style={{ textAlign: 'right', overflow: 'hidden' }} >
        {
            payload.reverse().map((entry, index) => {
              if (!entry.color) {
                const targetColor = colors[(index) % colors.length];
                entry.color = targetColor;
              }
              return (
                <ColorChip fullChip className="recharts-legend-item" key={entry.value} colorHex={entry.color} description={entry.value} opacity={1.0} style={{ margin: '0px 20px 5px 0px', display: 'inline-block' }} />
              );
          })
        }
      </ul>
      <div style={ chartAxisText }>
        <TranslationText
          dictionaryKey={"investor_feedback.distribution_chart_primary_x_axis"}
          defaultText="Cumulative Number of Feedback Records"
        />
      </div>
    </div>
  );

    return ReactDOM.createPortal(
      result,
      document.querySelector('#legendTitle')
    );
};

const topBarLegend = (index, height, marginTop, props) => {
  const { x, payload, width } = props;
  if (index === 0) {
      const xCoord = x - (width / 2);
      return (
        <g>
          {payload.topText && (<Text fontSize={typography.sizes['12px']} textAnchor="start" verticalAnchor="end" x={xCoord} dy={120 + marginTop}>{payload.topText}</Text>)}
          <Text fontSize={typography.sizes['12px']} textAnchor="start" verticalAnchor="end" x={xCoord} y={height - 38}>{payload.name}</Text>
          {payload.bottomText && (<Text fontSize={typography.sizes['12px']} textAnchor="start" verticalAnchor="end" x={xCoord} y={height - 23}>{payload.bottomText}</Text>)}
        </g>
      );
  }
};

const renderLabel = (props) => {
    const { x, y, width } = props;
    const title = translationString('investor_feedback.theme_distribution_chart_secodary_x_axis', props.language, "Date of Feedback");
    const fullWidth = width + x;
    return (
      <g>
        {/* <rect x={10} transform={`translate(0, ${y})`} width={fullWidth - 10} height={height} fill="#000000" fillOpacity="0.05" /> */}
        <rect x={10} transform={`translate(0, ${y})`} width={fullWidth - 10} height={1} fill="#D9D9D9" />
        {/* <rect x={10} transform={`translate(0, ${y + height - 2})`} width={fullWidth - 10} height={1} fill="#D9D9D9" /> */}
        <Text fontWeight={600} fontSize={typography.sizes['12px']} textAnchor="middle" verticalAnchor="end" width={width} x={x + width / 2} y={y + 45} className="customized-x-axis-label">
          {
            title
          }
        </Text>
      </g>
    );
};

const customYTick = ({ payload, fontWeight, ...other}) => {
    return (
      <Text {...other} dx={-15}>{payload.value + '%'}</Text>
    );
};

const adjustYAxisOnMaxData = (data, baseAxisWidth = 60, fontSize = typography.sizes['14px']) => {
    let axisWidth = baseAxisWidth;
    const arrayOfValues = data.map((set) => {
        const setVals = Object.keys(set).map((k) => parseInt(set[k], 10))
            .filter(i => (typeof i === 'number' && i));
        return setVals;
    });

    const maxLength = Math.max(...arrayOfValues).toString().length;

    if (maxLength > 6) {
        axisWidth = (maxLength - 6) * fontSize * (1.2) + baseAxisWidth;
    }

    return axisWidth;
};

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;


  return (
    <g>
      <path d={`M${x},${y} h ${width} v ${height} h -${width} Z`} fill={fill} />;
      <path d={`M${x},${y} L${x + width},${y} Z`} stroke="#fff" />
    </g>
  );
};

class FeedbackChartThemeDistribution extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      const el = document.querySelector('svg.recharts-surface');
      if (el) el.setAttribute('focusable', false);
    }, 0);
  }
  render() {
    const props = this.props;
    const { data, keys, barGap, barSize, title, legendHeight, legendTitle, xLegend, animate } = props;
    const fontSize = typography.sizes['14px'];
    const yAxixWidth = adjustYAxisOnMaxData(data, 60, fontSize);
    const animationDuration = 650;

    const colors = visualisationColors;
    const bars = keys => {
        const sortedKeys = keys.sort((a, b) => data[0][a.key] - data[0][b.key]);
        return (

            sortedKeys.map((key, index) => {
                // if the key does not have an assigned color, pick one from the color array
                if (!key.color) {
                    const targetColor = colors[keys.length - 1 - (index % colors.length)];
                    key.color = targetColor;
                }

                const barAnimate = animationDuration / keys.length;
                const barAnimateDelay = animationDuration - ((index + 1) * barAnimate);
                return (
                  <Bar
                    animationEasing="linear"
                    animationBegin={barAnimateDelay}
                    animationDuration={barAnimate}
                    maxBarSize={200}
                    label={topBarLegend.bind(this, index, props.height, +props.marginTop - 17 || 0)}
                    dataKey={key.key}
                    name={key.value}
                    stackId="a"
                    shape={CustomBar}
                    fill={key.color}
                    isAnimationActive={animate}
                  />
                );
            })
        );
    };

    return (
        <ResponsiveContainer width="100%" height={+props.height}>

            <ComposedChart
                data={data}
                margin={{ top: +props.marginTop - 17 + 65 || 20, right: 10, bottom: 0, left: 10 }}
                width={100} height={+props.height}
                barGap={barGap}
                barSize={barSize}
            >
              {xLegend
                ? (<XAxis
                  orientation="bottom"
                  legendTitle={legendTitle}
                  dy={5}
                  fontWeight={600}
                  fontSize={typography.sizes['12px']}
                  height={55}
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  language={props.language}
                  label={renderLabel.bind(this, )}
                  tick={false}
                />)
                : false}

              <YAxis
                tickLine={false}
                fontSize={fontSize}
                fontWeight={600}
                stroke="#000000"
                axisLine={false}
                domain={[0, 100]}
                tickFormatter={(val) => (`${val}%`)}
                width={yAxixWidth}
                interval={0}
                tick={customYTick}
                ticks={[0, 50, 100]}
              />
              <Legend
                title={title}
                align="left"
                verticalAlign="top"
                height={+legendHeight}
                content={renderLegend.bind(this, colors, props.keyTitle)}
                wrapperStyle={{ top: '0px' }}
              />

              <CartesianGrid vertical={false} />

              {bars(keys)}
            </ComposedChart>
        </ResponsiveContainer>
      );
  }
};

export default withLanguage(FeedbackChartThemeDistribution);

FeedbackChartThemeDistribution.defaultProps = {
    animate: false,
    title: '',
    units: ' (m)',
    xLegend: false,
    height: 400,
    primary: true,
    barGap: 0,
    legendHeight: 40,
    keys: [
        { key: 'da', value: 'A' },
        { key: 'db', value: 'B' },
        { key: 'dc', value: 'C' },
        { key: 'dd', value: 'D' },
        { key: 'de', value: 'E' },
        { key: 'df', value: 'F' },
        { key: 'dg', value: 'G' },
        { key: 'dh', value: 'H' },
    ],
};
