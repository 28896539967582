import { connect } from 'react-redux';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';

import { changeTab } from 'actions/UtilityActions';
import { tabIds, roadshowContactTabs } from 'constants/tabIds';

import * as modalIds from 'constants/modalIds';
import withModals from 'shared/hoc/withModals';

import { getRoadShowSummaryOnline } from 'selectors';

import CombinedContacts from './index';

export default compose(
    withTranslate(
        translate => ({
            btnText: translate('roadshow.online_roadshow', 'Online Roadshow'),
        })
    ),
    connect(
        state => {
            const isOnlineRoadshowAvailable = getRoadShowSummaryOnline(state) && getRoadShowSummaryOnline(state).url;
            return ({
                isBtnDisabled: !isOnlineRoadshowAvailable,
                visibleTab: roadshowContactTabs.ONLINE,
            })
        },
        dispatch => ({
            changeTab: () => dispatch(changeTab(tabIds.ROADSHOW_CONTACT_TABS, roadshowContactTabs.ONLINE))  
        })
    ),
    withModals(
        selection => ({
            modalId: modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_1,
            isModalOpen: selection(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_1),
        }),
        (close, open, props) => ({
            openModal: () => {
                props.changeTab(); // to default
                open(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_1);
            },
            closeModal: () => close(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_1),
        }),
    ),
    withAnalytics2(
        (ev, events) => ({
            adobeOpenModalEvent: () => ev(events.ROADSHOW_ONLINE_BUTTON),
        }),
    ),
)(CombinedContacts);