import { buffers, channel } from 'redux-saga';
import { cancel, fork, takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import IsvTypes from 'Isv/actions/IsvTypes';
import { create } from 'Isv/services/isvApi';
import { setUploadProgress,handleOpenUploadOverlay, getReferenceDataForCurrentDeal, handleReferenceInformationDataForCurrentDeal } from 'Isv/actions/IsvDocumentsAction';
import { setSnackBar } from 'Isv/actions/SnackBarAction';
import { isIsvRRTeamUser } from "selectors";
import { getIsvCurrentDealId } from "Isv/selectors";

const downloadFileChannel = channel(buffers.expanding())

export default (api, dispatch, getState) => {
    function* worker(action) {
        switch (action.type) {
            case IsvTypes.API_REQUEST_UPLOAD_REFERENCE_DOCUMENT: {
                const currentDealId = yield select(getIsvCurrentDealId);
                const isRrUser = yield select(isIsvRRTeamUser);
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.postFileData();

                function* onProgress(progressEvent) {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)
                    yield put(dispatch(setUploadProgress(percent)))
                }
                const response = !action.isTitleAlreadyExist ? yield call(apiType.req, action.endPoint, action.data, (progress) => downloadFileChannel.put(onProgress(progress).next())):null
                if (response && response.ok) {
                    yield put(handleOpenUploadOverlay(false, null, null, false, true));
                    yield put(dispatch(getReferenceDataForCurrentDeal(currentDealId, isRrUser)));
                }
                else {
                    yield put(setSnackBar(action.isTitleAlreadyExist? 'File with same title already exist for this deal, please upload a new reference document or re-upload file with a different title.': response.data))
                    yield put(handleOpenUploadOverlay(false, null, null, false, true))
                }
                break;
            }

            case IsvTypes.API_REQUEST_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL:{
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.getData();
                const response = yield call(apiType.req, action.endPoint, action.params);
                if (response.ok) {
                  yield put(handleReferenceInformationDataForCurrentDeal(response.data));
                }
                break;
            }

            case IsvTypes.API_REQUEST_DELETE_REFERENCE_DOCUMENTS: {
                const currentDealId = yield select(getIsvCurrentDealId);
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.postFileData();
                const isRrUser = yield select(isIsvRRTeamUser);
                const response = yield call(apiType.req, action.endPoint)
                if (response.ok) {
                    const requestIsvDealAction = getReferenceDataForCurrentDeal(currentDealId, isRrUser);
                    const referenceData = yield call(api.getData().req, requestIsvDealAction.endPoint, requestIsvDealAction.params, requestIsvDealAction.onSuccess)
                    yield put(handleReferenceInformationDataForCurrentDeal(referenceData.data));
                } else {
                    yield put(setSnackBar(response.data))
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            IsvTypes.API_REQUEST_UPLOAD_REFERENCE_DOCUMENT,
            IsvTypes.API_REQUEST_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL,
            IsvTypes.API_REQUEST_DELETE_REFERENCE_DOCUMENTS
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, IsvTypes.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};