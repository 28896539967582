import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Popup, { Divider } from 'shared/components/Popup';
import Scrollable from 'shared/components/Scrollable';

import { isDCMDealType } from 'selectors';

import Header from './Header';
import Themes from './Themes';
import DCMMultipleTranche from './dcm/MultipleTranche';
import ECMSingleTranche from './ecm/SingleTranche';
import Comment from './Comment';

const EnhancedDivider = styled(Divider)`
    display: block;
    margin: 20px 0 0 0;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;

    ${Divider}:not(${EnhancedDivider}) {
        display: block;
        margin: 1rem 0;
    }
`;

const Tranches = styled.div`
    > div {
        display: inline-block;
    }
`;

const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 0;
`;

const scrollableInlineStyles = {
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
};

class InvestorFeedbackModal extends React.PureComponent {
  render() {
    return (
        <Popup
            static
            width={1250}
            primaryButton
            primaryButtonContent="Close"
            primaryButtonKey="others.close"
            primaryButtonAction={this.props.closeModal}
        >
            <ModalContent>
                <Header details={this.props.details} />
            
                <EnhancedDivider />

                <Scrollable
                    scrollHeight={ window.innerHeight * .7 }
                    style={scrollableInlineStyles}
                > 
                    <ScrollableContent>
                        {
                            (
                                this.props.details.themes.positive.length > 0 ||
                                this.props.details.themes.negative.length > 0 ||
                                this.props.details.themes.valuation.length > 0
                            ) && (
                                <React.Fragment>
                                    <Themes themes={this.props.details.themes} />
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                        <Tranches>
                            {/* DCM Multiple tranches */
                                this.props.isDCM && (
                                    <DCMMultipleTranche feedbackTranchePriceDemand={this.props.details.feedbackTranchePriceDemand} />
                                )
                            }
                            {/* ECM Single Tranche */
                                !this.props.isDCM && (
                                    <ECMSingleTranche feedbackTranchePriceDemand={this.props.details.feedbackTranchePriceDemand}/>
                                )
                            }
                            
                            <Divider />
                        </Tranches>

                        {/* All Tranche types - ECM & DCM */}
                        <Comment comment={this.props.details.comment} />
                    </ScrollableContent>
                </Scrollable>
            </ModalContent>
        </Popup>
    );
  }
}

export default connect(
  state => ({
    isDCM: isDCMDealType(state),
  }),
)(InvestorFeedbackModal);
