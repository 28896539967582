import dealFeatures from 'constants/dealFeatures';
import isvNavLinks from 'Isv/constants/isvNavLinks';
import { events } from 'constants/analytics';

export const mapLinkToKey = link => {
  switch (link) {
    case dealFeatures.PRICING: 
      return 'home_page.pricing';
    case dealFeatures.INVESTORS: 
      return 'home_page.investors';
    case dealFeatures.ROADSHOW: 
      return 'home_page.roadshow';
    case dealFeatures.ORDERBOOK: 
      return 'home_page.orders';
    case dealFeatures.MY_DOCS: 
      return 'home_page.my_docs';
    case dealFeatures.INVESTOR_FEEDBACK: 
      return 'home_page.investor_feedback';
    case '/':
      return 'home_page.mydeal';
    default:
      return '';
  }
}

export const mapIsvLinkToKey = link => {
  switch (link) {
    case isvNavLinks.DASHBOARD:
      return 'home_page.dashboard';
    case isvNavLinks.DEAL_INFORMATION:
      return 'home_page.deal_information';
    case isvNavLinks.REFERENCE_INFORMATION:
      return 'home_page.reference_information';
    case '/':
      return 'home_page.dashboard';
    default:
      return '';  
  }
}

export const mapLinkToAnalyticEvent = link => {
  switch (link) {
    case '/':
      return events.NAVIGATION_MY_DEAL_BUTTON;
    case dealFeatures.PRICING: 
      return events.NAVIGATION_PRICING_BUTTON;
    case dealFeatures.INVESTORS: 
      return events.NAVIGATION_INVESTORS_BUTTON;
    case dealFeatures.ROADSHOW: 
      return events.NAVIGATION_ROADSHOW_BUTTON;
    case dealFeatures.ORDERBOOK: 
      return events.NAVIGATION_ORDERS_BUTTON;
    case dealFeatures.MY_DOCS: 
      return events.NAVIGATION_MY_DOCS_BUTTON;
    case dealFeatures.INVESTOR_FEEDBACK: 
      return events.NAVIGATION_INVESTOR_FEEDBACK_BUTTON;
    default:
      return {};
  }
}
