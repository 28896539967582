import { delay } from 'redux-saga';
import { take, call, put, takeEvery, fork, select } from 'redux-saga/effects';

import { isLoggedInError } from 'selectors';

import Types from 'actions/Types';

import {
  requestPreAuthErrLogPost,
  requestAuthTokenPost,
  requestExtAuthTokenPost,
  loginFormValid,
  loginFormInvalid,
  setPendingAuthentication,
  setAuthenticationResponse,
  setUsername,
  setAuthType,
  setLoginModal,
  setLoginError
} from 'actions/LoginActions';

import {
  verifyUsername,
  verifyTotpCode,
  pushNotification,
  cancelPushNotification
} from '@auth-framework/cmb-hsbcnet-login-service-api';

import { LAAS_API_METHODS, APP_NAME, AUTH_TYPES } from 'constants/laasConfig';

export default (api) => {
    function fetchTotpJsLaasApi(p) {
      return verifyTotpCode(p)
        .then(
          res => ({ res })
        )
        .catch(
          error => ({ error })
        )
    }

    function fetchPnJsLaasApi() {
      return pushNotification()
        .then(
          res => ({ res })
        )
        .catch(
          error => ({ error })
        )
    }

    function cancelPnOnLaasApi() {
      return cancelPushNotification();
    }

    function fetchUserLaasApi(u) {
      return verifyUsername(u, {
          env: window.__LaaSEnv,
          appName: APP_NAME,
        })
        .then(
          res => ({ res })
        )
      .catch(
        error => ({ error })
      )
    }

    function setErrorResponse( {errorData} ) {
      return (
        errorData === null ?
        'null' :
        'message ' + errorData.getMessage() + ' errorCode '  + errorData.getErrorCode()
      )
    }

    function replaceSpecialChars(str) {
      return (
        str === null ?
        null : 
        str.replace(/[^a-zA-Z0-9_ ]/g, "")
      )
    }

    function* handleTotpSubmit( {userNameField, passwordField, aT} ) {
      const isError = yield select(isLoggedInError);

      if(userNameField && passwordField && aT) {
        yield put(setPendingAuthentication(true));

        if(isError) {
          yield delay(((Math.random() * 10) + 1) * 1000);
          yield put(setLoginModal(true));
        } else {
          const { res, error }  = yield call(fetchTotpJsLaasApi, passwordField);

          if(res) {
            yield put(requestExtAuthTokenPost(userNameField, res.getInternalData().json_data.laas_token, aT));
            yield take(Types.LOGIN_FAILURE);
            yield put(setLoginModal(true));
          } else {
            yield put(requestPreAuthErrLogPost(userNameField, LAAS_API_METHODS.VERIFY_TOTP_CODE, replaceSpecialChars(setErrorResponse(error)), replaceSpecialChars(error.errorCode), replaceSpecialChars(error.errorDescription) ));
            yield delay(2000);
            yield put(setLoginModal(true));
          }
        }

        yield put(setPendingAuthentication(false));
      }
    }

    function* handlePnSubmit( userNameField, aT ) {
      if(userNameField && aT) {
        const isError = yield select(isLoggedInError);

        if(isError) {
          yield delay(((Math.random() * 10) + 1) * 1000);
          yield put(setLoginModal(true));
        } else {
          const { res, error }  = yield call(fetchPnJsLaasApi);
          if(res) {
            yield put(requestExtAuthTokenPost(userNameField, res.getInternalData().json_data.laas_token, aT));
            yield take(Types.LOGIN_FAILURE);
            yield put(setLoginModal(true));
          } else {
            const isAnyError = yield select(isLoggedInError);
            if(isAnyError) {
              yield put(requestPreAuthErrLogPost(userNameField, LAAS_API_METHODS.PUSH_NOTIFICATION, replaceSpecialChars(setErrorResponse(error)), replaceSpecialChars(error.errorCode), replaceSpecialChars(error.errorDescription)));
              yield put(setLoginModal(true));
            }
          }
        }
      }
    }

    function* handleCancelPnSubmit() {
      yield put(setLoginError(false));
      yield call(cancelPnOnLaasApi);
    }

    function* handleSubmit(action) {
      if(action.userNameField && action.passwordField) {
        yield put(setPendingAuthentication(true));
        yield put(requestAuthTokenPost(action.userNameField, action.passwordField));
        yield take(Types.LOGIN_FAILURE);  // We need to elaborate here to handle different responses
        // Could maybe use response.message
        yield put(setAuthenticationResponse('Unable to login'));
        yield put(setPendingAuthentication(false));
      }
    }

    function* handleExtSubmit({userNameField, authTypeField}) {
      if(userNameField && authTypeField) {
        yield put(setPendingAuthentication(true));

        const { res, error }  = yield call(fetchUserLaasApi, userNameField);

        if(error) {
          yield put(setLoginError(true));
          yield put(requestPreAuthErrLogPost(userNameField, LAAS_API_METHODS.VERIFY_USERNAME, replaceSpecialChars(setErrorResponse(error)), replaceSpecialChars(error.errorCode), replaceSpecialChars(error.errorDescription) ));
        } else {
          const {
            authenticationTypes
          } = res;
  
          if(authenticationTypes) {
            yield put(setUsername(userNameField));
            yield put(setAuthType(authTypeField));
          } else {
            yield put(setLoginError(true));
          }
        }

        const isError = yield select(isLoggedInError);
        if( authTypeField === AUTH_TYPES.MOBILE_APPROVE || (isError) ) {
          yield call(handlePnSubmit, userNameField, AUTH_TYPES.MOBILE_APPROVE);
        }

        yield put(setPendingAuthentication(false));
      }
    }

    function* handleChange(action) {
      if (!action.fieldValue) {
        yield put(loginFormInvalid(action.fieldName));
      } else {
        yield put(loginFormValid(action.fieldName));
      }
    }

    function* handleFailure(action) {
      yield put(setPendingAuthentication(false));
    }

    function* watcher() {
      yield fork(takeEvery, Types.LOGIN_FAILURE, handleFailure);
      yield fork(takeEvery, Types.LOGIN_FORM_SUBMIT, handleSubmit);
      yield fork(takeEvery, Types.LOGIN_FORM_EXT_SUBMIT, handleExtSubmit);
      yield fork(takeEvery, Types.LOGIN_FORM_PN_SUBMIT, handlePnSubmit);
      yield fork(takeEvery, Types.CANCEL_LOGIN_FORM_PN_SUBMIT, handleCancelPnSubmit);
      yield fork(takeEvery, Types.LOGIN_FORM_TOTP_SUBMIT, handleTotpSubmit);
      yield fork(takeEvery, Types.LOGIN_FORM_CHANGE, handleChange);
    }

    return {
        watcher,
        handleSubmit,
        handleExtSubmit,
        handlePnSubmit,
        handleTotpSubmit,
        handleChange,
    };
};
