import { put, call, fork, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga'
import Types from 'actions/Types';

import { requestManageDevice, deactivateDevice } from 'actions/UtilityActions';
import { setManageDevices, clearDeviceDeactivationMessages, setDeviceDeactivationMessages } from 'actions/LoginActions';
import { viewDeviceBiometrics } from '../../actions/LoginActions';

export default (api, dispatch, getState) => {
    function* viewBiometricsWorker( ) {
        const action = requestManageDevice();

        const apiType = api.getData();
        const response = yield call(apiType.req, action.endPoint);

        yield put(setManageDevices(response.data.data));
    }

    function* deactivateBiometricsWorker({ deviceId }) {
        const action = deactivateDevice(deviceId);

        const apiType = api.patchData();
        const response = yield call(apiType.req, action.endPoint);

        if(response.status !== 204) {
            yield put(setDeviceDeactivationMessages("ERROR"));
        } else {
            yield put(viewDeviceBiometrics());
            yield put(setDeviceDeactivationMessages("INFO"));
        }

        yield delay(5000);
        yield put(clearDeviceDeactivationMessages());
        
    }

    function* watcher() {
        yield fork(takeEvery, Types.VIEW_DEVICE_BIOMETRICS, viewBiometricsWorker);
        yield fork(takeEvery, Types.DEACTIVE_BIOMETRICS_DEVICE, deactivateBiometricsWorker);
    }

    return {
        watcher,
        viewBiometricsWorker,
        deactivateBiometricsWorker,
    };
};
