import React from 'react';
import { connect } from 'react-redux';
import { renewToken, logOff } from '../actions/HeartBeatActions';
import AlertModal from './AlertModal';
import * as modalIds from 'constants/modalIds';
import PopupSystem from 'shared/components/PopupSystem';

class SessionTimer extends React.PureComponent {
  render() {
    return (
      <PopupSystem
        modalID={modalIds.CONTINUE_SESSION_MODAL_ID}
        isModalOpen={this.props.checkingPulse}
        centered
        backgroundLayer
        zIndex={3001}
        closeModal={() => null}
        clickableElement={null}
        content={
          <AlertModal
            pulseMessage={this.props.pulseMessage}
            toggleModalLogout={this.props.logOff}
            toggleModalContinue={this.props.renewToken}
            showModal={this.props.checkingPulse}
            expire={this.props.pulseExpire}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  checkingPulse: state.login.checkingPulse,
  pulseExpire: state.login.pulseExpire,
  pulseMessage: state.login.pulseMessage,
});

const mapDispatchToProps = dispatch => ({
  renewToken: () => dispatch(renewToken()),
  logOff: () => dispatch(logOff()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimer);
