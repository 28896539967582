import React from 'react';

import { translationString }from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

import close from '../../images/Close X/Modal.png';
import closeX from '../../images/Close X/Regular.png';
import close2X from '../../images/Close X/Regular@2x.png';

import logo from '../../images/HSBC_Logo.svg';

import cardOn from '../../images/Table-card Toggle/Card Dark.svg';
import cardOff from '../../images/Table-card Toggle/Card Light.svg';

import tableOn from '../../images/Table-card Toggle/Table Dark.svg';
import tableOff from '../../images/Table-card Toggle/Table Light.svg';

import CheckMarkOn from '../../images/Checkmark/Dark Blue.svg';
import CheckMarkOff from '../../images/Checkmark/Light Gray.svg';

import dealSwitch from '../../images/deal-switch.svg'
import Doc from '../../images/doc-download.svg';
import Upload from "Isv/images/upload.png";

const Icon = (props) => {
    let icon;
    let isIcon = true;
    let alt;
    switch (props.iconName) {
        // ───────────────────────────────── traditional Icons ───────────────────────────────────────────────
        case "close":
            alt = translationString('', props.language, 'Close');
            isIcon = false; // show img element
            icon = close;
            break
        case "closeX":
        alt = translationString('', props.language, 'Close');
            isIcon = false; // show img element
            icon = closeX;
            break
        case "close2X":
            alt = translationString('', props.language, 'Close');
            isIcon = false; // show img element
            icon = close2X;
            break
        case "logoHeader":
            alt = translationString('', props.language, 'Logo header');
            isIcon = false; // show img element
            icon = logo;
            break
        case "cardViewOn":
            alt = translationString('', props.language, 'Card view on');
            isIcon = false; // show img element
            icon = cardOn;
            break
        case "tableViewOn":
            alt = translationString('', props.language, 'Table view on');
            isIcon = false; // show img element
            icon = tableOn;
            break
        case "cardViewOff":
            alt = translationString('', props.language, 'Card view off');
            isIcon = false; // show img element
            icon = cardOff;
            break
        case "tableViewOff":
            alt = translationString('', props.language, 'Table view off');
            isIcon = false; // show img element
            icon = tableOff;
            break
        case "CheckMarkOn":
            alt = translationString('', props.language, 'Check mark on');
            isIcon = false; // show img element
            icon = CheckMarkOn;
            break
        case "CheckMarkOff":
            alt = translationString('', props.language, 'Check mark off');
            isIcon = false; // show img element
            icon = CheckMarkOff;
            break
        case "dealSwitch":
            alt = translationString('', props.language, 'Deal Switch');
            isIcon = false;
            icon = dealSwitch;
            break
        case "DocumentUpload":
            alt = translationString('', props.language, 'Document');
            isIcon = false;
            icon = Doc;
            break
            case "Upload":
            alt = translationString('', props.language, 'Upload');
            isIcon = false;
            icon = Upload;
            break
            
        // ───────────────────────────────── Font Icons ───────────────────────────────────────────────
        case "Checkmark":
            alt = translationString('', props.language, 'Check mark');
            icon = "Checkmark"
            break
        case "Train":
            alt = translationString('', props.language, 'Train');
            icon = "Train"
            break
        case "Car":
            alt = translationString('', props.language, 'Car');
            icon = "Car"
            break
        case "Hotel":
            alt = translationString('', props.language, 'Hotel ');
            icon = "Hotel"
            break
        case "Star":
            alt = translationString('', props.language, 'Star');
            icon = "Star"
            break
        case "Search":
            alt = translationString('', props.language, 'Search');
            icon = "Search"
            break
        case "Profile":
            alt = translationString('', props.language, 'Profile');
            icon = "Profile"
            break
        case "Historical":
            alt = translationString('', props.language, 'Historical');
            icon = "Historical"
            break
        case "Document":
            alt = translationString('', props.language, 'Document');
            icon = "Document"
            break
        case "Desktop":
            alt = translationString('', props.language, 'Desktop');
            icon = "Desktop"
            break
        case "CommentIndicator":
            alt = translationString('', props.language, 'Comment Indicator');
            icon = "CommentIndicator"
            break
        case "ChatBubble":
            alt = translationString('', props.language, 'Chat bubble');
            icon = "ChatBubble"
            break
        case "Binoculars":
            alt = translationString('', props.language, 'Binoculars');
            icon = "Binoculars"
            break
        case "CaretUp":
            alt = translationString('', props.language, 'Caret up image');
            icon = "CaretUp"
            break
        case "CaretDown":
            alt = translationString('', props.language, 'Caret down image');
            icon = "CaretDown"
            break
        case "Notebook":
            alt = translationString('', props.language, 'Notebook');
            icon = "Notebook"
            break
        case "InvestorsTie":
            alt = translationString('', props.language, 'Investors tie');
            icon = "InvestorsTie"
            break
        case "Orders":
            alt = translationString('', props.language, 'Orders');
            icon = "Orders";
            break
        case "PrevArrow":
            alt = translationString('', props.language, 'Previous arrow');
            icon = "PrevArrow";
            break
        case "NextArrow":
            alt = translationString('', props.language, 'Next arrow');
            icon = "NextArrow";
            break
        case "OrderbookHome":
            alt = translationString('', props.language, 'Orderbook');
            icon = "OrderbookHome";
            break
        case "DocumentHome":
            alt = translationString('', props.language, 'Document');
            icon = "DocumentHome";
            break
        case "Chevron":
            alt = translationString('', props.language, 'Chevron image');
            icon = "Chevron";
            break

        // Glyphter - IcoMoon did not interpret these icons properly
        // ClassNames for these icons are prefixed 'icon-g'.  This is why the icon names below are things like 'gPlane' and not 'Plane'
        case "Plane":
            alt = translationString('', props.language, 'Plane');
            icon = "gPlane"
            break
        case "Price":
            alt = translationString('', props.language, 'Price');
            icon = "gPrice";
            break
        default:
            alt = translationString('', props.language, 'Plane');
            icon = "Plane";
            break
    }

    const other = {};

    if (props["aria-hidden"]) {
        other["aria-hidden"] = true;
    }

    other["aria-checked"] = props["aria-checked"];

    if (isIcon) {
        return (
            <span
                aria-hidden
                aria-label={props.alt || alt || 'Icon'}
                style={props.style}
                className={`icon-${icon} ${props.className}`}
                {...other}
            />
        )

    } else {
        // eslint-disable-next-line
        return (
            <img
                className={props.className}
                src={icon}
                style={props.style}
                alt={props.alt || alt || 'Image'}
                {...other}
            />
        )

    }


}

export default withLanguage(Icon);
