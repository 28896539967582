import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import { compose } from "recompose";
import styled from "styled-components";

import * as modalIds from "constants/modalIds";
import { ALL_INVESTORS, ALL_TRANCHES, DEFAULT_TIME_INTERVAL, DEFAULT_ALL } from "constants/orders";
import {
  updateCurrentTranche,
  openOrderSummaryDetailView,
  resetOrderBookDropDowns,
  filterBookEvolutionSummary,
  filterBookEvolutionSummaryForAmendedOrderByInvestor,
  requestStages,
} from "actions/OrderBookActions";
import {
  getTrancheTitles,
  getCurrentTranche,
  getBookEvolutionData,
  getBookEvolutionDataForAmendedOrderByInvestor,
  getSelectedDate,
  getTrancheLists,
  getAmendedOrderSummaryData,
  getInvestorNames,
  getSelectedInvestor,
  getSelectedInvestorType,
  getSelectedTimeRange,
  getSelectedTranche,
  getStages
} from "selectors";

import TranslationText, {
  translationString,
} from "translations/TranslationText";
import withLanguage from "translations/withLanguage";
import withTranslate from "shared/hoc/withTranslate";

import OrderbookHeader from "../../shared/summary/OrderbookHeader";
// analytics
import { featurePageIds } from "constants/featurePages";
import withAnalytics from "shared/hoc/withAnalytics";

// tabs
import { tabIds, allTranchesBookEvolutionSubTabs } from "constants/tabIds";
import HorizontalTab from "shared/accessibility/components/HorizontalTabs/HorizontalTab";
import HorizontalTabs from "shared/accessibility/components/HorizontalTabs";

import OrderSummaryDetailView from "../OrderSummary/OrderSummaryDetailView";
import OrderSummaryTable from "../OrderSummary/OrderSummaryTable";
import AmendedOrderInvestorTable from "../BookEvolution/AmendedOrderInvestorTable";
import OrderSummaryDropdownList from "../OrderSummary/OrderSummaryDropdownList";
import { TileContentInner, EmptyContent } from "Landing/dcm/Tiles/styles.js";
import OrderSummaryLineChart from "../OrderSummary/OrderSummaryLineChart";
import { dateFormatter } from "../../SharedTableComponents/formatters";
import Loading from "shared/components/Loading";
import { Sorters } from "mk-pattern-library-react";
import AmendedOrderDropdownFiltersList from "./AmendedOrderDropdownFiltersList";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import { DialogContent } from "@material-ui/core";
import FullScreenIcon from '@mui/icons-material/FitScreen';
import FullScreenTip from '@mui/material/Tooltip';
import { Box } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ButtonRight = styled.div`
float: right;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const DropDownPicker = styled.div`
  margin: 0px 0px 30px 0px;
`;

const CumulativeTextWrapper = styled.div`
  position: absolute;
  top: 10px;
  color: #000;
  right: 10px;
  font-weight: 600;
`;

const NoteTextWrapper = styled.div`
  color: #000;
  right: 10px;
  font-weight: 600;
  margin-top: 15px;
`;
const BookEvolutionContainer = (props) => {
  const [amendedOrderSummaryData, setAmendedOrderSummaryData] = useState([]);
  const [amendedInvestorList, setAmendedInvestorList] = useState([]);
  const [showAmendedOrderInvestorFilters, setShowAmendedOrderInvestorFilters] =
    useState(false);
  const [isShowStagesChecked, setIsShowStagesChecked] = useState(false);

  useEffect(() => {
    props.viewChange();
  }, []);

  useEffect(() => {
    getUpdatedStructuredOrders();
  }, [props.amendedOrderSummaryData]);

  useEffect(() => {
    getFilteredStructuredOrders();
  }, [
    amendedInvestorList.length,
    props.selectedInvestor,
    props.selectedTranche,
    amendedOrderSummaryData,
  ]);

  useEffect(() => {
    props.resetOrderBookDropDowns();
    if (showAmendedOrderInvestorFilters) {
      let tranche = props.trancheNameList[0] ? props.trancheNameList[0].key : 0;
      props.requestStages(props.dealId);
      props.filterBookEvolutionSummaryForAmendedOrderByInvestor(
        props.dealId,
        15,
        0,
        tranche,
        true
      ) 
    } else props.filterBookEvolutionSummary(props.dealId, DEFAULT_TIME_INTERVAL.key, ALL_INVESTORS.key, ALL_TRANCHES.key)
  }, [showAmendedOrderInvestorFilters]);

  const handleTrancheNavClick = (selectedTranche) => {
    props.updateCurrentTranche(selectedTranche.id);
  };

  const getFormattedOrdersFromBookEvolution = (arrayToProcess) => {
    let arrayToReturn = arrayToProcess
      .map((order) => order.orders)
      .reduce((acc, val) => acc.concat(val), []);

    if (showAmendedOrderInvestorFilters) {
      const summaryDates = getSummaryDates(
        true,
        props.dataForAmendedOrderByInvestor.orderSummary
      );
      arrayToReturn = arrayToReturn.filter((order) => {
        let tranchNameToCompare =
          props.selectedTranche.value === DEFAULT_ALL.value
            ? props.trancheNameList[0].value
            : props.selectedTranche.value;
        let dateToCompare =
          props.selectedDate.value === DEFAULT_ALL.value
            ? summaryDates[0].value
            : props.selectedDate.value;

        return (
          order.trancheName === tranchNameToCompare &&
          dateFormatter("localDate", order.dateEntered) === dateToCompare
        );
      });
    }

    if (props.selectedInvestorType.value !== "ALL") {
      arrayToReturn = getAmendedOrderByInvestorByInvestorType(
        arrayToReturn,
        props.selectedInvestorType.value
      );
    }
    return arrayToReturn;
  };

  const getUpdatedStructuredOrders = () => {
    const amendedResultData = JSON.parse(
      JSON.stringify(props.amendedOrderSummaryData)
    );
    // To Destructure the API response
    const structuredIndividualOrders = amendedResultData
      .map((item) => getFormattedOrdersFromBookEvolution(item.individualOrders))
      .reduce((acc, val) => acc.concat(val), []);

    // Trimming the Extra Spaces for Investor Names
    structuredIndividualOrders.forEach((item) => {
      item.investorLongName = item.investorLongName.trim();
    });
    // Getting All Unique Amended Investor Name List from Destructure API Response
    const amendedInvestorList = [
      ...new Map(
        structuredIndividualOrders.map((item) => [
          item["investorLongName"],
          item,
        ])
      ).values(),
    ]
      .sort((a, b) =>
        a.investorLongName.trim() < b.investorLongName.trim() ? -1 : 1
      )
      .map((item) => {
        return {
          key: item.investorID,
          value: item.investorLongName,
          label: item.investorLongName,
        };
      });

    setAmendedOrderSummaryData(structuredIndividualOrders);
    setAmendedInvestorList(amendedInvestorList);
  };

  const getFilterdData = (
    data,
    structuredIndividualOrders,
    index,
    nextIndex
  ) => {
    if (nextIndex !== 0 && index < nextIndex) {
      if (
        structuredIndividualOrders[index].dateEntered ===
          structuredIndividualOrders[nextIndex].dateEntered &&
        structuredIndividualOrders[index].dateTimeInterval !==
          structuredIndividualOrders[nextIndex].dateTimeInterval
      ) {
        data[nextIndex].dateEntered = "";
        data[nextIndex].dateTimeInterval = data[nextIndex].timeEntered;
      }
    }
    return data[index];
  };

  const getFilteredStructuredOrders = () => {
    const orderSummary = [];
    const sortedOrderSummary = [];
    let filteredData = [];
    let filteredTrancheData = [];

    const isIntialInvestor = _.isEqual(props.selectedInvestor, ALL_INVESTORS); // Check Wheather Default Investor Name is Selected
    const isIntialTranche = _.isEqual(props.selectedTranche, ALL_TRANCHES); // Check Wheather Default Tranche Name is Selected

    // Filter the Data as per Investor Names
    filteredData = amendedOrderSummaryData.filter(
      (order) =>
        order.investorLongName ===
        (isIntialInvestor
          ? amendedInvestorList[0].value
          : props.selectedInvestor.value)
    );

    // Filter the Data as per Tranche Names
    !isIntialTranche
      ? (filteredTrancheData = filteredData.filter(
          (order) => order.trancheName === props.selectedTranche.value
        ))
      : (filteredTrancheData = filteredData);

    // Formatting the Existing Received Date to Date-Month
    filteredTrancheData.forEach((item) => {
      item[item.orderId] = item.orderAmount;
      item.dateTimeInterval =
        dateFormatter("dateMonth", item.externalSourceLastModified) +
        " " +
        item.timeEntered;
    });

    // Sort the Orders Ascending (Date)
    filteredTrancheData.sort((a, b) =>
      Sorters.doubleSort(
        "dateEntered",
        a,
        b,
        "asc",
        "externalSourceLastModified"
      )
    );

    const sameData = JSON.parse(JSON.stringify(filteredTrancheData));

    // If next order has same date then display only time entry, remove the date.
    filteredTrancheData.forEach((order, index) => {
      if (filteredTrancheData.length > 1) {
        if (index !== filteredTrancheData.length - 1) {
          order = getFilterdData(
            sameData,
            filteredTrancheData,
            index,
            index + 1
          );
        } else {
          order = getFilterdData(
            sameData,
            filteredTrancheData,
            index,
            index - 1
          );
        }
        orderSummary.push(order);
      } else orderSummary.push(order);
    });

    const orderIdList = orderSummary.map((item) => {
      return {
        orderId: item.orderId,
        trancheName: item.trancheName,
      };
    });

    // Separate all Unique IDs from order list for Table Header Columns
    const amendedOrderIdList = [
      ...new Map(orderIdList.map((item) => [item["orderId"], item])).values(),
    ].sort((a, b) => (a.trancheName < b.trancheName ? -1 : 1));

    orderSummary.forEach((item) => {
      const obj = {};
      amendedOrderIdList.forEach((subItem) => {
        obj[subItem.orderId] =
          subItem.orderId === item.orderId ? item.orderAmount : null;
        obj["dateEntered"] = item.dateEntered;
        obj["timeEntered"] = item.timeEntered;
        obj["dateTimeInterval"] = item.dateTimeInterval;
      });
      sortedOrderSummary.push(obj);
    });
  };

  const getSummaryDates = (isOrderSummaryEnable, dataForDates) => {
    const orderDateSummary = [];
    if (isOrderSummaryEnable) {
      const data = { ...dataForDates };
      _.entries(data).map((item) => {
        orderDateSummary.push({
          value: dateFormatter("localDate", item[0]),
          label: dateFormatter("localDate", item[0]),
        });
        return null;
      });
    }
    return orderDateSummary.filter(
      (array, index, value) => value.indexOf(array) === index
    );
  };

  const getAmendedOrderByInvestorByInvestorType = (list) => {
    let arrayToReturn = [];
    list.forEach((element) => {
      if (element.investorType === props.selectedInvestorType.value) {
        arrayToReturn.push(element);
      }
    });
    return arrayToReturn;
  };

  const getDateFilterdSummaryData = (
    orderList,
    orderSummary,
    showCumulativeRow
  ) => {
    const getOrderSummaryByDate = _.entries(orderList).find((order) => {
      return dateFormatter("localDate", order[0]) === props.selectedDate.value;
    });
    const orderSummaryListByDate =
      getOrderSummaryByDate &&
      getOrderSummaryByDate.length &&
      getOrderSummaryByDate[1].filter(
        (order) => order.timeInterval !== "Total"
      );

    if (
      orderSummaryListByDate &&
      orderSummaryListByDate.length &&
      showCumulativeRow
    ) {
      const BookEvolutionTableKeys = _.keys(
        orderSummary[orderSummary.length - 1]
      );
      let grandTotalRow = {};

      _.each(orderSummaryListByDate, (order) => {
        _.each(BookEvolutionTableKeys, (column) => {
          if (column !== "date") {
            grandTotalRow[column] = order[column];
          } else {
            grandTotalRow[column] = "";
          }
          column === "timeInterval" && (grandTotalRow[column] = "Total");
        });
      });

      orderSummaryListByDate.push(grandTotalRow);
    }
    return orderSummaryListByDate || [];
  };

  const getOrderSummaryList = (data, isTable) => {
    const orderSummary = [];
    const tempOrderSummaryData = JSON.parse(JSON.stringify(data));
    const trancheList = JSON.parse(JSON.stringify(props.trancheNameList));
    _.entries(tempOrderSummaryData).map((item) => {
      item[1].map((col, index) => {
        if (isTable) {
          index === 0
            ? (col.date = dateFormatter("localDate", item[0]))
            : (col.date = "");
        } else {
          col.dateTimeInterval =
            index === 0
              ? dateFormatter("dateMonth", item[0]) +
                " " +
                col.timeInterval.slice(0, 5)
              : col.timeInterval.slice(0, 5);
          trancheList.forEach((item, index) => {
            if (col[item.key] || col[item.key] === 0) {
              col[trancheList[index].value] = col[item.key] || 0;
              delete col[item];
            }
          });
        }
        if (isTable || col.timeInterval !== "Total") orderSummary.push(col);
        return null;
      });
      return null;
    });

    if (props.selectedDate.value !== DEFAULT_ALL.value) {
      return getDateFilterdSummaryData(
        tempOrderSummaryData,
        orderSummary,
        isTable
      );
    }

    return orderSummary;
  };

  // from route we can optionally set the default order table tab
  const active = { id: props.currentTrancheId };
  const currentTrancheData = props.currentTrancheData;

  const isOrderSummaryEnable =
    props.orderSummaryData &&
    _.keys(props.orderSummaryData.orderSummary).length;
  const trancheTitles = props.trancheTitles.map((t) => ({
    ...t,
    btnAriaLabel: translationString(
      "orders.open_tranche_<tranche_name>_<status>",
      props.language,
      `Open tranche ${t.title} ${t.status || ""}`,
      [
        ["<tranche_name>", t.title],
        ["<status>", t.status],
      ]
    ),
  }));

 
  const [open, setOpen] = React.useState(false);
  const[showFilters, setShowFilters] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const toggleFilters = () => {
      setShowFilters(!showFilters);
  };

  const buttonText = showFilters? "Hide Filters": "Show Filters" ;
  
  return (
    <>
      {currentTrancheData ? (
        <>
          <OrderbookHeader
            bookEvolution={true}
            dealType="dcm"
            headerData={currentTrancheData}
            dateReceived={props.dateReceived}
            trancheTitles={trancheTitles}
            trancheClick={handleTrancheNavClick}
            trancheActive={active}
          />
          <>
            {props.loading ? (
              <LoadingContainer>
                <LoadingWrapper>
                  <Loading hasBackground={true} />
                </LoadingWrapper>
              </LoadingContainer>
            ) : (
              <Row>
                <Col md={12} style={{ marginBottom: "20px" }}>
                  {LoadOrderByInvestorFilter()}
                  <div className="tabbed-container relative">
                    <HorizontalTabs
                      tabsId={tabIds.ALL_TRANCHES_BOOK_EVOLUTION_SUB_TABS}
                    >
                      <HorizontalTab
                        tabId={allTranchesBookEvolutionSubTabs.VIEW_CHART}
                        title={props.viewChart}
                        onTabClicked={() => {
                          setShowAmendedOrderInvestorFilters(false);
                        }}
                      >
                        {isOrderSummaryEnable ? (
                          <React.Fragment>
                            <CumulativeTextWrapper>
                              Note: Time interval is not to scale.
                            </CumulativeTextWrapper>
                            <OrderSummaryLineChart
                              lineChartSummary={getOrderSummaryList(
                                props.orderSummaryData.orderSummary,
                                false
                              )}
                              lineChartList={props.trancheNameList}
                              notAvailable={props.notAvailable}
                              xAxisLabel="Date & Time (GMT)"
                              yAxisLabel="Amount (m)"
                              xDy={440}
                              chartLabel="orderSummary"
                              tooltipLabel={props.timeIntervalText}
                              dataKey={"dateTimeInterval"}
                            />
                          </React.Fragment>
                        ) : (
                          <TileContentInner>
                            <EmptyContent>{props.notAvailable}</EmptyContent>
                          </TileContentInner>
                        )}
                      </HorizontalTab>
                      <HorizontalTab
                        tabId={allTranchesBookEvolutionSubTabs.VIEW_TABLE}
                        title={props.viewTable}
                        onTabClicked={() => {
                          setShowAmendedOrderInvestorFilters(false);
                        }}
                      >
                    {isOrderSummaryEnable ? (
                          <React.Fragment>
                            <OrderSummaryTable
                              timeIntervalText={props.timeIntervalText}
                              dateText={props.dateText}
                              trancheLists={props.trancheNameList}
                              orderSummaryList={getOrderSummaryList(
                                props.orderSummaryData.orderSummary,
                                true
                              )}
                              orderSummaryData={props.orderSummaryData}
                              notAvailable={props.notAvailable}
                            />
                          </React.Fragment>
                        ) : (
                          <TileContentInner>
                            <EmptyContent>{props.notAvailable}</EmptyContent>
                          </TileContentInner>
                        )}
                      </HorizontalTab>
                      <HorizontalTab
                        tabId={allTranchesBookEvolutionSubTabs.AMEND_ORDERS}
                        title={props.amendedOrder}
                        onTabClicked={() => {
                          setShowAmendedOrderInvestorFilters(true);
                        }}
                      >
                      <div>
                      <ButtonRight>
                      <FullScreenTip title = 'Full Screen View' placement="bottom">
                        <FullScreenIcon variant="outlined" onClick={handleClickOpen} style = {{marginBottom:'5px'}}/>
                      </FullScreenTip>
                      </ButtonRight>
                      <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                      >
                        <AppBar sx={{ position: 'relative'}} style={{ background: '#253038' }}>
                          <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h8" component="div">
                              Order By Investor
                            </Typography>
                            <Box sx = {{display: 'flex', gap: 7}}>
                            <Button onClick = {toggleFilters} style={{ color: 'white'  }}> {buttonText }</Button>
                             <IconButton
                              edge="start"
                              color="inherit"
                              onClick={handleClose}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                            </Box>
                          </Toolbar>
                        </AppBar>
                        <DialogContent>
                          <div className = "table-fix-head-fullScreen" >
                            {showFilters && (<div>
                                {LoadOrderByInvestorFilter()}
                            </div>)}
                            {LoadOrderByInvestorTable()}
                         </div>
                        </DialogContent>
                      </Dialog>
                      </div>
                        {LoadOrderByInvestorTable()}
                      </HorizontalTab>
                    </HorizontalTabs>
                  </div>
                </Col>
              </Row>
            )}
          </>
          <OrderSummaryDetailView
            modalID={modalIds.BOOK_EVOLUTION_TABLE_ROW_MODAL_ID}
            className="close-modal-icon"
            bookEvolution={true}
          />
        </>
      ) : (
        <TranslationText
          dictionaryKey={"others.content_not_yet_available"}
          defaultText={"Content Not Yet Available"}
        />
      )}
    </>
  );
  function LoadOrderByInvestorFilter () {
      return(
      <>
      {isOrderSummaryEnable || props.isBookEvolutionFiltererd ? (
                    <DropDownPicker>
                      {showAmendedOrderInvestorFilters &&
                      props.dataForAmendedOrderByInvestor ? (
                        <AmendedOrderDropdownFiltersList
                          dealId={props.dealId}
                          investorTypeText={props.investorTypeText}
                          orderSummaryData={props.orderSummaryData}
                          trancheNameText={props.trancheNameText}
                          timeRangeText={props.timeRangeText}
                          allTranchelists={props.trancheNameList}
                          timeIntervalText={props.timeIntervalText}
                          dateText={props.dateText}
                          getDateSummary={[
                            ...getSummaryDates(
                              isOrderSummaryEnable,
                              props.dataForAmendedOrderByInvestor.orderSummary
                            ),
                          ]}
                          selectedDate={props.selectedDate}
                          isBookEvolution={true}
                          orginalOrderbookData={props.orderData}
                          amendedOrderbyInvestorTableData={
                            props.dataForAmendedOrderByInvestor
                              ? getFormattedOrdersFromBookEvolution(
                                  props.dataForAmendedOrderByInvestor
                                    .individualOrders
                                )
                              : []
                          }
                          isShowStagesChecked={isShowStagesChecked}
                          setIsShowStagesChecked={setIsShowStagesChecked}
                        />
                      ) : (
                        <div style={{marginTop:"30px"}}>
                        <OrderSummaryDropdownList
                          dealId={props.dealId}
                          allInvestorLists={[
                            ALL_INVESTORS,
                            ...props.investorNameList,
                          ]}
                          investorNameText={props.investorNameText}
                          orderSummaryData={props.orderSummaryData}
                          trancheNameText={props.trancheNameText}
                          allTranchelists={[...props.trancheNameList]}
                          timeIntervalText={props.timeIntervalText}
                          dateText={props.dateText}
                          getDateSummary={[
                            DEFAULT_ALL,
                            ...getSummaryDates(
                              isOrderSummaryEnable,
                              props.orderSummaryData.orderSummary
                            ),
                          ]}
                          selectedDate={props.selectedDate}
                          isBookEvolution={true}
                         
                        />
                        </div>
                      )}
                    </DropDownPicker>
                   ): null
                }
      </>)
  }
  
  function LoadOrderByInvestorTable(){
    return(
    <>
    {isOrderSummaryEnable &&
      props.dataForAmendedOrderByInvestor && props.dataForAmendedOrderByInvestor.orderdetails ? (
        <React.Fragment>
          <AmendedOrderInvestorTable
            amendedOrderByInvestorSummaryData={
              props.dataForAmendedOrderByInvestor
                ? getFormattedOrdersFromBookEvolution(
                    props.dataForAmendedOrderByInvestor
                      .individualOrders
                  )
                : []
            }
            orderDetails={
              props.dataForAmendedOrderByInvestor.orderdetails
            }
            showFilterForAmendedOrderbyinvestor={
              setShowAmendedOrderInvestorFilters
            }
            intervals={
              props.dataForAmendedOrderByInvestor.interval
            }
            getDateSummary={[
              ...getSummaryDates(
                isOrderSummaryEnable,
                props.dataForAmendedOrderByInvestor
                  .orderSummary
              ),
            ]}
            trancheLists={props.trancheLists}
            dateText={props.dateTimeText}
            timeEntered={props.timeEntered}
            investorNameText={props.investorNameText}
            firstOrderText={props.firstOrderText}
            lastOrderText={props.lastOrderText}
            peakOrderText={props.peakOrderText}
            allocationAmountText={props.allocationAmountText}
            lastMinusPeakText={props.lastMinusPeakText}
            orderAmountText={props.orderAmountText}
            timeIntervalText={props.timeIntervalText}
            statusText={props.statusText}
            orderIdText={props.orderIdText}
            limitSecurityText={props.limitSecurityText}
            selectedTimeRange={props.selectedTimeRange}
            selectedDate={props.selectedDate}
            notAvailable={props.notAvailable}
            openOrderSummaryDetailView={props.openOrderSummaryDetailView}
            isShowStagesChecked={isShowStagesChecked}
            stages={props.stages}
            orderSummaryList={getOrderSummaryList(
            props.orderSummaryData.orderSummary,
            true
          )}
          />
          <NoteTextWrapper>
            Note:- Please note that the frequency of MyDeal’s
            order updates are dependent upon IssueNet
            processing speeds. Rapid order revisions may not
            be captured as a result.
          </NoteTextWrapper>
        </React.Fragment>
      ) : (
        <TileContentInner>
          <EmptyContent>{props.notAvailable}</EmptyContent>
        </TileContentInner>
      )
    }
    </>);
  }
}
const mapStateToProps = (state) => ({
  currentTrancheData: getCurrentTranche(state),
  trancheTitles: getTrancheTitles(state),
  orderSummaryData: getBookEvolutionData(state),
  dataForAmendedOrderByInvestor:
    getBookEvolutionDataForAmendedOrderByInvestor(state),
  orderData: state.orderBookData.orderData,
  currentTrancheId: state.orderBookData.currentTrancheId,
  dealId: state.utility.dealsData.dealId,
  dateReceived: state.orderBookData.dateReceived,
  selectedDate: getSelectedDate(state),
  investorNameList: getInvestorNames(state),
  trancheNameList: getTrancheLists(state),
  loading: state.orderBookData.dataFetching,
  isBookEvolutionFiltererd: state.orderBookData.isBookEvolutionFiltererd,
  amendedOrderSummaryData: getAmendedOrderSummaryData(state),
  selectedInvestor: getSelectedInvestor(state),
  selectedInvestorType: getSelectedInvestorType(state),
  selectedTranche: getSelectedTranche(state),
  selectedTimeRange: getSelectedTimeRange(state),
  stages: getStages(state),
});

const mapDispatchToProps = (dispatch) => ({
  requestStages: (dealId) => dispatch(requestStages(dealId)),
  updateCurrentTranche: (trancheId) =>
    dispatch(updateCurrentTranche(trancheId, "DCM")),
  openOrderSummaryDetailView: (summaryData) =>
    dispatch(openOrderSummaryDetailView(summaryData)),
  resetOrderBookDropDowns: () => dispatch(resetOrderBookDropDowns()),
  filterBookEvolutionSummaryForAmendedOrderByInvestor: (dealId, interval, investorId, trancheName, isFilter) => dispatch(filterBookEvolutionSummaryForAmendedOrderByInvestor(dealId, interval, investorId, trancheName, isFilter)),
  filterBookEvolutionSummary: (dealId, investorId, trancheId, interval, loader) => dispatch(filterBookEvolutionSummary(dealId, investorId, trancheId, interval, loader)),
});

export default compose(
  withLanguage,
  connect(mapStateToProps, mapDispatchToProps),

  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.ORDERBOOK_SUMMARY_PAGE),
  })),
  withTranslate((translate) => ({
    viewChart: translate("orders.view_chart", "View Chart"),
    viewTable: translate("orders.view_table", "View Table"),
    amendedOrder: translate("orders.orders_by_investor", "Orders by Investor"),
    notAvailable: translate(
      "others.content_not_yet_available",
      "Content Not Yet Available"
    ),
    investorNameText: translate("orders.investor_name", "Investor Name"),
    investorTypeText: translate("orders.investor_types", "Investor Type"),
    timeRangeText: translate("orders.time_range", "Key Event Time"),
    trancheNameText: translate("orders.tranche_name", "Tranche Name"),
    timeIntervalText: translate("orders.time_interval", "Time Interval"),
    dateText: translate("orders.date", "Date"),
    timeEntered: translate("orders.time_entered", "Time Entered"),
    firstOrderText: translate("orders.first_order", "First Order"),
    lastOrderText: translate("orders.last_order", "Last Order"),
    peakOrderText: translate("orders.peak_order", "Peak Order"),
    allocationAmountText: translate("orders.allocation", "Allocation"),
    lastMinusPeakText: translate(
      "orders.last_minus_peak_change",
      "Last (-) Peak Change"
    ),
    orderAmountText: translate("orders.order_amount", "Order Amount"),
    statusText: translate('orders.status_value', 'Status'),
    orderIdText: translate('orders.orderId', 'Order Id'),
    limitSecurityText: translate('orders.limit_security', 'Limit Security'),
    dateTimeText: translate('orders.date_time_entered', 'Date and Time entered'),
  }))
)(BookEvolutionContainer);
