import React from 'react'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FileMetaForm from 'Isv/components/FileUpload/FileMetaForm/FileMetaForm'
import DocumentFileUploader from 'Isv/components/FileUpload/DocumentFileUploader/DocumentFileUploader'
import { uploadRefPostDocument,handleOpenUploadOverlay, startUploadingFile } from 'Isv/actions/IsvDocumentsAction';
import { selectUploadedOverlayStatus, selectUploadedFile , getIsvCurrentDealId, selectIsRefDocumentUploaded, selectedReferenceInformationDataForCurrentDeal} from "Isv/selectors";
import ProgressBar from 'Isv/components/common/ProgressBar'
import utils from 'Isv/util/util';
import FILE_UPLOAD_STATES from 'Isv/constants/fileUploadStates';
import {isIsvRRTeamUser} from 'selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RefFileUploadOverlay = ({ 
    isOpen, handleOpenUploadOverlay, uploadRefPostDocument, file, status, dealId, isRefDocumentUploaded, selectedReferenceInformationData,isRrUser 
 }) =>
 {
  const CheckReferenceFileTitle = (selectedReferenceInformationData, title, isSupportingDocument) => {
     let count = 0;
     selectedReferenceInformationData &&  selectedReferenceInformationData.length && 
     selectedReferenceInformationData.forEach(element => {
       element.title === title && isSupportingDocument && count++
     });
     return count;
  }
 
  const uploadRefDocument = (document) => {
    const dataFD = new FormData();
    const title = document.title.trim();
    dataFD.append('title', title);
    dataFD.append('PostedFile', file);
    dataFD.append('DocRefId', document.docRefId || 0);
    dataFD.append('dealId', dealId);
    dataFD.append('isRefDocumentUploaded', isRefDocumentUploaded);
    dataFD.append('isRrUser', isRrUser);
    const isTitleAlreadyExist = CheckReferenceFileTitle(selectedReferenceInformationData, title, document.isSupportingDocument); 
    uploadRefPostDocument(dataFD, isTitleAlreadyExist, isRefDocumentUploaded, document, isRrUser);
  }

  return (
    <Wrapper>
      <Dialog
        open={isOpen}
        title="File Upload"
        fullWidth={true}
        maxWidth={'md'}
        onBackdropClick={() => handleOpenUploadOverlay(false)}
      >
        <DialogTitle>File Upload</DialogTitle>
        <DialogContent dividers>
          <DocumentFileUploader />
          <FileMetaForm onSubmit={uploadRefDocument} />
        </DialogContent>
        {utils.isState(status, FILE_UPLOAD_STATES.IN_PROGRESS) && <ProgressBar />}
      </Dialog>
    </Wrapper>
  )
}

export default compose(
  connect(
    (state) => ({
      isOpen: selectUploadedOverlayStatus(state),
      file: selectUploadedFile(state),
      status: state.isvDocuments.status,
      dealId: getIsvCurrentDealId(state),
      isRefDocumentUploaded:selectIsRefDocumentUploaded(state),
      selectedReferenceInformationData: selectedReferenceInformationDataForCurrentDeal(state),
      isRrUser: isIsvRRTeamUser(state),
    }),
    (dispatch) => ({
      uploadRefPostDocument: (data, isTitleAlreadyExist, isRefDocumentUploaded, document, isRrUser) => {
        dispatch(startUploadingFile())
        dispatch(uploadRefPostDocument(data, isTitleAlreadyExist, isRefDocumentUploaded, document, isRrUser))
      },
      handleOpenUploadOverlay: (isOpen) => {
        dispatch(handleOpenUploadOverlay(isOpen))
      }
    })
  )
)(RefFileUploadOverlay);
