import Types from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';
import * as tiles from 'constants/homeTiles';

const receiveFeedbackThemeTypes = response => ({
    type: Types.API_RECEIVE_FEEDBACK_THEME_TYPES,
    feedbackThemeTypes: response,
});
const requestFeedbackThemeTypes = loader => ({
    type: Types.API_REQUEST_FEEDBACK_THEME_TYPES,
    endPoint: '/v1/feedback/themeTypes',
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveFeedbackThemeTypes,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackSentiments = response => ({
    type: Types.API_RECEIVE_FEEDBACK_SENTIMENTS,
    feedbackSentiments: response,
});
const requestFeedbackSentiments = loader => ({
    type: Types.API_REQUEST_FEEDBACK_SENTIMENTS,
    endPoint: '/v1/feedback/sentiments',
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveFeedbackSentiments,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackDealThemes = response => ({
    type: Types.API_RECEIVE_DEAL_THEMES,
    dealThemes: response,
});
const requestFeedbackDealThemes = (dealId, loader) => ({
    type: Types.API_REQUEST_DEAL_THEMES,
    endPoint: `/v1/deals/${dealId}/feedback/themes`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveFeedbackDealThemes,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackForDeal = response => ({
    type: Types.API_RECEIVE_FEEDBACK_FOR_DEAL,
    dealFeedback: response,
});
const requestFeedbackForDeal = (dealId, loader) => ({
    type: Types.API_REQUEST_FEEDBACK_FOR_DEAL,
    endPoint: `/v2/deals/${dealId}/feedback`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveFeedbackForDeal,
    loader,
    tile: tiles.HOME_FEEDBACK_TILE,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackSummaryForDealAndTheme = (response, responseDetails) => ({
    type: Types.API_RECEIVE_FEEDBACK_FOR_DEAL_AND_THEME,
    dealAndThemeSummary: response,
    responseDetails,
});
const requestFeedbackSummaryForDealAndTheme = (dealId, themeTypeId, loader) => ({
    type: Types.API_REQUEST_FEEDBACK_FOR_DEAL_AND_THEME,
    endPoint: `/v1/deals/${dealId}/feedback/${themeTypeId}/summary`,
    params: {},
    ajaxType: 'GET',
    responseDetails: `themeSummary${themeTypeId}`,
    onSuccess: receiveFeedbackSummaryForDealAndTheme,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackHighlightsForDealAndTheme = (response, responseDetails) => ({
    type: Types.API_RECEIVE_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE,
    dealAndSentimentSummary: response,
    responseDetails,
});
const requestFeedbackHighlightsForDealAndTheme = (dealId, themeTypeId, loader) => ({
    type: Types.API_REQUEST_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE,
    endPoint: `/v1/deals/${dealId}/feedback/${themeTypeId}/highlights`,
    params: {},
    ajaxType: 'GET',
    responseDetails: `themeHighlight${themeTypeId}`,
    onSuccess: receiveFeedbackHighlightsForDealAndTheme,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackChartTypes = (response) => ({
    type: Types.API_RECEIVE_FEEDBACK_CHART_TYPES,
    chartTypes: response,
});
const requestFeedbackChartTypes = (dealId, loader) => ({
    type: Types.API_REQUEST_FEEDBACK_CHART_TYPES,
    endPoint: `/v1/deals/${dealId}/feedback/charts`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveFeedbackChartTypes,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const receiveFeedbackChartData = (response, responseDetails) => ({
    type: Types.API_RECEIVE_FEEDBACK_CHART_DATA,
    chartData: response,
    responseDetails,
});
const requestFeedbackChartData = (dealId, chartId, loader) => ({
    type: Types.API_REQUEST_FEEDBACK_CHART_DATA,
    endPoint: `/v1/deals/${dealId}/feedback/charts/${chartId}`,
    params: {},
    ajaxType: 'GET',
    responseDetails: `feedbackChartData${chartId}`,
    onSuccess: receiveFeedbackChartData,
    loader,
    //
    feature: dealFeatures.INVESTOR_FEEDBACK,
});

const selectChartId = chartId => ({
    type: Types.SELECT_CHART_ID,
    chartId,
});

const selectChartTab = chartIndex => ({
    type: Types.SELECT_CHART_TAB,
    chartIndex,
})

const selectChartSubTab = (chartIndex, chartTitle) => ({
    type: Types.SELECT_CHART_SUB_TAB,
    chartIndex,
    chartTitle,
})

export {
    receiveFeedbackThemeTypes,
    requestFeedbackThemeTypes,
    receiveFeedbackSentiments,
    requestFeedbackSentiments,
    receiveFeedbackDealThemes,
    requestFeedbackDealThemes,
    receiveFeedbackForDeal,
    requestFeedbackForDeal,
    receiveFeedbackSummaryForDealAndTheme,
    requestFeedbackSummaryForDealAndTheme,
    receiveFeedbackHighlightsForDealAndTheme,
    requestFeedbackHighlightsForDealAndTheme,
    receiveFeedbackChartTypes,
    requestFeedbackChartTypes,
    receiveFeedbackChartData,
    requestFeedbackChartData,
    selectChartTab,
    selectChartSubTab,
    selectChartId
};
