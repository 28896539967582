export const isResponseNull = data => {
  // empty response
  if (!data) return true;

  if (Array.isArray(data)) {
    // empty array
    if (!data.length) return true;
    // array with first empty item
    if (data[0] === null) return true;
  }

  return false;
}
