/* @flow */
import React from 'react';
import Icon from '../components/Icon/Icon';

import TranslationText from 'translations/TranslationText';

export const otherColor = '#eeeeee';

export const primaryChartColors = [
    '#00716C',
    '#E9A115',
    '#4E3A7C',
    '#588942',
    '#003466',
    '#6F0008',
    '#1087EF',
];

export const secondaryChartColors = [
    '#008580',
    '#004F4B',
    '#E9A115',
    '#9E6D0E',
    '#716196',
    '#1C1A49',
    '#588942',
    '#142E01',
    '#547DA7',
    '#002951',
    '#E54D58',
    '#6F0008',
    '#1087EF',
    '#0C62AE',
    '#B57DA4',
];

export const HSBC_RAG_COLORS = {
  HSBC_RAG_RED: '#AF000D',
  HSBC_RAG_AMBER: '#E9A115',
  HSBC_RAG_GREEN: '#008580',
};

export const roadShowEventTypes:Map<number, EventType> = new Map();

roadShowEventTypes.set(0, {
    title            : () => ``,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 0,
});
roadShowEventTypes.set(1, {
    title            : (host:?string) => `1:1 Call ${(host) ? `(${host})` : ``}`,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 1,
});
roadShowEventTypes.set(2, {
    title            : (host:?string) => `1:1 Conference Call ${(host) ? `(${host})` : ``}`,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 2,
});
roadShowEventTypes.set(3, {
    title            : (host:?string, num:number) => `${num || 'X'}:1 ${(host) ? `(${host})` : ``}`,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 3,
});
roadShowEventTypes.set(4, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.group_meeting"}
                defaultText={'Group Meeting'}
        />
    ),
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 4,
});
roadShowEventTypes.set(5, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.conference_call"}
                defaultText={'Conference Call'}
        />
    ),
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 5,
});
roadShowEventTypes.set(6, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.private_meeting"}
                defaultText={'Private Meeting'}
        />
    ),
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 6,
});
roadShowEventTypes.set(7, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.placeholder"}
                defaultText={'Placeholder'}
        />
    ),
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 7,
});
roadShowEventTypes.set(8, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.flight"}
                defaultText={'Flight'}
        />
    ),
    icon             : <Icon iconName="Plane" style={{verticalAlign : 'baseline', color: 'rgb(153, 153, 153)', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color: 'rgb(153, 153, 153)', fontSize:20 }} iconName="Plane"  />,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.passengers"}
                defaultText={'Passengers'}
        />
    ),
    eventTypeId      : 8,
});
roadShowEventTypes.set(9, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.flight"}
                defaultText={'Flight'}
        />
    ),
    icon             : <Icon iconName="Plane" style={{verticalAlign : 'baseline', color: 'rgb(153, 153, 153)', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color: 'rgb(153, 153, 153)', fontSize:20 }} iconName="Plane"  />,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.passengers"}
                defaultText={'Passengers'}
        />
    ),
    eventTypeId      : 9,
});
roadShowEventTypes.set(10, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.train"}
                defaultText={'Train'}
        />
    ),
    icon             : <Icon iconName="Train" style={{verticalAlign : 'baseline', color: 'rgb(153, 153, 153)', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color: 'rgb(153, 153, 153)', fontSize:20 }} iconName="Train"  />,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.passengers"}
                defaultText={'Passengers'}
        />
    ),
    eventTypeId      : 10
});
roadShowEventTypes.set(11, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.car"}
                defaultText={'Car'}
        />
    ),
    icon             : <Icon iconName="Car" style={{verticalAlign : 'baseline', color:'rgb(153, 153, 153)', fontSize:12}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color: 'rgb(153, 153, 153)', fontSize:14, position:'relative'}} iconName="Car"  />,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.passengers"}
                defaultText={'Passengers'}
        />
    ),
    eventTypeId      : 11
});
roadShowEventTypes.set(12, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.hotel"}
                defaultText={'Hotel'}
        />
    ),
    icon             : <Icon iconName="Hotel" style={{verticalAlign : 'baseline', color: 'rgb(153, 153, 153)', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color: 'rgb(153, 153, 153)', fontSize:20 }} iconName="Hotel"  />,
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team"}
                defaultText={'Team'}
        />
    ),
    eventTypeId      : 12
});
roadShowEventTypes.set(17, {
    title            : () => (
        <TranslationText
                dictionaryKey={"roadshow.investor_call"}
                defaultText={'Investor Call'}
        />
    ),
    participantsTitle: (
        <TranslationText
                dictionaryKey={"roadshow.team_attendees"}
                defaultText={'Team Attendees'}
        />
    ),
    eventTypeId      : 17,
});