import React, { PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col  } from 'react-bootstrap';

import { PrimaryButton, SecondaryButton, SecondarySlateButton } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';

import { typography } from 'shared/styles/variables/theme'

export const Header = styled.h1`
    font-size: 1.438rem;
    line-height: 3.6rem;
    margin: 0 0 20px;
    text-align: left;
`;

export const Divider = styled.div`
    margin: 0 0 20px 0;
    border-bottom: 1px solid #D8D8D8;
`;

export const baseBtnStyle = {
    fontSize: typography.sizes['16px'],
    padding: '14px 20px',
};

export const ModalContainer = styled.div`
    position: relative;
    padding: 40px;
    background: ${props => ( props.style && props.style.background ) || '#ffffff'};
    margin: 0 auto;
    min-width: 340px;
    width: ${props => {
        if (props.static) {
            if (props.width) {
                return `${props.width}px`;
            }
            return '100%';
        }
        return '340px';
    }};
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 40px);
    border: 4px solid #000000;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    color: ${props => ( props.style && props.style.color ) || 'black'};
    text-align: left;
`;

export class Popup extends PureComponent {
    render() {
        return (
            <ModalContainer
                static={this.props.static}
                width={this.props.width}
                style={this.props.style}
            >
                {
                    this.props.header && (
                        <Header className="text-univ-lt">
                            <TranslationText
                                dictionaryKey={this.props.headerKey}
                                defaultText={this.props.header}
                            />
                        </Header>
                    )
                }
                <div className="popover-content popover-spacing-bottom-sm">
                    { this.props.children }
                </div>
                {
                    (this.props.primaryButton || this.props.secondaryButton || this.props.secondarySlateButton) && (
                      <Fragment>
                            <Row>
                                <Col sm={12}>
                                    <Divider
                                        style={this.props.dividerStyle}
                                    />
                                </Col>
                            </Row>
                        {this.props.actionBarControls && (
                          <Row>
                            <Col sm={12} style={this.props.actionBarControlsStyle}>
                              {this.props.actionBarControls}
                            </Col>
                          </Row>
                        )}
                        <Row>
                            <Col sm={3} className="text-left">
                            {
                                this.props.secondaryButton && (
                                    <SecondaryButton
                                        disabled={this.props.secondaryButtonDisabled}
                                        onClick={this.props.secondaryButtonAction}
                                        style={this.props.secondaryButtonStyle || baseBtnStyle}
                                    >
                                        <TranslationText
                                            dictionaryKey={this.props.secondaryButtonKey}
                                            defaultText={this.props.secondaryButtonContent}
                                        />
                                    </SecondaryButton>
                                )
                              }
                            </Col>
                            <Col sm={9} className="text-right">
                                {
                                    this.props.secondarySlateButton && (
                                        <SecondarySlateButton
                                            disabled={this.props.secondarySlateButtonDisabled}
                                            onClick={this.props.secondarySlateButtonAction}
                                            style={baseBtnStyle}
                                        >
                                            <TranslationText
                                                dictionaryKey={this.props.secondarySlateButtonKey}
                                                defaultText={this.props.secondarySlateButtonContent}
                                            />
                                        </SecondarySlateButton>
                                    )
                                }
                                {
                                    this.props.primaryButton && (
                                        <PrimaryButton
                                            size="normal"
                                            disabled={this.props.primaryButtonDisabled}
                                            onClick={this.props.primaryButtonAction}
                                            style={this.props.primaryButtonStyle || baseBtnStyle}
                                            aria-haspopup={this.props.primaryButtonHasPopup}
                                        >
                                            <TranslationText
                                                dictionaryKey={this.props.primaryButtonKey}
                                                defaultText={this.props.primaryButtonContent}
                                            />
                                        </PrimaryButton>
                                    )
                                }
                            </Col>
                        </Row>
                      </Fragment>
                    )
                }
            </ModalContainer>
        );
    }
}

Popup.propTypes = {
    // content
    header: PropTypes.string,
    static: PropTypes.bool,
    primaryButtonStyle: PropTypes.object,
    secondaryButtonStyle: PropTypes.object,
    dividerStyle: PropTypes.object,
    // action
    primaryButton: PropTypes.bool,
    primaryButtonContent: PropTypes.string,
    primaryButtonAction: PropTypes.func,
};

Popup.defaultProps = {
    // Todo
};

export default Popup;
