import { typography } from 'shared/styles/variables/theme';

export const defaultBtnStyle = {
    fontSize: typography.sizes['14px'],
    padding: '0px 1.1875rem',
    minHeight: '2.5rem'
};

export const primaryBtnStyle = {
    ...defaultBtnStyle,
    border: 'none',
    color: '#FFFFFF',
};
