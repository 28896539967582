import React, { PureComponent } from 'react';

import TranslationText from 'translations/TranslationText';

const commentTitleInlineStyle = {
    height: 25,
    margin: 0
};
const commentTextInlineStyles = {
  whiteSpace: 'pre-line',
  margin: 0,
};

class Comment extends PureComponent {
    render() {
        if (!this.props.comment) return null;

        return (
            <React.Fragment>
                <h2 style={commentTitleInlineStyle} className="text-univ-bd text-normal">
                    <TranslationText
                        dictionaryKey={"investor_feedback.comments"}
                        defaultText={"Comments"}
                    />
                </h2>
                <div style={commentTextInlineStyles}>
                    {
                        this.props.comment.trim()
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default Comment;
