import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  > div:nth-child(2) {
    margin-left: 20px;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 30px 0 10px 0;

  & button{
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
