

export const mapThemeTypeOrSentimentToKey = title => {
    switch (title) {
        // sentiment
        case 'Positive': return 'investor_feedback.positive';
        case 'Neutral': return 'investor_feedback.neutral';
        case 'Negative': return 'investor_feedback.negative';
        // theme type
        // case 'Positive': return 'investor_feedback.positive';
        case 'Concern': return 'investor_feedback.concern';
        case 'Valuation': return 'investor_feedback.valuation';
        default: 
            return '';
    }
}

export const mapChartTypeTitleToKeys = title => {
    switch (title) {
        //
        // WARN: If we will have custom titles then in dev mode we will see only one tab with missing key/translation
        //
        case 'Theme Distribution': return 'investor_feedback.theme_distribution';
        case 'Sentiment': return 'investor_feedback.sentiment';
        case 'Price Demand': return 'investor_feedback.price_demand';
        default:
            // custom title so we can return empty key and default one ( original ) will be returned
            return '';
    }
}