/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { compose } from 'recompose';
// TODO
import { ScrollingNav, ValueFormatters, HsbcButton } from 'mk-pattern-library-react';

import { updateSelectedDay, setRoadShowDailyEvent } from 'actions/RoadShowActions';
import { requestDoc } from 'actions/MyDocsActions';
import { viewChange } from 'actions/AnalyticsActions';
import { openModal, closeModal } from 'actions/UtilityActions';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import * as modalIds from 'constants/modalIds';
import { ALL_TEAMS_SELECTED } from 'constants/multiteam';
import { featurePageIds } from 'constants/featurePages';
// multilang
import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import withTranslate from 'shared/hoc/withTranslate';

import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';

import {
    getRoadShowSummaryOnline,
    getRoadShowContacts,
    getRoadShowSummaryCoordinators,
    getEventsForSelectedDay,
    getRoadShowSelectedDay,
    getSelectedTeam,
    getMultiTeamMode,
    getRoadShowEventDates,
    getRoadShowFullSchedule,
    getDailyEventId,
    isModalOpen
} from 'selectors';

import { ImgIcon } from 'shared/components/Icon';
import Notification from 'shared/components/Notifications';
import HsbcLink from 'shared/accessibility/components/Link';

// modal cnt
import MultiTeam from './modals/MultiTeam';

import CombinedContacts from './modals/CombinedContacts/indexContacts';
import CombinedDetails from './modals/CombinedContacts/indexDetails';
import CombinedCoordinators from './modals/CombinedContacts/indexCoordinators';
// cmp
import RoadShowDayView from './DayView.container';
import DetailsView from './EventDetails/DetailsView/DetailsView';

const documentIconStyle = {
    height: '18px',
    width: '18px',
    position: 'absolute',
    display: "inline-block",
    color: '#4D6474',
    left: -23,
    top: 1,
};

class RoadShowContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollToTopDayViewFlag: false,
            scrollToTopDetailViewFlag: false,
        };

        this.handleRoadShowNavClick = this.handleRoadShowNavClick.bind(this);
        this.handleDayViewUpdate    = this.handleDayViewUpdate.bind(this);
        this.handleDocumentClick    = this.handleDocumentClick.bind(this);

        this.dealType = props.dealType;
    }

    componentDidMount() {
        this.props.viewChange();
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    handleDocumentClick(docId, docName) {
        this.props.requestDoc(docId, docName);
    }

    handleDayViewUpdate(selectedEvent) {
        this.props.changeDailyEvent(selectedEvent.eventId);
        this.props.eventDetailButtonEvent();

        this.setState({
            scrollToTopDetailViewFlag: !this.state.scrollToTopDetailViewFlag
        });
    }

    handleRoadShowNavClick(selectedDay) {
        this.props.updateSelectedDay(selectedDay);
        this.props.changeDailyEvent(null);

        this.setState({
            scrollToTopDayViewFlag: !this.state.scrollToTopDayViewFlag,
            scrollToTopDetailViewFlag: !this.state.scrollToTopDetailViewFlag
        });

        this.props.carouselChangeEvent();
    }

    mapNavEvents() {
        return this.props.roadShowSummaryDates.map(date => {
          let eventText;

          if (date.hasFlights) {
              eventText = this.props.planeText;
          }
          else if (date.hasTrain) {
            eventText = this.props.trainText;
          }
          else if (date.hasCars) {
            eventText = this.props.carText;
          }

          return {
            ...date,
            details: `${date.meetingCount} ${date.meetingCount > 1
                ? translationString('roadshow.meetings', this.props.language, 'meetings')
                : translationString('roadshow.meeting', this.props.language, 'meeting')
            }`,
            btnAriaLabel: translationString(
                'roadshow.<day>,_places:_<places>,_meetings:_<meeting_count>',
                this.props.language,
                `${date.view}, places: ${date.status.length}${date.status.length > 0 ? `(${date.status.join(', ')})` : ''}, meetings: ${date.meetingCount}, ${date.eventIcon}, ${eventText}`,
                [
                    ['<day>', date.view],
                    ['<places>', `${date.status.length}${date.status.length > 0 ? `(${date.status.join(', ')})` : ''}`],
                    ['<meeting_count>', date.meetingCount],
                ]
            )
        }
      });
    }

    render() {
        const selectedDayEvents = this.props.eventsForSelectedDay ? this.props.eventsForSelectedDay.sort((a, b) => {
            const date1 = moment(a.startDateLocal)
            const date2 = moment(b.startDateLocal)

            //
            // ─── HOTELS AT BOTTOM OF LIST ────────────────────────────────────
            //
            if (a.eventTypeId === 12) {
                return 1
            }
            if (b.eventTypeId === 12) {
                return -1
            }
            // ────────────────────────────────────────────────────────────────────────────────

            if (date1.isBefore(date2)) {
                return -1
            }
            else if (date1.isAfter(date2)) {
                return 1
            }
            else {
                return 0
            }
        }) : [];

        let selectedEvent;
        if (this.props.dailyEventId) {
            if (selectedDayEvents.some(ev => ev.eventId === this.props.dailyEventId)) {
                selectedEvent = selectedDayEvents.filter(ev => ev.eventId === this.props.dailyEventId)[0];
            } else {
                // Improve this -> select first one from the list
                selectedEvent = selectedDayEvents[0];
            }
        } else {
            // Improve that too - if empty list then dispatch null !
            selectedEvent = selectedDayEvents[0];
        }

        let activeDay = this.props.selectedDay || {};

        let cityList = activeDay.status;

        if(activeDay.status && activeDay.status.map){
            cityList = activeDay.status.map((city, index) => `${city}${index < activeDay.status.length - 1 ? ', ' : ''}`);
        }

        let dayViewStyle = {
            marginLeft: 0,
            backgroundColor: '#FFFFFF',
            marginRight: 0,
            paddingRight: 0,
            width: window.innerWidth < 768 ? '100%' : '50%',
        }

        return (
            <div style={{ backgroundColor: "#fff" }}>
                <FeatureHeading invisible>
                    <TranslationText
                        dictionaryKey={"roadshow.roadshow"}
                        defaultText={"Roadshow"}
                    />
                </FeatureHeading>
                <Row>
                    <Col
                        sm={12}
                        style={{ marginTop: '20px' }}
                    >
                        <section aria-label={this.props.calendarRegionAriaLabel}>
                            <ScrollingNav
                                className="btn-scrolling-nav"
                                titles={this.mapNavEvents()}
                                width="100%"
                                btnWidth={114}
                                handleClick={this.handleRoadShowNavClick}
                                active={activeDay}
                            />
                        </section>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col sm={12}>
                        <div style={{ textAlign: "right" }}>
                            <p className='text-timestamp text-small'>
                                <TranslationText
                                    dictionaryKey={"common.data_as_of_<date_time>"}
                                    replace={[
                                        ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)]
                                    ]}
                                    defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)}`}
                                />
                            </p>
                        </div>
                    </Col>

                    <Col sm={6} style={{ paddingRight: 0}}>
                        {
                            this.props.multiTeamMode && (
                                <div style={{ position: 'relative', top: -20 }}><MultiTeam/></div>
                            )
                        }

                        <FeatureHeading className="text-xxlarge text-univ-bd">
                            {activeDay.view}
                        </FeatureHeading>
                    </Col>

                    <Col style={{ paddingLeft: 0, marginBottom: 3, paddingTop: 8 }} sm={6} className="text-right" >
                        <HsbcLink
                            id='link-full-schedule'
                            style={{ position: 'relative' }}
                            aria-label={
                                translationString(
                                    'roadshow.open_roadshow_full_schedule_document',
                                    this.props.language,
                                    'Open Roadshow Full Schedule document'
                                )
                            }
                            onClick={() => {
                                this.handleDocumentClick(this.props.fullScheduleDocumentId, "Full Roadshow Schedule");
                                this.props.fullScheduleButtonEvent();
                            }}
                            disabled={!this.props.fullScheduleDocumentId}
                        >
                            <ImgIcon
                                style={documentIconStyle}
                                icon="DocumentDownload"
                                aria-hidden
                            />
                            <TranslationText
                                dictionaryKey={'roadshow.full_schedule'}
                                defaultText={'Full Schedule'}
                            />
                        </HsbcLink>

                        <CombinedCoordinators />
                        <CombinedContacts />
                        <CombinedDetails />
                    </Col>
                    <Col sm={12} style={{marginBottom:20, marginTop:5}}>
                        <HeadingDescription>
                            {cityList}
                        </HeadingDescription>
                    </Col>
                </Row>
                <Row style={{ visibility: this.props.selectedTeam === ALL_TEAMS_SELECTED ? 'visible' : 'hidden' }}>
                  <Col style={{ fontSize: '0.625rem', textAlign: 'right', paddingRight: 26, paddingBottom: 3 }} sm={6}>
                    <TranslationText
                        dictionaryKey={'roadshow.all_events_are_sorted_by_utc'}
                        defaultText={'All events are sorted by UTC'}
                    />
                  </Col>
                </Row>
                {
                    this.props.selectedTeam && (
                        <Row id="roadshow-body">
                            <Col className="roadshow-body-scroll-container" sm={6} style={dayViewStyle}>
                                <RoadShowDayView
                                    key="rsdv"
                                    dayEvents={selectedDayEvents}
                                    activeEvent={selectedEvent}
                                    handleDayViewUpdate={this.handleDayViewUpdate}
                                />
                            </Col>
                            <Col className="roadshow-body-scroll-container" sm={6}>
                                {
                                    selectedEvent && (
                                        <DetailsView
                                            event={selectedEvent}
                                            dealType={this.dealType}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    )
                }
                {
                    this.props.multiTeamMode && !this.props.selectedTeam && (
                        <Row>
                            <Col sm={12}>
                                <Notification
                                    warning
                                    headline={this.props.notificationHeadlineText}
                                    description={this.props.notificationDescText}
                                />
                            </Col>
                        </Row>
                    )
                }
            </div>
        );
    }
}

export default compose(
    withLanguage,
    withTranslate(translate => ({
        calendarRegionAriaLabel: translate('', 'Calendar day list'),
        notificationHeadlineText: translate('', 'Warning.'),
        notificationDescText: translate('roadshow.to_view_the_correct_schedule_please_choose_your_ro', 'To view the correct schedule please choose your Roadshow Team from the Team Selector above...'),
        planeText: translate('roadshow.plane', 'plane'),
        trainText: translate('roadshow.plane', 'train'),
        carText: translate('roadshow.plane', 'car'),
    })),
    withAnalytics((ev, view) => ({
        // view
        viewChange: () => view(featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE),
        // events
        carouselChangeEvent: () => ev(events.ROADSHOW_CAROUSEL_DATE_CHANGE),
        fullScheduleButtonEvent: () => ev(events.ROADSHOW_FULL_SCHEDULE_BUTTON),
        eventDetailButtonEvent: () => ev(events.ROADSHOW_EVENT_DETAIL),
    })),
    connect(
        state => ({
            selectedTeam: getSelectedTeam(state),
            multiTeamMode: getMultiTeamMode(state),
            eventsForSelectedDay: getEventsForSelectedDay(state),
            roadShowSummaryDates: getRoadShowEventDates(state),
            dateReceived: state.roadShowData.dateReceived,
            fullScheduleDocumentId: state.roadShowData.fullScheduleDocumentId,
            selectedDay: getRoadShowSelectedDay(state),
            dailyEventId: getDailyEventId(state),
        }),
        dispatch => ({
            updateSelectedDay: day => dispatch(updateSelectedDay(day)),
            requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName)),
            changeDailyEvent: eventId => dispatch(setRoadShowDailyEvent(eventId)),
        })
    ),
)(RoadShowContainer)
