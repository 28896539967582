import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ValueFormatters } from 'mk-pattern-library-react';
import { compose } from 'recompose';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import withTranslate from 'shared/hoc/withTranslate';
import { numberFormatters } from 'shared/functions/formatters'

import Footer from './FullOrderBookFooter';

import OrderBookTable from '../../shared/table/OrderbookTable';
import { millionFormatter, dateFormatter } from '../../SharedTableComponents/formatters';
import SortState, { SORT } from 'shared/components/SortState';
import baseColumnProps from '../../SharedTableComponents/baseColumnProps';

import { StatusIcon } from 'mk-pattern-library-react/components/SvgIcon/SvgIcon';

import {
  investorCellStyle,
  headerStyle,
  sortedHeaderStyle,
  centerAlignedHeaderStyle,
  centerAlignedCellStyle,
  rightAlignedCellStyle,
  rightAlignedHeaderStyle
} from '../../shared/table/styles';


const investorLongNameColumn = (props, sort) => ({
  ...baseColumnProps(sort),
  attrs: {
    role: "rowheader",
  },
  dataField: 'investorLongName',
  text: props.investorText,
  headerClasses: '',
  style: investorCellStyle,
  headerStyle: headerStyle,
  headerSortingStyle: sortedHeaderStyle,
  columnFlex: 3,
  footer: props.viewType !== 'engagement' ? 'Total' : undefined,
  footerTitle: true,
});

const investorCountryColumn = (props, sort) => ({
  ...baseColumnProps(sort),
  dataField: 'investorCountry',
  text: props.countryText,
  headerClasses: '',
  style: centerAlignedCellStyle,
  headerStyle: centerAlignedHeaderStyle,
  headerSortingStyle: sortedHeaderStyle,
  columnFlex: 0.5,
})

const investorTypeColumn = (props, sort) => ({
  ...baseColumnProps(sort),
  dataField: 'investorType',
  text: props.typeText,
  headerClasses: '',
  style: centerAlignedCellStyle,
  headerStyle: centerAlignedHeaderStyle,
  headerSortingStyle: sortedHeaderStyle,
  columnFlex: 0.5,
});

const AllocationColumns = (props, sort) => {
  const columns = [
    investorLongNameColumn(props, sort),
    investorCountryColumn(props, sort),
    investorTypeColumn(props, sort),
  {
    ...baseColumnProps(sort),
    dataField: 'targetDemandAmount',
    text: props.targetDemandText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: rightAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: millionFormatter,
    footerAlign: 'right',
    footerFormatter: () =>
      <Footer
        topValue={props.dealsData.dealTotalDemandSummary.demandAtTargetSharePrice.totalDemandAmount}
        bottomValue={props.dealsData.dealTotalDemandSummary.demandAtTargetSharePrice.totalDemandFactor}
      />
  }, {
    ...baseColumnProps(sort),
    dataField: 'targetAllocationAmount',
    text: props.allocationAmountText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: rightAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: millionFormatter,
    footerFormatter: () =>
      <Footer
        topValue={props.dealsData.dealTotalAllocationSummary.totalAllocationAmount}
      />
  }, {
    ...baseColumnProps(sort),
    dataField: 'targetAllocationPercentage',
    text: props.allocationPercentageText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: rightAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    formatter: numberFormatters.oneDecimalPointPercent,
    columnFlex: 1,
  }];

  return columns;
}

const SummaryColumns = (props, sort) => {
  const columns = [
    investorLongNameColumn(props, sort),
    investorCountryColumn(props, sort),
    investorTypeColumn(props, sort),
    {
      ...baseColumnProps(sort),
      dataField: 'demandAtBottom',
      text: props.demandAtBottomText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: rightAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
      formatter: millionFormatter,
      footerFormatter: () =>
        <Footer
          topValue={props.dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.totalDemandAmount}
          bottomValue={props.dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.totalDemandFactor}
        />
    }, {
      ...baseColumnProps(sort),
      dataField: 'demandAtMidpoint',
      text: props.demandAtMidpointText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: rightAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
      formatter: millionFormatter,
      footerFormatter: () =>
      <Footer
        topValue={props.dealsData.dealTotalDemandSummary.demandAtMidSharePrice.totalDemandAmount}
        bottomValue={props.dealsData.dealTotalDemandSummary.demandAtMidSharePrice.totalDemandFactor}
      />
    }, {
      ...baseColumnProps(sort),
      dataField: 'demandAtTop',
      text: props.demandAtTopText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: rightAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
      formatter: millionFormatter,
      footerFormatter: () =>
        <Footer
          topValue={props.dealsData.dealTotalDemandSummary.demandAtTopSharePrice.totalDemandAmount}
          bottomValue={props.dealsData.dealTotalDemandSummary.demandAtTopSharePrice.totalDemandFactor}
        />
    }, {
      ...baseColumnProps(sort),
      dataField: 'currentLimit',
      text: props.currentLimitText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: rightAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
    }
  ];

  return columns;
}

const DemandDetailColumns = (props, sort) => {
  const columns = [
    investorLongNameColumn(props, sort)
  ];

  columns.push(...props.shareDemand.map((shareDemandPrice, index) => ({
    ...baseColumnProps(sort),
    dataField: shareDemandPrice.sharePrice.toString().replace('.', '_'),
    text: ValueFormatters.commaFormatter(shareDemandPrice.sharePrice),
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: {
      ...rightAlignedHeaderStyle,
      verticalAlign: 'bottom',
      borderRight: index === props.shareDemand.length -1 ? '1px solid rgb(216, 216, 216)' : 'none'
    },
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data) => millionFormatter(data[shareDemandPrice.sharePrice.toString().replace('.', '_')]),
    footer: '',
    footerFormatter: () =>
      <Footer
        topValue={props.shareDemand[index].totalDemandAmount}
        bottomValue={props.shareDemand[index].totalDemandFactor}
      />,
  })));

  columns.push({
    ...baseColumnProps(sort),
    dataField: 'externalSourceCreated',
    text: props.createdText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: {
      ...centerAlignedHeaderStyle,
      verticalAlign: 'bottom',
    },
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
    footer: '',
  }, {
    ...baseColumnProps(sort),
    dataField: 'externalSourceLastModified',
    text: props.modifiedText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: {
      ...centerAlignedHeaderStyle,
      verticalAlign: 'bottom',
    },
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
    footer: '',
  });

  return columns;
}

const EngagementColumns = (props, sort) => {
  const columns = [
    investorLongNameColumn(props, sort)
  ];

  columns.push({
    ...baseColumnProps(sort),
    dataField: 'topTierInvestor',
    text: props.topTierText,
    headerClasses: '',
    style: centerAlignedCellStyle,
    headerStyle: centerAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      data['topTierInvestor'] === true ? <StatusIcon
        aria-hidden={false}
        isCheckedIndicator
        isDisabled={data['topTierInvestor'] === false}
        title={props.topTierText}
        /> : undefined
    ),
    footer: undefined,
  }, {
    ...baseColumnProps(sort),
    dataField: 'earlyLookParticipantInvestor',
    text: props.earlyLookText,
    headerClasses: '',
    style: centerAlignedCellStyle,
    headerStyle: centerAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      data['earlyLookParticipantInvestor'] === true ?
      <StatusIcon
        aria-hidden={false}
        isCheckedIndicator
        isDisabled={data['earlyLookParticipantInvestor'] === false}
        title={props.earlyLookText}
      /> : undefined
    ),
    footer: undefined,
  }, {
    ...baseColumnProps(sort),
    dataField: 'preDealEducationInvestor',
    text: props.educationInvestorText,
    headerClasses: '',
    style: centerAlignedCellStyle,
    headerStyle: centerAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      data['preDealEducationInvestor'] === true ? <StatusIcon
        aria-hidden={false}
        isCheckedIndicator
        isDisabled={data['preDealEducationInvestor'] === false}
        title={props.educationInvestorText}
      /> : undefined
    ),
    footer: undefined,
  }, {
    ...baseColumnProps(sort),
    dataField: 'physicalRoadshow',
    text: props.physicalRoadshowText,
    headerClasses: '',
    style: centerAlignedCellStyle,
    headerStyle: centerAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      data['physicalRoadshow'] === true ? <StatusIcon
        aria-hidden={false}
        isCheckedIndicator
        isDisabled={data['physicalRoadshow'] === false}
        title={props.physicalRoadshowText}
      /> : undefined
    ),
    footer: undefined,
  }, {
    ...baseColumnProps(sort),
    dataField: 'hasComments',
    text: props.feedbackText,
    headerClasses: '',
    style: centerAlignedCellStyle,
    headerStyle: centerAlignedHeaderStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 1,
    formatter: (value, data, index) => (
      data['hasComments'] === true ?
      <StatusIcon
        aria-hidden={false}
        isCheckedIndicator
        isDisabled={data['hasComments'] === false}
        title={props.feedbackText}
      /> : undefined
    ),
    footer: undefined,
  });

  /*
  columns.push({
    ...baseColumnProps(sort),
    dataField: 'externalSourceCreated',
    text: props.createdText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: headerStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
    footer: undefined,
  }, {
    ...baseColumnProps(sort),
    dataField: 'externalSourceLastModified',
    text: props.modifiedText,
    headerClasses: '',
    style: rightAlignedCellStyle,
    headerStyle: headerStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.5,
    formatter: (value, data, index) => (
      dateFormatter('dayMonth', value)
    ),
    footer: undefined,
  });
  */

  return columns;
}

// Memoize this?
const getColumns = (props, sort) => {
  switch (props.viewType) {
    case 'allocation' :
      return AllocationColumns(props, sort);
    case 'orderSummary' :
      return SummaryColumns(props, sort);
    case 'demandDetail' :
      return DemandDetailColumns(props, sort);
    case 'engagement' :
      return EngagementColumns(props, sort);
    default :
      throw new Error('Invalid view type specified for ECM orderbook grid');
  }
};

class DemandDetails extends React.PureComponent {

  updatePosition = () => setTimeout(() => {
    const node = ReactDOM.findDOMNode(this);
    if (!node) return;

    const parent = node.parentNode;
    const tableHeader = parent.querySelector('thead');
    const investorLongNameHeader = parent.querySelector('thead tr th[data-field="investorLongName"');
    const externalSourceCreated = parent.querySelector('thead tr th[data-field="externalSourceCreated"');

    if (investorLongNameHeader && externalSourceCreated) {

      const investorLongNamesRects = investorLongNameHeader.getClientRects()[0]
      const left = investorLongNamesRects.right - tableHeader.offsetLeft;
      const right = externalSourceCreated.getClientRects()[0].left - tableHeader.offsetLeft;
      const width = right - left - 2;

      node.style.width = width + 'px';
      node.style.left = left + 'px';
    }
  }, 0)

  componentDidMount() {
    this.updatePosition();

    window.addEventListener("resize", this.updatePosition);
    window.addEventListener("orientationChange", this.updatePosition);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePosition);
    window.removeEventListener("orientationChange", this.updatePosition);
  }

  render() {
    return (
      <div
        id="demand-details-cnt"
        aria-hidden={true}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          height: 35,
          zIndex: 10,
          backgroundColor: 'rgb(237, 237, 237)',
          top: -69,
          textAlign: 'center',
          paddingTop: 7,
          color: 'rgb(77, 100, 116)',
        }}
      >
        {this.props.demandDetails}
      </div>
    )
  }
}

class FullOrderBookTable extends Component {

  componentWillMount() {
    this.sort = new SortState('investorLongName', SORT.ASCENDING, this.props.columnSortEvent, this.props.onSort);
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {
          this.props.viewType === 'demandDetail'
            ? <DemandDetails demandDetails={this.props.demandDetails} />
            : null
        }
        <OrderBookTable
          caption={this.props.tableCaption}
          data={this.props.data}
          keyField="orderId"
          columns={getColumns(this.props, this.sort)}
        />
      </div>
    );
  }
}

export default compose(
  withTranslate((translate, props) => ({
    demandDetails: translate(
      "orders.demand_details_@_<units>_per_share",
      `Demand Detail @ ${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m per share`,
      [
        ["<units>", `${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m`]
      ]
    ),
    investorText: translate('orders.investor', 'Investor'),
    countryText: translate('orders.country', 'Country'),
    typeText: translate('orders.type', 'Type'),
    targetDemandText: translate(
      'orders.demand_@_<price>',
      `Demand @ ${ValueFormatters.commaFormatter(props.dealsData.dealTotalAllocationSummary.sharePrice)} (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`,
      [
        ['<price>', `${ValueFormatters.commaFormatter(props.dealsData.dealTotalAllocationSummary.sharePrice)} (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`]
      ]
    ),
    allocationAmountText: translate(
      'orders.allocation_@_<price>',
      `Demand @ ${ValueFormatters.commaFormatter(props.dealsData.dealTargetPrice)} (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`,
      [
        ['<price>', `${ValueFormatters.commaFormatter(props.dealsData.dealTargetPrice)} (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`]
      ]
    ),
    allocationPercentageText: translate('orders.allocation_percentage_fill', 'Allocation Percentage Fill'),
    demandAtBottomText: translate(
      'orders.demand_@_bottom_(<units>)',
      `Demand @ Bottom (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`,
      [
        ['<units>', `${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m`]
      ]
    ),
    demandAtMidpointText: translate(
      'orders.demand_@_midpoint_(<units>)',
      `Demand @ Midpoint (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`,
      [
        ['<units>', `${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m`]
      ]
    ),
    demandAtTopText: translate(
      'orders.demand_@_top_(<units>)',
      `Demand @ Top (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m)`,
      [
        ['<units>', `${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}m`]
      ]
    ),
    currentLimitText: translate(
      'orders.current_limit_entered_(<currency>,_per_share)',
      `Current Limit Entered (${props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter}, per share)`,
      [
        ['<currency>', props.dealsData.dealCurrency || ValueFormatters.nullResponseCharacter]
      ]
    ),
    topTierText: translate('orders.top_tier_investor', 'Top Tier Investor'),
    earlyLookText: translate('orders.early_look_participant', 'Early Look Participant'),
    physicalRoadshowText: translate('orders.roadshow', 'Roadshow'),
    feedbackText: translate('orders.feedback', 'Feedback'),
    educationInvestorText: translate('orders.investor_education', 'Investor Education'),
    createdText: translate('orders.created', 'Created'),
    modifiedText: translate('orders.modified', 'Modified'),
    tableCaption: () => {
      switch (props.viewType) {
        case 'allocation':
          return translate('orders.allocation_table_caption', 'Table showing allocation data');
        case 'orderSummary':
          return translate('orders.order_summary_table_caption', 'Table showing order summary data');
        case 'demandDetail':
          return translate('orders.demand_detail_table_caption', 'Table showing demand detail data');
        case 'engagement':
          return translate('orders.engagement_table_caption', 'Table showing engagement data');
        default :
          return null;
      }
    },
  })),
  withAnalytics((ev, view) => ({
    columnSortEvent: (column, sort) => {
      ev(events.FULL_ORDERBOOK_SORT(column, sort));
    }
  })),
)(FullOrderBookTable);
