import { put, cancel, fork, call, takeEvery, takeLatest, select, all } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';

import Types, { UtilityTypes } from 'actions/Types';

import { IsvUtilityTypes } from '../../Isv/actions/IsvTypes';

import { logout } from 'actions/LoginActions';

import { apiRequest, apiFailure, tileModuleFetched, addCalledEndpoint, unauthorizedResponseEffect } from 'actions/UtilityActions';

import { getPollings } from 'selectors';

export function* smartEndpointFilter(action) {
    //
    // Smart request handling (prevent duplicated request in one event notification cycle)
    // if we will gonna have params -> please change string url comparison to object with url and params (for not its enough)
    //
    const pollings = yield select(getPollings);
    const dirty = pollings[action.feature].dirty;

    if (!dirty) {
        const found = pollings[action.feature].calledUrls.some(url => url === action.endPoint);
        if (found) {
            // feature is not dirty(pristine) and endpoint for current feature was already fetched (its fresh/up to date)
            return false;
        }

        yield put(addCalledEndpoint(action.feature, action.endPoint));
    }

    return true;
}

export function* handleBatchedRequests(api, ...actions) {

    let calls = [];
    for (let i = 0; i < actions.length; i++) {
        if (actions[i].feature) {
            const shouldContinue = yield smartEndpointFilter(actions[i]);
            if (shouldContinue) {
                calls.push({ action: actions[i], i });
            }
            // otherwise we do not need reuqest that data ( feature at this cycle requested it already - pristine state)
        }
    }

    const responses = yield all(calls.map(({ action: a }) => call(api.getData().req, a.endPoint, a.params)));

    const batched = [];
    for (let i = 0; i < calls.length; i++) {
        const response = responses[i];
        const action = calls[i].action;

        if (response.status === 200) {
            batched.push(action.onSuccess(response.data, action.responseDetails));

            if (action.tile) {
                batched.push(tileModuleFetched(action.tile));
            }
        } else if (response.status === 401) {
            yield put(unauthorizedResponseEffect(action.feature));
            return;
        } else {
            yield put(apiFailure(`Bad data response from action: ${action.type}`));
        }
    }

    // prevent flushing ( dispatch all actions at one shot )
    if (batched.length) {
        yield put(batchActions(batched));
    }
}

export default (api, dispatch, getState) => {
    function* worker(action) {
        let apiType;

        // yes we're not using anywhere cancelReq token (yet)
        switch (action.ajaxType) {
            case 'POST': {
                apiType = api.postData();
                break;
            }
            case 'PATCH': {
                apiType = api.patchData();
                break;
            }
            case 'PUT': {
                apiType = api.putData();
                break;
            }
            case 'DELETE': {
                apiType = api.deleteData();
                break;
            }
            case 'GETCREDS': {
                apiType = api.getDataCreds();
                break;
            }
            default: {
                apiType = api.getData();
                break;
            }
        }

        try {
            const { loader, params } = action;

            if (action.feature) {
                const shouldContinue = yield smartEndpointFilter(action);
                if (!shouldContinue) {
                    return;
                }
            }

            if (loader) {
                yield put(apiRequest(true));
            }

            const response = yield call(apiType.req, action.endPoint, params);

            switch (response.status) {
                case 200:
                case 204: {
                    yield put(action.onSuccess(response.data, action.responseDetails));

                    if (action.tile) {
                        yield put(tileModuleFetched(action.tile));
                    }

                    break;
                }
                case 401: {
                    if (action.type !== 'API_ATTEMPT_SSO' && action.type !== 'API_ATTEMPT_LOGIN'
                      && action.type !== 'API_GET_ACCEPT_TERMS_REQUEST') {
                        if (action.feature) {
                            // check with fresh deal info data -> if hidden -> go home otherwise logout
                            yield put(unauthorizedResponseEffect(action.feature));
                        } else {
                            yield put(logout());
                        }
                    }
                    else {
                      // TODO: yield put unable to login failure
                      yield put(action.onFailure());
                    }
                    break;
                }
                default: {
                    yield put(apiFailure(`Bad data response from action: ${action.type}`));
                    break;
                }
            }
        } catch (error) {
            yield put(apiFailure(`Bad Request ${error}`));
        } finally {
            if (action.loader) {
                yield put(apiRequest(false));
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            Types.LOGOFF,
            // orderbook feature
            Types.API_REQUEST_TRANCHES,
            Types.API_REQUEST_ORDERS,
            Types.API_REQUEST_INVESTOR_NAMES,
            Types.API_REQUEST_ORDER_SUMMARY,
            Types.API_REQUEST_ORDERS_FOR_ALL_TRANCHES,
            Types.API_REQUEST_TRANCHE_LISTS,
            // ordebook ecm & dcm ( depends on params )
            Types.API_REQUEST_DEMAND_SUMMARY_BY_TYPE,
            Types.API_REQUEST_DEMAND_SUMMARY_BY_SHARE_AND_TYPE,
            // orderbook only ecm feature
            Types.API_REQUEST_ECM_ORDERS,
            Types.API_REQUEST_SHARE_DEMAND,
            // orderbook dcm feature
            // (empty)
            // roadshow feature
            Types.API_REQUEST_ROADSHOW_SUMMARY,
            Types.API_REQUEST_ROADSHOW_EVENTS,
            Types.API_REQUEST_ROADSHOW_CONTACTS,
            // mydocs feature
            Types.API_REQUEST_DOCUMENTS,
            Types.API_REQUEST_DOCUMENTS_SUMMARY,
            // investor feedback feature
            Types.API_REQUEST_FEEDBACK_THEME_TYPES,
            Types.API_REQUEST_FEEDBACK_SENTIMENTS,
            Types.API_REQUEST_DEAL_THEMES,
            Types.API_REQUEST_FEEDBACK_FOR_DEAL,
            Types.API_REQUEST_FEEDBACK_FOR_DEAL_AND_THEME,
            Types.API_REQUEST_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE,
            Types.API_REQUEST_FEEDBACK_CHART_TYPES,
            Types.API_REQUEST_FEEDBACK_CHART_DATA,
            // pricing
            Types.API_REQUEST_PRICING_DOCUMENT_ID,
            Types.API_PRE_AUTH_ERR_LOG,
            // auth
            Types.API_ATTEMPT_SSO,
            Types.API_ATTEMPT_LOGIN,
            // v1.7
            UtilityTypes.API_GET_DEAL_REQUEST,
            UtilityTypes.API_GET_DEAL_INFO_REQUEST,
            UtilityTypes.API_GET_DEALS_REQUEST,

            UtilityTypes.API_GET_SITE_TEXT_REQUEST,

            
            // IsvUtilityTypes.API_GET_ISV_DEAL_INFO_REQUEST,
            IsvUtilityTypes.API_GET_ISV_DEALS_REQUEST,
            IsvUtilityTypes.API_GET_ISV_DEAL_REQUEST,
            IsvUtilityTypes.API_ACCEPT_LETTER,
            Types.API_GET_ACCEPT_TERMS_REQUEST,
            Types.API_GET_TERMS_AND_CONDITIONS,


        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
