import React, { useEffect, useState } from 'react'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SvgIcon } from 'shared/components/Icon';
import { filterOrderSummary, selectOrderSummaryDropdown, filterBookEvolutionSummary } from 'actions/OrderBookActions';
import { TIME_INTERVALS, DEFAULT_ALL, ALL_INVESTORS } from 'constants/orders';
import { getSelectedInvestor, getSelectedTranche, getSelectedInterval, getSelectedDate } from 'selectors';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const styles = {
    display: 'flex',
    alignItems: 'center'
}

const DropDownContainer = styled.div`
    ${styles}
`;

const InvestorDropDownContainer = styled.div`
    ${styles}
    span {
        margin-right: 10px
    }
`;

const TranchesDropDownContainer = styled.div`
    ${styles}
    span {
        margin: 0 10px
    }
`;

const DropDownLabel = styled.span``;

const arrowDropdownIconStyle = {
    transform: 'rotate(90deg)',
    marginTop: '2px',
    width: '18px',
    height: '18px',
}

const data = { value: 0 };

const OrderSummaryDropdownList = ({
    dealId,
    selectedInvestor,
    selectedTranche,
    selectedInterval,
    selectedDate,
    investorNameText,
    trancheNameText,
    timeIntervalText,
    allInvestorLists,
    allTranchelists,
    selectOrderSummaryDropdown,
    filterOrderSummary,
    isOrderBookSummary,
    filterBookEvolutionSummary,
    getDateSummary,
    dateText,
    isAmenededOrderSummary,
    isBookEvolution,
}) => {

    const [amendedInvestor, setAmendedInvestor] = useState(false)

    useEffect(() => {
        if (isAmenededOrderSummary && selectedInvestor.value === ALL_INVESTORS.value) {
            const data = allInvestorLists.filter((item) => item.key === ALL_INVESTORS.key);
            if (!data.length) {
                setAmendedInvestor(allInvestorLists[0]);
            }
        }
    }, [])

    const setDateSummary = (getDateSummary, selectedDate) => {
        const index = getDateSummary.findIndex(list => list.value === selectedDate.value);
        index > 0 && (data.value = index);
        const isListed = getDateSummary.some((el) => el.value === selectedDate.value);
        !isListed && getDateSummary.splice(data.value, 0, selectedDate);
        return getDateSummary
    }

    const onDropdownChange = (e, investorName, trancheName, interval, date, isDateUpdated) => {
        if ((amendedInvestor ? amendedInvestor.value : selectedInvestor.value) !== investorName.value || selectedTranche.value !== trancheName.value ||
            selectedInterval.value !== interval.value || selectedDate.value !== date.value) {
            setAmendedInvestor(false);
            selectOrderSummaryDropdown(investorName, trancheName, interval, date);
            if (isBookEvolution) { !isDateUpdated && filterBookEvolutionSummary(dealId, interval.key, investorName.key, trancheName.key, true, true) }
            else if (isOrderBookSummary) { !isDateUpdated && filterOrderSummary(dealId, interval.key, investorName.key, trancheName.key, true, true) }
        }
    }

    const getInvestorValue = value => {
        return value.length > 15 ? value.slice(0, 14) + "..." : value;
    }

    return (
        <div className="ordersummary-dropdown">
            <DropDownContainer>
                <InvestorDropDownContainer>
                    <DropDownLabel>{investorNameText}:</DropDownLabel>
                    <Autocomplete
                       disablePortal
                        id="order-investor-name"
                        options={allInvestorLists}
                        disableClearable
                        sx={{ width: 196 }}
                        popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                        value={getInvestorValue(amendedInvestor ? amendedInvestor.value : selectedInvestor.value)}
                        onChange={(e, value) => onDropdownChange(e, value ? value : selectedInvestor, selectedTranche, selectedInterval, DEFAULT_ALL)}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </InvestorDropDownContainer>

                <TranchesDropDownContainer>
                    <DropDownLabel>{trancheNameText}:</DropDownLabel>
                    <Autocomplete
                        disablePortal
                        id="order-tranche-name"
                        options={allTranchelists}
                        disableClearable
                        sx={{ width: 196 }}
                        popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                        value={selectedTranche.value.length > 17 ? selectedTranche.value.slice(0, 16) + "..." : selectedTranche.value }
                        onChange={(e, value) => onDropdownChange(e, amendedInvestor ? amendedInvestor : selectedInvestor, value ? value : selectedTranche, selectedInterval, selectedDate || DEFAULT_ALL)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        isOptionEqualToValue={(option, value) => option.value === value}
                    />
                </TranchesDropDownContainer>

                {
                    !isAmenededOrderSummary &&
                    <>
                        <TranchesDropDownContainer>
                            <DropDownLabel>{dateText} :</DropDownLabel>
                            <Autocomplete
                                disablePortal
                                id="order-dates"
                                options={setDateSummary(getDateSummary, selectedDate)}
                                disableClearable
                                sx={{ width: 196 }}
                                popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                                value={selectedDate.value}                                
                                onChange={(e, value) => onDropdownChange(e, selectedInvestor, selectedTranche, selectedInterval, value ? value : selectedDate, true)}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                isOptionEqualToValue={(option, value) => option.value === value}
                            />
                        </TranchesDropDownContainer>

                        <TranchesDropDownContainer>
                            <DropDownLabel>{timeIntervalText}:</DropDownLabel>
                            <Autocomplete
                                disablePortal
                                id="order-intervals"
                                options={TIME_INTERVALS}
                                disableClearable
                                sx={{ width: 196 }}
                                popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                                value={selectedInterval.value}
                                onChange={(e, value) => onDropdownChange(e, selectedInvestor, selectedTranche, value ? value : selectedInterval, selectedDate)}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                isOptionEqualToValue={(option, value) => option.value === value}
                            />
                        </TranchesDropDownContainer>
                    </>
                }
            </DropDownContainer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedInvestor: getSelectedInvestor(state),
    selectedTranche: getSelectedTranche(state),
    selectedInterval: getSelectedInterval(state),
    selectedDate: getSelectedDate(state),
});

const mapDispatchToProps = dispatch => ({
    selectOrderSummaryDropdown: (investorId, trancheId, interval, date) => dispatch(selectOrderSummaryDropdown(investorId, trancheId, interval, date)),
    filterOrderSummary: (dealId, interval, investorId, trancheId, loader, isFilter) => dispatch(filterOrderSummary(dealId, investorId, trancheId, interval, loader, isFilter)),
    filterBookEvolutionSummary: (dealId, interval, investorId, trancheId, loader, isFilter) => dispatch(filterBookEvolutionSummary(dealId, investorId, trancheId, interval, loader, isFilter)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(OrderSummaryDropdownList)
