import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { compose } from 'recompose';
// TODO
import { ValueFormatters, VisualisationColors } from 'mk-pattern-library-react';
import { numberFormatters } from 'shared/functions/formatters';
import TranslationText from 'translations/TranslationText';
import withTranslate from 'shared/hoc/withTranslate';
import ColorChip from './ColorChip';
import Table, {
  firstHeaderAlignedLeftStyle,
  firstRowAlignedLeftStyle,
  otherHeaderAlignedRightStyle,
  otherRowAlignedRight,
} from 'shared/components/Table';
import PieChart from 'shared/components/PieChart';
import Popover from '@mui/material/Popover';
import { PrimaryButton } from 'mk-pattern-library-react';
import { typography } from 'shared/styles/variables/theme'
import Draggable from 'react-draggable';

const Wrapper = styled.div`
  padding: 20px;
`;
const PanelLabel = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 900;
  text-align: left;
  width: 100%;
`;
const PieChartWrapper = styled.div`
  padding-left: 12px;
  margin: auto;
`;
const EnhancedCol = styled(Col)`
  table {
    table-layout: fixed;
  }
`;

const closeBtnStyle = {
  fontSize: typography.sizes['14px'],
  padding: '8px',
  color: '#FFFFFF',
  border: 'none',
  width: 80,
  display: 'block',
  margin: '0 0 0 auto'
};

class DemandByCarouselPanelPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      demandModalStatus: null,
      defaultPosition: {},
    };

    this.handleColorFormatter = this.handleColorFormatter.bind(this);
  }

  Columns = (props, isNotMainScreen) => {
    const columns = [{
      dataField: 'dataItem',
      text: props.type,
      headerStyle: {
        ...firstHeaderAlignedLeftStyle,
        paddingLeft: 8,
        width: '130px',
      },
      style: {
        ...firstRowAlignedLeftStyle,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingTop: 1,
        width: '130px',
      },
      formatExtraData: props.colorsPalette,
      formatter: props.handleColorFormatter,
      events: {
        onClick: (e, column, columnIndex, row) => {
          if (row.dataItem === 'Other' && row.otherValues && row.otherValues.length && !isNotMainScreen) {
            const defaultPosition = {
              x: 0,
              y: 0,
              top: -e.clientY,
              right: document.getElementsByClassName('mydeal-app-content container')[0].offsetWidth - 400
            }
            this.setState({ defaultPosition })
            this.handlePopOver(e.currentTarget)
          }
        },
      },
    }];

    if (props.tableType !== 'dcm') {
      columns.push({
        dataField: 'totalOrders',
        headerStyle: {
          ...otherHeaderAlignedRightStyle,
          width: '100px',
        },
        headerFormatter: () => (
          <React.Fragment>
            <TranslationText
              dictionaryKey={"orders.demand"}
              defaultText={"Demand"}
            />
              &nbsp;@<br />
            {
              ValueFormatters.commaDecimalFormatter(props.sharePrice)
            }
            ({ props.currency}m)
          </React.Fragment>
        ),
        style: {
          ...otherRowAlignedRight,
          width: '100px',
        },
        formatExtraData: props.colorsPalette,
        formatter: ValueFormatters.millionFormatter,
      });
    } else {
      columns.push({
        dataField: 'totalOrders',
        headerStyle: {
          ...otherHeaderAlignedRightStyle,
          width: '60px',
        },
        headerFormatter: () => (
          <React.Fragment>
            <TranslationText
              dictionaryKey={"orders.total"}
              defaultText={"Total"}
              style={{ whiteSpace: 'nowrap' }}
            />
            <br />(m)
          </React.Fragment>
        ),
        style: {
          ...otherRowAlignedRight,
          width: '60px',
        },
        formatExtraData: props.colorsPalette,
        formatter: ValueFormatters.millionFormatter,
      });
    }

    columns.push({
      dataField: 'percent',
      text: props.percentText,
      headerStyle: {
        ...otherHeaderAlignedRightStyle,
        width: '70px',
      },
      style: {
        ...otherRowAlignedRight,
        width: '70px',
      },
      formatExtraData: props.colorsPalette,
      formatter: numberFormatters.oneDecimalPointPercent,
    });

    columns.push({
      dataField: 'orders',
      text: '#',
      headerStyle: {
        ...otherHeaderAlignedRightStyle,
        width: '40px',
      },
      style: {
        ...otherRowAlignedRight,
        width: '40px',
      },
    });

    return columns;
  };

  titleHoverEl = (val: any) => <span title={val}>{val}</span>;

  colorChipFormatterEl = (colorsPalette, val: any, obj: Object, prop: string, index: number) => {
    const colorHex = colorsPalette(index, val);
    return <ColorChip fullChip description={this.titleHoverEl(val)} title={val} colorHex={colorHex} />;
  };

  handleColorFormatter(value, data, index, palette) {
    const colorsPalette = this.props.wideColorPalette ? VisualisationColors.extendedColors : VisualisationColors.colors;
    return this.colorChipFormatterEl(colorsPalette, value, data, null, index);
  }

  setRowBackgroundStyle = (row) => {
    if (row.dataItem === 'Other' && row.otherValues && row.otherValues.length) {
      return 'custom-underlined-class';
    }
  }

  handlePopOver = (status) => {
    this.setState({ demandModalStatus: status })
  }

  onDragStart = (event, data) => {
    const defaultPosition = { x: data.lastX, y: data.lastY, top: this.state.defaultPosition.top, right: this.state.defaultPosition.right }
    this.setState({ defaultPosition })
  }

  render() {
    const { demandModalStatus, defaultPosition } = this.state;
    if (!this.props.chartData.length) return null

    const colorsPalette = this.props.wideColorPalette ? VisualisationColors.asArray : VisualisationColors.asSmallArray;
    return (
      <Row style={{ margin: 0, position: 'relative' }}>
        <Wrapper className="clearfix">
          <EnhancedCol sm={7} md={6} lg={6}>
            <PanelLabel>{this.props.panelLabel}</PanelLabel>
            <Table
              keyField="dataItem"
              data={this.props.tableData}
              columns={this.Columns({ ...this.props, colorsPalette, handleColorFormatter: this.handleColorFormatter })}
              caption={this.props.tableCaption}
              rowClasses={this.props.popoverData && this.props.popoverData.isPopOverEnabled && this.setRowBackgroundStyle}
            />
            {
              this.props.popoverData && this.props.popoverData.isPopOverEnabled &&
              <Draggable
                bounds={{ left: -50, bottom: 0, top: defaultPosition.top, right: defaultPosition.right }}
                position={defaultPosition}
                onDrag={this.onDragStart}>
                <Popover
                  open={Boolean(demandModalStatus)}
                  anchorEl={demandModalStatus}
                  onClose={() => this.handlePopOver(null)}
                  marginThreshold={0}
                >
                  <div className="demand-summary-popup">
                    <Table
                      keyField="dataItem"
                      classes={this.props.popoverData.otherTableData.length > 3 ? 'scrollAlign': '' }
                      data={this.props.popoverData.otherTableData}
                      columns={this.Columns({ ...this.props }, true)}
                      caption={this.props.tableCaption}
                    />
                    <PrimaryButton
                      size="normal"
                      onClick={() => this.handlePopOver(null)}
                      style={closeBtnStyle}>
                      <TranslationText
                        dictionaryKey={"others.close"}
                        defaultText={"others.close"}
                      />
                    </PrimaryButton>
                  </div>
                </Popover>
              </Draggable>
            }
          </EnhancedCol>

          <Col sm={5} md={6} lg={6} aria-hidden>
            <PieChartWrapper>
              <PieChart
                data={this.props.chartData}
                wideColorPalette={this.props.wideColorPalette}
                animationDuration={750}
              />
            </PieChartWrapper>
          </Col>

        </Wrapper>
      </Row>
    );
  }
}

export default compose(
  withTranslate((translate, props) => ({
    percentText: translate("orders.percent", "Percent"),
    tableCaption: translate('orders.demand_table_caption', `Table showing demand by ${props.type}`),
  }))
)(DemandByCarouselPanelPie);
