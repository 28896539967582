import {
  cancel,
  fork,
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects";
import IsvTypes from "Isv/actions/IsvTypes";
import { create } from "services/api";
import { receiveIsvDeal } from "Isv/actions/IsvUtilityActions";
import { requestIsvDeal, apiFailure } from 'Isv/actions/IsvUtilityActions';
import { handleGetLatestCommentsByDealIdResponse, getLatestCommentsByDealId, handleGetLatestCommentsByCommentsId, getECB2ReturnDataForCurrentDeal } from 'Isv/actions/IsvDocumentsAction';
import { isIsvExternalUser } from "Isv/selectors";

export default (api, dispatch, getState) => {
  function* worker(action) {

    function* handleRequest(req, endPoint, params, success) {
      try {
          const response = yield call(req, endPoint, params);
          response.ok ? yield put(success(response.data)) : yield put(apiFailure('Bad Response'));
      } catch (error) {
          yield put(apiFailure('Bad call: ' + error));
      }
  }

    switch (action.type) {
      case IsvTypes.API_APPROVE_REJECT_REQUEST_STATUS: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const apiType = api.putData();
        const response = yield call(
          apiType.req,
          action.endPoint,
          action.params
        );
        if (response.ok) {
          let data = { ...action.selectedDealData };
          if (data.dealDocumentMapping && data.dealDocumentMapping.length > 0 && !action.fromEcb2Return) {
            let updatedObjIndex =
              data.clientDocumentFile &&
              data.clientDocumentFile.length > 0 &&
              data.clientDocumentFile.findIndex(
                (x) => x.clientFileId === action.params.clientFileId
              );
            if (action.isIsvCsmUser) {
              data.clientDocumentFile[updatedObjIndex].isCsmApproved =
                response.data.isCsmApproved;
            } else {
              data.clientDocumentFile[updatedObjIndex].isRrApproved =
                response.data.isRrApproved;
            }
            yield put(receiveIsvDeal(data));
          }
          else if (action.fromEcb2Return) {
            yield put(getECB2ReturnDataForCurrentDeal(action.dealId));
            const requestIsvDealAction = requestIsvDeal(action.dealId, false);
            yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
          }
        }
        break;
      }

      case IsvTypes.API_REQUEST_SUBMIT_INFO:
      case IsvTypes.API_REQUEST_SUBMIT_FINAL_SUBMISSION: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const apiType = api.putData();
        const response = yield call(
          apiType.req,
          action.endPoint,
          action.params
        );
        if (response.ok) {
            const requestIsvDealAction = requestIsvDeal(action.dealId, false);
            yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
        }
        
        break;
      }

      case IsvTypes.API_REQUEST_CLIENT_ECB_SUBMIT: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
         const apiType = api.putData();
         const response = yield call(apiType.req, action.endPoint, action.params);
         if (response.ok) {
             const requestIsvDealAction = requestIsvDeal(action.dealId, false);
             yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
             yield put(dispatch(getECB2ReturnDataForCurrentDeal(action.dealId)));
         }
         break
      }

      case IsvTypes.API_REQUEST_SUBMIT_DOCUEMENT_COMMENT: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const apiType = api.putData();
        const response = yield call(
          apiType.req,
          action.endPoint,
          action.params
        );
        if(response.ok) {
          yield put(getLatestCommentsByDealId(action.params.dealId, action.params.isClientScreen ? true : null, action.params.commentText.IsECBReturnVisible))
        }
        break;
      }

      case IsvTypes.API_REQUEST_LATEST_COMMENTS_FOR_CURRENT_DEAL: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const apiType = api.getData();
        const isIsvExtUser = yield select(isIsvExternalUser);
        action.params.isClientScreen = isIsvExtUser ? true : null;
        const response = yield call(apiType.req, action.endPoint, action.params);
        if (response.ok) {
          yield put(handleGetLatestCommentsByDealIdResponse(response.data));
        }
        break;
      }

      case IsvTypes.API_REQUEST_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT: {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const apiType = api.getData();
        const response = yield call(apiType.req, action.endPoint, action.params);
        if (response.ok) {
          yield put(handleGetLatestCommentsByCommentsId(response.data));
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  function* watcher() {
    const watcherTasks = yield fork(takeEvery, [
      IsvTypes.API_APPROVE_REJECT_REQUEST_STATUS,
      IsvTypes.API_REQUEST_SUBMIT_INFO,
      IsvTypes.API_REQUEST_SUBMIT_DOCUEMENT_COMMENT,
      IsvTypes.API_REQUEST_LATEST_COMMENTS_FOR_CURRENT_DEAL,
      IsvTypes.API_REQUEST_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT,
      IsvTypes.API_REQUEST_SUBMIT_FINAL_SUBMISSION,
      IsvTypes.API_REQUEST_CLIENT_ECB_SUBMIT
    ], worker);

    /* eslint-disable-next-line */
    yield fork(
      takeLatest,
      IsvTypes.KILL_ACTIVE_SAGA_WORKERS,
      cancelWorkerSaga,
      watcherTasks
    );
  }

  function* cancelWorkerSaga(task) {
    yield cancel(task);
    yield fork(watcher);
  }

  return {
    watcher,
    worker,
  };
};
