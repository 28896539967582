import React, { PureComponent, Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Row, Col } from 'react-bootstrap';
import Switch from "react-switch";
import { SvgIcon } from 'shared/components/Icon';
import Notification from 'shared/components/Notifications';
import TermsAndConditionsLink from 'components/TermsAndConditionsLink';

import SiteTextModal from 'components/SiteTextModal'

import withTranslate from 'shared/hoc/withTranslate';
import TranslationText, { translationString } from 'translations/TranslationText';
import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';

import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';

import { deactivateDevice, viewDeviceBiometrics } from 'actions/LoginActions';

import { EULA_MODAL_ID, BIOMETRIC_TERMS_MODAL_ID, BIOMETRIC_FAQ_MODAL_ID, CONTACT_US_MODAL_ID } from 'constants/modalIds';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';
import { Link } from 'react-router';
import Popup from 'shared/components/Popup';
import * as modalIds from 'constants/modalIds';
import PopupSystem from 'shared/components/PopupSystem';
import { components, outlines } from 'shared/styles/variables/theme';

const SettingsHeader = styled.h5`
    background-color: #ededed;
    padding: 0.5em 1em;
    font-weight: 600;
    margin-bottom: 1em;
`;

const SettingsList = styled.ul`
    margin: 0;
    padding: 0 0 1em 0.5em;
    position: relative;
    & li {
        border-bottom: solid 1px #ededed;
        list-style: none;
        padding: 0.5em;
        
    }
    & li:first-child {
        border-top: solid 1px #ededed;
    }
`;
const StyledLink = styled.a`
    color:black
    ${components.link['--default']}

    &:focus {
        ${components.link['--state']['--focus']}
        outline: ${outlines.dark};
    }

    &:hover {
        ${components.link['--state']['--hover']}
        text-decoration: underline;
    }

    &.disabled {
        ${components.link['--state']['--disabled']}
    }
`;
const ModalIcon = props => (
    <SvgIcon
        icon="pop-up-window"
        aria-hidden
        style={{ fill: 'rgb(0, 0, 0)', marginRight: 10 }}
    />
);

const SwitchContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 9px;
`;

const StyledListItem = styled.li`
    position: relative;
`;
const url = {
    pathname: featurePageIds.DATAPRIVACY_NOTICE_PAGE,
};
const cookieurl = {
    pathname: featurePageIds.COOKIE_NOTICE_PAGE,
};

const ManageDevice = props => {
    const handleChange = () => {
       props.updateFlag();
    }

    return (
        <StyledListItem tabIndex={0}>
            <label>
                <TranslationText
                    dictionaryKey={"settings.log_on_with_biometrics_on_device"}
                    defaultText={"Log on with biometrics on device "}
                />
                {props.device.deviceName}
                <SwitchContainer>
                    <Switch
                        onChange={handleChange}
                        checked={props.device.enabled}
                        disabled={!props.device.enabled}
                        height={20}
                        width={48}
                    />
                </SwitchContainer>
            </label>
        </StyledListItem>
    )
}

class Settings extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            popUpFlag                : false,
            userRegisteredDeviceId   : null,
            deviceName               : 'This Device'
        };

        this.updatePopUpFlag        = this.updatePopUpFlag.bind(this);
        this.deactivateThisDevice   = this.deactivateThisDevice.bind(this);
    }

    updatePopUpFlag = (device = {}) => {
        const {
            userRegisteredDeviceId = null,
            deviceName = '',
        } = device;

        this.setState({ 
            popUpFlag:!this.state.popUpFlag, 
            userRegisteredDeviceId, 
            deviceName 
        });
    }

    deactivateThisDevice() {
        this.props.deactivateDevice(this.state.userRegisteredDeviceId);
        this.updatePopUpFlag();
    }
    
    componentDidMount() {
        this.props.viewChange(featurePageIds.SETTINGS_PAGE);
        this.props.viewDeviceBiometrics();
    }

    render() {
        return (
            <FeatureContainer
                role="region"
                tabIndex={0}
                aria-label={this.props.containerRegionAriaLabel}
            >
                <HeaderContainer>
                    <Col sm={6}>
                        <FeatureHeading className="text-xxlarge text-univ-bd">
                            <TranslationText
                                dictionaryKey={"settings.settings"}
                                defaultText={"Settings"}
                            />
                        </FeatureHeading>
                        <HeadingDescription invisible top={5}>
                            <TranslationText
                                dictionaryKey={"settings.header_description"}
                                defaultText={"MyDeal policy documents and biometric device settings"}
                            />
                        </HeadingDescription>
                    </Col>
                </HeaderContainer>
                <Row style={{ width: '70%', margin: '0px auto'}}>
                    <SettingsHeader>
                        <TranslationText
                            dictionaryKey={"settings.general"}
                            defaultText={"General"}
                        />
                    </SettingsHeader>
                    <SettingsList>  
                        <li>
                            <TermsAndConditionsLink />
                        </li>
                        <li>
                            <SiteTextModal
                                modalId={EULA_MODAL_ID}
                                siteTextType={SITE_TEXT_TYPES.EULA}
                                linkText={this.props.eulaText}
                                tabIndex={0}
                                aria-label={
                                    translationString(
                                        'settings.open_end_user_license_agreement',
                                        this.props.language,
                                        'Open end user license agreement'
                                    )
                                }>
                            </SiteTextModal>
                        </li>
                        <li>
                        <ModalIcon />
                            <StyledLink>
                                <Link to={url} target="_blank"  style={{color:"black"}} >{this.props.dataPrivacyPolicyText}</Link>
                            </StyledLink>
                        </li>
                        <li>
                        <ModalIcon />
                            <StyledLink>
                                <Link to={cookieurl} target="_blank"  style={{color:"black"}} >{this.props.cookiePolicyText}</Link>
                            </StyledLink>
                        </li>
                        <li>
                            <SiteTextModal
                                modalId={CONTACT_US_MODAL_ID}
                                siteTextType={SITE_TEXT_TYPES.CONTACT_US}
                                linkText={this.props.contactUsText}
                                tabIndex={0}
                                width={630}
                                aria-label={
                                    translationString(
                                        'settings.open_contact_us',
                                        this.props.language,
                                        'Open contact us'
                                    )
                                }
                                >
                            </SiteTextModal>
                        </li>
                    </SettingsList>
                    
                    <SettingsHeader>
                        <TranslationText
                            dictionaryKey={"settings.biometric_log_on"}
                            defaultText={"Biometric Log On"}
                        />
                    </SettingsHeader>
                    {
                        this.props.deviceDeactivationMessage === "ERROR" ?
                            <Notification
                                error
                                headline={"Error"}
                                description={"Unable to deactive biometrics on this device"}
                            /> : null
                        
                    }
                    {
                        this.props.deviceDeactivationMessage === "INFO" ?
                            <Notification
                                info
                                headline={"Success"}
                                description={"Biometrics disabled on device"}
                            /> : null
                    }
                    
                    <SettingsList
                        
                    >
                        <li>
                            <SiteTextModal
                                modalId={BIOMETRIC_TERMS_MODAL_ID}
                                siteTextType={SITE_TEXT_TYPES.BIOMETRIC_TERMS}
                                linkText={this.props.biometricTermsText}
                                clickableElement={
                                    <Fragment><ModalIcon />{this.props.biometricTermsText}</Fragment>
                                }
                                aria-label={
                                    translationString(
                                        'settings.open_biometric_terms_and_conditions',
                                        this.props.language,
                                        'Open biometric terms and conditions'
                                    )
                                }
                                tabIndex={0}>
                            </SiteTextModal>
                        </li>
                        <li>
                            <SiteTextModal
                                modalId={BIOMETRIC_FAQ_MODAL_ID}
                                siteTextType={SITE_TEXT_TYPES.BIOMETRIC_FAQ}
                                linkText={this.props.biometricFaqText}
                                clickableElement={
                                    <Fragment><ModalIcon />{this.props.faqText}</Fragment>
                                }
                                aria-label={
                                    translationString(
                                        'settings.open_faq',
                                        this.props.language,
                                        'Open biometric frequently asked questions'
                                    )
                                }
                                tabIndex={0}
                            >
                            </SiteTextModal>
                        </li>
                        {
                            this.props.manageDevices && this.props.manageDevices.map(device =>
                                <ManageDevice
                                    device={device}
                                    updateFlag={() => this.updatePopUpFlag(device)}
                                />
                            )
                        }
                        {
                            (
                                <PopupSystem
                                    modalID={modalIds.BIOMETRIC_ON_DEVICE_MODAL_ID}
                                    isModalOpen={this.state.popUpFlag}
                                    centered
                                    backgroundLayer
                                    zIndex={3001}
                                    closeModal={() => null}
                                    clickableElement={null}
                                    content={
                                        <Popup
                                            static
                                            width={300}
                                            primaryButton
                                            primaryButtonContent="Yes"
                                            primaryButtonAction={this.deactivateThisDevice}
                                            primaryButtonKey="Yes"
                                            primaryButtonStyle={{ padding: '14px 35px', fontSize : '1rem'}}
                                            secondaryButton
                                            secondaryButtonContent="No"
                                            secondaryButtonAction={this.updatePopUpFlag}
                                            secondaryButtonKey="No"
                                            secondaryButtonStyle={{ padding: '14px 35px', fontSize : '1rem'}}
                                        >
                                            <div style={{ textAlign : 'center' }}>
                                                <h3>
                                                    <TranslationText
                                                        dictionaryKey={"settings.header_description"}
                                                        defaultText={"Information"}
                                                    />
                                                </h3>
                                                <p>
                                                    <TranslationText
                                                        dictionaryKey={"settings.header_description"}
                                                        defaultText={`This will deactivate biometric authentication on '${this.state.deviceName}'. You can re-activate at any time through your iPad. Are you sure?`}
                                                    />
                                                </p>
                                            </div>
                                        </Popup>
                                   }
                              />
                            )
                        }
                    </SettingsList>
                </Row>
            </FeatureContainer>
        )
    }
}

export default compose(
    connect(
        state => ({
            manageDevices: state.login.devices,
            deviceDeactivationMessage: state.login.deviceDeactivationMessage,
        }),
        dispatch => ({
            deactivateDevice: deviceId => {
                dispatch(deactivateDevice(deviceId));
            },
            viewDeviceBiometrics: () => {
                dispatch(viewDeviceBiometrics())
            },
        })
    ),
    withAnalytics((__, viewChange) => ({
        viewChange: viewId => viewChange(viewId),
    })),
    withTranslate((translate, props) => ({
        containerRegionAriaLabel: translate('settings.settings', 'Settings'),
        termsAndConditionsText: translate('settings.terms_and_conditions', 'Terms and Conditions'),
        dataPrivacyPolicyText: translate('settings.privacy_notice', 'Privacy Notice'),
        eulaText: translate('settings.eula', 'End User License Agreement'),
        cookiePolicyText: translate('settings.cookie_notice', 'Cookie Notice'),
        contactUsText: translate('settings.contact_us', 'Contact Us'),
        faqText: translate('settings.faq', 'Frequently Asked Questions'),
        biometricTermsText: translate('settings.biometric_terms', 'Biometric Terms and Conditions'),
        biometricFaqText: translate('settings.biometric_faq', 'Biometric Frequently Asked Questions'),
    }))
)(Settings);