/* @flow */
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  z-index: 3000;

  .MuiBox-root {
    .MuiLinearProgress-root {
      background-color: rgb(215, 216, 214);
      .MuiLinearProgress-bar {
        background-color: rgb(0, 133, 128);
      }
    }
  }
`;

const Heading = styled.h2`
  font-weight: normal;
  margin: 0;
`;

const Description = styled.p`
  margin: 5px 0 10px 0;
  font-size: 12px;
`;

const ProgressBar = (props) => (
  <ProgressWrapper className={`progres-bar`}>
    <Heading>Uploading new document</Heading>
    <Description>This process may take a while...</Description>
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={props.progress} />
    </Box>
  </ProgressWrapper>
)

export default compose(
  connect(
    (state) => ({
      progress: state.isvDocuments.progress,
    })
  )
)(ProgressBar);
