import { take, put, fork, takeEvery, cancel } from 'redux-saga/effects';
import * as storageKeys from 'constants/storageKeys';

import Types from 'actions/Types';

import { appSetup, requestAcceptTerms } from 'actions/UtilityActions';
import {
  loginFailure,
  setAuthentication,
  requestAuthTokenGet,
  initializing,
  showTermsAndConditions,
  attemptAuthentication,
  setAuthType,
  setUsername,
  setLoginError,
  setIsIsvUser,
} from 'actions/LoginActions';
import { heartBeatPulse } from 'actions/HeartBeatActions';

require('array.prototype.find').shim();
const jwt = require('jwt-decode');

export default (api) => {
    function* worker(action) {
        let userInfo = {};

        yield put(initializing(true));

        if (action.userData && action.userData.access_token) {
            const token = jwt(action.userData.access_token);
            const expirationDate = action.userData.noExtension ? action.userData.expires_in : (Date.now() + (action.userData.expires_in * 1000));

            window.sessionStorage.setItem(storageKeys.TOKEN, action.userData.access_token);
            window.sessionStorage.setItem(storageKeys.EXPIRATION, expirationDate);

            action.userData.expires_in = expirationDate; // add this to redux store

            userInfo = {
              ...token,
              ...action.userData
            };
            
            if (typeof (userInfo.isISVUser) === 'string') {
              userInfo.isISVUser = JSON.parse(userInfo.isISVUser);
            }
        }

        // If the T's and C's require agreement then do so here
        if (action.userData.termsAndConditionsAccepted === false) {
          yield put(showTermsAndConditions());

          const { termsAndConditionsId, accepted } = yield take(Types.ACCEPT_TERMS_CONDITIONS);

          if(!accepted) {
            yield put(setAuthType(null))
            yield put(setUsername(''))
            yield put(loginFailure());
            yield put(setLoginError(false));
            yield cancel();
          }

          yield put(requestAcceptTerms(termsAndConditionsId));
          yield take(Types.API_GET_ACCEPT_TERMS_RESPONSE);
        }

        yield put(setAuthentication(true, userInfo));
        yield put(heartBeatPulse());

        if (userInfo.isISVUser) {
          yield put(setIsIsvUser(true));
        }

        yield put(appSetup(userInfo.unique_name, userInfo.isISVUser));
        yield put(initializing(false));
    }

    function* handleInitialize(action) {
      const { token, expiration, isInternal } = action;

      yield put(initializing(true));

      if(token && expiration && expiration > Date.now()) {
        const userInfo = {
          access_token: token,
          expires_in: expiration,
          noExtension: true,
        };

        yield put(attemptAuthentication(userInfo));
      } else {
        if(isInternal) {
            yield put(requestAuthTokenGet(true));
        }
      }

      yield put(initializing(false));
    }

    function* watcher() {
      while(true) {
        const attemptAuthenticationTask = yield fork(takeEvery, Types.ATTEMPT_AUTHENTICATION, worker);
        const initializeTask = yield fork(takeEvery, Types.INITIALIZE, handleInitialize);

        yield take(Types.LOGIN_FAILURE);

        window.sessionStorage.removeItem(storageKeys.TOKEN);
        window.sessionStorage.removeItem(storageKeys.EXPIRATION);

        yield put(initializing(false));

        yield cancel(attemptAuthenticationTask);
        yield cancel(initializeTask);
      }
    }

    return {
        watcher,
        worker,
    };
};
