import { cancel, fork, takeEvery, takeLatest, put, select, } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import _ from 'lodash';

import Types, { UtilityTypes } from 'actions/Types';
import { changeTab } from 'actions/UtilityActions';
import {
    requestFeedbackForDeal,
    requestFeedbackDealThemes,
    requestFeedbackHighlightsForDealAndTheme,
    requestFeedbackChartTypes,
    requestFeedbackChartData,
    selectChartSubTab
} from 'actions/InvestorFeedbackActions';
import {
    getCurrentDealId,
    getInvestorFeedbackThemeTypes,
    getSelectedChartSubTabTitle,
    getInvestorFeedbackChartDataByChart,
    getSentimentsAndThemeTypesFetchedStatus,
    selectActualChart,
    selectFirstAvailableChart,
    selectFirstAvailableChartTab,
} from 'selectors';

import { tabIds } from 'constants/tabIds';
import dealFeatures from 'constants/dealFeatures';

import { handleBatchedRequests } from '../Requests';

export default (api, dispatch, getState) => {
    function* worker(action) {
        switch (action.type) {
            case UtilityTypes.EFFECT_REQUEST_FEATURE_DATA: {
                const { dealFeature, dealId } = action;

                if (dealFeature !== dealFeatures.INVESTOR_FEEDBACK) {
                    return;
                }

                let areThemeTypesAndSentimentsFetched;
                while (!areThemeTypesAndSentimentsFetched) {
                    // wait for arrival of theme types and sentiments ( static data )
                    areThemeTypesAndSentimentsFetched = yield select(getSentimentsAndThemeTypesFetchedStatus);
                    yield delay(100); // waiting 
                }

                const themeTypes = yield select(getInvestorFeedbackThemeTypes);
                const actionsToDispatch = [
                    requestFeedbackForDeal(dealId, false),
                    requestFeedbackDealThemes(dealId, false),
                ];
                for (let i = 0; i < themeTypes.length; i++) {
                    actionsToDispatch.push(requestFeedbackHighlightsForDealAndTheme(dealId, themeTypes[i].themeTypeId, false));
                }
                yield handleBatchedRequests(api, ...actionsToDispatch);

                // without batching -> to be handled response by this saga worker
                yield put(requestFeedbackChartTypes(dealId, false));

                break;
            }
            case Types.API_RECEIVE_FEEDBACK_CHART_TYPES: {
                const dealId = yield select(getCurrentDealId);
                const chartTypeDetails = yield select(selectActualChart);

                if (chartTypeDetails) {
                    // request data for current chart (other will be fetched lazy)
                    yield put(requestFeedbackChartData(dealId, chartTypeDetails.chartId, false));
                } else {
                    // chart is not available anymore
                    const chartCandidate = yield select(selectFirstAvailableChart);
                    const tabCandidate = yield select(selectFirstAvailableChartTab);
                    if (chartCandidate) {
                        // request data
                        yield put(requestFeedbackChartData(dealId, chartCandidate.chartId, false));
                        // select first available tab
                        yield put(changeTab(tabIds.INVESTOR_FEEDBACK_CHART_TABS, tabCandidate));
                        // select first tab with null title (after fetch will be selected properly to title)
                        yield put(selectChartSubTab(0, null));
                    }
                }
                break;
            }
            case Types.API_RECEIVE_FEEDBACK_CHART_DATA: {
                const chartTypeDetails = yield select(selectActualChart);

                if (chartTypeDetails) {
                    const selectedChartSubTabTitle = yield select(getSelectedChartSubTabTitle);
                    const newChartState = yield select(s => getInvestorFeedbackChartDataByChart(s, `feedbackChartData${chartTypeDetails.chartId}`));

                    const data = _.find(newChartState, chartSubTabData => chartSubTabData.pageTitle === selectedChartSubTabTitle)
                    if (data) {
                        // we have our chart in new state
                        const newIdx = _.indexOf(newChartState, data);
                        yield put(selectChartSubTab(newIdx, data.pageTitle)); // we found under new index our chart
                        return
                    }

                    // otherwise select first from list
                    const pageTitle = (newChartState[0] || {}).pageTitle;
                    yield put(selectChartSubTab(0, pageTitle));
                }
                
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.EFFECT_REQUEST_FEATURE_DATA,
            Types.API_RECEIVE_FEEDBACK_CHART_TYPES,
            Types.API_RECEIVE_FEEDBACK_CHART_DATA
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};