import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ParticipantDetails = (props) => {

    const { participants, participantsTitle } = props,
        spacingSm = { marginBottom: 5 },
        spacingXl = { marginBottom: 20 };
    const participantInfo = (participant, ind) => (
        <Col key={ind} sm={6}>
            <div>{participant.name}</div>
            <div>{participant.booking}</div>
            {/* There may be 2 lines of information to display here  */}
            {/* <div>{participant.telephone}</div>  */}
        </Col>
    )

    return (
        <Row className="text-normal" style={spacingXl}>
            <Col sm={12}>
                <div className="event-details-divider" style={{ marginBottom: 20, marginTop: 20 }}></div>
            </Col>
            <Col sm={12} style={{ paddingLeft: 30 }}>
                <div style={spacingSm} className="text-small text-univ-lt">{participantsTitle}</div>
                <Row>
                    {participants.map(participantInfo)}
                </Row>
            </Col>
        </Row>
    )
}

export default ParticipantDetails
