import { select, fork, takeEvery } from 'redux-saga/effects';

import * as storageKeys from 'constants/storageKeys';

import { getCurrentDealId, getUniqueName } from 'selectors';

import Types, { UtilityTypes, LanguageTypes } from 'actions/Types';

import { IsvUtilityTypes } from '../../Isv/actions/IsvTypes';

export default () => {

    function* worker(action) {
        const utilLocalStorageParser = key => {
            let data = window.localStorage.getItem(key);

            if (data) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    data = {}; // not proper JSON
                }
            } else {
                data = {};
            }

            return data;
        }

        const uniqueName = yield select(getUniqueName); // user unique name ( used in most cases with LocalStorage )

        switch (action.type) {
            case Types.ROADSHOW_EFFECT_SET_TEAM: {
                const { team } = action;
                if (!team) {
                    break; // team was not selected
                }

                const dealId = yield select(getCurrentDealId);
                if (!dealId) {
                    break; // deal was not fetched yet
                }

                let roadshowTeams = utilLocalStorageParser(storageKeys.ROADSHOW_SELECTED_TEAMS);

                // If we selected same team then prevent unnecessary save
                if (roadshowTeams[`${dealId}`] === team) {
                    break;
                }

                // set roadshow team
                roadshowTeams[`${dealId}`] = team;

                // save selected roadshow team
                window.localStorage.setItem(storageKeys.ROADSHOW_SELECTED_TEAMS, JSON.stringify(roadshowTeams));

                break;
            }
            
            case UtilityTypes.DEAL_SETUP: {
                const { dealId } = action;
                
                window.localStorage.setItem(storageKeys.SELECTED_DEAL, dealId);

                break;
            }
            case IsvUtilityTypes.SETUP_ISV_DEAL: {
                const { dealId } = action;
                window.localStorage.setItem(storageKeys.SELECTED_ISV_DEAL, dealId);

                break;
            }
            case LanguageTypes.SELECT_LANGUAGE: {
                const html = window.document.querySelector('html');
                
                if (html) {
                    html.setAttribute('lang', action.selectedLanguage);
                }

                if (!uniqueName) {
                    // login page (user not logged in)
                    window.localStorage.setItem(storageKeys.TEMP_SELECTED_LANGUAGE, action.selectedLanguage);
                } else {

                    window.localStorage.setItem(storageKeys.SELECTED_LANGUAGE, action.selectedLanguage);
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        yield fork(takeEvery, [
            Types.ROADSHOW_EFFECT_SET_TEAM,
            UtilityTypes.DEAL_SETUP,
            IsvUtilityTypes.SETUP_ISV_DEAL,
            LanguageTypes.SELECT_LANGUAGE
        ], worker);
    }

    return {
        watcher,
        worker,
    };
};
