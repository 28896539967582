import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { colors, extendedColors } from '../../utils/visualisationColors';

function renderCustomizedLabel({ payload, ...item }) {
  return (
    <text {...item} fill="#000" style={{ fontSize: 11 }}>
      {item.name}
    </text>
  )
}

const DonutChart = (props) => {
    const { data, animate, wideColorPalette } = props;
    const colorsPalette = wideColorPalette ? extendedColors : colors;

    const slices = () => (
        data.map((entry, index) => {
            const color = colorsPalette(index, entry.name);
            return <Cell key={`dt-${index}`} fill={color} />;
        })
    );

    return (
        <div style={{ width: 314, height: 270, margin: 'auto' }} >
          <ResponsiveContainer>
            <PieChart>
              <Pie
                animationDuration={750}
                data={data}
                startAngle={90}
                endAngle={-270}
                isAnimationActive={animate}
                label={renderCustomizedLabel}
                labelLine
                outerRadius={95}
              >
                {slices()}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
    );
};

export default DonutChart;

DonutChart.defaultProps = {
    animate: false,
    wideColorPalette: true,
};
