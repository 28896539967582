import { ValueFormatters } from 'mk-pattern-library-react';

const isCellEmpty = cell => !cell && cell !== false
const EMPTY_CELL_TEXT = '-'

export const millionFormatter = (cell, row, rowIndex, formatterExtraData) => {
  if (isCellEmpty(cell)) {
    return EMPTY_CELL_TEXT;
  }

  return ValueFormatters.millionFormatter(cell, row, rowIndex, formatterExtraData);
}

export const percentFormatter = (cell, row, rowIndex, formatterExtraData) => {
  if (isCellEmpty(cell)) {
    return EMPTY_CELL_TEXT;
  }

  return ValueFormatters.percentFormatter(cell, row, rowIndex, formatterExtraData);
}

export const dateFormatter = (format, cell) => {
  if (isCellEmpty(cell)) {
    return EMPTY_CELL_TEXT;
  }

  return ValueFormatters.dateFormatter(format, cell);
}

export const emptyCellFormatter = (cell, row, rowIndex, formatterExtraData) => {
  if (isCellEmpty(cell)) {
    return formatterExtraData.emptyValue;
  }

  return cell;
}
