import SortIcons from 'shared/components/SortIcon';
import SortableTableHeader from 'shared/components/SortableTableHeader';
import { SORT } from 'shared/components/SortState';
import { emptyCellFormatter } from './formatters';

export default sort => ({
  sort: false,
  sortCaret: SortIcons,
  //onSort: sort.setFromHeader,
  headerAttrs: sort.getHeaderAttributes,
  title: true,
  headerFormatter: SortableTableHeader,
  isSorted: sort.column,
  footer: '',
  columnFlex: 1,
  headerEvents: {
    onClick: (e, a) => {
      const column = e.currentTarget;
      const field = column.dataset.field;
      const sortOrder = e.currentTarget.getAttribute('aria-sort') === SORT.DESCENDING
        ? SORT.ASCENDING : SORT.DESCENDING;

      sort.set(field, sortOrder);
    }
  },
  formatExtraData: {
    emptyValue: '-'
  },
  formatter: emptyCellFormatter,

});
