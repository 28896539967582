/*
 *
 * API creator v2 which handle cancel tokens
 * providing possibility to cancel not needed polling actions
 *
 */
import apisauce from 'apisauce';
import axios from 'axios';

export const create = baseURL => {
    const api = apisauce.create({
        baseURL,
        headers: {
            Accept: 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        },
        timeout: 600000, // 1000ms * 60sec * 10 => 10 minutes timeout
    });

    const createCancelTokenSource = () => axios.CancelToken.source();
    const getBearerToken = () => window.sessionStorage.getItem('_mydeal_');

    const getData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const getHtml = () => {
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                baseURL: '/',
                withCredentials: false,
                headers: {
                    Accept: 'text/html',
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            }),
        };  
    };
    const getDataDownload = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                responseType: 'blob',
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const getDataCreds = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                withCredentials: true,
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const postData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.post(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const patchData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.patch(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const putData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.put(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };
    const deleteData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.delete(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };

    const etagGetData = (endPoint, parameters, etag = null) => {
        const headers = {
            Authorization: `Bearer ${getBearerToken()}`,
        };
        if (etag) {
            headers.Etag = etag;
            headers["If-Modified-Since"] = etag.replace('W/"', '').replace('"','');
        }

        return api.get(endPoint, parameters, {
            withCredentials: false,
            headers,
        });
    };

    return {
        getData,
        getDataDownload,
        getHtml,
        getDataCreds,
        postData,
        patchData,
        putData,
        deleteData,
        baseURL,
        // etag
        etagGetData,
        // for testing
        api,
    };
};

export default {
    create,
};
