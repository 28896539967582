import React, { Component } from 'react';
import { compose } from 'recompose';

import { overlayIds } from 'constants/featurePages';

import { events } from 'constants/analytics';
import withAnalytics from 'shared/hoc/withAnalytics';

import TranslationText from 'translations/TranslationText';
import Popup from 'shared/components/Popup'

class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLeft: props.expire
        }

        this.countDown = this.countDown.bind(this)
        this.startTimer = this.startTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
        this.secondsToMinutes = this.secondsToMinutes.bind(this)
    }

    componentDidMount() {
        this.props.viewChange();
        this.startTimer();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.expire !== this.state.timeLeft) {
            this.setState({
                timeLeft: nextProps.expire
            })
        }
    }

    componentWillUnmount() {
      if(this.timer) {
        clearInterval(this.timer);
      }
    }

    secondsToMinutes(seconds) {
        const secondsDisplay = Math.floor(seconds % 60);
        return Math.floor(seconds / 60) + ':' + ((secondsDisplay < 10) ? `0${secondsDisplay}` : secondsDisplay)
    }

    countDown() {
        const newTimeLeft = this.state.timeLeft - 1;

        this.setState({
            timeLeft: newTimeLeft
        })
        if (newTimeLeft <= 0) {
            clearInterval(this.timer);
            this.props.toggleModalLogout();
        }
    }

    startTimer() {
        clearInterval(this.timer)
        this.timer = setInterval(this.countDown, 1000)
    }

    resetTimer() {
        clearInterval(this.timer)
        this.setState({ timeLeft: this.props.expire })
    }

    render() {

        return (
            <Popup
      				header="Continue Session?"
      				headerKey="logout.continue_session"
      				primaryButton={!this.props.pulseMessage}
      				primaryButtonContent="Continue"
      				primaryButtonKey="logout.continue"
      				primaryButtonAction={() => {
                    this.resetTimer();
                    this.props.toggleModalContinue();
                    this.props.continueEvent()
                }}
      				secondaryButton
      				secondaryButtonContent="Logout"
      				secondaryButtonKey="logout.logout"
      				secondaryButtonAction={() => {
                    this.resetTimer();
                    this.props.toggleModalLogout()
                    this.props.logoutEvent();
                }}
			>
                <div style={{ margin: '0 0 10px 0' }}>
                        <TranslationText
                            dictionaryKey={"logout.you_will_be_logged_out_in_<in_minutes_seconds>"}
                            replace={[
                                ["<in minutes/seconds>", this.secondsToMinutes(this.state.timeLeft)]
                            ]}
                            defaultText={`You will be logged out in ${this.secondsToMinutes(this.state.timeLeft)}`}
                        />
                        <br />
                        {
                            this.props.pulseMessage && (
                                <span className="text-investor">
                                    {
                                        this.props.pulseMessage === "Your session can not be extended further. Please logon again!"
                                            ? <TranslationText
                                                dictionaryKey={"logout.your_session_can_not_be_extended_further_please_l"}
                                                defaultText={this.props.pulseMessage}
                                            />
                                            : this.props.pulseMessage
                                    }
                                </span>
                            )
                        }
                    </div>
            </Popup>
        )
    }
}

export default compose(
    withAnalytics((ev, viewChange) => ({
        logoutEvent: () => ev(events.SESSION_EXPIRY_LOGOUT),
        continueEvent: () => ev(events.SESSION_EXPIRY_CONTINUE),
        viewChange: () => viewChange(overlayIds.SESSION_EXPIRY_OVERLAY),
    }))
)(AlertModal);
