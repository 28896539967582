import { createSelector } from "reselect";
import { isInternalUser, getUniqueName } from "selectors";

const selectUtilityReducer = (state) => state.isvUtility;

export const getIsvDeals = (state) => selectUtilityReducer(state).allDeals;
export const getIsvCurrentDeal = (state) =>
  selectUtilityReducer(state).dealsData;

export const getLatestIsvCurrentDeal = (state) => {
  return state.isvInformationBanner.latestIsvCurrentDealData;
}
export const getIsvUniqueProductType = createSelector(
  [getIsvDeals],
  (isvDeals) => {
    const uniqueProductType = [
      ...new Map(
        isvDeals.map((item) => [item["productType.productTypeName"], item])
      ).values(),
    ];
    return uniqueProductType;
  }
);

export const selectInformationBannerReducer = (state) => 
  state.isvInformationBanner.isDashboardBannerOpen;

export const dealInformationBannerReducer = (state) =>
  state.isvInformationBanner.isDealInformationBannerOpen;

export const isIsvExternalUser = createSelector(
  [
    getIsvCurrentDeal,
    (state) => isInternalUser(state),
    (state) => getUniqueName(state),
  ],
  (isvDeal, isInternalUser, userId) => {
    if (!isvDeal || !isvDeal.dealUserMapping || !isvDeal.dealUserMapping.length)
      return false;
    return (
      isvDeal.dealUserMapping.some((ele) => ele.userId === Number(userId)) &&
      (!isInternalUser || isInternalUser === 'false')
    );
  }
);

export const referenceInformationBannerReducer = (state) =>
  state.isvInformationBanner.isReferenceInformationBannerOpen;

export const selectConfirmationBannerStatus = (state) => state.isvInformationBanner.isConfirmationDialogueOpen;
export const getIsvCurrentDealId = state => selectUtilityReducer(state).dealsData.dealId;
export const selectUploadedFile = state => state.isvDocuments.file;
export const selectUploadedOverlayStatus = state => state.isvDocuments.isOverlayOpen;
export const selectUploadedTitle = state => state.isvDocuments.title;
export const selectIsEcbClientUploaded = state => state.isvDocuments.isEcbClientDocUploaded;
export const selectedDocument = state => state.isvDocuments.selectedDocument;
export const selectSnackBarStatus = state => state.isvSnackBar.showSnackbar;
export const selectSnackBarMessage = state => state.isvSnackBar.snackBarMessage;
export const isIsvBaseFetchCompleted = state => selectUtilityReducer(state).isvBaseFetchCompleted;
export const dataFetching = state => selectUtilityReducer(state).dataFetching;
export const selectDocumentDeleteId = (state) => state.isvInformationBanner.deleteDealDocumentId;
export const selectClientDocumentData = state => state.isvDocuments.clientDocumentData;
export const selectLatestCommentsDataForCurrentDeal = state => state.isvDocuments.latestCommentsDataForCurrentDeal;
export const selectLatestCommentsDataForCurrentComment = state => state.isvDocuments.latestCommentsDataForCurrentComment;
export const selectIsCommentPopUpOpen = state => state.isvDocuments.isCommentsPopupOpen;
export const selectIsvRejectButtonLabel = state => state.isvDocuments.rejectLabel;
export const selectIsvIsAdditionalComment = state => state.isvDocuments.isAdditionalComment;
export const selectIsvTableColumnWidth = state => state.isvDocuments.titleTableColumnWidth;
export const selectedEcb2ReturnDataForCurrentDeal = state => state.isvDocuments.ecb2ReturnDataForCurrentDeal;
export const selectedEcb2ReturnStatus = state => state.isvDocuments.isECBStatusEnabled;
export const selectIsRefDocumentUploaded = state => state.isvDocuments.isReferenceDocUploaded;
export const selectedReferenceInformationDataForCurrentDeal = state => state.isvDocuments.referenceInformationDataForCurrentDeal;
export const selectIsReferenceInformationEnabled = state => state.isvDocuments.isReferenceInformationEnabled;