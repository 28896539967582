import { createStore, applyMiddleware, compose } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas/index';

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);

let store;

if (process.env.NODE_ENV === 'development') {
  const DevTools = require('./DevTools').default; // eslint-disable-line global-require
  store = createStore(enableBatching(rootReducer), compose(middleware, DevTools.instrument()));
} else {
  store = createStore(enableBatching(rootReducer), compose(middleware));
}


const apiUrl = process.env.REACT_APP_DEFAULT_API || 'env-not-found';

sagaMiddleware.run(rootSaga(apiUrl, store.dispatch, store.getState));

export default store;
