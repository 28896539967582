import { put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { UtilityTypes } from 'actions/Types';
import {
    checkFeature,
    requestDealInfo,
} from 'actions/UtilityActions';
import { getCurrentDealId } from 'selectors';

export default (api, dispatch, getState) => {
    function* watcher() {
        /*
         * Possible scenarios:
         *  - when we open feature before EventNotification will receive info that feature
         *    page which we opened was disabled in that one minute interval
         *
         *  - only one?
         *
         */
        while (true) {
            // for that deal feature we received 401
            const { dealFeature } = yield take(UtilityTypes.UNAUTHORIZED_EFFECT_RESPONSE_FEATURE);

            // we're requesting for deal info once again (immediately to not wait for EventNotification saga)
            const dealId = yield select(getCurrentDealId);
            yield put(requestDealInfo(dealId, true));

            // we're waiting for it response and base on that we're checking that feature which failed with 401
            yield take(UtilityTypes.API_GET_DEAL_INFO_RESPONSE)

            // check feature once again
            yield put(checkFeature(dealFeature));

            // give a once second sleep skill other failed 401 responses
            yield delay(1000);
        }
    }

    return {
        watcher,
    };
};
