import { createReducer } from 'reduxsauce';

import Types, { UtilityTypes } from '../actions/Types';

export const INITIAL_STATE = {
  pricingDocumentId: null,
};

const receivePricingDocumentId = (state, action) => Object.assign({}, state, {
  pricingDocumentId: action.id,
});

const requestPricingDocumentId = state => Object.assign({}, state, {
  pricingDocumentId: null,
});

const ACTION_HANDLERS = {
  [Types.API_RECEIVE_PRICING_DOCUMENT_ID]: receivePricingDocumentId,
  [Types.API_REQUEST_PRICING_DOCUMENT_ID]: requestPricingDocumentId,
  // v1.7
  // Deal Cleanup
  [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE),
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
