import { delay } from 'redux-saga';
import { put, select, cancel, fork, call, takeEvery, takeLatest, take } from 'redux-saga/effects';
import { without, isEqual } from 'lodash';

import eventTypes from 'constants/eventTypes';
import dealFeatures from 'constants/dealFeatures';

import Types, { UtilityTypes } from 'actions/Types';
import {
    apiFailure,
    markFeature,
    checkFeature,
    requestPollingInterval,
    requestDeals,
    requestDealInfo,
    setHomeFeatureStatus,
} from 'actions/UtilityActions';
import { logout } from 'actions/LoginActions';
import {
    getBeatingStatus,
    getCurrentDealId,
    getPollingFrequency,
    getAllowedNavigationFeatures,
    getIsSetStagesModalOpen
} from 'selectors';

export default (api) => {
    function* workerEN() {
        let lastUpdated;

        while (true) {
            const pollingFrequency = yield select(getPollingFrequency);

            yield delay(pollingFrequency * 1000);

            const isBeating = yield select(getBeatingStatus);
            if (!isBeating) {
                // we're delaying request by one polling frequency for one polling interval cycle
                continue;
            }

            try {
                const dealId = yield select(getCurrentDealId);
                const params = {};

                if (!lastUpdated) {
                    lastUpdated = null;
                }

                params["query.onlyIfChangedSince"] = lastUpdated
                const isStagesPopupOpen = yield select(getIsSetStagesModalOpen)
                if(!isStagesPopupOpen){
                    const response = yield call(api.getData().req, `/v1/notifications/${dealId}`, params);
                    if (response.status === 204) {
                        // skip / continue loop
                    } else if (response.status === 200) {
                        const data = response.data.data;
                        lastUpdated = response.data.lastUpdated || lastUpdated;
    
                        const { deals, eventNotifications } = data[0];
    
                        //
                        // handle event notifications
                        //
                        if (eventNotifications && eventNotifications.length) {
                            if (eventNotifications.indexOf(eventTypes.CONFIGURATION) >= 0) {
                                yield put(requestPollingInterval(false));
                            }
    
                            if (eventNotifications.indexOf(eventTypes.DEAL_ACCESS) >= 0) {
                                yield put(requestDeals(false))
                            }
    
                            if (eventNotifications.indexOf(eventTypes.DEAL) >= 0) {
                                if (deals && deals.length) {
                                    const deal = deals[0];
                                    if (deal) {
                                        const { notifications } = deal;
    
                                        if (notifications.indexOf(dealFeatures.DEAL) >= 0) {
                                            let previousAvailableFeatures = yield select(getAllowedNavigationFeatures);
                                            previousAvailableFeatures = previousAvailableFeatures.filter(f => f.visibleInMainMenu)
                                                .map(f => f.url)
                                                .filter(u => u !== '/');
    
                                            // wait for all needed new info then set up rest
                                            yield put(requestDealInfo(dealId, false));
                                            yield take(UtilityTypes.API_GET_DEAL_INFO_RESPONSE);
    
                                            let nextAvailableFeatures = yield select(getAllowedNavigationFeatures);
                                            nextAvailableFeatures = nextAvailableFeatures.filter(f => f.visibleInMainMenu)
                                                .map(f => f.url)
                                                .filter(u => u !== '/');
    
                                            // check which feature was enabled and which disabled
                                            if (!isEqual(previousAvailableFeatures, nextAvailableFeatures)) {
                                                // new feature was enabled (added)
                                                const addedFeatures = without(nextAvailableFeatures, ...previousAvailableFeatures);
                                                addedFeatures.forEach(feature => {
                                                    // apply to deal notification if its not applied ( to perform needed request )
                                                    if (!notifications.some(f => f === feature)) {
                                                        notifications.push(feature);
                                                    }
                                                });
    
                                                // feature was disabled (removed)
                                                const removedFeatures = without(previousAvailableFeatures, ...nextAvailableFeatures);
                                                removedFeatures.forEach(feature => {
                                                    // apply to deal notification if its not applied ( to check feature and redirect if necessary )
                                                    if (!notifications.some(f => f === feature)) {
                                                        notifications.push(feature);
                                                    }
                                                })
                                            } else {
                                                // features didnt change
                                            }
                                        }
    
                                        const filtered = notifications.filter(dealFeature => dealFeature !== dealFeatures.DEAL);
    
                                        for (let i = 0; i < filtered.length; i++) {
                                            // mark all provided features as dirty -> needed for next requests (loop below)
                                            yield put(markFeature(filtered[i], true));
                                        }
    
                                        for (let i = 0; i < filtered.length; i++) {
                                            const dealFeature = filtered[i];
                                            // #1 home tile was not fetched since now
                                            yield put(setHomeFeatureStatus(dealFeature, false));
                                            // #2 check feature
                                            yield put(checkFeature(dealFeature));
                                        }
                                    }
                                }
                            }
                        }
                    } else if (response.status === 401) {
                        yield put(apiFailure('Unauthorized access'));
                        yield put(logout());
                    } else {
                        yield put(apiFailure('bad response'));
                    }
                }
            } catch (error) {
                yield put(apiFailure(`bad call ${error}`));
            }

        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.START_EVENT_NOTIFICATION_SAGA,
        ], workerEN);

        yield fork(takeLatest, [
            UtilityTypes.STOP_EVENT_NOTIFICATION_SAGA,  // deal switching
            Types.KILL_ACTIVE_SAGA_WORKERS, // logout
            /* eslint-disable-next-line */
        ], cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker: workerEN,
    };
};
