import React, { Component } from 'react';

import withLanguage from 'translations/withLanguage';
import { translationString } from 'translations/TranslationText';

import { buttonKeyHandler } from 'util/onKeyPress';

import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';

/**
 * A container to tab between different views
 */

class NewTabsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        }

        this.changeTab = this.changeTab.bind(this);
    }

    changeTab = (tab, event) => {
        this.setState({
            activeTab: tab
        }); 
    }

    render() {
        const { language } = this.props;
        let tabsCount = this.props.children.length,
            tabWidth = tabsCount > 1 ? 100 / tabsCount + '%' : '50%',
            tabs = [],
            tabContent = [],
            children = this.props.children;

        if (!children.length) {
            children = [children];
        }

        children.forEach((child, index) => {
            if (child && child.props) {
                const { tabActiveIndex, changeTabActiveIndex } = child.props;
                let activeTab = this.state.activeTab;

                if ((tabActiveIndex === 0 || tabActiveIndex) && changeTabActiveIndex) {
                    activeTab = tabActiveIndex;
                }

                tabs.push(
                    <div
                        role="tab"
                        aria-selected={activeTab === index}
                        key={child.props.title}
                        className={activeTab === index ? 'text-thirteen new-tabs-container-tab active' : 'text-thirteen new-tabs-container-tab'}
                        style={{ width: tabWidth }}
                        tabIndex={0}
                        onKeyDown={e => buttonKeyHandler(e, () => {
                            if (changeTabActiveIndex) {
                                changeTabActiveIndex(index);
                            } else {
                                this.changeTab(index);
                            }

                            if (child.props.onTabClicked) {
                                child.props.onTabClicked();
                            }
                        })}
                        onClick={() => {
                            if (changeTabActiveIndex) {
                                changeTabActiveIndex(index);
                            } else {
                                this.changeTab(index);
                            }

                            if (child.props.onTabClicked) {
                                child.props.onTabClicked();
                            }
                        }}
                        >
                        <div className={`tab-label-container ${index === children.length - 1 ? 'last-index' : ''} ${activeTab === index ? 'active' : ''}`}>
                            <span className="tab-label">
                                {
                                    child.props.titleKey && child.props.title ? (
                                        <ResponsiveEllipsis
                                            maxLine={1}
                                            text={
                                                translationString(child.props.titleKey, language, child.props.title)
                                            }
                                        />
                                    ) : (
                                        <ResponsiveEllipsis
                                            maxLine={1}
                                            text={child.props.title}
                                        />
                                    )
                                }
                            </span>
                        </div>
                    </div>
                )
                if (activeTab === index) {
                    tabContent.push(child);
                }
            }
        });


        return (
            <div
                style={this.props.style}
            >
                { /* TODO REFACTOR H4 -> div */ }
                <h4
                    role="tablist"
                    style={{ background: '#EDEDED', fontWeight: 'bold', marginTop: 0 }}
                    className="text-normal new-tabs-container-tab-container"
                >
                    {tabs}
                </h4>
                <div
                    className="new-tab-content"
                    role="tabpanel"
                >
                    {tabContent}
                </div>
            </div>
        )
    }

}

export default withLanguage(NewTabsContainer);
