// const goldenRatio = 1.618;
// const calcRatio = a => a * goldenRatio;
// const calcPixel = pt => pt * 1.33;

export const fontColors = {
    white: '#FFFFFF',
    navBlue: '#969696',
    black: '#000000',
    grey: '#6F6F6F',
    darkGrey: '#4d6474',
    lightGrey: '#d8d8d8',
    nightGrey: '#333333',
};


//
// defaults sizes
//
export const headings = {
    h1: '',
    h2: '',
    h3: '',
    h4: '',
    h5: '',
    h6: '',
}

//
//
//
export const palette = {
    primary: '',
    secondary: '',
    tertiary: '',
    //
    accent: '',
    //
    text: fontColors.black,
    alternateText: fontColors.grey,
    disabledText: fontColors.nightGrey,
    //
    success: '',
    error: '',
    checkedIcon: fontColors.darkGrey,
    uncheckedIcon: fontColors.lightGrey,
};

export const typography = {
    family: {
        light: 'Univers Next Lt',
        normal: 'Univers Next',
        bold: 'Univers Next Bd',
    },
    // should match WCAG (proposal) !
    sizes: {
        // '6px': '0.375rem',
        // '7px': '0.375rem',
        // '8px': '0.375rem',
        '9px': '0.563rem',
        // '10px': '0.375rem',
        '11px': '0.688rem',
        '12px': '0.750rem',
        '13px': '0.813rem',
        '14px': '0.875rem',
        '15px': '0.938rem',
        '16px': '1.000rem',
        '18px': '1.125rem',
        '20px': '1.250rem',
        '24px': '1.500rem',
        '37px': '2.313rem',
        '45px': '2.813rem',
    },
    lineHeights: {
        // calcRatio(this.sizes.large) (?)
    }
};

//
// if we will have bg with opacity -> white20 ( rgba(white, 20) )
//
export const backgrounds = {
    white: '#FFF',
    slateBlue: '#1D262C',
    gray: '#dddddd',
}

export const outlines = {
    light: '1px dotted white',
    dark: '1px dotted black',
}

//
// Custom BEM approach proposal
//
export const components = {
    button: {
        '--state': {
            '--focus': `
                outline: ${outlines.light};
            `,
            '--disabled': `
                background: transparent;
            `,
            '--hover': `
                background: white;
            `,
        },
        '--default': {

        }
    },
    link: {
        '--state': {
            '--focus': `
                outline: ${outlines.dark};
                color: ${palette.text};
            `,
            '--hover': `
                text-decoration: underline;
                color: ${palette.text};

                * {
                    text-decoration: underline; ${/* all nested texts inside link */''}
                }
            `,
            '--disabled': `
                cursor: not-allowed;
                color: ${palette.disabledText};
                opacity: .5;
            `,
        },
        '--default': `
            cursor: pointer;
            color: ${palette.text};
        `,
    },
}
