import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';

import * as zIndexes from 'shared/styles/variables/indexes';
import { ModalContainer } from 'shared/components/Popup';

const ContainerWrapper = styled.div`
    display: inline-block;
    height: 100%;
`;

const ContentWrapper = styled.div`
    ${ModalContainer} {
        width: ${props => props.width}px;
        min-width: ${props => props.width}px;
        padding: 0;
        border: 1px solid #333;
    }
`;

const dropdownInlineStyles = {
    boxShadow: '0 5px 10px rgba(0,0,0,.2)',
    position: 'absolute',
    top: -1,
    left: 0,
    zIndex: zIndexes.dropdown,
}

class PopupSystem extends PureComponent {
    fallbackFocus = () => {
        const id = this.props.modalID;

        setTimeout(() => {
            const clickableElement = document.getElementById(`${id}-btn`);

            if (this && this.props && this.props.closeModal) {
                this.props.closeModal();
            }

            if (clickableElement) {
                clickableElement.focus();
            }
        }, 100);
    }

    render() {
        return (
            <ContainerWrapper>
                {
                    this.props.clickableElement
                }
                {
                    this.props.isModalOpen && (
                        <div style={{ position: 'relative' }} >
                            <FocusTrap
                                focusTrapOptions={{
                                    clickOutsideDeactivates: true,
                                    escapeDeactivates: false,
                                    onDeactivate: this.fallbackFocus,
                                }}
                                active={this.props.isModalOpen}
                            >
                                <ContentWrapper
                                    id={this.props.modalID}
                                    style={dropdownInlineStyles}
                                    aria-modal
                                    width={this.props.width}
                                >
                                    {
                                        this.props.content
                                    }
                                </ContentWrapper>
                            </FocusTrap>
                        </div>
                    )
                }
            </ContainerWrapper>
        );
    }
}

PopupSystem.propTypes = {
    // required
    modalID: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    content: PropTypes.element.isRequired,
};

PopupSystem.defaultProps = {
    // needed ?
};

export default PopupSystem;
