import React, { PureComponent } from "react";
import styled from "styled-components";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fontColors } from "shared/styles/variables/theme";
import { primaryBtnStyle } from 'Isv/styles/buttonStyle';
import FileUploadOverlay from 'Isv/components/FileUpload/FileUploadOverlays/FileUploadOverlay';
import EcbToReturnTable from "./EcbToReturnTable";
import { isIsvRRTeamUser } from "selectors";
import { PrimaryButton, SecondarySlateButton } from 'mk-pattern-library-react';
import {
  getIsvCurrentDeal,
  selectIsvTableColumnWidth,
  selectLatestCommentsDataForCurrentDeal,
  isIsvExternalUser,
  getIsvCurrentDealId,
  selectedEcb2ReturnDataForCurrentDeal,
  dataFetching,
  dealInformationBannerReducer
} from "Isv/selectors";
import { applyIsvCloseBanner } from "Isv/actions/IsvInformationBannerAction";
import IsvInformationBanner from "Isv/components/InformationBanner/InformationBanner";
import DealInformationCommentBoxContainer from '../DealInformation/DealInformationCommentBoxContainer'
import { viewDocument, handleOpenUploadOverlay, handleECBSubmit } from 'Isv/actions/IsvDocumentsAction';
import Loading from 'shared/components/Loading';
import {
  NEXT_ACTION_OWNER,
  REJECTED_STATUS,
  SUBMIT_TO_RBI
} from 'Isv/constants/isvDocumentReviewStatus'

const Header = styled.h2`
  font-size: 26px;
  color: ${fontColors.black};
  font-weight: 600;
`;

const InformationButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content : flex-end;
  width: 100%
  button {
    &:not(:first-child) {
      margin-left: 20px;
    }   
  }
`

const DocumentActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
`;

const AdditionalCommentContainer = styled.div`
  max-width: ${(props) => (props.isClientData ? props.width - 14 : props.width) + 'px'};
  width: 100%;
`;

const AdditonalCommentHeader = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

const CommentStyle = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 5px;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

class EcbToReturn extends PureComponent {

  state = {
    isReviewSubmitted: false
  }
 
  enableSubmitForReviewButton = (selectedEcb2ReturnDataForCurrentDeal) => {
    const { selectedDealData } = this.props;
    let count = 0;
    const documentCount = selectedEcb2ReturnDataForCurrentDeal && selectedEcb2ReturnDataForCurrentDeal.clientDocumentFile;
    documentCount && documentCount.length && 
    documentCount.forEach(doc => {
      doc.ecbReturnStatus && doc.isClientDocReviewed === null &&  count++;
    });

    return (
      Boolean(count) &&
      selectedDealData &&
      selectedDealData.status &&
      selectedDealData.status.ecB2ReturnStatus
    );
  };

  checkSubmitDisabled = (dealDocuments) => {
    let count = 0, isSubmitButtonEnabled;
    if (dealDocuments && dealDocuments.clientDocumentFile) {
      dealDocuments.clientDocumentFile.forEach(doc => {
        doc.isRrApproved === true && doc.ecbReturnStatus && doc.isRrDocReviewed === null && count++;
      });
      isSubmitButtonEnabled = count >= 1;
    }
    this.setState({ isReviewSubmitted: false });
    return isSubmitButtonEnabled;
  }

  checkRejectToClientDisabled = (dealDocuments) => {
    let count = 0;
    if (dealDocuments && dealDocuments.clientDocumentFile) {
      dealDocuments.clientDocumentFile.forEach(doc => {
        doc.isRrApproved === false && doc.ecbReturnStatus === false && doc.isRrDocReviewed === null && count++;
      })
    }
    this.setState({ isReviewSubmitted: false });
    return !(Boolean(count));
  }

  handleECBSubmit = (dealId, userAction) => {
    this.setState({ isReviewSubmitted : true })
    this.props.handleECBSubmit(dealId, userAction);
  }

  handleECbDocUpload = (isOpen, document, title) => {
    this.props.handleOpenUploadOverlay(isOpen, document, title, true)
    this.setState({ isReviewSubmitted: false })
  }

  render() {
    const {
      dealId,
      selectedDealData,
      titleTableColumnWidth,
      latestCommentsDataForCurrentDeal,
      viewDocument,
      isIsvExternalUser,
      isIsvRRTeamUser, 
      selectedEcb2ReturnDataForCurrentDeal,
      isDealInformationBannerOpen,
      handleCloseBanner,
    } = this.props;
    const lastComment =
      latestCommentsDataForCurrentDeal &&
      latestCommentsDataForCurrentDeal.length > 0 &&
      latestCommentsDataForCurrentDeal.find(
        (item) =>
          item.title === "Additional Comment" &&
          item.dealId === selectedDealData.dealId
      );

    return (
      <>
      {this.props.loading ? (
          <LoadingContainer>
            <LoadingWrapper>
              <Loading hasBackground={false} />
            </LoadingWrapper>
          </LoadingContainer>
        ) : null}
        <Header>ECB 2 Return</Header>
        {isDealInformationBannerOpen && isIsvExternalUser &&
          <IsvInformationBanner
            bannerHeading={'ECB 2 Return.'}
            bannerText={'ECB 2 Return is available for completion, please complete and submit for review to HSBC by the deadline specified below'}
            handleCloseBanner={handleCloseBanner}
            onCloseOverlay="isDealInformationBannerOpen"
          />
        }
        <FileUploadOverlay />
        <EcbToReturnTable
          viewDocument={viewDocument}
          handleOpenUploadOverlay={this.handleECbDocUpload}
        />
        <DocumentActionContainer>
          <AdditionalCommentContainer
              width={titleTableColumnWidth}
              isClientData={
                selectedDealData &&
                selectedDealData.clientDocumentFile &&
                selectedDealData.clientDocumentFile.length
              }
            >
              <AdditonalCommentHeader>Additional Comment</AdditonalCommentHeader>
              <CommentStyle>
                <DealInformationCommentBoxContainer
                  selectedDealData={selectedDealData}
                  title={"Additional Comment"}
                  lastComment={lastComment}
                  isAdditionalComment={true}
                  fromEcb2Return={true}
                />
              </CommentStyle>
            </AdditionalCommentContainer>
            <InformationButtonContainer>
              {isIsvExternalUser && (
                <>
                  <PrimaryButton
                    size="normal"
                    id="submit-ecb-button"
                    disabled={!this.enableSubmitForReviewButton(selectedEcb2ReturnDataForCurrentDeal) || this.state.isReviewSubmitted}
                    style={primaryBtnStyle}
                    onClick={() => this.handleECBSubmit(dealId, NEXT_ACTION_OWNER.CLIENT)}
                  >
                    Submit
                  </PrimaryButton>
                </>
              )}
              {isIsvRRTeamUser &&
                <>
                  <SecondarySlateButton
                    size="normal"
                    id="ecb-reject-to-client-button"
                    style={primaryBtnStyle}
                    disabled={this.checkRejectToClientDisabled(selectedEcb2ReturnDataForCurrentDeal) || this.state.isReviewSubmitted}
                    onClick={() => this.handleECBSubmit(dealId, REJECTED_STATUS.REJECTED_BY_RR)}
                  >
                    Reject To Client
                  </SecondarySlateButton>

                  <PrimaryButton
                    size="normal"
                    id="ecb-final-submit-button"
                    disabled={!this.checkSubmitDisabled(selectedEcb2ReturnDataForCurrentDeal) || this.state.isReviewSubmitted}
                    style={primaryBtnStyle}
                    onClick={() => this.handleECBSubmit(dealId, SUBMIT_TO_RBI)}
                  >
                    Submit to RBI
                  </PrimaryButton>
                </>
              }
            </InformationButtonContainer>
        </DocumentActionContainer>
      </>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      dealId: getIsvCurrentDealId(state),
      selectedDealData: getIsvCurrentDeal(state),
      titleTableColumnWidth: selectIsvTableColumnWidth(state),
      latestCommentsDataForCurrentDeal: selectLatestCommentsDataForCurrentDeal(state),
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      selectedEcb2ReturnDataForCurrentDeal: selectedEcb2ReturnDataForCurrentDeal(state),
      loading: dataFetching(state),
      isDealInformationBannerOpen: dealInformationBannerReducer(state),
    }),
    (dispatch) => ({
      viewDocument: (documentId, title, fileName, dealDocumentId) => {
        dispatch(viewDocument(documentId, title, fileName, dealDocumentId))
      },
      handleOpenUploadOverlay: (isOpen, document, title, isEcbClientDocUploaded) => {
        dispatch(handleOpenUploadOverlay(isOpen, document, title, isEcbClientDocUploaded))
      },
      handleECBSubmit: (dealId, data) => {
        dispatch(handleECBSubmit(dealId, data))
      },
      handleCloseBanner: (label) => {
        dispatch(applyIsvCloseBanner(label))
      },
    })
  )
)(EcbToReturn);
