import { put, fork, takeEvery } from 'redux-saga/effects';

import Types from 'actions/Types';
import { stopEventNotificationSaga } from 'actions/UtilityActions';
import { killActiveSagaWorkers } from 'actions/UtilityActions';
import { logout } from 'actions/LoginActions';
import { killHeartBeat } from 'actions/HeartBeatActions';

import * as storageKeys from 'constants/storageKeys';
import { featurePageIds } from 'constants/featurePages';
import { browserHistory } from 'react-router';

export default (api) => {
    function* worker(action) {
        switch (action.type) {
            case Types.LOGOFF: {
                // stop token refresh
                yield put(killHeartBeat());
                // stop polling
                yield put(stopEventNotificationSaga());

                break;
            }
            case Types.LOGOFF_SUCCESS: {
                // stop necessary sagas
                yield put(killActiveSagaWorkers());
                // clear login data
                yield put(logout(false));

                if (window.__MyDealEnv === 'INTERNAL') {
                    browserHistory.push(featurePageIds.INTERNAL_LOGIN_PAGE);
                } else {
                    browserHistory.push(featurePageIds.EXTERNAL_LOGIN_PAGE);
                }

                // #2 Clear everything - handled by logoff/logout action (root reducer?)
                // yield put(purge());

                // #3.a clear LocalStorage data - Roadshow Team will be lost
                // window.localStorage.clear();

                // #3.b clear all SessionStorage data - token will be lost
                // window.sessionStorage.clear();

                window.sessionStorage.removeItem(storageKeys.TOKEN);
                window.sessionStorage.removeItem(storageKeys.EXPIRATION);

                break;
            }
            default :
              // Do nothing
        }
    }

    function* watcher() {
        yield fork(takeEvery, [
            Types.LOGOFF,
            Types.LOGOFF_SUCCESS,
        ], worker);
    }

    return {
        watcher,
        worker,
    };
};
