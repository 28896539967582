import React, { useRef, useEffect } from 'react'

const TableHead = ({ setTheadRef, list, children, style }) => {
    const tableHeadRef = useRef(null);

    const resizeTheadRef = () => {
        setTheadRef && setTheadRef(tableHeadRef)
    };

    useEffect(() => {
        resizeTheadRef();
        window.addEventListener('resize', resizeTheadRef);
        return () => window.removeEventListener('resize', resizeTheadRef);
    }, [list]);

    return (
        <thead ref={tableHeadRef} style={style}>
            {children}
        </thead>
    )
}

export default TableHead;
