import { put, select, cancel, fork, takeEvery, takeLatest, take } from 'redux-saga/effects';

import dealFeatures from 'constants/dealFeatures';

import Types, { UtilityTypes } from 'actions/Types';
import { setHomeFeatureStatus, markFeature, requestDeal } from 'actions/UtilityActions';
import { requestMyDocs } from 'actions/MyDocsActions';
import { requestFeedbackForDeal } from 'actions/InvestorFeedbackActions';
import { requestEcmOrders, requestTranches } from 'actions/OrderBookActions';
import { getDealTypeFromDeals } from 'selectors';

export default (api, dispatch, getState) => {
    function* worker({ dealFeature, dealId }) {
        const state = yield select();

        // let feture know that we fetched data from one of the endpoints
        yield put(setHomeFeatureStatus(dealFeature, false));

        switch (dealFeature) {
            case dealFeatures.ROADSHOW: {
                // nothing todo now
                break;
            }
            case dealFeatures.INVESTOR_FEEDBACK: {
                yield put(requestFeedbackForDeal(dealId, false));
                break;
            }
            case dealFeatures.MY_DOCS: {
                yield put(requestMyDocs(dealId, false));
                break;
            }
            case dealFeatures.ORDERBOOK: {
                const dealType = getDealTypeFromDeals(state, dealId);
                const isDCM = dealType === 'DCM';

                // special case -> we're requesting all data for orderbook
                yield put(markFeature(dealFeature, false));

                yield put(requestDeal(dealId, true));

                if (isDCM) {
                    yield put(requestTranches(dealId, false));
                } else {
                    // wait for data (otherwise lowest share price will be not up to date)
                    yield take(UtilityTypes.API_GET_DEAL_RESPONSE);
                    yield put(requestEcmOrders(dealId, false));
                }
                break;
            }
            case dealFeatures.PRICING: {
                // nothing todo now
                break;
            }
            case dealFeatures.INVESTORS: {
                // nothing todo now
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.EFFECT_HANDLE_HOME_FEATURE_SETUP
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
