import { cancel, fork, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import IsvTypes from 'Isv/actions/IsvTypes';
import { create } from 'services/api';
import { handleECB2ReturnDataForCurrentDeal } from 'Isv/actions/IsvDocumentsAction';

export default (api, dispatch, getState) => {
    function* worker(action) {
        switch (action.type) {
          case IsvTypes.API_REQUEST_ECB2_RETURN_DATA_FOR_CURRENT_DEAL: {
            const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.getData();
                const response = yield call(apiType.req, action.endPoint, action.params);
                if (response.ok) {
                  yield put(handleECB2ReturnDataForCurrentDeal(response.data));
                }
                break;
            }

            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            IsvTypes.API_REQUEST_ECB2_RETURN_DATA_FOR_CURRENT_DEAL,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, IsvTypes.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};