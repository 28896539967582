import { createTypes } from 'reduxsauce';

//
// Unique prefix for feature actions
//
const createPrefix = (obj, prefix) => Object.keys(obj)
  .reduce((acc, key) => {
    acc[key] = `${prefix}${obj[key]}`;
    return acc;
  }, {});

const All = createTypes(`
  ATTEMPT_AUTHENTICATION
  SET_AUTH_TYPE
  SET_LOGIN_ERROR
  SET_IS_ISV_USER
  SET_USERNAME
  SET_LOGIN_MODAL
  SET_INVALID_FIELDS
  SET_AUTHENTICATION
  HSBCNET_VERIFY_USERNAME
  LOGIN_FAILURE
  LOGOUT
  LOGOFF
  LOGOFF_SUCCESS
  RENEW_TOKEN
  RENEW_TOKEN_RECEIVE
  HEARTBEAT_PULSE
  KILL_HEARTBEAT
  CHECK_PULSE
  KILL_ACTIVE_SAGA_WORKERS

  USER_INTERACTION

  API_ATTEMPT_SSO
  API_ATTEMPT_LOGIN
  API_PRE_AUTH_ERR_LOG

  UPDATE_CURRENT_TRANCHE
  CHANGE_FULL_ORDERBOOK_SORT
  CHANGE_ALL_TRANCHES_FULL_ORDERBOOK_SORT

  UPDATE_ORDER_SUMMARY
  UPDATE_FULL_ORDERBOOK
  UPDATE_BOOK_EVOLUTION_SUMMARY
  UPDATE_BOOK_EVOLUTION_SUMMARY_FOR_AMENDED_ORDER_BY_INVESTOR
  API_RECEIVE_STAGES
  API_UPDATE_STAGES
  UPDATE_AMENDED_ORDER_SUMMARY

  API_FAILURE
  API_REQUEST

  API_REQUEST_ECM_ORDERS
  API_REQUEST_ORDERS
  API_RECEIVE_ORDERS

  API_REQUEST_ORDERS_FOR_ALL_TRANCHES
  INCLUDE_AMENDED_ORDERS
  API_REQUEST_SHARE_DEMAND
  API_RECEIVE_SHARE_DEMAND

  API_REQUEST_DEMAND_SUMMARY_BY_TYPE
  API_RECEIVE_DEMAND_SUMMARY_BY_TYPE

  API_REQUEST_DEMAND_SUMMARY_BY_SHARE_AND_TYPE
  API_RECEIVE_DEMAND_SUMMARY_BY_SHARE_AND_TYPE

  API_REQUEST_TRANCHES
  API_RECEIVE_TRANCHES

  CHANGE_TRANCHE_LOADER_STATUS

  API_REQUEST_INVESTOR_NAMES
  API_RECEIVE_INVESTOR_NAMES

  API_REQUEST_TRANCHE_LISTS
  API_RECEIVE_TRANCHE_LISTS

  API_RECEIVE_ORDER_SUMMARY
  API_REQUEST_ORDER_SUMMARY

  UPDATE_SELECTION_ORDER_SUMMARY
  REFRESH_FULLORDERBOOK_DATA

  UPDATE_SELECTION_AMENDED_ORDER_SUMMARY
  UPDATE_SET_STAGES_MODAL_OPEN
  UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY
  UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY_AMENDED_ORDER_BY_INVESTOR
  UPDATE_STAGES
  ROADSHOW_UPDATE_SELECTED_DAY
  SET_ROADSHOW_DAILY_EVENT

  API_RECEIVE_ROADSHOW_SUMMARY
  API_REQUEST_ROADSHOW_SUMMARY

  API_RECEIVE_ROADSHOW_EVENTS
  API_REQUEST_ROADSHOW_EVENTS

  API_RECEIVE_ROADSHOW_CONTACTS
  API_REQUEST_ROADSHOW_CONTACTS

  API_RECEIVE_DOCUMENTS_SUMMARY
  API_REQUEST_DOCUMENTS_SUMMARY

  API_RECEIVE_DOCUMENTS
  API_REQUEST_DOCUMENTS

  API_REQUEST_DOC

  API_RECEIVE_FEEDBACK_THEME_TYPES
  API_REQUEST_FEEDBACK_THEME_TYPES

  API_RECEIVE_FEEDBACK_SENTIMENTS
  API_REQUEST_FEEDBACK_SENTIMENTS

  API_RECEIVE_DEAL_THEMES
  API_REQUEST_DEAL_THEMES

  API_RECEIVE_FEEDBACK_FOR_DEAL
  API_REQUEST_FEEDBACK_FOR_DEAL

  API_RECEIVE_FEEDBACK_FOR_DEAL_AND_THEME
  API_REQUEST_FEEDBACK_FOR_DEAL_AND_THEME

  API_RECEIVE_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE
  API_REQUEST_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE

  API_RECEIVE_FEEDBACK_CHART_TYPES
  API_REQUEST_FEEDBACK_CHART_TYPES

  API_RECEIVE_FEEDBACK_CHART_DATA
  API_REQUEST_FEEDBACK_CHART_DATA

  SELECT_CHART_ID
  SELECT_CHART_TAB
  SELECT_CHART_SUB_TAB

  API_RECEIVE_INVESTOR_ENGAGEMENT
  API_REQUEST_INVESTOR_ENGAGEMENT

  API_RECEIVE_PRICING_DOCUMENT_ID
  API_REQUEST_PRICING_DOCUMENT_ID

  ROADSHOW_CHANGE_TEAM
  ROADSHOW_EFFECT_SET_TEAM

  LOGIN_FORM_CHANGE
  LOGIN_FORM_SUBMIT
  LOGIN_FORM_EXT_SUBMIT
  LOGIN_FORM_PN_SUBMIT
  CANCEL_LOGIN_FORM_PN_SUBMIT
  LOGIN_FORM_TOTP_SUBMIT
  LOGIN_FORM_INVALID
  LOGIN_FORM_VALID

  INITIALIZE
  INITIALIZING
  DATAFETCHING

  SET_PENDING_AUTHENTICATION
  SET_AUTHENTICATION_RESPONSE

  SHOW_TERMS_AND_CONDITIONS
  TERMS_AND_CONDITIONS_RESPONSE
  ACCEPT_TERMS_CONDITIONS
  CANCEL_TERMS_CONDITIONS
  ON_TERMS_CHECKED_CHANGE
  TERMS_AND_CONDITIONS_ACCEPTED

  API_GET_ACCEPT_TERMS_RESPONSE
  API_GET_ACCEPT_TERMS_REQUEST

  API_GET_TERMS_AND_CONDITIONS
  RECEIVE_TERMS_AND_CONDITIONS

  OPEN_SITE_TEXT
  SET_SITE_TEXT


  API_DEACTIVATE_DEVICE

  SET_DEVICE_DEACTIVATE_MESSAGES
  CLEAR_DEVICE_DEACTIVATE_MESSAGES

  VIEW_DEVICE_BIOMETRICS
  DEACTIVE_BIOMETRICS_DEVICE
  SET_MANAGE_DEVICES

  ORDER_SUMMARY_MODAL
  CLOSE_ORDER_SUMMARY_MODAL

  SELECT_ORDER_SUMMARY_DROPDOWN
  SELECT_AMENDED_ORDER_BY_INVESTOR_DROPDOWN
  SELECT_INVESTOR_TYPE
  RESET_ORDER_SUMMARY_DROPDOWN
  CURRENT_ACTIVE_ORDER_SUMMARY_TAB

`);

export const UtilityTypes = createPrefix(
  createTypes(`
    APP_SETUP

    PURGE

    DEAL_SETUP
    DEAL_PURGE

    MARK_FEATURE
    CHECK_FEATURE

    API_GET_POLLING_FREQUENCY_REQUEST
    API_GET_POLLING_FREQUENCY_RESPONSE

    API_GET_DEAL_REQUEST
    API_GET_DEAL_RESPONSE
    TILE_MODULE_FETCHED

    API_REQUEST_MANAGE_DEVICE
    API_RECEIVE_MANAGE_DEVICE

    API_GET_DEAL_INFO_RESPONSE
    API_GET_DEAL_INFO_REQUEST

    API_GET_DEALS_REQUEST
    API_GET_DEALS_RESPONSE

    API_GET_NAV_FEATURES_REQUEST
    API_GET_NAV_FEATURES_RESPONSE

    API_GET_SITE_TEXT_REQUEST
    API_GET_SITE_TEXT_RESPONSE

    START_EVENT_NOTIFICATION_SAGA
    STOP_EVENT_NOTIFICATION_SAGA

    EFFECT_HANDLE_HOME_FEATURE_SETUP
    SET_HOME_FEATURE_FETCH_STATUS

    ADD_CALLED_ENDPOINT_FROM_NOTIFICATION_CYCLE

    UNAUTHORIZED_EFFECT_RESPONSE_FEATURE

    EFFECT_REQUEST_FEATURE_DATA

    BASE_FETCH_COMPLETED

    OPEN_MODAL
    CLOSE_MODAL

    CHANGE_TAB
  `),
  'Utility_Types_',
);

export const LanguageTypes = createPrefix(
  createTypes(`
    SELECT_LANGUAGE
  `),
  'Language_Types_',
);

export const MyDocsTypes = createPrefix(
  createTypes(`
    CHANGE_ACTIVE_CATEGORY
  `),
  'MyDocs_Types_',
);

export const AnalyticsTypes = createPrefix(
  createTypes(`
    VIEW_CHANGE
    EVENT_TRIGGERED
  `),
  'Analytics_Types_',
);

export default {
  ...All,
};
