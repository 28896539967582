export const ISV_DOCUMENT_REVIEW_STATUS = {
    PENDING_ONE_WAY_LETTER: 'Pending 1 way letter acknowledgement',
    PENDING_FIRST_REVIEW: 'Pending 1st Review',
    PENDING_FINAL_CLIENT_SUBMISSION: 'Pending Final Client Submission of information',
    SUBMITTED_TO_RBI: 'Submitted to RBI',
    COMPLETED: 'Completed',
    MATURED: 'Matured'
};

export const WORKFLOW_STATUS = {
    DRAFT_ECB_SUBMISSION: 'Draft ECB Submission',
    FINAL_ECB_SUBMISSION: 'Final ECB Submission'
}

export const REJECTED_STATUS = {
    REJECTED_BY_CSM: 'RejectedByCSM',
    REJECTED_BY_RR: 'RejectedByRR'
}

export const SUBMIT_TO_RBI = 'SubmitToRBI';

export const NEXT_ACTION_OWNER = {
    CLIENT: 'Client',
    CSM: 'CSM',
    RR_TEAM: 'RR Team',
    HSBC: 'HSBC'
}

export const ISV_DOCUMENT_TYPE = {
    SUPPORTING_DOCUMENT:  'Supporting Document',
    Mandatory_Document: 'Mandatory Document',
    ECB_RETURN_DOCUMENT: 'ECB 2 Return Document',
    REFERENCE_DOCUMENT: 'Reference Document'
}

export const ISV_DOCUMENT_FIELDS= {
    ECB_RETURN_STATUS: 'ecbReturnStatus',
    IS_RR_FINAL_STATUS: 'isRrFinalStatus',
    IS_RR_APPROVED: 'isRrApproved',
    IS_RR_DOC_REVIEWED: 'isRrDocReviewed',
}