import { createReducer } from 'reduxsauce';

import Types, { UtilityTypes } from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';
import * as homeTiles from 'constants/homeTiles';
import * as animationChartIds from 'constants/animations';
import {
  tabIds,
  ecmSummaryDemandTabs,
  dcmSummaryDemandTabs,
  topTenOrdersTabs,
  roadshowContactTabs,
  allTranchesOrderSummarySubTabs,
  allTranchesBookEvolutionSubTabs,
  allTranchesAmendedSummarySubTabs,
} from 'constants/tabIds';

export const INITIAL_STATE = {
  fetching: false,
  baseFetchCompleted: false,
  allDeals: [],
  // current deal info
  dealsData: {
    dealId: null,
    dealType: '',
    //
    // ECM Orderbook Data ( move to Orderbook reducer + update selectors )
    //
    orderbookStatusDetail: {
      currentStatus: '',
    },
    dealTotalDemandSummary: {
      demandAtBottomSharePrice: {},
      demandAtMidSharePrice: {},
      demandAtTopSharePrice: {},
    },
    dealTotalAllocationSummary: {
      sharePrice: '',
    },
  },
  lastUserInteraction: null,
  navigationFeatures: [], // all nav features
  allowedNavigationFeatures: [], // features available for current deal
  //
  // home tiles fetching statuses
  [homeTiles.HOME_ORDERS_TILE]: false,
  [homeTiles.HOME_FEEDBACK_TILE]: false,
  [homeTiles.HOME_DOCUMENTS_TILE]: false,
  //
  // polling
  polling: {
    [dealFeatures.ORDERBOOK]: {
      homeFetched: false,
      dirty: true,
      calledUrls: [],
    },
    [dealFeatures.ROADSHOW]: {
      homeFetched: false, // we dont have tile (yet)
      dirty: true,
      calledUrls: [],
    },
    [dealFeatures.INVESTOR_FEEDBACK]: {
      homeFetched: false,
      dirty: true,
      calledUrls: [],
    },
    [dealFeatures.INVESTORS]: {
      homeFetched: false, // we dont have tile (yet)
      dirty: true,
      calledUrls: [],
    },
    [dealFeatures.MY_DOCS]: {
      homeFetched: false,
      dirty: true,
      calledUrls: [],
    },
    [dealFeatures.PRICING]: {
      homeFetched: false, // we dont have tile (yet)
      dirty: true,
      calledUrls: [],
    },
  },
  // Event Notification Interval ( in seconds )
  pollingFrequency: 60,
  //
  // Modals handling
  //
  modals: {
    // [modalID]: true -> we have nested modals thats why obj with ids
  },
  //
  // Tabs handling
  //
  tabs: {
    [tabIds.ECM_SUMMARY_DEMAND_TABS]: ecmSummaryDemandTabs.BY_INVESTOR_TYPE,
    [tabIds.TOP_TEN_ORDERS_TABS]: topTenOrdersTabs.TOP_TEN_ORDERS,
    [tabIds.DCM_SUMMARY_DEMAND_TABS]: dcmSummaryDemandTabs.BY_REGION,
    [tabIds.ALL_TRANCHES_SUB_TABS]: allTranchesOrderSummarySubTabs.VIEW_CHART,
    [tabIds.ALL_TRANCHES_AMENDED_SUMMARY_SUB_TABS]: allTranchesAmendedSummarySubTabs.VIEW_TABLE,
    // depending on arrived data
    [tabIds.INVESTOR_FEEDBACK_CHART_TABS]: null,
    // roadshow
    [tabIds.ROADSHOW_CONTACT_TABS]: roadshowContactTabs.ALL_CONTACTS,
    [tabIds.ALL_TRANCHES_BOOK_EVOLUTION_SUB_TABS]:allTranchesBookEvolutionSubTabs.VIEW_CHART
  },
  //
  // animations state
  //
  animations: {
    [animationChartIds.ORDERS_PIE_CHART]: true,
  },
};

const apiRequest = (state, action) => Object.assign({}, state, {
  fetching: action.isFetching,
  name: action.name,
});

const apiFailure = (state, action) => Object.assign({}, state, {
  error: true,
  message: action,
});

const detectUserInteraction = (state) => Object.assign({}, state, {
  lastUserInteraction: Date.now(),
});

const baseFetchCompleted = (state, action) => {
  if (!action.dealId) {
    return Object.assign({}, state, { baseFetchCompleted: true });
  }

  return Object.assign({}, state, {
    baseFetchCompleted: true,
    dealsData: {
      ...state.dealsData,
      dealId: action.dealId,
    },
  });
}

const killActiveSagaWorkers = state => Object.assign({}, state, {
  polling: Object.assign({}, INITIAL_STATE.polling),
});

const receiveNavigationFeatures = (state, { navigationFeatures }) => Object.assign({}, state, {
  navigationFeatures,
});

const tileModuleFetched = (state, { tile }) => Object.assign({}, state, {
  [tile]: true,
});

const receiveDeals = (state, action) => {
  const nullData = (action.allDeals.data === null) || (action.allDeals.data.length === 0);
  return Object.assign({}, state, {
    error: null,
    allDeals: nullData ? [] : action.allDeals.data,
    dateReceived: Date.now(),
  });
};

const receiveDeal = (state, action) => {
  const nullData = (action.dealsData.data === null) || (action.dealsData.data.length === 0);
  return Object.assign({}, state, {
    fetching: false,
    error: null,
    dealsData: nullData ? {} : action.dealsData.data[0],
    dateReceived: Date.now(),
  });
};

const receiveDealInfo = (state, action) => Object.assign({}, state, {
  allowedNavigationFeatures: action.dealInfo.allowedNavigationFeatures,
});

const markFeature = (state, action) => Object.assign({}, state, {
  polling: {
    ...state.polling,
    [action.dealFeature]: {
      ...state.polling[action.dealFeature],
      dirty: action.dirty,
      ...(action.dirty ? { calledUrls: [] } : {}),
    },
  },
});

const setHomeFeatureStatus = (state, { dealFeature, status }) => Object.assign({}, state, {
  polling: {
    ...state.polling,
    [dealFeature]: {
      ...state.polling[dealFeature],
      homeFetched: status,
    }
  }
})

const setPollingFrequency = (state, { pollingFrequency }) => Object.assign({}, state, {
  pollingFrequency,
});

const addCalledEndpoint = (state, { dealFeature, endPoint }) => Object.assign({}, state, {
  polling: {
    ...state.polling,
    [dealFeature]: {
      ...state.polling[dealFeature],
      calledUrls: [
        ...state.polling[dealFeature].calledUrls,
        endPoint,
      ],
    },
  },
});

const openModal = (state, { modalId }) => Object.assign({}, state, {
  modals: {
    ...state.modals,
    [modalId]: true,
  }
});

const closeModal = (state, { modalId }) => {
  const { [modalId]: modalToRemove, ...otherModals } = state.modals;
  return Object.assign({}, state, {
    modals: otherModals,
  });
};

const changeTab = (state, { tabsId, tabId }) => Object.assign({}, state, {
  tabs: {
    ...state.tabs,
    [tabsId]: tabId,
  },
});

const changeChartAnimateState = (state, { chartId, animate }) => Object.assign({}, state, {
  animations: {
    ...state.animations,
    [chartId]: animate,
  },
});

const ACTION_HANDLERS = {
  [Types.API_REQUEST]: apiRequest,
  [Types.API_FAILURE]: apiFailure,
  [Types.USER_INTERACTION]: detectUserInteraction,
  // saga cleanup + reset
  [Types.KILL_ACTIVE_SAGA_WORKERS]: killActiveSagaWorkers,
  // default data needed for start
  [UtilityTypes.API_GET_NAV_FEATURES_RESPONSE]: receiveNavigationFeatures,
  [UtilityTypes.API_GET_DEALS_RESPONSE]: receiveDeals,
  [UtilityTypes.API_GET_DEAL_RESPONSE]: receiveDeal,
  [UtilityTypes.API_GET_DEAL_INFO_RESPONSE]: receiveDealInfo,
  // polling
  [UtilityTypes.MARK_FEATURE]: markFeature,
  [UtilityTypes.API_GET_POLLING_FREQUENCY_RESPONSE]: setPollingFrequency,
  [UtilityTypes.TILE_MODULE_FETCHED]: tileModuleFetched,
  [UtilityTypes.SET_HOME_FEATURE_FETCH_STATUS]: setHomeFeatureStatus,
  [UtilityTypes.ADD_CALLED_ENDPOINT_FROM_NOTIFICATION_CYCLE]: addCalledEndpoint,
  [UtilityTypes.BASE_FETCH_COMPLETED]: baseFetchCompleted,
  // modals handling
  [UtilityTypes.OPEN_MODAL]: openModal,
  [UtilityTypes.CLOSE_MODAL]: closeModal,
  // tabs handling
  [UtilityTypes.CHANGE_TAB]: changeTab,
  // animate handling
  [UtilityTypes.CHANGE_CHART_ANIMATE_STATE]: changeChartAnimateState,
  // cleanup
  [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE, {
    navigationFeatures: state.navigationFeatures,
    notificationInterval: state.notificationInterval,
    allDeals: state.allDeals,
    lastUserInteraction: state.lastUserInteraction,
    pollingFrequency: state.pollingFrequency,
    baseFetchCompleted: state.baseFetchCompleted,
  }),
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
