import React from 'react';
import styled from 'styled-components';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

const TopValue = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: right;
  font-weight: normal;
`;

const BottomValue = styled.div`
  font-size: 0.750rem;
  line-height: 1rem;
  text-align: right;
  font-weight: normal;
`;

export default (props) =>
  <div>
    <TopValue>{ValueFormatters.millionFormatter(props.topValue)}</TopValue>
    {props.bottomValue && <BottomValue>{ValueFormatters.commaDecimalFormatter(props.bottomValue)}x</BottomValue>}
  </div>
