import { cancel, fork, takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import IsvTypes from 'Isv/actions/IsvTypes';
import { create } from 'services/api';
import { handleDashboardResponse } from 'Isv/actions/IsvInformationBannerAction';
import { receiveIsvDeal, requestIsvDeal, apiFailure } from 'Isv/actions/IsvUtilityActions';
import { getIsvCurrentDealId } from "Isv/selectors";

export default (api, dispatch, getState) => {
    function* worker(action) {

        function* handleRequest(req, endPoint, params, success) {
            try {
                const response = yield call(req, endPoint, params);
                response.ok ? yield put(success(response.data)) : yield put(apiFailure('Bad Response'));
            } catch (error) {
                yield put(apiFailure('Bad call: ' + error));
            }
        }

        switch (action.type) {
            case IsvTypes.API_REQUEST_DASHBOARD_DATA: {
                const currentDealId = yield select(getIsvCurrentDealId);
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.putData();
                const response = yield call(apiType.req, action.endPoint, action.params);
                if (response.ok) {
                    yield put(handleDashboardResponse(response.data))
                    yield put(receiveIsvDeal(response.data));
                    const requestIsvDealAction = requestIsvDeal(currentDealId, false);
                    yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
                }
                break
            }

            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            IsvTypes.API_REQUEST_DASHBOARD_DATA,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, IsvTypes.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
