import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';

import { Figure, FigCaption } from 'shared/accessibility/components/Caption';

const ThemeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ThemeLabel = styled.span`
  width: 150px;
  margin: auto 0;
`;

const ThemeList = styled.ul`
  flex: 1;
  padding: 0;
  margin: 0;
  li {
    list-style-type: disc;
    height: 30px;
    line-height: 30px;
  };
`;

const ThemeListItem = styled.li`
    font-size: 0.750rem;
    float: left;
    padding-right: 24px;
`;

class ThemeBar extends PureComponent {
    render() {
        return (
            <ThemeContainer>
                <ThemeLabel className="text-timestamp">
                    {this.props.label}
                </ThemeLabel>
                <Figure>
                    <FigCaption>{this.props.listDefinition}</FigCaption>
                    <ThemeList>
                        {
                            this.props.themes.map((theme, index) => (
                                <ThemeListItem key={`${index}-${theme}`}>{theme}</ThemeListItem>
                            ))
                        }
                    </ThemeList>
                </Figure>
            </ThemeContainer>
        );
    }
}

export default compose(
    withTranslate((translate, props) => ({
        label: translate(props.labelKey, props.label),
        listDefinition: translate('', `${props.label} themes`),
    }))
)(ThemeBar);
