import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
import { browserHistory } from 'react-router';
import styled from 'styled-components';

import { SvgIcon } from 'shared/components/Icon';

import withModals from 'shared/hoc/withModals';

import { featurePageIds } from 'constants/featurePages';

import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';
import { buttonKeyHandler } from 'util/onKeyPress';

import TranslationText from 'translations/TranslationText';
import flexbox from 'shared/styles/mixins/flexbox';

const LinkToFeedback =  styled.div`
  ${flexbox('row', 'center', 'flex-start')}

  &:hover span {
    text-decoration: underline;
  }
`;

const provideKeyForHighlightHeader = theme => {
  switch (theme.themeTypeId) {
    case 1: {// Positive
      return 'investor_feedback.positive_feedback_highlights';
    }
    case 2: {// Concern
      return 'investor_feedback.concern_highlighted';
    }
    case 3: {// Valuation
      return 'investor_feedback.valuation_fedback_highlights';
    }
    default: {// if not recognized
      return `${theme.themeTypeName} Feedback Highlights`;
    }
  }
}

class FeedbackHighlightContainer extends PureComponent {

  navigateToFeedback = (e, /* feedback */) => {
    e.stopPropagation();
    
    browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE);
  }

  render() {

    return (
      <div className="feedback-highlight-container" style={{ padding: '20px 10px' }}>
        <h3 style={{ margin: '0 0 10px 0'}}>
          <TranslationText
              dictionaryKey={provideKeyForHighlightHeader(this.props.theme)}
              defaultText={`${this.props.theme.themeTypeName} Feedback Highlights`}
            />
        </h3>
        <div>
          <Row className="text-normal">
            {
              this.props.highlightData
                .filter(feedback => feedback.text)
                .map(feedback => (
                  <Col key={feedback.feedbackId} sm={4}>
                    <ResponsiveEllipsis
                      maxLine={4}
                      text={feedback.text}
                    />
                  </Col>
                ))
            }
          </Row>
          <Row className="text-normal">
            {
              this.props.highlightData
                .filter(feedback => feedback.text)
                .map(feedback => (
                  <Col key={feedback.feedbackId} sm={4}>
                    <LinkToFeedback
                      className="text-timestamp"
                      style={{ padding: '10px 0px', cursor: 'pointer' }}
                      onClick={e => this.navigateToFeedback(e, feedback)}
                      role="button"
                      onKeyDown={e => buttonKeyHandler(e, () => this.navigateToFeedback(e, feedback))}
                      tabIndex={0}
                    >
                      <span>{feedback.investor}</span>
                      <SvgIcon
                        color="#DB0011"
                        icon="chevron-right"
                        size="xxsmall"
                        style={{ marginLeft: 5 }}
                        aria-hidden
                      />
                    </LinkToFeedback>
                  </Col>
                ))
            }
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  withModals(
    null,
    (__, open) => ({
        openModal: modalId => open(modalId),
    }),
  )
)(FeedbackHighlightContainer);
