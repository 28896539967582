import { fork, take, select } from 'redux-saga/effects';

import loginSaga from './Login';
import heartBeatSaga from './HeartBeat';

import fileSaga from './File';
import storageSaga from './Storage';

import appSetup from './AppSetup';
import baseRequest from './Requests';
import checkFeature from './CheckFeature';
import dealSetup from './DealSetup';
import eventNotifications from './EventNotifications';
import homeFeatures from './HomeFeatures';
import logout from './Logout';
import loginForm from './LoginForm';
import siteText from './SiteText';
import devices from './Devices';

import myDocs from './CheckFeature/MyDocs';
import pricing from './CheckFeature/Pricing';
// import investors from './CheckFeature/Investors';
import roadshow from './CheckFeature/Roadshow'
import investorFeedback from './CheckFeature/InvestorFeedback'
import orderbook from './CheckFeature/Orderbook'

import dashboard from 'Isv/sagas/Dashboard'
import fileOperation from 'Isv/sagas/FileOperation'
import dealInformation from 'Isv/sagas/DealInformation'
import ecb2Return from 'Isv/sagas/Ecb2Return'
import referenceInformation from 'Isv/sagas/ReferenceInformation'

import unauthorizedHandler from './UnauthorizedHandler';

import analytics from './Analytics';

import markitApi from '../services/MarkitApi';
import { create } from '../services/api';



function* logger() {
    while (true) {
        const action = yield take('*');
        const state = yield select(s => s);

        console.log(`Logger ---- Action: ${action.type}`)
        console.log(action);
        console.log(state);
    }
}

const rootSaga = (apiUrl, dispatch, getState) => {
    const MarkitDataApi = markitApi.create(apiUrl);
    const api = create(apiUrl);

    function* root() {
        if (process.env.NODE_ENV === 'development') {
            yield fork(logger);
        }

        yield fork(loginForm(api, dispatch, getState).watcher);

        yield fork(loginSaga(MarkitDataApi).watcher);
        yield fork(heartBeatSaga(MarkitDataApi).watcher);
        // v1.6
        yield fork(storageSaga(api, dispatch, getState).watcher);
        yield fork(fileSaga(api, dispatch, getState).watcher);
        // v1.7
        yield fork(appSetup(api, dispatch, getState).watcher);
        yield fork(baseRequest(api, dispatch, getState).watcher);
        yield fork(dealSetup(api, dispatch, getState).watcher);
        yield fork(logout(api, dispatch, getState).watcher);
        yield fork(eventNotifications(api, dispatch, getState).watcher);
        yield fork(homeFeatures(api, dispatch, getState).watcher);
        // feature data loading + checking
        yield fork(checkFeature(api, dispatch, getState).watcher);
        yield fork(myDocs(api, dispatch, getState).watcher);
        yield fork(pricing(api, dispatch, getState).watcher);
        yield fork(roadshow(api, dispatch, getState).watcher);
        yield fork(investorFeedback(api, dispatch, getState).watcher);
        yield fork(orderbook(api, dispatch, getState).watcher);
        yield fork(dashboard(api, dispatch, getState).watcher);
        yield fork(fileOperation(api, dispatch, getState).watcher);
        yield fork(dealInformation(api, dispatch, getState).watcher);
        yield fork(ecb2Return(api, dispatch, getState).watcher);
        yield fork(referenceInformation(api, dispatch, getState).watcher);
        
        // yield fork(investors(api, dispatch, getState).watcher);
        // 401
        yield fork(unauthorizedHandler(api, dispatch, getState).watcher);
        // Adobe Analytics
        yield fork(analytics(api, dispatch, getState).watcher);
        // SiteText
        yield fork(siteText(api, dispatch, getState).watcher);
        // Devices
        yield fork(devices(api, dispatch, getState).watcher);

    }
    return root;
};

export default rootSaga;
