//
// All Used Icons since Landing Page refactor
//
import React from 'react';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';

// all used icons/svgs
import DocumentDownload from './doc-download.svg';

class BaseImgIcon extends React.PureComponent {
    render() {
        let src, alt;

        switch (this.props.icon) {
            case 'DocumentDownload': {
                src = DocumentDownload;
                alt = this.props.documentUploadAlt;
                break;
            }
            default:
              console.log(`Unrecognised img icon ${this.props.icon}`);
        }

        if (!src && !alt) return null;

        const other = {};
        other['aria-hidden'] = this.props['aria-hidden'];
        other['aria-checked'] = this.props['aria-checked'];
        return (
            <img
                className={this.props.className}
                style={this.props.style}
                src={src}
                alt={alt || ''}
                aria-label={alt || ''}
                {...other}
            />
        );
    }
}

export default compose(
    withTranslate(
        translate => ({
            documentDownloadAlt: translate('', 'Document Upload'),
        }),
    ),
)(BaseImgIcon);
