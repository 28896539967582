import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ListGroup } from 'react-bootstrap';

import { changeActiveCategory } from 'actions/MyDocsActions';
import {
    getMyDocsSummaryData,
    getMyDocsActiveCategoryId,
    getAllNotArchivedDocumentsCount
} from 'selectors';

import { events } from 'constants/analytics';
import withAnalytics from 'shared/hoc/withAnalytics';

import AllCategoryListItem from './AllCategoryListItem';
import CategoryListItem from './CategoryListItem';

const listGroupInlineStyles = { borderTop: '1px solid #D8D8D8' };

class CategoryList extends PureComponent {
    changeCategory = (id, name) => {
        if (!id) {
            // All documents category
            this.props.changeActiveCategory(null);
            this.props.categoryEvent('All Documents')
        } else {
            // Dedicated category
            this.props.changeActiveCategory(id);
            this.props.categoryEvent(name);
        }

        const elem = document.getElementById('document-list');
        if (elem) elem.focus();
    }

    render() {
        return (
            <ListGroup style={listGroupInlineStyles}>
                <AllCategoryListItem
                    isActive={!this.props.activeCategoryId}
                    handleClick={this.changeCategory}
                    total={this.props.allNotArchived}
                />
                {
                    this.props.myDocsSummary.map(category => (
                        <CategoryListItem
                            id={category.documentCategoryId}
                            key={category.documentCategoryId}
                            isActive={this.props.activeCategoryId === category.documentCategoryId}
                            handleClick={this.changeCategory}
                            category={category}
                        />
                    ))
                }
            </ListGroup>
        )
    }
};

export default compose(
    connect(
        state => ({
            myDocsSummary: getMyDocsSummaryData(state),
            activeCategoryId: getMyDocsActiveCategoryId(state),
            allNotArchived: getAllNotArchivedDocumentsCount(state),
        }),
        dispatch => ({
            changeActiveCategory: categoryId => dispatch(changeActiveCategory(categoryId)),
        }),
    ),
    withAnalytics((eventTriggered) => ({
        categoryEvent: categoryName => eventTriggered(events.MY_DOCS_LEFT_NAV(categoryName.toLowerCase())),
    })),
)(CategoryList);
