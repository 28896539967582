import React, { PureComponent } from 'react';
import { Link, browserHistory } from 'react-router';
import styled from 'styled-components';

import { typography, fontColors, components, outlines } from 'shared/styles/variables/theme';

import { FontIcon } from 'shared/components/Icon';
import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';

import { anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';

const HeroContainer = styled.div`
    width: 150px;
    margin-right: 40px;
    margin-top: 30px;
`;
const LinkContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
`;

const sharedStyle = `
    color: ${fontColors.white};
    font-size: ${typography.sizes['12px']};

`;
const NavigableLink= styled(Link)`
    ${components.link['--default']}
    ${sharedStyle}
    font-size: ${typography.sizes['16px']};
    display: inline-block;
    font-family: ${typography.family};

    &:focus {
        ${components.link['--state']['--focus']}
        ${sharedStyle}
        font-size: ${typography.sizes['16px']};
        outline: ${outlines.light};
    }

    &:hover {
        ${components.link['--state']['--hover']}
        ${sharedStyle}
        font-size: ${typography.sizes['16px']};
        span::before {
            text-decoration: none;
        }
    }
`;
const NotNavigable = styled.div`
    ${sharedStyle}
    font-size: ${typography.sizes['16px']};
    font-family: ${typography.family};
`;

const FeatureDescription = styled.div`
    ${sharedStyle}
    margin-top: 5px;
    font-family: ${typography.family.normal};
`;

const linkContainerIconStyle = {
    verticalAlign: 'middle',
    marginLeft: 7,
    color: '#fff',
    fontSize: typography.sizes['9px'],
    display: 'inline-block',
    marginTop: 0,
};

class Hero extends PureComponent {
    navigateEvent = e => {
        e.preventDefault();
        if (anchorEventHandling(e)) {
            browserHistory.push(this.props.url);
            this.props.onHeroClicked();
        }
    }

    render() {
        return (
            <HeroContainer>
                { this.props.featureIcon && this.props.featureIcon }
                <LinkContainer>
                    {
                        this.props.url ? (
                            <React.Fragment>
                                <NavigableLink
                                  id={this.props.id || 'navigable-link'}
                                  to={this.props.url}
                                  onKeyPress={this.navigateEvent}
                                  onClick={this.navigateEvent}
                                  aria-label={this.props.title}
                                >
                                    {this.props.title}
                                    
                                </NavigableLink>
                                <FontIcon
                                        style={linkContainerIconStyle}
                                        icon="Chevron"
                                        aria-hidden
                                    />
                            </React.Fragment>
                        ) : (
                            <NotNavigable>
                                {this.props.title}
                            </NotNavigable>
                        )
                    }

                </LinkContainer>
                <FeatureDescription>
                    <ResponsiveEllipsis
                        maxLine={3}
                        text={this.props.text}
                    />
                </FeatureDescription>
            </HeroContainer>
        );
    }
}

export default Hero;
