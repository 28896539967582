import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { featurePageIds } from 'constants/featurePages';
import dealFeatures from 'constants/dealFeatures';
import { checkFeature } from 'actions/UtilityActions';

import Loading, { FeaturePageLoadingContainer } from 'shared/components/Loading';

class InvestorFeedback extends PureComponent {
    componentDidMount() {
        this.props.checkFeature(dealFeatures.INVESTOR_FEEDBACK);
    }

    render() {
        if (!this.props.chartTypesFetched) {
            return (
                <FeaturePageLoadingContainer>
                    <Loading />
                </FeaturePageLoadingContainer>
            )
        }

        if (!this.props.chartTypes.length) {
            if (this.props.location.pathname !== featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE) {
                browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE);
                return null;
            }
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    chartTypes: state.investorFeedbackData.chartTypes,
    chartTypesFetched: state.investorFeedbackData.chartTypesFetched,
});

const mapDispatchToProps = dispatch => ({
    // polling
    checkFeature: feature => dispatch(checkFeature(feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestorFeedback);
