import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DCMLandingPage from './dcm/Landing.container';
import ISVLandingPage from 'Isv/Landing/Landing.container';
import { isISVUser } from 'selectors';
// import { isIsvExternalUser } from 'Isv/selectors';

class Landing extends PureComponent {

    componentDidMount() {
        document.title = 'HSBC MyDeal';
    }

    render() {
        return !this.props.isISVUser ? <DCMLandingPage /> : <ISVLandingPage />
    }
}

const mapStateToProps = (state) => ({
    isISVUser: isISVUser(state),
    // isvDealData:getIsvCurrentDeal(state),
    // isUserId:isIsvExternalUser(state)
});

export default connect(mapStateToProps, null)(Landing);
