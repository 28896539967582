import styled from 'styled-components';
import LoginBackground from 'images/Orderbook-bg.png';

export const Logo = styled.img`
  position: relative;
  top: -23px;
  height: 71px;
  width: auto;
  left: -24px;
`;

export const LoginContainer = styled.div`
  background-color: #4B545B;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${LoginBackground});
  background-position: center 120px;
  background-repeat: no-repeat;
  overflow: visible;
`;

export const LoginHeader = styled.div`
  height: 120.5px;
  background-color: #000000;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  margin: 20px auto;
  & h2 {
    font-size: 30px;
  }
`;

export const SecurityKeyInfo = styled.div`
  position: relative;
  width: 350px;
  background-color: #ededed;
  padding: 0px 30px 35px 30px;
  font-size: 0.750rem;
`;

export const PnStepsSection = styled(SecurityKeyInfo)`
  width: 390px;
`;

export const SecurityKeyInfoTotp = styled(SecurityKeyInfo)`
  width: 385px;
`;

export const HeaderSection = styled.div`
`;

export const ExtOptions = styled.div`
`;

export const OptionOne = styled.div`
  margin: 20px 0px 15px 0px;
`;

export const OptionTwo = styled.div`
  margin: 5px 0px 20px 0px;
`;

export const LoginForm = styled.div`
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 0px 30px 30px 30px;
  flex: 1;
`;

export const Header = styled.div`
  margin: 0px 0px 15px;
  line-height: 1.3;
  font-size: 1.500rem;
  color: #333;
`;

export const LoginFormFields = styled.form`
  display: inline-block;
`;

export const UserNameInfo = styled.div`
  padding: 1px 0 8px 0;
`
export const PnUserNameInfo = styled.div`
  padding-top: 22px;
`

export const LoginExtFormFields = styled(LoginFormFields)`
  padding: 0px 0 15px 0; 
`;

export const StaffIdContainer = styled.div`
  width: 390px;
`;

export const PContainer = styled.div`
`;

export const UserNameContainer = styled.div`
`;

export const ButtonContainer = styled.div`
  border-top: solid 1px black;
  padding-top: 20px;
  text-align: right;
`;

export const SpinnerSection = styled.div`
  display: inline-block;
  margin: 10px 0px 0px 6px;
`;

export const TotpButtonContainer = styled(ButtonContainer)`
  margin-top: 115px;
`;

export const PnButtonContainer = styled(ButtonContainer)`
`;

export const StyledInput = styled.input`
  display: block;
  height: 40px;
  margin-bottom: 15px;
  padding: 0px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(146, 146, 146);
  border-image: initial;
  &:focus {
    outline: none;
  }
`;

export const StyledExtInput = styled(StyledInput)`
  width: 390px;
`;

export const StyledTotpInput = styled(StyledInput)`
  width: 355px;
`;

export const StyledIntInput = styled(StyledInput)`
  width: 390px;
`;

export const StyledButton = styled.input`
  height: 40px;
  background-color: #fff;
  border: solid 1px #000;
  margin-left: 10px;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    color: rgb(0, 0, 0);
    cursor: not-allowed;
  }
  padding: 0 20px;
`;

export const ImageContainer = styled.div`
  width: 80px;
`;

export const InstructionContainer = styled.div`
  width: 300px;
`;

export const TotpSteps = styled.img`
  width: 340px;
  margin: 15px 0px 7px;
`;

export const PnSteps = styled(TotpSteps)`
  margin: 0px;
`

export const List = styled.ol`
  padding: 3px 0 0 10px;
  & li {
    padding-bottom: 10px;
  }
`
export const ExtFooterLinks = styled.div`
  margin-top: 50px;
  & p {
    margin:0px;
    font-size: 14px;
    & a {
      color : #337ab7;
      text-decoration:none;
      font-size: 14px;
    }
  }
  & div {
    margin-top: 1px;
  }
`
export const PnFooterLinks = styled.div`
  margin-top: 88px;
  div {
    margin-top: 1px;
  }
`
export const TotpFooterLinks = styled.div`
  margin-top: 94px;
  div {
    margin-top: 1px
  }
`

export const Left = styled.div`
  float : left;
  width : 195px;
  text-align : center;
  & p:second-child {
    margin : 15px;
  }
`

export const Right = styled(Left)`
  border-left: solid 0.1px gray
`
export const TotpStepList = styled(List)`
  font-size: 12px;
  & li {
    width: 89px;
    margin: 0px 22px 20px 6px;
    float: left;
  } 
  & li:nth-child(2){
    width: 101px;
    margin: 0px 9px 20px 8px;
  }
  & li:last-child {
    width: 55px;
    margin: 0 0 0 4px;
  }
`

export const PnStepList =  styled(TotpStepList)`
  li {
    margin-bottom:0px;
  }
`;

export const Label = styled.label`
  font-weight: normal;
`;