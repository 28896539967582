import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import dealFeatures from 'constants/dealFeatures';
import { checkFeature } from 'actions/UtilityActions';

import { isDCMDealType, isECMDealType, areDCMOrdersFetched, areECMOrdersFetched, getTrancheLoader } from 'selectors';

import Loading, { FeaturePageLoadingContainer } from 'shared/components/Loading';

class OrderBook extends PureComponent {
    componentDidMount() {
        this.props.checkFeature(dealFeatures.ORDERBOOK);
    }

    render() {
        if (this.props.isDCM) {
            if (this.props.trancheLoader ? false : this.props.isDCMFetching) {
                return (
                    <FeaturePageLoadingContainer>
                        <Loading />
                    </FeaturePageLoadingContainer>
                );
            }

            return this.props.children;
        }

        if (this.props.isECM) {
            if (this.props.isECMFetching) {
                return (
                    <FeaturePageLoadingContainer>
                        <Loading />
                    </FeaturePageLoadingContainer>
                );
            }

            return this.props.children;
        }
        
        return null;
    }
}

export default connect(
    state => ({
        isDCM: isDCMDealType(state),
        isECM: isECMDealType(state),
        isDCMFetching: !areDCMOrdersFetched(state),
        isECMFetching: !areECMOrdersFetched(state),
        trancheLoader: getTrancheLoader(state),
    }),
    dispatch => ({
        // polling
        checkFeature: feature => dispatch(checkFeature(feature)),
    }),
)(OrderBook);
