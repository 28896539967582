
//
// Once defined should never change !
//

const LARGE_SCREEN = 1920;      // Wide Monitors (1920x1080)
const MEDIUM_SCREEN = 1365;     // ThinkPad (1366x768)
const SMALL_SCREEN = 768;       // iPad (768x1024) P/L mode

export default {
    LARGE_SCREEN,
    MEDIUM_SCREEN,
    SMALL_SCREEN,
};
