import Types, { UtilityTypes } from 'actions/Types';
import { loginFailure } from 'actions/LoginActions';

const apiFailure = (message) => ({
  type: Types.API_FAILURE,
  message,
});

const apiRequest = (isFetching) => ({
  type: Types.API_REQUEST,
  isFetching,
});

const killActiveSagaWorkers = (actionType) => ({
  type: Types.KILL_ACTIVE_SAGA_WORKERS,
  actionType,
});

const detectUserInteraction = () => ({
  type: Types.USER_INTERACTION,
});

const baseFetchCompleted = dealId => ({
  type: UtilityTypes.BASE_FETCH_COMPLETED,
  dealId
});

// @effect saga handler
const appSetup = (unique_name, isISVUser) => ({
  type: UtilityTypes.APP_SETUP,
  unique_name,
  isISVUser
});

const requestAcceptTerms = termsAndConditionsId => ({
  type: Types.API_GET_ACCEPT_TERMS_REQUEST,
  endPoint: `/v1/TermsAndConditions?termsAndConditionsId=${termsAndConditionsId}&accepted=true`,
  ajaxType: 'PATCH',
  onSuccess: receiveTermsAccepted,
  onFailure: loginFailure
});

const receiveTermsAccepted = () => ({
  type: Types.API_GET_ACCEPT_TERMS_RESPONSE,
  accepted: true,
});

const purge = () => ({ type: UtilityTypes.PURGE });

// @effect saga handler
const dealSetup = (dealId, redirect) => ({
  type: UtilityTypes.DEAL_SETUP,
  dealId,
  redirect,
});

const dealPurge = () => ({ type: UtilityTypes.DEAL_PURGE });

const markFeature = (dealFeature, dirty) => ({
  type: UtilityTypes.MARK_FEATURE,
  dealFeature,
  dirty,
});

const receiveDeal = dealsData => ({
  type: UtilityTypes.API_GET_DEAL_RESPONSE,
  dealsData,
})
const requestDeal = (dealId, loader) => ({
  type: UtilityTypes.API_GET_DEAL_REQUEST,
  endPoint: `/v1/deals/${dealId}`,
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveDeal,
  loader,
});

const receiveDealInfo = response => ({
  type: UtilityTypes.API_GET_DEAL_INFO_RESPONSE,
  dealInfo: response.data[0],
});

const requestDealInfo = (dealId, loader) => ({
  type: UtilityTypes.API_GET_DEAL_INFO_REQUEST,
  endPoint: `/v1/dealInfo/${dealId}`,
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveDealInfo,
  loader,
});

const tileModuleFetched = tile => ({
  type: UtilityTypes.TILE_MODULE_FETCHED,
  tile,
});

const receiveDeals = allDeals => ({
  type: UtilityTypes.API_GET_DEALS_RESPONSE,
  allDeals,
})
const requestDeals = (loader) => ({
  type: UtilityTypes.API_GET_DEALS_REQUEST,
  endPoint: '/v2/deals',
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveDeals,
  loader,
});

const receiveNavigationFeatures = ({ data }) => ({
  type: UtilityTypes.API_GET_NAV_FEATURES_RESPONSE,
  navigationFeatures: data,
});
const requestNavigationFeatures = loader => ({
  type: Types.API_GET_NAV_FEATURES_REQUEST,
  endPoint: '/v1/StaticData/NavigationFeature',
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveNavigationFeatures,
  loader,
});

const startEventNotificationSaga = () => ({
  type: UtilityTypes.START_EVENT_NOTIFICATION_SAGA,
});
const stopEventNotificationSaga = () => ({
  type: UtilityTypes.STOP_EVENT_NOTIFICATION_SAGA,
});

const receivePollingInterval = response => ({
  type: UtilityTypes.API_GET_POLLING_FREQUENCY_RESPONSE,
  pollingFrequency: response.data[0].pollingFrequency,
});
const requestPollingInterval = loader => ({
  type: UtilityTypes.API_GET_POLLING_FREQUENCY_REQUEST,
  endPoint: '/v1/ServiceConfiguration',
  params: {},
  ajaxType: 'GET',
  onSuccess: receivePollingInterval,
  loader, // v 1.7
});

const checkFeature = dealFeature => ({
  type: UtilityTypes.CHECK_FEATURE,
  dealFeature,
});

const handleHomeFeature = (dealFeature, dealId) => ({
  type: UtilityTypes.EFFECT_HANDLE_HOME_FEATURE_SETUP,
  dealFeature,
  dealId,
});

const setHomeFeatureStatus = (dealFeature, status) => ({
  type: UtilityTypes.SET_HOME_FEATURE_FETCH_STATUS,
  dealFeature,
  status,
});

const addCalledEndpoint = (dealFeature, endPoint) => ({
  type: UtilityTypes.ADD_CALLED_ENDPOINT_FROM_NOTIFICATION_CYCLE,
  dealFeature,
  endPoint,
});

const unauthorizedResponseEffect = dealFeature => ({
  type: UtilityTypes.UNAUTHORIZED_EFFECT_RESPONSE_FEATURE,
  dealFeature,
});

const requestFeatureData = (dealFeature, dealId) => ({
  type: UtilityTypes.EFFECT_REQUEST_FEATURE_DATA,
  dealFeature,
  dealId,
});

const openModal = modalId => ({
  type: UtilityTypes.OPEN_MODAL,
  modalId,
});

const closeModal = modalId => ({
  type: UtilityTypes.CLOSE_MODAL,
  modalId,
})
const changeTab = (tabsId, tabId) => ({
  type: UtilityTypes.CHANGE_TAB,
  tabsId,
  tabId,
});

// NOTE: All site text is for en-gb at the moment so the language is hard coded
const requestSiteText = (languageCode, siteTextType) => ({
  type: UtilityTypes.API_GET_SITE_TEXT_REQUEST,
  endPoint: `../../en-gb/${siteTextType}`,
  ajaxType: 'GET',
});

const requestManageDevice = () => ({
  type: UtilityTypes.API_REQUEST_MANAGE_DEVICE,
  endPoint: `/v1/managedevice`,
  ajaxType: 'GET',
});

const receiveManageDevice = response => ({
  type: UtilityTypes.API_RECEIVE_MANAGE_DEVICE,
  devices: response.data,
});

const deactivateDevice = deviceId => ({
  type: UtilityTypes.API_DEACTIVATE_DEVICE,
  endPoint: `/v1/managedevice/DeactivateUserDevice?userRegisteredDeviceId=${deviceId}`,
  ajaxType: 'PATCH',
});

export {
    apiRequest,
    apiFailure,
    killActiveSagaWorkers,
    detectUserInteraction,
    appSetup,
    purge,
    dealSetup,
    dealPurge,
    tileModuleFetched,
    markFeature,
    requestDeal,
    requestDealInfo,
    requestDeals,
    requestNavigationFeatures,
    startEventNotificationSaga,
    stopEventNotificationSaga,
    requestPollingInterval,
    checkFeature,
    handleHomeFeature,
    setHomeFeatureStatus,
    addCalledEndpoint,
    unauthorizedResponseEffect,
    requestFeatureData,
    // fetching
    baseFetchCompleted,
    // modal
    openModal,
    closeModal,
    // tabs
    changeTab,
    requestAcceptTerms,
    requestSiteText,
    requestManageDevice,
    receiveManageDevice,
    deactivateDevice,

};
