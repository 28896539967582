import React, { Component } from 'react';

import PropTypes from 'prop-types';
// import { ButtonGroup } from 'react-bootstrap';
import HsbcButton from '../HsbcButton/HsbcButton.js';

/**
 * The only true button group.
 */

class HsbcButtonGroup extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}

	}


	render() {
		const buttons = this.props.titles.map((title, index) => {
			return (
				<HsbcButton
					key={title.id}
					size="normal"
					className={this.props.className + (this.props.active.id === title.id ? " active" : "")}
					onClick={this.props.handleClick.bind(this, title)}
					>
					<span style={{ overflow: "hidden", whiteSpace: "nowrap" }} className={this.props.active.title === title.title ? "" : "titleText"}>{title.title}</span>
					<div className="subText" style={{ textAlign: 'left', fontSize: 12, color: (title.status === "." ? "#fff" : "") }} >
						{title.status}
					</div>
				</HsbcButton>
			)
		});

		let btnGrpStyle = {
			width: this.props.width,
			backgroundColor: this.props.color,

		}


		return (
			<div style={btnGrpStyle}>
					{buttons}
			</div>
		);
	}

}

HsbcButtonGroup.propTypes = {
	/**
	 * string `title` : Button Label
	 *
	 * string `status` : Button Subtext (Tranche nav)
	 */
	titles: PropTypes.arrayOf(PropTypes.object),
	width: PropTypes.string,
	color: PropTypes.string,
	/**
	 * Bool value to disable tranche nav horizontal scroll
	 */
	disablePagination: PropTypes.bool
};
HsbcButtonGroup.defaultProps = {
	titles: [{ title: "Home" }, { title: "1st" }, { title: "2nd" }],
	width: "100%",
	color: "#fff",
	disablePagination: false,
};

export default HsbcButtonGroup;
