import resolutions from '../variables/resolution';

//
// Once defined should never change !
//
const largeScreen = styles => `
    @media screen and (min-width: ${resolutions.LARGE_SCREEN}px) {
        ${styles}
    }
`;

const mediumScreen = styles => `
    @media screen and (min-width: ${resolutions.MEDIUM_SCREEN}px) and (max-width: ${resolutions.LARGE_SCREEN - 1}px) {
        ${styles}
    }
`;

const smallScreen = styles => `
    @media screen and (min-width: ${resolutions.SMALL_SCREEN}px) and (max-width: ${resolutions.MEDIUM_SCREEN - 1}px) {
        ${styles}
    }
`;

//
// iPad / landscape / portrait / Retina 2x
//
const portraitMode = styles => `
    @media (orientation: portrait) {
        ${styles}
    }
`;
const landscapeMode = styles => `
    @media (orientation: landscape) {
        ${styles}
    }
`;
// optional 
const retinaScreen = styles => `
    @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
        ${styles}
    }
`;

//
// For smaller viewport ( < 800 px we should display that our app does not support this dimensions - UX perspective )
//

export default {
    largeScreen,
    mediumScreen,
    smallScreen,
    // proto
    portraitMode,
    landscapeMode,
    retinaScreen,
};
