import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { getRoadShowSummaryCoordinators } from 'selectors';
import TranslationText from 'translations/TranslationText';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align
    -items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

class CoordinatorsModal extends React.PureComponent {
    render() {
        return (
            <Fragment>
                <Container>
                    {
                        this.props.coordinators.length > 0 && this.props.coordinators.map((coordinator, index) => (
                                        <div key={index} style={{ margin: 20 }}>
                                            <div className="text-bold">{coordinator.name}</div>
                                            <div>
                                                <TranslationText
                                                    dictionaryKey={'roadshow.roadshow_coordinator'}
                                                    defaultText={'Roadshow Coordinator'}
                                                />
                                            </div>
                                            {
                                                coordinator.title && (<div>{coordinator.title}</div>)
                                            }
                                            {
                                                coordinator.directTelephone && (<div>T: {coordinator.directTelephone}</div>)
                                            }
                                            {
                                                coordinator.mobileTelephone && (<div>M: {coordinator.mobileTelephone}</div>)
                                            }
                                            <div className="popover-spacing-bottom-sm">{coordinator.email}</div>
                                        </div>
                                    ))
                    }
                </Container>
                {
                    (this.props.coordinators.length === 0) &&  (
                        <div style={{ textAlign: 'left', paddingBottom: 20 }}>
                            <TranslationText
                                dictionaryKey={'roadshow.no_roadshow_coordinators_available'}
                                defaultText={'No Roadshow Coordinators available'}
                            />
                        </div>
                    )
                }
            </Fragment>
        )
    }
}

export default compose(
    connect(
        state => ({
            coordinators: getRoadShowSummaryCoordinators(state),
        }),
    )
)(CoordinatorsModal);
