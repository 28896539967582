import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, Navbar } from 'react-bootstrap';
import { browserHistory, Link, withRouter } from 'react-router';
import styled from 'styled-components';

import { SecondarySlateButton } from '../../components/HsbcButton';
import logo from '../../images/HSBC_Logo.svg';

import LanguagePicker from 'components/LanguagePicker/LanguagePicker';
import withLanguage from 'translations/withLanguage';
import TranslationText, { translationString } from 'translations/TranslationText';

import { mapLinkToKey, mapLinkToAnalyticEvent } from 'util/homeModules';
import { buttonKeyHandler } from 'util/onKeyPress';

import { eventTriggered } from 'actions/AnalyticsActions';

import { featurePageIds } from 'constants/featurePages';

import { typography } from 'shared/styles/variables/theme'

import { version } from 'constants/buildDetails.json';

const NavContainer = styled(Navbar)`
  height: 100px;
  & .container {
    position: relative
  }
`;

const BodgedHeaderStyle = styled.div`
  position: absolute;
  bottom: 0px;
  height: 40px;
  width: 10000px;
  overflow: visible;
  left: -1000px;
  background-color: #253038;
  border-top: solid 1px rgba(255,255,255,0.1);
`;

const VersionHover = styled(Link)`
  .version-text {
    position: fixed;
    top: 46px;
    left: calc((100vw - 1280px) / 2 + 71px);
    font-size: 10px;
    visibility: hidden;
  }
  &:hover {
    .version-text {
      visibility: visible;
    }
  }
`;

const Logo = styled.img`
  position: relative;
  top: -23px;
  height: 71px;
  width: auto;
  left: -24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-size: ${typography.sizes['12px']};

  *[tabindex="0"]:focus {
      outline: 1px dotted #7d7d7d;
  }
`;

const NavItem = styled.div`
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, .1);
  &:first-child {
    border-left: 1px solid rgba(255, 255, 255, .1);
  }
`;

const EnhancedNav = styled(Nav)`
  *[tabindex="0"]:focus {
      outline: 1px dotted #7d7d7d;
  }
  position: relative;
  padding-right: 0;
  background-color: #253038;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
`;

const FakeLink = styled.span`
  color: ${props => props.active ? '#fff' : '#969696'};
  width: 100%;
  height: 39px;
  padding-left: 0;
  padding-right: 0;
  border-bottom: ${props => props.active ? '4px solid #DB0011' : '4px solid transparent'};
  padding-top: 9.5px;
  padding-bottom: 11.5px;
  line-height: 20px;
  position: relative;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: ${props => !props.active ? 'underline' : 'none'};
  }
`;

const SettingsNavLink = styled.li`
  position: absolute !important;
  right: 0px;
  margin-right: 20px !important;
`;

const getLinkIconAndStatus = (linkItem, activeRoute) => {
  const link = activeRoute.toLowerCase();
  const linkUrl = linkItem.url.toLowerCase();

  return (link === '/' || linkUrl === '/')
    ? link === linkUrl
    : (link.indexOf(linkUrl) > -1) || false;
};

const NavMain = (props) => {
    const activeRoute = props.location.pathname;
    const navlinks = props.links.map((link, index) => {
        if (link.visibleInMainMenu) {
            const  isActive = getLinkIconAndStatus(link, activeRoute);
            const  idAttr = link.url.substring(1) ? link.url.substring(1) :'myDeal';
            return (
              <li
                id={`nav-${idAttr}`}
                role="presentation"
                key={`key-link-${link.url}`}
                style={{ marginRight: 40, display: 'inline-block', paddingTop: 0, paddingBottom: 0 }}
              >
                <FakeLink
                  role="button"
                  aria-label={`${
                    translationString(
                      'common.navigate_to_<module_name>_module',
                      props.language,
                      `Navigate to ${link.title} module`,
                      [
                        ['<module_name>', link.title]
                      ]
                    )
                    }, ${isActive ? 'selected' : 'not selected'}`
                  }
                  tabIndex={0}
                  onClick={() => !isActive && props.navigateToUrl(link.url)}
                  onKeyDown={e => buttonKeyHandler(e, () => !isActive && props.navigateToUrl(link.url))}
                  active={isActive}
                >
                  <TranslationText
                    dictionaryKey={mapLinkToKey(link.url)}
                    defaultText={link.title}
                  />
                </FakeLink>
              </li>
            );
        } else {
            // Do not create a link - flagged as NOT visible in MainMenu
            return false;
        }
    },
    ).filter((nav) => nav);

    return (
      <NavContainer role="banner">
        <BodgedHeaderStyle />
        <Navbar.Header style={{ height: 60 }}>
          <Navbar.Brand>
            <VersionHover
              to={'/'}
              onKeyPress={e => { e.preventDefault(); props.navigateToUrl(featurePageIds.LANDING_PAGE) }}
              title="Click to view the home page"
            >
              <Logo src={logo} alt="HSBC"/>
              {props.title}
              {
                version && 
                <span className="version-text">
                  MyDeal version: {version}
                </span>
              }
            </VersionHover>
          </Navbar.Brand>

          <Container>
            <NavItem>
              <LanguagePicker />
            </NavItem>
            {props.isDeals ?
              <NavItem>
                {props.popoverElement}
              </NavItem> : null
            }
            <SecondarySlateButton
              onClick={props.logout}
              onKeyPress={props.logout}
            >
              <TranslationText
                dictionaryKey={"home_page.logout"}
                defaultText={"Logout"}
              />
            </SecondarySlateButton>
          </Container>
        </Navbar.Header>
        <EnhancedNav role="navigation" className="navbar-underline">
          <Fragment>
            {navlinks}
            <SettingsNavLink>
            <FakeLink
                  id='nav-settings'
                  role="button"
                  aria-label={`${
                    translationString(
                      'common.view_settings',
                      props.language,
                      `View Settings`,
                    )
                    }, ${false ? 'selected' : 'not selected'}`
                  }
                  tabIndex={0}
                  onClick={() => props.navigateToUrl("/settings")}
                  onKeyDown={e => {}}
                  active={false}
                >
                  <TranslationText
                    dictionaryKey="Settings"
                    defaultText="Settings"
                  />
                </FakeLink>
              </SettingsNavLink>
          </Fragment>
        </EnhancedNav>
      </NavContainer>
    );
};

const mapStateToProps = (state, ownProps) => ({
  isDeals: state.utility.allDeals.length > 0,
})

const mapDispatchToProps = (dispatch, state) => ({
  navigateToUrl: url => {
    browserHistory.push(url);
    dispatch(eventTriggered(mapLinkToAnalyticEvent(url)));
    
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withLanguage(
    withRouter(routerProps => <NavMain {...routerProps} />)
  )
);

NavMain.defaultProps = {
    links: [{ url: '/', title: 'MyDeal' }],
    user: 'Username',
};
