import { tableEllipsis } from 'shared/styles/mixins/ellipsis';
import { typography } from 'shared/styles/variables/theme';

export const headerStyle = {
  height: 70,
  lineHeight: '20px',
  borderRight: '1px solid rgb(216, 216, 216)',
  verticalAlign: 'top',
  fontWeight: 500,
  color: 'rgb(77, 100, 116)',
}

export const rightAlignedHeaderStyle = {
  ...headerStyle,
  textAlign: 'right',
}

export const centerAlignedHeaderStyle = {
  ...headerStyle,
  textAlign: 'center'
}

export const sortedHeaderStyle = {
  backgroundColor: 'rgb(77, 100, 116)',
  color: '#fff',
}
export const leftAlignedHeaderStyle = {
    ...headerStyle,
   textAlign: 'left'
  }
  
export const cellStyle = {
  fontSize: typography.sizes['14px'],
  borderRight: '1px solid rgb(216, 216, 216)',
  borderTop: 'none',
  borderBottom: '1px solid rgb(216, 216, 216)',
  height: 25,
  wordBreak: 'break-word',
  wordWrap: 'break-word'
}

export const rightAlignedCellStyle = {
  ...cellStyle,
  textAlign: 'right'
}

export const centerAlignedCellStyle = {
  ...cellStyle,
  textAlign: 'center'
}

export const investorCellStyle = {
  ...cellStyle,
  borderRight: '1px solid rgb(216, 216, 216)',
  ...tableEllipsis,
}
export const leftAlignedCellStyle = {
    ...cellStyle,
     textAlign: 'left'
  }
  