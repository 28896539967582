//
// Roadshow
//
export const ROADSHOW_MULTITEAM_MODAL_ID = 'r-m-m-1';
export const ROADSHOW_COMBINED_CONTACTS_MODAL_ID_1 = 'r-c-m-5';
export const ROADSHOW_COMBINED_CONTACTS_MODAL_ID_2 = 'r-c-m-6';
export const ROADSHOW_COMBINED_CONTACTS_MODAL_ID_3 = 'r-c-m-7';
//
// Investor Feedback
//
export const INVESTOR_FEEDBACK_DETAIL_MODAL_ID = feedbackId => `if-d-m-1--${feedbackId}`;
export const INVESTOR_FFEDBACK_SORT_MODAL_ID = 'if-s-m-2';

//
// Language
//
export const MULTILANGUAGE_PICKER_MODAL_ID = 'm-p-m-1';

//
// General
//
export const DEAL_SWITCHER_MODAL_ID = 'd-s-m-1';
export const CONTINUE_SESSION_MODAL_ID = 'c-s-m-2';
export const TERMS_AND_CONDITIONS_MODAL_ID = 't-a-c-m-2';
export const LOG_ON_SCREEN_MODAL_ID = 'l-o-s-m-1'

//
// SiteText
//
export const TERMS_MODAL_ID = 'terms-m-1';
export const EULA_MODAL_ID = 'eula-m-1';
export const PRIVACY_MODAL_ID = 'privacy-m-1';
export const COOKIES_MODAL_ID = 'cookie-m-1';
export const CONTACT_US_MODAL_ID = 'contact-us-m-1';
export const BIOMETRIC_TERMS_MODAL_ID = 'bioterms-t-m-1';
export const BIOMETRIC_FAQ_MODAL_ID = 'biofaq-f-m-1';
export const BIOMETRIC_ON_DEVICE_MODAL_ID = 'bio-o-d-m-1';

export const ONE_WAY_LETTER = 'one-w-l-1';

//Order Summary Table Row Modal

export const ORDER_SUMMARY_TABLE_ROW_MODAL_ID = "summary-t-r-m-1"
export const BOOK_EVOLUTION_TABLE_ROW_MODAL_ID = "book-evolution-t-r-m-1"