import Table from 'shared/components/Table';
import React from 'react';
import { fromPairs } from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';

import  {
    firstHeaderAlignedLeftStyle,
    firstRowAlignedLeftStyle,
    otherRowAlignedRight
} from 'shared/components/Table';

const customWordBreak = {
  wordWrap: 'break-word',
}

const transformData = (data) => {
  const series = data.series;
  const values = series.filter(item => item.seriesType === "Plot");
  const totals = series.filter(item => item.seriesType === "CategorySummary")[0].data;

  let lastMonth = "";
  let lastDay = "";

  const dates = data.categoryAxis.categoryLabel.reduce((acc, current, index) => {
    const splitDate = current.split("-");

    if (splitDate.length === 2) {
      lastMonth = splitDate[1];
      acc.push(moment(current, 'DD-MMM').format('Do MMM'));
    } else {
      if(splitDate[0] !== "") {
        acc.push(moment(`${current} ${lastMonth}`, 'D-MMM').format('Do MMM'));
      } else {
        acc.push(moment(`${lastDay} ${lastMonth}`, 'D-MMM').format('Do MMM'));
      }
    }

    if(splitDate[0] !== "") {
      lastDay = splitDate[0];
    }

    return acc
  }, []);

  const transformedData = dates.map((date, index) => ({
    'date': date,
    'total': totals[index].value,
    ...fromPairs(values.map(v => [
      [v.seriesTitle], v.data[index].value
    ])),
  }));

  return transformedData;
}

const Columns = (props, data) => {
  const plots = props.series.series.filter(series => series.seriesType === "Plot");

  const columns = [{
    dataField: 'total',
    text: props.cumulativeText,
    columnFlex: 1,
    style: {
      ...otherRowAlignedRight,
      ...customWordBreak,
      textAlign: 'center',
    },
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
      ...customWordBreak,
    }
  }, {
    dataField: 'date',
    text: props.dateOfFeedbackText,
    columnFlex: 1,
    attrs: {
      role: 'rowheader',
    },
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
      ...customWordBreak,
    },
    style: {
      ...firstRowAlignedLeftStyle,
      ...customWordBreak,
    }
  }]

  columns.push(...plots.map(plot => ({
    dataField: plot.seriesTitle,
    text: plot.seriesTitle,
    columnFlex: 1,
    style: {
      ...otherRowAlignedRight,
      ...customWordBreak,
      textAlign: 'center',
    },
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
      ...customWordBreak,
    }
  })));

  return columns;
};

const ThemeDistributionTable = props =>
  <Table
    classes='fixed standard'
    data={transformData(props.series)}
    bordered={false}
    keyField={'date'}
    columns={Columns(props)}
    caption={props.tableCaption}
  />

export default compose(
  withTranslate(translate => ({
    tableCaption: translate('', 'Theme distribution over time'),
    cumulativeText: translate("investor_feedback.distribution_chart_primary_x_axis", "Cumulative Number of Feedback Records"),
    dateOfFeedbackText: translate('investor_feedback.theme_distribution_chart_secodary_x_axis', "Date of Feedback"),
  }))
)(ThemeDistributionTable);
