import React, { PureComponent } from 'react';
import styled from 'styled-components'
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
	buttonKeyHandler,
	btnKeyPress,
} from 'util/onKeyPress';
/**
 * The only true button.
 */
class HsbcButton extends PureComponent {

	throttledEvent = _.throttle(e => {
		if (this.props.disabled) return;

		buttonKeyHandler(e, this.props.onClick);
		btnKeyPress(e, this.props.onClick);
	}, 1000, { trailing: false, leading: true });

	eventToThrottle = e => {
		e.persist()
		e.preventDefault()
		this.throttledEvent(e);
	}

	render() {
		const {
			onClick,
			className,
			size,
			children,
			style,
			disabled,
			...other
		} = this.props;

		let baseStyle = {
			fontSize: HsbcButton.sizes[size],
		};

		if (style) {
			baseStyle = Object.assign(baseStyle,style);
		}

		if (className && className.indexOf('btn-tranche-underline') > -1) {
			baseStyle.marginRight = '0px';
		}

		return (
			<button
				disabled={disabled}
				className={className}
				style={baseStyle}
				onClick={this.eventToThrottle}
				onKeyPress={this.eventToThrottle}
				{...other}
			>
				{children}
			</button>
		);
	}
}

HsbcButton.propTypes = {
	/**
	 * Button label.
	 */
	size: PropTypes.oneOf(['small', 'normal', 'large']),
};
HsbcButton.defaultProps = {
	size: 'normal',
};

HsbcButton.sizes = {
	small: '0.625rem',
	regular : '0.813rem',
	normal: '0.875rem',
	large: '1.125rem',
};

export default styled(HsbcButton)`
	&:focus {
		outline: dotted 1px white;
	}

	&:focus:not(.active) {
		background-color: rgb(62, 80, 93);
	}
`;

export const SecondarySlateButton = styled(HsbcButton)`
  background-color: #4D6474;
  color: #FFF;
	padding: 10px;
  margin-left: 10px;
	outline: none;
	border: none;

  &:hover, &:focus {
    background-color: #3E505D;
  }

  &:active {
    background-color: #253038;
  }

  &:disabled {
      cursor: not-allowed;
			opacity: 0.5;
			background-color: none;
  }

	&.btn-masthead {
		background-color: #415562;
	}
`;

export const PrimaryButton = styled(HsbcButton)`
    background-color: #db0011;
		color: #fff;

    margin-left: 10px;

    &:hover, &:focus {
        background-color: #af000d;
				outline:0;
    }

    &:active {
        background-color: #83000A;
        padding-top: 20px;
        padding-left: 20px;
    }

    &:disabled {
        cursor: not-allowed;
				color: #fff;
				opacity: 0.5;
				background-color: none;
    }
`;

export const SecondaryButton = styled(HsbcButton)`
    background-color: #fff;
		color: #333333;
		border: solid 1px #333333;

    margin-left: 10px;

    &:hover, &:focus {
        background-color: #D9D9D9;
				outline:0;
    }

    &:active {
        background-color: #B6B7B6;
        padding-top: 20px;
        padding-left: 20px;
    }

    &:disabled {
        cursor: not-allowed;
		opacity: 0.5;
		background-color: #D9D9D9;
    }
`;

export const LegacyButton = styled(HsbcButton)`
    background-color: #00847f;
	color: #fff;
    margin-left: 10px;

    &:hover, &:focus {
        background-color: #005e59;
		outline:0;
    }

    &:active {
        background-color: #004f4b;
        padding-top: 20px;
        padding-left: 20px;
    }

    &:disabled {
        cursor: not-allowed;
		color: #fff;
		opacity: 0.5;
		background-color: none;
    }
`;