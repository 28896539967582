import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import dealFeatures from 'constants/dealFeatures';
import { checkFeature } from 'actions/UtilityActions';

import { getDocumentsFetchedStatus } from 'selectors';

import Loading, { FeaturePageLoadingContainer } from 'shared/components/Loading';

class Pricing extends PureComponent {
    componentDidMount() {
        this.props.checkFeature(dealFeatures.PRICING);
    }

    render() {
        if (!this.props.documentsFetched) {
            return (
                <FeaturePageLoadingContainer>
                    <Loading />
                </FeaturePageLoadingContainer>
            );
        }

        return this.props.children;
    }
}



export default connect(
    state => ({
        documentsFetched: getDocumentsFetchedStatus(state),
    }),
    dispatch => ({
        // polling
        checkFeature: feature => dispatch(checkFeature(feature)),
    })
)(Pricing);
