import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import { SvgIcon } from "shared/components/Icon";
import {
  selectAmendedOrderByInvestorDropDown,
  filterBookEvolutionSummaryForAmendedOrderByInvestor,
  requestStages,
  setIsSetStagesModalOpen
} from "actions/OrderBookActions";
import {
  TIME_INTERVALS,
  DEFAULT_ALL,
  TIME_RANGES,
  STATIC_MINUTES,
  DEFAULT_MIN,
  DEFAULT_TIME_INTERVAL,
} from "constants/orders";
import {
  getSelectedInvestorType,
  getSelectedTranche,
  getSelectedInterval,
  getSelectedDate,
  getSelectedTimeRange,
  getIsSetStagesModalOpen
} from "selectors";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, HsbcButton } from "mk-pattern-library-react";
import SpecificTimePopup from "./SpecificTimePopup";
import SetStagesPopup from "./SetStagesPopup";
import { dateFormatter } from "../../SharedTableComponents/formatters";

const styles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const trancheContainerStyles = {
  ...styles,
  flexDirection: "column",
  alignItems: "start",
};

const DropDownContainer = styled.div`
  ${styles}
  justify-content: space-between;
`;

const TranchesDropDownContainer = styled.div`
  ${trancheContainerStyles},
  span {
    margin: 0 0 10px;
  }
`;

const arrowDropdownIconStyle = {
  transform: "rotate(90deg)",
  marginTop: "2px",
  width: "18px",
  height: "18px",
};

const AmendedOrderDropdownFiltersList = ({
  dealId,
  selectedInvestorType,
  selectedTimeRange,
  selectedTranche,
  selectedInterval,
  selectedDate,
  investorTypeText,
  trancheNameText,
  timeIntervalText,
  allTranchelists,
  getDateSummary,
  dateText,
  isAmenededOrderSummary,
  orginalOrderbookData,
  selectAmendedOrderByInvestorDropDown,
  filterBookEvolutionSummaryForAmendedOrderByInvestor,
  isShowStagesChecked,
  setIsShowStagesChecked,
  requestStages,
  isSetStagesModalOpen,
  setIsSetStagesModalOpen
}) => {
  const [investorTypeList, setInvestorTypeList] = useState([]);
  const [datesList, setDatesList] = useState([]);
  const [isFromSpecificTimeModalOpen, setIsFromSpecificTimeModalOpen] =
    useState(false);
  const [hour, setHour] = useState("00");
  const [min, setMin] = useState(DEFAULT_MIN);

  useEffect(() => {
    if (orginalOrderbookData && orginalOrderbookData.length) {
      let datesArray = [];
      const getInvestorTypes = orginalOrderbookData.map((order) => {
        datesArray.push({
          label: dateFormatter("localDate", order.dateEntered),
          value: dateFormatter("localDate", order.dateEntered),
        });
        return {
          label: order.investorType,
          value: order.investorType,
        };
      });

      const uniqueInvestorTypes = getInvestorTypes
        .filter(
          (elem, index) =>
            getInvestorTypes.findIndex((obj) => obj.value === elem.value) ===
            index
        )
        .sort((a, b) => (a.value.trim() < b.value.trim() ? -1 : 1));
      const uniqueDates = datesArray.filter(
        (elem, index) =>
          datesArray.findIndex((obj) => obj.value === elem.value) === index
      );

      uniqueInvestorTypes.unshift(DEFAULT_ALL);
      setInvestorTypeList(uniqueInvestorTypes);
      setDatesList(uniqueDates);
    }
  }, []);

  const onDropdownChange = (
    e,
    trancheName,
    date,
    interval,
    timeRange,
    investorType,
    applyLocalFilter
  ) => {
    if (
      selectedTranche.value !== trancheName.value ||
      selectedInterval.value !== interval.value ||
      selectedDate.value !== date.value ||
      selectedTimeRange.value !== timeRange.value ||
      selectedInvestorType.value !== investorType.value
    ) {
      selectAmendedOrderByInvestorDropDown(
        trancheName,
        date,
        interval,
        timeRange,
        investorType
      );
      if (!applyLocalFilter) {
        filterBookEvolutionSummaryForAmendedOrderByInvestor(
          dealId,
          interval.key,
          0,
          trancheName.key,
          true
        );
      }
    }
  };

  const initialSelectedTranche =
    selectedTranche.value === DEFAULT_ALL.value
      ? allTranchelists[0]
      : selectedTranche;
  const initialSelectedDate =
    selectedDate.value === DEFAULT_ALL.value ? getDateSummary[0] : selectedDate;

  return (
    <div className="ordersummary-dropdown">
      <DropDownContainer>
        <TranchesDropDownContainer
          style={{ position: "relative", top: "23px" }}
        >
          <span>{trancheNameText}:</span>
          <Autocomplete
            disablePortal
            id="order-tranche-name"
            options={allTranchelists}
            disableClearable
            sx={{ width: 196 }}
            popupIcon={
              <SvgIcon
                icon="chevron-right"
                style={arrowDropdownIconStyle}
                aria-hidden
              />
            }
            value={
              initialSelectedTranche.value.length > 17
                ? initialSelectedTranche.value.slice(0, 16) + "..."
                : initialSelectedTranche.value
            }
            onChange={(e, value) => {
              onDropdownChange(
                e,
                value ? value : selectedTranche,
                DEFAULT_ALL,
                selectedInterval,
                selectedTimeRange,
                DEFAULT_ALL,
                false
              );
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
          <Checkbox
            label={"Show Stages"}
            labelStyle={{ fontSize: "14px", fontFamily: "inherit" }}
            onCheckedChange={() => {
              setIsShowStagesChecked(!isShowStagesChecked);
              onDropdownChange(
                undefined,
                selectedTranche,
                selectedDate,
                DEFAULT_TIME_INTERVAL,
                selectedTimeRange,
                selectedInvestorType,
                false
              );
            }}
            checked={isShowStagesChecked}
          />
        </TranchesDropDownContainer>
        <TranchesDropDownContainer
          style={{ position: "relative", top: "23px" }}
        >
          <span>{investorTypeText}:</span>
          <Autocomplete
            disablePortal
            id="order-investor-name"
            options={investorTypeList}
            disableClearable
            sx={{ width: 196 }}
            popupIcon={
              <SvgIcon
                icon="chevron-right"
                style={arrowDropdownIconStyle}
                aria-hidden
              />
            }
            value={selectedInvestorType.value || investorTypeList[0]}
            onChange={(e, value) =>
              onDropdownChange(
                e,
                initialSelectedTranche,
                initialSelectedDate,
                selectedInterval,
                selectedTimeRange,
                value ? value : selectedInvestorType,
                true
              )
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          <HsbcButton
            onClick={() => {
              requestStages(dealId);
              setIsSetStagesModalOpen(true);
            }}
            className="btn-default btn-action btn-action-type text-action btn-margin-top"
            size="normal"
          >
            Set Stages
          </HsbcButton>
        </TranchesDropDownContainer>
        {!isAmenededOrderSummary && (
          <>
            <TranchesDropDownContainer>
              <span>{dateText} :</span>
              <Autocomplete
                disablePortal
                id="order-dates"
                options={getDateSummary}
                disableClearable
                sx={{ width: 196 }}
                popupIcon={
                  <SvgIcon
                    icon="chevron-right"
                    style={arrowDropdownIconStyle}
                    aria-hidden
                  />
                }
                value={initialSelectedDate.value}
                onChange={(e, value) =>
                  onDropdownChange(
                    e,
                    initialSelectedTranche,
                    value ? value : selectedDate,
                    selectedInterval,
                    selectedTimeRange,
                    selectedInvestorType,
                    true
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) => option.value === value}
              />
            </TranchesDropDownContainer>

            <TranchesDropDownContainer>
              <span>{timeIntervalText}:</span>
              <Autocomplete
                disablePortal
                id="order-intervals"
                options={TIME_INTERVALS}
                disableClearable
                disabled={
                  selectedTimeRange.disableTimeInterval || isShowStagesChecked
                }
                sx={{ width: 196 }}
                popupIcon={
                  <SvgIcon
                    icon="chevron-right"
                    style={arrowDropdownIconStyle}
                    aria-hidden
                  />
                }
                value={selectedInterval.value}
                onChange={(e, value) =>
                  onDropdownChange(
                    e,
                    initialSelectedTranche,
                    initialSelectedDate,
                    value ? value : selectedInterval,
                    selectedTimeRange,
                    selectedInvestorType,
                    false
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) => option.value === value}
              />
            </TranchesDropDownContainer>

            <TranchesDropDownContainer>
              <span>{"Time Range (GMT)"}:</span>
              <Autocomplete
                disablePortal
                id="order-intervals"
                options={TIME_RANGES}
                disableClearable
                sx={{ width: 196 }}
                popupIcon={
                  <SvgIcon
                    icon="chevron-right"
                    style={arrowDropdownIconStyle}
                    aria-hidden
                  />
                }
                value={selectedTimeRange.label || TIME_RANGES[0].label}
                onChange={(e, value) => {
                  if (value.value.includes("From")) {
                    setIsFromSpecificTimeModalOpen(true);
                    if (selectedTimeRange.disableTimeInterval) {
                      setHour(
                        selectedTimeRange.value.split(" ")[0].split(":")[0]
                      );
                      let minToSet = STATIC_MINUTES.find(
                        (element) =>
                          element.value ===
                          selectedTimeRange.value.split(" ")[0].split(":")[1]
                      );
                      setMin(minToSet);
                    }
                  } else {
                    setMin(DEFAULT_MIN);
                    setHour("00");
                    onDropdownChange(
                      e,
                      selectedTranche,
                      selectedDate,
                      selectedInterval,
                      value ? value : selectedTimeRange,
                      selectedInvestorType,
                      true
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) => option.value === value}
              />
            </TranchesDropDownContainer>
          </>
        )}
      </DropDownContainer>
      <SpecificTimePopup
        isModalOpen={isFromSpecificTimeModalOpen}
        setIsModalOpen={setIsFromSpecificTimeModalOpen}
        hour={hour}
        min={min}
        setHour={setHour}
        setMin={setMin}
        onDropdownChange={onDropdownChange}
        selectedTranche={selectedTranche}
        selectedInterval={selectedInterval}
        selectedInvestorType={selectedInvestorType}
        selectedDate={selectedDate}
      />
      <SetStagesPopup
        isModalOpen={isSetStagesModalOpen}
        setIsModalOpen={setIsSetStagesModalOpen}
        hour={hour}
        min={min}
        setHour={setHour}
        setMin={setMin}
        allDatesList={datesList}
        onDropdownChange={onDropdownChange}
        selectedTranche={selectedTranche}
        selectedInterval={selectedInterval}
        selectedInvestorType={selectedInvestorType}
        selectedDate={selectedDate}
        dealId={dealId}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedInvestorType: getSelectedInvestorType(state),
  selectedTimeRange: getSelectedTimeRange(state),
  selectedTranche: getSelectedTranche(state),
  selectedInterval: getSelectedInterval(state),
  selectedDate: getSelectedDate(state),
  isSetStagesModalOpen: getIsSetStagesModalOpen(state)
});

const mapDispatchToProps = (dispatch) => ({
  filterBookEvolutionSummaryForAmendedOrderByInvestor: (dealId, interval, investorId, trancheName, isFilter) => dispatch(filterBookEvolutionSummaryForAmendedOrderByInvestor(dealId, interval, investorId, trancheName, isFilter)),
  selectAmendedOrderByInvestorDropDown: (selectedTranche, selectedDate, selectedInterval, selectedTimeRange, selectedInvestorType) => dispatch(selectAmendedOrderByInvestorDropDown(selectedTranche, selectedDate, selectedInterval, selectedTimeRange, selectedInvestorType)),
  requestStages: (dealId) => dispatch(requestStages(dealId)),
  setIsSetStagesModalOpen: (isModalOpen) => dispatch(setIsSetStagesModalOpen(isModalOpen))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AmendedOrderDropdownFiltersList
);
