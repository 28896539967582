import { put, fork, call, select, cancel, takeEvery, takeLatest } from 'redux-saga/effects';

import * as storageKeys from 'constants/storageKeys';
import { getAvailableLanguages, getDeals } from 'selectors';
import Types, { UtilityTypes } from 'actions/Types';
import { selectLanguage } from 'actions/LanguageActions';
import {
  apiFailure,
  dealSetup,
  requestDeals,
  requestNavigationFeatures,
  requestPollingInterval,
  baseFetchCompleted
} from 'actions/UtilityActions';
import {
    setupIsvDeal,
    requestIsvDeals,
    isvBaseFetchCompleted
} from '../../Isv/actions/IsvUtilityActions';
import {
    requestFeedbackSentiments,
    requestFeedbackThemeTypes
} from 'actions/InvestorFeedbackActions';
import { handleBatchedRequests } from 'sagas/Requests';
import { create } from '../../services/api';

import { getIsvDeals } from '../../Isv/selectors';

export default (api) => {
    function* handleRequest(req, endPoint, params, success) {
        try {
            const response = yield call(req, endPoint, params);
            if (response.ok) {
                yield put(success(response.data));
            } else {
                yield put(apiFailure('Bad Response'));
            }
        } catch (error) {
            yield put(apiFailure('Bad call: ' + error));
        }
    }

    function* worker({ unique_name, isISVUser }) {
        if(isISVUser) {
            const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
            //
            // 1. Request for Available Deals
            //
            const requestDealsAction = requestIsvDeals();
            yield handleRequest(api.getData().req, requestDealsAction.endPoint, {}, requestDealsAction.onSuccess)

            //
            // 2. Run first deal setup ( if was set in LocalStorage )
            //
            const dealsList = yield select(getIsvDeals);
            // console.log('dealList', dealsList)
            let dealId;

            let selectedDealId = window.localStorage.getItem(storageKeys.SELECTED_ISV_DEAL);

            if (selectedDealId) {
                selectedDealId = +selectedDealId;

                if (!Number.isNaN(selectedDealId) && dealsList.some(deal => deal.dealId === selectedDealId)) {
                    dealId =  selectedDealId;
                }
            }
            
            // if (!dealId && dealsList.length) {
            //     dealId = dealsList[0].dealId;
            // }

            if (dealId) {
                yield put(setupIsvDeal(dealId, false));
                yield put(isvBaseFetchCompleted(dealId));
            }
            else {
                yield put(isvBaseFetchCompleted());
            }
        } else {
            //
            // 0 Language picking
            //
            const availableLanguages = yield select(getAvailableLanguages);
            const tempLanguage = window.localStorage.getItem(storageKeys.TEMP_SELECTED_LANGUAGE);

            if (tempLanguage && availableLanguages[tempLanguage]) {  // temp was filled and is available
                yield put(selectLanguage(tempLanguage));
                window.localStorage.removeItem(storageKeys.TEMP_SELECTED_LANGUAGE);
            } else {
                const langBrowserPreference = window.navigator.language || // All modern
                    (window.navigator.languages && window.navigator.languages[0]) || // Chrome & FF
                    window.navigator.userLanguage; // IE <= 10

                const savedLanguage = window.localStorage.getItem(storageKeys.SELECTED_LANGUAGE);
                try {
                    if (savedLanguage && availableLanguages[savedLanguage]) {
                        // #1 - select from LocalStorage
                        yield put(selectLanguage(savedLanguage));
                    } else if (langBrowserPreference && availableLanguages[langBrowserPreference]) {
                        // #2 - select lang based on user location
                        yield put(selectLanguage(langBrowserPreference));
                    }

                } catch (e) {
                    window.localStorage.removeItem(storageKeys.SELECTED_LANGUAGE)
                }
            }
            //
            // 0.b  Pick Language - based on user location or from LocalStorage (optional) + make sure that this lang is still available
            //
            //
            // 1. Request for Available Deals
            //
            const requestDealsAction = requestDeals();
            yield handleRequest(api.getData().req, requestDealsAction.endPoint, {}, requestDealsAction.onSuccess)
    
            //
            // 2. Request All Navigation Features
            //
            const requestNavFeaturesAction = requestNavigationFeatures();
            yield handleRequest(api.getData().req, requestNavFeaturesAction.endPoint, {}, requestNavFeaturesAction.onSuccess)
    
            //
            // 3. Run first deal setup ( if was set in LocalStorage )
            //
            const dealsList = yield select(getDeals);
    
            let dealId;
    
            let selectedDealId = window.localStorage.getItem(storageKeys.SELECTED_DEAL);
    
            if (selectedDealId) {
                selectedDealId = +selectedDealId;
    
                if (!Number.isNaN(selectedDealId) && dealsList.some(deal => deal.dealId === selectedDealId)) {
                    dealId =  selectedDealId;
                }
            }
            
    
            if (!dealId && dealsList.length) {
                dealId = dealsList[0].dealId;
            }
    
            if (dealId) {
                yield put(dealSetup(dealId, false));
            } else {
                yield put(baseFetchCompleted());
            }

            const requestPollingIntervalAction = requestPollingInterval();
            yield handleRequest(api.getData().req, requestPollingIntervalAction.endPoint, {}, requestPollingIntervalAction.onSuccess);
    
            // static data
            yield handleBatchedRequests(
                api,
                requestFeedbackSentiments(false),
                requestFeedbackThemeTypes(false)
            );
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.APP_SETUP,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
