import React, { Fragment } from 'react';
import styled from 'styled-components';
// TODO
import { Icon } from 'mk-pattern-library-react';

const StyledIcon = styled(Icon)`
  font-size: 7px;
  padding-left: 10px;
  padding-bottom: 2px;
`;

const InactiveIcon = styled(StyledIcon)`
  opacity: .3;
`;

export default (order, column) =>
  <Fragment>
    <InactiveIcon className="inactive-sort-icon" iconName="CaretDown" />
    <StyledIcon className="ascending-sort-icon" iconName="CaretUp" />
    <StyledIcon className="descending-sort-icon" iconName="CaretDown" />
  </Fragment>
