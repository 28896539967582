import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { compose } from "recompose";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { Icon } from "mk-pattern-library-react";
import Table from "../TableContainer/Table";
import TableHead from "../TableContainer/TableHead";
import TableBody from "../TableContainer/TableBody";
import TableRow from "../TableContainer/TableRow";
import TableHeaderCell from "../TableContainer/TableHeaderCell";
import TableColumnCell from "../TableContainer/TableColumnCell";
import Popup from "shared/components/Popup";
import PopupSystem from "shared/components/PopupSystem";
import { typography } from "shared/styles/variables/theme";
import { SvgIcon } from "shared/components/Icon";
import flexbox from "shared/styles/mixins/flexbox";
import styled from "styled-components";
import { updateStages } from "actions/OrderBookActions";
import { getStages } from "selectors";
import Autocomplete from "@mui/material/Autocomplete";
import { dateFormatter } from "../../SharedTableComponents/formatters";

const IconContainer = styled.div`
  ${flexbox("row", "center", "flex-start")}
  cursor: pointer;
`;

const closeIconStyle = {
  right: "15px",
  top: "15px",
  cursor: "pointer",
};

const trancheContainerStyles = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "start",
};

const arrowDropdownIconStyle = {
  transform: "rotate(90deg)",
  marginTop: "2px",
  width: "18px",
  height: "18px",
};
const TranchesDropDownContainer = styled.div`
  ${trancheContainerStyles},
`;
const useStateWithCallback = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const setValueAndCallback = (newValue, callback) => {
    setValue((prevValue) => {
      if (callback) {
        callback(prevValue, newValue);
      }
      return newValue;
    });
  };

  return [value, setValueAndCallback];
};
function SetStagesPopup({
  isModalOpen,
  setIsModalOpen,
  updateStages,
  stages,
  dealId,
  allDatesList,
}) {
  const [arrayOfStages, setArrayOfStages] = useStateWithCallback([]);
  const [dataNotLoaded, setDataNotLoaded] = useState(true);

  useEffect(() => {
    setArrayOfStages([...stages], () => {
      if (isModalOpen) setDataNotLoaded(false);
    });
  }, [stages]);

  const checkIfDateSelected = (index) => {
    if (arrayOfStages[index].stageDate) {
      return true;
    }
    return false;
  };

  const isValidHour = (hourValueString) => {
    const hourValue = hourValueString
      ? parseInt(hourValueString)
      : hourValueString;
    return hourValue === "" || (hourValue < 24 && hourValue >= 0);
  };

  const isStagesDataChanged = (initialStageData, newStageData) => {
    return !isEqual(initialStageData, newStageData);
  };
  const isValidMinute = (minValueString) => {
    const minValue = minValueString ? parseInt(minValueString) : minValueString;
    return minValue === "" || (minValue < 60 && minValue >= 0);
  };

  const handleChange = (event, index, clearTimeValue, dateDropdownvalue) => {
    const tempArrayOfStages = [...arrayOfStages];
    let objectToSet = { ...tempArrayOfStages[index] };
    let stageTimeToSet = objectToSet.stageTime;
    let stageDateToSet = objectToSet.stageDate;
    if (clearTimeValue) {
      objectToSet = { ...objectToSet, stageTime: "", stageDate: null };
    } else {
      let value =
        !event.target.value || event.target.value === "0"
          ? "00"
          : parseInt(event.target.value) !== 0 &&
            parseInt(event.target.value) < 10
          ? `0${event.target.value}`
          : parseInt(event.target.value);
      value = parseInt(value) > 9 ? parseInt(value) : `0${parseInt(value)}`;
      if (event.target.name === "hour") {
        if (!stageTimeToSet.length) {
          stageTimeToSet = `${value}:00`;
        } else {
          let hh = value;
          let mm = stageTimeToSet.split(":")[1];
          stageTimeToSet = `${hh}:${mm}`;
        }
      } else if (event.target.name === "min") {
        if (!stageTimeToSet.length) {
          stageTimeToSet = `00:${value}`;
        } else {
          let hh = stageTimeToSet.split(":")[0];
          let mm = value;
          stageTimeToSet = `${hh}:${mm}`;
        }
      } else {
        stageTimeToSet = stageTimeToSet ? stageTimeToSet : `00:00`;
        stageDateToSet = dateDropdownvalue;
      }
      objectToSet = {
        ...objectToSet,
        stageTime: stageTimeToSet,
        stageDate: stageDateToSet,
      };
    }
    tempArrayOfStages[index] = { ...objectToSet };
    let hour = stageTimeToSet ? stageTimeToSet.split(":")[0] : stageTimeToSet;
    let min = stageTimeToSet ? stageTimeToSet.split(":")[1] : stageTimeToSet;
    if (isValidMinute(min) && isValidHour(hour)) {
      setArrayOfStages([...tempArrayOfStages]);
    }
  };

  const disabledStyle = () => {
    if (!isStagesDataChanged(stages, arrayOfStages)) {
      return {
        pointerEvents: "none",
        color: "#ccc",
      };
    } else {
      return {};
    }
  };

  return (
    <div>
      <PopupSystem
        modalID={"1"}
        isModalOpen={isModalOpen}
        centered
        backgroundLayer
        zIndex={3001}
        closeModal={() => {
          setIsModalOpen(false);
          setDataNotLoaded(true);
        }}
        clickableElement={null}
        content={
          <Popup
            static
            dividerStyle={{
              margin: "15px 0px 15px",
              borderBottom: "2px solid #585858",
            }}
            style={{ padding: "20px" }}
            secondaryButton
            secondaryButtonStyle={{
              fontSize: typography.sizes["16px"],
              padding: "14px 20px",
              ...disabledStyle(),
            }}
            secondaryButtonAction={() => {
              updateStages(dealId, arrayOfStages);
              setIsModalOpen(false);
              setDataNotLoaded(true);
            }}
            secondaryButtonKey="orders.save"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                if (isStagesDataChanged(stages, arrayOfStages)) {
                  if (
                    window.confirm(
                      "Changes not saved . Are you sure you want to discard changes ?"
                    )
                  ) {
                    setArrayOfStages([...stages]);
                    setIsModalOpen(false);
                    setDataNotLoaded(true);
                  }
                } else {
                  setIsModalOpen(false);
                }
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Icon iconName="closeX" style={closeIconStyle} />
            </IconButton>
            <h2 style={{ marginTop: "10px" }}>Set Stages</h2>

            <div className="react-bootstrap-table table-fix-head-stages">
              <Table
                className={`table table-bordered`}
                id={"amended-order-table"}
              >
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Stages</TableHeaderCell>

                    <TableHeaderCell>Date</TableHeaderCell>
                    <TableHeaderCell>
                      Event Time ( hh : mm ) ( GMT )
                    </TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrayOfStages.map((stage, index) => {
                    let hour = stage.stageTime
                      ? stage.stageTime.split(":")[0]
                      : "";
                    let min = stage.stageTime
                      ? stage.stageTime.split(":")[1]
                      : "";
                    return (
                      <TableRow key={index}>
                        <TableColumnCell>{`${stage.stageName} (${stage.stageShortname})`}</TableColumnCell>
                        <TableColumnCell>
                          <TranchesDropDownContainer>
                            <Autocomplete
                              disablePortal
                              disableClearable
                              id="order-dates"
                              options={allDatesList}
                              sx={{ width: 196 }}
                              popupIcon={
                                <SvgIcon
                                  icon="chevron-right"
                                  style={arrowDropdownIconStyle}
                                  aria-hidden
                                />
                              }
                              value={
                                stage.stageDate
                                  ? dateFormatter("localDate", stage.stageDate)
                                  : stage.stageDate
                              }
                              onChange={(e, value) =>
                                handleChange(e, index, false, value.value)
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value
                              }
                            />
                          </TranchesDropDownContainer>
                        </TableColumnCell>
                        <TableColumnCell>
                          <TextField
                            size="small"
                            helperText={
                              !isValidHour(hour) ? "Invalid hour" : ""
                            }
                            name="hour"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 23 } }}
                            value={hour}
                            error={!isValidHour(hour)}
                            onChange={(e) => handleChange(e, index)}
                            disabled={
                              dataNotLoaded || !checkIfDateSelected(index)
                            }
                          />
                          <TextField
                            size="small"
                            helperText={
                              !isValidMinute(min) ? "Invalid minute" : ""
                            }
                            name="min"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 59 } }}
                            value={min}
                            error={!isValidMinute(min)}
                            onChange={(e) => handleChange(e, index)}
                            disabled={
                              dataNotLoaded || !checkIfDateSelected(index)
                            }
                          />
                        </TableColumnCell>
                        <TableColumnCell>
                          {stage.stageTime &&
                            stage.stageTime.length &&
                            !dataNotLoaded && (
                              <IconContainer
                                onClick={(e) => {
                                  handleChange(e, index, true);
                                }}
                              >
                                <SvgIcon
                                  size="xlarge"
                                  icon="circle-delete"
                                  aria-hidden
                                />
                              </IconContainer>
                            )}
                        </TableColumnCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Popup>
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  stages: getStages(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateStages: (dealId, data) => dispatch(updateStages(dealId, data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SetStagesPopup
);
