import React, { Component , Fragment } from 'react';
import styled from 'styled-components';
import TranslationText from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import { ButtonGroup } from 'react-bootstrap';
import { HsbcButton } from 'mk-pattern-library-react';
import Table from 'shared/components/Table';

const ButtonContainer = styled.div`
  position: absolute;
  right: ${props => props.right || '0px'};
  top: ${props => props.top || '0px'};
  z-index: 1000;
`;

const legendKeyStyle = {
  fontSize: 14,
  width: '300px',
  fontWeight: 600,
  paddingBottom: '10px'
};

class ChartTableContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'CHART',
    };
  }

  showChart = () => {
    this.setState({
      view: 'CHART',
    })
  }

  showTable = () => {
    this.setState({
      view: 'TABLE',
    })
  }

  render() {
    return (
      <Fragment>
        <ButtonContainer
          {...this.props.buttonStyle}
        >
          <ButtonGroup>
              <HsbcButton
                  id='btn-view-chart'
                  aria-pressed={this.state.view === "CHART"}
                  className={"btn-segment" + (this.state.view === "CHART" ? " active" : "")}
                  onClick={this.showChart}
              >
                  <TranslationText
                      dictionaryKey={"orders.view_chart"}
                      defaultText="View Chart"
                  />
              </HsbcButton>
              <HsbcButton
                  id='btn-view-table'
                  aria-pressed={this.state.view === "TABLE"}
                  className={"btn-segment" + (this.state.view === "TABLE" ? " active" : "")}
                  onClick={this.showTable}
              >
                  <TranslationText
                      dictionaryKey={"orders.view_table"}
                      defaultText="View Table"
                  />
              </HsbcButton>
          </ButtonGroup>
        </ButtonContainer>
        <div style={ this.props.tableStyle }>
        {
          this.state.view === 'TABLE' && this.props.tableTitle ? (
            <h3 style={ legendKeyStyle }>
              {this.props.tableTitle}
            </h3>
          ) : null
        }
        {
          this.state.view === 'CHART' ? (
            this.props.children
          ) : this.props.table || (
              <Table
                data={this.props.tableData}
                bordered={false}
                keyField={this.props.keyField}
                columns={this.props.columns}
                caption={this.props.caption}
              />
          )
        }
        </div>
      </Fragment>
    );
  }
}

export default withLanguage(ChartTableContainer);
