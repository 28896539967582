/* eslint-disable */
import React from 'react';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Text } from 'recharts';
import ColorChip from '../ColorChip/ColorChip';
import PropTypes from 'prop-types';
import { NiceScale } from '../../utils/niceScale';
import * as ValueFormatters from '../../utils/valueFormatters';
import { colors, extendedColors } from '../../utils/visualisationColors';

const selectPalette = extended => extended ? extendedColors : colors;

const DefaultBar = (props) => {
    const { fill, x, y, width, height } = props;

    return (
      <g>
        <path d={`M${x},${y} h ${width} v ${height} h -${width} Z`} x={x} y={y} width={width} height={height} fill={fill} />;
        <path d={`M${x},${y} L${x + width},${y} Z`} stroke="#fff" strokeWidth="2px" />
      </g>
    );
};

const BackBar = (props) => {
    const { fill, x, y, width, height } = props;

    return (
      <g>
        <path d={`M${x},${y} h ${width * 2} v ${height} h -${width * 2} Z`} stroke="none" fill={fill} opacity=".5" />;
        <path d={`M${x},${y} L${x + width * 2},${y} Z`} stroke="#fff" strokeWidth="2px" />
      </g>
    );

  //  return <path d={getPath(x, y, width, height, 0, width * 2)} stroke="none" fill={fill} opacity=".5" />;
};

const FrontBar = (props) => {
    const { fill, x, y, width, height } = props;

    return (
      <g>
        <path d={`M${x},${y} h ${width / 2} v ${height} h -${width / 2} Z`} stroke="none" fill={fill} opacity=".5" />;
        <path d={`M${x},${y} L${x + width},${y} Z`} stroke="#fff" strokeWidth="2px" />
      </g>
    );

  //    return <path d={getPath(x, y, width, height, width / 2, -width / 2)} stroke="none" fill={fill} />;
};

FrontBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

const renderLegend = (props) => {
    const { payload, title, hasTarget, colorPalette } = props;
    return (
      <div style={{ textAlign: "left" }}>
        {title && (<span style={{ paddingLeft: '10px', display: 'inline-block', fontSize: 14, width: '125px', float: 'left', fontWeight: 600, paddingBottom: '10px' }}>{title}</span>)}
        <ul className="recharts-default-legend" style={{ paddingLeft: '0', overflow: 'hidden' }} >
          {
            [...payload].reverse().map((entry, index) => {
              return (
                <ColorChip fullChip className='recharts-legend-item' key={entry.value} colorHex={colorPalette(index, entry.value)} description={entry.value} opacity={hasTarget ? (index === 0 ? .5 : 1.0) : 1.0} style={{ margin: "0px 20px 5px 0px", display: "inline-block" }} />
              )
            })
          }
        </ul>
      </div>
    );
}

const topBarLegend = (index, height, marginTop, engagementModule, props) => {
    const { x, payload, width } = props;

    if (index === 0) {
        const xCoord = x - (width / 2);

        let iconFont = payload.name === 'plane' ? 'Glyphter' : 'icomoon'


        return (
            <g>

                {payload.topText && (<Text fontWeight={600} fontSize="12px" textAnchor="left" verticalAnchor="end" x={xCoord} dy={50 + marginTop}>{payload.topText}</Text>)}
                {/* Bottom Text  Maybe a tracker object with last Month in it?*/}

                {/*engagementModule && (<image className={`engagement-chart-icon-${payload.name}`} align="center" href={icons[payload.name]} src={icons[payload.name]} x={xCoord + (width / 2) - 8} y={height - 27} width="18px" height="16px"/>) */}

                {(engagementModule && payload.name === 'desktop') && (<Text fill="#4D6474" fontSize="16px" fontFamily={iconFont} align="center" x={xCoord + (width / 2) - 8} y={height - 12}  height="16px">
                    &#xe90a;
                </Text>)}

                {(engagementModule && payload.name === 'chatbubble') && (<Text fill="#4D6474" fontSize="16px" fontFamily={iconFont} align="center" x={xCoord + (width / 2) - 8} y={height - 12}  height="16px">
                    &#xe906;
                </Text>)}

                {(engagementModule && payload.name === 'notebook') && (<Text fill="#4D6474" fontSize="16px" fontFamily={iconFont} align="center" x={xCoord + (width / 2) - 8} y={height - 12}  height="16px">
                    &#xe90e;
                </Text>)}

                {(engagementModule && payload.name === 'plane') && (<Text fill="#4D6474" fontSize="16px" fontFamily={iconFont} align="center" x={xCoord + (width / 2) - 8} y={height - 12}  height="16px">
                    &#x0041;
                </Text>)}


                {!engagementModule && (<Text fontWeight={600} fontSize="12px" textAnchor="left" verticalAnchor="end" x={xCoord} y={height - 38}>{payload.name}</Text>)}
                {payload.bottomText && (<Text fontWeight={600} fontSize="12px" textAnchor="left" verticalAnchor="end" x={xCoord} y={height - 23}>{payload.bottomText}</Text>)}


            </g>
        )
    }
}
const renderLabel = (props) => {
    const { x, y, width, height, legendTitle } = props;
    const fullWidth = width + x;
    return (
        <g>
            <rect x={10} transform={`translate(0, ${y})`} width={fullWidth - 10} height={1} fill="#D9D9D9" />
            <Text fontWeight={600} fontSize="12px" textAnchor="middle" verticalAnchor="end" width={width} x={x + width / 2} y={y + 45} className="customized-x-axis-label">{legendTitle}</Text>
        </g>
    );
}

const customYTick = (props) => {
    const { payload } = props;
    return (
        <Text  {...props} dx={-15}>{payload.value + '%'}</Text>
    )
}

const customXTick = (props) => {
    const { payload, fill, ...other } = props;
    return (
        <Text fill="#000" {...other}>{payload.value}</Text>
    )
}

const adjustYAxisOnMaxData = (data, baseAxisWidth = 60, fontSize = 14) => {
    let axisWidth = baseAxisWidth;
    let arrayOfValues = [];
    data.map((set) => {
        const setVals = Object.keys(set).map((k) => parseInt(set[k]))
            .filter(i => (typeof i == "number" && i));
        arrayOfValues.push(...setVals);
    });

    const maxLength = Math.max(...arrayOfValues).toString().length;

    if (maxLength > 6) {
        axisWidth = (maxLength - 6) * fontSize * (1.2) + baseAxisWidth;
    }

    return axisWidth
}

const StackedBar = (props) => {
    const { data, keys, title, barGap, barSize, legendHeight, legendTitle, xLegend, hasTarget, animate, wideColorPalette } = props;
    const colorPalette = wideColorPalette ? extendedColors : colors;
    const fontSize = 14;
    const yAxixWidth = adjustYAxisOnMaxData(data, 60, fontSize);
    const animationDuration = 650;
    let barStyle = <DefaultBar />;
    if (hasTarget) {
        barStyle = <FrontBar />;
    }

    let niceScale = {};
    let maxVal = 0;
    if (!props.feedbackChart) {
        let reducedData = data.map((dataObj) => {
            let total = 0;
            for (let key in dataObj) {
                if (key !== 'name' && key !== 'target') {
                    total += dataObj[key]
                }
            }
            return total
        })

        reducedData.sort((a, b) => {
            if (a > b) {
                return -1
            }
            else if (a < b) {
                return 1
            }
            else {
                return 0
            }
        })

        maxVal = reducedData[0]; // MAX VAL
        niceScale = (new NiceScale(0, maxVal, props.maxYTicks).niceScale());
    }

    const tickFormatter = val => {
        if (props.feedbackChart) {
            return `${val}%`;
        }

        if (props.tickFormatter) {
            return props.tickFormatter(val);
        }

        return ValueFormatters.commaFormatter(val)
    }

    const bars = (keys) => {
        
        return (
            keys.map((key, i) => {
                const index = keys.length - 1 - i;
                // if the key does not have an assigned color, pick one from the color array
                let barAnimate = animationDuration / keys.length;
                let barAnimateDelay = animationDuration - ((i + 1) * barAnimate)
                return (
                    props.feedbackChart ? (
                        <Bar
                            animationEasing="linear"
                            animationBegin={barAnimateDelay}
                            animationDuration={barAnimate}
                            key={key.key}
                            maxBarSize={props.engagementModule ? 27 : 200}
                            label={topBarLegend.bind(this, index, props.height, +props.marginTop || 0, props.engagementModule)}
                            dataKey={key.key}
                            name={key.value}
                            stackId="a"
                            shape={barStyle}
                            fill={colorPalette(index, key.key)}
                            isAnimationActive={animate}
                        />
                    ) : (
                        <Bar
                            animationEasing="linear"
                            animationBegin={barAnimateDelay}
                            animationDuration={barAnimate}
                            key={key.key}
                            dataKey={key.key}
                            name={key.value}
                            stackId="a"
                            shape={barStyle}
                            fill={colorPalette(index, key.key)}
                            isAnimationActive={animate}
                        />
                    )
                )
            })
        );
    };

    return (
      <ResponsiveContainer width="100%" height={+props.height}>
        <ComposedChart
          data={data}
          margin={{ top: +props.marginTop || 20, right: 10, bottom: 0, left: 10 }}
          width={100} height={+props.height}
          barGap={barGap}
          barSize={barSize}
        >
                {/* tickFormatter={()=> "TOCK"} */}
                {xLegend
                    ? (<XAxis
                        orientation = "bottom"
                        legendTitle = {legendTitle}
                        dy          = {5}
                        fontWeight  = {600}
                        fontSize    = "12"
                        height      = {props.engagementModule ? 40 : 55}
                        dataKey     = "name"
                        axisLine    = {false}
                        tickLine    = {false}
                        label       = {renderLabel.bind(this)}
                        tick        = {props.feedbackChart ? false : customXTick}
                    />)
                    : false}

                <YAxis
                    tickLine      = {false}
                    fontSize      = {fontSize}
                    fontWeight    = {600}
                    stroke        = "#000000"
                    axisLine      = {false}
                    domain        = {props.feedbackChart ? [0, 100] : [niceScale.niceMinimum, niceScale.niceMaximum]}
                    tickFormatter = {tickFormatter}
                    width         = {yAxixWidth}
                    interval      = {0}
                    tick          = {props.feedbackChart ? customYTick : true}
                    ticks         = {props.feedbackChart ? [0, 50, 100] : niceScale.ticks}
                />

                {!props.engagementModule && (<Legend colorPalette={colorPalette} title={title} align="left" verticalAlign="top" height={+legendHeight} content={renderLegend.bind(this)} wrapperStyle={{ top: '0px' }} hasTarget={hasTarget} />)}
                <CartesianGrid vertical={false} />
                {hasTarget &&
                    <Bar dataKey="target" name="Target" stackId="b" shape={<BackBar />} fill={colors[0]} />
                }
                {bars(keys)}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default StackedBar;

StackedBar.defaultProps = {
    animate: false,
    title: "",
    hasTarget: false,
    units: " (m)",
    xLegend: false,
    height: 400,
    primary: true,
    barGap: 0,
    legendHeight: 40,
    keys: [
        { key: 'da', value: 'A' },
        { key: 'db', value: 'B' },
        { key: 'dc', value: 'C' },
        { key: 'dd', value: 'D' },
        { key: 'de', value: 'E' },
        { key: 'df', value: 'F' },
        { key: 'dg', value: 'G' },
        { key: 'dh', value: 'H' },
    ],
};
