import React from 'react';
// TODO
import { Icon } from 'mk-pattern-library-react';

export const debounce = (func, wait, immediate) => {
    var timeout;
    return () => {
        /* eslint-disable-next-line */
        const context = this, args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const roadShowEventTypes = new Map();
roadShowEventTypes.set(0, {
    title            : () => ``,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 0,
});
roadShowEventTypes.set(1, {
    title            : (host) => `1:1 Call ${(host) ? `(${host})` : ``}`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 1,
});
roadShowEventTypes.set(2, {
    title            : (host) => `1:1 Conference Call ${(host) ? `(${host})` : ``}`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 2,
});
roadShowEventTypes.set(3, {
    title            : (host, num) => `${num || 'X'}:1 ${(host) ? `(${host})` : ``}`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 3,
});
roadShowEventTypes.set(4, {
    title            : () => `Group Meeting`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 4,
});
roadShowEventTypes.set(5, {
    title            : () => `Conference Call`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 5,
});
roadShowEventTypes.set(6, {
    title            : () => `Private Meeting`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 6,
});
roadShowEventTypes.set(7, {
    title            : () => `Placeholder`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 7,
});
roadShowEventTypes.set(8, {
    title            : () => `Flight`,
    icon             : <Icon iconName="Plane" style={{verticalAlign : 'baseline', color:'#999999', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color : 'black', fontSize:20 }} iconName="Plane"  />,
    participantsTitle: 'Passengers',
    eventTypeId      : 8,
});
roadShowEventTypes.set(9, {
    title            : () => `Flight`,
    icon             : <Icon iconName="Plane" style={{verticalAlign : 'baseline', color:'#999999', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color : 'black', fontSize:20 }} iconName="Plane"  />,
    participantsTitle: 'Passengers',
    eventTypeId      : 9,
});
roadShowEventTypes.set(10, {
    title            : () => `Train`,
    icon             : <Icon iconName="Train" style={{verticalAlign : 'baseline', color:'#999999', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color : 'black', fontSize:20 }} iconName="Train"  />,
    participantsTitle: 'Passengers',
    eventTypeId      : 10
});
roadShowEventTypes.set(11, {
    title            : () => `Car`,
    icon             : <Icon iconName="Car" style={{verticalAlign : 'baseline', color:'#999999', fontSize:12}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color : 'black', fontSize:14, position:'relative', top:5 }} iconName="Car"  />,
    participantsTitle: 'Passengers',
    eventTypeId      : 11
});
roadShowEventTypes.set(12, {
    title            : () => `Hotel`,
    icon             : <Icon iconName="Hotel" style={{verticalAlign : 'baseline', color:'#999999', fontSize:16}}/>,
    summaryIcon      : <Icon style={{ width: '100%', color : 'black', fontSize:20 }} iconName="Hotel"  />,
    participantsTitle: 'Team',
    eventTypeId      : 12
});
roadShowEventTypes.set(17, {
    title            : () => `Investor Call`,
    participantsTitle: 'Team Attendees',
    eventTypeId      : 17,
});