import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import withTranslate from 'shared/hoc/withTranslate';

import { buttonKeyHandler } from 'util/onKeyPress';

import {
    FloatedRightSpan,
    ListItemContainer,
    listGroupItemInlineStyles
} from './CategoryListItem.styles.js';

class AllCategoryListItem extends PureComponent {
    itemClicked = () => {
        this.props.handleClick();
    }

    itemPressed = e => {
        buttonKeyHandler(e, this.props.handleClick);
    }

    render() {
        return (
            <ListGroupItem
                tabIndex={0}
                aria-selected={this.props.isActive}
                aria-label={this.props.listItemAriaLabel}
                onClick={this.itemClicked}
                onKeyDown={this.itemPressed}
                style={listGroupItemInlineStyles}
                className={`day-view-cell ` + (this.props.isActive ? 'active' : '')}
            >
                <ListItemContainer>
                    <span className="text-univ-lt text-large">
                        {this.props.allDocumentsText}
                    </span>
                    <FloatedRightSpan className="text-univ-bd text-medium">
                        { this.props.total }
                    </FloatedRightSpan>
                </ListItemContainer>
            </ListGroupItem>
        );
    }
}

export default withTranslate((translate, props) => ({
    allDocumentsText: translate(
        "my_docs.all_documents",
        "All Documents",
    ),
    listItemAriaLabel: translate(
        'my_docs.display_documents_from_category_<category_name>',
        `Display documents from category: all documents`,
        [
            ['<category_name>', 'all documents']
        ]
    ),
}))(AllCategoryListItem);