import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Link, browserHistory } from 'react-router';

import { featurePageIds } from 'constants/featurePages';

import { requestDoc } from 'actions/MyDocsActions';
import { getHomeTileStatus, getLastDocuments } from 'selectors';

import { buttonEventHandling, anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';
import { dateFormatters } from 'shared/functions/formatters';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import { ImgIcon } from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';

import { typography, components } from 'shared/styles/variables/theme';

import {
    TileContainerOuter,
    TileHeader,
    TileContent,
    TileContentInner,
    DateContent,
    ChevronIcon,
    EmptyContent
} from './styles.js';

import { ellipsis } from 'shared/styles/mixins/ellipsis';

const DocumentContainer = styled.div`
    margin-bottom: 13px;
`;

const DocumentTitle = styled.span`
    width: calc(100% - 26px);
    ${components.link['--default']}
    display: inline-block;

    font-family: ${typography.family.bold};
    font-size: ${typography.sizes['12px']};

    cursor: pointer;

    &:hover {
        ${components.link['--state']['--hover']}
    }

    &:focus {
        ${components.link['--state']['--focus']}
    }
`;

const DocumentTextContainer = styled.div`
    display: inline-block;
    width: calc(100% - 26px);
`;

const documentIconStyle = {
    height: '21px',
    width: '16px',
    position: 'relative',
    display: "inline-block",
    float: "left",
    color: '#4D6474',
    fontSize: typography.sizes['18px'],
    marginRight: 10,
    marginTop: 5,
};

const url = {
    pathname: featurePageIds.MY_DOCS_DOCUMENTS_PAGE,
};

class LatestDocuments extends PureComponent {
    navigateEvent = (e) => {
        e.preventDefault();
        if (anchorEventHandling(e)) {
            browserHistory.push(url);
            this.props.linkChange();
        }
    }

    documentTitleEvent = (e, ...details) => {
        if (buttonEventHandling(e)) {
            this.props.requestDoc(...details);
        }
    }

    render() {
        return (
            <TileContainerOuter>
                {
                    this.props.isFetching ? (
                        <Loading
                            text={this.props.loadingText}
                            size="small"
                        />
                    ) : (
                        <React.Fragment>
                            <TileHeader>
                                <Link
                                    id='link-d-lts-docs'
                                    to={url}
                                    onKeyPress={this.navigateEvent}
                                    onClick={this.navigateEvent}
                                    aria-label={this.props.linkAriaLabel}
                                >
                                    {this.props.linkText}
                                    <ChevronIcon />
                                </Link>
                            </TileHeader>
                            <TileContent>
                                <TileContentInner>
                                    {
                                        this.props.documents.length ? (
                                            <React.Fragment>
                                                {
                                                    this.props.documents.map(doc => (
                                                        <DocumentContainer>
                                                            <ImgIcon
                                                                style={documentIconStyle}
                                                                icon="DocumentDownload"
                                                                aria-hidden
                                                            />
                                                            <DocumentTextContainer>
                                                                <DocumentTitle
                                                                    id={`doc-${doc.documentVersionId}`}
                                                                    tabIndex={0}
                                                                    role="button"
                                                                    title={doc.title || ''}
                                                                    aria-label={this.props.documentAriaLabel(doc.title)}
                                                                    onClick={e => this.documentTitleEvent(e, doc.documentVersionId, doc.title)}
                                                                    onKeyDown={e => this.documentTitleEvent(e, doc.documentVersionId, doc.title)}
                                                                >
                                                                    <ResponsiveEllipsis
                                                                        style={ellipsis}
                                                                        maxLine={1}
                                                                        text={doc.title || ''}
                                                                    />
                                                                </DocumentTitle>
                                                                <DateContent>
                                                                    {
                                                                        dateFormatters.dayMonthYearWithProvidedZone(
                                                                            doc.lastModified || doc.created,
                                                                            doc.lastModifiedTimeZone || doc.createDateTimeZone
                                                                        )
                                                                    }
                                                                </DateContent>
                                                            </DocumentTextContainer>
                                                        </DocumentContainer>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ) : (
                                            <EmptyContent>
                                                {this.props.notAvailable}
                                            </EmptyContent>
                                        )
                                    }
                                </TileContentInner>
                            </TileContent>
                        </React.Fragment>
                    )
                }
            </TileContainerOuter>
        );
    }
}

export default compose(
    connect(
        state => ({
            isFetching: !getHomeTileStatus(state, 'homeFetchedDocuments'),
            documents: getLastDocuments(state)
                .slice(0, 3),
        }),
        dispatch => ({
            requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName))
        })
    ),
    withTranslate(
        translate => ({
            // link
            linkText: translate('home_page.documents', 'Documents'),
            linkAriaLabel: translate('home_page.navigate_to_documents', 'Navigate to documents'),
            // document
            documentAriaLabel: doc => translate('home_page.open_document', "Open document") + ' ' + doc.title,
            // content
            notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
            loadingText: translate('home_page.loading_documents', 'Loading documents'),
        })
    ),
    withAnalytics2(
        (ev, events) => ({
            linkChange: () => ev(events.LATEST_DOCUMENTS),
        })
    )
)(LatestDocuments);