import React from 'react'

const TableHeaderCell = (props) => {
    return (
        <th {...props}>
            {props.children}
        </th>
    )
}

export default TableHeaderCell;