import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { AppContainer } from 'mk-pattern-library-react';
import { IsvAppContainer } from 'mk-pattern-library-react';
import DealSwitcher from './DealSwitcher/DealSwitcher';
import IsvDealSwitcher from '../Isv/components/IsvDealSwitcher/IsvDealSwitcher'
import SessionTimer from './SessionTimer';
import InternalLogin from './InternalLogin';
import ExternalLogin from './ExternalLogin';

import ExternalTotpLogin from './ExternalTotpLogin';
import ExternalPnLogin from './ExternalPnLogin';

import TermsAndConditionsModal from './TermsAndConditionsModal';

import { getCurrentDealInfo, getAllowedNavigationFeatures, getSelectedLanguage, isBaseFetchCompleted, isAnyModalOpened, isISVUser } from '../selectors';

import { logOff } from 'actions/HeartBeatActions';
import { detectUserInteraction, dealSetup } from 'actions/UtilityActions';
import { initialize, } from 'actions/LoginActions';

import { setupIsvDeal, isvSwitchDeal, requestIsvDealClick } from '../Isv/actions/IsvUtilityActions';

import Loading from 'shared/components/Loading';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import * as storageKeys from 'constants/storageKeys';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';
import OneWayLetter from 'components/OneWayLetter'
import * as modalIds from "constants/modalIds";
import { AUTH_TYPES } from 'constants/laasConfig';
import { getIsvCurrentDeal, isIsvExternalUser, isIsvBaseFetchCompleted } from 'Isv/selectors';

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

let DevTools;

if (process.env.NODE_ENV === 'development') {
  DevTools = require('../DevTools').default; // eslint-disable-line global-require
}

class App extends Component {
  constructor(props) {
      super(props);
      this.dealSwitchClick = this.dealSwitchClick.bind(this);
      this.logoutMethod = this.logoutMethod.bind(this);
  }
  
  componentDidMount() {
    const token = window.sessionStorage.getItem(storageKeys.TOKEN);
    const expiration = window.sessionStorage.getItem(storageKeys.EXPIRATION);
    this.props.initialize(token, expiration, storageKeys.IS_INTERNAL);
  }

  dealSwitchClick(dealId) {
      !this.props.isISVUser ? this.props.setupDeal(dealId) : this.props.setupIsvDeal(dealId);
      // this.props.isISVUser && this.props.requestIsvDealClick(dealId)
      if(this.props.isISVUser){
        setTimeout(()=>{
          this.props.isvSwitchDeal(dealId)
        },500)
      }
  }

  logoutMethod() {
      this.props.logOff();
      this.props.logoutEvent();
  }

  attemptSSO = () => {
    this.props.initialize(null, null, storageKeys.IS_INTERNAL);
  }

  render() {
    if(this.props.login.showTermsAndConditions) {
       return <TermsAndConditionsModal />;
    }
    if(!this.props.login.isAuthenticated) {
      if(this.props.initializing) {
        return (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )
      }
      if (storageKeys.IS_INTERNAL) {
        return <InternalLogin attemptSSO={this.attemptSSO} />
      } else if ( (this.props.login.error) || ( this.props.login.authType === AUTH_TYPES.MOBILE_APPROVE ) ) {
        if(this.props.login.authType === AUTH_TYPES.TOTP) {
          return <ExternalTotpLogin />
        } else {
          return <ExternalPnLogin />
        }
      } else if ( this.props.login.authType === AUTH_TYPES.TOTP ) {
        return <ExternalTotpLogin />
      } else {
        return <ExternalLogin />
      }
    } 
    else if (this.props.login.isAuthenticated && !this.props.baseFetchCompleted && !this.props.isISVUser) {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }
    else if(this.props.login.isAuthenticated && !this.props.isvBaseFetchCompleted && this.props.isISVUser) {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }
    
    return (
      <div aria-hidden={this.props.isAnyModalOpened}>
        {
          !this.props.isISVUser ? 
            <AppContainer
                fluid={this.props.location.pathname === "/"}
                logout={this.logoutMethod}
                detectUserInteraction={this.props.detectUserInteraction}
                links={this.props.navigationFeatures}
                user={this.props.login.userName}
                company={this.props.currentDealInfo.issuerName}
                project={this.props.currentDealInfo.projectName}
                popoverElement={<DealSwitcher onSwitchDeal={ this.dealSwitchClick } />}
              >
              {this.props.children}
            </AppContainer>
          :
          <>
           { this.props.isIsvExternalUser && this.props.isvDealData && this.props.dealSwitch &&
            (
              <OneWayLetter
              modalID={modalIds.ONE_WAY_LETTER}
              className={"close-modal-icon"}
              siteTextType={SITE_TEXT_TYPES.ONE_WAY_LETTER}
              dealId={this.props.isvDealData && this.props.isvDealData.dealId}
            />
          )}
            <IsvAppContainer
              fluid={true}
              logout={this.logoutMethod}
              detectUserInteraction={this.props.detectUserInteraction}
              links={this.props.navigationFeatures}
              user={this.props.login.userName}
              company={this.props.currentDealInfo.issuerName}
              project={this.props.currentDealInfo.projectName}
              popoverElement={<IsvDealSwitcher onSwitchDeal={ this.dealSwitchClick } />}
            >
              {this.props.children}
            </IsvAppContainer>
            </>
        }
        <SessionTimer />
        { DevTools && <DevTools /> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    login: state.login,
    currentDealInfo: getCurrentDealInfo(state),
    navigationFeatures: getAllowedNavigationFeatures(state),
    languageCode: getSelectedLanguage(state),
    baseFetchCompleted: isBaseFetchCompleted(state),
    isvBaseFetchCompleted: isIsvBaseFetchCompleted(state),
    initializing: state.login.initializing,
    isAnyModalOpened: isAnyModalOpened(state),
    dealSwitch:state.isvUtility.dealSwitch,
    isISVUser: isISVUser(state),
    isvDealData:getIsvCurrentDeal(state),
    isIsvExternalUser:isIsvExternalUser(state)
});

const mapDispatchToProps = dispatch => ({
  initialize: (token, expiration, isInternal) => {
    dispatch(initialize(token, expiration, isInternal));
  },
  detectUserInteraction: () => {
    dispatch(detectUserInteraction());
  },
  logOff: () => {
    dispatch(logOff());
  },
  setupDeal: (dealId) => {
    dispatch(dealSetup(dealId, true));
  },
  isvSwitchDeal: (dealId) => {
    dispatch(isvSwitchDeal(dealId))
  },
  setupIsvDeal: (dealId) => {
    dispatch(setupIsvDeal(dealId, true));
  },
  requestIsvDealClick:(dealId , loader)=>{
    dispatch(requestIsvDealClick(dealId,loader))
  }
})

export default compose(
  withAnalytics(event => ({
    logoutEvent: () => event(events.LOGOUT),
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
