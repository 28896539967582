import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
// TODO
import { ValueFormatters, Sorters } from 'mk-pattern-library-react';

import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import { DateAsOf } from 'shared/styles/components/date';
import withAnalytics from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import { featurePageIds } from 'constants/featurePages';
import { getMyDocsData } from 'selectors';

import DocumentList from 'MyDocs/Documents/DocumentList/DocumentList';

const ROADSHOW_CATEGORY_ID = 6;

const EmptyDocumentList = styled.div`
  width: 100%;
  text-align: center;
`;

class DocumentMode extends Component {
  componentDidMount() {
    this.props.viewChange();
  }

  render() {
    const roadshowDocs = this.props.myDocs
      .filter((document) => ((ROADSHOW_CATEGORY_ID === document.category.documentCategoryId)))
      .sort((a, b) => Sorters.doubleSort('title', a, b, 'desc', 'created'))
      .filter(doc => doc.master)

    return (
      <FeatureContainer>
        <HeaderContainer>
          <Col sm={6}>
            <FeatureHeading className="text-xxlarge text-univ-bd">
              {this.props.roadshowDocument}
            </FeatureHeading>
            <HeadingDescription top={5}>
              {this.props.latestRoadshowDocument}
            </HeadingDescription>
          </Col>
          <Col sm={6}>
            <DateAsOf className="text-timestamp text-small">
              {this.props.dataAsOf}
            </DateAsOf>
          </Col>
        </HeaderContainer>
        <Row style={{ padding: '0 0 0 15px' }}>
          <Col sm={12} md={12} lg={12} style={{ backgroundColor: '#fff', padding: 0 }}>
            {
              !roadshowDocs.length ? (
                <EmptyDocumentList>
                  {this.props.noDocumentsAvailable}
                </EmptyDocumentList>
              ) : (
                <DocumentList data={roadshowDocs} />
              )
            }
          </Col>
        </Row>
      </FeatureContainer>
    );
  }
}

export default compose(
  withAnalytics((__, view) => ({
    viewChange: () => view(featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE),
  })),
  withTranslate((translate, props) => ({
    roadshowDocument: translate('roadshow.roadshow_document', 'Roadshow Document'),
    latestRoadshowDocument: translate('roadshow.the_latest_roadshow_document', 'The latest roadshow document'),
    noDocumentsAvailable: translate('common.no_documents_available', 'No documents available'),
    dataAsOf: translate(
      'common.data_as_of_<date_time>',
      `Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', props.dateReceived)}`,
      [
        ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', props.dateReceived)]
      ]
    )
  })),
  connect(
    state => ({
      myDocs: getMyDocsData(state),
      dateReceived: state.myDocsData.dateReceived,
    }),
  ),
)(DocumentMode);
