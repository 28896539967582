var ValueFormatters = require('./valueFormatters');
export const doubleSort = (secondary, a, b, order, prop, fallbackProp, secondaryOrder) => {
    order = order || 'asc';

    let aVal1 = ValueFormatters.accessor(null, a, prop);
    let bVal1 = ValueFormatters.accessor(null, b, prop);

    let aVal2 = ValueFormatters.accessor(null, a, secondary);
    let bVal2 = ValueFormatters.accessor(null, b, secondary);


    if ((typeof aVal1 === 'undefined' || aVal1 === null) || (aVal1 === '--')) {
        if (prop !== secondary) {
            if (aVal2 < bVal2) {
                return secondaryOrder ? 1 : -1
            }
            else if (aVal2 > bVal2) {
                return secondaryOrder ? -1 : 1
            }
            else {

                return 0
            }
        }
        else {
            return 0
        }
    }
    else if (typeof aVal1 === 'string') {
        aVal1 = aVal1.toLowerCase().trim();
    }
    if ((typeof bVal1 === 'undefined' || bVal1 === null) || (bVal1 === '--')) {
        if (prop !== secondary) {
            if (aVal2 < bVal2) {
                return secondaryOrder ? 1 : -1
            }
            else if (aVal2 > bVal2) {
                return secondaryOrder ? -1 : 1
            }
            else {

                return 0
            }
        }
        else {
            return 0
        }

    }
    else if (typeof bVal1 === 'string') {
        bVal1 = bVal1.toLowerCase().trim();
    }
    if (typeof aVal2 === 'string') {
        aVal2 = aVal2.toLowerCase().trim();
    }
    if (typeof bVal2 === 'string') {
        bVal2 = bVal2.toLowerCase().trim();
    }


    if (order === 'asc') {
        if (aVal1 < bVal1) {
            return -1
        }
        else if (aVal1 > bVal1) {
            return 1
        }
        else {
            if (prop !== secondary) {
                if (aVal2 < bVal2) {
                    return secondaryOrder ? 1 : -1
                }
                else if (aVal2 > bVal2) {
                    return secondaryOrder ? -1 : 1
                }
                else {

                    return 0
                }
            }
            else {
                return 0
            }
        }
    }
    else {
        if (aVal1 < bVal1) {
            return 1
        }
        else if (aVal1 > bVal1) {
            return -1
        }
        else {
            if (prop !== secondary) {
                if (aVal2 < bVal2) {
                    return secondaryOrder ? 1 : -1
                }
                else if (aVal2 > bVal2) {
                    return secondaryOrder ? -1 : 1
                }
                else {

                    return 0
                }
            }
            else {
                return 0
            }
        }
    }
}

export const doubleSortFlat = (a, b, prop, secondary, direction, fallbackProp) => {

    let aVal1 = a[prop]
    let bVal1 = b[prop]

    let badAVal = false
    let badBVal = false
    if (aVal1 === null || typeof aVal1 === 'undefined' || aVal1 === "") {
        aVal1 = a[fallbackProp]
        if (aVal1 === null || typeof aVal1 === 'undefined' || aVal1 === "") {
            badAVal = true;
        }
    }
    if (bVal1 === null || typeof bVal1 === 'undefined' || bVal1 === "") {
        bVal1 = b[fallbackProp]
        if (bVal1 === null || typeof bVal1 === 'undefined' || bVal1 === "") {
            badBVal = true
        }
    }

    let aVal2 = a[secondary]
    let bVal2 = b[secondary]

    let aVal1Type = typeof aVal1;
    let bVal1Type = typeof bVal1;
    let aVal2Type = typeof aVal2;
    let bVal2Type = typeof bVal2;

    aVal1 = aVal1Type === 'string' ? aVal1.toLowerCase().trim() : aVal1;
    bVal1 = bVal1Type === 'string' ? bVal1.toLowerCase().trim() : bVal1;
    aVal2 = aVal2Type === 'string' ? aVal2.toLowerCase().trim() : aVal2;
    bVal2 = bVal2Type === 'string' ? bVal2.toLowerCase().trim() : bVal2;

    if (badAVal && badBVal) {
        if (prop !== secondary) {
            if (aVal2 < bVal2) {
                return -1
            }
            else if (aVal2 > bVal2) {
                return 1
            }
            else {

                return 0
            }
        }
        else {
            return 0
        }
    }
    else if (badAVal) {
        switch (bVal1Type) {
            case 'number':
                aVal1 = 0;
                break;
            case 'string':
                aVal1 = '';
                break;
            default:
                aVal1 = null;
                break;

        }
    }
    else if (badBVal) {
        switch (aVal1Type) {
            case 'number':
                bVal1 = 0;
                break;
            case 'string':
                bVal1 = '';
                break;
            default:
                bVal1 = null;
                break;

        }

    }


    if (direction === 'asc') {

        if (aVal1 < bVal1) {
            return -1
        }
        else if (aVal1 > bVal1) {
            return 1
        }
        else {

            if (aVal2 < bVal2) {
                return -1
            }
            else if (aVal2 > bVal2) {
                return 1
            }

            else {
                return 0
            }

        }
    }
    else {

        if (aVal1 < bVal1) {
            return 1
        }
        else if (aVal1 > bVal1) {
            return -1
        }
        else {

            if (aVal2 < bVal2) {
                return -1
            }
            else if (aVal2 > bVal2) {
                return 1
            }

            else {
                return 0
            }

        }
    }


}

export const themeSort = (secondary, a, b, theme) => {

    // Check to see if the theme is inside of any themeType within either feedback data object
    let aContains = false;
    for (let themeType in a.themes) {
        if (a.themes[themeType].indexOf(theme) > -1) {
            aContains = true;
            break;
        }
    }

    let bContains = false;
    for (let themeType in a.themes) {
        if (b.themes[themeType].indexOf(theme) > -1) {
            bContains = true;
            break;
        }
    }

    // If both objects have a theme, sort by date
    if (aContains && bContains) {
        if (a[secondary] > b[secondary]) {
            return -1
        }
        else if (a[secondary] < b[secondary]) {
            return 1
        }
        else {
            return 0
        }
    }
    else {
        if (aContains) {
            return -1
        }
        else if (bContains) {
            return 1
        }
        // If no objects have theme, sort by date
        else {
            if (a[secondary] > b[secondary]) {
                return -1
            }
            else if (a[secondary] < b[secondary]) {
                return 1
            }
            else {
                return 0
            }
        }
    }

}