import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import get from 'lodash/get';
import { compose } from 'recompose';
import { ValueFormatters, SiteConstants, HsbcButton } from 'mk-pattern-library-react';
import { dateFormatter } from '../../SharedTableComponents/formatters';

import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import withTranslate from 'shared/hoc/withTranslate';

import { updateCurrentTranche } from 'actions/OrderBookActions';
import { getCurrentTranche, getDemandDataForSummary } from 'selectors';

import DemandByCarouselPanelPie from '../../shared/summary/DemandByCarouselPanelPie';
import DemandByCarouselPanelBar from '../../shared/summary/DemandByCarouselPanelBar';
import OrderbookHeader from '../../shared/summary/OrderbookHeader';
import TopTenTable from '../../shared/summary/TopTenTable';
import StatusBar from '../../shared/summary/StatusBar';

import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import { tabIds, ecmSummaryDemandTabs } from 'constants/tabIds';
import HorizontalTab from 'shared/accessibility/components/HorizontalTabs/HorizontalTab';
import HorizontalTabs from 'shared/accessibility/components/HorizontalTabs';

const panelStyle = {
    minHeight: '380px',
};

const panelStyleTopTen = {
    height: '448px'
};

const demandButtonGroupStyle = {
  margin: '20px 20px 10px',
}

const dayKeyFieldFormatter = value => {
  if(value === 'Other') {
    return 'Other';
  }

  return dateFormatter('dayMonth', value);
};



class OrderSummaryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselIndex: 0,
            chartTabView: 'lowest',
        };
        this.carouselChange = this.carouselChange.bind(this);
    }

    componentDidMount() {
        this.props.viewChange();
    }

    carouselChange(index) {
        this.setState({ carouselIndex: index });
    }

    handleChartTabViewToggle(view) {
        this.setState({ chartTabView: view })
    }

    formatDemandByData(demandData, chartColors, isMillion, type) {
        const barData = [];
        const dataPointKeys = [];

        demandData.forEach(item => {
            const priceDemandDetail = (item.demand) ? item.demand : [];
            const priceDemandDataSeries = {};

            // populate priceDemandDataSeries[] with priceDemandDetail[] based on item.categoryName
            priceDemandDetail.forEach(item => {
                if (dataPointKeys.indexOf(item.categoryName) === -1) {
                    dataPointKeys.push(item.categoryName);
                }
                priceDemandDataSeries[item.categoryName] = isMillion ? (item.demand / 1000000) : item.demand;
            });

            const sortedDataSeries = [];
            for (const item in priceDemandDataSeries) {
                sortedDataSeries.push([item, priceDemandDataSeries[item]]);
            }

            sortedDataSeries.sort((a, b) => (a[1] - b[1]));

            const mergedDataSeries = {};

            if (sortedDataSeries.length > 0) {
                for (const item of sortedDataSeries) {
                    mergedDataSeries[item[0]] = item[1];
                }
                mergedDataSeries.name = item.sharePrice;
                barData.push(mergedDataSeries);
            }

        });
        barData.sort((a, b) => {
            if (+a.name < +b.name) {
                return -1
            }
            else if (+a.name > +b.name) {
                return 1
            }
            else {
                return 0
            }
        })

        const firstSeries = barData[0];

        if (!firstSeries) {
            return ({
                barData,
                barKeys: [],
            });
        }

        let keyItems = [];
        let keyTotals = {}

        barData.forEach((barDatum, ind) => {
            for (let barDatumKey in barDatum) {

                if (barDatumKey === 'name') {
                    barDatum[barDatumKey] = ValueFormatters.commaDecimalFormatter(barDatum[barDatumKey]);
                }

                if (keyItems.indexOf(barDatumKey) === -1) {
                    keyItems.push(barDatumKey);

                }
                if (ind === 0) {
                    keyTotals[barDatumKey] = 0;
                    keyTotals[barDatumKey] += barDatum[barDatumKey]
                }
            }
        });

        if (type === 'date') {
            keyItems.sort((a, b) => {
                if (a.toLowerCase() === 'other') {
                    return 1
                }
                else if (b.toLowerCase() === 'other') {
                    return -1
                }
                if (a < b) {
                    return -1
                }
                else if (a > b) {
                    return 1
                }
                else {
                    return 0
                }
            });
        }
        else {
            keyItems.sort((a, b) => {

                if (a.toLowerCase() === 'other') {
                    return 1
                }
                else if (b.toLowerCase() === 'other') {
                    return -1
                }

                if ((keyTotals[a] || 0) < (keyTotals[b] || 0)) {
                    return 1
                }
                else if ((keyTotals[a] || 0) > (keyTotals[b] || 0)) {
                    return -1
                }
                else {
                    return 0
                }
            });
        }


        // TODO:  need to add 0 values in case any date[] values are not in first series
        // TODO Handle if keyItems > colors length
        const colors = chartColors.slice(0, keyItems.length);
        //colors.reverse();
        //add any key formatters here based on type passed
        const formatKey = (value) => {
            if (type === "date") {
                return ValueFormatters.abbreviateFullDate(value);
            }
            else {
                return value
            }
        }

        const barKeys = keyItems
            .filter(item => item !== 'name')
            .map((item, index) => (
                {
                    key: item,
                    value: formatKey(item),
                    color: item.toLowerCase() === 'other' ? SiteConstants.otherColor : (colors[(index) % colors.length])
                })).reverse()
        return ({
            barData,
            barKeys,
        });
    }

    render() {
        // from route we can optionally set the default order table tab
        const ordersTableTab = get(this.props.location, 'state.ordersTable');

        const dealsData = this.props.dealsData
        // const currentTrancheData = this.props.currentTrancheData;
        const stackedBarDayData = this.formatDemandByData(this.props.externalSourceLastModifiedSummaryDataByShare, SiteConstants.secondaryChartColors, true, "date");
        const stackedBarCountryData = this.formatDemandByData(this.props.investorCountrySummaryDataByShare, SiteConstants.primaryChartColors, true);
        const stackedBarInvestorTypeData = this.formatDemandByData(this.props.investorTypeSummaryDataByShare, SiteConstants.primaryChartColors, true);

        const tableDataCountry = this.props.investorCountrySummaryData.tableData;
        const countryPieData = this.props.investorCountrySummaryData.chartData;
        const tableDataInvestor = this.props.investorTypeSummaryData.tableData;
        const investorPieData = this.props.investorTypeSummaryData.chartData;

        const stackedXAxisLegendLabel = (
            translationString(
                'orders.demand_(<units>)_@_<currency>_per_share',
                this.props.language,
                `Demand (${dealsData.dealCurrency}m) @ ${dealsData.dealCurrency} per share`,
                [
                    ["<units>", `${dealsData.dealCurrency}m`],
                    ['<currency>', dealsData.dealCurrency]
                ]
            )
        );

        return (
            <div>
                <OrderbookHeader dealType='ecm' headerData={dealsData} dateReceived={this.props.dateReceived} />

                <StatusBar
                    barType="ecm"
                    currentTranche={dealsData}
                    dealDemandSharePrice={dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice}
                    dealTotalDemandAmountAtLowestPrice={dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.totalDemandAmount}
                    dealSize={dealsData.dealSize}
                    currency={dealsData.dealCurrency}
                    total={dealsData.dealTotalNumberOfOrdersAtLowestPrice}
                />
                <Row>
                    <Col md={7} style={{ marginBottom: 20 }}
                      role="region"
                      aria-label={translationString("orders.demand_details", this.props.language, "Demand Details")}>
                        <div className="tabbed-container">
                            <HorizontalTabs tabsId={tabIds.ECM_SUMMARY_DEMAND_TABS}>
                                <HorizontalTab
                                    tabId={ecmSummaryDemandTabs.BY_INVESTOR_TYPE}
                                    title={this.props.tab1Title}
                                    onTabClicked={() => this.props.tabChangeEvent(events.ORDERS_SUMMARY_DEMAND_TAB_BY_INVESTOR_TYPE)}
                                >
                                    <ButtonGroup style={demandButtonGroupStyle}>
                                        <HsbcButton
                                            id='btn-ecm-os-i-dp'
                                            size="regular"
                                            className={"btn-segment" + (this.state.chartTabView === "lowest" ? " active" : "")}
                                            onClick={() => this.handleChartTabViewToggle("lowest")}
                                        >
                                            <TranslationText
                                                dictionaryKey={"orders.demand_@_<price>"}
                                                replace={[
                                                    ["<price>", `${ValueFormatters.commaDecimalFormatter(dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice)} (${dealsData.dealCurrency})`]
                                                ]}
                                                defaultText={`Demand @ ${ValueFormatters.commaDecimalFormatter(dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice)} (${dealsData.dealCurrency})`}
                                            />
                                        </HsbcButton>

                                        <HsbcButton
                                            id='btn-ecm-os-i-ap'
                                            size="regular"
                                            className={"btn-segment" + (this.state.chartTabView === "all" ? " active" : "")}
                                            onClick={() => this.handleChartTabViewToggle("all")}
                                        >
                                            <TranslationText
                                                dictionaryKey={"orders.all_prices"}
                                                defaultText={"All Prices"}
                                            />
                                        </HsbcButton>
                                    </ButtonGroup>

                                    {
                                        this.state.chartTabView === 'lowest' && (
                                            <DemandByCarouselPanelPie
                                                wideColorPalette={false}
                                                currency={dealsData.dealCurrency}
                                                sharePrice={dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice}
                                                total={`${dealsData.dealTotalNumberOfOrdersAtLowestPrice}`}
                                                tableType="ecm"
                                                panelStyle={panelStyle}
                                                chartData={investorPieData}
                                                tableData={tableDataInvestor}
                                                type={translationString('orders.type', this.props.language, 'Type')}
                                            />
                                        )
                                    }
                                    {
                                        this.state.chartTabView === 'all' && (
                                            <DemandByCarouselPanelBar
                                                wideColorPalette={false}
                                                currency={dealsData.dealCurrency}
                                                height={350}
                                                panelStyle={panelStyle}
                                                chartData={stackedBarInvestorTypeData.barData}
                                                keyData={stackedBarInvestorTypeData.barKeys}
                                                type={translationString('orders.type', this.props.language, 'Type')}
                                                xAxisLabel={stackedXAxisLegendLabel}
                                                legendTitle={translationString('orders.investor_type', this.props.language, 'Investor Type')}
                                                keyField="investorType"
                                                buttonStyle={{
                                                  right: '20px',
                                                  top: '-48px',
                                                }}
                                            />
                                        )
                                    }
                                </HorizontalTab>
                                <HorizontalTab
                                    tabId={ecmSummaryDemandTabs.BY_COUNTRY}
                                    title={this.props.tab2Title}
                                    onTabClicked={() => this.props.tabChangeEvent(events.ORDERS_SUMMARY_DEMAND_TAB_BY_COUNTRY)}
                                >
                                    <ButtonGroup style={demandButtonGroupStyle}>
                                        <HsbcButton
                                            id='btn-ecm-os-c-dp'
                                            size="regular"
                                            className={"btn-segment" + (this.state.chartTabView === "lowest" ? " active" : "")}
                                            onClick={() => this.handleChartTabViewToggle("lowest")}
                                        >
                                            <TranslationText
                                                dictionaryKey={"orders.demand_@_<price>"}
                                                replace={[
                                                    ["<price>", `${ValueFormatters.commaDecimalFormatter(dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice)} (${dealsData.dealCurrency})`]
                                                ]}
                                                defaultText={`Demand @ ${ValueFormatters.commaDecimalFormatter(dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice)} (${dealsData.dealCurrency})`}
                                            />
                                        </HsbcButton>

                                        <HsbcButton
                                            id='btn-ecm-os-c-ap'
                                            size="regular"
                                            className={"btn-segment" + (this.state.chartTabView === "all" ? " active" : "")}
                                            onClick={() => this.handleChartTabViewToggle("all")}
                                        >
                                            <TranslationText
                                                dictionaryKey={"orders.all_prices"}
                                                defaultText={"All Prices"}
                                            />
                                        </HsbcButton>
                                    </ButtonGroup>


                                    {
                                        this.state.chartTabView === 'lowest' && (
                                            <DemandByCarouselPanelPie
                                                wideColorPalette={false}
                                                currency={dealsData.dealCurrency}
                                                sharePrice={dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice}
                                                total={`${dealsData.dealTotalNumberOfOrdersAtLowestPrice}`}
                                                tableType="ecm"
                                                panelStyle={panelStyle}
                                                chartData={countryPieData}
                                                tableData={tableDataCountry}
                                                type={translationString('orders.country', this.props.language, 'Country')}
                                            />
                                        )
                                    }
                                    {
                                        this.state.chartTabView === 'all' && (
                                            <DemandByCarouselPanelBar
                                                wideColorPalette={false}
                                                currency={dealsData.dealCurrency}
                                                height={350}
                                                panelStyle={panelStyle}
                                                chartData={stackedBarCountryData.barData}
                                                keyData={stackedBarCountryData.barKeys}
                                                type={translationString('orders.country', this.props.language, 'Country')}
                                                xAxisLabel={stackedXAxisLegendLabel}
                                                legendTitle={translationString('orders.country', this.props.language, 'Country')}
                                                keyField="country"
                                                buttonStyle={{
                                                  right: '20px',
                                                  top: '-48px',
                                                }}
                                            />
                                        )
                                    }
                                </HorizontalTab>
                                <HorizontalTab
                                    tabId={ecmSummaryDemandTabs.BY_DAY}
                                    title={this.props.tab3Title}
                                    onTabClicked={() => {
                                        this.props.tabChangeEvent(events.ORDERS_SUMMARY_DEMAND_TAB_BY_DAY);
                                    }}
                                >
                                      <DemandByCarouselPanelBar
                                          wideColorPalette
                                          currency={dealsData.dealCurrency}
                                          panelStyle={panelStyle}
                                          chartData={stackedBarDayData.barData}
                                          tableSort={'day'}
                                          keyData={stackedBarDayData.barKeys}
                                          type={translationString('orders.day', this.props.language, 'Day')}
                                          xAxisLabel={stackedXAxisLegendLabel}
                                          legendTitle={translationString('orders.date_of_demand', this.props.language, 'Date of Demand')}
                                          keyField="day"
                                          keyFieldFormatter={dayKeyFieldFormatter}
                                          tableStyle={{ marginTop: 50 }}
                                          buttonStyle={{
                                            right: '20px',
                                            top: '10px',
                                          }}
                                        />
                                </HorizontalTab>
                            </HorizontalTabs>
                        </div>
                    </Col>
                    <Col md={5}
                      role="region"
                      aria-label={translationString("orders.top_ten_details", this.props.language, "Top Ten Details")}>
                        <TopTenTable
                            panelStyle={panelStyleTopTen}
                            type="ecm"
                            orderData={this.props.orderData}
                            currentTrancheData={dealsData}
                            sharePrice={dealsData.dealDemandLowestSharePrice}
                            defaultTab={ordersTableTab}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    currentTrancheData: getCurrentTranche(state),
    investorCountrySummaryData: getDemandDataForSummary(state, 'investorCountrySummaryDataByShareLowest'),
    investorTypeSummaryData: getDemandDataForSummary(state, 'investorTypeSummaryDataByShareLowest'),

    investorCountrySummaryDataByShare: state.orderBookData.investorCountrySummaryDataByShare,
    investorTypeSummaryDataByShare: state.orderBookData.investorTypeSummaryDataByShare,
    externalSourceLastModifiedSummaryDataByShare: state.orderBookData.externalSourceLastModifiedSummaryDataByShare,
    // TODO:  This objetc includes tranches, may need to pull out tranche data and remove trancheData below
    dealsData: state.utility.dealsData,
    lowestSharePrice: state.utility.dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice,
    // investorTypeDataByShare: state.orderBookData.ShareDemandByInvestorType,
    currentTrancheId: state.orderBookData.currentTrancheId,
    dealId: state.utility.dealsData.dealId,
    // trancheData: state.orderBookData.TrancheData,
    // titles: state.orderBookData.tranches,
    orderData: state.orderBookData.orderData,
    // currentTranche: state.orderBookData.currentTranche,
    dateReceived: state.orderBookData.dateReceived,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentTranche: (trancheId) => dispatch(updateCurrentTranche(trancheId, 'ECM')),
});

export default compose(
    withLanguage,
    withTranslate((translate, props) => ({
        tab1Title: translate('orders.demand_by_investor_type', 'Demand by Investor Type'),
        tab2Title: translate('orders.demand_by_country', 'Demand by Country'),
        tab3Title: translate('orders.demand_by_day', 'Demand by Day'),
    })),
    withAnalytics((ev, view) => ({
        viewChange: () => view(featurePageIds.ORDERBOOK_SUMMARY_PAGE),
        tabChangeEvent: eventDetails => ev(eventDetails),
    })),
    connect(mapStateToProps, mapDispatchToProps),
)(OrderSummaryContainer);
