import Types from 'actions/Types';

const attemptAuthentication = (response) => ({
    type: Types.ATTEMPT_AUTHENTICATION,
    userData: response,
});

const setAuthentication = (status, context) => ({
    type: Types.SET_AUTHENTICATION,
    status,
    context,
});

const loginFailure = (message) => ({
  type: Types.LOGIN_FAILURE,
  message
});

const logout = (preventSSO) => ({
    type: Types.LOGOUT,
    preventSSO : !preventSSO ? false : true
});

const requestAuthTokenGet = (loader) => ({
    type: Types.API_ATTEMPT_SSO,
    endPoint: '/v1/auth2/internal',
    params: {},
    ajaxType: 'GETCREDS',
    onSuccess:  attemptAuthentication,
    onFailure: loginFailure,
    loader,
});

const requestAuthTokenPost = (username, pdword, loader) => ({
    type: Types.API_ATTEMPT_LOGIN,
    endPoint: '/v1/auth/loginAs',
    params: {
        username,
        pdword,
    },
    ajaxType: 'POST',
    onSuccess: attemptAuthentication,
    onFailure: loginFailure,
    loader,
});

const requestPreAuthErrLogPost = (username, request, response, errorCode, errorDescription, loader) => ({
  type: Types.API_PRE_AUTH_ERR_LOG,
  endPoint: '/v1/preauth/error',
  params: {
    username,
    request,
    response,
    errorCode, 
    errorDescription,
  },
  ajaxType: 'POST',
  onSuccess: loginFailure,
  onFailure: loginFailure,
  loader,
});

const requestExtAuthTokenPost = (username, pdword, aT, loader) => ({
  type: Types.API_ATTEMPT_LOGIN,
  endPoint: '/v1/auth/loginAs',
  params: {
    username,
    pdword,
    aT,
  },
  ajaxType: 'POST',
  onSuccess: attemptAuthentication,
  onFailure: loginFailure,
  loader,
})

const loginFormChanged = (fieldName, fieldValue) => ({
  type: Types.LOGIN_FORM_CHANGE,
  fieldName,
  fieldValue,
});

const loginFormSubmit = (userNameField, passwordField) => ({
  type: Types.LOGIN_FORM_SUBMIT,
  userNameField,
  passwordField,
})

const setInvalidFields = (fields) => ({
  type: Types.SET_INVALID_FIELDS,
  fields,
})

const loginFormExtSubmit = (userNameField, authTypeField) => ({
  type: Types.LOGIN_FORM_EXT_SUBMIT,
  userNameField,
  authTypeField
})

const loginFormTotpSubmit = ( userNameField, passwordField, aT ) => ({
  type: Types.LOGIN_FORM_TOTP_SUBMIT,
  userNameField,
  passwordField,
  aT,
})

const loginFormPnSubmit = ( userNameField, aT ) => ({
  type: Types.LOGIN_FORM_PN_SUBMIT,
  userNameField,
  aT
})

const cancelLoginFormPnSubmit = () => ({
  type: Types.CANCEL_LOGIN_FORM_PN_SUBMIT
})

const setAuthType = ( authType ) => ({
  type: Types.SET_AUTH_TYPE,
  authType,
})

const setLoginError = ( e ) => ({
  type: Types.SET_LOGIN_ERROR,
  e,
})

const setIsIsvUser = (e) => ({
  type: Types.SET_IS_ISV_USER,
  e,
})

const setUsername = ( userName ) => ({
  type: Types.SET_USERNAME,
  userName,
})

const setLoginModal = ( showLoginModal ) => ({
  type: Types.SET_LOGIN_MODAL,
  showLoginModal,
})

const loginFormInvalid = (fieldName) => ({
  type: Types.LOGIN_FORM_INVALID,
  fieldName,
})

const loginFormValid = (fieldName) => ({
  type: Types.LOGIN_FORM_VALID,
  fieldName,
})

const initialize = (token, expiration, isInternal) => ({
  type: Types.INITIALIZE,
  token,
  expiration,
  isInternal,
})

const initializing = (initializing) => ({
  type: Types.INITIALIZING,
  initializing,
});

const setPendingAuthentication = pending => ({
  type: Types.SET_PENDING_AUTHENTICATION,
  pending,
});

const setAuthenticationResponse = message => ({
  type: Types.SET_AUTHENTICATION_RESPONSE,
  message,
});

const requestTermsAndConditions = langCode => ({
  type: Types.API_GET_TERMS_AND_CONDITIONS,
  endPoint: `/v1/TermsAndConditions?langCode=${langCode.toLowerCase()}`,
  ajaxType: 'GET',
  onSuccess:  receiveTermsAndConditions,
});

const receiveTermsAndConditions = response => ({
  type: Types.RECEIVE_TERMS_AND_CONDITIONS,
  data: response.data[0],
})

const showTermsAndConditions = () => ({
  type: Types.SHOW_TERMS_AND_CONDITIONS,
});

const acceptTermsAndConditions = (accepted, termsAndConditionsId) => ({
  type: Types.ACCEPT_TERMS_CONDITIONS,
  accepted,
  termsAndConditionsId,
});

const onTermsCheckedChange = (checked) => ({
  type: Types.ON_TERMS_CHECKED_CHANGE,
  checked,
});

const receiveTermsAccepted = () => ({
  type: Types.TERMS_AND_CONDITIONS_ACCEPTED
});

const openSiteText = (langCode, siteTextType) => ({
  type: Types.OPEN_SITE_TEXT,
  langCode,
  siteTextType,
});

const setSiteText = (langCode, siteTextType, text) => ({
  type: Types.SET_SITE_TEXT,
  langCode,
  siteTextType,
  text,
});

const deactivateDevice = deviceId => ({
  type: Types.DEACTIVE_BIOMETRICS_DEVICE,
  deviceId,
})

const viewDeviceBiometrics = () => ({
  type: Types.VIEW_DEVICE_BIOMETRICS,
})

const setManageDevices = devices => ({
  type: Types.SET_MANAGE_DEVICES,
  devices,
})

const clearDeviceDeactivationMessages = () => ({
  type: Types.CLEAR_DEVICE_DEACTIVATE_MESSAGES,
})

const setDeviceDeactivationMessages = (response) => ({
  type: Types.SET_DEVICE_DEACTIVATE_MESSAGES,
  response,
})

export {
    attemptAuthentication,
    setAuthType,
    setLoginError,
    setIsIsvUser,
    setUsername,
    setAuthentication,
    loginFailure,
    logout,
    requestPreAuthErrLogPost,
    setLoginModal,
    requestAuthTokenPost,
    requestExtAuthTokenPost,
    requestAuthTokenGet,
    loginFormChanged,
    loginFormSubmit,
    setInvalidFields,
    loginFormExtSubmit,
    loginFormTotpSubmit,
    loginFormPnSubmit,
    cancelLoginFormPnSubmit,
    loginFormInvalid,
    loginFormValid,
    initialize,
    initializing,
    setPendingAuthentication,
    setAuthenticationResponse,
    showTermsAndConditions,
    acceptTermsAndConditions,
    onTermsCheckedChange,
    receiveTermsAccepted,
    requestTermsAndConditions,
    receiveTermsAndConditions,
    openSiteText,
    setSiteText,
    deactivateDevice,
    viewDeviceBiometrics,
    setManageDevices,
    clearDeviceDeactivationMessages,
    setDeviceDeactivationMessages,
};
