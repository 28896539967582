import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';
import _ from 'lodash';
// TODO
import { Icon } from 'mk-pattern-library-react';

import withLanguage from 'translations/withLanguage';
import { translationString } from 'translations/TranslationText';

import { buttonKeyHandler } from 'util/onKeyPress';

const tickSpace = 10;

const Tick = styled.div`
    position: fixed;
    top: ${props => props.tickTop + 3}px;
    left: ${props => props.tickLeft}px;

    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    background: black;

    ${
        props => {
            if (props.tickWhite) {
                return `
                    z-index: 2001;
                    background: white
                    border-left: 1px solid #979797;
                    border-top: 1px solid #979797;
                `
            }

            return '';
        }
    }
`;

const BackgroundLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(76,76,76,.5);
    z-index: ${props => props.zIndex};
`;

export const CloseIconContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2000;

    img, span {
        width: ${props => props.smallIcon ? 12 : 14}px;
        height: ${props => props.smallIcon ? 12 : 14}px;

        color: #333;

        &:hover {
            color: #b6b7b6;
            cursor: pointer;
        }
    }
`;

class PopupSystem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            left: 0,
            //
            width: 0,
            height: 0,
            // tick
            tickTop: 0,
            tickLeft: 0,
        };

        this.calcModalLocation = this.calcModalLocation.bind(this);
        this.provideStylesForModal = this.provideStylesForModal.bind(this);
    }

    throttledEvent = _.throttle(e => {
		if (this.props.disabled) return;

        buttonKeyHandler(e, this.props.closeModal);

	}, 100, { trailing: true, leading: false });

	keyboardCloseEvent = e => {
		e.persist()
		this.throttledEvent(e);
    }

    calcModalLocation(e) {
        const modalHandler = document.getElementById(this.props.modalID);
        let {
            width: modalWidth,
            height: modalHeight
        } = (modalHandler ? modalHandler.getClientRects()[0] : {});

        if (this.props.clickableElement) {
            const { id: clickableElementID } = this.props.clickableElement.props;
            const buttonHandler = document.getElementById(clickableElementID);

            if (buttonHandler && buttonHandler.getClientRects) {
                let tickTop, tickLeft;
                let {
                    left,
                    top,
                    height: clickableHeight,
                    width: clickableWidth
                } = buttonHandler.getClientRects()[0];

                tickLeft = Math.round(left + clickableWidth/2 - 10);
                tickTop = Math.round(top + clickableHeight) + 1;

                if (left > (window.innerWidth / 2)) {
                    left = left - modalWidth + clickableWidth;
                } else if (window.innerWidth < modalWidth) {
                    left = 0;
                    modalWidth = window.innerWidth;
                }

                this.setState(() => ({
                    // fixed part
                    top: top + clickableHeight + tickSpace,
                    left,
                    // centered part (modal dimensions)
                    width: modalWidth || 0,
                    height: modalHeight || 0,
                    // tick
                    tickTop,
                    tickLeft
                }))
            }
        } else {
            // clickable is null
            this.setState(prevState => ({
                ...prevState,
                // centered part (modal dimensions)
                width: modalWidth || 0,
                height: modalHeight || 0,
            }))

            // not the best i know
            this.forceUpdate();
        }
    }

    componentDidMount() {
        if (this.props.isModalOpen) {
            this.calcModalLocation();
        }

        window.addEventListener('resize', this.calcModalLocation);
        window.addEventListener('orientationChange', this.calcModalLocation);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
            this.calcModalLocation();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcModalLocation);
        window.removeEventListener('orientationChange', this.calcModalLocation);
    }

    provideStylesForModal() {
        const shadow = { boxShadow: '0 5px 10px rgba(0,0,0,.2)' };
        const zIndex = this.props.zIndex || 2000;

        if (this.props.centered) {
            return {
                ...shadow,
                position: 'fixed',
                top: window.innerHeight / 2 - this.state.height / 2,
                left: window.innerWidth / 2 - this.state.width / 2 || 0,
                zIndex,
            };
        }

        return {
            ...shadow,
            position: 'fixed',
            top: this.state.top,
            left: this.state.left,
            zIndex,
        }
    }

    fallbackFocus = () => {
        const id = this.props.modalID;
        setTimeout(() => {
            const clickableElement = document.getElementById(`${id}-btn`);

            if (this && this.props && this.props.closeModal) {
                this.props.closeModal(id);
            }

            if (clickableElement) {
                clickableElement.focus();
            }
        }, 100);
    }

    render() {
        const { language } = this.props;
        let style = !this.props.clickableElement
            ? { width: 0, height: 0 }
            : { display: 'inline-block' };

        if (this.props.clickableElementStyle) {
          style = {
            ...style,
            ...this.props.clickableElementStyle
          }
        }
        
        return (
            <div
                id={`${this.props.modalID}-cnt`}
                style={style}
            >
                {
                    this.props.clickableElement
                }
                {
                    this.props.isModalOpen && (
                        <Portal>
                            <div style={{ width: 0, height: 0 }}>
                                {
                                    /* Transparent Layer for large modal (all contacts/feedback detail) */
                                    this.props.backgroundLayer && (
                                        <BackgroundLayer zIndex={this.props.zIndex || 2000} />
                                    )
                                }
                                {
                                    !this.props.centered && (
                                        <Tick
                                            {...this.state}
                                            tickWhite={this.props.tickWhite}
                                        />
                                    )
                                }
                                <FocusTrap
                                    focusTrapOptions={{
                                        clickOutsideDeactivates: true,
                                        escapeDeactivates: false,
                                        onDeactivate: this.fallbackFocus,
                                    }}
                                    active={this.props.isModalOpen}
                                >
                                    <div
                                        id={this.props.modalID}
                                        style={this.provideStylesForModal()}
                                        aria-modal
                                        className={this.props.className}
                                    >
                                            {
                                                this.props.closeIcon && (
                                                    <CloseIconContainer
                                                        aria-label={translationString('aria.close_modal', language, "Close modal")}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={this.props.closeModal}
                                                        onKeyDown={this.keyboardCloseEvent}
                                                        smallIcon={this.props.smallIcon}
                                                    >
                                                        <Icon
                                                            iconName={this.props.smallIcon ? 'closeX' : 'close2X'}
                                                            alt={translationString('aria.close_modal', language, "Close modal")}
                                                        />
                                                    </CloseIconContainer>
                                                )
                                            }
                                            {
                                                this.props.content
                                            }
                                    </div>
                                </FocusTrap>
                            </div>
                        </Portal>
                    )
                }
            </div>
        );
    }
}

PopupSystem.propTypes = {
    // required
    modalID: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    content: PropTypes.element.isRequired,
    // optional
    closeIcon: PropTypes.bool,
    clickableElement: (props, propName, componentName) => {
        if (props.clickableElement && props.clickableElement.props) {
            const clickableProps = props.clickableElement.props;
            const clickablePropTypes = {
                // required
                id: PropTypes.string.isRequired,
                // WAI-AIRA - default for all
                'aria-label': PropTypes.string.isRequired,
                'aria-haspopup': PropTypes.bool.isRequired,
                // actions
                onClick: PropTypes.func.isRequired,
            };

            // special format (required)
            if (clickableProps.id !== `${props.modalID}-btn`) {
                return new Error(`Invalid format of clickableElement ID in ${componentName}`)
            }

            //
            // focusable & visible for screen reader
            //
            if (
                props.clickableElement &&
                props.clickableElement.type.name !== 'HsbcButton'
            ) {
                clickablePropTypes.role = PropTypes.oneOf(['button','link']).isRequired;
                clickablePropTypes.tabIndex = PropTypes.number.isRequired;
            }

            PropTypes.checkPropTypes(clickablePropTypes, clickableProps, props.modalID, componentName)
        }
    },
    // optional styling
    centered: PropTypes.bool,           // in central part of viewport
    backgroundLayer: PropTypes.bool,    // layer to show on first plan only modal
    smallIcon: PropTypes.bool,          // -> smaller X icon
};

PopupSystem.defaultProps = {
    // needed ?
};

export default withLanguage(PopupSystem);
