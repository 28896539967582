import { connect } from 'react-redux';

import { getSelectedLanguage } from 'selectors';
import { translationString } from 'translations/TranslationText';

function withTranslate(ariaLabels) {
    return Cmp => connect(
        (state, ownProps) => ariaLabels(
            (key, defaultText, replace) => translationString(key, getSelectedLanguage(state), defaultText, replace),
            ownProps,
        )
    )(Cmp);
}

export default withTranslate;
