import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import PerfectScroll from 'perfect-scrollbar';

const Scroll = styled.div`
    position: relative;
    
    &.ps > .ps__rail-y {
        opacity: .6;
    }

    &.ps--active-y .ps__scrollbar-y-rail {
        opacity: 1 !important;
    }
`;

class Scrollable extends Component {
    constructor(props) {
        super(props);

        this._scroll = null;            // PS handler
        this._scrollContainer = null;   // Element ref

        this.targetObserver = new MutationObserver(() => {
            if (this.props.update) {
                this.props.update();
            }
        });

        this.childObserver = new MutationObserver(() => {
            this.scrollUpdate();
        })

        this.scrollUpdate = this.scrollUpdate.bind(this);
    }

    scrollUpdate() {
        if (this._scroll) this._scroll.update();
    }

    componentDidMount() {
        const target = ReactDOM.findDOMNode(this);
        const child = target.children[0];
        
        this.targetObserver.observe(target, {
            attributes: true,
        });

        this.childObserver.observe(child, {
            attributes: true,
            subtree: true,
        });

        this._scroll = new PerfectScroll(this._scrollContainer, {
            theme: 'default',
            suppressScrollX: true,
        });

        setTimeout(() => {
            if (this._scrollContainer) {
                const rail = this._scrollContainer.querySelector('.ps__rail-y')
                const thumb = this._scrollContainer.querySelector('.ps__thumb-y')
                if (rail && thumb) {
                    rail.setAttribute('aria-hidden', true);
                    thumb.setAttribute('focusable', false)
                    thumb.setAttribute('tabindex', -1);
                }
            }
        }, 10);

        window.addEventListener("resize", this.scrollUpdate);
        window.addEventListener("orientationChange", this.scrollUpdate);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scrollHeight !== this.props.scrollHeight) {
            this.scrollUpdate();
        }

        if (prevProps.scrollToTopFlag !== this.props.scrollToTopFlag) {
            this._scroll.scrollTop = 0;
        }

        return true;
    }

    componentWillUnmount() {
        if (this._scroll) this._scroll.destroy();

        this.targetObserver.disconnect();
        this.childObserver.disconnect();

        window.removeEventListener("resize", this.scrollUpdate);
        window.removeEventListener("orientationChange", this.scrollUpdate);
    }

    render() {
        return (
            <Scroll
                ref={(el) => {
                    this._scrollContainer = el;
                }}
                style={{
                    ...this.props.style,
                    height: this.props.scrollHeight
                }}
                className={this.props.className}
            >
                {this.props.children}
            </Scroll>
        );
    }
}

export default Scrollable;
