import React, { PureComponent } from 'react';

import TranslationText from 'translations/TranslationText';
import { HSBC_RAG_GREEN, HSBC_RAG_AMBER, HSBC_RAG_RED } from 'constants/colors';

import ThemeBar from './ThemeBar';

const headerInlineStyles = { height: 25, margin: 0 };

const sentimentGroupStyles = {
  backgroundColor: '#F7F7F7',
  padding: '0 0 0 10px',
  marginBottom: '7px',
};

const positiveInlineStyles = {
    ...sentimentGroupStyles,
    borderLeft: `solid 7px ${HSBC_RAG_GREEN}`,
};

const negativeInlineStyles = {
    ...sentimentGroupStyles,
    borderLeft: `solid 7px ${HSBC_RAG_RED}`,
};

const valuationInlineStyles = {
    ...sentimentGroupStyles,
    borderLeft: `solid 7px ${HSBC_RAG_AMBER}`,
};

class Themes extends PureComponent { 
    render() {
        return (
            <React.Fragment>
                <h2 style={headerInlineStyles} className="text-univ-bd text-normal">        
                    <TranslationText
                        dictionaryKey={"investor_feedback.themes"}
                        defaultText={"Themes"}
                    />
                </h2>

                {
                    !!this.props.themes.positive.length && (
                        <div style={positiveInlineStyles}>
                            <ThemeBar
                                label="Positives"
                                labelKey="investor_feedback.positives"
                                themes={this.props.themes.positive}
                            />
                        </div>
                    )
                }

                {
                    !!this.props.themes.negative.length && (
                        <div style={negativeInlineStyles}>
                            <ThemeBar
                                label="Concerns"
                                labelKey="investor_feedback.concerns"
                                themes={this.props.themes.negative}
                            />
                        </div>
                    )
                }

                {
                    !!this.props.themes.valuation.length && (
                        <div style={valuationInlineStyles}>
                            <ThemeBar
                                label="Valuation Metrics"
                                labelKey="investor_feedback.valuation_metrics"
                                themes={this.props.themes.valuation}
                            />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

export default Themes;
