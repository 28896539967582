import React from 'react'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FileMetaForm from 'Isv/components/FileUpload/FileMetaForm/FileMetaForm'
import DocumentFileUploader from 'Isv/components/FileUpload/DocumentFileUploader/DocumentFileUploader'
import { uploadPostDocument, handleOpenUploadOverlay, startUploadingFile } from 'Isv/actions/IsvDocumentsAction';
import { selectUploadedOverlayStatus, selectUploadedFile , getIsvCurrentDealId, selectClientDocumentData, getIsvCurrentDeal, selectIsEcbClientUploaded, selectIsRefDocumentUploaded} from "Isv/selectors";
import ProgressBar from 'Isv/components/common/ProgressBar'
import utils from 'Isv/util/util';
import FILE_UPLOAD_STATES from 'Isv/constants/fileUploadStates';
import { ISV_DOCUMENT_TYPE } from 'Isv/constants/isvDocumentReviewStatus';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const FileUploadOverlay = ({ 
    isOpen, handleOpenUploadOverlay, uploadPostDocument, file, status, dealId, selectedClientDealData, 
    currentDeal, isEcbClientDocUploaded 
 }) =>
 {
  const CheckClientTitle = (selectedClientDealData, title, isSupportingDocument) => {
     let count = 0;
     selectedClientDealData &&  selectedClientDealData.length && 
     selectedClientDealData.forEach(element => {
       ((element.document && element.document.title === title) || (element.title === title)) && isSupportingDocument && count++
     });
     return count;
  }
  const uploadDocument = (document) => {
    const dataFD = new FormData();
    const title = document.title.trim();
    dataFD.append('title', title);
    dataFD.append('PostedFile', file);
    dataFD.append('ClientFileId', document.clientFileId || 0);
    dataFD.append('isClientSupportingDoc', document.isClientSupportingDocument || false);
    dataFD.append('dealId', dealId);
    dataFD.append('isEcbClientDocUploaded', isEcbClientDocUploaded || false);
    const fileUploadWorkflowStatus = currentDeal.isFinalSubmission ? "final" : "draft"
    dataFD.append('fileUploadWorkflow', fileUploadWorkflowStatus);
    const isTitleAlreadyExist = CheckClientTitle(selectedClientDealData, title, document.isSupportingDocument); 
    const isEcbDocument = document.document && document.document.category.documentCategoryName === ISV_DOCUMENT_TYPE.ECB_RETURN_DOCUMENT;
    
    uploadPostDocument(dataFD, isTitleAlreadyExist, isEcbDocument, document);
  }

  return (
    <Wrapper>
      <Dialog
        open={isOpen}
        title="File Upload"
        fullWidth={true}
        maxWidth={'md'}
        onBackdropClick={() => handleOpenUploadOverlay(false)}
      >
        <DialogTitle>File Upload</DialogTitle>
        <DialogContent dividers>
          <DocumentFileUploader />
          <FileMetaForm onSubmit={uploadDocument} /> 
        </DialogContent>
        {utils.isState(status, FILE_UPLOAD_STATES.IN_PROGRESS) && <ProgressBar />}
      </Dialog>
    </Wrapper>
  )
}

export default compose(
  connect(
    (state) => ({
      isOpen: selectUploadedOverlayStatus(state),
      file: selectUploadedFile(state),
      status: state.isvDocuments.status,
      dealId: getIsvCurrentDealId(state),
      selectedClientDealData: selectClientDocumentData(state),
      currentDeal: getIsvCurrentDeal(state),
      isEcbClientDocUploaded: selectIsEcbClientUploaded(state),
      isRefDocumentUploaded:selectIsRefDocumentUploaded(state)
    }),
    (dispatch) => ({
      uploadPostDocument: (data, isTitleAlreadyExist, isEcbDocument, document) => {
        dispatch(startUploadingFile())
        dispatch(uploadPostDocument(data, isTitleAlreadyExist, isEcbDocument, document))
      },
      handleOpenUploadOverlay: (isOpen) => {
        dispatch(handleOpenUploadOverlay(isOpen))
      }
    })
  )
)(FileUploadOverlay);
