import { createReducer } from 'reduxsauce';

import { LanguageTypes } from 'actions/Types';
import languages from 'translations/languages';

const availableLanguages = Object.keys(languages);
const langHashMap = {};

availableLanguages.forEach(lang => {
  langHashMap[lang] = true;
});

export const INITIAL_STATE = {
  selectedLanguage: availableLanguages[0],
  availableLanguages: langHashMap,
};

const selectLanguage = (state, { selectedLanguage }) => Object.assign({}, state, {
  selectedLanguage,
});

const ACTION_HANDLERS = {
  [LanguageTypes.SELECT_LANGUAGE]: selectLanguage,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
