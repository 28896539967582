
// for <td> min-width is required 
export const tableEllipsis = {
    maxWidth: 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

export const ellipsis = {
    whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}

export const styledEllipsis = `
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;