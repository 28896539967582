import moment from 'moment-timezone';
import numeral from 'numeral';

//
// Number utils
//
const emptyNumberValue = '';

const commaFormatter = value => {
  if ((typeof value === 'undefined') || value === null) {
    return emptyNumberValue;
  }

  if (value >= 1000) {
    return numeral(value).format('0,0');
  }
    
  return numeral(value).format('0,0.00');
};

//
// Number formatters 
//
const oneDecimalPointPercent = value => {
    if (typeof value !== 'number') return emptyNumberValue;
    return `${value.toFixed(1)}%`;
};

const oneDecimalPoint = value => {
    if (typeof value !== 'number') return emptyNumberValue;
    return value.toFixed(1);
}

const twoDecimalPoint = value => {
    if (typeof value !== 'number') return emptyNumberValue;
    return commaFormatter(value.toFixed(2));
}

const million = value => {
    if (typeof value === 'number') {
        return commaFormatter(value / 1000000);
    }

    // (?)
    return emptyNumberValue;
}

export const numberFormatters = {
    oneDecimalPointPercent,
    oneDecimalPoint,
    twoDecimalPoint,
    million,
};

//
// Date Formatter
//
const emptyDateValue = '';

const dayMonthYear = validTime => validTime.format('DD MMM YYYY');
const zoneTime = validTime => validTime.parseZone().format('HH:mm');

const dayMonth = value => {
    const time = moment(value);
    if (!time.isValid()) return emptyDateValue;

    const now = moment();
    if (now.isSame(time, 'day')) {
        // today
        return time.format('HH:mm');
    }

    return time.format('DD MMM');
}

const dayMonthYearZoneShort = value => {
    const time = moment(value);
    if (!time.isValid()) return emptyDateValue;

    const zone = moment.tz.zone(moment.tz.guess(Date.now()));
    const zoneTime = moment.tz(time, zone.name);

    let timeAbbreviation = zone.abbr(Date.now());
    timeAbbreviation = ((timeAbbreviation[0] === '+' || timeAbbreviation === '-') ? 'GMT' : '') + timeAbbreviation;

    return `${zoneTime.format('DD MMM YYYY HH:mm')} (${timeAbbreviation})`;
}

const dayMonthYearWithProvidedZone = (value, timeZone) => {
    const time = moment(value);
    if (!time.isValid()) return emptyDateValue;

    return `${dayMonthYear(time)} ${zoneTime(time)} ${timeZone}`;
}

const dateTime = (value, timeZone) => {
    const time = moment(value);
    if (!time.isValid()) return emptyDateValue;

    if (moment().isSame(time, 'day')) {
        return dayMonthYear(time);
    }

    return `${dayMonthYear(time)} ${timeZone}`;
}

const timeAbbr = () => {
  let abbr = moment.tz.zone(moment.tz.guess()).abbr(Date.now());
  return ((timeAbbr[0] === '+' || timeAbbr === '-') ? 'GMT' : '') + abbr;
}

export const dateFormatters = {
    dayMonth,
    dayMonthYearZoneShort,
    dayMonthYearWithProvidedZone,
    dateTime,
    timeAbbr,
};

//
// Other
//
export default {
    numberFormatters,
    dateFormatters,
};
