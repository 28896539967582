import React from 'react'

const TableRow = (props) => {
    return (
        <tr {...props}>
            {props.children}
        </tr>
    )
}

export default TableRow;