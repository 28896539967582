import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';

import { simulateResize } from 'shared/functions/events';

import withTabs from 'shared/hoc/withTabs';
import keys from 'shared/accessibility/constants/keys'; 

import { styledDarkOutline } from 'shared/styles/mixins/outline';
import { typography } from 'shared/styles/variables/theme';

const height = 40;
const border = 3;
const font = 14;

const TabList = styled.div`
    width: 100%;
    background: #fff;
    
    margin-top: 0;
    margin-bottom: 0;

    height: ${height}px;
    width: 100%; 
`;

const Tab = styled.div`
    display: inline-block;
    box-sizing: border-box;

    height: ${height}px;

    padding: ${(height - 2 * border - font) / 2 - 2}px 20px;
    margin-right: 2px;

    color: #000;
    background-color: #f3f3f3;

    cursor: pointer;
   
    font-size: ${typography.sizes['14px']};
    font-weight: bold;
    text-align: center;

    border-top: ${border}px solid #f3f3f3; 
    border-bottom: ${border}px solid #f3f3f3; 

    border-right: 1px solid #fff;
    border-left: 1px solid #fff;

    &.active {
        position: relative;

        background-color: #fff;
        border-top: ${border}px solid #db0011; 
        border-bottom: ${border}px solid #fff;

        border-left: 1px solid #D7D8D6;
        border-right: 1px solid #D7D8D6;

        &:after {
             position: absolute;
             bottom: -5px;
             left: 0;
             content: '';
             width: 100%;
             height: 4px;
             background-color: #fff;
        }
    }

    &:hover:not(.active) {
        padding: ${(height - 2 - font) / 2 - 2}px 20px;
        background-color: #fff;
        border-top: 1px solid #d7d8d6;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #D7D8D6;
        border-left: 1px solid #D7D8D6;
    }

    &:focus {
        z-index: 3000;
        ${styledDarkOutline}
    }
`;

const TabDiv = styled.div`
    height: 100%;
`;

const TabSpan = styled.span`
    line-height: ${font}px;
`;

const TabContent = styled.div`
    border: 1px solid #c9c9c9;

    .react-bs-table{
        margin: 0;
    }

    .react-bs-table TABLE THEAD TR {
        &:first-child > TH {
            border-top: none;
        }
    }
`;

class TabHeader extends PureComponent {

    click = () => {
        this.props.tabHeaderClicked(this.props.child);
    }

    keyDown = e => {
        e.persist();
        this.props.tabHeaderKeyDownEvent(e, this.props.child);
    }

    keyUp = e => {
        e.persist();
        this.props.tabHeaderKeyUpEvent(e, this.props.child);
    }

    render() {
        const {
            selected,
            tabsId,
            child
        } = this.props;

        return (
            <Tab
                role="tab"
                aria-selected={selected}
                tabIndex={selected ? 0 : -1}
                aria-controls={tabsId}
                className={selected ? 'active' : null}

                onClick={this.click}
                onKeyDown={this.keyDown}
                onKeyUp={this.keyUp}

                id={child.props.tabId}
                key={child.props.tabId}
            >
                <TabDiv>
                    <TabSpan>
                        { child.props.title }
                    </TabSpan>
                </TabDiv>
            </Tab>
        )
    }
}

class HorizontalTabs extends PureComponent {

    selectPrevious = child => React.Children
        .toArray(this.props.children)
        .map((c, index, array) => {
            if (c.props.tabId === child.props.tabId) {
                if (index === 0) {
                    return array[array.length - 1];
                }
                return array[index - 1];
            }
            return null;
        })
        .filter(c => !!c)[0]; 

    selectNext = child => React.Children
        .toArray(this.props.children)
        .map((c, index, array) => {
            if (c.props.tabId === child.props.tabId) {
                if (index === array.length -1) {
                    return array[0];
                }
                return array[index + 1];
            }
            return null;
        })
        .filter(c => !!c)[0];

    selectFirst = () => React.Children
        .toArray(this.props.children)[0];

    selectLast = () => React.Children
        .toArray(this.props.children)
        .map((child , index, array) => {
            if (index === array.length -1) return child;
            return null;
        })
        .filter(c => !!c)[0];

    tabHeaderClicked = child => {
        this.props.changeTab(child.props.tabId);
        
        simulateResize();

        // activate tab
        if (child.props.onTabClicked) {
            // additional actions
            child.props.onTabClicked();
        }
    }

    tabHeaderKeyDownEvent = (e, child) => {
        switch (e.keyCode) {
            case keys.end:
                e.preventDefault();
                this.tabHeaderClicked(this.selectLast());
                
                break;
            case keys.home:
                e.preventDefault();
                this.tabHeaderClicked(this.selectFirst());

                break;
            default:
                break;
        };
    }

    tabHeaderKeyUpEvent = (e, child) => {
        const idx = this.props.children.indexOf(child);
        const total = this.props.children.length;
        const isLast = idx === total - 1;
        const isFirst = idx === 0;

        switch (e.keyCode) {
            case keys.left:
                if (isFirst) {
                    this.tabHeaderClicked(this.selectLast());
                } else {
                    this.tabHeaderClicked(this.selectPrevious(child));
                }

                break;
            case keys.right:
                if (isLast) {
                    this.tabHeaderClicked(this.selectFirst());
                } else {
                    this.tabHeaderClicked(this.selectNext(child));
                }

                break;
            default: 
                break;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeTab !== this.props.activeTab) {
            const node = document.getElementById(this.props.activeTab);
            if (node) node.focus();
        }
    }

    render() {
        return (
            <React.Fragment>
                <TabList role="tablist" aria-orientation="horizontal">
                    {
                        React.Children
                            .toArray(this.props.children)
                            .filter(child => child && child.props.visible)
                            .map(child => (
                                <TabHeader
                                    selected={child.props.tabId === this.props.activeTab}
                                    tabsId={this.props.tabsId}
                                    child={child}
                                    tabHeaderClicked={this.tabHeaderClicked}
                                    tabHeaderKeyDownEvent={this.tabHeaderKeyDownEvent}
                                    tabHeaderKeyUpEvent={this.tabHeaderKeyUpEvent}
                                />
                            ))
                    }
                </TabList>
                <TabContent
                    id={this.props.tabsId}
                    role="tabpanel"
                    tabIndex={-1}
                    aria-labelledby={'tab-element-id-ref'}
                    {...this.props}
                >
                    {
                        React.Children
                            .toArray(this.props.children)
                            .filter(child => child && child.props.tabId === this.props.activeTab)
                    }
                </TabContent>
            </React.Fragment>
        )
    }
}

export default compose(
    withTabs(changeTab => ({
        changeTab: tabId => changeTab(tabId),
    }))
)(HorizontalTabs);
