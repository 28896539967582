import React from 'react'

const TableBody = (props) => {
    return (
        <tbody {...props}>
            {props.children}
        </tbody>
    )
}

export default TableBody;