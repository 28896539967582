import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as modalIds from 'constants/modalIds';

import Popup from 'shared/components/Popup';
import PopupSystem from 'shared/components/PopupSystem';
import Scrollable from 'shared/components/Scrollable';
import { SvgIcon } from 'shared/components/Icon';
import { openModal, closeModal } from 'actions/UtilityActions';

import withTranslate from 'shared/hoc/withTranslate';
import { getSelectedLanguage, getTermsAndConditionsText, isModalOpen } from 'selectors';

import HsbcLink from 'shared/accessibility/components/Link';
import { requestTermsAndConditions  } from 'actions/LoginActions'

class TermsAndConditionsLink extends Component {

    openModal = () => {
        this.props.openModal(this.props.modalId, this.props.langCode);
    }

    closeModal = () => {
        this.props.closeModal(this.props.modalId);
    }

    render () {
        return (
            <PopupSystem
                centered
                backgroundLayer
                modalID={this.props.modalId}
                isModalOpen={this.props.isModalOpen}
                closeIcon
                closeModal={this.closeModal}
                content={
                    <Popup
                        static
                        width={1250}
                        primaryButton
                        primaryButtonContent="Close"
                        primaryButtonAction={this.closeModal}
                        primaryButtonKey="common.close"
                    >
                        <Scrollable
                            scrollHeight={ window.innerHeight * .5}
                            style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                        >
                            <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                        </Scrollable>
                    </Popup>
                }

                clickableElement={
                    <HsbcLink
                        id={`${this.props.modalId}-btn`}
                        title={this.props.btnText}
                        disabled={this.props.isBtnDisabled}
                        onClick={this.openModal}
                        aria-label={this.props.btnText}
                        role="link"
                        aria-haspopup
                    >
                        <div style={{ width: 30, height: 10, display: 'inline-block', position: 'relative' }}>
                            <SvgIcon
                                icon="pop-up-window"
                                aria-hidden
                                style={{ position: 'absolute', top: -5}}
                            />
                        </div>
                        {this.props.btnText}
                    </HsbcLink>
                }
            />
        )
    }
}

export default compose(
    connect(
        state => ({
            modalId: modalIds.TERMS_AND_CONDITIONS_MODAL_ID,
            content: getTermsAndConditionsText(state),
            langCode: getSelectedLanguage(state),
            isModalOpen: isModalOpen(state, modalIds.TERMS_AND_CONDITIONS_MODAL_ID),
        }),
        dispatch => ({
          openModal: (modalId, langCode) => {
            dispatch(requestTermsAndConditions(langCode));
            dispatch(openModal(modalId));
          },
          closeModal: modalId => dispatch(closeModal(modalId)),
        }),
    ),
    /*withModals(
        selection => ({
            modalId: modalIds.TERMS_AND_CONDITIONS_MODAL_ID,
            isModalOpen: selection(modalIds.TERMS_AND_CONDITIONS_MODAL_ID),
        }),
        (close, open, props) => ({
            openModal: () => {
                open(modalIds.TERMS_AND_CONDITIONS_MODAL_ID);
                props.dispatch(requestTermsAndConditions(props.langCode))

            },
            closeModal: () => close(modalIds.TERMS_AND_CONDITIONS_MODAL_ID),
        }),
    ),*/
    withTranslate(translate => ({
        btnText: translate('common.terms_and_conditions', 'Terms and Conditions')
    }))
)(TermsAndConditionsLink);
