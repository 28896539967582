import { ROADSHOW_SELECTED_TEAMS } from 'constants/storageKeys';

export const selectTeamFromLocalStorage = (dealId, uniqueName) => {
    /* eslint-disable-next-line */
    let roadshowTeams = window.localStorage.getItem(ROADSHOW_SELECTED_TEAMS);

    if (roadshowTeams) {
        roadshowTeams = JSON.parse(roadshowTeams);

        // what if not an object ?
        
        const selectedTeam = roadshowTeams[`${dealId}`];

        if (selectedTeam) {
            return selectedTeam;
        }

        return null;
    }

    return null;
};