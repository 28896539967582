import React from 'react';
import styled from 'styled-components';

import { SvgIcon } from 'shared/components/Icon';

import { typography, fontColors } from 'shared/styles/variables/theme';
import { notificationColors } from 'shared/styles/variables/colors';
import flexbox from 'shared/styles/mixins/flexbox';
 
const provideNotificationColorPalette = props => {
    if (props.warning) return notificationColors.WARNING;
    if (props.info) return notificationColors.INFO;
    if (props.error) return notificationColors.ERROR;

    throw new Error('Notification prop missing ( info / warning / error )');
}

const Container = styled.div`
    ${flexbox('row', 'center', 'flex-start')}
    padding: 20px;
    border: 2px solid ${props => provideNotificationColorPalette(props).border};
    background: ${props => provideNotificationColorPalette(props).container};
    font-size: ${typography.sizes['14px']};
    color: ${fontColors.nightGrey};
`;

const IconContainer = styled.div`
    ${flexbox('row', 'center', 'flex-start')}
    margin-right: 10px;
`;

const Headline = styled.div`
    font-family: ${typography.family.bold};
`;

const Description = styled.div``;
const Action = styled.div``;

class Notification extends React.PureComponent {
    render() {
        const type = {};
        ({
            warning: type.warning,
            error: type.error,
            info: type.info
        } = this.props);

        return (
            <Container {...type}>
                <IconContainer>
                    <SvgIcon
                        color={provideNotificationColorPalette(type).icon}
                        size="xlarge"
                        icon="circle-error-solid"
                        aria-hidden
                    />
                </IconContainer>
                {
                    this.props.headline && (
                        <Headline>{this.props.headline}&nbsp;</Headline>
                    )
                }
                {
                    this.props.description && (
                        <Description>{this.props.description}</Description>
                    )
                }
                {
                    this.props.action && this.props.actionContent && (
                        <Action>Link with action (optional) - TODO if needed</Action>
                    )
                }                
            </Container>
        )
    }
}

export default Notification;
