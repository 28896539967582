import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { featurePageIds } from 'constants/featurePages';
import { viewChange } from 'actions/AnalyticsActions';
import { isDCMDealType, isECMDealType } from 'selectors';

import PricingDocs from './PricingDocuments';

class PricingDocuments extends PureComponent {
    componentDidMount(){
        this.props.viewChange(featurePageIds.PRICING_DOCUMENTS_PAGE);
    }

    render() {
        if (this.props.isDCM) {
            return <PricingDocs />
        } 

        if (this.props.isECM) {
            return <PricingDocs />
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    isDCM: isDCMDealType(state),
    isECM: isECMDealType(state),
});

const mapDispatchToProps = dispatch => ({
    // analytics
    viewChange: viewId => dispatch(viewChange(viewId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PricingDocuments);