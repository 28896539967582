import { createReducer } from 'reduxsauce';
import Types, { UtilityTypes } from 'actions/Types';
import { isResponseNull } from 'util/dataChecks';
import { ALL_TRANCHES, ALL_INVESTORS, DEFAULT_TIME_INTERVAL, DEFAULT_ALL, TIME_RANGES } from 'constants/orders';

export const INITIAL_STATE = {
  // both ECM & DCM
  dataReceived: null,
  orderData: [],
  historicalOrderData: {
    // [trancheId / dealId]: [...]
  },
  //
  // ECM only
  //
  shareDemandData: [],
  historicalShareDemandData: {
    // [dealId]: [...]  -> shareDemandData
  },
  externalSourceLastModifiedSummaryDataByShare: [],
  investorCountrySummaryDataByShare: [],
  investorTypeSummaryDataByShare: [],
  // [lowestDemandKey]: demandData
  historicalDemandSummaryByShareAndType: {
    // [dealId]: {
    //    [`${type}SummaryDataByShare`]: {
    //       lowestDemandKey, -> for selector
    //       demandData,      -> for lowest share
    //       typeData         -> data
    //    },
    // },
    // ...
  },
  //
  // DCM only
  //
  currentTrancheId: null,
  currentActiveOrderSummaryTab: null,
  tranches: [],
  investorNames: [],
  trancheLists: [],
  orderSummaryData: [],
  amendedOrderSummaryData: [],
  bookEvolutionSummary:[],
  bookEvolutionSummaryForAmendedOrderByInvestorTable: [],
  stages:[],
  //
  investorTypeSummaryData: [],
  investorCountrySummaryData: [],
  investorRegionSummaryData: [],
  historicalDemandSummaryByType: {
    // [trancheId]: {
    //    investorTypeSummaryData: data,
    //    investorCountrySummaryData: data,
    //    investorRegionSummaryData: data,
    // }
  },
  //
  // Fetching statuses
  //
  ordersReceived: false, // both
  shareDemandReceived: false, // ecm
  tranchesReceived: false, // dcm
  isAmended: false,
  trancheLoader: null,
  fullOrderBookSort: {
    column: "investorLongName",
    direction: "Ascending",
  },
  allTranchesFullOrderBookSort: {
    column: "externalSourceLastModified",
    direction: "Ascending",
  },

  modalSummary: [],
  isModalOpen: false,

  selectedInvestor: ALL_INVESTORS,
  selectedTranche: ALL_TRANCHES,
  selectedInterval: DEFAULT_TIME_INTERVAL, 
  selectedTimeRange: DEFAULT_ALL,
  selectedDate: DEFAULT_ALL,
  selectedInvestorType: DEFAULT_ALL,
  dataFetching: false,
  isBookEvolutionFiltererd: false,
  isOrderSummaryFiltererd: false,
  isSetStagesModalOpen:false
};

const dataFetching = (state, action) => ({
  ...state,
 dataFetching: action.dataFetching,
});

const receiveOrders = (state, action) => {
  const isNull = isResponseNull(action.orderData);
  const orderData = isNull ? [] : action.orderData;
  return Object.assign({}, state, {
    orderData,
    dateReceived: Date.now(),
    historicalOrderData: {
      ...state.historicalOrderData,
      [action.responseDetails.trancheId]: orderData,
    },
    ordersReceived: true,
  });
}

const includeAmendedOrders = (state, action) => ({
  ...state,
  isAmended: action.checked,
})

const receiveShareDemand = (state, action) => {
  const isNull = isResponseNull(action.shareDemandData);
  const shareDemandData = isNull ? [] : action.shareDemandData;

  return Object.assign({}, state, {
    shareDemandData,
    dateReceived: Date.now(),
    historicalShareDemandData: {
      ...state.historicalShareDemandData,
      [action.responseDetails.dealId]: shareDemandData,
    },
    shareDemandReceived: true,
  });
}

const receiveDemandSummaryByShareAndType = (state, action) => {
  const isNull = isResponseNull(action.responseData);
  const data = isNull ? [] : action.responseData;

  const lowestSharePrice = action.responseDetails.lowestSharePrice;
  const demandSummaryForLowestShare = isNull ? [] : action.responseData.find(x => x.sharePrice === lowestSharePrice);
  const lowestDemandKey = `${action.responseDetails.type}Lowest`;

  return Object.assign({}, state, {
    [action.responseDetails.type]: data,
    [lowestDemandKey]: demandSummaryForLowestShare,
    historicalDemandSummaryByShareAndType: {
      ...state.historicalDemandSummaryByShareAndType,
      [action.responseDetails.dealId]: {
        ...(state.historicalDemandSummaryByShareAndType[action.responseDetails.dealId] || {}),
        [action.responseDetails.type]: {
          lowestDemandKey,
          demandData: demandSummaryForLowestShare,
          typeData: data,
        }
      }
    }
  })
};

const receiveDemandSummaryByType = (state, action) => {
  const isNull = isResponseNull(action.responseData);
  const data = isNull ? [] : action.responseData;

  return Object.assign({}, state, {
    [action.responseDetails.type]: data,
    //
    historicalDemandSummaryByType: {
      ...state.historicalDemandSummaryByType,
      [action.responseDetails.trancheId]: {
        ...(state.historicalDemandSummaryByType[action.responseDetails.trancheId] || {}),
        [action.responseDetails.type]: data,
      },
    }
  });
}

const receiveTranches = (state, action) => {
  const isNull = isResponseNull(action.tranches);
  let data = {
    currentTrancheId: state.currentTrancheId,
    tranches: isNull ? [] : action.tranches,
    dateReceived: Date.now(),
    tranchesReceived: true,
    ordersReceived: isNull,
  }

  if (!data.tranches.some(t => t.trancheId === data.currentTrancheId)) {
    // tranche not available anymore
    data.currentTrancheId = null;
  }

  if (!data.currentTrancheId && !isNull) {
    // tranche was not selected / not available - select new one
    data.currentTrancheId = action.tranches[0].trancheId
  }

  // TODO => @JE clear historical data?

  return Object.assign({}, state, data);
}

const updateCurrentTranche = (state, { trancheId, dealType }) => {
  const isDCM = dealType === 'DCM';

  let otherFields;
  //
  // we have to extract summary for new tranche from historical data
  // ( always up to date - event notification 'smart' polling )
  //
  if (isDCM) {
    otherFields = {
      ...(state.historicalDemandSummaryByType[trancheId] || {})
    }
  } else {
    otherFields = {}
    const keys = Object.keys(state.historicalDemandSummaryByShareAndType[trancheId] || {});
    keys.forEach(type => {
      const typeObj = state.historicalDemandSummaryByShareAndType[trancheId][type];
      otherFields[type] = typeObj.typeData;
      otherFields[typeObj.lowestDemandKey] = typeObj.demandData;
    })
  }

  return Object.assign({}, state, {
    currentTrancheId: trancheId,
    // change orders or clean start
    orderData: state.historicalOrderData[trancheId] || [],
    // change data
    ShareDemand: state.historicalShareDemandData[trancheId] || [],
    // depending on dealtype change diff fields
    ...otherFields,
  });
}

const sortListData = (list) => {
  const tempList = JSON.parse(JSON.stringify(list));
  const tempListMap = [];
  for (var prop in tempList) {
    if (tempList.hasOwnProperty(prop)) {
      tempListMap.push({
        'key': prop,
        'value': tempList[prop],
        'label': tempList[prop],
      });
    }
  }
  return tempListMap.sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1);
}

const requestInvestorNames = (state, { investorNames }) => {
  const data = sortListData(investorNames);
  return Object.assign({}, state, {
    ...state,
    investorNames: data
  })
}

const requestTranchLists = (state, { trancheLists }) => {
  const data = sortListData(trancheLists);
  return Object.assign({}, state, {
    ...state,
    trancheLists: data
  })
}

const recieveOrderSummary = (state, action) => ({
  ...state,
  orderSummaryData: action.orderSummary
});

const changeFullOrderBookSort = (state, action) => ({
  ...state,
  fullOrderBookSort: {
    column: action.column,
    direction: action.direction,
  }
});

const openOrderSummaryDetailView = (state, action) => ({
  ...state,
  isModalOpen: !state.isModalOpen,
  modalSummary: action.summaryData,
})

const closeOrderSummaryDetailView = (state) => ({
  ...state,
  isModalOpen: false,
  modalSummary: [],
})

const selectOrderSummaryDropdown = (state, action) => ({
  ...state,
  selectedInvestor: action.selectedInvestor,
  selectedTranche: action.selectedTranche,
  selectedInterval: action.selectedInterval,
  selectedDate: action.selectedDate,
})
const selectAmendedOrderByInvestorDropDown = (state, action) => ({
  ...state,
  selectedInvestorType: action.selectedInvestorType,
  selectedTranche: action.selectedTranche,
  selectedInterval: action.selectedInterval,
  selectedDate: action.selectedDate,
  selectedTimeRange: action.selectedTimeRange,
});

const selectInvestorTypeDropdown = (state, action) => ({
  ...state,
  selectedInvestorType: action.selectedInvestorType,
})

const changeAllTranchesFullOrderBookSort = (state, action) => ({
  ...state,
  allTranchesFullOrderBookSort: {
    column: action.column,
    direction: action.direction,
  }
});

const requestUpdateOrderSummary = (state, action) => ({
  ...state,
  orderSummaryData: action.orderSummary,
  isOrderSummaryFiltererd: action.isFilter
})

const refreshFullOrderBookData = (state, action) => ({
  ...state,
  orderData: action.orderData
})

const requestUpdateBookEvolutionSummary = (state, action) => ({
  ...state,
  bookEvolutionSummary: action.bookEvolutionSummary,
  isBookEvolutionFiltererd: action.isFilter
})

const requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable = (state, action) => ({
  ...state,
  bookEvolutionSummaryForAmendedOrderByInvestorTable:
    action.bookEvolutionSummaryForAmendedOrderByInvestorTable,
  isBookEvolutionFiltererd: action.isFilter,
});

const requestUpdateStages = (state, action) => {
  return{
  ...state,
  stages:action.stages
}}

const setIsSetStagesModalOpen = (state, action) => {
  return{
  ...state,
  isSetStagesModalOpen: action.data
}}

const requestUpdateAmendedOrderSummary = (state, action) => ({
  ...state,
  amendedOrderSummaryData: action.amendedOrderSummaryData
})

const resetOrderBookDropDowns = (state) => ({
  ...state,
  selectedInvestor: ALL_INVESTORS,
  selectedTranche: ALL_TRANCHES,
  selectedInterval: DEFAULT_TIME_INTERVAL,
  selectedDate: DEFAULT_ALL,
  selectedTimeRange: TIME_RANGES[0],
  selectedInvestorType: DEFAULT_ALL
})

const getCurrentActiveSummaryTab = (state, action) => ({
  ...state,
  currentActiveOrderSummaryTab: action.activeTab
})

const changeLoderStatus = (state, action) => ({
  ...state,
  trancheLoader: action.value,
})

const ACTION_HANDLERS = {
  [Types.API_RECEIVE_ORDERS]: receiveOrders,
  [Types.API_RECEIVE_DEMAND_SUMMARY_BY_TYPE]: receiveDemandSummaryByType,
  [Types.API_RECEIVE_DEMAND_SUMMARY_BY_SHARE_AND_TYPE]: receiveDemandSummaryByShareAndType,
  [Types.API_RECEIVE_SHARE_DEMAND]: receiveShareDemand,
  [Types.API_RECEIVE_TRANCHES]: receiveTranches,
  [Types.API_RECEIVE_INVESTOR_NAMES]: requestInvestorNames,
  [Types.API_RECEIVE_TRANCHE_LISTS]: requestTranchLists,
  [Types.API_RECEIVE_ORDER_SUMMARY]: recieveOrderSummary,
  [Types.UPDATE_CURRENT_TRANCHE]: updateCurrentTranche,
  [Types.UPDATE_SELECTION_ORDER_SUMMARY]: requestUpdateOrderSummary,
  [Types.REFRESH_FULLORDERBOOK_DATA]: refreshFullOrderBookData,
  [Types.UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY]: requestUpdateBookEvolutionSummary,
  [Types.UPDATE_SELECTION_AMENDED_ORDER_SUMMARY]: requestUpdateAmendedOrderSummary,
  [Types.UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY_AMENDED_ORDER_BY_INVESTOR]:
    requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable,    
  [Types.UPDATE_STAGES]: requestUpdateStages,
  [Types.UPDATE_SET_STAGES_MODAL_OPEN]: setIsSetStagesModalOpen,
  [Types.CHANGE_TRANCHE_LOADER_STATUS]: changeLoderStatus,

  // Deal Cleanup
  [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE), changeFullOrderBookSort,
  [Types.CHANGE_FULL_ORDERBOOK_SORT]: changeFullOrderBookSort,
  [Types.CHANGE_ALL_TRANCHES_FULL_ORDERBOOK_SORT]: changeAllTranchesFullOrderBookSort,
  [Types.INCLUDE_AMENDED_ORDERS]: includeAmendedOrders,
  [Types.ORDER_SUMMARY_MODAL]: openOrderSummaryDetailView,
  [Types.CLOSE_ORDER_SUMMARY_MODAL]: closeOrderSummaryDetailView,
  [Types.SELECT_ORDER_SUMMARY_DROPDOWN]: selectOrderSummaryDropdown,
  [Types.SELECT_AMENDED_ORDER_BY_INVESTOR_DROPDOWN]: selectAmendedOrderByInvestorDropDown,
  [Types.SELECT_INVESTOR_TYPE]: selectInvestorTypeDropdown,
  [Types.RESET_ORDER_SUMMARY_DROPDOWN]: resetOrderBookDropDowns,
  [Types.DATAFETCHING]: dataFetching,
  [Types.CURRENT_ACTIVE_ORDER_SUMMARY_TAB]: getCurrentActiveSummaryTab,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
