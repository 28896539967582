import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

import ChartTableContainer from 'shared/components/ChartTableContainer';

import {
    getInvestorFeedbackChartDataByChart,
    getInvestorFeedbackHighlightsByTheme,
    getSelectedChartSubTab
} from 'selectors';
import { selectChartSubTab } from 'actions/InvestorFeedbackActions';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

// import { featurePageIds } from 'constants/featurePages';

import Buttons from './shared/Buttons';
import NoContent from './shared/NoContent';

import ThemeDistributionTable from './tables/ThemeDistributionTable';
import ThemeDistributionChart from './charts/ThemeDistributionChart';
import FeedbackHighlightContainer from './charts/ThemeDistributionChart/FeedbackHighlightContainer';

class ThemeDistributionTabContent extends PureComponent {
    buttonClicked = (index, name) => {
        this.props.selectChartSubTab(index, name);

        this.props.themeDistributionTabEvent(name);
    }

    render() {
        const { chartData } = this.props;

        const renderChart = (chartData[this.props.activeIndex] && chartData[this.props.activeIndex].series) || false;
        const themeTypeId = this.props.activeIndex + 1;

        const highlightData = this.props.getHighlightData(themeTypeId); // Related to order of pages
        const theme = this.props.themeTypes
            .filter((themeType) => themeType.themeTypeId === themeTypeId)[0];

        const barHeight = window.innerWidth >= 1024 ? 450 : 545;

        let dataSetChart;
        let dataKeys;

        if (renderChart) {
            const dataSet = chartData[this.props.activeIndex].categoryAxis.categoryLabel
                .map((label) => {
                    const splitLabel = label.split('-');
                    return {
                        name: splitLabel[0],
                        bottomText: splitLabel[1] || '',
                    };
                });

            dataKeys = [];
            dataSetChart = dataSet.map((dataObj, ind) => {
                const chartObj = {};
                // Always put category summary first to prevent additional loops through series
                chartData[this.props.activeIndex].series.sort((a, b) => {
                    if (a.seriesType === 'CategorySummary') {
                        return -1;
                    } else if (b.seriesType === 'CategorySummary') {
                        return 1;
                    } else {
                        const aVal = a.data[0] ? a.data[0].value : 0;
                        const bVal = b.data[0] ? b.data[0].value : 0;
                        if (aVal > bVal) {
                            return -1;
                        } else if (aVal < bVal) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                });

                chartData[this.props.activeIndex].series.forEach((series) => {
                    if (series.seriesType === 'CategorySummary') {
                        // Top Labels
                        chartObj.topText = series.data[ind] ? series.data[ind].value : null;
                    } else {
                        // Find total for each index
                        let total = 0;
                        chartData[this.props.activeIndex].series.forEach((seriesForTotal) => {
                            if (seriesForTotal.seriesType !== 'CategorySummary') {
                                total += seriesForTotal.data[ind] ? seriesForTotal.data[ind].value : 0;
                            }
                        });

                        // convert to %
                        chartObj[series.seriesTitle] = series.data[ind] ? ((series.data[ind].value / total) * 100) : null;

                        if (ind === 0) {
                            dataKeys.push({
                                key: series.seriesTitle,
                                value: series.seriesTitle
                            });
                        }
                    }
                });

                return {
                  ...dataObj,
                  ...chartObj
                };
            });
        }

        if (renderChart) {
            return (
                <React.Fragment>
                    <Buttons
                      style={{ marginRight: 250, zIndex: 50 }}
                        activeIndex={this.props.activeIndex}
                        chartNames={this.props.chartNames}
                        buttonClicked={this.buttonClicked}
                    />
                    <ChartTableContainer
                      table={
                        <ThemeDistributionTable key={this.props.chartNames[this.props.activeIndex]} series={this.props.chartData[this.props.activeIndex]} />
                      }
                      buttonStyle={{
                        top: '61px',
                        right: '37px'
                      }}
                      tableTitle={
                        <TranslationText
                          dictionaryKey={"investor_feedback.distribution_chart_title"}
                          replace={[
                        	["<theme_type>", translationString(mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]), this.props.language, mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]))]
                          ]}
                          defaultText={`Distribution of ${this.props.chartNames[this.props.activeIndex]} Themes by Date`}
                        />
                      }
                    >
                      <div id="legendTitle" aria-hidden />
                      <div style={{ marginTop: -134 }} aria-hidden>
                        <ThemeDistributionChart
                            marginTop="60"
                            feedbackChart
                            xLegend
                            keyTitle={this.props.chartNames[this.props.activeIndex]}
                            legendTitle="Theme Distribution"
                            height={barHeight}
                            data={dataSetChart}
                            keys={dataKeys.slice(0).reverse()}
                            hasTarget={false}
                            legendHeight="60"
                        />
                      </div>
                    </ChartTableContainer>
                    <FeedbackHighlightContainer
                        theme={theme}
                        highlightData={highlightData}
                    />
                </React.Fragment>
            )
        }

        return <NoContent />;
    }
}

export default compose(
    withLanguage,
    withAnalytics((ev) => ({
        themeDistributionTabEvent: title => ev(events.THEME_DISTRIBUTION_TAB_BUTTON(title.toLowerCase())),
    })),
    connect(
        (state, ownProps) => {
            const chartData = getInvestorFeedbackChartDataByChart(state, `feedbackChartData${ownProps.chartDetails.chartId}`);
            return ({
                // sub tab handling
                activeIndex: getSelectedChartSubTab(state),
                // data
                chartData,
                chartNames: chartData.map(c => c.pageTitle),
                // needed?
                themeTypes: state.investorFeedbackData.feedbackThemeTypes,
                getHighlightData: (theme) => getInvestorFeedbackHighlightsByTheme(state, `themeHighlight${theme}`),
            })
        },
        dispatch => ({
            selectChartSubTab: (index, title) => dispatch(selectChartSubTab(index, title)),
        }),
    ),
)(ThemeDistributionTabContent);
