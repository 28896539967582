import React, { PureComponent } from "react";
import styled from "styled-components";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fontColors } from "shared/styles/variables/theme";
import { applyIsvCloseBanner, handleConfirmationDialogue } from "Isv/actions/IsvInformationBannerAction";
import FileUploadOverlay from 'Isv/components/FileUpload/FileUploadOverlays/FileUploadOverlay';
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import IsvInformationBanner from "Isv/components/InformationBanner/InformationBanner"
import DealInformationTable from './DealInformationTable'
import ConfirmationDialog from 'Isv/components/common/ConfirmationDialog';
import {
  viewDocument,
  handleOpenUploadOverlay,
  deleteSupportingDocument,
  handleSubmitDealInformation,
  handeFinalSubmission,
  handleCommentsPopup,
  getLatestCommentsByCommentId,
  handleGetLatestCommentsByCommentsId,
  downloadAllDocuments,
  handleReferenceInformationStatus
} from 'Isv/actions/IsvDocumentsAction';
import {
  getIsvCurrentDealId,
  getIsvCurrentDeal,
  dealInformationBannerReducer,
  isIsvExternalUser,
  selectConfirmationBannerStatus,
  selectDocumentDeleteId,
  selectLatestCommentsDataForCurrentDeal,
  selectIsvTableColumnWidth,
  dataFetching
} from "Isv/selectors";
import { PrimaryButton, SecondarySlateButton, SecondaryButton } from 'mk-pattern-library-react';
import { primaryBtnStyle, defaultBtnStyle } from 'Isv/styles/buttonStyle';
import DealInformationCommentBoxContainer from './DealInformationCommentBoxContainer';
import { browserHistory } from 'react-router';
import {
  WORKFLOW_STATUS,
  ISV_DOCUMENT_REVIEW_STATUS,
  NEXT_ACTION_OWNER,
  REJECTED_STATUS,
  ISV_DOCUMENT_TYPE
} from 'Isv/constants/isvDocumentReviewStatus'
import Loading from 'shared/components/Loading';

const Header = styled.h2`
  font-size: 26px;
  color: ${fontColors.black};
  font-weight: 600;
`;

const InformationButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content : flex-end;
  width: 100%
  button {
    &:not(:first-child) {
      margin-left: 20px;
    }
    
  }
`
const CommentStyle = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 5px;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
`

const DocumentActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const AdditionalCommentContainer = styled.div`
  max-width: ${(props) => (props.isClientData ? props.width - 14 : props.width) + 'px'};
  width: 100%;
`

const AdditonalCommentHeader = styled.span`
  font-weight: 600;
  font-size: 12px;
`
const LoadingContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

class DealInformation extends PureComponent {

  state = {
    isSupportingUploadButtonDisabled: false,
    documentStatusrole: this.props.isIsvCsmUser ? 'isCsmApproved' : 'isRrApproved',
    isFinalReviewCompleted: false,
  }

  viewDocument = (documentId, title, fileName, dealDocumentId) => {
    this.props.viewDocument(documentId, title, fileName, dealDocumentId);
  }

  CheckReviewSubmitted = (dealDocuments) => {
    let isSubmitReviewButtonDisabled = true;
    dealDocuments && dealDocuments.forEach(doc => {
      isSubmitReviewButtonDisabled = doc.document.clientDocumentFile.length ? false: true;
    });
    return isSubmitReviewButtonDisabled;
  }

  CheckSubmitEnabled = (dealDocuments, nextAction) => {
    let count = 0, isSubmitButtonDisabled;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (dealDocuments && dealDocuments.clientDocumentFile) {
      dealDocuments.clientDocumentFile.forEach(doc => {
        !doc[this.state.documentStatusrole] && count++;
      });
      isSubmitButtonDisabled = dealDocuments && dealDocuments.status && dealDocuments.status.nextAction !== nextAction ? true : count >= 1;
    }
    return isSubmitButtonDisabled;
  }

  handleSubmitDealInformation = (dealId, label) => {
    const { selectedDealData, isIsvCsmUser } = this.props;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if ((selectedDealData && isIsvCsmUser && selectedDealData.status && 
      selectedDealData.status.statusName !== "Completed" && selectedDealData.status.nextAction === "CSM") && 
      ((selectedDealData.isFinalSubmission && selectedDealData.maturityDate == null) || 
      (selectedDealData.maturityDate !== null && currentDate > new Date(selectedDealData.maturityDate)))
    ) {
      browserHistory.push({ pathname: "/", state: { fromDealInformation: true } });
    }    
    else {
      this.props.handleSubmitDealInformation(dealId, label)
    }
  }

  enableDisableInformationBanner = (selectedDealData) => {
    return selectedDealData && selectedDealData.status && 
    ((this.props.isIsvExternalUser && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT) || 
      (this.props.isIsvCsmUser && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CSM) ||(this.props.isIsvRRTeamUser && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.RR_TEAM))   
  }

  CheckInformationBannerText = () => {
    const { selectedDealData } = this.props;
    if (this.props.isIsvExternalUser){
      let status = selectedDealData && selectedDealData.status &&
        selectedDealData.status.workflowName === WORKFLOW_STATUS.DRAFT_ECB_SUBMISSION
          ? "Please upload all information prior to submitting for HSBC review.  If uploading additional information not assigned to your deal, please use “Upload” button at the bottom of the screen, if uploading for information assigned to your deal, please use the upload icon in “Download/Upload/Delete” column."
          : selectedDealData && selectedDealData.status &&
            selectedDealData.status.workflowName === WORKFLOW_STATUS.FINAL_ECB_SUBMISSION &&
            selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.PENDING_ONE_WAY_LETTER
          ? "Please upload all information (signed where required) for HSBC review."
          : "";
      return status;
    }
    else if (this.props.isIsvRRTeamUser)
      return "Please ensure you review each information prior to approving/rejecting.  If rejecting, a comment is required to be added.  You can view the information by clicking on the title column.";
    else
      return "Please ensure you review each information prior to approving/rejecting. If rejecting, a comment is required to be added. You can view the information by clicking on the title. To upload information relating to the template, please use the upload icon in Upload column. To delete information from the deal, please use the delete icon in Delete column.";
  }

  handleUploadSubmitButton = (deleteDocumentId, isFinalReviewCompleted, isCSMFinalSubmission) => {
    if (isFinalReviewCompleted) {
      this.handleSubmitDealInformation(this.props.dealId, 'SubmitToRBI');
      this.setState({
        isFinalReviewCompleted: false
      });
      this.props.handleConfirmationDialogue(false);
    } else if (isCSMFinalSubmission) {
      this.props.handleSubmitDealInformation(this.props.dealId, 'SendToDraft');
      this.props.handleConfirmationDialogue(false);
    } else {
      this.props.handleConfirmationDialogue(false);
      deleteDocumentId ? this.props.deleteSupportingDocument(deleteDocumentId) : this.handleSubmitDealInformation(this.props.dealId, NEXT_ACTION_OWNER.CLIENT);
    }
  }

  handleDeleteConfirmationDialogue = (isOpen, deleteDealDocumentId) => {
    this.props.handleConfirmationDialogue(isOpen, deleteDealDocumentId);
  }

  enableSubmitForReviewButton = (selectedDealData) => {
    return selectedDealData && selectedDealData.isClientSubmit && selectedDealData.status && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT && !selectedDealData.status.ecB2ReturnStatus;
  }

  areAllFinalDocsUploaded = (selectedDealData) => {
    let isdocsUploaded = false
    if(selectedDealData.isFinalSubmission) {
      isdocsUploaded = selectedDealData.clientDocumentFile.some((document) => document.fileUploadWorkflow !== "final")
    }
    return isdocsUploaded
  }

  isRejectButtonEnabled = (selectedDealData, approver, rejectUser) => {
    let count = 0;
    const documentCount = selectedDealData && selectedDealData.clientDocumentFile;
    documentCount && documentCount.length && 
    documentCount.forEach(doc => {
      doc[approver] === null && count++;
    });
    return Boolean(count) || !(selectedDealData.status && selectedDealData.status.nextAction === rejectUser)
  }

  enableClientUploadButton = (selectedDealData) => {
    return selectedDealData && selectedDealData.status && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT;
  }

  handleRejectInformation = (dealId, rejectLabel) => {
    this.props.handleSubmitDealInformation(dealId, rejectLabel);
  }

  handeFinalSubmission = (dealId, isFinalSubmission, finalReview) => {
    if (finalReview) {
      this.props.handleConfirmationDialogue(true);
      this.setState({ isFinalReviewCompleted: true })
    } else this.props.handeFinalSubmission(dealId, isFinalSubmission);
  }

  checkIsFinalReviewCompleted = (selectedDealData) => {
    return selectedDealData && (selectedDealData.status && selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.SUBMITTED_TO_RBI && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.RR_TEAM)
  }

  getConfirmationDialogueSubheader = (deleteDocumentId, isFinalReviewCompleted, isFinalSubmission, selectedDealData) => {
    const subHeader = deleteDocumentId ?
      'Are you sure you want to delete this information?' :
      isFinalReviewCompleted ?
      'Final review completed. Please download all information using the Download All button and submit to RBI.' :
      isFinalSubmission ?
      (selectedDealData.status.workflowName === WORKFLOW_STATUS.FINAL_ECB_SUBMISSION && selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.PENDING_FIRST_REVIEW) ?
      'You are sending this deal to Draft ECB Submission workflow. You will need to complete Draft ECB Submission workflow before performing Final ECB Submission workflow again.' :
      'Information will be submitted for review. Do you want to continue?' :
      'Information has now been submitted for 1st internal review.'
    return subHeader;
  }

  render() {

    const {
      dealId,
      isOpen,
      selectedDealData,
      handleCloseBanner,
      isDealInformationBannerOpen,
      handleOpenUploadOverlay,
      isIsvExternalUser,
      handleConfirmationDialogue,
      deleteDocumentId,
      isIsvCsmUser,
      isIsvRRTeamUser,
      latestCommentsDataForCurrentDeal,
      downloadAllDocuments,
      titleTableColumnWidth,
      handleReferenceInformationStatus
    } = this.props;
    handleReferenceInformationStatus(false);
    const { isFinalReviewCompleted } = this.state;
    const lastComment =
    latestCommentsDataForCurrentDeal &&
    latestCommentsDataForCurrentDeal.length > 0 &&
    latestCommentsDataForCurrentDeal.find(
      (item) => item.title === 'Additional Comment' && item.dealId === selectedDealData.dealId
    );
    const isFinalSubmission = selectedDealData && selectedDealData.isFinalSubmission;
    const isFinalCSMSubmission = selectedDealData && selectedDealData.status && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CSM && selectedDealData.status.workflowName === WORKFLOW_STATUS.FINAL_ECB_SUBMISSION && selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.PENDING_FIRST_REVIEW;
    return (
      <>
       {this.props.loading ? (
          <LoadingContainer>
            <LoadingWrapper>
              <Loading hasBackground={false} />
            </LoadingWrapper>
          </LoadingContainer>
        ) : null}
        <Header>Deal Information</Header>
        {isDealInformationBannerOpen && this.enableDisableInformationBanner(selectedDealData) &&
          <IsvInformationBanner
            bannerHeading={!isIsvExternalUser
              ? "Information Review."
              : selectedDealData && selectedDealData.status &&
                selectedDealData.status.workflowName === WORKFLOW_STATUS.DRAFT_ECB_SUBMISSION
              ? "Draft submission of information."
              : selectedDealData && selectedDealData.status &&
                selectedDealData.status.workflowName === WORKFLOW_STATUS.FINAL_ECB_SUBMISSION
              ? "Final submission of information."
              : ""
            }
            bannerText={this.CheckInformationBannerText()}
            handleCloseBanner={handleCloseBanner}
            onCloseOverlay="isDealInformationBannerOpen"
          />
        }
        <FileUploadOverlay />

        <DealInformationTable
          selectedDealData={selectedDealData}
          viewDocument={this.viewDocument}
          handleOpenUploadOverlay={handleOpenUploadOverlay}
          handleDeleteConfirmationDialogue={this.handleDeleteConfirmationDialogue}
        />

        <DocumentActionContainer>
          <AdditionalCommentContainer width={titleTableColumnWidth} isClientData={selectedDealData && selectedDealData.clientDocumentFile && selectedDealData.clientDocumentFile.length}>
            <AdditonalCommentHeader>Additional Comment</AdditonalCommentHeader>
            <CommentStyle>
              <DealInformationCommentBoxContainer
                selectedDealData={selectedDealData}
                title={'Additional Comment'}
                lastComment={lastComment}
                isAdditionalComment={true}
              />
            </CommentStyle>
          </AdditionalCommentContainer>

          <InformationButtonContainer>
            {isIsvExternalUser &&
              <>
                {selectedDealData && selectedDealData.isFinalSubmission !== true &&
                  <SecondarySlateButton
                    size="normal"
                    id="upload-supporting-document-button"
                    disabled={!this.enableClientUploadButton(selectedDealData)}
                    style={primaryBtnStyle}
                    onClick={() => handleOpenUploadOverlay(true)}
                  >
                    Supporting Document Upload
                  </SecondarySlateButton>
                }
                <PrimaryButton
                  size="normal"
                  id="submit-for-review-button"
                  disabled={!this.enableSubmitForReviewButton(selectedDealData) || this.areAllFinalDocsUploaded(selectedDealData) }
                  style={primaryBtnStyle}
                  onClick={() => handleConfirmationDialogue(true)}
                >
                  Submit for Review
                </PrimaryButton>
              </>
            }
            {isIsvCsmUser &&
              <>
              {
                isFinalSubmission && 
                <SecondaryButton
                  size="normal"
                  id="send-to-draft-button"
                  disabled={!isFinalCSMSubmission}
                  style={defaultBtnStyle}
                  onClick={() => handleConfirmationDialogue(true)}
                >
                  Send to Draft Workflow
                </SecondaryButton>
              }
                <SecondarySlateButton
                  size="normal"
                  id="reject-to-client-button"
                  disabled={this.isRejectButtonEnabled(selectedDealData, 'isCsmApproved', NEXT_ACTION_OWNER.CSM)}
                  style={primaryBtnStyle}
                  onClick={() => this.handleRejectInformation(dealId, REJECTED_STATUS.REJECTED_BY_CSM)}
                >
                  Reject To Client
                </SecondarySlateButton>
                <PrimaryButton
                  size="normal"
                  id="submit-to-rr-team-button"
                  disabled={this.CheckSubmitEnabled(selectedDealData, NEXT_ACTION_OWNER.CSM) ||
                    (selectedDealData && ((selectedDealData.clientDocumentFile && selectedDealData.clientDocumentFile.length > 0 &&
                    selectedDealData.clientDocumentFile.filter((x) => x.isClientSupportingDocument !== true).length) !==
                    (selectedDealData.dealDocumentMapping && selectedDealData.dealDocumentMapping.filter((doc => doc && doc.document.category && doc.document.category.documentCategoryName !== ISV_DOCUMENT_TYPE.ECB_RETURN_DOCUMENT)).length )))
                }
                  style={primaryBtnStyle}
                  onClick={() => this.handleSubmitDealInformation(dealId, NEXT_ACTION_OWNER.CSM)}
                >
                  Submit to RR Team
                </PrimaryButton>
              </>
            }
            {isIsvRRTeamUser &&
              <>
                {
                  isFinalSubmission &&
                  <SecondarySlateButton
                    size="normal"
                    id="download-all-button"
                    disabled={!this.checkIsFinalReviewCompleted(selectedDealData)}
                    style={primaryBtnStyle}
                    onClick={() => downloadAllDocuments(dealId, selectedDealData && selectedDealData.referenceId)}
                  >
                    Download All
                  </SecondarySlateButton>
                }
                <SecondarySlateButton
                  size="normal"
                  id="reject-to-csm-button"
                  disabled={this.isRejectButtonEnabled(selectedDealData, 'isRrApproved', NEXT_ACTION_OWNER.RR_TEAM) || this.checkIsFinalReviewCompleted(selectedDealData)}
                  style={primaryBtnStyle}
                  onClick={() => this.handleRejectInformation(dealId, REJECTED_STATUS.REJECTED_BY_RR)}
                >
                  Reject To CSM
                </SecondarySlateButton>

                <PrimaryButton
                  size="normal"
                  id="final-submit-button"
                  disabled={this.CheckSubmitEnabled(selectedDealData, NEXT_ACTION_OWNER.RR_TEAM) || this.checkIsFinalReviewCompleted(selectedDealData)}
                  style={primaryBtnStyle}
                  onClick={() => this.handeFinalSubmission(dealId, true, isFinalSubmission)}
                >
                  {isFinalSubmission ? 'Complete Review' : 'Client Final Submission'}
                </PrimaryButton>
              </>
            }
          </InformationButtonContainer>
        </DocumentActionContainer>
       
        <ConfirmationDialog
          open={isOpen}
          confirmButtonText={isFinalCSMSubmission ? 'Continue' : isFinalSubmission && selectedDealData && selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT ? 'Yes' : 'OK'}
          declineButtonText = {
            (deleteDocumentId || (isFinalSubmission && selectedDealData && ((selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT || isFinalCSMSubmission)))) ? 'Cancel' : ''
          }
          header={isFinalReviewCompleted ? '' : 'Warning'}
          subHeader={this.getConfirmationDialogueSubheader(deleteDocumentId, isFinalReviewCompleted, isFinalSubmission, selectedDealData)}
          onConfirmClick={() => this.handleUploadSubmitButton(deleteDocumentId, isFinalReviewCompleted, isFinalCSMSubmission)}
          isCancelButtonNotRequired={(deleteDocumentId || (isFinalSubmission && selectedDealData && (selectedDealData.status.nextAction === NEXT_ACTION_OWNER.CLIENT || isFinalCSMSubmission))) ? false : true}
          onDeclineClick={() => handleConfirmationDialogue(false)}
        />
      </>
    )
  }
}

export default compose(
  connect(
    (state) => ({
      selectedDealData: getIsvCurrentDeal(state),
      isDealInformationBannerOpen: dealInformationBannerReducer(state),
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvCsmUser: isIsvCsmUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      isOpen: selectConfirmationBannerStatus(state),
      deleteDocumentId: selectDocumentDeleteId(state),
      dealId: getIsvCurrentDealId(state),
      latestCommentsDataForCurrentDeal: selectLatestCommentsDataForCurrentDeal(state),
      titleTableColumnWidth: selectIsvTableColumnWidth(state),
      loading: dataFetching(state),
    }),
    (dispatch) => ({
      handleCloseBanner: (label) => {
        dispatch(applyIsvCloseBanner(label))
      },
      handleOpenUploadOverlay: (isOpen, document, title) => {
        dispatch(handleOpenUploadOverlay(isOpen, document, title))
      },
      handleConfirmationDialogue: (value, isopen) => {
        dispatch(handleConfirmationDialogue(value, isopen))
      },
      viewDocument: (documentId, title, fileName, dealDocumentId) => {
        dispatch(viewDocument(documentId, title, fileName, dealDocumentId))
      },
      deleteSupportingDocument: (id) => {
        dispatch(deleteSupportingDocument(id))
      },
      handleSubmitDealInformation: (dealId, status) => {
        dispatch(handleSubmitDealInformation(dealId, status))
      },
      handeFinalSubmission: (dealId, isFinalSubmission) => {
        dispatch(handeFinalSubmission(dealId, isFinalSubmission))
      },
      handleCommentsPopup: (isOpen, isAdditonalComment, rejectLabel) => {
        dispatch(handleCommentsPopup(isOpen, isAdditonalComment, rejectLabel))
      },
      getLatestCommentsByCommentId: (commentId, isClientScreen) => {
        dispatch(getLatestCommentsByCommentId(commentId, isClientScreen));
      },
      handleGetLatestCommentsByCommentsId: (data) => {
        dispatch(handleGetLatestCommentsByCommentsId(data))
      },
      downloadAllDocuments: (dealId, referenceId) => {
        dispatch(downloadAllDocuments(dealId, referenceId))
      },
      handleReferenceInformationStatus: (status) => {
        dispatch(handleReferenceInformationStatus(status))
      }
    })
  )
)(DealInformation);
