import Types from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';

const receivePricingDocumentId = (data = {}) => ({
  type: Types.API_RECEIVE_PRICING_DOCUMENT_ID,
  id: data.documentVersionId,
});
const requestPricingDocumentId = (dealId, loader) => ({
    type: Types.API_REQUEST_PRICING_DOCUMENT_ID,
    endPoint: `/v1/Pricing/Document/${dealId}`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receivePricingDocumentId,
    loader,
    //
    feature: dealFeatures.PRICING,
});

export {
  receivePricingDocumentId,
  requestPricingDocumentId,
};
