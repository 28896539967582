import Types from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';
import * as tiles from 'constants/homeTiles';

const receiveOrders = ({ data }, responseDetails) => ({
    type: Types.API_RECEIVE_ORDERS,
    orderData: data,
    responseDetails
});
// DCM
const requestOrders = (trancheId, loader) => ({
    type: Types.API_REQUEST_ORDERS,
    endPoint: `/v1/tranches/${trancheId}/orders`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveOrders,
    loader,
    tile: tiles.HOME_ORDERS_TILE,
    feature: dealFeatures.ORDERBOOK,
    //
    responseDetails: {
        trancheId,
    },
});

// DCM
const requestOrdersForAllTranches = (dealId, loader) => ({
    type: Types.API_REQUEST_ORDERS_FOR_ALL_TRANCHES,
    endPoint: `/v1/Orderbook/Deals/${dealId}/FullOrderBook`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveOrders,
    loader,
    tile: tiles.HOME_ORDERS_TILE,
    feature: dealFeatures.ORDERBOOK,
    responseDetails: {
        trancheId: 'aT',
    }
});

// ECM
const requestEcmOrders = (dealId, loader) => ({
    type: Types.API_REQUEST_ECM_ORDERS,
    endPoint: `/v1/deals/${dealId}/orders`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveOrders,
    loader,
    tile: tiles.HOME_ORDERS_TILE,
    feature: dealFeatures.ORDERBOOK,
    //
    responseDetails: {
        trancheId: dealId,
    },
});

// ECM
const receiveShareDemand = ({ data }, responseDetails) => ({
    type: Types.API_RECEIVE_SHARE_DEMAND,
    shareDemandData: data,
    responseDetails
});
const requestShareDemand = (dealId, loader) => ({
    type: Types.API_REQUEST_SHARE_DEMAND,
    endPoint: `/v1/deals/${dealId}/shareDemand`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveShareDemand,
    loader,
    //
    feature: dealFeatures.ORDERBOOK,
    responseDetails: {
        dealId,
    },
});

// ECM
const receiveDemandSummaryByShareAndType = ({ data }, responseDetails) => ({
    type: Types.API_RECEIVE_DEMAND_SUMMARY_BY_SHARE_AND_TYPE,
    responseData: data,
    responseDetails,
});
const requestDemandSummaryByShareAndType = (type, dealId, lowestSharePrice, maxSize, loader) => ({
    type: Types.API_REQUEST_DEMAND_SUMMARY_BY_SHARE_AND_TYPE,
    endPoint: `/v1/deals/${dealId}/shareDemandSummaryBySharePrice?query.groupByColumnName=${type}${maxSize ? '&query.maxGroupBySize=' + maxSize : ''}`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveDemandSummaryByShareAndType,
    responseDetails: {
        dealId,
        type: `${type}SummaryDataByShare`,
        lowestSharePrice,
    },
    loader,
    //
    feature: dealFeatures.ORDERBOOK,
});

// DCM
const receiveDemandSummaryByType = ({ data }, responseDetails) => ({
    type: Types.API_RECEIVE_DEMAND_SUMMARY_BY_TYPE,
    responseData: data,
    responseDetails,
});
const requestDemandSummaryByType = (type, dealId, currentTrancheId, maxSize, loader) => ({
    type: Types.API_REQUEST_DEMAND_SUMMARY_BY_TYPE,
    endPoint: `/v1/deals/${dealId}/tranches/${currentTrancheId}/demandSummaryByTranche?query.groupByColumnName=${type}${maxSize ? '&query.maxGroupBySize=' + maxSize : ''}`,
    params: {},
    typeName: `${type}SummaryData`,
    ajaxType: 'GET',
    onSuccess: receiveDemandSummaryByType,
    responseDetails: {
        type: `${type}SummaryData`,
        trancheId: currentTrancheId,
    },
    loader,
    //
    feature: dealFeatures.ORDERBOOK,
});

const receiveTranches = ({ data }) => ({
    type: Types.API_RECEIVE_TRANCHES,
    tranches: data,
});
const requestTranches = (dealId, loader) => ({
    type: Types.API_REQUEST_TRANCHES,
    endPoint: `/v1/deals/${dealId}/tranches`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveTranches,
    loader,
    feature: dealFeatures.ORDERBOOK,
});

const receiveInvestorNames = ({ data }) => ({
    type: Types.API_RECEIVE_INVESTOR_NAMES,
    investorNames: data
})

const requestInvestorNames = (dealId, loader) => ({
    type: Types.API_REQUEST_INVESTOR_NAMES,
    endPoint: `/v1/Orderbook/Deals/${dealId}/Investors`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveInvestorNames,
    loader,
    feature: dealFeatures.ORDERBOOK,
});

const receiveTranchLists = ({ data }) => ({
    type: Types.API_RECEIVE_TRANCHE_LISTS,
    trancheLists: data
})

const requestTranchLists = (dealId, loader) => ({
    type: Types.API_REQUEST_TRANCHE_LISTS,
    endPoint: `/v1/Orderbook/Deals/${dealId}/Tranches`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveTranchLists,
    loader,
    feature: dealFeatures.ORDERBOOK,
});

const receiveOrderSummary = ({ data }) => ({
    type: Types.API_RECEIVE_ORDER_SUMMARY,
    orderSummary: data,
});

const requestOrderSummary = (dealId, intvl, investorId, trancheId, loader) => ({
    type: Types.API_REQUEST_ORDER_SUMMARY,
    endPoint: `/v1/Orderbook/Deals/${dealId}/${intvl}/${investorId}/${trancheId}/OrderSummary`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveOrderSummary,
    loader,
    feature: dealFeatures.ORDERBOOK,
});

const requestUpdateOrderSummary = (orderData, isFilter) => ({
    type: Types.UPDATE_SELECTION_ORDER_SUMMARY,
    orderSummary: orderData.data,
    isFilter: isFilter
})

const requestUpdateFullOrderBook = (fullOrderBookData) => ({
    type: Types.REFRESH_FULLORDERBOOK_DATA,
    orderData: fullOrderBookData.data
})

const requestUpdateAmendedOrderSummary = (amendedOrderSummaryData) => ({
    type: Types.UPDATE_SELECTION_AMENDED_ORDER_SUMMARY,
    amendedOrderSummaryData: amendedOrderSummaryData.data
})

const setIsSetStagesModalOpen = (isSetStagesModalOpen) => ({
    type: Types.UPDATE_SET_STAGES_MODAL_OPEN,
    data: isSetStagesModalOpen
})

const requestUpdateBookEvolutionSummary = (orderData,isFilter) => ({
    type: Types.UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY,
    bookEvolutionSummary: orderData.data,
    isFilter: isFilter
})

const requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable = (orderData,isFilter) => ({
    type: Types.UPDATE_SELECTION_BOOK_EVOLUTION_SUMMARY_AMENDED_ORDER_BY_INVESTOR,
    bookEvolutionSummaryForAmendedOrderByInvestorTable: orderData.data,
    isFilter: isFilter
})

const requestUpdateStages = (data) => ({
    type: Types.UPDATE_STAGES,
    stages: data.data,
})

const dataFetching = (dataFetching) => ({
    type: Types.DATAFETCHING,
    dataFetching,
  });

const updateCurrentTranche = (trancheId, dealType) => ({
    type: Types.UPDATE_CURRENT_TRANCHE,
    trancheId,
    dealType,
});

const filterOrderSummary = (dealId, investorId, trancheId, interval, isFilter) => ({
    type: Types.UPDATE_ORDER_SUMMARY,
    endPoint: `/v1/Orderbook/Deals/${dealId}/${interval}/${investorId}/${trancheId}/OrderSummary`,
    ajaxType: 'GET',
    dealId,
    investorId,
    trancheId,
    interval,
    isFilter
});

const refreshFullOrderBook = (dealId) => ({
    type: Types.UPDATE_FULL_ORDERBOOK,
    endPoint: `/v1/Orderbook/Deals/${dealId}/FullOrderBook`,
    ajaxType: 'GET',
    dealId,
});

const filterBookEvolutionSummary = (dealId, investorId, trancheId, interval, isFilter) => ({
    type: Types.UPDATE_BOOK_EVOLUTION_SUMMARY,
    endPoint: `/v1/Orderbook/Deals/${dealId}/${interval}/${investorId}/${trancheId}/BookEvolution`,
    ajaxType: 'GET',
    dealId,
    investorId,
    trancheId,
    interval,
    isFilter
});

const filterBookEvolutionSummaryForAmendedOrderByInvestor = (dealId, interval, investorId, trancheId, isFilter) => ({
    type: Types.UPDATE_BOOK_EVOLUTION_SUMMARY_FOR_AMENDED_ORDER_BY_INVESTOR,
    endPoint: `/v1/Orderbook/Deals/${dealId}/${interval}/${investorId}/${trancheId}/BookEvolutionByInvestor`,
    ajaxType: 'GET',
    dealId,
    investorId,
    trancheId,
    interval,
    isFilter
});

const requestStages = (dealId) => ({
    type: Types.API_RECEIVE_STAGES,
    endPoint: `/v1/Orderbook/Deals/${dealId}/GetBookEvolutionStage`,
    ajaxType: 'GET',
    dealId
});


const updateStages = (dealId, stagesDataArray) => ({
    type: Types.API_UPDATE_STAGES,
    endPoint: `/v1/Orderbook/Deals/${dealId}/UpdateBookEvolutionStage`,
    ajaxType: "POST",
    params: stagesDataArray,
    dealId,
  });

const filterAmendedOrderSummary = (dealId, investorId, trancheId) => ({
    type: Types.UPDATE_AMENDED_ORDER_SUMMARY,
    endPoint: `/v1/Orderbook/Deals/${dealId}/${investorId}/${trancheId}/AmendedOrderSummaryByInvestor`,
    ajaxType: 'GET',
    dealId,
    investorId,
    trancheId,
})

const changeFullOrderBookSort = (column, direction) => ({
    type: Types.CHANGE_FULL_ORDERBOOK_SORT,
    direction,
    column,
});

const changeAllTranchesFullOrderBookSort = (column, direction) => ({
    type: Types.CHANGE_ALL_TRANCHES_FULL_ORDERBOOK_SORT,
    direction,
    column,
});

const includeAmendedOrders = (checked) => ({
    type: Types.INCLUDE_AMENDED_ORDERS,
    checked,
});

const openOrderSummaryDetailView = (summaryData) => ({
    type: Types.ORDER_SUMMARY_MODAL,
    summaryData,
})

const closeOrderSummaryDetailView = () => ({
    type: Types.CLOSE_ORDER_SUMMARY_MODAL,
})

const selectOrderSummaryDropdown = (selectedInvestor, selectedTranche, selectedInterval, selectedDate) => ({
    type: Types.SELECT_ORDER_SUMMARY_DROPDOWN,
    selectedInvestor,
    selectedTranche,
    selectedInterval,
    selectedDate,
})

const selectAmendedOrderByInvestorDropDown = (selectedTranche, selectedDate, selectedInterval, selectedTimeRange, selectedInvestorType ) => ({
  type: Types.SELECT_AMENDED_ORDER_BY_INVESTOR_DROPDOWN,
  selectedTranche,
  selectedDate,
  selectedInterval,
  selectedTimeRange,
  selectedInvestorType,
})

const selectInvestorTypeDropdown = (selectedInvestorType) => ({
    type: Types.SELECT_INVESTOR_TYPE,
    selectedInvestorType,
})

const resetOrderBookDropDowns = () => ({
    type: Types.RESET_ORDER_SUMMARY_DROPDOWN,
})

const getCurrentActiveSummaryTab = (activeTab) => ({
    type: Types.CURRENT_ACTIVE_ORDER_SUMMARY_TAB,
    activeTab
})

const changeLoderStatus = (value) => ({
    type: Types.CHANGE_TRANCHE_LOADER_STATUS,
    value,
})

export {
    receiveOrders,
    requestOrders,
    requestOrdersForAllTranches,
    requestEcmOrders,
    receiveTranches,
    requestTranches,
    receiveInvestorNames,
    requestInvestorNames,
    receiveTranchLists,
    requestTranchLists,
    requestOrderSummary,
    receiveOrderSummary,
    requestDemandSummaryByType,
    receiveDemandSummaryByType,
    receiveDemandSummaryByShareAndType,
    requestDemandSummaryByShareAndType,
    updateCurrentTranche,
    receiveShareDemand,
    requestShareDemand,
    changeFullOrderBookSort,
    openOrderSummaryDetailView,
    closeOrderSummaryDetailView,
    selectOrderSummaryDropdown,
    selectAmendedOrderByInvestorDropDown,
    resetOrderBookDropDowns,
    filterOrderSummary,
    refreshFullOrderBook,
    filterAmendedOrderSummary,
    changeAllTranchesFullOrderBookSort,
    includeAmendedOrders,
    requestUpdateOrderSummary,
    requestUpdateFullOrderBook,
    requestUpdateAmendedOrderSummary,
    setIsSetStagesModalOpen,
    filterBookEvolutionSummary,
    filterBookEvolutionSummaryForAmendedOrderByInvestor,
    requestStages,
    updateStages,
    requestUpdateBookEvolutionSummary,
    requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable,
    requestUpdateStages,
    selectInvestorTypeDropdown,
    dataFetching,
    getCurrentActiveSummaryTab,
    changeLoderStatus,
};