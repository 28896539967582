import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
import styled from 'styled-components';

import { ValueFormatters, Sorters } from 'mk-pattern-library-react';
import ButtonGroup from 'shared/components/Buttons/ButtonGroup';

import sentimentColorProvider from 'util/sentimentColorProvider';

import * as modalIds from 'constants/modalIds';
import { featurePageIds } from 'constants/featurePages';

import { requestDoc } from 'actions/MyDocsActions';
import { openModal, closeModal, checkFeature } from 'actions/UtilityActions';

import {
    isModalOpen,
    isDCMDealType,
    getFeedbackCardData,
    getInvestorFeedbackHighlightsByTheme,
    getInvestorFeedbackChartDataByChart,
} from 'selectors';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import withLanguage from 'translations/withLanguage';
import withTranslate from 'shared/hoc/withTranslate';
import TranslationText from 'translations/TranslationText';

import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import flexbox from 'shared/styles/mixins/flexbox';
import Dropdown from 'shared/accessibility/components/Dropdown';
import { ImgIcon } from 'shared/components/Icon';
import HsbcLink from 'shared/accessibility/components/Link';

import FeedbackDetail from './FeedbackDetail/FeedbackDetail';

const ButtonsContainer = styled.div`
  ${flexbox('row', 'center', 'flex-end')}
`;

const HSBCLinkContainer = styled.div`
  ${flexbox('row', 'center', 'flex-start')}
  margin: ${props => props.switchVisible ? 15 : 7}px 0 0 0;
`;

const HsbcLinkContent = styled.div`
    display: inline-block;
    height: 20px;
`;

const PADDING_LEFT_0PX = {
  paddingLeft: 0,
};

const COLUMN_STYLE = leftBorderColor => ({
  padding: 10,
  border: '1px solid #D8D8D8',
  borderLeft: `10px solid ${leftBorderColor}`,
  marginBottom: 5,
});

const ROW_STYLE = {
  margin: 0,
};

const OUTER_COL_STYLE = {
  padding: 0,
};

const documentIconStyle = {
    height: '18px',
    width: '18px',
    position: 'relative',
    display: "inline-block",
    float: "left",
    color: '#4D6474',
    fontSize: 20,
    marginRight: 8,
};

const sortPopoverItems = props => [
  {// 1
    sort: id => [id, 'created', 'desc', null],
    content: props.content1,
  },{// 2
    sort: id => [id, 'created', 'asc', null],
    content: props.content2,
  },{// 3
    sort: id => [id, 'companyName', 'asc', null],
    content: props.content3,
  },{// 4
    sort: id => [id, 'companyName', 'desc', null],
    content: props.content4,
  },{// 5
    sort: id => [id, 'investorName', 'asc', null],
    content: props.content5,
  },{// 6
    sort: id => [id, 'investorName', 'desc', null],
    content: props.content6,
  },{// 7
    sort: id => [id, 'sentiment', 'asc', null],
    content: props.content7,
  },{// 8
    sort: id => [id, 'sentiment', 'desc', null],
    content: props.content8,
  },{// 9
    sort: id => [id, 'investorType', 'asc', null],
    content: props.content9,
  },{// 10
    sort: id => [id, 'investorType', 'desc', null],
    content: props.content10,
  },{// 11
    sort: id => [id, 'cityRecorded', 'asc', null],
    content: props.content11,
  },{// 12
    sort: id => [id, 'cityRecorded', 'desc', null],
    content: props.content12,
  },{// 13
    sort: id => [id, 'banksRecorded', 'asc', null],
    content: props.content13,
  },{// 14
    sort: id => [id, 'banksRecorded', 'desc', null],
    content: props.content14,
  }
]

class Details extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSort: { id: 1, field: 'created', order: 'desc', sortTheme: null },
            sortLabel: 'Sort Feedback',
            feedbackIdModalDetail: null,
        }

        this.setSort = this.setSort.bind(this);
    }

    componentDidMount() {
      this.props.viewChange();
    }

    handleDocumentClick = (docId, docName) => {
        this.props.requestDoc(docId, docName);
    }

    changeFeedbackModalVisibility(details = {}) {
        const { feedbackId } = details;
        if (feedbackId) {
          this.props.openModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID);
          this.setState({
              feedbackIdModalDetail: feedbackId,
          });
        } else {
          this.props.closeModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID);
        }
    }

  setSort(sortInd, field, order, sortTheme, label) {
    this.setState({
      activeSort: {
        id: sortInd,
        field,
        order,
        sortTheme,
      },
      sortLabel: label || sortTheme,
      scrollSwitcher: !this.state.scrollSwitcher,
    });
  }

  render() {
    const SortList = [
      ...sortPopoverItems(this.props).map((item, index) => {
        const label = this.state.activeSort.id === (index + 1)
          ? this.props.currentlySortingBy(item.content)
          : this.props.sortBy(item.content);

        return ({
          content: item.content,
          selected: this.state.activeSort.id === (index + 1),
          label,
          click: () => this.setSort(...item.sort(index + 1), item.content),
        })
      }),
      ...this.props.dealThemes.map((dealTheme, ind) => {
        const label = this.state.activeSort.id === (sortPopoverItems.length + ind + 1)
            ? this.props.currentlySortingByThemeName(dealTheme.themeName)
            : this.props.sortByThemeName(dealTheme.themeName);

        return ({
          content: dealTheme.themeName,
          selected: this.state.activeSort.id === (sortPopoverItems.length + ind + 1),
          label,
          click: () => this.setSort(sortPopoverItems.length + ind + 1, 'created', 'desc', dealTheme.themeName, dealTheme.themeName)
        })
      }),
    ];

    const chartsDisabled = this.props.chartTypesFetched && this.props.chartTypes.length === 0;

    return (
        <FeatureContainer>
            <HeaderContainer>
              <Row style={{ margin: 0 }}>
                <Col sm={6}>
                    <FeatureHeading className="text-xxlarge text-univ-bd">
                        <TranslationText
                            dictionaryKey={"investor_feedback.investor_feedback"}
                            defaultText={'Investor Feedback'}
                        />
                    </FeatureHeading>
                    <HeadingDescription top={5}>
                        <TranslationText
                            dictionaryKey={"investor_feedback.view_feedback_collected_from_investors"}
                            defaultText={"View feedback collected from investors"}
                        />
                    </HeadingDescription>
                    <HSBCLinkContainer switchVisible={!chartsDisabled}>
                      <HsbcLink
                        id='link-detailed-fb'
                        title={this.props.fullFeedbackDocument}
                        disabled={!this.props.fullFeedbackDoc}
                        onClick={() => {
                          this.handleDocumentClick(this.props.fullFeedbackDoc, 'Full Feedback');
                          this.props.fullFeedbackButtonEvent();
                        }}
                      >
                        <HsbcLinkContent>
                          <ImgIcon
                            style={documentIconStyle}
                            icon="DocumentDownload"
                            aria-hidden
                          />
                          {this.props.fullFeedbackDocument}
                        </HsbcLinkContent>
                      </HsbcLink>
                    </HSBCLinkContainer>
                </Col>
                <Col sm={6} style={PADDING_LEFT_0PX}>
                    <div style={{ textAlign: 'right' }}>
                    <p className="text-timestamp text-small" style={{ marginBottom: 4 }}>
                        <TranslationText
                            dictionaryKey={"common.data_as_of_<date_time>"}
                            replace={[
                                ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)]
                            ]}
                            defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)}`}
                        />
                    </p>
                    </div>
                    <ButtonsContainer>
                      {
                        !chartsDisabled && (
                          <ButtonGroup
                            size="small"
                            buttons={[
                              {
                                id:'btn-ifd-summary',
                                active: false,
                                text: this.props.stickyToggleSummary,
                                action: () => {
                                  browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE);
                                  this.props.invFeedbackSummaryEvent();
                                }
                              },
                              {
                                id:'btn-ifd-details',
                                active: true,
                                text: this.props.stickyToggleInvestorFeedback,
                                action: () => {
                                  browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE);
                                  this.props.invFeedbackButtonEvent();
                                },
                              }
                            ]}
                          />
                        )
                      }
                    </ButtonsContainer>
                   
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col sm={12} className="text-right" style={{ position: 'relative', marginBottom: 10 }}>
                    <Dropdown
                        dropdownId={modalIds.INVESTOR_FFEDBACK_SORT_MODAL_ID}
                        dropdownText={this.state.sortLabel}
                        items={SortList}
                        label={this.props.sortFeedbackLabel}
                    />
                </Col>
              </Row>
            </HeaderContainer>

            <Row>
                <Col sm={12}>
                    <Row style={ROW_STYLE}>
                        <Col sm={12} style={OUTER_COL_STYLE}>
                        <Row style={ROW_STYLE}>
                            {
                                this.props.feedbackCardData
                                    .slice(0)
                                    .sort((a, b) => this.state.activeSort.sortTheme ? Sorters.themeSort('created', a, b, this.state.activeSort.sortTheme) : Sorters.doubleSort('created', a, b, this.state.activeSort.order, this.state.activeSort.field, null, true))
                                    .map((feedbackDetail) => {
                                        const feedbackSentimentColor = sentimentColorProvider(feedbackDetail.sentiment);
                                        return (
                                        <Col
                                          key={`feedback-${feedbackDetail.feedbackId}`}
                                          id={`feedback-${feedbackDetail.feedbackId}`}
                                          sm={12}
                                          style={COLUMN_STYLE(feedbackSentimentColor)}
                                        > 
                                            <FeedbackDetail
                                              key={feedbackDetail.feedbackId}
                                              showDetails={this.changeFeedbackModalVisibility}
                                              feedback={feedbackDetail}
                                              isDCM={this.props.isDCM} />
                                        </Col>
                                        );
                                    })
                            }
                        </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FeatureContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  dateReceived: state.investorFeedbackData.dataReceived,
  dealId: state.utility.dealsData.dealId,
  feedbackCardData: getFeedbackCardData(state),
  dealThemes: state.investorFeedbackData.dealThemes,
  themeTypes: state.investorFeedbackData.feedbackThemeTypes,
  investorFeedbackHighlightsByTheme: (theme) => getInvestorFeedbackHighlightsByTheme(state, `themeHighlight${theme}`),
  chartTypes: state.investorFeedbackData.chartTypes,
  investorFeedbackChartDataByChart: (chartId) => getInvestorFeedbackChartDataByChart(state, `feedbackChartData${chartId}`),
  feedbackId: state.routing.locationBeforeTransitions && state.routing.locationBeforeTransitions.state ? state.routing.locationBeforeTransitions.state.feedbackId : null, // Pulling feedback ID for scrolling to specific card
  fullFeedbackDoc: state.investorFeedbackData.fullFeedbackDoc,
  isDCM: isDCMDealType(state),
  chartTypesFetched: state.investorFeedbackData.chartTypesFetched,
  //
  isInvestorFeedbackSortModalOpen: isModalOpen(state, modalIds.INVESTOR_FFEDBACK_SORT_MODAL_ID),
});

const mapDispatchToProps = (dispatch) => ({
  requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName)),
  checkFeature: (feature) => dispatch(checkFeature(feature)),
  // modals
  openModal: modalId => dispatch(openModal(modalId)),
  closeModal: modalId => dispatch(closeModal(modalId)),
});

export default compose(
  withLanguage,
  withTranslate(translate => ({
    fullFeedbackDocument: translate('investor_feedback.full_feedback_document', 'Full Feedback Document'),
    sortFeedbackLabel: translate('investor_feedback.sort_feedback', 'Sort Feedback'),
    currentlySortingBy: content => translate(
      '',
      `Currently sorting by ${content}`,
    ),
    sortBy: content => translate(
      '',
      `Sort By ${content}`,
    ),
    currentlySortingByThemeName: themeName => translate(
      'investor_feedback.currently_sorting_by_theme_<theme_name>_descending',
      `Currently sorting by theme ${themeName} descending`,
      [
        ['<theme_name>', themeName]
      ]
    ),
    sortByThemeName: themeName => translate(
      'investor_feedback.sort_by_theme_<theme_name>_descending',
      `Sort by theme ${themeName} descending`,
      [
        ['<theme_name>', themeName]
      ]
    ),
    stickyToggleSummary: translate('investor_feedback.summary', 'Summary'),
    stickyToggleInvestorFeedback: translate('investor_feedback.details', 'Details'),
    // sorting content
    content1: `${translate('investor_feedback.recorded_date', 'Recorded Date')} - ${translate('others.newest_to_oldest', 'Newest to Oldest')}`,
    content2: `${translate('investor_feedback.recorded_date', 'Recorded Date')} - ${translate('others.oldest_to_newest', 'Oldest to Newest')}`,
    content3: `${translate('investor_feedback.investor_name', 'Investor Name')} - ${translate('others.a_to_z', 'A to Z')}`,
    content4: `${translate('investor_feedback.investor_name', 'Investor Name')} - ${translate('others.z_to_a', 'Z to A')}`,
    content5: `${translate('investor_feedback.investor_contact', 'Investor Contact')} - ${translate('others.a_to_z', 'A to Z')}`,
    content6: `${translate('investor_feedback.investor_contact', 'Investor Contact')} - ${translate('others.z_to_a', 'Z to A')}`,
    content7: `${translate('investor_feedback.sentiment', 'Sentiment')} - ${translate('others.a_to_z', 'A to Z')}`,
    content8: `${translate('investor_feedback.sentiment', 'Sentiment')} - ${translate('others.z_to_a', 'Z to A')}`,
    content9: `${translate('investor_feedback.investor_type', 'Investor Type')} - ${translate('others.a_to_z', 'A to Z')}`,
    content10: `${translate('investor_feedback.investor_type', 'Investor Type')} - ${translate('others.z_to_a', 'Z to A')}`,
    content11: `${translate('investor_feedback.location', 'Location')} - ${translate('others.a_to_z', 'A to Z')}`,
    content12: `${translate('investor_feedback.location', 'Location')} - ${translate('others.z_to_a', 'Z to A')}`,
    content13: `${translate('investor_feedback.bank_recorded', 'Bank Recorded')} - ${translate('others.a_to_z', 'A to Z')}`,
    content14: `${translate('investor_feedback.bank_recorded', 'Bank Recorded')} - ${translate('others.z_to_a', 'Z to A')}`,
  })),
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE),
    invFeedbackSummaryEvent: () => ev(events.INVESTOR_FEEDBACK_SUMMARY_BUTTON),
    invFeedbackButtonEvent: () => ev(events.INVESTOR_FEEDBACK_BUTTON),
    fullFeedbackButtonEvent: () => ev(events.INVESTOR_FULL_FEEDBACK_BUTTON),
    sortFeedbackEvent: () => ev(events.INVESTOR_FEEDBACK_SORT_FEEDBACK_BUTTON),
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(Details)
