import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    isDCMDealType,
    isECMDealType,
    isDCMOrderbookContentAvailable,
    isECMOrderbookContentAvailable
} from 'selectors';

import PageContentNotAvailable from 'components/PageContentNotAvailable';

import ECMFullOrderBookContainer from './ecm/FullOrderBook/FullOrderBook.container';
import DCMFullOrderBookContainer from './dcm/FullOrderBook/FullOrderBook.container';

class FullOrderBookComponent extends PureComponent {
    render() {
        if (!this.props.isDCMAvailable && !this.props.isECMAvailable) {
            return <PageContentNotAvailable />
        }

        if (this.props.isECM) {
            return <ECMFullOrderBookContainer />;
        }

        if (this.props.isDCM) {
            return <DCMFullOrderBookContainer />;
        }

        return null;
    }
}

const EnhancedFullOrderBook = connect(
    state => ({
        isDCMAvailable: isDCMOrderbookContentAvailable(state),
        isECMAvailable: isECMOrderbookContentAvailable(state),
        isFetching: state.utility.fetching,
        isDCM: isDCMDealType(state),
        isECM: isECMDealType(state),
    }),
)(FullOrderBookComponent);

export default EnhancedFullOrderBook;
