import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import dealFeatures from 'constants/dealFeatures';
import { checkFeature } from 'actions/UtilityActions';
import { getMyDocsFetchedStatus } from 'selectors';

import Loading, { FeaturePageLoadingContainer } from 'shared/components/Loading';

class MyDocs extends PureComponent {
    componentDidMount() {
        this.props.checkFeature(dealFeatures.MY_DOCS);
    }

    render() {
        if (!this.props.dataFetched) {
            return (
                <FeaturePageLoadingContainer>
                    <Loading />
                </FeaturePageLoadingContainer>
            )
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    dataFetched: getMyDocsFetchedStatus(state),
});

const mapDispatchToProps = dispatch => ({
    checkFeature: feature => dispatch(checkFeature(feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDocs);
