import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import { VisualisationColors } from 'mk-pattern-library-react';

function createCell(entry, i, palette) {
    return <Cell key={`dt-${i}`} fill={palette(i, entry.name)} />;
}

// Styling
const ChartContainer = styled.div`
    width: 314px;
    height: 270px;
    margin: auto;
`;

class Chart extends PureComponent {

  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      animate: true,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ animate: false }), 1000);

    setTimeout(() => {
      const el = document.querySelector('svg.recharts-surface');
      if (el) el.setAttribute('focusable', false);
    }, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { data } = this.props;

    const palette = this.props.wideColorPalette ? VisualisationColors.extendedColors : VisualisationColors.colors;
    const slices = data.map((e, i) => createCell(e, i, palette));

    return (
      <ChartContainer>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              startAngle={90}
              endAngle={-270}
              // label={renderCustomizedLabel}
              // labelLine
              outerRadius={95}
              // memory leak fix
              animationDuration={this.props.animationDuration}
              isAnimationActive={this.state.animate}
              //
              focusable={false}
            >
              {slices}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    )
  }
}

export default Chart;
