import React from 'react';
import styled from 'styled-components';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

const Value = styled.div`
  font-size: 0.875rem;
  line-height: 20px;
  text-align: right;
  font-weight: normal;
`;

export default (props) => <Value>{ValueFormatters.millionFormatter(props.value)}</Value>
