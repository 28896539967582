const DASHBOARD = '/';

const DEAL_INFORMATION = '/dealInformation';

const REFERENCE_INFORMATION = '/referenceInformation';

const ECB_TO_RETURN = '/ecbToReturn';

export const mapDealFeatureToPageTitle = dealFeature => {
    switch (dealFeature) {
        case DASHBOARD: return 'HSBC - MyDeal';
        case DEAL_INFORMATION: return 'Deal Information - HSBC MyDeal';
        case REFERENCE_INFORMATION: return 'Reference Information - HSBC MyDeal';
        default: return 'HSBC - MyDeal';
    }
}

export const allowedUiDealFeatures  = [
  {
    id: DASHBOARD,
    url: '/',
    title: 'Dashboard',
    visibleInMainMenu: true
  }, {
    id: 'dealInformation',
    url: DEAL_INFORMATION,
    title: 'Deal Information',
    visibleInMainMenu: true
  }, {
    id: 'referenceInformation',
    url: REFERENCE_INFORMATION,
    title: 'Reference Information',
    visibleInMainMenu: false
  }, {
    id: 'ecbToReturn',
    url: ECB_TO_RETURN,
    title: 'ECB 2 Return',
    visibleInMainMenu: false
  }
]

export default {
  DASHBOARD,
  DEAL_INFORMATION,
  REFERENCE_INFORMATION,
  ECB_TO_RETURN
};
