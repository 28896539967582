import React from 'react';
import { Row, Col } from 'react-bootstrap';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

const iconStyle = {
    marginLeft: 7,
    width: 16,
    display: 'inline-block',
    color: 'rgb(153, 153, 153)'
} 

const flexbox = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
}

const EventSummary = (props) => {

    const spacingLg = { marginBottom: 8 };

    const { eventTitle, startTime, endTime, eventText, eventIcon, eventTypeId, startTimeZone } = props;

    let endTimeZone = props.endTimeZone;
    if ((typeof endTimeZone === 'undefined' || endTimeZone === null) && endTime) {
        endTimeZone = startTimeZone; // if no timezone, make them equal to one another
    }

    return (
        <Row style={spacingLg} className="text-univ-lt">
            <Col sm={7} style={{ position: 'relative' }}>
                <div style={{ overflow: 'hidden', paddingLeft: 15 }}>
                    <h3 style={{minHeight:28, margin: 0, lineHeight: 1.4}} className="text-xlarge text-bold text-univ-bd">{eventTitle}</h3>
                    <span className="text-large">{eventText}</span>{eventIcon && (<div style={iconStyle}>{eventIcon}</div>)}
                </div>
            </Col>

            {startTime && (eventTypeId !== 12) &&
                (<Col sm={5} className="text-right text-large">
                    <div style={flexbox}>
                        <div>
                            <div style={{marginBottom : 3}}>
                                {
                                    ValueFormatters.dateFormatter('zoneTime', startTime)
                                }
                            </div>
                            {
                                (
                                    endTimeZone && (endTimeZone === startTimeZone) && null
                                ) || (
                                    endTimeZone && (endTimeZone !== startTimeZone) && (
                                        <div className="text-medium">{startTimeZone}</div>
                                    )
                                ) || null
                            }
                        </div>
                        {
                            endTime && (
                                <div>
                                    &nbsp;to&nbsp;
                                </div>
                            )
                        }
                        {
                            endTime && (
                                <div>
                                    <div style={{marginBottom : 3}}>
                                        {
                                            ValueFormatters.dateFormatter('zoneTime', endTime)
                                        }
                                    </div>
                                    {
                                        (
                                            !endTimeZone && (
                                                <div className="text-medium">{startTimeZone}</div>
                                            )
                                        ) || (
                                            <div className="text-medium">{endTimeZone}</div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </Col>)
            }
        </Row>
    )
}

export default EventSummary
