import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
// TODO
import { SiteConstants } from 'mk-pattern-library-react';

import withTranslate from 'shared/hoc/withTranslate';

import EventSummary from '../EventSummary/EventSummary';
import LogisticsDetails from '../LogisticsDetails/LogisticsDetails';
import MeetingsDetails from '../MeetingsDetails/MeetingsDetails';
import InvestorDetails from '../InvestorDetails/InvestorDetails';
import BankerDetails from '../BankerDetails/BankerDetails';
import ParticipantDetails from '../ParticipantDetails/ParticipantDetails';

// TODO
const { roadShowEventTypes } = SiteConstants;

const DetailsView = (props) => {
    const event = props.event;
    const knownType = roadShowEventTypes.has(event.eventTypeId);
    const typeId = knownType ? event.eventTypeId : 0
    const eventType = roadShowEventTypes.get(typeId);
    const dealType = props.dealType;

    let investors = [];
    let bankers = [];
    let participants = [];
    let drivers = [];
    let salesContacts = [];

    // Split persons associated with the evet into the proper lists
    if (event.persons && event.persons.length) {
        event.persons.forEach((person) => {
            switch (person.personTypeId) {
                case 1:
                    participants.push(person);
                    break
                case 2:
                    investors.push(person);
                    break
                case 3:
                    bankers.push(person);
                    break
                case 4:
                    drivers.push(person);
                    break
                case 5:
                    salesContacts.push(person);
                    break
                default:
                  throw new Error(`Invalid person type Id: ${person.personTypeId}`);
            }
        });
    }

    let xNum;

    if (event.eventTypeId === 3) {
        let investorsByFirm = {};
        if (investors.length) {
            investors.forEach((investor) => {
                if (!investorsByFirm[investor.company]) {
                    investorsByFirm[investor.company] = true;
                }
                xNum = Object.keys(investorsByFirm).length
            });
        }
    }

    return (
        <Row
            id={'event-detail-container'}
            role="region"
            aria-label={props.eventDetailRegion}
            tabIndex={-1}
        >
            <Col sm={12} style={{ paddingBottom: 15, paddingTop: 10 }}>
                {/* Top summary of event */}
                <EventSummary
                    eventTitle    = {event.title}
                    startTime     = {event.eventTypeId !== 9 ? event.startDateLocal : null}
                    endTime       = {event.eventTypeId !== 9 ? event.endDateLocal : null}
                    eventText     = {eventType.title(event.host, xNum)}
                    eventIcon     = {eventType.summaryIcon}
                    eventTypeId   = {event.eventTypeId}
                    startTimeZone = {event.startTimeZone}
                    endTimeZone   = {event.endTimeZone}
                    investorList  = {investors}
                />

                {/* Checking against unknown event type */}
                {eventType.eventTypeId === 0
                    && (
                        // Event is unknown, check to see if we need a logistic or meeting view based on data returned
                        (event.location1 && event.location2)
                            ?
                            (
                                // Show Logistics Info
                                <LogisticsDetails
                                    driver={drivers[0] || {}}
                                    event={event}
                                    eventType={eventType}
                                    dealType={dealType}
                                />
                            )
                            :
                            (
                                // Show Meeting Info
                                <MeetingsDetails
                                    event={event}
                                    dealType={dealType}
                                />
                            )
                    )}

                {(eventType.eventTypeId !== 0
                    &&
                    (eventType.eventTypeId >= 8) && (
                        // Show Logistics Info
                        <LogisticsDetails
                            driver={drivers[0] || {}}
                            event={event}
                            eventType={eventType}
                            dealType={dealType}
                        />
                    ))
                    ||
                   ((eventType.eventTypeId < 8) && (
                        // Show Meeting Info
                        <MeetingsDetails
                            event={event}
                            dealType={dealType}
                        />
                    ))
                }



                {/* Only show investor sections if we have them */}
                {(investors.length !== 0) && (
                    <InvestorDetails
                        event={event}
                        eventTypeId={eventType.eventTypeId}
                        investors={investors}
                    />
                )}

                {/* Only show banker section if we have them */}
                {(bankers.length !== 0 || salesContacts.length !== 0) && (
                    <BankerDetails
                        bankers={bankers}
                        salesContacts={salesContacts}
                    />
                )}

                {/* Only show participants section if we have them */}
                {(participants.length !== 0) && (
                    <ParticipantDetails
                        participants={participants}
                        participantsTitle={eventType.participantsTitle}
                    />
                )}

            </Col>
        </Row>
    )
};

export default compose(
    withTranslate(translate => ({
        eventDetailRegion: translate('', 'Event detail content'),
    }))
)(DetailsView);
