import React, { Fragment, Component } from 'react'
import styled from 'styled-components';

import { isIE } from 'shared/styles/mixins/browser';

const StyledLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 2rem;
  line-height: 2.4rem;
  font-size: 1rem;
  font-family: "Univers Next for HSBC Regular",sans-serif;
  font-weight: 500;
  color: #333;
  outline: none;
  &:before {
    content: "";
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    left: 0;
    bottom: .5rem;
    border: 1px solid #929292;
    margin-right: 1rem;
  }
`

const StyledInput = styled.input`
  display: none;
  &:checked+label:before {
    content: "\f00a";
    font-family: 'HSBC Icons';
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: #008580;
    text-align: center;

    ${
      isIE(`
        line-height: 1.1;
      `)
    }
  }
`;

class Checkbox extends Component {

  onClick = () => {
    if(this.props.disabled !== false) {
      this.props.onCheckedChange && this.props.onCheckedChange(!this.props.checked);
    }
  }

  render() {
    return (
      <Fragment>
        <StyledInput
          id={this.props.id || 'cb'}
          type="checkbox"
          style={{ display: 'none' }}
          name={this.props.name}
          checked={this.props.checked}
          disabled={this.props.disabled}
        />
        <StyledLabel
          htmlFor={this.props.id || 'cb'}
          classNames="checkbox-label"
          style={this.props.labelStyle}
          onClick={this.onClick}>
            {this.props.label}
        </StyledLabel>
      </Fragment>
    );
  }
}

export default Checkbox;
