//
// General dimensiton of most important elements (header, nav, main, footer etc.)
//

// navigation (shared btw ecm & dcm)
export const NAVIGATION_HEIGHT = 100;

// main content
export const MAIN_HEIGHT = `calc(100vh - ${NAVIGATION_HEIGHT}px)`;

// Landing Page (shared btw ecm & dcm)

// Pricing (shared btw ecm & dcm)

// Roadshow (shared btw ecm & dcm)

// Investor Feedback (shared btw ecm & dcm)

// Orders (shared btw ecm & dcm)

// MyDocs (shared btw ecm & dcm)

// other