import React, { Component } from 'react';
import * as d3 from 'd3';
import 'd3-selection-multi';

import { VisualisationColors } from 'mk-pattern-library-react';

import { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import provideTranslationStringForTitle from '../../shared/provideTranslationStringForTitle';

const visualisationColors = VisualisationColors.asArray;

const margin = { top: 30, right: 5, bottom: 50, left: 50 };

const fullWidth = 1000;
const fullHeight = 600;

const yAxisWidth = 140;
const xAxisHeight = 150;

const width = fullWidth - margin.right - margin.left - yAxisWidth;
const height = fullHeight - margin.top - margin.bottom - xAxisHeight;

class FeedbackChartPriceDemand extends Component {
  buildChart() {
    this.chartData = this.props.data;

    this.categoryAxis = this.chartData.categoryAxis.categoryLabel;
    this.cumulativeData = this.chartData.series.find(s => s.seriesTitle === 'Cumulative Demand').data;
    this.demandData = this.chartData.series.find(s => s.seriesTitle === 'Demand').data.map(d => d.value);
    this.demandMaxData = this.chartData.series.find(s => s.seriesTitle === 'Demand Max').data.map(d => d.value);
    this.demandLowData = this.chartData.series.find(s => s.seriesTitle === 'Demand Min').data.map(d => d.value);
    this.targetSize = this.chartData.series.find(s => s.seriesTitle === 'Target Size').data.map(d => d.value)[0];
    this.trancheCurrency = this.chartData.series.find(s => s.seriesTitle === 'Tranche Currency').data.map(d => d.value)[0];
    this.demandUnit = this.chartData.series.find(s => s.seriesTitle === 'Demand Unit').data.map(d => d.value)[0];

    this.valueTitle = this.chartData.valueAxis.valueTitle;

    this.max = d3.max(this.cumulativeData, d => d.value);

    this.cumulativeData = this.cumulativeData.map((d, i) => ({
      ...d,
      xAxisLabel: this.categoryAxis[i],
    }));

    this.y = d3.scaleLinear()
      .domain([0, this.max])
      .range([height, 0])
      .nice();

    this.x = d3.scaleBand()
      .range([0, width])
      .domain(this.categoryAxis)
      .paddingInner(0.2);

    this.x2 = d3.scaleBand()
      .range([0, width])
      .domain([...Array(this.demandData.length).keys()])
      .paddingInner(0.3);

    this.xAxis = d3.axisBottom(this.x)
      .ticks(this.categoryAxis.length);

    this.xAxis2 = d3.axisBottom(this.x2)
      .ticks(this.demandData.length)
      .tickFormat(i => this.demandData[i]);

    this.yAxis = d3.axisLeft(this.y).tickSize(-yAxisWidth, 0);
    d3.select('#chart').html('');
    const svg = d3.select('#chart')
      .append('svg')
        .attr('width', fullWidth)
        .attr('height', fullHeight)
        .attr('focusable', false)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // svg.append('rect')
    //     .attrs({ x: -50, y: height, width: fullWidth, height: 100, fill: '#F7F7F7' })
    // replaced with (bottom)
    svg.append('rect')
        .attrs({ x: -50, y: height, width: fullWidth, height: 1, fill: '#d9d9d9' })

    const chartg = svg.append('g')
      .attr('transform', 'translate(' + yAxisWidth + ',0)')

    chartg.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0, 380)')
      .call(this.xAxis);

    svg.append('text')
      .text(provideTranslationStringForTitle(this.valueTitle, this.props.language))
      .attr('y', 400)
      .attr('x', -40);

    svg.append('text')
      .text(translationString('investor_feedback.demand', this.props.language, this.valueTitle))
      .attr('y', 440)
      .attr('x', -40);

    svg.append('text')
      .text(`(${this.trancheCurrency}${this.demandUnit})`)
      .attr('y', 453)
      .attr('x', -40);

    svg.append('text')
      .text(`${translationString('investor_feedback.demand', this.props.language, this.valueTitle)} (${this.trancheCurrency}${this.demandUnit})`)
      .attr('y', -20)
      .attr('x', -25);

    if(this.targetSize) {
      svg.append('text')
        .text(translationString('investor_feedback.target_size', this.props.language, this.valueTitle))
        .attr('y', this.y(this.targetSize) - 5)
        .attr('x', 10);

      svg.append('text')
        .text(this.targetSize)
        .attr('y', this.y(this.targetSize) + 15)
        .attr('x', 10);
    }

    chartg.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0, ' + 420 + ')')
      .call(this.xAxis2);

    svg.append('g')
      .classed('y axis', true)
      .attr('x', 400)
      .call(this.yAxis);

    if(this.targetSize) {
      chartg.append('line')
        .attr('stroke-dasharray', '5, 5')
        .attr('x1', -175)
        .attr('y1', this.y(this.targetSize))
        .attr('x2', width)
        .attr('y2', this.y(this.targetSize))
        .attr('stroke', '#bbbbbb');
    }

    const gridlines = d3.axisLeft()
      .tickFormat('')
      .tickSize(-width)
      .scale(this.y);

    chartg.append('g')
     .attr('class', 'axis-lines')
     .call(gridlines);

    d3.selectAll('.axis-lines line')
      .attr('stroke', '#dddddd');

    d3.selectAll('.y.axis line')
      .attr('stroke', '#dddddd');

    const rects = chartg
      .append('g')
      .attr('class', 'bars')
      .selectAll('rect')
      .data(this.cumulativeData);

    const foregroundsBars = rects.enter();

    foregroundsBars.append('rect')
      .attr('fill', (d, i) => visualisationColors[(i) % visualisationColors.length])
      .attr('x', d => this.x(d.xAxisLabel))
      .attr('y', (d, i) => this.y(this.cumulativeData[i].value))
      .attr('height', (d, i) => {
        const value = this.cumulativeData[i].value - (i > 0 ? this.cumulativeData[i - 1].value : 0);
        if (this.max === 0 && value === 0) {
          return 0;
        }
        return height - this.y(value)
      })
      .attr('width', this.x.bandwidth());

    const priceSensitivityIndicators = rects.enter();

    priceSensitivityIndicators.append('rect')
      .attr('class', 'sensitivity')
      .attr('fill', '#9b9b9b')
      .attr('x', d => this.x(d.xAxisLabel) + this.x.bandwidth() / 2 - 5)
      .attr('y', (d, i) => this.y(this.demandMaxData[i]))
      .attr('height', (d, i) => {
        const value = this.demandMaxData[i] - this.demandLowData[i];
        if (this.max === 0 && value === 0) {
          return 0;
        }
        return height - this.y(value)
      })
      .attr('width', 10);

    this.categoryAxis.forEach((categoryAxis) => {
      const backgroundBars = rects.enter();
      backgroundBars.append('rect')
      .attr('fill', (d, i) => visualisationColors[(i) % visualisationColors.length])
      .attr('x', () => this.x(categoryAxis))
      .attr('y', (d, i) => this.y(this.cumulativeData[i].value))
      .attr('height', (d, i) => {
        const value = this.cumulativeData[i].value - (i > 0 ? this.cumulativeData[i - 1].value : 0);
        if (this.max === 0 && value === 0) {
          return 0;
        }
        return height - this.y(value);
      })
      .attr('width', this.x.bandwidth())
      .style('opacity', 0.1);
    });

    d3.selectAll('text').attrs().styles({
      'font-weight': 600,
      'font-size': '0.750rem',
      'font-family': 'Univers Next,Courier New,Helvetica,Arial,sans-serif',
      'line-height': 1.42857,
      color: '#333',
    });

    d3.selectAll('g.x.axis text').attrs().styles({
      'font-weight': 200,
    })

    d3.selectAll('.axis.x .tick line').style('visibility', 'hidden');
    d3.selectAll('.axis .domain').remove();
    d3.selectAll('.axis-lines .domain').remove();

    d3.select('.axis.y .tick:first-child').remove();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  componentDidMount() {
    this.buildChart();
  }

  shouldComponentUpdate(props) {
    return props !== this.props;
  }

  render() {
    return (
      <div
        id="chart"
        style={{margin: '5px 50px 50px 50px'}}
        className={`price-demand-chart ${this.props.className || ''}`} 
        aria-hidden
      >
      </div>
    );
  }
};

export default withLanguage(FeedbackChartPriceDemand);
