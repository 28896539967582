import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import withLanguage from 'translations/withLanguage';

import HsbcButton from '../HsbcButton/HsbcButton.js';
import Icon from '../Icon/Icon';

import keyboardKeys from 'shared/accessibility/constants/keys';
/**
 * Scrolling Navigation - A variation on a special case button group
 */
class ScrollingNav extends Component {

    constructor(props) {
        super(props);

        this.pagination = false;
        this.updatePagination = this.updatePagination.bind(this);
    }

    keyboardEvent = (event, index) => {
        event.preventDefault();
        const { which } = event;

        switch (which) {
            case keyboardKeys.left: {
                const prevElement = index === 0 ? this.props.titles[0] : this.props.titles[index - 1];
                const element = document.querySelector(`#carousel-${prevElement.id}`);

                element && element.focus();

                break;
            }
            case keyboardKeys.right: {
                const nextElement = index === this.props.titles.length - 1 ? this.props.titles[this.props.titles.length - 1] : this.props.titles[index +1];
                const element = document.querySelector(`#carousel-${nextElement.id}`);

                element && element.focus();

                break;
            }
            default :
              // Do nothing
        }
    }

    // might need to throw this in the render
    updatePagination() {
        const prevPagination = this.pagination;

        if (this.refs && this.refs.scrollNav) {
            let $track = this.refs.scrollNav.getElementsByClassName('slick-track')[0];
            let btnWidth;

            if ($track && $track.children && $track.children.length) {
                let $list = this.refs.scrollNav.getElementsByClassName('slick-list')[0];


                btnWidth = Array.prototype.reduce.call($track.children, (tot, btn) => {
                    return tot + btn.offsetWidth
                }, 0)

                if (btnWidth >= $list.offsetWidth) {
                    this.pagination = true;
                }
                else {
                    // remove scrolling buttons when screen becomes large again
                    this.pagination = false;
                }
            }
        }

        if (this.pagination !== prevPagination) {
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.updatePagination();

        window.addEventListener("resize", this.updatePagination);
        window.addEventListener("orientationChange", this.updatePagination);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePagination);
        window.removeEventListener("orientationChange", this.updatePagination);
    }

    componentDidUpdate() {
        this.updatePagination();
    }

    getEventTypeIcon(event) {
        // reference C:\Projects\hsbc-mydeal\src\util\siteConstants.js for eventType mappings
        let eventIcon = "";
        let iconStyle = { color: event.pastDay ? '#999999' : '#4F595F' }
        if (event.hasFlights) {
            eventIcon = 'Plane';
            iconStyle.fontSize = 14
        }
        else if (event.hasTrain) {
            eventIcon = 'Train';
            iconStyle.fontSize = 14
        }
        else if (event.hasCars) {
            eventIcon = 'Car';
            iconStyle.fontSize = 10
        }


        return {
            eventIcon,
            iconStyle,
        };
    }

    render() {
        let sliderBtnWidth = this.props.btnWidth || 114;

        const buttons = this.props.titles.map((title, index) => {
            const active = this.props.active.id === title.id;

            const icon = this.getEventTypeIcon(title);
            let buttonClass = this.props.className;
            let noEvents = title.meetingCount === 0;
            buttonClass += (active ? " active" : "");
            // buttonClass += (noEvents ? " no-events" : "");
            buttonClass += title.pastDay ? " past-day" : "";
            let statusText = title.status;
            // If we receive an array for the status (roadshow multiple cities)
            if(title.status && title.status.map){
                if(title.status.length - 1 > 0){
                    statusText = `${title.status[0]} +${title.status.length - 1}`
                }
            }

            if(statusText && statusText.length === 0){
                statusText = null
            }

            return (
                <div key={title.id}>
                    <HsbcButton
                        id={`carousel-${title.id}`}
                        size="normal"
                        className={buttonClass}
                        aria-label={title.btnAriaLabel}
                        aria-pressed={active}
                        onClick={this.props.handleClick.bind(this, title)}
                        style={{ width: sliderBtnWidth }}
                        onKeyUp={e => this.keyboardEvent(e, index)}
                        tabIndex={active ? 0 : -1}
                    >
                        <div className="scrolling-nav-element" aria-hidden="true">
                            <span
                                style={{
                                    position: 'relative',
                                    right: '2px',
                                    overflow: "hidden",
                                    textOverflow: 'ellipsis',
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    width: "100%",
                                    fontWeight: title.pastDay ? "normal" : "600"
                                }}
                                title={title.title}
                                className={this.props.active.title === title.title ? "" : "titleText "}
                            >
                                {title.title}
                            </span>
                            <div className="subText text-univ-lt" style={{ bottom:5, position: 'relative', right: '1px', overflow: "hidden", whiteSpace: "nowrap", textOverflow: 'ellipsis', textAlign: 'left', fontSize: '0.750rem', color: (title.status === "." ? "#fff" : "") }} >
                                <span>{title.title === 'Book Evolution' ? this.props.issuerName : statusText || <div>&nbsp;</div>}</span>

                                <div className="nav-details">
                                    {!noEvents ? title.details || <div>&nbsp;</div> : <div>&nbsp;</div>}
                                    <div className="nav-details-icon">
                                        {icon.eventIcon && <Icon iconName={icon.eventIcon} style={icon.iconStyle} />}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </HsbcButton>
                </div>
            )

        });

        let navGrpStyle = {
            width: this.props.width,
            backgroundColor: this.props.color,
            overflow: 'hidden',
        }

        let settings = {
            dots         : false,
            arrows       : this.pagination,
            infinite     : false,
            speed        : 250,
            swipeToSlide : true,
            touchMove    : true,
            variableWidth: true,
            draggable    : this.pagination,
        }

        if (!this.pagination) {
            // settings.slickGoTo = 0;
            if (this.refs.scrollNav && this.refs.scrollNav.slickGoTo) {
                this.refs.scrollNav.slickGoTo(0);
            }
        }

        return (
            <div ref="scrollNav" className="scrolling-nav" style={navGrpStyle}>
                <Slider
                    ref="scrollNav"
                    className={`scrolling-nav-carousel ${this.pagination ? 'scrolling-nav-carousel-margin' : ''}`}
                    {...settings}
                    prevArrow={
                        <div aria-hidden>
                            <Icon className = "scrolling-nav-prev" style = {{ verticalAlign: 'middle', color: '#FFFFFF', fontSize: 12 }} iconName = "Chevron" />
                        </div>
                    }
                    nextArrow={
                        <div aria-hidden>
                            <Icon className = "scrolling-nav-next" style = {{ verticalAlign: 'middle', color: '#FFFFFF', fontSize: 12 }} iconName = "Chevron" />
                        </div>
                    }
                >
                    {
                        buttons.filter(btn => btn)
                    }
                </Slider>
            </div>
        );

    }

}

ScrollingNav.propTypes = {
	/**
	 * string `title` : Button Label
	 *
	 * string `status` : Button Subtext
	 */
    titles: PropTypes.arrayOf(PropTypes.object),
    width: PropTypes.string,
    color: PropTypes.string,
    issuerName: PropTypes.string,
};
ScrollingNav.defaultProps = {
    titles: [{}],
    width: "100%",
    color: "#fff",
};

export default withLanguage(ScrollingNav);
