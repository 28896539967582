import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { ValueFormatters, Icon } from 'mk-pattern-library-react';

import { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import ScrollableTable from 'shared/components/ScrollableTable';
import SortIcons from 'shared/components/SortIcon';
import SortState, { SORT } from 'shared/components/SortState';
import SortableTableHeader from 'shared/components/SortableTableHeader';

import { buttonKeyHandler } from 'util/onKeyPress';
import { requestDoc } from 'actions/MyDocsActions';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import { fontColors, typography } from 'shared/styles/variables/theme';

const headerStyles = {
  backgroundColor: "#ededed",
  minHeight      : "30px",
  fontSize       : typography.sizes['12px'],
  color          : "#000",
  lineHeight     : "17px",
  marginLeft     : 0,
  marginRight    : 0,
  verticalAlign  : "middle",
  textDecoration: "none",
  borderTop: 'solid 2px rgb(221, 221, 221)',
  fontWeight: 500,
  paddingLeft: 20,
  paddingRight: 20,
};

const cellStyle = {
  borderLeft: 'none',
  borderTop: 'none',
  borderRight: 'none',
  padding: '9px 15px 17px 15px',
  height: '65px',
  borderBottom: 'solid 1px rgb(221, 221, 221)',
}

const descriptionStyle = {
  color: fontColors.grey,
}

const DocumentsContainer = (props) => {

  const sort = useRef(new SortState('lastModified', SORT.DESCENDING)).current;

  const baseColumnProps = (sort) => ({
    sort: true,
    sortCaret: SortIcons,
    headerAttrs: sort.getHeaderAttributes,
    title: true,
    headerFormatter: SortableTableHeader,
    columnFlex: 1,
    headerEvents: {
      onClick: (e, a) => {
        const column = e.currentTarget;
        const field = column.dataset.field;
        const sortOrder = e.currentTarget.getAttribute('aria-sort') === SORT.DESCENDING
          ? SORT.ASCENDING : SORT.DESCENDING;
  
        sort.set(field, sortOrder);
      }
    },
  });

  const Columns = (props, sort) => {
    const columns = [{
      ...baseColumnProps(sort),
      dataField: 'title',
      text: translationString("common.document_title", props.language, "Document Title"),
      headerClasses: 'doc-header',
      headerStyle: {
        ...headerStyles,
        marginTop: 7,
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
      },
      style: {
        ...cellStyle,
        width: '75%',
      },
      columnFlex: 3,
      formatter: (value, data, index) => (
        <div>
          <Icon style={{height: 21, width: 16, position: 'relative', display: 'inline-block', float: 'left', color: 'rgb(77, 100, 116)', fontSize: 20, marginRight: 10}} iconName="DocumentUpload" />
          <div>
            <h3 className="text-link" style={{ cursor: 'pointer', marginBottom: 0, display: 'inline', lineHeight: '19px', fontSize: typography.sizes['18px']}}>
              <span
                id={`doc-${data.documentVersionId}`}
                role="button"
                aria-label={
                  translationString(
                      "common.open_document_<document_name>",
                      props.language,
                      `Open document ${data.title}`,
                      [
                          ['<document_name>', data.title]
                      ]
                  )
                }
                onKeyDown={e => buttonKeyHandler(e, () => { props.documentRequestedEvent(); props.requestDoc(data.documentVersionId, data.title) })}
                onClick={() => { props.documentRequestedEvent(); props.requestDoc(data.documentVersionId, data.title) }}
                tabIndex={0}
              >
                {data.title}
              </span>
            </h3>
            <div style={{ fontSize: typography.sizes['14px'], letterSpacing: '0.19px', lineHeight: '20px'}}>
              <span style={ descriptionStyle }>
                <span>{data.description || translationString("my_docs.no_comments", props.language, "No Comments")}</span>
              </span>
            </div>
          </div>
        </div>
      ),
    }, {
      ...baseColumnProps(sort),
      dataField: 'lastModified',
      text: translationString("common.upload_date", props.language, "Upload Date"),
      headerClasses: 'doc-header',
      headerStyle: {
        ...headerStyles,
        marginTop: 7,
        textAlign: 'right',
        borderLeft: 'none',
        borderBottom: 'none',
      },
      style: {
        ...cellStyle,
        width: '25%',
        fontFamily: 'Univers Next Lt',
        color: fontColors.grey,
        fontSize: typography.sizes['12px'],
        paddingTop: 16,
        textAlign: 'right',
      },
      formatter: (value, data) => ValueFormatters.dateFormatter('dayMonthYearZoneShort', data.lastModified || data.created),
    }];
  
    return columns;
  }

  return (
    <div style={{ paddingRight: 15 }}>
      <ScrollableTable
        data={props.data}
        bordered={false}
        rowStyle={{ borderBottom: 'solid 1px rgb(221, 221, 221)' }}
        keyField="documentVersionId"
        columns={Columns(props, sort)}
        defaultSorted={[{ dataField: sort.column, order: sort.direction === SORT.ASCENDING ? 'asc' : 'desc'}]}
        {...props}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName)),
})

export default compose(
  withAnalytics((ev) => ({
    documentRequestedEvent: () => ev(events.MY_DOCS_OPEN_DOCUMENT),
  })),
  connect(null, mapDispatchToProps),
  withLanguage
)(DocumentsContainer);
