import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import langNames from 'local-lang-names';

import * as modalIds from 'constants/modalIds';

import { getSelectedLanguage, getAvailableLanguages } from 'selectors';
import { selectLanguage } from 'actions/LanguageActions';

import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import Dropdown from 'shared/accessibility/components/Dropdown';

class LanguagePicker extends PureComponent {

    dropdownOpened = () => {
        this.props.languageEvent();
    }

    render() {
        const items = Object
            .keys(this.props.availableLanguages)
            .map((lang, idx, array) => ({
                selected: this.props.selectedLanguage === lang,
                content: langNames(lang),
                lang,
                label: this.props.selectedLanguage === lang
                    ? this.props.listItemSelectedAriaLabel(lang)
                    : this.props.listItemNotSelectedAriaLabel(lang),
                click: () => this.props.selectLanguage(lang),
            }));

        return (
            <Dropdown
                dropdownId={modalIds.MULTILANGUAGE_PICKER_MODAL_ID}
                dropdownText={langNames(this.props.selectedLanguage)}
                items={items}
                label={this.props.dropdownAriaLabel}
                reversed
                dropdownOpened={this.dropdownOpened}
                isMasthead={true}
                renderOptionContent={item => (
                    <span lang={item.lang}>
                        {item.content}
                    </span>
                )}
            />
        );
    }
}

export default compose(
    withAnalytics2(
        (eventTrigger, eventIds) => ({
            languageEvent: () => eventTrigger(eventIds.MULTI_LANGUAGE),
        })
    ),
    withTranslate((translate, props) => ({
        dropdownAriaLabel: translate('common.open_language_menu', 'Open Language Menu'),
        // list items
        listItemSelectedAriaLabel: lang => translate(
            'common.selected_language_-_<language>',
            `Selected language - ${langNames(lang)}`,
            [
                ['<language>', langNames(lang)]
            ]
        ),
        listItemNotSelectedAriaLabel: lang => translate(
            'common.select_language_-_<language>',
            `Select language - ${langNames(lang)}`,
            [
                ['<language>', langNames(lang)]
            ]
        ),
    })),
    connect(
        state => ({
            selectedLanguage: getSelectedLanguage(state),
            availableLanguages: getAvailableLanguages(state),
        }),
        dispatch => ({
            selectLanguage: language => dispatch(selectLanguage(language)),
        }),
    ),
)(LanguagePicker);
