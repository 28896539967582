import React from 'react';
import { Row, Col } from 'react-bootstrap';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';

const BankerDetails = (props) => {
    const spacingSm = { marginBottom: 5 };

    const bankerInfo = (banker, ind) => (
        <Col style={{marginBottom:10}} key={ind} sm={6}>

            <div style={spacingSm} className="text-small text-univ-lt">
                <TranslationText
                    dictionaryKey={"roadshow.banker"}
                    defaultText={"Banker"}
                />
            </div>
            <div>{banker.company}</div>
            <div>{banker.name}</div>
            <div>{ValueFormatters.choosePhoneFormatter(banker)}</div>
        </Col>
    )

    const salesInfo = (salesContact, ind) => (
        <Col style={{marginBottom:10}} key={ind} sm={6}>

            <div style={spacingSm} className="text-small text-univ-lt">
                <TranslationText
                    dictionaryKey={"roadshow.sales_contact"}
                    defaultText={"Sales Contact"}
                />
            </div>
            <div>{salesContact.company}</div>
            <div>{salesContact.name}</div>
            <div>{ValueFormatters.choosePhoneFormatter(salesContact)}</div>
        </Col>
    )

    const { bankers, salesContacts } = props;
    return (
        <Row className="text-normal" style={{ paddingLeft: 15 }}>
            {salesContacts && salesContacts.map(salesInfo)}
            {bankers && bankers.map(bankerInfo)}
        </Row>
    )
}

export default BankerDetails