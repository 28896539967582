import { connect } from 'react-redux';

import { getSelectedTab } from 'selectors';
import { changeTab } from 'actions/UtilityActions';

function withTabs(action) {
    return Cmp => connect(
        (state, ownProps) => ({
            activeTab: getSelectedTab(state, ownProps.tabsId),
        }),
        (dispatch, ownProps) => (
            action(id => dispatch(changeTab(ownProps.tabsId, id)))
        )
    )(Cmp);
}

export default withTabs;
