import dealFeatures from './dealFeatures';

export const featurePageIds = {
    // auth 
    // TODO - when external/internal auth page will have own url pls improve:
    // analytics constants line 30 & 35
    // analytics saga line 61 & 63
    INTERNAL_LOGIN_PAGE: '/',
    EXTERNAL_LOGIN_PAGE: '/',
    EXTERNAL_TOTP_LOGIN_PAGE: '/',
    EXTERNAL_PN_LOGIN_PAGE: '/',
    SETTINGS_PAGE: '/settings',
    // home
    LANDING_PAGE: '/',
    // orders
    ORDERBOOK_SUMMARY_PAGE: `${dealFeatures.ORDERBOOK}/summary`,
    ORDERBOOK_FULL_PAGE: `${dealFeatures.ORDERBOOK}/full`,
    ORDERBOOK_AMENDED_PAGE: `${dealFeatures.ORDERBOOK}/amended`,
    ORDERBOOK_EVOLUTION_PAGE:  `${dealFeatures.ORDERBOOK}/evolution`,
    // investor feedback
    INVESTOR_FEEDBACK_SUMMARY_PAGE: `${dealFeatures.INVESTOR_FEEDBACK}/summary`,
    INVESTOR_FEEDBACK_DETAILS_PAGE: `${dealFeatures.INVESTOR_FEEDBACK}/details`,
    // roadshow
    ROADSHOW_DAILY_SCHEDULE_PAGE: `${dealFeatures.ROADSHOW}/dailySchedule`,
    ROADSHOW_DOCUMENT_MODE_PAGE: `${dealFeatures.ROADSHOW}/documentMode`,
    // my docs
    MY_DOCS_DOCUMENTS_PAGE: dealFeatures.MY_DOCS,
    // pricing
    PRICING_DOCUMENTS_PAGE: dealFeatures.PRICING,
    // investors
    INVESTORS_PAGE: dealFeatures.INVESTORS,
    DATAPRIVACY_NOTICE_PAGE: `${dealFeatures.SETTINGS}/privacynotice`,
    COOKIE_NOTICE_PAGE: `${dealFeatures.SETTINGS}/cookienotice`,
    DATAPRIVACY_NOTICE_PAGE_EXTERNAL: '/privacynotice',
    COOKIE_NOTICE_PAGE_EXTERNAL: '/cookienotice',
}

export const overlayIds = {
    // modals etc.
    SESSION_EXPIRY_OVERLAY: 'session expiry overlay',
};

/*
 * Order of the feature pages is important for analytics saga worker
 * (pls do not change it - add new pages at the end of each list)
 */
const featurePages = {
    [dealFeatures.SETTINGS]: [
        featurePageIds.SETTINGS_PAGE,
    ],
    [dealFeatures.ORDERBOOK]: [
        featurePageIds.ORDERBOOK_SUMMARY_PAGE,
        featurePageIds.ORDERBOOK_FULL_PAGE,
        featurePageIds.ORDERBOOK_AMENDED_PAGE,
        featurePageIds.ORDERBOOK_EVOLUTION_PAGE
    ],
    [dealFeatures.ROADSHOW]: [
        featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE,
        featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE,
    ],  
    [dealFeatures.INVESTOR_FEEDBACK]: [
        featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE,
        featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE,
    ],
    [dealFeatures.MY_DOCS]: [
        featurePageIds.MY_DOCS_DOCUMENTS_PAGE,
    ],
    [dealFeatures.PRICING]: [
        featurePageIds.PRICING_DOCUMENTS_PAGE,
    ],
    [dealFeatures.INVESTORS]: [
        featurePageIds.INVESTORS_PAGE,
    ],
};



export default featurePages;