import { createTypes } from 'reduxsauce';

//
// Unique prefix for feature actions
//
const createPrefix = (obj, prefix) => Object.keys(obj)
  .reduce((acc, key) => {
    acc[key] = `${prefix}${obj[key]}`;
    return acc;
  }, {});

const All = createTypes(`
  API_FAILURE
  API_REQUEST
  KILL_ACTIVE_SAGA_WORKERS
  APPLY_CLOSE_INFORMATION_BANNER
  APPLY_HANDLE_CONFIRMATION_DIALOUGE
  APPLY_HANDLE_UPLOAD_OVERLAY
  APPLY_HANDLE_FILE_UPLOAD
  START_UPLOADING
  UPLOAD_PROGRESS
  SET_SNACK
  CLOSE_SNACK_BAR
  HANDLE_CLIENT_DOCUMENT_DATA
  HANDLE_COMMENTS_POPUP
  HANDLE_TITLE_COLUMN_WIDTH
  HANDLE_ECB_STATUS
  HANDLE_REFERENCE_INFORMATION_STATUS
  
  
  API_REQUEST_DASHBOARD_DATA
  API_RECEIVE_DASHBOARD_DATA
  API_REQUEST_DOWNLOAD_DOCUMENT
  API_REQUEST_UPLOAD_DOCUMENTS
  API_APPROVE_REJECT_REQUEST_STATUS
  API_REQUEST_DELETE_SUPPORTING_DOCUMENTS
  API_REQUEST_SUBMIT_INFO
  API_REQUEST_SUBMIT_DOCUEMENT_COMMENT
  API_REQUEST_LATEST_COMMENTS_FOR_CURRENT_DEAL
  API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_DEAL
  API_REQUEST_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT
  API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT
  API_REQUEST_SUBMIT_FINAL_SUBMISSION
  API_REQUEST_DOWNLOAD_ALL_DOCUMENTS
  API_REQUEST_ECB2_RETURN_DATA_FOR_CURRENT_DEAL
  API_RECEIVE_ECB2_RETURN_DATA_FOR_CURRENT_DEAL
  API_REQUEST_CLIENT_ECB_SUBMIT
  API_REQUEST_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL
  API_RECEIVE_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL
  API_REQUEST_DOWNLOAD_REFERENCE_DOCUMENT
  API_REQUEST_UPLOAD_REFERENCE_DOCUMENT
  API_REQUEST_DELETE_REFERENCE_DOCUMENTS
`);

export const IsvUtilityTypes = createPrefix(
  createTypes(`
    API_GET_ISV_DEALS_REQUEST
    API_GET_ISV_DEALS_RESPONSE
    API_GET_ISV_DEAL_REQUEST
    API_GET_ISV_DEAL_RESPONSE
    SETUP_ISV_DEAL
    OPEN_ONE_WAY_LETTER
    ACCEPT_ONE_WAY_LETTER
    ISV_SWITCH_DEAL
    ISV_BASE_FETCH_COMPLETED
    API_ACCEPT_LETTER
    API_REQUEST_ISV_DEAL_CLICK
    API_ACCEPT_LETTER_CLICK
    DATAFETCHING
  `),
  'Utility_Types_',
);

export default {
  ...All,
};
