import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import { typography } from 'shared/styles/variables/theme';

//
// Headers
//
const headerBase = {
    fontSize: typography.sizes['12px'],
    lineHeight: '14px',
    height: '38px',
    verticalAlign: 'top',
    color: '#333333',
    fontWeight: 600,
};

export const firstHeaderAlignedLeftStyle = {
    ...headerBase,
    textAlign: 'left',
};

export const firstHeaderAlignedCenterStyle = {
    ...headerBase,
    textAlign: 'center',
};

export const firstHeaderAlignedRightStyle = {
    ...headerBase,
    textAlign: 'right',
};

export const otherHeaderAlignedRightStyle = {
    ...headerBase,
    textAlign: 'right',
}

export const lastHeaderAlignedRightStyle = {
    ...headerBase,
    padding: '8px 10px 8px 2px',
    textAlign: 'right',
}

export const otherHeaderAlignedLeftStyle = {
    ...headerBase,
    padding: '8px 2px',
    textAlign: 'left',
}

export const textCapitalize = {
    ...headerBase,
    textTransform: 'capitalize'
}

//
// Rows
//
const rowBase = {
    height: '28px',
    verticalAlign: 'middle',
    lineHeight: '14px',
    outline: 'none',
    padding: '5px 5px',
};

export const firstRowAlignedLeftStyle = {
    ...rowBase,
}

export const firstRowAlignedRightStyle = {
    ...rowBase,
    textAlign: 'right',
};

export const lastRowAlignedLeftStyle = {
    ...rowBase,
}

export const lastRowAlignedRightStyle = {
    ...rowBase,
};

export const otherRowAlignedLeft = {
    ...rowBase,
    textAlign: 'left',
}

export const otherRowAlignedRight = {
    ...rowBase,
    textAlign: 'right',
}

export const otherRowAlignedCenter = {
    ...rowBase,
    textAlign: 'center',
}

//
// All Tables in app should use this cmp
//
const Table = React.memo(props => (
    <BootstrapTable
        data={props.data}
        bordered={props.bordered || false}
        {...props}
    >
        {props.children}
    </BootstrapTable>
));

export default Table;
