import styled from 'styled-components';

export const FloatedRightSpan = styled.span`
    float: right;
`;

export const ListItemContainer = styled.div`
    padding: 0 20px;
`;

export const listGroupItemInlineStyles = {
    padding: 0,
    borderRight: 'none',
    borderLeft: 'none',
};