import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import withTranslate from 'shared/hoc/withTranslate';
import Table from 'shared/components/Table';

import { Figure, FigCaption } from 'shared/accessibility/components/Caption';

import { beautifyPriceDemand } from '../util';

const TableContainer = styled.div`
    display: block !important;
    width 100%;
`;

const Columns = props => ([
    {
        dataField: 'name',
        text: props.trancheHeader,
        attrs: {
            role: "rowheader",
        },
        headerStyle: {
            width: 100 / 12 * 8 +'%',
            border: 'none',
            background: 'none',
            paddingLeft: 0,
            paddingTop: 5,
            paddingBottom: 10,
        },
        style: {
            width: 100 / 12 * 8 +'%',
            paddingLeft: 0,
            fontSize: '0.750rem',
            paddingTop: 8,
            paddingBottom: 8,
        },
        formatter: (value, data) => {
            return data.description || data.name;
        },
    },
    {
        dataField: 'ioi',
        text: props.ioiHeader,
        attrs: {
            role: "rowheader",
        },
        headerStyle: {
            width: 100 / 12 * 2 +'%',
            border: 'none',
            background: 'none',
            paddingTop: 5,
            paddingBottom: 10,
        },
        style: {
            width: 100 / 12 * 2 +'%',
            fontSize: '0.750rem',
            paddingTop: 8,
            paddingBottom: 8,
        },
    },
    {
        dataField: 'priceSensitvity',
        text: props.priceSensitivityHeader,
        attrs: {
            role: "rowheader",
        },
        headerStyle: {
            textAlign: 'right',
            width: 100 / 12 * 2 +'%',
            border: 'none',
            background: 'none',
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 10,
        },
        style: {
            textAlign: 'right',
            width: 100 / 12 * 2 +'%',
            fontSize: '0.750rem',
            paddingRight: 0,
            paddingTop: 8,
            paddingBottom: 8,
        },
    },
]);

class MultipleTranche extends PureComponent {
    render() {
        const { feedbackTranchePriceDemand }  = this.props;

        // if it's not defined
        if (!feedbackTranchePriceDemand) return null;

        const tableData = feedbackTranchePriceDemand.map(tranche => ({
            name: tranche.trancheShortName,
            description: tranche.trancheDescription,
            ioi: beautifyPriceDemand(tranche) || this.props.notProvidedText,
            priceSensitvity: tranche.priceDemand.valuation || this.props.notProvidedText,
        }))

        return (
            <TableContainer>
                <Figure>
                    <FigCaption>{this.props.tableCaption}</FigCaption>
                    <Table
                        keyField='name'
                        data={tableData}
                        columns={Columns(this.props)}
                    />
                </Figure>
            </TableContainer>
        )
    }
}

export default compose(
    withTranslate(translate => ({
        tableCaption: translate('', 'Table showing tranches with their ioi and price sensitivity'),
        trancheHeader: translate('investor_feedback.tranche', "Tranche"),
        ioiHeader: translate('investor_feedback.ioi', 'IoI'),
        priceSensitivityHeader: translate('investor_feedback.price_sensitivity', 'Price Sensitivity'),
        notProvidedText: translate('investor_feedback.not_provided', 'Not Provided')
    }))
)(MultipleTranche);
