
export const convertNumbersToFullName = short => {
    switch(short) {
        case 'b': return 'billion';
        case 'm': return 'million';
        case 't': return 'thousands';
        default:
            return short;
    }
}

export const convertNumbersToFullKey = short => {
    switch(short) {
        case 'b': return 'others.billion';
        case 'm': return 'others.million';
        case 't': return 'others.thousands';
        default:
            return short;
    }
}