import { delay } from 'redux-saga';
import { call, put, select, cancel, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import Types from 'actions/Types';
import {
    checkPulse,
    logOff,
    heartBeatPulse,
    // killHeartBeat,
    renewToken
} from 'actions/HeartBeatActions';
import * as storageKeys from 'constants/storageKeys';

export default (api) => {

    function* worker(action) {
        switch (action.type) {
            case Types.HEARTBEAT_PULSE: {
                const sessionCountdown = 150000; // 2min 10sec to give a margin on error
                const timeUntilExpire = (window.sessionStorage.getItem(storageKeys.EXPIRATION) - Date.now())

                const delayTime = timeUntilExpire - sessionCountdown;

                yield delay(delayTime);

                const getLastUserInteraction = (state) => state.utility.lastUserInteraction;
                const lastUserInteraction = yield select(getLastUserInteraction);

                // if has user interacted during this time period since we last renewed we will autorenew.
                if ((Date.now() - lastUserInteraction) < sessionCountdown) {
                    yield put(renewToken());
                } else {
                    const pulseExpire = (((window.sessionStorage.getItem(storageKeys.EXPIRATION) - Date.now()) - 30000) / 1000)
                    yield put(checkPulse(true, Math.floor(pulseExpire)));
                }

                break;
            }
            case Types.RENEW_TOKEN: {
                let response = yield call(api.getData, action.endPoint);
                // check if we got a token
                if (response.ok) {
                    yield put(action.onSuccess(response.data || {}, action.responseDetails));
                    yield put(heartBeatPulse());
                } else {
                    // If token request failed, log the user out
                    yield put(logOff())
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            Types.HEARTBEAT_PULSE,
            Types.RENEW_TOKEN,
            Types.LOGOFF,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, [Types.KILL_HEARTBEAT], cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker
    }
}
