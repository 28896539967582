import React, { Component } from 'react';
import styled from 'styled-components';

import ScrollableTable from 'shared/components/ScrollableTable';

const ScrollbleContainer = styled.div`

`;

class OrderBookTable extends Component {
  render() {
    return (
      <ScrollbleContainer>
        <ScrollableTable
          classes="mydeal-table fixed-header top-header bottom-footer"
          data={this.props.data}
          bordered={false}
          keyField={this.props.keyField}
          columns={this.props.columns}
          defaultSorted={this.props.defaultSorted}
          caption={this.props.caption}
        />
      </ScrollbleContainer>
    );
  }
}

export default OrderBookTable;
