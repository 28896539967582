import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

import TranslationText from 'translations/TranslationText';

const infoStyles = { marginTop: 50 };

class PageContentNotAvailable extends PureComponent {
  render() {
    return (
      <Row>
        <Col sm={12}>
          <p className="text-center" style={infoStyles}>
            <TranslationText
              dictionaryKey={"others.content_not_yet_available"}
              defaultText={"Content Not Yet Available"}
            />
          </p>
        </Col>
      </Row>
    )
  }
}

export default PageContentNotAvailable;
