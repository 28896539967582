import IsvTypes from "./IsvTypes";

export const applyIsvCloseBanner = (label) => ({
  type: IsvTypes.APPLY_CLOSE_INFORMATION_BANNER,
  label,
});

export const handleConfirmationDialogue = (value, deleteDealDocumentId) => ({
  type: IsvTypes.APPLY_HANDLE_CONFIRMATION_DIALOUGE,
  value,
  deleteDealDocumentId
})

export const handlePostDashboardData = data => ({
  type: IsvTypes.API_REQUEST_DASHBOARD_DATA,
  endPoint: '/Deals/UpdateDeal',
  params: {
    dealId: data.dealId,
    maturityDate: data.maturityDate,
    lrnReference: data.lrnReference,
  },
  ajaxType: 'PUT',
})

export const handleDashboardResponse = data => {
  return ({ 
    type: IsvTypes.API_RECEIVE_DASHBOARD_DATA,
    data
}) }
