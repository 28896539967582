import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getAllowedNavigationFeatures, getAllNavigationFeatures, isECMDealType, isDCMDealType } from 'selectors';

import dealFeatures from 'constants/dealFeatures';
import { checkAllowedUiFeatures } from 'util/navigationFeatures';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import * as dimensions from 'shared/styles/variables/dimensions';
import responsiveness from 'shared/styles/mixins/responsiveness';
import { typography, fontColors } from 'shared/styles/variables/theme';
import { ContentAdjuster } from 'shared/styles/components/containers';

import { landingDimensions } from './Landing.styles';

import landingImageHD from 'images/landing/landing-hd.jpg';
import landingImageFHD from 'images/landing/landing-fhd.jpg';
import landingImage5K from 'images/landing/landing-5k.jpg';

import LatestFeedback from './Tiles/LatestFeedback.component';
import LatestDocuments from './Tiles/LatestDocuments.component';
import LatestECMORders from './Tiles/ecm/LatestOrders.component';
import LatestDCMOrders from './Tiles/dcm/LatestOrders.component';
import LatestDCMOrder from './Tiles/dcm/LatestOrder.component';

import { PricingIcon, RoadshowIcon, FeedbackIcon, OrdersIcon, DocumentsIcon } from 'mk-pattern-library-react/components/SvgIcon/SvgIcon';

import Hero from './Hero.component';

const maxHeroHeight = 400;
const tileMargin = 10;

const LandingContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - ${dimensions.NAVIGATION_HEIGHT}px);
    background: radial-gradient(circle, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 80%, rgba(0,0,0,0) 100%);
    background-image: url(${landingImageHD});

    ${responsiveness.largeScreen(`
        background-image: url(${landingImage5K});
    `)}

    ${responsiveness.mediumScreen(`
        background-image: url(${landingImageFHD});
    `)}

    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: -1;

    height: 100%;
    width: 100%;

    padding-bottom: 10px;
    margin-bottom: -10px;

`;

const WelcomeContainer = styled.div`
    position: relative;
`;

// IE 11 fix
const EnhancedContentAdjuster = styled(ContentAdjuster)`
    display: flex;
    flex-direction: column;
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
    height: 100%;
    min-height: calc(100vh - ${dimensions.NAVIGATION_HEIGHT}px - ${landingDimensions.TILE_HEIGHT}px - ${2 * tileMargin}px);
    padding: 0 0 0 50px;

    ${responsiveness.largeScreen(`
        max-height: ${maxHeroHeight}px;
    `)}

    ${responsiveness.mediumScreen(`
        max-height: ${maxHeroHeight}px;
    `)}
`;

const LandingH1 = styled.h1`
    margin: 0;
    padding: 0;
    font-size: ${typography.sizes['37px']};
    font-family: ${typography.family.light};
    color: ${fontColors.white};
`;
const LandingH2 = styled.h2`
    margin: 0;
    padding: 5px 0 0 0;
    font-size: ${typography.sizes['13px']};
    font-family: ${typography.family.light};
    color: ${fontColors.white};
`;

const HeroItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-bottom: 30px;
`;

const CarouselContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;

    > div {
        margin: ${tileMargin}px ${tileMargin/2}px 0 ${tileMargin/2}px;
    }
`;

class Landing extends PureComponent {
    componentDidMount() {
        this.props.viewChange();
    }

    providePricing = (url, sort, featureIcon) => (
        <Hero
            id='link-pr-pricing'
            key="p"
            sort={sort}
            url={url}
            icon={'gPrice'}
            featureIcon={featureIcon}
            title={this.props.pricing}
            text={this.props.pricingDescription}
            onHeroClicked={this.props.pricingEvent}
        />
    )

    provideRoadshow = (url, sort, featureIcon) => (
        <Hero
            id='link-pr-roadshow'
            key="r"
            sort={sort}
            url={url}
            icon={'gPlane'}
            featureIcon={featureIcon}
            title={this.props.roadshow}
            text={this.props.roadshowDescription}
            onHeroClicked={this.props.roadshowEvent}
        />
    )

    provideOrders = (url, sort, featureIcon) => (
        <Hero
            id='link-pr-orders'
            key="o"
            sort={sort}
            url={url}
            icon={'Orders'}
            featureIcon={featureIcon}
            title={this.props.orders}
            text={this.props.ordersDescription}
            onHeroClicked={this.props.ordersEvent}
        />
    )

    provideMyDocs = (url, sort, featureIcon) => (
        <Hero
            id='link-pr-docs'
            key="md"
            sort={sort}
            url={url}
            icon={'Document'}
            featureIcon={featureIcon}
            title={this.props.myDocuments}
            text={this.props.myDocsDescription}
            onHeroClicked={this.props.myDocsEvent}
        />
    )

    provideInvestorFeedback = (url, sort, featureIcon) => (
        <Hero
            id='link-pr-in-fb'
            key="if"
            sort={sort}
            url={url}
            icon={'ChatBubble'}
            featureIcon={featureIcon}
            title={this.props.investorFeedback}
            text={this.props.investorFeedbackDescription}
            onHeroClicked={this.props.investorFeedbackEvent}
        />
    )

    provideInvestors = (url, sort) => (
        <Hero
            id='link-pr-in'
            key="i"
            sort={sort}
            url={url}
            icon={'Profile'}
            title={this.props.investors}
            text={this.props.investorsDescription}
            onHeroClicked={this.props.investorsEvent}
        />
    );

    icons = {
        [dealFeatures.PRICING]: this.providePricing,
        [dealFeatures.INVESTORS]: this.provideInvestors,
        [dealFeatures.ROADSHOW]: this.provideRoadshow,
        [dealFeatures.ORDERBOOK]: this.provideOrders,
        [dealFeatures.MY_DOCS]: this.provideMyDocs,
        [dealFeatures.INVESTOR_FEEDBACK]: this.provideInvestorFeedback,
    }

    sortChildrens = ({ props: a }, { props: b }) => {
        if (a.sort < b.sort) return -1;
        if (a.sort > b.sort) return 1;
        return 0;
    }

    render() {
        const activeHomeModules = [];
        const items = [];

        const {
            allowedNavigationFeatures
        } = this.props;

        const allowedUiFeatures = checkAllowedUiFeatures(allowedNavigationFeatures, 'visibleInHeroMenu');

        // Hero Menu
        allowedUiFeatures
            .filter(feature => feature.visibleInHeroMenu)
            .map(feature => ({
                url: feature.visibleInMainMenu ? feature.url : false,
                sortNo: feature.sortNo,
                feature: feature.url,
            }))
            .sort(({ sortNo: a }, { sortNo: b}) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            })
            .forEach(({ sortNo, url, feature }) => {
                switch (feature) {
                    case dealFeatures.INVESTOR_FEEDBACK: {
                        items.push(this.provideInvestorFeedback(url, sortNo, <FeedbackIcon width="40" height="40" color="#fff" />));

                        if (!url) return
                        activeHomeModules.push(<LatestFeedback key={'lf'} />);
                        break;
                    }
                    case dealFeatures.ROADSHOW: {
                        items.push(this.provideRoadshow(url, sortNo, <RoadshowIcon width="40" height="40" color="#fff" />));

                        if (!url) return;
                        // No home module available
                        break;
                    }
                    case dealFeatures.ORDERBOOK: {
                        items.push(this.provideOrders(url, sortNo, <OrdersIcon width="40" height="40" color="#fff" />));

                        if (!url) return;

                        if (this.props.isECM) {
                            activeHomeModules.push(<LatestECMORders key={'le'} />);
                        }

                        if (this.props.isDCM) {
                            activeHomeModules.push(<LatestDCMOrders key={'ld1'} />)
                            activeHomeModules.push(<LatestDCMOrder key={'ld2'} />);
                        }

                        break;
                    }
                  /*  case dealFeatures.INVESTORS: {
                        items.push(this.provideInvestors(url, sortNo));

                        if (!url) return;
                        //  activeHomeModules.push(<EngagementChartWithData />); // TODO
                        break;
                    }*/
                    case dealFeatures.MY_DOCS: {
                        items.push(this.provideMyDocs(url, sortNo, <DocumentsIcon Icon width="40" height="40" color="#fff" /> ));

                        if (!url) return;
                        activeHomeModules.push(<LatestDocuments key={'ld'}/>);
                        break;
                    }
                    case dealFeatures.PRICING: {
                        items.push(this.providePricing(url, sortNo, <PricingIcon width="40" height="40" color="#fff" />));

                        if (!url) return;
                        // No home module available
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });

        return (
            <LandingContainer>
                <WelcomeContainer>
                    <EnhancedContentAdjuster>
                        <HeadingContainer>
                            <LandingH1>{this.props.headingTitle}</LandingH1>
                            <LandingH2>{this.props.headingDescription}</LandingH2>
                            <HeroItems>
                                {
                                    React.Children
                                        .toArray(items)
                                        .sort(this.sortChildrens)
                                }
                            </HeroItems>
                        </HeadingContainer>
                    </EnhancedContentAdjuster>
                </WelcomeContainer>
                <ContentAdjuster>
                    <CarouselContainer>
                        {activeHomeModules}
                    </CarouselContainer>
                </ContentAdjuster>
            </LandingContainer>
        );
    }
}

export default compose(
    withTranslate(translate => ({
        headingTitle: translate(
            'home_page.welcome_to_mydeal',
            'Welcome to MyDeal'
        ),
        headingDescription: translate(
            'home_page.bringing_the_latest_deal_information_to_your_finge',
            'Bringing the latest deal information to your fingertips'
        ),
        myDocuments: translate('home_page.documents', 'Documents'),
        myDocsDescription: translate('home_page.view_all_your_documents_in_one_centralised_locatio', 'View all your documents in one centralised location'),
        pricing: translate('home_page.pricing', 'Pricing'),
        pricingDescription: translate('home_page.keep_track_of_the_latest_pricing_document_for_your', 'Keep track of the latest pricing document for your deal'),
        orders: translate('home_page.orders', 'Orders'),
        ordersDescription: translate('home_page.monitor_and_analyse_investor_demand_and_orders_ove', 'Monitor and analyse investor demand and orders over time'),
        roadshow: translate('home_page.roadshow', 'Roadshow'),
        roadshowDescription: translate('home_page.plan_with_confidence_and_prepare_for_investor_meet', 'Plan with confidence and prepare for investor meetings'),
        investorFeedback: translate('home_page.investor_feedback', 'Investor Feedback'),
        investorFeedbackDescription: translate('home_page.gauge_investor_sentiment_throughout_your_deal', 'Gauge investor sentiment throughout your deal'),
        investors: translate('home_page.investors', 'Investors'),
        investorsDescription: translate('home_page.compare_and_contrast_investor_history_and_commitme', 'Compare and contrast investor history and commitment'),
    })),
    withAnalytics2(
        (eventTrigger, eventIds) => ({
            myDocsEvent: () => eventTrigger(eventIds.EXPLORE_MY_DOCS),
            pricingEvent: () => eventTrigger(eventIds.EXPLORE_PRICING),
            ordersEvent: () => eventTrigger(eventIds.EXPLORE_ORDERS),
            roadshowEvent: () => eventTrigger(eventIds.EXPLORE_ROADSHOW),
            investorFeedbackEvent: () => eventTrigger(eventIds.EXPLORE_INVESTOR_FEEDBACK),
            investorsEvent: () => eventTrigger(eventIds.EXPLORE_INVESTORS),
        }),
        (viewChange, viewIds) => ({
            viewChange: () => viewChange(viewIds.LANDING_PAGE),
        })
    ),
    connect(state => ({
        isECM: isECMDealType(state),
        isDCM: isDCMDealType(state),
        navigationFeatures: getAllNavigationFeatures(state),
        allowedNavigationFeatures: getAllowedNavigationFeatures(state),
    }))
)(Landing)
