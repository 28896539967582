import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withTranslate from 'shared/hoc/withTranslate';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';
import { dateFormatter } from '../../SharedTableComponents/formatters';
import SortState, { SORT } from 'shared/components/SortState';
import baseColumnProps from '../../SharedTableComponents/baseColumnProps';
import { SvgIcon } from 'shared/components/Icon';
import { selectIsAmended, getSelectedInvestorType } from 'selectors';
import ScrollableTable from 'shared/components/ScrollableTable';
import {
  sortedHeaderStyle,
  centerAlignedCellStyle,
  rightAlignedCellStyle,
  leftAlignedCellStyle,
  leftAlignedHeaderStyle,
  centerAlignedHeaderStyle,
} from '../../shared/table/styles';
import styled from 'styled-components';
import { ORDER_STATUS } from 'constants/orders';
import { DEFAULT_ALL } from 'constants/orders';
import { selectInvestorTypeDropdown } from 'actions/OrderBookActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ValueFormatters } from 'mk-pattern-library-react';

const FullorderTableContainer = styled.div`
    position: relative;
    height: 15px;
`;

const styles = {
  display: 'flex',
  alignItems: 'center'
}

const DropDownContainer = styled.div`
  ${styles}
  margin-top:30px
`;

const InvestorTypeDropDownContainer = styled.div`
  ${styles}
  span {
      margin-right: 10px
  }
`;

const DropDownLabel = styled.span``;

const textStyle = {
  textTransform: 'capitalize'
}

const arrowUpIconStyle = {
  fill: 'green',
  height: '50px',
  width: '50px',
  marginTop: '7px',
  marginLeft: '3px'
}

const arrowDownIconStyle = {
  fill: 'red',
  height: '50px',
  width: '50px',
  marginTop: '12px',
  marginLeft: '3px',
  marginBottom: '-8px'
}

const arrowUpHeaderStyle = {
  fill: 'green',
  height: '50px',
  width: '50px',
  marginLeft: '-13px',
}

const arrowDownHeaderStyle = {
  fill: 'red',
  height: '50px',
  width: '50px',
  marginTop: '-57px',
  marginLeft: '10px',
}

const arrowDropdownIconStyle = {
  transform: 'rotate(90deg)',
  marginTop: '2px',
  width: '18px',
  height: '18px',
}

const applyRowbackground = (backgroundColor) => ({
  background: backgroundColor
})

const Columns = (props, sort) => {
  const defaultColumnProps = baseColumnProps(sort);
  const columns = [
    {
      ...defaultColumnProps,
      attrs: {
        role: "rowheader",
      },
      dataField: 'orderId',
      text: props.orderIdText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.70
    },
    {
      ...defaultColumnProps,
      dataField: 'investorLongName',
      text: props.investorNameText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1.50,
    },
    {
      dataField: 'investorType',
      text: props.investorTypeText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
    },
    {
      ...defaultColumnProps,
      dataField: 'investorCountry',
      text: props.investorCountryText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.95,
    },
    {
      ...defaultColumnProps,
      dataField: 'currency',
      text: props.currencyText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: props.isAmended ? centerAlignedHeaderStyle : leftAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.70,
    },
    {
      ...defaultColumnProps,
      dataField: 'orderAmount',
      text: props.orderAmountText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: leftAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.65,
      formatter: ValueFormatters.commaDecimalFormatter
    },
    {
      ...defaultColumnProps,
      dataField: 'limitSecurity',
      text: props.limitSecurityText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1.20,
    },
    {
      ...defaultColumnProps,
      dataField: 'trancheName',
      text: props.trancheNameText,
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 1,
    },
    {
      ...defaultColumnProps,
      dataField: 'externalSourceLastModified',
      text: props.dateText,
      headerClasses: '',
      headerStyle: leftAlignedHeaderStyle,
      style: leftAlignedCellStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.95,
      formatter: (value, data, index) => (
        dateFormatter('localTime', value)
      ),
    },
  ];

  if (props.isAmended === true) {
    columns.push(
      {
        ...defaultColumnProps,
        isDummyField: 'amendedAmount',
        text: <><SvgIcon icon="arrow-up" style={arrowUpHeaderStyle} aria-hidden /><SvgIcon icon="arrow-down" style={arrowDownHeaderStyle} aria-hidden /></>,
        headerClasses: 'sorticondisable',
        style: centerAlignedCellStyle,
        headerStyle: centerAlignedHeaderStyle,
        headerSortingStyle: sortedHeaderStyle,
        columnFlex: 0.55,
        formatter: (value, data, index) => (
          (data['amendedAmount'] !== null) ?
            (data['amendedAmount'] > 0 ?
              <span className="upArrow">
                <SvgIcon
                  icon="arrow-up"
                  aria-hidden
                  style={arrowUpIconStyle}
                />
              </span>
              :
              <span className="downArrow">
                <SvgIcon
                  icon="arrow-down"
                  aria-hidden
                  style={arrowDownIconStyle}
                />
              </span>) : undefined
        ),
      }, {
      ...defaultColumnProps,
      dataField: 'amendedAmount',
      text: props.amendedAmountText,
      headerClasses: '',
      style: rightAlignedCellStyle,
      headerStyle: centerAlignedHeaderStyle,
      headerSortingStyle: sortedHeaderStyle,
      columnFlex: 0.80,
      formatter: ValueFormatters.commaDecimalFormatter
    },
    );
  }
  columns.push({
    ...defaultColumnProps,
    dataField: 'status',
    text: props.statusText,
    headerClasses: 'sorticondisable',
    headerStyle: leftAlignedHeaderStyle,
    style: leftAlignedCellStyle,
    headerSortingStyle: sortedHeaderStyle,
    columnFlex: 0.75,
    formatter: (value, data, index) => (
      <p style={textStyle}>{data['status'].toLowerCase()}</p>
    ),
  })

  return columns;
}

class FullOrderBookAllTranchesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allInvestorType: [],
    };
  }

  componentWillMount() {
    this.sort = new SortState('externalSourceLastModified', SORT.ASCENDING, this.props.columnSortEvent, this.props.onSort);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.length) {
      const getInvestorTypes = this.props.data.map((order) => {
        return {
          label: order.investorType, value: order.investorType
        }
      })

      const uniqueInvestorTypes = getInvestorTypes.filter((elem, index) => getInvestorTypes.findIndex(obj => obj.value === elem.value) === index).sort((a, b) => a.value.trim() < b.value.trim() ? -1 : 1)
      uniqueInvestorTypes.unshift(DEFAULT_ALL)
      this.setState({
        allInvestorType: uniqueInvestorTypes
      });
    }
  }

  setRowBackgroundStyle = (row) => {
    switch (row.status) {
      case ORDER_STATUS.DELETED:
      case ORDER_STATUS.CANCELLED: return applyRowbackground('#ff0000');
      case ORDER_STATUS.INACTIVE: return applyRowbackground('#808080');
      default: return null;
    }
  }

  onDropdownChange = (e, option) => {
    this.props.selectInvestorTypeDropdown({ text: option.value, value: option.value })
  }

  getFullOrderBookData = (data) => {
    const { selectedInvestorType } = this.props;
    if (selectedInvestorType.value !== 'ALL') {
      return data.filter((item) => item.investorType === selectedInvestorType.value)
    } else return data;
  }

  render() {
    return (
      <>
        {
          this.state.allInvestorType && this.state.allInvestorType.length &&
          <div className="ordersummary-dropdown">
            <DropDownContainer>
              <InvestorTypeDropDownContainer>
                <DropDownLabel>Investor Type:</DropDownLabel>
                <Autocomplete
                  disablePortal
                  id='fullorder-investor-type'
                  options={this.state.allInvestorType}
                  disableClearable
                  sx={{ width: 196 }}
                  popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                  value={this.props.selectedInvestorType.value.length > 25 ? this.props.selectedInvestorType.value.slice(0, 24) + "..." : this.props.selectedInvestorType.value}
                  onChange={(e, value) => this.onDropdownChange(e, value)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  renderInput={(params) => <TextField {...params} variant='outlined' />}
                />
              </InvestorTypeDropDownContainer>
            </DropDownContainer>
          </div>
        }
        <FullorderTableContainer>
        </FullorderTableContainer>
        <ScrollableTable
          id="full-order-table"
          data={this.getFullOrderBookData(this.props.data)}
          classes="fixed-header-table fullorderbook-table"
          keyField="Investor Name"
          bordered={false}
          columns={Columns(this.props, this.sort)}
          rowStyle={this.setRowBackgroundStyle}
        />
      </>
    );
  }

}

export default compose(
  withTranslate((translate, props) => ({
    orderIdText: translate('orders.orderId', 'Order Id'),
    investorNameText: translate('orders.investor_name', 'Investor Name'),
    investorTypeText: translate('orders.investor_types', 'Investor Type'),
    investorCountryText: translate('orders.investor_country', 'Investor Country'),
    currencyText: translate('orders.currency', 'Currency'),
    orderAmountText: translate('orders.order_amount', 'Order Amount') + ' (m)',
    limitSecurityText: translate('orders.limit_security', 'Limit Security'),
    trancheNameText: translate('orders.tranche_name', 'Tranche Name'),
    dateText: translate('orders.date_time_entered', 'Date & Time Entered'),
    amendedAmountText: translate('orders.amended_amount', 'Amended Amount') + ' (m)',
    statusText: translate('orders.status_value', 'Status'),
    notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
  })),
  withAnalytics((ev, view) => ({
    columnSortEvent: (column, sort) => {
      ev(events.FULL_ORDERBOOK_SORT(column, sort));
    }
  })),
  connect(
    state => ({
      isAmended: selectIsAmended(state),
      selectedInvestorType: getSelectedInvestorType(state),
    }),
    dispatch => ({
      selectInvestorTypeDropdown: (investorType) => dispatch(selectInvestorTypeDropdown(investorType))
    })
  )
)(FullOrderBookAllTranchesTable);
