import featurePages from 'constants/featurePages';
import  { allowedUiDealFeatures } from 'constants/dealFeatures';

export const selectAvailableNavigationFeatures = allowedNavFeatures => {
    const features = {};

    if (allowedNavFeatures.length) {
        allowedNavFeatures.forEach((feature) => {
            if (feature.visibleInMainMenu && feature.url !== '/') {
                features[feature.url] = feature.url;
            }
        });
    }

    return features;
}

export const checkAllowedUiFeatures = ( allowedNavigationFeatures, visibleProperty = 'visibleInMainMenu' ) => {
    if (allowedNavigationFeatures.length) {
        allowedNavigationFeatures.filter((e)=> e.url !== '/' && e[visibleProperty] === true).forEach((feature) => {
            const uIElementIndex = allowedUiDealFeatures.findIndex((i) => (i.url === feature.url)) || -1;
            if (uIElementIndex !== -1 && allowedUiDealFeatures[uIElementIndex][visibleProperty] === false) {
                allowedNavigationFeatures[uIElementIndex][visibleProperty] = false;
            }
        });
    }    

    return allowedNavigationFeatures;
}

export const isInThatFeaturePage = dealFeature => {

    const pathname = window.location.pathname;
    const isInThatFeature = featurePages[dealFeature] && featurePages[dealFeature].indexOf(pathname) >= 0;

    return isInThatFeature;
}
