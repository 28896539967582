import { put, cancel, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { browserHistory } from 'react-router';

import dealFeatures, { mapDealFeatureToPageTitle } from 'constants/dealFeatures';
import { featurePageIds } from 'constants/featurePages';
import Types, { UtilityTypes } from 'actions/Types';

import {
    markFeature,
    handleHomeFeature,
    requestFeatureData,
} from 'actions/UtilityActions';
import {
    getPollings,
    getCurrentDealId,
    isFeatureAvailable,
} from 'selectors';
import { isInThatFeaturePage } from 'util/navigationFeatures';

export default (api, dispatch, getState) => {
    function* worker({ dealFeature }) {
        const dealId = yield select(getCurrentDealId);
        if (!dealId) {
            return; // deal was not set up yet
        }

        if (dealFeature === dealFeatures.DEAL) {
            return; // request for deal info (pending)
        }

        // are we on that feature page
        // is that feature allowed for us
        const state = yield select();
        const pollings = getPollings(state);
        const areWeOnThatFeaturePage = isInThatFeaturePage(dealFeature);

        if (areWeOnThatFeaturePage) {
            document.title = mapDealFeatureToPageTitle(dealFeature);
        }

        const isThatFeatureAvailable = isFeatureAvailable(state, dealFeature);
        if (!isThatFeatureAvailable) {
            if (areWeOnThatFeaturePage) {
                browserHistory.push(featurePageIds.LANDING_PAGE);
            }

            return; // we're not on that page so we can leave it as is
        }

        if (!pollings[dealFeature].dirty) {
            return; // pristine -> no request for data needed
        } else if (!areWeOnThatFeaturePage) {
            // depending on feature would be nice to request home page tiles data
            yield put(handleHomeFeature(dealFeature, dealId));

            return; // we're not on that feature page -> requests under the hood are not needed
        }

        // mark as pristine
        yield put(markFeature(dealFeature, false));

        // request data for dedicated feature (we're on that feature page)
        yield put(requestFeatureData(dealFeature, dealId));
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.CHECK_FEATURE
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
