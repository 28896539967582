import Types from 'actions/Types';
import { loginFailure } from 'actions/LoginActions';

const renewTokenSuccess = (response) => {
    if (response.access_token) {
        return ({
            type: Types.RENEW_TOKEN_RECEIVE,
            access_token: response.access_token,
            token_type: response.token_type,
            expires_in: response.expires_in,
        });
    } else if (response.message) {
        return ({
            type: Types.RENEW_TOKEN_RECEIVE,
            message: response.message,
            // missing token details
        });
    } else {
        return ({
            type: Types.RENEW_TOKEN_RECEIVE,
            // Not sure what we might have here
        });
    }
};

const renewToken = () => ({
    type: Types.RENEW_TOKEN,
    endPoint: '/v1/auth/renewToken',
    onSuccess: renewTokenSuccess,
    checkingPulse: false,
    onFailure: loginFailure
});

const LogOffSuccess = () => ({
    type: Types.LOGOFF_SUCCESS,
})

const logOff = () => ({
    type: Types.LOGOFF,
    endPoint: '/v1/auth/logoff',
    onSuccess: LogOffSuccess,
    checkingPulse: false,
    sessionExpired: true,
});

const heartBeatPulse = () => ({
    type: Types.HEARTBEAT_PULSE,
    beating: true,
});

const checkPulse = (checkingPulse, pulseExpire) => ({
    type: Types.CHECK_PULSE,
    checkingPulse,
    pulseExpire: pulseExpire || 120,
});

const killHeartBeat = () => ({
    type: Types.KILL_HEARTBEAT,
});

export {
    renewToken,
    logOff,
    heartBeatPulse,
    checkPulse,
    renewTokenSuccess,
    killHeartBeat,
};
