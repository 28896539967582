import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SecondaryButton } from 'mk-pattern-library-react';
import withTranslate from 'shared/hoc/withTranslate';
import TranslationText from 'translations/TranslationText';
import { translationString } from 'translations/TranslationText';
import { selectLoginFormInvalidFields } from 'selectors';
import LanguagePicker from './LanguagePicker/LanguagePicker';

import { events } from 'constants/analytics';

import MDSpinner from 'react-md-spinner';

import {
  loginFormChanged,
  setAuthType,
  setInvalidFields,
  setPendingAuthentication,
  loginFormPnSubmit,
  setLoginError,
  setUsername,
  setLoginModal,
  cancelLoginFormPnSubmit
} from 'actions/LoginActions';

import { featurePageIds } from 'constants/featurePages';

import withAnalytics from 'shared/hoc/withAnalytics';

import { Navbar } from 'react-bootstrap';
import logo from '../mk-pattern-library-react/images/HSBC_Logo.svg';
import { Link } from 'react-router';

import { typography } from 'shared/styles/variables/theme'

import { SvgIcon } from 'shared/components/Icon';
import TermsAndConditionsLink from './TermsAndConditionsLink';
import SiteTextModal from './SiteTextModal';

import { EULA_MODAL_ID, LOG_ON_SCREEN_MODAL_ID, CONTACT_US_MODAL_ID } from 'constants/modalIds';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';

import pnStepsImage from '../images/HsbcnetPnFlow.png';

import Popup from 'shared/components/Popup';
import PopupSystem from 'shared/components/PopupSystem';

import {
  Logo,
  LoginContainer,
  InnerContainer,
  PnStepsSection,
  LoginForm,
  Header,
  PnButtonContainer,
  PnUserNameInfo,
  SpinnerSection,
  PnFooterLinks,
  PnStepList,
  PnSteps
} from './styles/Login';
import { components, outlines } from 'shared/styles/variables/theme';

const cancelBtnStyle = {
  fontSize: typography.sizes['16px'],
  padding: '10px',
  width: 120,
  marginRight:'13px'
};

const fillCircleSvgStyle = {
  fill: '#1a4875', 
  position: 'absolute', 
  marginTop: '123px'
}

const notificationTextStyle = {
  margin: '133px 0px 27px 27px'
}

const LanguagePickerContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 10px;
`;

const ModalIcon = props => (
  <SvgIcon
      icon="pop-up-window"
      aria-hidden
      style={{ fill: 'rgb(0, 0, 0)', marginRight: 10 ,marginLeft: "-11px"}}
  />
);

const StyledLink = styled.a`
    color:black
    ${components.link['--default']}

    &:focus {
        ${components.link['--state']['--focus']}
        outline: ${outlines.dark};
    }

    &:hover {
        ${components.link['--state']['--hover']}
        text-decoration: underline;
    }

    &.disabled {
        ${components.link['--state']['--disabled']}
    }
`;

const url = {
  pathname: featurePageIds.DATAPRIVACY_NOTICE_PAGE_EXTERNAL,
};
const cookieurl = {
  pathname: featurePageIds.COOKIE_NOTICE_PAGE_EXTERNAL,
};


class ExternalPnLogin extends Component {
  constructor(props) {
    super(props);

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onCancelPnClick = this.onCancelPnClick.bind(this);
  }

  componentDidMount() {
    this.props.viewChange();
    this.props.setLoginModal(false);
  }

  onSubmitClick() {
    this.props.loginFormPnSubmit(this.props.userName, this.props.authType);
  }

  onCancelPnClick() {
    if( !this.props.unauthorised ) {
      this.props.cancelLoginFormPnSubmit();
    }
    this.props.setUsername('');
    this.props.setAuthType('');
  }

  onCancelClick() {
    this.props.setAuthType('');
    this.props.setUsername('');
    this.props.setLoginError(false);
  }

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitClick();
    }
  }

  render() {
    return (
      <LoginContainer className="internal-login">
        <Navbar style={{ height: 120.5 }}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to={'/'}><Logo src={logo} alt="HSBC"/>MyDeal</Link>
            </Navbar.Brand>
            <LanguagePickerContainer>
              <LanguagePicker />
            </LanguagePickerContainer>
          </Navbar.Header>
        </Navbar>
        <InnerContainer>
          <PnStepsSection>
            <Header>
              <h2>
                <TranslationText
                  dictionaryKey={"login_screen.sent_notification"}
                  defaultText={"We've sent you a notification"}
                />
              </h2>
            </Header>
            <PnSteps src={pnStepsImage} alt=""/>
            <PnStepList>
              <li>Click on notification received</li>
              <li>Enter your security PIN</li>
              <li>Continue on web browser</li>
            </PnStepList>
            <PnFooterLinks>
            <TermsAndConditionsLink />
              <span>&nbsp;&nbsp;&nbsp;</span>
              <SiteTextModal
                modalId={CONTACT_US_MODAL_ID}
                siteTextType={SITE_TEXT_TYPES.CONTACT_US}
                linkText={this.props.contactUsText}
                tabIndex={0}
                width={630}
                aria-label={
                    translationString(
                        'settings.open_contact_us',
                        this.props.language,
                        'Open contact us'
                    )
                }
              >
              </SiteTextModal>
              <SiteTextModal
                modalId={EULA_MODAL_ID}
                siteTextType={SITE_TEXT_TYPES.EULA}
                linkText={this.props.eulaText}
                tabIndex={0}
                aria-label={
                    translationString(
                        'settings.open_eula',
                        this.props.language,
                        'Open end user license agreement'
                    )
                }>
              </SiteTextModal>
              <div style={{display:"inline-block",marginLeft:"10px"}}>
              <StyledLink>
              <ModalIcon />
               <Link  to={url} target="_blank"  style={{color:"black"}} >{this.props.dataPrivacyPolicyText}</Link>
              </StyledLink>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <StyledLink>
              <ModalIcon />
               <Link to={cookieurl} target="_blank"  style={{color:"black"}} >{this.props.cookiePolicyText}</Link>
              </StyledLink>
              </div>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </PnFooterLinks>
          </PnStepsSection>
          <LoginForm>
            <Header>
              <h2>
                <TranslationText
                  dictionaryKey={"login_screen.log_on_to_hsbc_mydeal"}
                  defaultText={"Logon to HSBC MyDeal"}
                />
              </h2>
            </Header>
            <div style={{color: 'red'}}>
            </div>
            {
              this.props.userName && 
              <PnUserNameInfo>
                  You are logging in as <b>‘{this.props.userName}’</b>.
              </PnUserNameInfo>
            }
            <SvgIcon
                icon="circle-fill-information"
                aria-hidden
                style={fillCircleSvgStyle}
            />
            <p style={notificationTextStyle}>
              Not received the notification on your mobile device? Click 'Cancel' below.
            </p>
            <PnButtonContainer>
              <SecondaryButton
                size="normal"
                loading={true}
                onClick={this.onCancelPnClick}
                style={cancelBtnStyle}
                disabled={this.props.unauthorised}
              >
                {translationString("login_screen.cancel", this.props.language, 'Cancel')}
              </SecondaryButton>
              <SpinnerSection>
                <MDSpinner size={20} singleColor={'#D8D8D8'} style={{ marginRight: 10, bottom: 2}} />
                <TranslationText
                  dictionaryKey={"login_screen.check_mobile_device"}
                  defaultText={"Check your mobile device"}
                />
              </SpinnerSection>
            </PnButtonContainer>
            <PopupSystem
              modalID={LOG_ON_SCREEN_MODAL_ID}
              isModalOpen={this.props.showLoginModal}
              centered
              backgroundLayer
              zIndex={3001}
              closeModal={() => null}
              clickableElement={null}
              content={
                  <Popup
                      static
                      width={300}
                      dividerStyle={{margin:'35px 0px 25px', borderBottom: '2px solid #585858'}}
                      primaryButton
                      primaryButtonContent="Ok"
                      primaryButtonAction={this.onCancelClick}
                      primaryButtonKey="Ok"
                      primaryButtonStyle={{margin:'0px 0px -12px', padding: '10px 25px', fontSize : '1rem'}}
                  >
                      <div style={{ marginTop: '30px' }}>
                        <h5>
                          Unable to login, Please try again
                        </h5>
                      </div>
                  </Popup>
                }
            />
          </LoginForm>
        </InnerContainer>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  invalid: selectLoginFormInvalidFields(state).length > 0,
  unauthorised: state.login.error,
  pendingAuthentication: state.login.pendingAuthentication,
  pristine: state.login.pristine,
  userName: state.login.userName,
  authType: state.login.authType,
  showLoginModal: state.login.showLoginModal,
});

const mapDispatchToProps = dispatch => ({
  loginFormChanged: (fieldName, fieldValue) => {
    dispatch(loginFormChanged(fieldName, fieldValue));
  },
  setAuthType: (aT) => {
    dispatch(setAuthType(aT));
  },
  setUsername: (username) => {
    dispatch(setUsername(username));
  },
  setLoginModal: (lM) => {
    dispatch(setLoginModal(lM));
  },
  setInvalidFields: (fields) => {
    dispatch(setInvalidFields(fields));
  },
  setPendingAuthentication: (pA) => {
    dispatch(setPendingAuthentication(pA));
  },
  loginFormPnSubmit: ( userNameField, aT ) => {
    dispatch(loginFormPnSubmit( userNameField, aT ));
  },
  cancelLoginFormPnSubmit: () => {
    dispatch(cancelLoginFormPnSubmit());
  },
  setLoginError: (e) => {
    dispatch(setLoginError(e));
  }
});

export default compose(
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.EXTERNAL_PN_LOGIN_PAGE),
    loginSubmit: () => ev(events.LOGIN_BUTTON),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate((translate, props) => ({
      containerRegionAriaLabel: translate('settings.settings', 'Settings'),
      termsAndConditionsText: translate('settings.terms_and_conditions', 'Terms and Conditions'),
      contactUsText: translate('settings.contact_us', 'Contact Us'),
      dataPrivacyPolicyText: translate('settings.privacy_notice', 'Privacy Notice'),
      eulaText: translate('settings.eula', 'End User License Agreement'),
      cookiePolicyText: translate('settings.cookie_notice', 'Cookie Notice'),
      faqText: translate('settings.faq', 'Frequently Asked Questions'),
  }))
)(ExternalPnLogin);