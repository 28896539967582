const ECM_SUMMARY_DEMAND_TABS = 'e-s-d-t';
export const ecmSummaryDemandTabs = {
    BY_INVESTOR_TYPE: 'bit',
    BY_COUNTRY: 'bc',
    BY_DAY: 'bd',
};

const ALL_TRANCHES_SUB_TABS = 'a-t-s-t';
export const allTranchesOrderSummarySubTabs = {
    VIEW_CHART : 'vc',
    VIEW_TABLE : 'vt'
}
const ALL_TRANCHES_BOOK_EVOLUTION_SUB_TABS = 'a-t-b-e-s-t';
export const allTranchesBookEvolutionSubTabs = {
    VIEW_CHART : 'vbc',
    VIEW_TABLE : 'vbt'
}

const ALL_TRANCHES_AMENDED_SUMMARY_SUB_TABS = 'a=t-a-s-s-t';
export const allTranchesAmendedSummarySubTabs = {
    VIEW_CHART: 'vc',
    VIEW_TABLE: 'vt'
}

const DCM_SUMMARY_DEMAND_TABS = 'd-s-d-t';
export const dcmSummaryDemandTabs = {
    BY_REGION: 'br',
    BY_INVESTOR_TYPE: 'bit',
    BY_COUNTRY: 'bc',
};

const TOP_TEN_ORDERS_TABS = 't-t-o-t';
export const topTenOrdersTabs = {
    TOP_TEN_ORDERS: 'tto',
    MOST_RECENT_TOP_TEN: 'mrtt',
};

const INVESTOR_FEEDBACK_CHART_TABS = 'i-f-c-t';
export const investorFeedbackChartTabs = {
    THEME_DISTRIBUTION: 'td',
    SENTIMENT: 's',
    PRICE_DEMAND: 'pd',
};


const ROADSHOW_CONTACT_TABS = 'r-c-t';
export const roadshowContactTabs = {
    ALL_CONTACTS: 'ac',
    COORDINATORS: 'c',
    ONLINE: 'o' 
};

//
// All Ids of tab containers
//
export const tabIds = { 
    // orders
    ECM_SUMMARY_DEMAND_TABS,
    DCM_SUMMARY_DEMAND_TABS,
    TOP_TEN_ORDERS_TABS,
    ALL_TRANCHES_SUB_TABS,
    ALL_TRANCHES_BOOK_EVOLUTION_SUB_TABS,
    ALL_TRANCHES_AMENDED_SUMMARY_SUB_TABS,
    // investor feedback
    INVESTOR_FEEDBACK_CHART_TABS,
    // roadshow
    ROADSHOW_CONTACT_TABS,
};
