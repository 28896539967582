import React from 'react';

function topFunction() {
  window.location.hash = '';
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const PrivacyPolicy = () => (
  <>
    <h1 id="title">The MyDeal Privacy Notice as at 17/Sep/2019</h1>
    <h2>On this Page</h2>
    <ul class="linkstyle">
      <li><a href="#About-this-notice">About this notice</a></li>
      <li><a href="#Who-this-Notice-applies-to">Who this Notice applies to</a></li>
      <li><a href="#Contact-Details">Contact Details</a></li>
      <li><a href="#What-information-we-collect">What information we collect</a></li>
      <li><a href="#How-we’ll-use-your-information">How we’ll use your information</a></li>
      <li><a href="#Lawful-basis-for-processing-your-information">Lawful basis for processing your information</a></li>
      <li><a href="#Who-we-might-share-your-information-with"> Who we might share your information with</a></li>
      <li><a href="#How-long-we’ll-keep-your-information">How long we’ll keep your information</a></li>
      <li><a href="#Transferring-your-information-overseas"> Transferring your information overseas</a></li>
      <li><a href="#Your-rights"> Your rights</a></li>
      <li><a href="#How-we-keep-your-information-secure">How we keep your information secure</a></li>
      <li><a href="#Further-information-about-your-data"> Further information about your data</a></li>
      <li><a href="#Processing-by-another-HSBC-Group-controller">Processing by another HSBC Group controller</a></li>
      <li><a href="#Cookies">Cookies</a></li>
    </ul>
    <h2 id="About-this-notice">1. About this notice</h2>
      <p>This Privacy Notice applies to personal information held by members of the HSBC Group as data controllers, or the
        organisation responsible for personal information under our control. It only applies for the purposes of operating
        the MyDeal website and mobile application. For all products, services and processes other than MyDeal, please refer
        to the data controller’s other relevant privacy notice.<span> </span></p>
      <p>This Privacy Notice explains what information we collect about you, how we’ll use that information, who we’ll share
        it with, the circumstances when we’ll share it and what steps we’ll take to make sure it stays private and secure.
        It will continue to apply even if your use of or agreement for MyDeal ends. This Privacy Notice may be updated from
        time to time and the most recent version can be found at https://mydeal.gbm.hsbc.com/.</p>
      <h2 id="Who-this-Notice-applies-to">2. Who this Notice applies to</h2>
      <p>This Privacy Notice applies to your use of the MyDeal website and mobile application. When we say ‘we’, we mean
        HSBC Bank plc and any other HSBC Group companies which act as a data controller in respect of your personal data.
        For the purposes of this notice, the relevant data controller is:</p>
      <p>(a) HSBC Bank plc, as the operator of the MyDeal website and mobile application, in connection with the provision
        of these services; and</p>
      <p>(b) the HSBC Group company that the entity you are acting on behalf of has a relationship with (as defined in the
        MyDeal terms and conditions). <span> </span></p>
      <h2 id="Contact-Details">3. Contact Details</h2>
      <p>You can find the contact details of the data controllers and their data protection officers (where relevant) by
        clicking on the Customer Support link on https://mydeal.gbm.hsbc.com/ and selecting the relevant
        country/territory.<span> </span></p>
      <h2 id="What-information-we-collect">4. What information we collect</h2>
      <p>The information we collect is required to provide you with access to our services, to fulfil our agreement with the
        entity you are acting on behalf of, to enable us to fulfil our legal and regulatory compliance obligations, and to
        address our legitimate business interests, as described below. Failure to provide your information (or if all
        personal data submitted to us is not complete, accurate, true and correct) may mean it is not possible to provide
        you with access to certain products or services which you or the entity you are acting on behalf of have
        requested.<span> </span></p>
      <p>We’ll only collect your information in line with relevant regulations and law. The information we collect may
        include:</p>
      <h3>4.1 Information that you, or your employer / the profile owner you represent /corporate representative, provide to
        us, e.g.:</h3>
      <ul>
        <li>personal details, e.g. name, gender, date of birth, role/occupation;</li>
        <li>contact details, e.g. home and work postal addresses, email addresses and phone numbers;</li>
        <li>meeting details, e.g. start and end times, location and attendance;</li>
        <li>logistic details, e.g. flight numbers, flight times, transportation details and accommodation arrangements;</li>
        <li>your feedback about products and services presented on the MyDeal website and mobile application;</li>
        <li>authentication information, e.g. integrated login credentials from HSBCnet for access to the MyDeal website and
          mobile application;</li>
        <li>other information about you that you give us by filling in forms, surveys, or by communicating with us, whether
          face-to-face, by phone, email, online, or otherwise.</li>
      </ul>
      <h3>4.2 Information we collect or generate about you, e.g.:</h3>
      <ul>
        <li>information about you or those you represent and their relationship with us, including the channels you use and
          your ways of interacting with us and the MyDeal website / application, as well information concerning complaints
          and disputes;</li>
        <li>information included in customer documentation (e.g. a record of advice that we may have given you);</li>
        <li>cookies, and similar technologies we use to make our website operational - our cookie policy available at
          https://mydeal.gbm.hsbc.com/ contains more details about how we use cookies;</li>
        <li>records of correspondence and other communications between us, including email, telephone conversations, live
          chat, instant messages and social media communications;</li>
        <li>information that we need to support our regulatory obligations, e.g. information about transaction details,
          purpose of payment, ordering customer information, counterparty/beneficiary information, identification documents,
          detection of any suspicious and unusual activity and information about parties connected to you or these
          activities;</li>
        <li>information about the devices you use to access MyDeal, e.g. software and IP address.</li>
      </ul>
      <h2 id="How-we’ll-use-your-information">5. How we’ll use your information</h2>
      <p>The reasons we use your information include to:<span> </span></p>
      <ul>
        <li>provide you with access to MyDeal (including our mobile app), including identifying you and carrying out your
          instructions, sending alerts, service messaging and notification of activities;</li>
        <li>manage our relationship with you or the profile owner you represent (as part of our provision of its MyDeal
          services);</li>
        <li>fulfil our contractual arrangements with third party information providers operating in financial markets (e.g.
          Bloomberg). This can include sharing our customer’s identity with those third party providers, including contact
          details of our customer’s representatives, when our customers use that service;</li>
        <li>prevent or detect crime including fraud and financial crime, e.g. financing for terrorism and human trafficking;
        </li>
        <li>ensure security and business continuity and risk management;</li>
        <li>improve products and services, including performance of data analytics to better understand how you or the
          company you represent use our services, and to respond to any service issues you may have;</li>
        <li>protect our legal rights and comply with our legal obligations;</li>
        <li>carry out marketing activities in relation to our products and services, where permitted;</li>
        <li>develop systems or products ;</li>
        <li>carry out planning, audit and administrative activities.</li>
      </ul>
      <p>We do not carry out automated decision making which might produce legal effects and/or otherwise affects you.</p>
      <h3>5.1 Specific use - Tracking or recording what you say or do</h3>
      <p>We may record details of your interactions with us, including emails, telephone conversations, live chats, video
        chats and any other kinds of communication that you may have with us regarding MyDeal. We may use these recordings
        to check your instructions to us, assess, analyse and improve our service, train our people, manage risk or to
        prevent and detect fraud and other crimes. We may also capture additional information about these interactions, such
        as information about the devices or software that you use.</p>
      <h3>5.2 Specific use - Compliance with laws and regulatory compliance obligations</h3>
      <p>We’ll use your information to meet our compliance obligations, to comply with other laws and regulations and to
        share with regulators and other authorities that HSBC Group companies are subject to. This may include using it to
        help detect or prevent crime (including terrorism financing, money laundering and other financial crimes). We’ll
        only do this on the basis that it’s needed to comply with a legal obligation or it’s in our legitimate interests and
        that of others.</p>
      <h2 id="Lawful-basis-for-processing-your-information">6. Lawful basis for processing your information</h2>
      <p>We’ll only use your information where we have identified a lawful basis for processing it. These reasons include
        where we:</p>
      <ul>
        <li>need to pursue our legitimate interests or that of the profile owner you represent (for example, as part of our
          provision of its MyDeal services);</li>
        <li>need to process the information to carry out an agreement we have with you or the profile owner you represent
          (as part of our provision of its MyDeal services) or a third party information provider operating in financial
          markets (e.g. Bloomberg);</li>
        <li>need to process the information to comply with a legal obligation;</li>
        <li>believe the use of your information as described is in the public interest e.g. for the purpose of preventing or
          detecting crime;</li>
        <li>have obtained your consent in those jurisdictions that mandate it, or consent is the most appropriate basis for
          processing it.<span> </span></li>
      </ul>
      <p><b></b><br/></p>
      <h2 id="Who-we-might-share-your-information-with">7. Who we might share your information with</h2>
      <p>We may share your information with others where lawful to do so including where we or they:</p>
      <ul>
        <li>need to in order to provide you with products or services you’ve requested, e.g. fulfilling a payment request,
          sharing information with third party vendors who we may utilise;</li>
        <li>have a public or legal duty to do so, e.g. to assist with detecting and preventing fraud, tax evasion and
          financial crime;</li>
        <li>need to in connection with regulatory reporting, litigation or asserting or defending legal rights and
          interests;</li>
        <li>have a legitimate business reason for doing so, e.g. sharing your information and information about your use of
          your account with the profile owner you represent as part of our provision of its MyDeal services, tracking usage,
          performing data analysis to identify market/industry trends and pursuing other business interests;</li>
        <li>intend to manage risk, verify your identity, enable another company to provide you with services you’ve
          requested, or assess your suitability for products and services;</li>
        <li>have asked you for your permission to share it, and you’ve agreed.</li>
      </ul>
      <p>We may share your information for these purposes with others including the profile owner you represent, any other
        HSBC group companies and any sub-contractors, agents or service providers who work for us or provide services to us
        or other HSBC Group companies (including their employees, sub-contractors, service providers, directors and
        officers). This includes sharing your information with third party information providers operating in financial
        markets where required in connection with their services.<span> </span></p>
      <h2 id="How-long-we’ll-keep-your-information">8. How long we’ll keep your information</h2>
      <p>We keep your information in line with our data retention policy (for example, in some countries/territories we will
        retain information from MyDeal for a minimum 5 year period). This enables us to comply with legal and regulatory
        requirements or use it where we need to for our legitimate purposes such as managing your account and dealing with
        any disputes or concerns that may arise.<span> </span></p>
      <p>We may need to retain your information for a longer period where we need the information to comply with regulatory
        or legal requirements or where we may need it for our legitimate purposes e.g. to help us respond to queries or
        complaints, fighting fraud and financial crime, responding to requests from regulators, etc. If we don’t need to
        retain information for this period of time, we may destroy, delete or anonymise it more promptly.</p>
      <h2 id="Transferring-your-information-overseas">9. Transferring your information overseas</h2>
      <p>Your information may be transferred to and stored in locations outside your home jurisdiction, including
        countries/territories that may not have the same level of protection for personal information. When we do this,
        we’ll ensure they have an appropriate level of protection and that the transfer is lawful. We may need to transfer
        your information in this way to carry out our contract with you, to fulfil a legal obligation, to protect the public
        interest and / or for our legitimate interests. In some countries/territories the law might compel us to share
        information with certain persons (e.g. with tax authorities). Even in these cases, we’ll only share your information
        with people who have the right to see it. You can obtain more details of the protection given to your information
        when it is transferred outside your home jurisdiction by contacting us using the details in the ‘More details about
        your information’ section below.</p>
      <h2 id="Your-rights">10. Your rights</h2>
      <p>You have a number of rights in relation to the information that we hold about you. These rights include:</p>
      <ul>
        <li>the right to access information we hold about you and to obtain information about how we process it, save where
          such disclosure would be prohibited by law;</li>
        <li>in some circumstances, the right to withdraw your consent to our processing of your information, which you can
          do at any time. We may continue to process your information if we have another legitimate reason for doing so,
          although this may impact your ability to continue to have access to our products and services;</li>
        <li>in some circumstances, the right to receive certain information in an electronic format and/or request we
          transmit it to a third party where this is technically feasible. The right only applies to information provided to
          us.</li>
        <li>the right to request that we rectify your information if it’s inaccurate or incomplete;</li>
        <li>in some circumstances, the right to request that we erase your information. We may continue to retain your
          information if we are entitled or required to retain it; and</li>
        <li>the right to object to, and to request that we restrict, our processing of your information in some
          circumstances. Again, there may be situations where you object to, or ask us to restrict, our processing of your
          information but we are entitled to continue processing your information and / or to refuse that request.</li>
      </ul>
      <p>You can exercise your rights by contacting us using the details set out below. You may also have a right to
        complain to the relevant data protection regulator in the country/territory in which you live or work.</p>
      <h2 id="How-we-keep-your-information-secure">11. How we keep your information secure<span> </span></h2>
      <p>We use a range of measures to keep your information safe and secure which may include encryption and other forms of
        security. We require our staff and any third parties who carry out any work on our behalf to comply with appropriate
        compliance standards including obligations to protect any information and applying appropriate measures for the use
        and transfer of information.</p>
      <h2 id="Further-information-about-your-data">12. Further information about your data</h2>
      <p>If you’d like further information on your data, or to contact our Data Protection Officer where applicable, please
        contact your usual HSBC representative, or use the Customer Support option on the https://mydeal.gbm.hsbc.com/
        homepage, which sets out our contact details in applicable jurisdictions.<span> </span></p>
      <h2 id="Processing-by-another-HSBC-Group-controller">13. Processing by another HSBC Group controller</h2>
      <p>Where you are acting on behalf of an entity that has a relationship with a company of the HSBC Group (as defined in
        the MyDeal terms and conditions), you should read this document in conjunction with the Privacy Notice issued by
        that entity and available at:<span> </span></p>
      <ul>
        <li>HSBC Bank Australia Limited and The Hongkong and Shanghai Banking Corporation Limited, Sydney Branch in
          Australia: <a href="https://www.hsbc.com.au/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.au</span></a>
        </li>
        <li>HSBC Bangladesh: <a href="https://www.hsbc.com.bd/%22%20%5Ct%20%22_blank"><span
              class="s2">www.hsbc.com.bd</span></a></li>
        <li>HSBC Bank (China) Company Limited: <a href="https://www.hsbc.com.cn/%22%20%5Ct%20%22_blank"><span
              class="s2">www.hsbc.com.cn</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited in Hong Kong SAR: <a
            href="https://www.hsbc.com.hk/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.hk</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited in India: <a
            href="https://www.hsbc.co.in/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.co.in</span></a></li>
        <li>PT Bank HSBC Indonesia: <a href="https://www.hsbc.co.id/%22%20%5Ct%20%22_blank"><span
              class="s2">www.hsbc.co.id</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited in Japan: <a
            href="https://www.hsbc.co.jp/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.co.jp</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited, Seoul Branch in Korea: <a
            href="https://www.hsbc.co.kr/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.co.kr</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited, Macau Branch in Macau SAR: <a
            href="https://www.hsbc.com.mo/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.mo</span></a></li>
        <li>HSBC Bank Malaysia Berhad and HSBC Amanah Malaysia Berhad in Malaysia: <a
            href="https://www.hsbc.com.my/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.my</span></a> or <a
            href="https://www.hsbcamanah.com.my/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbcamanah.com.my</span></a>
        </li>
        <li>The Hongkong and Shanghai Banking Corporation Limited Maldives Branch in Maldives: <a
            href="http://www.business.maldives.hsbc.com/%22%20%5Ct%20%22_blank"><span
              class="s2">http://www.business.maldives.hsbc.com</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited, incorporated in the Hong Kong SAR, acting through its New
          Zealand branch: <a href="https://www.hsbc.co.nz/utilities/privacy-and-security%22%20%5Ct%20%22_blank"><span
              class="s2">https://www.hsbc.co.nz/utilities/privacy-and-security</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited, Singapore Branch in Singapore: <a
            href="https://www.hsbc.com.sg/privacy-statement/%22%20%5Ct%20%22_blank"><span
              class="s2">https://www.hsbc.com.sg/privacy-statement/</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited Sri Lanka Branch in Sri Lanka: <a
            href="https://www.hsbc.com.lk/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.lk</span></a></li>
        <li>HSBC Bank (Taiwan) Limited and The Hongkong and Shanghai Banking Corporation Limited, Taipei Branch in Taiwan:
          <a href="https://www.hsbc.com.tw/%22%20%5Ct%20%22_blank"><span class="s2">www.hsbc.com.tw</span></a></li>
        <li>The Hongkong and Shanghai Banking Corporation Limited, Philippines branch in Philippines: <a
            href="http://www.business.hsbc.com.ph/%22%20%5Ct%20%22_blank"><span
              class="s2">http://www.business.hsbc.com.ph</span></a></li>
        <li>Hang Seng Bank Limited in Hong Kong SAR: <a
            href="https://www.hangseng.com/en-hk/home/%22%20%5Ct%20%22_blank"><span class="s2">www.hangseng.com</span></a>
        </li>
      </ul>
      <p>Where the local notice conflicts with this Privacy Notice, the former shall apply.</p>
      <h2 id="Cookies">14. Cookies</h2>
      <p>You should also read our Cookie Policy at <a href="https://mydeal.gbm.hsbc.com/">https://mydeal.gbm.hsbc.com/</a>
        to find out more about how HSBC and our trusted partners use cookies, which is relevant to your online security. We
        use cookies to make our website and mobile devices more secure and easier to use.</p>
      <button onClick={topFunction} id="moveToTopBtn" title="Go to top">Top</button>
  </>
)

export default PrivacyPolicy;
