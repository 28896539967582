import React from 'react';
import styled from 'styled-components';

import { anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';
import { components, outlines } from 'shared/styles/variables/theme';

const StyledLink = styled.a`
    ${components.link['--default']}

    &:focus {
        ${components.link['--state']['--focus']}
        outline: ${outlines.dark};
    }

    &:hover {
        ${components.link['--state']['--hover']}
        text-decoration: underline;
    }

    &.disabled {
        ${components.link['--state']['--disabled']}
    }
`;

class HSBCLink extends React.PureComponent {
    navigateEvent = (e) => {
        e.preventDefault();

        if (this.props.disabled) {
            return;
        }

        if (anchorEventHandling(e)) {
            this.props.onClick && this.props.onClick();
        }
    }

    render() {
        const { disabled, style, ...other } = this.props;
        return (
            <StyledLink
                {...other}
                style={style}
                className={`
                    ${this.props.disabled ? 'disabled' : ''}
                `}
                onKeyPress={this.navigateEvent}
                onClick={this.navigateEvent}
                tabIndex={0}
            >
                {this.props.children}
            </StyledLink>
        )
    }
}

export default HSBCLink;
