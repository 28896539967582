import { createReducer } from 'reduxsauce'
import Types, { UtilityTypes, MyDocsTypes } from 'actions/Types'

export const INITIAL_STATE = {
    // data
    myDocsSummary: [],
    myDocs: [],
    // view
    fetchedDocs: false,
    fetchedSummary: false,
    activeCategoryId: null, // All
};

const receiveMyDocsSummary = (state, action) => {
    const nullData = (action.myDocsSummaryData === null) || (action.myDocsSummaryData.length === 0);
    return Object.assign({}, state, {
        fetchedSummary: true,
        myDocsSummary: nullData ? [] : action.myDocsSummaryData.data,
        dateReceived: Date.now()
    })
};

const receiveMyDocs = (state, action) => {
    const nullData = (action.myDocsData === null) || (action.myDocsData.length === 0);
    return Object.assign({}, state, {
        fetchedDocs: true,
        myDocs: nullData ? [] : action.myDocsData.data,
        dateReceived: Date.now()
    })
};

const requestDoc = (state, action) =>
    Object.assign({}, state, {
        fetching: false,
        name: action.name,
    });

const changeActiveCategory = (state, { categoryId }) =>
    Object.assign({}, state, { activeCategoryId: categoryId });


// map our types to our handlers
const ACTION_HANDLERS = {
    [Types.API_RECEIVE_DOCUMENTS_SUMMARY]: receiveMyDocsSummary,
    [Types.API_RECEIVE_DOCUMENTS]: receiveMyDocs,
    [Types.API_REQUEST_DOC]: requestDoc,
    [MyDocsTypes.CHANGE_ACTIVE_CATEGORY]: changeActiveCategory,
    //
    // Deal Cleanup
    [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE)
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)