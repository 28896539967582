import styled from 'styled-components';

const flexbox = (direction, align, justify, wrap = 'nowrap', content = 'stretch') => `
  display: flex;
  flex-direction: ${direction};
  align-items: ${align};
  justify-content: ${justify};
  flex-wrap: ${wrap};
  align-content: ${content};
`;

export const Wrapper = styled.div`
  ${flexbox('column', 'flex-start', 'flex-start')}
  width: 100%;
`;

export const Heading = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
`;

export const DropzoneInner = styled.div`
  ${flexbox('column', 'center', 'center')}
  width: 100%;  
  min-width: 300px;
  background: #f2f2f2;
  border: 2px dashed #d7d8d6;
  width: 100%;
  height: 160px;
  margin: 20px 0 30px;
`;

export const DropzoneWrapper = styled.div`
  display: block;
  text-align: center;
  width: 100%;
`

export const DropzoneHeading = styled.p`
  font-weight: 300;
  color: #000000;
  font-size: 25px;
  margin: 0;
  word-break: break-word;
  padding: 7px;
  max-width: 100%;
  word-wrap: break-word;
`;

export const DropzoneSubheading = styled.p`
  font-weight: 300;
  font-size: 15px;
  color: #000000;
  margin-top: 0;
`;
