import React, { PureComponent } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Icon, ValueFormatters } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

const HeaderContainer = styled.div`
    height: auto;
`;


class Header extends PureComponent {
    render() {
        return (
            <HeaderContainer>
                <Col sm={9} style={{ padding: 0 }}>
                    <h3 style={{ margin: 0, fontSize: '1.250rem' }}>
                        <span
                            style={{ wordWrap: 'break-word' }}
                        >
                            {this.props.details.companyName}
                        </span>
                        {
                            this.props.details.keyInvestor && (
                                <Icon className="feedback-detail-header-key-investor" iconName="Star" style={{ color: '#4F595F', marginLeft: 10, fontSize: 16 }} />
                            )
                        }
                    </h3>
                    <h4 style={{ marginBottom: 5 }}>
                        {
                            this.props.details.investorType && (
                                <span>{this.props.details.investorType}&nbsp;</span>
                            )
                        }
                        {
                            this.props.details.investorName && (
                                <span className="text-timestamp">{this.props.details.investorName}</span>
                            )
                        }
                    </h4>
                    <div className="text-timestamp text-univ-lt">
                        <span>
                            <TranslationText
                            dictionaryKey={"investor_feedback.feedback_recorded_by"}
                            defaultText={"Feedback recorded by"}
                            />
                            :&nbsp;
                            <span>{this.props.details.banksRecorded}</span>
                        </span>
                        {
                            this.props.details.cityRecorded && (
                            <span>
                                &nbsp;{this.props.details.cityRecorded}
                            </span>
                            )
                        }
                        <span>
                            &nbsp;on {ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.details.created)}
                        </span>
                    </div>
                </Col>
                <Col sm={3} style={{ padding: 0 }} className="text-right text-large text-univ-lt">
                    <span>
                        <TranslationText
                            dictionaryKey={mapThemeTypeOrSentimentToKey(this.props.details.sentiment)}
                            defaultText={this.props.details.sentiment}
                        />
                        &nbsp;
                        <TranslationText
                            dictionaryKey={"investor_feedback.sentiment"}
                            defaultText={"Sentiment"}
                        />
                    </span>
                </Col>
            </HeaderContainer>
         );
    }
}

export default Header;
