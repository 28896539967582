import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import get from "lodash/get";
import { compose } from "recompose";
import styled from "styled-components";

import * as modalIds from "constants/modalIds";
import { ALL_INVESTORS, ALL_TRANCHES, DEFAULT_ALL } from "constants/orders";
import dealFeatures from 'constants/dealFeatures';
import {
  updateCurrentTranche,
  openOrderSummaryDetailView,
  getCurrentActiveSummaryTab,
  changeLoderStatus,
} from "actions/OrderBookActions";
import { handleHomeFeature } from 'actions/UtilityActions';
import {
  getTrancheTitles,
  getCurrentTranche,
  getDemandDataForSummary,
  getOrderSummaryData,
  getSelectedDate,
  getInvestorNames,
  getTrancheLists,
} from "selectors";

import TranslationText, {
  translationString,
} from "translations/TranslationText";
import withLanguage from "translations/withLanguage";
import withTranslate from "shared/hoc/withTranslate";

import DemandByCarouselPanelPie from "../../shared/summary/DemandByCarouselPanelPie";
import TopTenTable from "../../shared/summary/TopTenTable";
import StatusBar from "../../shared/summary/StatusBar";
import OrderbookHeader from "../../shared/summary/OrderbookHeader";
// analytics
import { featurePageIds } from "constants/featurePages";
import withAnalytics from "shared/hoc/withAnalytics";
import { events } from "constants/analytics";
import { ALL_TRANCHES_RECORD, ORDER_SUMMARY_TABS } from "constants/orders";

// tabs
import {
  tabIds,
  dcmSummaryDemandTabs,
  allTranchesOrderSummarySubTabs,
} from "constants/tabIds";
import HorizontalTab from "shared/accessibility/components/HorizontalTabs/HorizontalTab";
import HorizontalTabs from "shared/accessibility/components/HorizontalTabs";

import OrderSummaryDetailView from "./OrderSummaryDetailView";
import OrderSummaryTable from "./OrderSummaryTable";
import OrderSummaryDropdownList from "./OrderSummaryDropdownList";
import { TileContentInner, EmptyContent } from "Landing/dcm/Tiles/styles.js";
import OrderSummaryLineChart from "./OrderSummaryLineChart";
import { dateFormatter } from "../../SharedTableComponents/formatters";
import Loading from 'shared/components/Loading';

const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

const DropDownPicker = styled.div`
  margin: 30px 0px;
`;

const CumulativeTextWrapper = styled.div`
  position: absolute;
  top: 10px;
  color: #000;
  right: 10px;
  font-weight: 600;
`;


const OrderSummaryContainer = (props) => {

  useEffect(() => {
    props.viewChange();
    props.getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.ORDERBOOKSUMMARY);
  }, []);

  useEffect(() => {
    const { dealId, orderData, currentTrancheData, changeLoderStatus, handleHomeFeature } = props;
    if (active.id !== ALL_TRANCHES_RECORD.id && orderData && orderData.length) {
      if (orderData.length !== currentTrancheData.trancheTotalNumberOfOrders) {
        handleHomeFeature(dealFeatures.ORDERBOOK, dealId);
        changeLoderStatus(true);
      }
    }
  }, [props.orderData])

  const handleTrancheNavClick = (selectedTranche) => {
    props.updateCurrentTranche(selectedTranche.id);
  }

  const getSummaryDates = (isOrderSummaryEnable) => {
    const orderDateSummary = [];
    if (isOrderSummaryEnable) {
      const data = { ...props.orderSummaryData.orderSummary };
      _.entries(data).map((item) => {
        orderDateSummary.push({
          value: dateFormatter("localDate", item[0]),
          label: dateFormatter("localDate", item[0]),
        });
        return null;
      });
    }
    return orderDateSummary.filter(
      (array, index, value) => value.indexOf(array) === index
    );
  };

  const getDateFilterdSummaryData = (orderList, orderSummary, showCumulativeRow) => {
    const getOrderSummaryByDate = _.entries(orderList).find(
      (order) =>
        dateFormatter("localDate", order[0]) === props.selectedDate.value
    );
    const orderSummaryListByDate =
      getOrderSummaryByDate &&
      getOrderSummaryByDate.length &&
      getOrderSummaryByDate[1].filter(
        (order) => order.timeInterval !== "Total"
      );

    if (
      orderSummaryListByDate &&
      orderSummaryListByDate.length &&
      showCumulativeRow
    ) {
      const orderSummaryTableKeys = _.keys(
        orderSummary[orderSummary.length - 1]
      );
      let grandTotalRow = {};

      _.each(orderSummaryListByDate, (order) => {
        _.each(orderSummaryTableKeys, (column) => {
          if (column !== "date") {
            grandTotalRow[column] = order[column];
          } else {
            grandTotalRow[column] = "";
          }
          column === "timeInterval" &&
            (grandTotalRow[column] = "Total");
        });
      });

      orderSummaryListByDate.push(grandTotalRow);
    }
    return orderSummaryListByDate || [];
  };

  const getOrderSummaryList = (data, isTable) => {
    const orderSummary = [];
    const tempOrderSummaryData = JSON.parse(JSON.stringify(data));
    const trancheList = JSON.parse(JSON.stringify(props.trancheNameList));
    _.entries(tempOrderSummaryData).map((item) => {
      item[1].map((col, index) => {
        if (isTable) {
          index === 0
            ? (col.date = dateFormatter("localDate", item[0]))
            : (col.date = "");
        } else {
          col.dateTimeInterval =
            index === 0
              ? dateFormatter("dateMonth", item[0]) +
              " " +
              col.timeInterval.slice(0, 5)
              : col.timeInterval.slice(0, 5);
          trancheList.forEach((item, index) => {
            if (col[item.key] || col[item.key] === 0) {
              col[trancheList[index].value] = col[item.key] || 0;
              delete col[item];
            }
          });
        }
        if (isTable || col.timeInterval !== "Total")
          orderSummary.push(col);
        return null;
      });
      return null;
    });

    if (props.selectedDate.value !== "ALL") {
      return getDateFilterdSummaryData(
        tempOrderSummaryData,
        orderSummary,
        isTable
      );
    }

    return orderSummary;
  };

  const checkPopOverStatus = (tableData) => {
    let isPopOverEnabled = false;
    const otherTableData = [];
    const data = tableData.find((item) => item.dataItem === 'Other' && item.otherValues && item.otherValues.length)
    if (data) {
      isPopOverEnabled = true;
      const demandData = data.otherValues;
      demandData.forEach(item => {
        otherTableData.push({
          dataItem: item.categoryName,
          totalOrders: item.demand,
          percent: item.percentage,
          orders: item.totalNumberOfOrders,
          otherValues: item.otherValues,
        });
      });
    }
    return {
      isPopOverEnabled,
      otherTableData
    }
  }

  // from route we can optionally set the default order table tab
  const ordersTableTab = get(props.location, "state.ordersTable");

  const active = { id: props.currentTrancheId };
  const currentTrancheData = props.currentTrancheData;
  const tableDataCountry = props.InvestorCountrySummaryData.tableData;
  const countryPieData = props.InvestorCountrySummaryData.chartData;
  const tableDataRegion = props.InvestorRegionSummaryData.tableData;
  const regionPieData = props.InvestorRegionSummaryData.chartData;
  const tableDataInvestor = props.InvestorTypeSummaryData.tableData;
  const investorPieData = props.InvestorTypeSummaryData.chartData;

  const isOrderSummaryEnable =
    props.orderSummaryData &&
    _.keys(props.orderSummaryData.orderSummary).length;

  let panelStyle = {
    height: "366px",
  };

  const trancheTitles = props.trancheTitles.map((t) => ({
    ...t,
    btnAriaLabel: translationString(
      "orders.open_tranche_<tranche_name>_<status>",
      props.language,
      `Open tranche ${t.title} ${t.status || ""}`,
      [["<tranche_name>", t.title], ["<status>", t.status]]
    ),
  }));

  return (
    <>
      {currentTrancheData ? (
        <>
          <OrderbookHeader
            orderSummary="true"
            dealType="dcm"
            headerData={currentTrancheData}
            dateReceived={props.dateReceived}
            trancheTitles={trancheTitles}
            trancheClick={handleTrancheNavClick}
            trancheActive={active}
          />

          {active.id !== ALL_TRANCHES_RECORD.id && (
            <>
              <StatusBar
                barType="dcm"
                currentTranche={currentTrancheData}
                currency={currentTrancheData.trancheCurrency}
                total={currentTrancheData.trancheTotalNumberOfOrders}
              />
              <Row style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 40 }}>
                <Col md={7}>
                  <div className="tabbed-container" style={{ height: '100%' }}>
                    <HorizontalTabs tabsId={tabIds.DCM_SUMMARY_DEMAND_TABS} style={{ height: 'calc(100% - 40px)' }}>
                      <HorizontalTab
                        tabId={dcmSummaryDemandTabs.BY_REGION}
                        title={props.tab1Title}
                        onTabClicked={() => {
                          props.tabChangeEvent(
                            events.ORDERS_SUMMARY_DEMAND_TAB_BY_REGION
                          );
                        }}
                      >
                        <DemandByCarouselPanelPie
                          wideColorPalette={false}
                          total={`${
                            currentTrancheData.trancheTotalNumberOfOrders
                            }`}
                          panelStyle={panelStyle}
                          chartData={regionPieData}
                          tableData={tableDataRegion}
                          popoverData={checkPopOverStatus(tableDataRegion)}
                          tableType="dcm"
                          type={translationString(
                            "orders.region",
                            props.language,
                            "Region"
                          )}
                        />
                      </HorizontalTab>
                      <HorizontalTab
                        tabId={dcmSummaryDemandTabs.BY_INVESTOR_TYPE}
                        title={props.tab2Title}
                        onTabClicked={() => {
                          props.tabChangeEvent(
                            events.ORDERS_SUMMARY_DEMAND_TAB_BY_INVESTOR_TYPE
                          );
                        }}
                      >
                        <DemandByCarouselPanelPie
                          wideColorPalette={false}
                          total={`${
                            currentTrancheData.trancheTotalNumberOfOrders
                            }`}
                          panelStyle={panelStyle}
                          chartData={investorPieData}
                          tableData={tableDataInvestor}
                          popoverData={checkPopOverStatus(tableDataInvestor)}
                          tableType="dcm"
                          type={translationString(
                            "orders.investor",
                            props.language,
                            "Investor"
                          )}
                        />
                      </HorizontalTab>
                      <HorizontalTab
                        tabId={dcmSummaryDemandTabs.BY_COUNTRY}
                        title={props.tab3Title}
                        onTabClicked={() => {
                          props.tabChangeEvent(
                            events.ORDERS_SUMMARY_DEMAND_TAB_BY_COUNTRY
                          );
                        }}
                      >
                        <DemandByCarouselPanelPie
                          wideColorPalette={false}
                          total={`${
                            currentTrancheData.trancheTotalNumberOfOrders
                            }`}
                          panelStyle={panelStyle}
                          chartData={countryPieData}
                          tableData={tableDataCountry}
                          popoverData={checkPopOverStatus(tableDataCountry)}
                          tableType="dcm"
                          type={translationString(
                            "orders.country",
                            props.language,
                            "country"
                          )}
                        />
                      </HorizontalTab>
                    </HorizontalTabs>
                  </div>
                </Col>
                <Col md={5}>
                  <TopTenTable
                    panelStyle={panelStyle}
                    type="dcm"
                    orderData={props.orderData}
                    currentTrancheData={currentTrancheData}
                    defaultTab={ordersTableTab}
                  />
                </Col>
              </Row>
            </>
          )}
          
          {active.id === ALL_TRANCHES_RECORD.id && (
            <>
            {
                props.loading ? (
                  <LoadingContainer>
                    <LoadingWrapper>
                      <Loading hasBackground={true} />
                    </LoadingWrapper>
                  </LoadingContainer>
             ):(
            <Row>
              <Col md={12} style={{ marginBottom: "20px" }}>
                {(isOrderSummaryEnable || props.isOrderSummaryFiltererd) ? (
                  <DropDownPicker>
                    <OrderSummaryDropdownList
                      dealId={props.dealId}
                      allInvestorLists={[
                        ALL_INVESTORS,
                        ...props.investorNameList,
                      ]}
                      investorNameText={props.investorNameText}
                      orderSummaryData={props.orderSummaryData}
                      trancheNameText={props.trancheNameText}
                      allTranchelists={[
                        ALL_TRANCHES,
                        ...props.trancheNameList,
                      ]}
                      timeIntervalText={props.timeIntervalText}
                      dateText={props.dateText}
                      getDateSummary={[
                        DEFAULT_ALL,
                        ...getSummaryDates(isOrderSummaryEnable),
                      ]}
                      selectedDate={props.selectedDate}
                      isOrderBookSummary={true}
                    />
                  </DropDownPicker>
                ) : null}
                <div className="tabbed-container relative">
                  <HorizontalTabs tabsId={tabIds.ALL_TRANCHES_SUB_TABS}>
                    <HorizontalTab
                      tabId={allTranchesOrderSummarySubTabs.VIEW_CHART}
                      title={props.viewChart}
                    >
                      {isOrderSummaryEnable ? (
                        <React.Fragment>
                        <CumulativeTextWrapper>
                        Note: Time interval is not to scale.
                        </CumulativeTextWrapper>
                        <OrderSummaryLineChart
                          lineChartSummary={getOrderSummaryList(props.orderSummaryData.orderSummary, false)}
                          lineChartList={props.trancheNameList}
                          notAvailable={props.notAvailable}
                          xAxisLabel='Date & Time (GMT)'
                          yAxisLabel='Cumulative Order Amount (m)'
                          xDy={440}
                          chartLabel="orderSummary"
                          tooltipLabel={props.timeIntervalText}
                          dataKey={'dateTimeInterval'}
                        />
                        </React.Fragment>
                      ) : (
                          <TileContentInner>
                            <EmptyContent>
                              {props.notAvailable}
                            </EmptyContent>
                          </TileContentInner>
                        )}
                    </HorizontalTab>
                    <HorizontalTab
                      tabId={allTranchesOrderSummarySubTabs.VIEW_TABLE}
                      title={props.viewTable}
                    >
                      {isOrderSummaryEnable ? (
                        <React.Fragment>
                          <CumulativeTextWrapper>
                            Point in time cumulative total
                          </CumulativeTextWrapper>
                          <OrderSummaryTable
                            timeIntervalText={props.timeIntervalText}
                            dateText={props.dateText}
                            trancheLists={props.trancheNameList}
                            orderSummaryList={getOrderSummaryList(
                              props.orderSummaryData.orderSummary,
                              true
                            )}
                            orderSummaryData={props.orderSummaryData}
                            notAvailable={props.notAvailable}
                          />
                        </React.Fragment>
                      ) : (
                          <TileContentInner>
                            <EmptyContent>
                              {props.notAvailable}
                            </EmptyContent>
                          </TileContentInner>
                        )}
                    </HorizontalTab>
                  </HorizontalTabs>
                </div>
              </Col>
            </Row>
             )
            }
            </>
          )}
          <OrderSummaryDetailView
            modalID={modalIds.ORDER_SUMMARY_TABLE_ROW_MODAL_ID}
            className={"close-modal-icon"}
          />
        </>
      ) : (
          <TranslationText
            dictionaryKey={"others.content_not_yet_available"}
            defaultText={"Content Not Yet Available"}
          />
        )}
    </>
  );
}


const mapStateToProps = (state) => ({
  currentTrancheData: getCurrentTranche(state),
  trancheTitles: getTrancheTitles(state),
  orderSummaryData: getOrderSummaryData(state),
  orderData: state.orderBookData.orderData,
  currentTrancheId: state.orderBookData.currentTrancheId,
  dealId: state.utility.dealsData.dealId,
  InvestorCountrySummaryData: getDemandDataForSummary(
    state,
    "investorCountrySummaryData"
  ),
  InvestorTypeSummaryData: getDemandDataForSummary(
    state,
    "investorTypeSummaryData"
  ),
  InvestorRegionSummaryData: getDemandDataForSummary(
    state,
    "investorRegionSummaryData"
  ),
  dateReceived: state.orderBookData.dateReceived,
  selectedDate: getSelectedDate(state),
  investorNameList: getInvestorNames(state),
  trancheNameList: getTrancheLists(state),
  loading: state.orderBookData.dataFetching,
  isOrderSummaryFiltererd: state.orderBookData.isOrderSummaryFiltererd
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTranche: (trancheId, loader) => dispatch(updateCurrentTranche(trancheId, "DCM")),
  openOrderSummaryDetailView: (summaryData) => dispatch(openOrderSummaryDetailView(summaryData)),
  getCurrentActiveSummaryTab: (activeTab) => dispatch(getCurrentActiveSummaryTab(activeTab)),
  changeLoderStatus: (value) => dispatch(changeLoderStatus(value)),
  handleHomeFeature: (dealFeature, dealId) => dispatch(handleHomeFeature(dealFeature, dealId)),
});

export default compose(
  withLanguage,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.ORDERBOOK_SUMMARY_PAGE),
    tabChangeEvent: (eventDetails) => ev(eventDetails),
  })),
  withTranslate((translate, props) => ({
    tab1Title: translate("orders.demand_by_region", "Demand by Region"),
    tab2Title: translate(
      "orders.demand_by_investor_type",
      "Demand by Investor Type"
    ),
    tab3Title: translate("orders.demand_by_country", "Demand by Country"),
    viewChart: translate("orders.view_chart", "View Chart"),
    viewTable: translate("orders.view_table", "View Table"),
    notAvailable: translate(
      "others.content_not_yet_available",
      "Content Not Yet Available"
    ),
    investorNameText: translate("orders.investor_name", "Investor Name"),
    trancheNameText: translate("orders.tranche_name", "Tranche Name"),
    timeIntervalText: translate("orders.time_interval", "Time Interval"),
    dateText: translate("orders.date", "Date"),
  }))
)(OrderSummaryContainer);
