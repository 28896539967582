import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { featurePageIds } from 'constants/featurePages';
import dealFeatures from 'constants/dealFeatures';
import { checkFeature } from 'actions/UtilityActions';
import { getRoadshowDataArrivedFlag } from 'selectors';

import PageContentNotAvailable from 'components/PageContentNotAvailable';

import Loading, { FeaturePageLoadingContainer } from 'shared/components/Loading';

class Roadshow extends PureComponent {
    componentDidMount() {
        this.props.checkFeature(dealFeatures.ROADSHOW);
    }

    render() {
        if (this.props.roadshowId && !this.props.roadshowDocMode) {
            if (this.props.location.pathname !== featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE) {
                browserHistory.push(featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE);
                return null;
            }
        }

        if (this.props.roadshowDocMode) {
            if (this.props.location.pathname !== featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE) {
                browserHistory.push(featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE);
                return null;
            }
        }

        if (!this.props.dataArrived && !this.props.roadshowId && !this.props.roadshowDocId) {
            return (
                <FeaturePageLoadingContainer>
                    <Loading />
                </FeaturePageLoadingContainer>
            )
        }

        if (this.props.dataArrived && !this.props.roadshowId && !this.props.roadshowDocId) {
            return <PageContentNotAvailable />; // or null if DI
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    roadshowId: state.roadShowData.roadshowId,
    roadshowDocId: state.roadShowData.fullScheduleDocumentId,
    roadshowDocMode: state.roadShowData.documentOnlyMode,
    dataArrived: getRoadshowDataArrivedFlag(state),
});

const mapDispatchToProps = dispatch => ({
    // polling
    checkFeature: feature => dispatch(checkFeature(feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roadshow);
