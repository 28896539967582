import React, { PureComponent } from "react";
import styled from "styled-components";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fontColors } from "shared/styles/variables/theme";
import { applyIsvCloseBanner, handleConfirmationDialogue } from "Isv/actions/IsvInformationBannerAction";
import RefFileUploadOverlay from 'Isv/components/FileUpload/FileUploadOverlays/RefFileUploadOverlay';
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import IsvInformationBanner from "Isv/components/InformationBanner/InformationBanner"
import ReferenceInformationTable from '../ReferenceInformation/ReferenceInformationTable'
import ConfirmationDialog from 'Isv/components/common/ConfirmationDialog';
import {
  viewReferenceDocument,
  handleOpenUploadOverlay,
  deleteReferenceDocument,
  handleReferenceInformationStatus
} from 'Isv/actions/IsvDocumentsAction';
import {
  getIsvCurrentDealId,
  selectedReferenceInformationDataForCurrentDeal,
  referenceInformationBannerReducer,
  isIsvExternalUser,
  selectConfirmationBannerStatus,
  selectDocumentDeleteId,
  selectIsvTableColumnWidth,
  dataFetching
} from "Isv/selectors";
import { PrimaryButton } from 'mk-pattern-library-react';
import { primaryBtnStyle } from 'Isv/styles/buttonStyle';
import Loading from 'shared/components/Loading';

const Header = styled.h2`
  font-size: 26px;
  color: ${fontColors.black};
  font-weight: 600;
`;

const InformationButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content : flex-end;
  width: 100%
  button {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`
const DocumentActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const LoadingContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

class ReferenceInformation extends PureComponent {
  
  viewDocument = (docRefId, title, fileName) => {
    this.props.viewReferenceDocument(docRefId, title, fileName);
  }

  enableDisableInformationBanner = () => {
    return this.props.isIsvCsmUser||this.props.isIsvRRTeamUser;   
  }

  CheckInformationBannerText = () => {
    if (this.props.isIsvCsmUser || this.props.isIsvRRTeamUser){
      return "You can upload reference information or documents of this deal here. Please use New Reference Document button to add new information. You can click on uploaded document title to download the document."
    }
  }

  handleDeleteReferenceDocument = (deleteDocumentId) => {
      this.props.handleConfirmationDialogue(false);
      if(deleteDocumentId ) 
        this.props.deleteReferenceDocument(deleteDocumentId);
  }

  handleDeleteConfirmationDialogue = (value, deleteDocRefId) => {
    this.props.handleConfirmationDialogue(value, deleteDocRefId);
  }

  getConfirmationDialogueSubheader = (deleteDocumentId) => {
    if(deleteDocumentId)
      return 'Are you sure you want to delete this information?';
  }

  handleReferenceDocUpload = (isOpen, document, title) => {
    this.props.handleOpenUploadOverlay(isOpen, document, title, false, true)
    this.setState({ isReviewSubmitted: false })
  }

  render() {
    const {
      isOpen,
      handleCloseBanner,
      isReferenceInformationBannerOpen,
      handleOpenUploadOverlay,
      handleConfirmationDialogue,
      deleteDocumentId,
      isIsvCsmUser,
      isIsvRRTeamUser,
      handleReferenceInformationStatus
    } = this.props;
    handleReferenceInformationStatus(true);
    return (
      <>
       {this.props.loading ? (
          <LoadingContainer>
            <LoadingWrapper>
              <Loading hasBackground={false} />
            </LoadingWrapper>
          </LoadingContainer>
        ) : null}
        <Header>Reference Information</Header>
        {isReferenceInformationBannerOpen && this.enableDisableInformationBanner &&
          <IsvInformationBanner
            bannerHeading="Information Review."
            bannerText={this.CheckInformationBannerText()}
            handleCloseBanner={handleCloseBanner}
            onCloseOverlay="isReferenceInformationBannerOpen"
          />
        }
        <RefFileUploadOverlay />

        <ReferenceInformationTable
          viewDocument={this.viewDocument}
          handleOpenUploadOverlay={handleOpenUploadOverlay}
          handleDeleteConfirmationDialogue={this.handleDeleteConfirmationDialogue}
        />

        <DocumentActionContainer>
          <InformationButtonContainer>
            {(isIsvCsmUser || isIsvRRTeamUser) &&
              <>
                  <PrimaryButton
                    size="normal"
                    id="upload-supporting-document-button"
                    disabled= {false}
                    style={primaryBtnStyle}
                    onClick={() => handleOpenUploadOverlay(true, null, null, false, true)}
                  >
                    New Reference Document
                  </PrimaryButton>
              </>
            }
          </InformationButtonContainer>
        </DocumentActionContainer>
       
        <ConfirmationDialog
          open={isOpen}
          confirmButtonText="Continue"
          declineButtonText = "Cancel"
          header="Warning"
          subHeader={this.getConfirmationDialogueSubheader(deleteDocumentId)}
          onConfirmClick={() => this.handleDeleteReferenceDocument(deleteDocumentId)}
          isCancelButtonNotRequired={deleteDocumentId ? false : true}
          onDeclineClick={() => handleConfirmationDialogue(false)}
        />
      </>
    )
  }
}

export default compose(
  connect(
    (state) => ({
      selectedReferenceData: selectedReferenceInformationDataForCurrentDeal(state),
      isReferenceInformationBannerOpen: referenceInformationBannerReducer(state),
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvCsmUser: isIsvCsmUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      deleteDocumentId: selectDocumentDeleteId(state),
      dealId: getIsvCurrentDealId(state),
      titleTableColumnWidth: selectIsvTableColumnWidth(state),
      loading: dataFetching(state),
      isOpen:selectConfirmationBannerStatus(state)
    }),
    (dispatch) => ({
      handleCloseBanner: (label) => {
        dispatch(applyIsvCloseBanner(label))
      },
      handleOpenUploadOverlay: (isOpen, document, title, isEcbClientDocUploaded, isReferenceDocUploaded) => {
        dispatch(handleOpenUploadOverlay(isOpen, document, title,isEcbClientDocUploaded, isReferenceDocUploaded))
      },
      handleConfirmationDialogue: (value, deleteDocRefId) => {
        dispatch(handleConfirmationDialogue(value, deleteDocRefId))
      },
      viewReferenceDocument: (docRefId, title, fileName) => {
        dispatch(viewReferenceDocument(docRefId, title, fileName))
      },
      deleteReferenceDocument: (id) => {
        dispatch(deleteReferenceDocument(id))
      },
      handleReferenceInformationStatus: (status) => {
        dispatch(handleReferenceInformationStatus(status))
      }
    })
  )
)(ReferenceInformation);
