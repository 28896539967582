import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { selectUploadedTitle, selectUploadedFile, selectedDocument, selectIsReferenceInformationEnabled } from "Isv/selectors";
import { handleOpenUploadOverlay } from 'Isv/actions/IsvDocumentsAction';
import { StyledForm, RowWrapper, ActionBar } from './styles';
import { PrimaryButton, SecondaryButton, LegacyButton } from 'mk-pattern-library-react';
import { defaultBtnStyle, primaryBtnStyle } from 'Isv/styles/buttonStyle';
import { ISV_DOCUMENT_TYPE } from 'Isv/constants/isvDocumentReviewStatus';

const FieldContainer = styled.div`
    width: 100%;
    margin-top: ${(props) => props.isMargin ? '50px' : '0'}
    .MuiFormControl-root {
        width: 100%;
        .MuiInput-root {
            &.Mui-focused {
                &::after {
                    border-bottom: 2px solid red;
                }
            }
        }
        .MuiFormHelperText-root {
            position: absolute;
            top: -20px;
            &.Mui-focused {
                color: #db0011
            }
        }
    }
`

const FileMetaForm = ({ title, handleOpenUploadOverlay, onSubmit, file, selectedDocument, isReferenceInformationEnabled }) => {

    const supportingData = {
        category: ISV_DOCUMENT_TYPE.SUPPORTING_DOCUMENT,
        description: '',
        title: title || '',
        isClientSupportingDocument: true,
        isSupportingDocument: true,
    }

    const ReferenceData = {
        category: ISV_DOCUMENT_TYPE.REFERENCE_DOCUMENT,
        description: '',
        title: title || '',
    }

    const [formData, updateFormData] = useState(selectedDocument ? selectedDocument : isReferenceInformationEnabled ? ReferenceData : supportingData);
    
    useEffect(() => {
        if(!selectedDocument && title) {
            updateFormData(supportingData)
        }
    }, [title])

    const handleFormSubmit = (e) => {
        onSubmit(formData);
    }

    const handleUploadFormChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleResetForm = () => {
        updateFormData({
            ...formData,
            title: '',
        });
    }

    return (
        <StyledForm
            className={`file-meta-form`}
        >
            <RowWrapper>
                <FieldContainer>
                    <TextField
                        name='title'
                        variant="standard"
                        id="filled-select-title"
                        helperText="Title"
                        value={formData.title}
                        onChange={handleUploadFormChange}
                        disabled={(formData && formData.documentId) || formData.clientFileId || formData.docRefId ? true : false}
                    />
                </FieldContainer>
                <FieldContainer>
                    <TextField
                        name="categoryId"
                        variant="standard"
                        id="filled-select-currency"
                        helperText="Category"
                        value={formData && formData.document ? formData.document.category.documentCategoryName : (formData.categoryId ? formData.category.documentCategoryName : isReferenceInformationEnabled ? 'Reference Document':'Supporting Document')}
                        onChange={handleUploadFormChange}
                        disabled
                    />
                </FieldContainer>
            </RowWrapper>
            <ActionBar>
                <PrimaryButton
                    size="normal"
                    id="upload-save-button"
                    disabled={(file && title && formData && formData.title.length > 0) || (file && formData && formData.title) ? false : true}
                    style={primaryBtnStyle}
                    onClick={handleFormSubmit}
                >
                    Save
                </PrimaryButton>
                
                <LegacyButton
                    size="normal"
                    id="upload-reset-button"
                    disabled={!(!formData.uploadedBy && formData.title.trim().length > 0)}
                    style={primaryBtnStyle}
                    onClick={handleResetForm}
                >
                    Reset
                </LegacyButton>

                <SecondaryButton
                    size="normal"
                    id="upload-close-button"
                    style={defaultBtnStyle}
                    onClick={() => handleOpenUploadOverlay(false)}
                >
                    Close
                </SecondaryButton>
                
            </ActionBar>
        </StyledForm>
    )
}

export default compose(
    connect(
        (state) => ({
            title: selectUploadedTitle(state),
            file: selectUploadedFile(state),
            selectedDocument: selectedDocument(state),
            isReferenceInformationEnabled: selectIsReferenceInformationEnabled(state)
        }),
        (dispatch) => ({
            handleOpenUploadOverlay: (isOpen) => {
                dispatch(handleOpenUploadOverlay(isOpen))
            }
        })
    )
)(FileMetaForm);
