export const enterKeyPress = (ev, fn) => {
    if (ev.key === "Enter") {
        fn();
    }
};



export const btnKeyPress = (ev, fn) => {
    if (ev.type === 'click') {
        fn();
    }
}

export const buttonKeyHandler = (ev, fn) => {
    if (
        // enter support
        ev.key === "Enter" ||
        ev.code === "Enter" ||
        ev.which === 13 ||
        // space support
        ev.key === " " ||
        ev.code === "Space" ||
        ev.which === 32
    ) {
        fn();
    }
};

export const escKeyHandler = (ev, fn) => {
    if (
        // esc support
        ev.key === "Escape" ||
        ev.code === "Escape" ||
        ev.which === 27
    ) {
        fn();
    }
}