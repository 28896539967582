import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import Scrollable from './Scrollable';

class ScrollableBody extends PureComponent {
    /*
     * This component is responsible for displaying element with height equal to rest of the viewport
     */
    constructor(props) {
        super(props);

        this.body = null;
    }

    calcHeight = () => {
        let height = 0;

        if (this.body && this.body.getClientRects) {
            height = Math.floor(window.innerHeight - this.body.getClientRects()[0].top);
        }

        return height;
    }

    updateHeight = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        this.body = ReactDOM.findDOMNode(this);

        window.addEventListener("resize", this.updateHeight);
        window.addEventListener("orientationChange", this.updateHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateHeight);
        window.removeEventListener("orientationChange", this.updateHeight);
    }

    render() {
        return (
            <Scrollable
                /* Responsible for scroll to top */
                scrollToTopFlag={this.props.scrollToTopFlag}
                /* Responsible for height cal */
                scrollHeight={this.calcHeight()}
                /* if height inside will change */
                update={this.updateHeight}
            >
                {this.props.children}
            </Scrollable>
        )
    }
}

class ScrollableTable extends Component {

    componentDidMount() {
        this.updateColumnWidths();

        window.addEventListener("resize", this.updateColumnWidths);
        window.addEventListener("orientationChange", this.updateColumnWidths);
    }

    componentDidUpdate() {
        this.updateColumnWidths();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColumnWidths);
        window.removeEventListener("orientationChange", this.updateColumnWidths);
    }

    updateColumnWidths = () => {
        const node = ReactDOM.findDOMNode(this);
        if (!node) return

        const { width } = node.parentNode.getBoundingClientRect();

        const totalFlex = this.props.columns.reduce((total, column, index) =>
        total += column.columnFlex || 1
        , 0)

        const widthPerFlex = width / totalFlex;

        //
        // HEADER
        //
        Array.from(node.querySelectorAll('thead th')).forEach((header, index) => {
            const dim =  widthPerFlex * this.props.columns[index].columnFlex + 'px';
            header.style['max-width'] = dim;
            header.style['min-width'] = dim;
            header.style.width = dim;
        })

        //
        // BODY
        //
        Array.from(node.querySelectorAll('tbody tr')).forEach(tableRow => {
            Array.from(tableRow.querySelectorAll('td')).forEach((cell, index) => {
                const dim =  widthPerFlex * this.props.columns[index].columnFlex + 'px';
                cell.style['max-width'] = dim;
                cell.style['min-width'] = dim;
                cell.style.width = dim;
            })
        })

        //
        // FOOTER
        //
        Array.from(node.querySelectorAll('tfoot tr')).forEach(tableRow => {
            Array.from(tableRow.querySelectorAll('th')).forEach((cell, index) => {
                const dim =  widthPerFlex * this.props.columns[index].columnFlex + 'px' || '100%';
                cell.style['max-width'] = dim;
                cell.style['min-width'] = dim;
                cell.style.width = dim;
            })
        })

        const thead = node.querySelector('thead');
        const tbody = node.querySelector('tbody');
        const tfoot = node.querySelector('tfoot');
        if (tbody && thead) {
            const dimensions = tbody.getBoundingClientRect();
            thead.style.width = `${dimensions.width}px`;

            if (tbody) {
                tbody.style.width = `${dimensions.width}px`;
            }
            if (tfoot) {
                tfoot.style.width = `${dimensions.width}px`;
            }
        }
    }

    render() {
        const { columns, ...props } = this.props;

        const enhancedColumns = columns.map(col => ({
            ...col,
            onSort: () => {
                setTimeout(() => this.updateColumnWidths && this.updateColumnWidths(), 0);
            }
        }));

        return (
            <ScrollableBody>
                <BootstrapTable
                    ref={(container) => this.container = container}
                    columns={enhancedColumns}
                    { ...props }
                />
            </ScrollableBody>
        )
    }
}

export default ScrollableTable;
