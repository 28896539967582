import React, { Component , Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { StackedBarChart } from 'mk-pattern-library-react';
import ChartTableContainer from 'shared/components/ChartTableContainer';
import styled from 'styled-components';
import { compose } from 'recompose';
import withTranslate from 'shared/hoc/withTranslate';
import { emptyCellFormatter } from '../../SharedTableComponents/formatters';
import numeral from 'numeral';

import {
  firstHeaderAlignedLeftStyle,
  firstRowAlignedLeftStyle,
  otherHeaderAlignedRightStyle,
  otherRowAlignedRight,
} from 'shared/components/Table';

const convertDataFieldNameToColumnTranslationKey = key => {
  switch(key.toLowerCase()) {
    case 'investortype' :
      return 'investor_type';
    case 'day' :
      return 'date_of_demand';
    case 'country' :
      return 'country';
    default:
      return key;
  }
}

const transformData = (keyField, data, sort) => {
  if(!data.length) {
    return [];
  }

  const keys = Object.keys(data[0]).filter(key => key !== 'name');

  let tableData = keys.reduce((transformedData, key, keyIndex) => {
      if(key === 'name') {
        return transformedData;
      }

      transformedData.push({
        [keyField]: key,
        ...data.reduce((valuesData, item, valuesIndex) => ({
          ...valuesData,
          [valuesIndex]: item[key],
        }), {})
      });

    return transformedData;
  }, [])

  if(sort) {
    tableData = tableData.sort((a, b) => {
      return a[sort] >= b[sort] ? 1 : -1
    });
  }

  return tableData;
}

const dataFormatter = value => {
  if(Number.isNaN(Number(value))) {
    return '-';
  }

  return Number(value).toFixed(2);
}

const ChartLegend = styled.h4`
  padding-left: 10px;
`;

const Columns = (props, data) => {

  if(!data.length) {
    return [];
  }

  const columns = [{
    dataField: props.keyField,
    text: props.keyFieldText,
    columnFlex: 1,
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
    },
    style: {
      ...firstRowAlignedLeftStyle,
    },
    formatter: props.keyFieldFormatter || emptyCellFormatter
  }];

  columns.push(...data.map((item, index) => ({
    dataField: index,
    text: `${data[index].name} (${props.currency})`,
    columnFlex: 1,
    formatter: dataFormatter,
    headerStyle:otherHeaderAlignedRightStyle,
    style: {
      ...otherRowAlignedRight,
      textOverflow: 'initial',
    }

  })));

  return columns;
}

const yAxisTickFormatter = val =>
  numeral(val).format('0,0');

class DemandByCarouselPanelBar extends Component {
    render() {
        const tableData = transformData(this.props.keyField, this.props.chartData, this.props.tableSort);

        return (
          <Row style={this.props.panelStyle} >

              <div className="clearfix" style={{ padding: "10px" }}>
                  <Col sm={12}>
                    <ChartTableContainer
                      keyField={this.props.keyField}
                      tableData={tableData}
                      columns={Columns(this.props, this.props.chartData)}
                      caption={this.props.tableCaption}
                      buttonStyle={this.props.buttonStyle}
                      tableStyle={this.props.tableStyle}
                    >
                    <Fragment>
                      <ChartLegend>
                        {this.props.legendTitle}
                      </ChartLegend>
                      <StackedBarChart
                          wideColorPalette={this.props.wideColorPalette}
                          maxYTicks={8}
                          marginTop={this.props.marginTop || null}
                          xLegend={true}
                          legendTitle={this.props.xAxisLabel}
                          height={this.props.height}
                          data={this.props.chartData}
                          keys={this.props.keyData}
                          title={this.props.panelLabel}
                          hasTarget={false}
                          legendHeight="60"
                          // memory leak
                          animate={false}
                          tickFormatter={yAxisTickFormatter}
                      />
                      </Fragment>
                    </ChartTableContainer>
                  </Col>
              </div>
          </Row>
        )
    }
}

export default compose(
  withTranslate((translate, props) => ({
    keyFieldText: translate(`orders.${convertDataFieldNameToColumnTranslationKey(props.keyField)}`, props.keyField),
    tableCaption: translate('orders.demand_table_caption', `Table showing demand by ${props.type}`)
  })),
)(DemandByCarouselPanelBar);
