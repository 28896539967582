export const APP_NAME = 'MyDeal';

export const AUTH_TYPES = {
  PASSWORD: 'password',
  MOBILE_APPROVE: 'mobile_approve',
  TOTP: 'totp',
  IU:'iu'
}

export const LAAS_API_METHODS = {
  VERIFY_USERNAME  : 'verifyUsername',
  VERIFY_TOTP_CODE : 'verifyTotpCode',
  PUSH_NOTIFICATION : 'pushNotification',
  CANCLE_PUSH_NOTIFICATION : 'cancelPushNotification'
}