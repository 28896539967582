import { PureComponent } from 'react';
import propTypes from 'prop-types';

class HorizontalTab extends PureComponent {
    render() {
        if (!this.props.visible) {
            return null;
        }
        
        return this.props.children;
    }
}

HorizontalTab.propTypes = {
    // identified by 
    tabId: propTypes.string.isRequired,
    // tab title
    title: propTypes.string.isRequired,
    // additional actions ( adobe event etc. )
    onTabClicked: propTypes.func,
    // optimisation purposes
    visivble: propTypes.bool,
}

HorizontalTab.defaultProps = {
    visible: true,
};

export default HorizontalTab;
