import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { compose } from 'recompose';
// TODO
import { HsbcButton } from 'mk-pattern-library-react';

import { getTrancheTitles, getCurrentTranche, getDCMFullOrderBookTable, getDCMAllTranchesFullOrderBookTable } from 'selectors';
import { requestOrders, updateCurrentTranche, changeFullOrderBookSort, requestOrdersForAllTranches, changeAllTranchesFullOrderBookSort, getCurrentActiveSummaryTab } from 'actions/OrderBookActions';

import TranslationText from 'translations/TranslationText';

import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import FullOrderBookTable from './FullOrderBookTable';
import FullOrderBookAllTranchesTable from './FullOrderBookAllTranchesTable';
import OrderbookHeader from '../../shared/summary/OrderbookHeader';
import { ALL_TRANCHES_RECORD, ORDER_SUMMARY_TABS } from 'constants/orders';
import { Row, Col } from 'react-bootstrap';
import Loading from 'shared/components/Loading';
import styled from "styled-components";

const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

class FullOrderBookContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeView: "orderDetail",
        };
        this.handleViewToggle = this.handleViewToggle.bind(this);
        this.handleTrancheNavClick = this.handleTrancheNavClick.bind(this);
    }

    componentDidMount() {
        this.props.viewChange();
        this.props.getCurrentActiveSummaryTab(ORDER_SUMMARY_TABS.FULLORDERBOOK);
    }

    handleTrancheNavClick(selectedTranche) {
        this.props.updateCurrentTranche(selectedTranche.id);
    }

    handleViewToggle(activeView) {
        this.setState({ activeView });
    }

    render() {
        const currentTrancheData = this.props.currentTrancheData;
        const active = { id: this.props.currentTrancheId };
        return (
            currentTrancheData ? (
                <div style={{ backgroundColor: "#fff" }}>

                    <OrderbookHeader fullOrderbook={true} dealType='dcm' headerData={currentTrancheData} dateReceived={this.props.dateReceived} trancheTitles={this.props.trancheTitles} trancheClick={this.handleTrancheNavClick} trancheActive={active} />

                    {
                        active.id !== ALL_TRANCHES_RECORD.id &&
                        <>
                            <ButtonGroup style={{ marginBottom: 80 }}>
                                <HsbcButton
                                    id='btn-dcm-fo-od'
                                    size="regular"
                                    className={"btn-segment" + (this.state.activeView === "orderDetail" ? " active" : "")}
                                    onClick={() => {
                                        this.handleViewToggle("orderDetail");
                                        this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_ORDER_DETAIL)
                                    }}
                                >
                                    <TranslationText
                                        dictionaryKey={"orders.order_detail"}
                                        defaultText={"Order Detail"}
                                    />
                                </HsbcButton>
                                <HsbcButton
                                    id='btn-dcm-fo-it'
                                    size="regular"
                                    className={"btn-segment" + (this.state.activeView === "investorType" ? " active" : "")}
                                    onClick={() => {
                                        this.handleViewToggle("investorType");
                                        this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_INVESTOR_TYPE)
                                    }}
                                >
                                    <TranslationText
                                        dictionaryKey={"orders.investor_type"}
                                        defaultText={"Investor Type"}
                                    />
                                </HsbcButton>
                            </ButtonGroup>
                        </>
                    }
                    {
                        active.id !== ALL_TRANCHES_RECORD.id ?
                            <FullOrderBookTable
                                data={this.props.fullOrderBookTable}
                                viewType={this.state.activeView}
                                currentTrancheData={currentTrancheData}
                                onSort={this.props.changeFullOrderBookSort}
                            />
                            :
                            <>
                                {
                                    this.props.loading ? (
                                        <LoadingContainer>
                                            <LoadingWrapper>
                                                <Loading hasBackground={true} />
                                            </LoadingWrapper>
                                        </LoadingContainer>
                                    ) :
                                    this.props.allTranchesFullOrderBookTable.length ?
                                        <>
                                            <Row>
                                                <Col sm={12}>
                                                    <div style={{ borderBottom: '1px solid #C9C9C9' }}></div>
                                                </Col>
                                            </Row>
                                            <FullOrderBookAllTranchesTable
                                                data={this.props.allTranchesFullOrderBookTable}
                                                viewType={this.state.activeView}
                                                onSort={this.props.changeAllTranchesFullOrderBookSort}
                                            />
                                        </>
                                        :
                                        <div className="text-center">
                                            <TranslationText
                                                dictionaryKey={"others.content_not_yet_available"}
                                                defaultText={"Content Not Yet Available"}
                                            />
                                        </div>
                                }
                            </>
                    }
                </div>
            ) : (
                    <div>
                        <TranslationText
                            dictionaryKey={"others.content_not_yet_available"}
                            defaultText={"Content Not Yet Available"}
                        />
                    </div>
                )
        );
    }
}
const mapStateToProps = (state) => ({
    dealId: state.utility.dealsData.dealId,
    trancheTitles: getTrancheTitles(state),
    currentTrancheId: state.orderBookData.currentTrancheId,
    currentTrancheData: getCurrentTranche(state),
    dateReceived: state.orderBookData.dateReceived,
    fullOrderBookTable: getDCMFullOrderBookTable(state),
    allTranchesFullOrderBookTable: getDCMAllTranchesFullOrderBookTable(state),
    loading: state.orderBookData.dataFetching
});

const mapDispatchToProps = dispatch => ({
    requestOrders: (trancheId, loader) => dispatch(requestOrders(trancheId, loader)),
    requestOrdersForAllTranches: (dealId, loader) => dispatch(requestOrdersForAllTranches(dealId, loader)),
    updateCurrentTranche: (trancheId, loader) => dispatch(updateCurrentTranche(trancheId, 'DCM')),
    changeFullOrderBookSort: (column, direction) => dispatch(changeFullOrderBookSort(column, direction)),
    changeAllTranchesFullOrderBookSort: (column, direction) => dispatch(changeAllTranchesFullOrderBookSort(column, direction)),
    getCurrentActiveSummaryTab: (activeTab) => dispatch(getCurrentActiveSummaryTab(activeTab))
});

export default compose(
    withAnalytics((ev, view) => ({
        viewChange: () => view(featurePageIds.ORDERBOOK_FULL_PAGE),
        buttonChangeEvent: eventDetails => ev(eventDetails),
    })),
    connect(mapStateToProps, mapDispatchToProps)
)(FullOrderBookContainer);
