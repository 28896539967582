import { AnalyticsTypes } from 'actions/Types';

const viewChange = viewId => ({
    type: AnalyticsTypes.VIEW_CHANGE,
    viewId,
});

const eventTriggered = eventDetails => ({
    type: AnalyticsTypes.EVENT_TRIGGERED,
    eventDetails,
});

export {
    viewChange,
    eventTriggered,
};
