//
// All Used Icons since Landing Page refactor
//
import React from 'react';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';

class BaseFontIcon extends React.PureComponent {
    render() {
        let alt;

        switch (this.props.icon) {
            //
            // icomoon
            //
            case 'Chevron': {
                alt = this.props.chevronAlt;
                break;
            }
            case 'Document': {
                alt = this.props.documentAlt;
                break;
            }
            case 'Profile': {
                alt = this.props.profileAlt;
                break;
            }
            case 'Orders': {
                alt = this.props.ordersAlt;
                break;
            }
            case 'ChatBubble': {
                alt = this.props.chatAlt;
                break;
            }
            //
            // glyphter
            //
            case 'gPlane': {
                alt = this.props.calendarAlt;
                break;
            }
            case 'gPrice': {
                alt = this.props.percentageAlt;
                break;
            }
            default:
              console.log(`Unrecognised font icon ${this.props.icon}`);
        }

        if (!alt) return null;

        const other = {};
        other['aria-hidden'] = this.props['aria-hidden'];
        other['aria-checked'] = this.props['aria-checked'];

        return (
             <span
                className={`icon-${this.props.icon} ${this.props.className}`}
                style={this.props.style}
                aria-label={alt || ''}
                role="img"
                {...other}
            />
        );
    }
}

export default compose(
    withTranslate(
        translate => ({
            chevronAlt: translate('', 'Chevron'),
            documentAlt: translate('', 'Document'),
            profileAlt: translate('', 'Profile'),
            ordersAlt: translate('', 'Bucket'),
            chatAlt: translate('', 'Chat'),
            //
            calendarAlt: translate('', 'Calendar'),
            percentageAlt: translate('', 'Perecentage'),
        }),
    ),
)(BaseFontIcon);
