import React from 'react';
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from 'recharts';
import { extendedColorsForLineChart } from 'mk-pattern-library-react/utils/visualisationColors';
import { TileContentInner, EmptyContent } from 'Landing/dcm/Tiles/styles.js';
import { SvgIcon } from 'shared/components/Icon';
import styled from 'styled-components';
import { ValueFormatters } from 'mk-pattern-library-react';

const AmendedOrderLegendsContainer = styled.div`
  width: 1162px;
  text-align: center;
  margin-left: -40px
`;

const AmendedOrderLegendsUlList = styled.ul`
  margin: 5px 0 10px !important;
  padding: 0px;
  text-align: center;
`;

const AmendedOrderLegendsList = styled.li`
  display: inline-block;
  margin-right: 10px;
`;

const AmendedOrderLegendsWrapper = styled.div`
  display: inline-block;
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const AmendedOrderLegendItem = styled.span``;

const legendIconStyle = {
  height: '14px',
  width: '14px',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: '4px'
}

const getChartSlices = (lineChartSummary, lineChartList, chartLabel) => {
  switch (chartLabel) {
    case 'bookEvaluation':
    case 'orderSummary': {
      const tranches = Object.keys(lineChartSummary[0]);
      const filteredTrancheList = lineChartList.filter(i => tranches.includes(i.key));
      const isAnimation = filteredTrancheList.length === 1 ? false : true
      return filteredTrancheList.map((tranche, index) => {
        const color = extendedColorsForLineChart(index, tranche.key);
        return <Line key={index} dataKey={tranche.value} stroke={color} activeDot={{ r: 8 }} isAnimationActive={isAnimation} />
      })
    }
    case 'amendedOrderSummary': {
      return lineChartList.map((order, index) => {
        const color = extendedColorsForLineChart(index, lineChartSummary[index].dateTimeInterval);
        return <Line connectNulls key={index} dataKey={lineChartList[index].orderId} stroke={color} activeDot={{ r: 8 }} />
      })
    }
    default: return null;
  }
}

const AxisLabel = ({ axisType, x, y, width, height, children, xDy }) => {
  const isVert = axisType === 'yAxis';
  const cx = isVert ? x : x + (width / 2);
  const cy = isVert ? (height / 2) + y : (xDy ? xDy : y + height + 10);
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle" fontWeight={600} fill='#666'>
      {children}
    </text>
  );
};

const renderCusomizedLegend = (lineChartSummary, lineChartList, trancheOrders) => {
  let barIndex = -1;
  return (
    <AmendedOrderLegendsContainer>
      {
        trancheOrders.map((tranche, index) => {
          return tranche.count > 0 &&
            <AmendedOrderLegendsWrapper key={index}>
              {tranche.trancheName}
              <AmendedOrderLegendsUlList>
                {
                  getOrdersList(tranche.trancheName, lineChartList).map((item, index) => {
                    barIndex++;
                    const color = extendedColorsForLineChart(barIndex, lineChartSummary[barIndex].dateTimeInterval);
                    return (
                      <AmendedOrderLegendsList key={index}>
                        <SvgIcon
                          icon="chart-legend"
                          aria-hidden
                          style={legendIconStyle}
                          color='none'
                          strokeWidth='4'
                          stroke={color}
                        />
                        <AmendedOrderLegendItem>{item.orderId}</AmendedOrderLegendItem>
                      </AmendedOrderLegendsList>
                    )
                  })}
              </AmendedOrderLegendsUlList>
            </AmendedOrderLegendsWrapper>
        })
      }
    </AmendedOrderLegendsContainer>
  );
}

const getOrdersList = (trancheName, list) => {
  const data = list.filter((item) => item.trancheName === trancheName)
  return data;
}

const OrderSummaryLineChart = ({
  lineChartSummary,
  lineChartList,
  trancheOrders,
  notAvailable,
  xAxisLabel,
  yAxisLabel,
  chartLabel,
  tooltipLabel,
  dataKey,
  xDy,
}) => {
  return (
    <>
      {
        lineChartSummary.length ?
          <div className='ordersummary-chart'>
            <ResponsiveContainer width='120%' aspect={3}>
              <LineChart data={lineChartSummary} margin={{ top: 10, right: 300, left: 29 }}>
                <CartesianGrid />
                <XAxis
                  dataKey={dataKey}
                  interval={0}
                  height={180}
                  textAnchor='end'
                  angle={-90}
                  label={<AxisLabel axisType='xAxis' xDy={xDy}>{xAxisLabel}</AxisLabel>}
                />
                <YAxis
                  tickFormatter={ValueFormatters.commaWithoutDecimalFormatter}
                  label={<AxisLabel axisType='yAxis'>{yAxisLabel}</AxisLabel>}
                />
                {
                  chartLabel === 'amendedOrderSummary' ?
                    <Legend content={renderCusomizedLegend(lineChartSummary, lineChartList, trancheOrders)} /> :
                    <Legend />
                }
                <Tooltip
                  formatter={ValueFormatters.commaDecimalFormatter}
                  labelFormatter={
                    (value) => {
                      return `${tooltipLabel}: ${value}`
                    }
                  }
                />
                {getChartSlices(lineChartSummary, lineChartList, chartLabel)}
              </LineChart>
            </ResponsiveContainer>
          </div> :
          <TileContentInner>
            <EmptyContent>
              {notAvailable}
            </EmptyContent>
          </TileContentInner>
      }
    </>
  )
};

export default React.memo(OrderSummaryLineChart);
