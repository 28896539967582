import React, { Fragment } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

const DLContainer = styled.dl`
    margin: 0;

    dt {
      font-weight: normal;
    }
`;

const StatusBar = props => {
    let trancheTargetAmount,
        trancheTotalOrderAmount,
        orderPercentage;

    if (props.barType === 'dcm') {
        trancheTargetAmount = props.currentTranche.trancheTargetAmount;
        trancheTotalOrderAmount = props.currentTranche.trancheTotalOrderAmount;
        orderPercentage = props.currentTranche.trancheTotalSubscribed;
    } else {
        trancheTargetAmount = props.currentTranche.dealIpoValue;
        trancheTotalOrderAmount = props.currentTranche.dealTotalDemandAmountAtLowestPrice;
        orderPercentage = props.currentTranche.dealTotalDemandCoverage;
    }

    let progress = Math.round((orderPercentage) * 100);
    let progressBarOver = false;

    if (progress > 100) {
        progressBarOver = true;
        progress = ((1 / orderPercentage) * 100);
    }

    return (
      <DLContainer>
        <Row role="region" aria-label={translationString("orders.orderbook_summary", props.language, "Orderbook Summary")} style={{ margin: '0', padding: '20px 5px' }}>
        <Col sm={2}>
            <dt className="text-small">
              <TranslationText
                  dictionaryKey={"orders.status"}
                  defaultText={"Status"}
              />
            </dt>
            <dd className="text-xlarge">{props.currentTranche.orderbookStatusDetail.currentStatus.status || ''}</dd>
        </Col>
        <Col sm={2}>
          <Row style={{ margin: 0 }}>
            <dt className="text-small">
              {
                props.barType === 'dcm' ? (
                  <TranslationText
                    dictionaryKey={"orders.target"}
                    defaultText={"Target"}
                  />
                ) : (
                  <TranslationText
                    dictionaryKey={"orders.deal_size"}
                    defaultText={"Deal Size"}
                  />
                )
              } ({props.currency || ''}m)
            </dt>
          </Row>
          <Row style={{ margin: 0 }}>
            <dd className="text-xlarge">
                <div style={{ display: 'table', width: '100%' }}>
                  <div style={{ position: 'relative', top: '4px', lineHeight: '20px', float: 'left', marginRight: '5px' }}>{ValueFormatters.millionFormatter(trancheTargetAmount)}</div>

                  <ProgressBar
                    style={{ position: 'relative', left: '15px', top: '4px' }}
                    className={progressBarOver ? 'status-bar-progress progress-bar-over' : 'status-bar-progress '}
                    now={progress}
                  />
                </div>
              </dd>
          </Row>
        </Col>
        <Col sm={2}>
          <Row style={{ margin: 0 }}>
            <dt className="text-small"  style={{ whiteSpace: 'nowrap'}}>
              {
                props.barType === 'dcm' ? (
                  <Fragment>
                    <TranslationText
                      dictionaryKey={"orders.total_orders"}
                      defaultText={'Total Orders'}
                    /> ({props.currency || ''}m)
                  </Fragment>
                ) : (
                  <TranslationText
                      dictionaryKey={"orders.demand_@_<price>"}
                      replace={[
                          ["<price>", ValueFormatters.commaDecimalFormatter(props.dealDemandSharePrice)]
                      ]}
                      defaultText={`Demand @ ${ValueFormatters.commaDecimalFormatter(props.dealDemandSharePrice)}`}
                  />
                )
              }
            </dt>
          </Row>
          <Row style={{ margin: 0 }}>
            <dd>
              <div className="text-xlarge">{ValueFormatters.millionFormatter(trancheTotalOrderAmount)}</div>
            </dd>
          </Row>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={12}>
              <dt className="text-small">
                {
                  props.barType === 'dcm' ? (
                    <TranslationText
                      dictionaryKey={"orders.subscribed"}
                      defaultText={'Subscribed'}
                    />
                  ) : (
                    <TranslationText
                      dictionaryKey={"orders.coverage"}
                      defaultText={'Coverage'}
                    />
                  )
                }
              </dt>
              <dd className="text-xlarge">{orderPercentage ? `${ValueFormatters.commaDecimalFormatter(orderPercentage)}x` : ''}</dd>
            </Col>
          </Row>
        </Col>
          <Col sm={2}>
            <Row>
              <Col sm={12}>
                <dt className="text-small">
                  <TranslationText
                    dictionaryKey={"orders.orders"}
                    defaultText={'Orders'}
                  />
                </dt>
                <dd className="text-xlarge">
                  {props.total}
                </dd>
              </Col>
            </Row>
          </Col>
      </Row>
      </DLContainer>
    );
};

export default withLanguage(StatusBar);
