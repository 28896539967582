import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Row, Col } from 'react-bootstrap';
import TranslationText from 'translations/TranslationText';
import { getRoadShowContacts, getRoadShowSummaryCoordinators } from 'selectors';
import Scrollable from 'shared/components/Scrollable';

class ContactsModal extends PureComponent {
  render() {
    const { contacts, roadShowCoordinators } = this.props;

    const contactMasterList = {
      issuers: [],
      banks: [],
      travelContacts: [],
    };

    contacts.forEach((company) => {
      if (company.people.length) {
        switch (company.people[0].personTypeId) {
          case 1:
            contactMasterList.issuers.push(company);
            break;
          case 2:
          case 3:
              contactMasterList.banks.push(company);
              break;
          case 4:
              contactMasterList.travelContacts = contactMasterList.travelContacts.concat(company.people.map(person => person));
              break;
          default:
              break;
        }
      }
    });

    contactMasterList.banks.sort((a, b) => {
      // HSBC needs to be listed first
      if (a.companyName.toLowerCase() === 'hsbc') {
          return -1;
      } else if (b.companyName.toLowerCase() === 'hsbc') {
          return 1;
      }

      if (a.companyName < b.companyName) {
          return -1;
      } else if (a.companyName > b.companyName) {
          return 1;
      } else {
          return 0;
      }
    });

    return (
      <Scrollable
        scrollHeight={ window.innerHeight * .5}
        style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Row style={{ marginLeft: 0, marginRight: 0 }} >
          {
            contactMasterList.issuers.map((company, ind) => (
              <Col key={ind} sm={12} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                <h2 className="roadshow-contact-type-header text-univ-bd text-medium">
                  {company.companyName}
                </h2>
                {
                  company.people.map((person, ind) => (
                    <div key={ind} className="investor-list-cell contact-cell">
                      <Row>
                        <Col sm={12} className="text-univ-bd text-normal">
                          {person.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-small">
                          <Row>
                            <Col sm={4} className="roadshow-contact-list-person-details-position">{person.title}</Col>
                            <Col sm={1} className="roadshow-contact-list-person-details roadshow-contact-list-person-details-team" />
                            <Col sm={2} className="roadshow-contact-list-person-details">T: {person.directTelephone}</Col>
                            <Col sm={2} className="roadshow-contact-list-person-details">M: {person.mobileTelephone}</Col>
                            <Col sm={3} className="roadshow-contact-list-person-details"><a href={`mailto:${person.email}`}>{person.email}</a></Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </Col>
            ))
          }

          <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <h2 className="roadshow-contact-type-header text-univ-bd text-medium">
              <TranslationText
                dictionaryKey={"roadshow.banks"}
                defaultText={"Banks"}
              />
            </h2>
            {
              contactMasterList.banks.map((company, ind) => (
                <Row key={ind}>
                  <Col sm={12}>
                    <h3 className="roadshow-contact-company-header text-univ-bd text-large" >
                      {company.companyName}
                    </h3>
                  </Col>
                  <Col sm={12} style={{ marginBottom: 30 }}>
                    {
                      company.people.map((person, ind) => (
                        <div key={ind} className="investor-list-cell contact-cell">
                          <Row>
                            <Col sm={12} className="text-univ-bd text-normal">
                              {person.name}
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="text-small">
                              <Row>
                                <Col sm={4} className="roadshow-contact-list-person-details-position">{person.title}</Col>
                                <Col sm={1} className="roadshow-contact-list-person-details roadshow-contact-list-person-details-team" />
                                <Col sm={2} className="roadshow-contact-list-person-details">T: {person.directTelephone}</Col>
                                <Col sm={2} className="roadshow-contact-list-person-details">M: {person.mobileTelephone}</Col>
                                <Col sm={3} className="roadshow-contact-list-person-details"><a href={`mailto:${person.email}`}>{person.email}</a></Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      ))
                    }
                  </Col>
                </Row>
              ))
            }
          </Col>
          <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <h2 className="roadshow-contact-type-header text-univ-bd text-medium">
              <TranslationText
                dictionaryKey={"roadshow.travel_contacts"}
                defaultText={"Travel Contacts"}
              />
            </h2>
            <Row>
              <Col sm={12} >
                {
                  roadShowCoordinators.length > 0 && roadShowCoordinators.map((coordinator) => (
                    <div className="investor-list-cell contact-cell">
                      <Row>
                        <Col sm={12} className="text-univ-bd text-normal">
                          {coordinator.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-small">
                          <Row>
                            <Col sm={4} className="roadshow-contact-list-person-details-position">
                              <TranslationText
                                dictionaryKey={"roadshow.roadshow_coordinator"}
                                defaultText={"Roadshow Coordinator"}
                              />
                            , {coordinator.title}</Col>
                            <Col sm={1} className="roadshow-contact-list-person-details roadshow-contact-list-person-details-team" />
                            <Col sm={2} className="roadshow-contact-list-person-details">T: {coordinator.directTelephone}</Col>
                            <Col sm={2} className="roadshow-contact-list-person-details">M: {coordinator.mobileTelephone}</Col>
                            <Col sm={3} className="roadshow-contact-list-person-details"><a href={`mailto:${coordinator.email}`}>{coordinator.email}</a></Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))
                }

                {
                  contactMasterList.travelContacts.map((person, ind) => (
                    <div key={ind} className="investor-list-cell contact-cell">
                      <Row>
                        <Col sm={12} className="text-univ-bd text-normal">
                          {person.title}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-small">
                          <Row>
                            <Col sm={4} className="roadshow-contact-list-person-details-position">{person.company}</Col>
                            <Col sm={1} />
                            <Col sm={3} className="roadshow-contact-list-person-details">
                              <TranslationText
                                dictionaryKey={"roadshow.24_hours_t"}
                                defaultText={"24 Hours T"}
                              />
                              : {person.directTelephone}
                            </Col>
                            <Col sm={4} className="roadshow-contact-list-person-details">
                              <TranslationText
                                dictionaryKey={"roadshow.driver_direct_m"}
                                defaultText={"Driver Direct M"}
                              />
                              : {person.mobileTelephone}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Scrollable>
    );
  }
}

export default compose(
  connect(
    state => ({
        roadShowCoordinators: getRoadShowSummaryCoordinators(state),
        contacts: getRoadShowContacts(state),
    }),
  ),
)(ContactsModal);
