export default {
    TERMS: {
        name: 'TERMS',
        path: 'terms.html',
    },
    EULA: {
        name: 'EULA',
        path: 'eula.html',
    },
    C_POLICY: {
        name: 'C_POLICY',
        path: 'cpolicy.html',
    },
    CONTACT_US: {
        name: 'CONTACT_US',
        path: 'contactus.html',
    },
    PRIVACY: {
        name: 'PRIVACY',
        path: 'privacy.html',
    },
    BIOMETRIC_TERMS: {
        name: 'BIOMETRIC_TERMS',
        path: 'biometricterms.html'
    },
    BIOMETRIC_FAQ: {
        name: 'BIOMETRIC_FAQ',
        path: 'biometricfaq.html'
    },
    ONE_WAY_LETTER: {
        name: 'ONE_WAY_LETTER',
        path: 'onewayletter.html'
    }
};