//
// All events that are matching WCAG WAI-ARIA requirements should be moved here
//


// Button events (onKeyPress - check if each key is supported for all browsers - IE)
export const buttonEventHandling = ev => {
    if (
        // click support (easier checking)
        ev.type === 'click' ||
        // enter support
        ev.key === "Enter" ||
        ev.code === "Enter" ||
        ev.which === 13 ||
        // space support
        ev.key === " " ||
        ev.code === "Space" ||
        ev.which === 32     
    ) {
        return true;
    }
    return false;
}

// Link Event (onKeyPress - check if each key is supported for all browsers - IE)
export const anchorEventHandling = ev => {
    if (
        // click support (easier checking)
        ev.type === 'click' ||
        // enter support
        ev.key === "Enter" ||
        ev.code === "Enter" ||
        ev.which === 13
    ) {
        return true;
    }
    return false;
}

//
// ...
// 