import { createReducer } from 'reduxsauce';

import IsvTypes, { IsvUtilityTypes } from '../actions/IsvTypes';

export const INITIAL_STATE = {
  fetching: false,
  allDeals: [],
  showOneWayLetter: false,
  dealSwitch: false,
  isISVUser: false,
  isvBaseFetchCompleted: false,
  dataFetching:false,
  // current deal info
  dealsData: {
    dealId: null,
  }  
};

const apiRequest = (state, action) => Object.assign({}, state, {
  fetching: action.isFetching,
  name: action.name,
});

const apiFailure = (state, action) => Object.assign({}, state, {
  error: true,
  message: action,
});


const receiveIsvDeals = (state, action) => {
  const nullData = (action.allDeals === null) || (action.allDeals.length === 0);
  return Object.assign({}, state, {
    error: null,
    allDeals: nullData ? [] : action.allDeals,
    dateReceived: Date.now(),
  });
};

const receiveIsvDeal = (state, action) => {
  const nullData = (action.dealsData === null) || (action.dealsData.length === 0);
  return Object.assign({}, state, {
    fetching: false,
    error: null,
    dealsData: nullData?[]:action.dealsData,
    dateReceived: Date.now(),
  });
};
const openOneWayLetter =(state, action) => ({
  ...state,
  showOneWayLetter: true,
});
const acceptOneWayLetter  = (state, action) => ({
  ...state,
  dealSwitch: false,
});

const isvSwitchDeal =(state)=>({
  ...state,
  dealSwitch:true
})

const isvBaseFetchCompleted = (state, action) => {
  if (!action.dealId) {
    return Object.assign({}, state, { isvBaseFetchCompleted: true });
  }
  
  return Object.assign({}, state, {
    isvBaseFetchCompleted: true,
    dealsData: {
      ...state.dealsData,
      dealId: action.dealId,
    },
  });
}

const dataFetching = (state, action) => ({
  ...state,
 dataFetching: action.dataFetching,
});

const ACTION_HANDLERS = {
  [IsvTypes.API_REQUEST]: apiRequest,
  [IsvTypes.API_FAILURE]: apiFailure,
  [IsvUtilityTypes.API_GET_ISV_DEALS_RESPONSE]: receiveIsvDeals,
  [IsvUtilityTypes.API_GET_ISV_DEAL_RESPONSE]: receiveIsvDeal,
  [IsvUtilityTypes.OPEN_ONE_WAY_LETTER]: openOneWayLetter,
  [IsvUtilityTypes.ACCEPT_ONE_WAY_LETTER]: acceptOneWayLetter,
  [IsvUtilityTypes.ISV_SWITCH_DEAL]: isvSwitchDeal,
  [IsvUtilityTypes.ISV_BASE_FETCH_COMPLETED]: isvBaseFetchCompleted, 
  [IsvUtilityTypes.DATAFETCHING]: dataFetching,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
