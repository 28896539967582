//
// Component Enhancer that provides additional value from store ( selected language )
//
import { connect } from 'react-redux';

import { getSelectedLanguage } from 'selectors';

function withLanguage(WrappedComponent) {
    return connect(
        state => ({
            language: getSelectedLanguage(state),
        }),
    )(WrappedComponent);
}

export default withLanguage;
