import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import styled from 'styled-components';

import { FontIcon } from 'shared/components/Icon';

import withModals from 'shared/hoc/withModals';
import Popup from 'shared/components/Popup';
import DropdownSystem from './DropdownSystem';
import Scrollable from 'shared/components/Scrollable';
import { Row, Col } from 'shared/components/Grid';

import { styledEllipsis } from 'shared/styles/mixins/ellipsis';
import keys from 'shared/accessibility/constants/keys';
import { outlines, typography } from 'shared/styles/variables/theme'

const CustomIcon = styled(FontIcon)`
    display: inline-block;

    font-size: 10px;
    margin-top: 2px;
    margin-right: 2px;

    transition: transform .3s linear;

    &.opened {
        transform: rotateX(180deg) rotateZ(90deg);
    }
    &.closed {
        transform: rotateX(0deg) rotateZ(90deg);
    }
`;

const Chevron = ({ reversed, isDropdownOpen, isMasthead }) => (
    <CustomIcon
        aria-hidden
        className={isDropdownOpen ? 'opened' : 'closed'}
        style={{
            color: isMasthead ? '#fff' : reversed ? '#969696' : '#000',
        }}
        icon="Chevron"
    />
);

export const ItemContent = styled.span`
    display: inline-block;
    width: 100%;
    ${styledEllipsis}
`;

const MastheadStyleOverrides = {
  background: '#253038',
  color: '#969696',
}

const ScrollContent = {
     maxHeight: '350px', 
     overflowY: 'auto' 
}

const CustomButton = styled.button`
    font-size: ${typography.sizes['12px']};
    display: flex;
    flex-direction: row;
    align-items: center;

  &.btn-action.btn-masthead:before {
    content: '';
     display: inline-block;
     height: 100%;
     vertical-align: middle;
     margin-right: -0.25em; /* Adjusts for spacing */
    }
  &.btn-action.btn-masthead {
    text-align:center;
    height: 100%;
    border: none;
    &.btn-action {

      color: #fff;
      background: transparent;
    }
    & span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.btn-action {
       border-color: ${props => props.reversed ? '#969696' : '#333'};
       color: ${props => props.reversed ? '#969696' : '#333'};
       background: transparent;

       &:hover, &:active {
           border-color: ${props => props.reversed ? '#969696' : '#333'};
           color: ${props => props.reversed ? '#969696' : '#333'};
           background: transparent;
       }

       &:focus {
           border-color: ${props => props.reversed ? '#969696' : '#333'};
           color: ${props => props.reversed ? '#969696' : '#333'};
           background: ${props => props.reversed ? 'transparent' : '#f2f2f2'};
           outline: ${props => props.reversed ? '1px solid #7d7d7d' : '1 px solid black'};
       }
   }
`;

const DropdownText = styled.span`
    display: inline-block;
    width: calc(100% - 10px);
    text-align: left;
    line-height: 1.3;
    margin: -4px 0;
    ${styledEllipsis}
`;

const DropdownOption = styled.li`
    cursor: pointer;
    line-height: 20px;
    list-style: none;
    &:hover .LinesEllipsis {
        text-decoration: underline;
    }

    &:hover {
        background-color: ${props => props.isMasthead ? '#1D262C' : '#d9d9d9'};
        color: ${props => props.isMasthead ? '#FFF' : 'inherit'};
    }

    ${/*border-left: ${props => props.isSelected ? 'solid 4px rgb(224, 224, 223)': 'none'};*/''}

    ${props => (props.isSelected ?
      props.isMasthead ? 'border-left: solid 4px #DB0011': 'border-left: solid 4px rgb(224, 224, 223)' : '')};
    ${props => (props.isSelected && props.isMasthead ? 'background-color: #1D262C': 'transparent')};

    &:focus {
        ${props => props.reversed ? `outline: ${outlines.light};` : ''}
    }
`;

const StyledRow = styled(Row)`
    position: relative;
    padding: 10px 15px;
`;

const StyledColumn = styled(Col)`
    height: 20px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
`;

class DropdownList extends PureComponent {

    constructor() {
        super()

        this.container = React.createRef()
    }

    select = i => {
        const node = ReactDOM.findDOMNode(this);
        node.querySelector(`#dropdown-item-${i}`).focus();
    }

    focusNext = i => {
        if (i === this.props.items.length - 1) return;
        this.select(i + 1);
    }
    focusPrev = i => {
        if (i === 0) return;
        this.select(i - 1);
    }
    focusFirst = () => this.select(0);
    focusLast = () => this.select(this.props.items.length - 1);

    printable = key => {
        const character = String.fromCharCode(key);

        // get index of current focused element

        this.keysSoFar = `${this.keysSoFar || ''}${character}`;
        this.clearKeysSoFarAfterDelay();

        // firstly check if from currently selected is match

        // otherwise find all before current focused element

        const nextMatch = this.findMatchInRange(0, this.props.items.length - 1);
        if (typeof nextMatch === 'number') {
            this.select(nextMatch);
        }
    }

    clearKeysSoFarAfterDelay = () => {
        if (this.keyClear) {
            clearTimeout(this.keyClear);
            this.keyClear = null;
        }
        this.keyClear = setTimeout(() => {
            this.keysSoFar = '';
            this.keyClear = null;
        }, 500);
    };

    findMatchInRange = (startIndex, endIndex) => {
        for (let n = startIndex; n < endIndex; n++) {
            const label = this.props.items[n].content;
            if (label && label.toUpperCase().indexOf(this.keysSoFar) === 0) {
                return n;
            }
        }
        return null;
    };

    keyboardEvent = (e, i) => {
        e.persist();
        e.preventDefault();

        const code = e.which || e.keyCode;

        switch(code) {
            case keys.tab: {
                if (e.shiftKey) {
                    this.focusPrev(i);
                } else {
                    this.focusNext(i);
                }
                break;
            }
            case keys.enter: {
                this.props.items[i].click();
                this.props.close();
                break;
            }
            case keys.esc: {
                this.props.close();
                break;
            }
            case keys.down: {
                this.focusNext(i);
                break;
            }
            case keys.up: {
                this.focusPrev(i)
                break;
            }
            case keys.end: {
                this.focusLast();
                break;
            }
            case keys.home: {
                this.focusFirst();
                break;
            }
            default: {
                this.printable(code);
                break;
            }
        }
    }

    componentDidMount() {
        if(this.container) {
            const domNode = ReactDOM.findDOMNode(this.container.current).parentNode;
            const buttonNode = domNode.parentNode.parentNode.childNodes[0]

            const listRight = domNode.getClientRects()[0].right;
            const buttonNodeRight = buttonNode.getClientRects()[0].right;

            if ( listRight > window.innerWidth ) {
                domNode.style.left = buttonNodeRight - listRight + 'px';
            }
        }
    }

    render() {
        const list = (
            <ul
                id={this.props.listId}
                role="listbox"
                style={{ margin: 1, padding: 0, listStyle: 'none' }}
                
            >
                {
                    this.props.items.map((item, i) => {
                        // Only one dropdown will be opened so ID's are fine
                        return (
                            <DropdownOption
                                id={`dropdown-item-${i}`}
                                key={`dropdown-item-${i}`}
                                role="option"
                                aria-label={item.label}
                                aria-selected={item.selected}
                                tabIndex={0}
                                onKeyDown={e => this.keyboardEvent(e, i)}
                                onKeyUp={e => e.preventDefault()}
                                onKeyPress={e => e.preventDefault()}
                                onClick={() => {
                                    item.click()
                                    this.props.close();
                                }}
                                onFocus={item.onFocus}
                                isSelected={item.selected}
                                isMasthead={this.props.isMasthead}
                                reversed={this.props.reversed}
                            >
                            {
                                this.props.renderOption
                                  ? this.props.renderOption(item)
                                  : <StyledRow>
                                        <StyledColumn size={12} title={item.content}>
                                            {
                                                this.props.renderOptionContent ? (
                                                    this.props.renderOptionContent(item)
                                                ) : (
                                                    <ItemContent>
                                                        {item.content}
                                                    </ItemContent>
                                                )
                                            }
                                        </StyledColumn>
                                    </StyledRow>
                             }
                                {
                                    this.props.isItemBorder && this.props.items.length - 1 !== i && (
                                        <div style={{ borderBottom: '1px solid #E0E0DF', margin: '0 10px' }} />
                                    )
                                }
                            </DropdownOption>
                        )
                    })
                }
            </ul>
        )

        const style = this.props.isMasthead ? MastheadStyleOverrides : {};

        return (
           

            <Popup ref={this.container} style={style}>
                {
                    this.props.items.length > 6 && (
                        <Scrollable scrollHeight={'auto'} style={ScrollContent}>
                            {list}
                        </Scrollable>
                    )
                }
                {
                    this.props.items.length <= 6 && (
                        list
                    )
                }
            </Popup>
        )
    }
}

class Dropdown extends PureComponent {

    state = {
        index: 0,
    };

    dropdownOpenClick = () => {
        if (!this.props.isDropdownOpen) {
            this.props.openDropdown();
        }
        if (this.props.dropdownOpened) {
            // additional tasks
            this.props.dropdownOpened();
        }
    }

    dropdownCloseClick = () => {
        this.props.closeDropdown();
    }

    buttonKeyPressed = e => {
        const code = e.which || e.keyCode;
        switch (code) {
            case keys.down: {
                this.props.openDropdown();
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        const items = [...this.props.items.map((e, index) => ({
            ...e,
            onFocus: () => this.setState({ index }),
        }))];

        return (
            <DropdownSystem
                modalID={this.props.dropdownId}
                isModalOpen={this.props.isDropdownOpen}
                closeModal={this.dropdownCloseClick}
                content={
                    <DropdownList
                        listId={`${this.props.dropdownId}-list`}
                        reversed={this.props.reversed}
                        isMasthead={this.props.isMasthead}
                        isItemBorder={!this.props.isMasthead}
                        items={items}
                        close={this.dropdownCloseClick}
                        renderOption={this.props.renderOption}
                        renderOptionContent={this.props.renderOptionContent}
                    />
                }
				clickableElement={
					<CustomButton
						id={`${this.props.dropdownId}-btn`}
						className={`btn-action btn-action-type ${this.props.isMasthead ? 'btn-masthead' : ''}`}
                        reversed={this.props.reversed}
						onClick={this.dropdownOpenClick}
                        onKeyDown={this.buttonKeyPressed}
                        style={{
                            position: 'relative',
                        }}
                        aria-expanded={this.props.isDropdownOpen}
                        aria-haspopup="true"
                        aria-owns={`${this.props.dropdownId}-list`}
                        aria-label={this.props.label}
                        title={this.props.dropdownText}
                        aria-activedescendant={
                            `dropdown-item-${this.state.index}`
                        }
					>
                        {
                            this.props.clickableContent ? (
                                this.props.clickableContent
                            ) : (
                                <DropdownText>
                                    {this.props.dropdownText}
                                </DropdownText>
                            )
                        }
						&nbsp;&nbsp;&nbsp;
						<Chevron {...this.props} />
					</CustomButton>
				}
                width={this.props.width || 280}
			/>
        );
    }
}

export default compose(
    withModals(
        (selection, ownProps) => ({
            isDropdownOpen: selection(ownProps.dropdownId),
        }),
        (close, open, ownProps) => ({
            openDropdown: () => open(ownProps.dropdownId),
            closeDropdown: () => close(ownProps.dropdownId),
        }),
    ),
)(Dropdown);
