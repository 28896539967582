import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { SvgIcon } from 'shared/components/Icon';

import { tabIds, roadshowContactTabs } from 'constants/tabIds';
import HorizontalTab from 'shared/accessibility/components/HorizontalTabs/HorizontalTab';
import HorizontalTabs from 'shared/accessibility/components/HorizontalTabs';

import Popup, { Divider } from 'shared/components/Popup';
import PopupSystem from 'shared/components/PopupSystem';
import Scrollable from 'shared/components/Scrollable';

import withTranslate from 'shared/hoc/withTranslate';
import HsbcLink from 'shared/accessibility/components/Link';

import {
    getRoadShowSummaryOnline,
    getRoadShowContacts,
    getRoadShowSummaryCoordinators
} from 'selectors';

import ContactsModalContent from './ContactsModal.component';
import CoordinatorsContent from './CoordinatorsModal.component';
import OnlineRoadshowContent from './OnlineRoadshowModal.component';

const EnhancedDivider = styled(Divider)`
    margin: 0 0 30px;
`;

const EmptySpace = styled.div`
    height: 20px;
    width: 100%;
`;

class CombinedContacts extends PureComponent {
    modalOpenClick = () => {
        if (!this.props.isModalOpen) {
            this.props.openModal();
            this.props.adobeOpenModalEvent();
        }
    }

    render() {
        return (
            <PopupSystem
                centered
                backgroundLayer
                modalID={this.props.modalId}
                isModalOpen={this.props.isModalOpen}
                closeIcon
                closeModal={this.props.closeModal}
                content={
                    <Popup
                        static
                        header="Roadshow Details"
                        headerKey="roadshow.roadshow_details"
                        primaryButton
                        primaryButtonContent="Close"
                        primaryButtonKey="common.close"
                        primaryButtonAction={this.props.closeModal}
                        width={this.props.visibleTab === roadshowContactTabs.ALL_CONTACTS ? 1250 : undefined}
                    >
                        <EnhancedDivider />
                        <HorizontalTabs tabsId={tabIds.ROADSHOW_CONTACT_TABS}>
                            <HorizontalTab
                                tabId={roadshowContactTabs.ALL_CONTACTS}
                                title={this.props.allContactsTabText}
                                visible={this.props.visibleTab === roadshowContactTabs.ALL_CONTACTS}
                                onTabClicked={() => {
                                    // adobe analytics event (?)
                                }}
                            >
                            <Scrollable
                                style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                            >
                                  <ContactsModalContent />
                            </Scrollable>

                            </HorizontalTab>
                            <HorizontalTab
                                tabId={roadshowContactTabs.COORDINATORS}
                                title={this.props.roadshowCoordinatorsText}
                                visible={this.props.visibleTab === roadshowContactTabs.COORDINATORS}
                                onTabClicked={() => {
                                    // adobe analytics event (?)
                                }}
                            >
                                <Scrollable
                                    style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                                >
                                    <CoordinatorsContent />
                                </Scrollable>
                            </HorizontalTab>
                            <HorizontalTab
                                tabId={roadshowContactTabs.ONLINE}
                                title={this.props.onlineRoadshowText}
                                visible={this.props.visibleTab === roadshowContactTabs.ONLINE}
                                onTabClicked={() => {
                                    // adobe analytics event (?)
                                }}
                            >
                                <Scrollable
                                    style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                                >
                                    <OnlineRoadshowContent />
                                </Scrollable>
                            </HorizontalTab>
                        </HorizontalTabs>
                        <EmptySpace aria-hidden/>
                    </Popup>
                }
                clickableElement={
                    <HsbcLink
                        id={`${this.props.modalId}-btn`}
                        title={this.props.btnText}
                        disabled={this.props.isBtnDisabled}
                        onClick={this.modalOpenClick}
                        style={{ marginLeft: 15 }}
                        aria-label={this.props.btnText}
                        aria-haspopup
                    >
                      <div style={{ width: 20, height: 10, display: 'inline-block', position: 'relative' }}>
                            <SvgIcon
                                icon="pop-up-window"
                                aria-hidden
                                style={{ position: 'absolute', top: -5, left: -4 }}
                            />
                        </div>
                        {this.props.btnText}
                    </HsbcLink>
                }
            />
        );
    }
}

export default compose(
    withTranslate(
        translate => ({
            // tabs
            allContactsTabText: translate('roadshow.all_contacts', 'All Contacts'),
            roadshowCoordinatorsText: translate('roadshow.roadshow_coordinators', 'Roadshow Coordinators'),
            onlineRoadshowText: translate('roadshow.online_roadshow', 'Online Roadshow'),
        })
    ),
    connect(
        state => ({
            // duplication in each dedicated hoc ( we're aware of that )
            isOnlineRoadshowAvailable: getRoadShowSummaryOnline(state) && getRoadShowSummaryOnline(state).url,
            areAllContactsAvailable: getRoadShowContacts(state).length > 0,
            areRoadshowCoordinatorsAvailable: getRoadShowSummaryCoordinators(state).length > 0,
        }),
    ),
)(CombinedContacts);
