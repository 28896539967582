import { createReducer } from "reduxsauce";
import IsvTypes from "Isv/actions/IsvTypes";
import FILE_UPLOAD_STATES from 'Isv/constants/fileUploadStates';

const INITIAL_STATE = {
    isOverlayOpen: false,
    file: null,
    title: '',
    selectedDocument: null,
    progress: 0,
    status: FILE_UPLOAD_STATES.READY,
    clientDocumentData: null,
    latestCommentsDataForCurrentDeal: null,
    latestCommentsDataForCurrentComment: null,
    isCommentsPopupOpen: false,
    rejectLabel: null,
    isAdditionalComment: false,
    titleTableColumnWidth: 'auto',
    ecb2ReturnDataForCurrentDeal: null,
    isECBStatusEnabled: false,
    isEcbClientDocUploaded: false,
    isReferenceInformationEnabled:false,
    referenceInformationDataForCurrentDeal:null
};

const uploadFileDocument = (state, action) => {
    let fileWithoutExtension = action.file.name.split('.');
    fileWithoutExtension.pop();
    fileWithoutExtension = fileWithoutExtension.join('.');
    return {
        ...state,
        file: action.file,
        title: action.title || fileWithoutExtension
    };
};

const handleUploadOverlay = (state, action) => {
    return {
        ...state,
        isOverlayOpen: action.isOpen,
        selectedDocument: action.document,
        status: FILE_UPLOAD_STATES.READY,
        progress: 0,
        file: null,
        title: action.title,
        isEcbClientDocUploaded: action.isEcbClientDocUploaded,
        isReferenceDocUploaded: action.isReferenceDocUploaded
    }
}

const startUploadingFile = (state) => {
    return {
        ...state,
        status: FILE_UPLOAD_STATES.IN_PROGRESS,
    };
}

const setUploadProgress = (state, action) => {
    return {
        ...state,
        progress: action.progress,
    };
}

const handleClientDocuments = (state, action) => {
    return {
        ...state,
        clientDocumentData: action.data
    }
}

const handleLatestCommentsForCurrentDeal = (state, action) => {
    return {
        ...state,
        latestCommentsDataForCurrentDeal: action.data
    }
}

const handleLatestCommentsForCurrentComment = (state, action) => {
    return {
        ...state,
        latestCommentsDataForCurrentComment: action.data
    }
}

const handleCommentsPopup = (state, action) => {
    return {
        ...state,
        isCommentsPopupOpen: action.isOpen,
        isAdditionalComment: action.isAdditionalComment,
        rejectLabel: action.rejectLabel,
    }
}

const setTitleColumnWidth = (state, action) => {
    return {
        ...state,
        titleTableColumnWidth: action.width
    }
}

const handleECB2ReturnDataForCurrentDeal = (state, action) => {
    return {
        ...state,
        ecb2ReturnDataForCurrentDeal: action.data
    }
}

const handleECBStatus = (state, action) => {
    return {
        ...state,
        isECBStatusEnabled: action.status,
    }
}

const handleReferenceInformationStatus = (state, action) => {
    return {
        ...state,
        isReferenceInformationEnabled: action.status,
    }
}

const handleReferenceInformationDataForCurrentDeal = (state, action) => {
    return {
        ...state,
        referenceInformationDataForCurrentDeal: action.data
    }
}

const ACTION_HANDLERS = {
    [IsvTypes.APPLY_HANDLE_FILE_UPLOAD]: uploadFileDocument,
    [IsvTypes.APPLY_HANDLE_UPLOAD_OVERLAY]: handleUploadOverlay,
    [IsvTypes.START_UPLOADING]: startUploadingFile,
    [IsvTypes.UPLOAD_PROGRESS]: setUploadProgress,
    [IsvTypes.HANDLE_CLIENT_DOCUMENT_DATA]: handleClientDocuments,
    [IsvTypes.API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_DEAL]: handleLatestCommentsForCurrentDeal,
    [IsvTypes.API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT]: handleLatestCommentsForCurrentComment,
    [IsvTypes.HANDLE_COMMENTS_POPUP]: handleCommentsPopup,
    [IsvTypes.HANDLE_TITLE_COLUMN_WIDTH]: setTitleColumnWidth,
    [IsvTypes.API_RECEIVE_ECB2_RETURN_DATA_FOR_CURRENT_DEAL]: handleECB2ReturnDataForCurrentDeal,
    [IsvTypes.HANDLE_ECB_STATUS]: handleECBStatus,
    [IsvTypes.HANDLE_REFERENCE_INFORMATION_STATUS]: handleReferenceInformationStatus,
    [IsvTypes.API_RECEIVE_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL]: handleReferenceInformationDataForCurrentDeal,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
