import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import moment from 'moment-timezone';
// TODO
import { ValueFormatters, Icon } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';
import { ImgIcon } from 'shared/components/Icon';
import { requestDoc } from 'actions/MyDocsActions';

import HsbcLink from 'shared/accessibility/components/Link';


const InvestorDetails = (props) => {
    const now               = moment(),
          investorIconStyle = {
            verticalAlign: 'super',
            marginRight  : 10,
            paddingLeft  : 15,
            height:15
        },

        profileIconStyle    = Object.assign({ height: 15 }, investorIconStyle),
        feedbackIconStyle   = Object.assign({ height: 14 }, investorIconStyle),
        historicalIconStyle = Object.assign({ height: 16 }, investorIconStyle),

        investorLinkStyle = {
            display : 'inline-block',
            width   : 80,
            fontSize: 12,
        },
        downloadIconStyle = {
            width: '18px',
            marginRight: 5,
        },
        spacingLg = { marginBottom: 8 },
        invProStyle = {
            ...spacingLg,
            paddingLeft: 15
        };

    const { event, eventTypeId, investors } = props;
    const { startDateLocal, investorProfiles } = event;
    // Meeting types 4 and 5 (Group, Group Conference) need logic surrounding present / past states and which investors were present
    // True if a group AND is in the past
    const pastGroupEvent = (moment(startDateLocal).isBefore(now)) && (eventTypeId === 4 || eventTypeId === 5) ? true : false;

    const handleDownloadClick = (documentVersionId) => {
        if(!documentVersionId) return;
        
        props.requestDoc(documentVersionId, "Investor Profile");
    }

    const investorInfo = (investor, ind) => (
        <ListGroupItem key={ind} className="investor-list-cell text-univ-rg">
            <Row>
                <Col sm={6}>
                    {investor.name}
                </Col>
                <Col sm={6}>
                    {ValueFormatters.choosePhoneFormatter(investor)}
                </Col>
            </Row>
        </ListGroupItem>
    )

    const investorDetailSection = (firmName, documentVersionId, investorList) => {
        if (investorList.length > 0) {
            return (
                <div>
                    { 
                        documentVersionId && 
                        <HsbcLink 
                            className="text-univ-lt" 
                            style={invProStyle}
                            onClick={() => handleDownloadClick(documentVersionId)}
                        >
                            { 
                                <div
                                    title="Investor Profile" 
                                    style={{display:"inline-block"}} 
                                >
                                    <ImgIcon
                                        style={downloadIconStyle}
                                        icon="DocumentDownload"
                                        aria-hidden
                                    />
                                </div>
                            }
                            {firmName}
                            {/* {investorList.keyInvestor && (<Icon style={{ height: '12px', verticalAlign: 'initial', marginLeft: 10 }} iconName="star-gray" />)} */}
                        </HsbcLink>
                    }
                    { !documentVersionId &&
                        <div className="text-univ-lt" style={invProStyle}>
                            {firmName}
                        </div>
                    }
                    <Row style={spacingLg} className="text-univ-lt">
                        {/* Need logic surrounding these buttons - do we have this information for the firm?  */}
                        {investorList.profile && (<Col sm={4}>
                            <Icon style={profileIconStyle} iconName="profile" />
                            <span className="text-investor" style={investorLinkStyle}>
                                <TranslationText
                                    dictionaryKey={"roadshow.investor_profile"}
                                    defaultText={"Investor Profile"}
                                />
                            </span>
                        </Col>)}
                        {investorList.feedback && (<Col sm={4}>
                            <Icon style={feedbackIconStyle} iconName="chat" />
                            <span className="text-investor" style={investorLinkStyle}>
                                <TranslationText
                                    dictionaryKey={"roadshow.investor_feedback"}
                                    defaultText={"Investor Feedback"}
                                />
                            </span>
                        </Col>)}
                        {investorList.history && (<Col sm={4}>
                            <Icon style={historicalIconStyle} iconName="historicalInvesments" />
                            <span className="text-investor" style={investorLinkStyle}>
                                <TranslationText
                                    dictionaryKey={"roadshow.historical_investments"}
                                    defaultText={"Historical Investments"}
                                />
                            </span>
                        </Col>)}
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ListGroup className="text-small">
                                {/*Need to map over investors  */}
                                {investorList.map(investorInfo)}
                            </ListGroup>
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return null
        }
    }

    // Create objects of investors to group them by firm
    let investorsByFirm = {};

    investors.forEach((investor) => {

        if (!investorsByFirm[investor.company]) {
            investorsByFirm[investor.company] = [];
        }

        investorsByFirm[investor.company].push(investor);
    });

    let investorDetailSections  = [],
        investorDetailSections2 = [];

    const firmNames = Object.keys(investorsByFirm).sort((a, b) => {
        if (a < b) {
            return -1
        }
        else if (a > b) {
            return 1
        }
        else {
            return 0
        }
    });
    firmNames.forEach((firmName) => {
        // sort by lastname
        investorsByFirm[firmName].sort((a, b) => {
            let aSplit = a.name.split(' '),
                bSplit = b.name.split(' '),

                name1 = aSplit[aSplit.length - 1],
                name2 = bSplit[bSplit.length - 1];

            if (name1 < name2) {
                return -1
            }
            else if (name1 > name2) {
                return 1
            }
            else {
                return 0
            }

        });

        let attendedInvestors    = [],
            notAttendedInvestors = [];

        for (var i = 0; i < investorsByFirm[firmName].length; i++) {
            const investor = investorsByFirm[firmName][i];

            if (pastGroupEvent) {
                if (investor.attended) {
                    attendedInvestors.push(investor);
                }
                else {
                    notAttendedInvestors.push(investor);
                }
            }
            else {
                attendedInvestors.push(investor);
            }

        }
        const investorProfilesObj = investorProfiles.find( 
            investorProfile => investorProfile.company ===  firmName
        );

        const documentVersionId = (investorProfilesObj) ? investorProfilesObj.documentVersionId : null;

        const attendedInvestorsSection    = investorDetailSection(firmName, documentVersionId, attendedInvestors),
              notAttendedInvestorsSection = investorDetailSection(firmName, documentVersionId, notAttendedInvestors);
        if (attendedInvestorsSection) {
            investorDetailSections.push(attendedInvestorsSection);
        }
        if (notAttendedInvestorsSection) {
            investorDetailSections2.push(notAttendedInvestorsSection);
        }
    })

    return (
        <Row >
            {!pastGroupEvent && (<Col sm={12}>
                <div className="text-small text-univ-lt" style={{ marginBottom: 5, paddingLeft: 15  }}>
                    <TranslationText
                        dictionaryKey={"roadshow.investors"}
                        defaultText={"Investors"}
                    />
                </div>
            </Col>)}

            {(pastGroupEvent && (investorDetailSections.length > 0)) && (<Col sm={12}>
                <div className="text-small text-univ-lt" style={{ marginBottom: 5, paddingLeft: 15   }}>
                    <TranslationText
                        dictionaryKey={"roadshow.investors_investor_present"}
                        defaultText={"Investors Present"}
                    />
                </div>
            </Col>)}

            {(investorDetailSections.length > 0) && investorDetailSections.map((section, ind) => { return <Col sm={12} style={spacingLg} key={ind}>{section}</Col> })}

            {(pastGroupEvent && (investorDetailSections2.length > 0)) && (<Col sm={12}>
                <div className="text-small text-univ-lt" style={{ marginBottom: 5, paddingLeft: 15   }}>
                    <TranslationText
                        dictionaryKey={"roadshow.investors_not_present"}
                        defaultText={"Investors Not Present"}
                    />
                </div>
            </Col>)}
            {pastGroupEvent && (investorDetailSections2.length > 0) && investorDetailSections2.map((section, ind) => { return <Col sm={12} style={{ paddingLeft: 15 }} key={ind}>{section}</Col> })}

        </Row>
    )
}

export default compose(
    connect(
        null,
        dispatch => ({
            requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName)),
        })
    ),
)(InvestorDetails);