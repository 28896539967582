/* @flow weak */
import React from 'react';
import PropTypes from 'prop-types';

export default function Flag({
    props,
    onClick,
    className,
    size,
    children,
}) {

    let flagStyle = {
        color: '#000',
        height:'21px',
        fontFamily: 'Univers Next',
        fontSize : '12px',
        lineHeight:'14px',
        display: 'inline-block',
        marginLeft : '10px',
        position:'relative',
        verticalAlign:'middle',
    }

    let textContainerStyle = {
        height:'100%',
        backgroundColor : '#EDB343',
        // float:'left',
        padding:'4px 10px'
    }

    // let tailStyle = {
    //     height:'100%',
    //     // float:'left',
    //     position:'absolute',
    //     left:'calc(100% - 1px)',
    //     top : '0px'

    // }

    return (
        <div style={flagStyle}>
            {children && (<div style={textContainerStyle}>{children}</div>)}
            {/* <img style={tailStyle} src={FlagTail}/> */}
        </div>
    )

}

Flag.propTypes = {
	/**
	 * Flag label
	 */
	children: PropTypes.string,
};

Flag.defaultProps = {
    children: ""
};
