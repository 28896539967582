import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { onlyUpdateForKeys, pure, compose } from 'recompose';
import moment from 'moment';

import { ValueFormatters, Sorters } from 'mk-pattern-library-react';
import {  millionFormatter  } from '../../SharedTableComponents/formatters';

import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import withTranslate from 'shared/hoc/withTranslate';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import Table, {
  firstHeaderAlignedLeftStyle,
  firstRowAlignedLeftStyle,
  otherHeaderAlignedRightStyle,
  otherRowAlignedRight,
  otherRowAlignedLeft,
  otherHeaderAlignedLeftStyle
} from 'shared/components/Table';
import { ellipsis } from 'shared/styles/mixins/ellipsis';

import { tabIds, topTenOrdersTabs } from 'constants/tabIds';
import HorizontalTab from 'shared/accessibility/components/HorizontalTabs/HorizontalTab';
import HorizontalTabs from 'shared/accessibility/components/HorizontalTabs';

const TablesContainer = compose(
  onlyUpdateForKeys(['children', 'props']),
  pure,
)(styled.div`
  padding: 30px 20px 20px;
  height: ${props => props.height}px;
`);

const Columns = props => {
  const columns = [{
    dataField: 'investorLongName',
    text: translationString("orders.investor", props.language, "Investor"),
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
      paddingLeft: 8,
      width: '210px',
    },
    style: {
      ...firstRowAlignedLeftStyle,
      paddingLeft: 8,
    },
    formatter: (val, data, index) => (
      <span>
        <span className='details-table-prepend-index'>{(index + 1)}</span>
        <div style={ellipsis} title={val.trim()} className='details-table-prepend-body'>{val}</div>
      </span>
    ),
  }, {
    dataField: 'investorType',
    text: translationString("orders.type", props.language, "Type"),
    headerStyle: {
      ...otherHeaderAlignedLeftStyle,
      width: '70px',
    },
    style: {
      ...otherRowAlignedLeft,
    },
    formatter: (val, data, index) => (
      (!val && val !== false) ? '-' : <div style={ellipsis} title={val.trim()} >{val}</div>
    ),
  }];

  if (props.type === 'ecm') {
    columns.push({
      dataField: 'shareDemandSummary.demandAtBottomSharePrice.demandAmount',
      headerFormatter: () => (
        <React.Fragment>
          <TranslationText
            ictionaryKey={"orders.demand"}
            defaultText={"Demand"}
          />
          &nbsp;@<br />
          {
            ValueFormatters.commaDecimalFormatter(props.currentTrancheData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice)
          }
          ({props.currentTrancheData.dealCurrency}m)
        </React.Fragment>
      ),
      headerStyle: {
        ...otherHeaderAlignedRightStyle,
        width: '85px',
      },
      style: {
        ...otherRowAlignedRight,
      },
      formatter: (val, data, index) => (
        ValueFormatters.millionFormatter(ValueFormatters.accessor(val, data, 'shareDemandSummary.demandAtBottomSharePrice.demandAmount'))
      ),
      formatExtraData: {
        emptyValue: '-',
      }
    })
  } else {
    columns.push({
      dataField: 'totalAmount',
      headerFormatter: () => (
        <React.Fragment>
            <TranslationText
                dictionaryKey={"orders.total"}
                defaultText={"Total"}
            />
            <br />(m)
        </React.Fragment>
      ),
      headerStyle: {
        ...otherHeaderAlignedRightStyle,
        width: '85px',
      },
      style: {
        ...otherRowAlignedRight,
      },
      formatter: millionFormatter,
      formatExtraData: {
        emptyValue: '-',
      }
    })
  }

  let timeAbbr = moment.tz.zone(moment.tz.guess()).abbr(Date.now());
  timeAbbr = ((timeAbbr[0] === '+' || timeAbbr === '-') ? 'GMT' : '') + timeAbbr;

  columns.push({
    dataField: 'externalSourceLastModified',
    headerFormatter: () => (
        <React.Fragment>
          <TranslationText
              dictionaryKey={"orders.modified"}
              defaultText={"Modified"}
          />
          <br />({timeAbbr})
        </React.Fragment>
      ),
    headerStyle: {
      ...otherHeaderAlignedRightStyle,
      width: '85px',
    },
    style: {
      ...otherRowAlignedRight,
    },
    formatter: (val, data, index) => (
       ValueFormatters.dateFormatter('dayMonth', val)
    ),
  })

  return columns;
};

class TopTenTable extends PureComponent {
  render() {
    if (this.props.orderData.length === 0) {
      return null;
    }

    const topTenOrders =
      this.props.orderData
        .map((order) => order)
        .sort((a, b) => (
          Sorters.doubleSort('investorLongName', a, b, 'desc', this.props.type === 'ecm' ? 'shareDemandSummary.demandAtBottomSharePrice.demandAmount' : 'totalAmount')
        ))
        .slice(0, 10);

    const ordersSortedByDate =
      this.props.orderData
        .sort((a, b) => (
            Sorters.doubleSort('investorLongName', a, b, 'desc', 'externalSourceLastModified')
        ));

    let topTenChangedOrders = [];

    if (this.props.type === 'ecm') {
      const lastModifiedOrder = moment(ordersSortedByDate[0].externalSourceLastModified);
      const comparisonTimeEnd = moment(ordersSortedByDate[0].externalSourceLastModified);
      let comparisonTimeStart = moment(ordersSortedByDate[0].externalSourceLastModified);

      if (lastModifiedOrder.day() === 1) {
        comparisonTimeStart = comparisonTimeStart.subtract(3, 'days');
      } else {
        comparisonTimeStart = comparisonTimeStart.subtract(1, 'days');
      }

      const last24HoursTopTenChanged = ordersSortedByDate.filter((order) => (
        moment(order.externalSourceLastModified).isBetween(comparisonTimeStart, comparisonTimeEnd)
      ));

      if (last24HoursTopTenChanged.length > 10) {
        topTenChangedOrders = last24HoursTopTenChanged.sort((a, b) => (
          Sorters.doubleSort('investorLongName', a, b, 'desc', 'shareDemandSummary.demandAtBottomSharePrice.demandAmount')
        )).slice(0, 10);
      } else {
        topTenChangedOrders = ordersSortedByDate.slice(0, 10).sort((a, b) => (
          Sorters.doubleSort('investorLongName', a, b, 'desc', 'shareDemandSummary.demandAtBottomSharePrice.demandAmount')
        ));
      }
    } else {
      topTenChangedOrders = ordersSortedByDate.slice(0, 10);
    }

    return (
      <div className="tabbed-container">
        <HorizontalTabs
           tabsId={tabIds.TOP_TEN_ORDERS_TABS}
        >
          <HorizontalTab
            tabId={topTenOrdersTabs.TOP_TEN_ORDERS}
            title={this.props.tab1Title}
            onTabClicked={() => {
              this.props.tabChangeEvent(events.ORDERS_SUMMARY_TOP10_TAB_ORDERS);
            }}
          >
            <TablesContainer>
              <Table
                classes='fixed'
                keyField="orderId"
                data={topTenOrders}
                columns={ Columns(this.props) }
                caption={this.props.tab1TableCaption}
              />
            </TablesContainer>
          </HorizontalTab>
          <HorizontalTab
            tabId={topTenOrdersTabs.MOST_RECENT_TOP_TEN}
            title={this.props.tab2Title}
            onTabClicked={() => {
              this.props.tabChangeEvent(events.ORDERS_SUMMARY_TOP10_TAB_NEW_OR_CHANGED);
            }}
          >
            <TablesContainer>
              <Table
                classes='fixed'
                keyField="orderId"
                data={topTenChangedOrders}
                columns={ Columns(this.props) }
                caption={this.props.tab2TableCaption}
              />
            </TablesContainer>
          </HorizontalTab>
        </HorizontalTabs>
      </div>
    );
  }
}

export default compose(
  withLanguage,
  withAnalytics((ev) => ({
      tabChangeEvent: eventDetails => ev(eventDetails),
  })),
  withTranslate(translate => ({
      tab1Title: translate('orders.top_10_orders', 'Top 10 Orders'),
      tab2Title: translate('orders.most_recent_top_10_new_changed', 'Most Recent Top 10 New/Changed'),
      tab1TableCaption: translate('orders.top_10_table_caption', 'Table showing top 10 orders'),
      tab2TableCaption: translate('orders.most_recent_10_table_caption', 'Table showing most recent top 10 new/changed orders')
  })),
)(TopTenTable);
