import React, { Component } from "react";
import styled from "styled-components";
import PopupSystem from "shared/components/PopupSystem";
import Popup, { Divider } from "shared/components/Popup";
import { dateFormatter } from "../../SharedTableComponents/formatters";
import {
  leftAlignedCellStyle,
  rightAlignedCellStyle,
  leftAlignedHeaderStyle,
} from "../../shared/table/styles";
import ScrollableTable from "shared/components/ScrollableTable";
import { ValueFormatters } from "mk-pattern-library-react";
const ScrollbleContainer = styled.div`
  div:nth-of-type(1)  {
    max-height: 380px;
  }
`;

const textStyle = {
  textTransform: 'capitalize'
}

const ModalAlignCenter = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  ${Divider} {
    display: block;
    margin: 1rem 0;
  }
`;

class BookEvolutionsOrderSummaryDetailView extends Component {
  Columns = (props) => {
    const columns = [
      {
        attrs: {
          role: "rowheader",
        },
        dataField: "orderId",
        text: props.orderIdText,
        style: leftAlignedCellStyle,
        headerStyle: leftAlignedHeaderStyle,
        columnFlex: 0.6,
      },
      {
        dataField: "investorLongName",
        text: props.investorNameText,
        headerStyle: leftAlignedHeaderStyle,
        style: leftAlignedCellStyle,
        columnFlex: 1.5,
      },
      {
        dataField: "orderAmount",
        text: props.orderAmountText,
        headerStyle: leftAlignedHeaderStyle,
        style: rightAlignedCellStyle,
        columnFlex: 0.75,
        formatter: ValueFormatters.commaDecimalFormatter,
      },
      {
        dataField: "limitSecurity",
        text: props.limitSecurityText,
        headerStyle: leftAlignedHeaderStyle,
        style: leftAlignedCellStyle,
        columnFlex: 1,
      },
      {
        dataField: "externalSourceLastModified",
        text: props.dateText,
        headerClasses: "",
        headerStyle: leftAlignedHeaderStyle,
        style: leftAlignedCellStyle,
        columnFlex: 0.8,
        formatter: (value) => dateFormatter("localTime", value),
      },
      {
        dataField: "status",
        text: props.statusText,
        headerClasses: "",
        headerStyle: leftAlignedHeaderStyle,
        style: leftAlignedCellStyle,
        columnFlex: 0.8,
        formatter: (value, data, index) => (
          <p style={textStyle}>{data['status'].toLowerCase()}</p>
        )
      },
    ];

    return columns;
  };

  closeModalClick = () => {
    this.props.setIsModalOpen(false);
  };

  render() {
    const { isModalOpen, modalSummary } = this.props;
    return (
      <PopupSystem
        centered
        backgroundLayer
        modalID={this.props.modalID}
        className={this.props.className}
        isModalOpen={isModalOpen}
        closeModal={this.closeModalClick}
        primaryButton
        primaryButtonContent="Close"
        primaryButtonAction={this.closeModalClick}
        primaryButtonKey="others.close"
        closeIcon
        content={
          <ModalAlignCenter>
            <Popup
              static
              width={this.props.width || 1250}
              primaryButton
              primaryButtonContent="Close"
              primaryButtonAction={this.closeModalClick}
              primaryButtonKey="others.close"
            >
              <div className="scrollable-container-wrapper">
                <ScrollbleContainer>
                  <ModalContent>
                    <ScrollableTable
                      classes="mydeal-table fixed-header top-header bottom-footer order-summary-details"
                      data={modalSummary}
                      bordered={false}
                      keyField="Order Summary Details"
                      columns={this.Columns(this.props)}
                    />
                  </ModalContent>
                </ScrollbleContainer>
              </div>
            </Popup>
          </ModalAlignCenter>
        }
      />
    );
  }
}

export default BookEvolutionsOrderSummaryDetailView;
