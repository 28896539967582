import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as modalIds from 'constants/modalIds';
import { events } from 'constants/analytics';
import withAnalytics from 'shared/hoc/withAnalytics';

import styled from 'styled-components';

import { getDeals, getCurrentDealId, getCurrentDeal } from 'selectors';

import Dropdown from 'shared/accessibility/components/Dropdown';
import withTranslate from 'shared/hoc/withTranslate';

const DropdownItemContainer = styled.div`
  padding: 5px 10px;
  text-align: left;
  line-height: 20px;
`;

const DropdownTemplateContainer = styled.div`
  padding: 0 10px;
  text-align: left;
  line-height: 1.3;
`;

const ProjectName = styled.div`
  padding-bottom: 3px;
`;

const ClientName = styled.div`
  font-size: 11px;
`;

const DropdownListItem = props =>
  <DropdownItemContainer>
    <ProjectName>{props.content}</ProjectName>
    <ClientName>{props.content2}</ClientName>
  </DropdownItemContainer>

const DropdownTemplate = props =>
  <DropdownTemplateContainer>
    <ProjectName>{props.content}</ProjectName>
    <ClientName>{props.content2}</ClientName>
  </DropdownTemplateContainer>

class DealSwitcher extends React.PureComponent {
  dropdownOpened = () => {
    this.props.dealSwitchEvent();
  }

  render() {
    const selectedDeal = this.props.allDeals.length > 0 && this.props.allDeals.filter(deal => deal.selected)[0];

    return (
      <Dropdown
        dropdownId={modalIds.DEAL_SWITCHER_MODAL_ID}
        dropdownText={this.props.currentDeal.projectName}
        items={this.props.allDeals}
        label={this.props.dealSwitcherAriaLabel}
        reversed
        dropdownOpened={this.dropdownOpened}
        renderOption={item => <DropdownListItem {...item} />}
        clickableContent={<DropdownTemplate content={selectedDeal && selectedDeal.content} content2={selectedDeal && selectedDeal.content2} />}
        isMasthead
      />
    )
  }
}

export default compose(
  withTranslate(translate => ({
    dealSwitcherAriaLabel: translate('home_page.deal_switcher', 'Deal Switcher'),
  })),
  connect(
    (state, props) => ({
      allDeals: getDeals(state)
        .map(({ dealId, projectName, issuerName}) => ({
          dealId,
          // dropdown adjustment
          selected: dealId === getCurrentDealId(state),
          content: projectName,
          content2: issuerName,
          label: projectName,
          click: () => props.onSwitchDeal(dealId),
        })),
      currentDeal: getCurrentDeal(state),
    }),
  ),
  withAnalytics(ev => ({
      dealSwitchEvent: () => ev(events.DEAL_SWITCH),
  })),
)(DealSwitcher);
