// LocalStorage
export const ROADSHOW_SELECTED_TEAMS = '__mydeal__roadshow_selected_teams';
export const SELECTED_LANGUAGE = '__mydeal__selected_language';
export const TEMP_SELECTED_LANGUAGE = '__mydeal__temp_language';
export const SELECTED_DEAL = '__mydeal__selected_deal';
export const SELECTED_ISV_DEAL = '__mydeal__isv__selected_deal';

// Session Storage
export const TOKEN = "_mydeal_";
export const EXPIRATION = "_mydeal_exp_";

export const IS_INTERNAL = window.__MyDealEnv === "INTERNAL"
