import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import {
    getMyDocsForActiveCategory,
    getMyDocsActiveCategoryId,
    getMyDocsReceivedDate
} from 'selectors';

import withTranslate from 'shared/hoc/withTranslate';
import TranslationText from 'translations/TranslationText';

import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';

import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import { DateAsOf } from 'shared/styles/components/date';

import CategoryList from './CategoryList/CategoryList';
import DocumentList from './DocumentList/DocumentList';

const categoryListColumnInlineStyles = { paddingRight: 0, borderRight: 'none' };
const documentListColumnInlineStyles = { paddingLeft: 0, paddingRight: 0, borderLeft: '2px solid #D8D8D8' };

class MyDocsContainer extends PureComponent {
    componentDidMount() {
        this.props.viewChange(featurePageIds.MY_DOCS_DOCUMENTS_PAGE);
    }

    render() {
        return (
            <FeatureContainer>
                <HeaderContainer>
                    <Col sm={6}>
                        <FeatureHeading className="text-xxlarge text-univ-bd">
                            <TranslationText
                                dictionaryKey={"my_docs.documents"}
                                defaultText={"Documents"}
                            />
                        </FeatureHeading>
                        <HeadingDescription invisible top={5}>
                            <TranslationText
                                dictionaryKey={""}
                                defaultText={"All latest available documents"}
                            />
                        </HeadingDescription>
                    </Col>
                    <Col sm={6}>
                        <DateAsOf className='text-timestamp text-small'>
                            <TranslationText
                                dictionaryKey={"common.data_as_of_<date_time>"}
                                replace={[
                                    ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)]
                                ]}
                                defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)}`}
                            />
                        </DateAsOf>
                    </Col>
                </HeaderContainer>
                <Row>
                    <Col
                        sm={5} md={4} lg={3}
                        style={categoryListColumnInlineStyles}
                        className="mydocs-body-scroll-container"
                    >
                        <CategoryList />
                    </Col>

                    <Col
                        id="document-list"
                        sm={7} md={8} lg={9}
                        style={documentListColumnInlineStyles}
                        aria-label={this.props.documentsFromSelectedCategoryAriaLabel}
                        tabIndex={-1}
                    >
                      <DocumentList data={this.props.myDocs} />
                    </Col>
                </Row>
            </FeatureContainer>
        );
    }
}

export default compose(
    connect(
        state => ({
            myDocs: getMyDocsForActiveCategory(state),
            dateReceived: getMyDocsReceivedDate(state),
            activeCategoryId: getMyDocsActiveCategoryId(state),
        }),
    ),
    withAnalytics((__, viewChange) => ({
        viewChange: viewId => viewChange(viewId),
    })),
    withTranslate((translate, props) => ({
        documentsFromSelectedCategoryAriaLabel: translate(
            '', // missing key in excel
            'Documents from selected category',
        )
    }))
)(MyDocsContainer);
