import React from 'react';
import moment from 'moment-timezone';
import numeral from 'numeral';

import { SvgIcon } from 'shared/components/Icon';

import Icon from '../components/Icon/Icon';
import ColorChip from '../components/ColorChip/ColorChip';
import { primaryColorOther, colors } from './visualisationColors';

export const nullResponseCharacter = '';
numeral.nullFormat(nullResponseCharacter);

const darkGray = {
  color: '#333'
}
const lightGray = {
  color: '#D8D8D8'
}
const medGray = {
  color: '#999999'
}
const darkBlue = {
  color: '#4D6474'
}

// Decides which icon a boolean needs for the table
const boolIconPicker = (obj, prop, style) => {
  // currently showing icon only when false

  switch (prop) {
    case 'hedge': return obj[prop] ? (
      <SvgIcon
        color={darkBlue.color}
        icon="agree"
        size="xxsmall"
        style={{ ...style, verticalAlign: 'middle' }}
        aria-checked={true}
      />
    ) : (
      <SvgIcon
        color={lightGray.color}
        icon="agree"
        size="xxsmall"
        style={{ ...style, verticalAlign: 'middle' }}
        aria-checked={false}
      />
    );
    case 'keyInvestor': return obj[prop] ? <Icon iconName="Star" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Star" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'topTierInvestor': return obj[prop] ? <Icon iconName="Star" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Star" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'earlyLookParticipantInvestor': return obj[prop] ? <Icon iconName="Binoculars" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Binoculars" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'preDealEducationInvestor': return obj[prop] ? <Icon iconName="Notebook" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Notebook" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'physicalRoadshow': return obj[prop] ? <Icon iconName="Plane" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Plane" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'netRoadshow': return obj[prop] ? <Icon iconName="Desktop" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Desktop" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'historicalInvestor': return obj[prop] ? <Icon iconName="Historical" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="Historical" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'hasComments':
    case 'feedback': return obj[prop] ? <Icon iconName="ChatBubble" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="ChatBubble" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'hasFlights': return obj[prop] ? <Icon iconName="Plane" style={{ ...medGray, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : null;
    case 'hasTrain':
    case 'train': return obj[prop] ? <Icon iconName="Train" style={{ ...medGray, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : null;
    case 'chat': return obj[prop] ? <Icon iconName="ChatBubble" style={{ ...darkBlue, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : <Icon iconName="ChatBubble" style={{ ...lightGray, ...style, verticalAlign: 'middle' }} aria-checked={false} />;
    case 'hasCars': return obj[prop] ? <Icon iconName="Car" style={{ ...medGray, ...style, verticalAlign: 'middle' }} aria-checked={true} /> : null;
    case 'selected': return obj[prop] ? <Icon style={{ ...darkGray, ...style, verticalAlign: 'middle' }} iconName="Checkmark" aria-checked={true} /> : <span />;
    default: return null;
  }
};


export const booleanFormatter = (value: any, obj: Object, prop: string, style: Object) => boolIconPicker(obj, prop, style);

export const tableDateFormatter = (value: any, obj: Object, prop: string) => dateFormatter(prop, value);

export const dateFormatter = (format: string, value: string): string => {
  const time = moment(value);
  let now;
  // parseZone allows you to keep the zone the timestamp has on it
  // moment will automatically parse into your local

  if (time.isValid()) {
    let zone,
      zoneTime,
      timeAbbreviation;
    switch (format) {
      case 'zoneTime':
        return time.parseZone().format('HH:mm');
      case 'localTime':
        return time.parseZone().format('DD MMM YYYY HH:mm:ss');
      case 'localDate':
        return time.parseZone().format('DD MMMM YYYY');
      case 'roadshow-nav':
        return time.parseZone().format('ddd DD');
      case 'roadshow':
        now = moment();
        if (now.isSame(time, 'day')) {
          return `Today ${time.parseZone().format('DD MMM')}`;
        }
        return time.parseZone().format('ddd DD MMM');
      case 'dayMonth':
        now = moment();
        if (now.isSame(time, 'day')) {
          return time.format('HH:mm');
        }
        return time.format('DD MMM');
      case 'dateMonth':
        return time.format('DD MMM');
      case 'dayMonthYearZone':
        zone = moment.tz.zone(moment.tz.guess(Date.now()));
        zoneTime = moment.tz(time, zone.name);
        timeAbbreviation = zone.abbr(Date.now());
        timeAbbreviation = ((timeAbbreviation[0] === '+' || timeAbbreviation === '-') ? 'GMT' : '') + timeAbbreviation;
        return `${zoneTime.format('DD MMMM YYYY HH:mm')} (${timeAbbreviation})`;
      case 'dayMonthYearZoneShort':
        zone = moment.tz.zone(moment.tz.guess(Date.now()));
        zoneTime = moment.tz(time, zone.name);
        timeAbbreviation = zone.abbr(Date.now());
        timeAbbreviation = ((timeAbbreviation[0] === '+' || timeAbbreviation === '-') ? 'GMT' : '') + timeAbbreviation;
        return `${zoneTime.format('DD MMM YYYY HH:mm')} (${timeAbbreviation})`;
      case 'dayMonthYearGMTtoISTZone':
        let initialDate;
        initialDate = new Date(new Date(time).getTime())
        initialDate.setHours(initialDate.getHours() + 5);
        initialDate.setMinutes(initialDate.getMinutes() + 30);
          return `${moment(initialDate).format('DD MMM YYYY HH:mm')} (IST)`;
      case "dayMonthYearISTZone":
        let dateToFormat;
        dateToFormat = new Date(new Date(time).getTime());
        return `${moment(dateToFormat).format("DD MMM YYYY HH:mm")} (IST)`;
      case 'dayMonthYear':
        return time.format('DD MMM YYYY');
      default:
        now = moment();
        if (now.isSame(time, 'day')) {
          return time.format('HH:mm');
        }
        return time.format('DD MMM YYYY');
    }
  } else {
    return nullResponseCharacter;
  }
};

export const titleHover = (val: any): string => <span title={val}>{val}</span>;

export const colorChipFormatter = (val: any, obj: Object, prop: string, index: number) => {
  const colorHex = (val.toLowerCase() === 'other') ? primaryColorOther : prop[index % prop.length];
  return <ColorChip fullChip description={titleHover(val)} colorHex={colorHex} />;
};

export const accessor = (val: any, obj: Object, prop: string) => {
  prop = prop.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  prop = prop.replace(/^\./, ''); // strip a leading dot
  const propsToAccess = prop.split('.');
  for (let i = 0; i < propsToAccess.length; i++) {
    const currentProp = propsToAccess[i];
    if (obj) {
      if (currentProp in obj) {
        if (obj instanceof Object) {
          obj = obj[currentProp];
        }
      } else {
        return nullResponseCharacter;
      }
    }
    else {
      return nullResponseCharacter;
    }
  }
  return ((typeof obj === 'undefined') || obj === null) ? nullResponseCharacter : obj;
};

export const summer = (arrayProp: string, val: any, obj: Object, prop: string) => {
  // drill down to array if nested
  const summee = accessor(val, obj, prop);

  if (obj && summee) {
    return summee.reduce((runningTotal, currentEl) => {
      if (arrayProp) {
        // If number is inside array of objects
        return runningTotal + +currentEl[arrayProp];
      } else {
        // array of numbers
        return runningTotal + +currentEl;
      }
    }, 0);
  } else {
    return null;
  }
};

export const badNumFormatter = (val: any) => (typeof val !== 'number') ? '' : val;

export const percentFormatter = (val: any, obj: Object, short: boolean) => ((typeof val === 'number') ? `${val.toFixed(short ? 1 : 2)}%` : `${nullResponseCharacter}`);

export const percentFormatterWithDecimals = (val: any, numDecimals: number) => ((typeof val === 'number') ? `${val.toFixed(numDecimals ? numDecimals : 0)}%` : `${nullResponseCharacter}`);

export const nullValFormatter = (val: any) => (((typeof val === 'undefined') || val === null) ? nullResponseCharacter : val);

export const commaFormatter = (val: any) => {
  if ((typeof val === 'undefined') || val === null) {
    return nullResponseCharacter;
  }

  return numeral(val).format('0,0.00');
};
export const commaWithoutDecimalFormatter = (val: any) => {
  if ((typeof val === 'undefined') || val === null) {
    return nullResponseCharacter;
  }

  return numeral(val).format('0,0');
};
export const commaDecimalFormatter = (val: any) => (((typeof val === 'undefined') || val === null || (typeof val !== 'number')) ? nullResponseCharacter : commaFormatter(val.toFixed(2)));

export const millionFormatter = (val: any) => {
  if (typeof val === 'number') {
    return commaFormatter(val / 1000000);
  }
};

// Used on Order Book Summary Chart Dates to convert long string date to abbreviated format
export const abbreviateFullDate = (fullDate: string) => {
  if (fullDate === 'Other') {
    return 'Other';
  }
  return moment(fullDate).format('DD MMM');
};

export const totalOrderFormatter = (amount: any, obj: Object) => {
  if ((typeof amount === 'undefined') || amount === null) {
    return '--';
  }

  let totalOrder = "<span class='order-amount'>" + amount + "</span> <span class='order-currency'>" + obj.currency + "</span>";

  return totalOrder;
};

export const choosePhoneFormatter = (person: Person) => {

  if (person.directTelephone) {
    return `T: ${person.directTelephone}`
  }
  else if (person.mobileTelephone) {
    return `M: ${person.mobileTelephone}`
  }
  else {
    return (<span>&nbsp;</span>)
  }

}
export const timeAbbr = (): string => {
  let abbr = moment.tz.zone(moment.tz.guess()).abbr(Date.now());
  return ((timeAbbr[0] === '+' || timeAbbr === '-') ? 'GMT' : '') + abbr;
}

export const renderDateTime = (date: any, timeZone: string) =>
    <div className="text-univ-lt" style={{ fontSize: "12px", color: "#999", lineHeight: "16px" }}>
        <span>
          {`${dateFormatter('', date)} ${moment().isSame(date, 'day') ? timeZone : ''}`}
        </span>
    </div>

export const teamColor = i => colors(i);
export const findTeamColor = (teamNames, teamName) => colors(teamNames.indexOf(teamName));
