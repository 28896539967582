import { createReducer } from "reduxsauce";
import IsvTypes from "Isv/actions/IsvTypes";

const INITIAL_STATE = {
    showSnackbar: false,
    snackBarMessage: '',
};

const closeSnackBar = (state) => {
    return {
      ...state,
      showSnackbar: false,
    }
  }

  const setSnack = (state, action) => {
    return {
      ...state,
      showSnackbar: true,
      snackBarMessage: action.message,
    }
  }

const ACTION_HANDLERS = {
  [IsvTypes.CLOSE_SNACK_BAR]: closeSnackBar,
  [IsvTypes.SET_SNACK]: setSnack,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
