export const isIE = content => `
    @media all and (-ms-high-contrast: none), (-ms-hight-contrast: active) {
        ${/* IE 10/11 */''}
        ${content}
    }

    @supports (-ms-accelerator: true) {
         ${/* Edge 12- 15  */''}
        ${content}
    }
    @supports (-ms-ime-align: auto) {
        ${/* Edge 16+  */''}
        ${content}
    }
`;

export const isChromeOrSafari = content => `
    @supports (-webkit-appearance-) {
        ${content}
    }
`;