import Types, { MyDocsTypes } from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';
import * as tiles from 'constants/homeTiles';

const receiveMyDocsSummary = response => ({
    type: Types.API_RECEIVE_DOCUMENTS_SUMMARY,
    myDocsSummaryData: response,
});
const requestMyDocsSummary = (dealId, loader) => ({
    type: Types.API_REQUEST_DOCUMENTS_SUMMARY,
    endPoint: `/v1/${dealId}/document/categories`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveMyDocsSummary,
    loader,
    //
    feature: dealFeatures.MY_DOCS,
});

const receiveMyDocs = response => ({
    type: Types.API_RECEIVE_DOCUMENTS,
    myDocsData: response,
});
const requestMyDocs = (dealId, loader) => ({
    type: Types.API_REQUEST_DOCUMENTS,
    endPoint: `/v1/documents/${dealId}`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveMyDocs,
    loader,
    tile: tiles.HOME_DOCUMENTS_TILE,
    //
    feature: dealFeatures.MY_DOCS,
});

const requestDoc = (documentId, documentName) => ({
    type: Types.API_REQUEST_DOC,
    endPoint: navigator.userAgent.indexOf('GoodAccess') >= 0
        ? `/v1/documents/file/${documentId}?MyDealAuthToken=${window.sessionStorage.getItem('_mydeal_')}`
        : `/v1/documents/file/${documentId}`,
    documentName,
    params: {},
    ajaxType: 'GETFILE',
    loader: false,
});

const changeActiveCategory = categoryId => ({
    type: MyDocsTypes.CHANGE_ACTIVE_CATEGORY,
    categoryId,
});

export {
    receiveMyDocsSummary,
    requestMyDocsSummary,
    receiveMyDocs,
    requestMyDocs,
    requestDoc,
    changeActiveCategory,
};
