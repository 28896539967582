import React, { useState, useEffect } from "react";
import Table from "../TableContainer/Table";
import TableHead from "../TableContainer/TableHead";
import TableBody from "../TableContainer/TableBody";
import TableRow from "../TableContainer/TableRow";
import TableHeaderCell from "../TableContainer/TableHeaderCell";
import TableColumnCell from "../TableContainer/TableColumnCell";
import { Sorters } from "mk-pattern-library-react";
import { DEFAULT_ALL_DAY, DEFAULT_ALL } from "constants/orders";
import { TileContentInner, EmptyContent } from "Landing/dcm/Tiles/styles.js";
import { Icon } from "mk-pattern-library-react";
import BookEvolutionsOrderSummaryDetailView from "./BookEvolutionOrderSummaryDetailView";
import "mk-pattern-library-react/styles/table-amended-order.scss";
import { dateFormatter } from "../../SharedTableComponents/formatters";
import styled from 'styled-components';

const timeIntervalStyle = {
  textAlign: "center",
};

const timeIntervalStyleHighlighted = {
  textAlign: "center",
  backgroundColor: "yellow",
};

const stageTextStyle = {
  fontSize: "10px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
};

const iconStyle = {
  fontSize: 6,
  color: "#ff0000",
  marginRight: "3px",
};

const NoWrapRow = styled.div`
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding:3px;
`

export const pickCaret = (direction, activeSort) => {
  const caretColor = activeSort ? "#4D6474" : "#c0c0c0";
  if (direction === "asc") {
    return (
      <Icon iconName="CaretUp" style={{ fontSize: 8, color: caretColor }} />
    );
  } else if (direction === "desc") {
    return (
      <Icon iconName="CaretDown" style={{ fontSize: 8, color: caretColor }} />
    );
  } else {
    return "";
  }
};
const AmendedOrderInvestorTable = ({
  amendedOrderByInvestorSummaryData,
  investorNameText,
  firstOrderText,
  lastOrderText,
  allocationAmountText,
  lastMinusPeakText,
  peakOrderText,
  timeIntervalText,
  statusText,
  dateText,
  limitSecurityText,
  orderAmountText,
  orderIdText,
  selectedTimeRange,
  selectedDate,
  intervals,
  showFilterForAmendedOrderbyinvestor,
  orderDetails,
  isShowStagesChecked,
  stages,
  getDateSummary
}) => {
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [intervalStagesObject, setIntervalStagesObject] = useState({});

  const TableHeaderslist = [
    {
      labelText: investorNameText,
      columnProperty: "investorLongName",
      dataType: "string",
    },
    {
      labelText: `${orderIdText}`,
      columnProperty: "orderId",
      dataType: "int",
    },
    {
      labelText: `${firstOrderText}`,
      columnProperty: "firstOrderAmount",
      dataType: "int",
    },
    {
      labelText: `${lastOrderText}`,
      columnProperty: "lastOrderAmount",
      dataType: "int",
    },
    {
      labelText: `${peakOrderText}`,
      columnProperty: "peakOrderAmount",
      dataType: "int",
    },
    {
      labelText: `${lastMinusPeakText}`,
      columnProperty: "lastMinusPeak",
      dataType: "int",
    },
    {
      labelText: `${allocationAmountText}`,
      columnProperty: "allocationAmount",
      dataType: "int",
    },
  ];

  const [sortObject, setSortObject] = useState({
    sortOrder: "asc",
    sortColumn: TableHeaderslist[0].columnProperty,
    dataType: TableHeaderslist[0].dataType,
  });

  const formatTooltipHoverText = (array) => {
    let text = "";
    if (array && array.length) {
      array.forEach((element) => {
        text = text + "\n" + element;
      });
    }
    return text;
  };

  const convertIntToDecimal = (amount) => {
    if (amount || amount === 0) {
      return Number.parseFloat(amount).toFixed(2);
    } else {
      return amount;
    }
  };

  const checkIfOrderExists = (orderId, ordersArray, element) => {
    let isExists = false;
    let modifiedArray = ordersArray.map((order) => {
      if (order.orderId === orderId) {
        isExists = true;
        order.amounts = {
          ...order.amounts,
          [element.timeInterval]: convertIntToDecimal(element.orderAmount),
        };
      }
      return order;
    });
    return {
      modifiedArray: modifiedArray,
      isExists: isExists,
    };
  };

  const formatDataForAmendOrderinvestorTable = (arrayToProcess) => {
    const sortedArray = arrayToProcess.sort((a, b) =>
      Sorters.doubleSort("timeInterval", a, b, "asc", "investorLongName")
    );

    let arrayToReturn = [];
    sortedArray.forEach((element) => {
      orderDetails.forEach((orderDetail) => {
        if (orderDetail.orderId === element.orderId) {
          let orderResponse = checkIfOrderExists(
            orderDetail.orderId,
            arrayToReturn,
            element
          );
          if (!orderResponse.isExists) {
            arrayToReturn.push({
              ...element,
              ...orderDetail,
              amounts: {
                [element.timeInterval]: convertIntToDecimal(
                  element.orderAmount
                ),
              },
              firstOrderAmount: convertIntToDecimal(
                orderDetail.firstOrderAmount
              ),
              lastOrderAmount: convertIntToDecimal(orderDetail.lastOrderAmount),
              allocationAmount: convertIntToDecimal(
                orderDetail.allocationAmount / 1000000
              ),
              peakOrderAmount: convertIntToDecimal(orderDetail.peakOrderAmount),
              lastMinusPeak: convertIntToDecimal(
                orderDetail.lastOrderAmount - orderDetail.peakOrderAmount
              ),
            });
          } else {
            arrayToReturn = orderResponse.modifiedArray;
          }
        }
      });
    });

    sortArrayBasedOnProperty(
      arrayToReturn,
      sortObject.sortColumn,
      sortObject.dataType,
      sortObject.sortOrder
    );
  };

  const comparisonFunction = (a, b, fieldToSort, dataType, orderofSort) => {
    let comparison = 0;
    let value1 = a[fieldToSort];
    let value2 = b[fieldToSort];
    if (dataType === "string") {
      value1 = value1.trim().toUpperCase();
      value2 = value2.trim().toUpperCase();
      if (value1 > value2) {
        comparison = orderofSort === "asc" ? 1 : -1;
      } else if (value1 < value2) {
        comparison = orderofSort === "asc" ? -1 : 1;
      }
    } else {
      comparison = orderofSort === "asc" ? value1 - value2 : value2 - value1;
    }
    return comparison;
  };

  const sortArrayBasedOnProperty = (
    arrayToSort,
    fieldToSort,
    dataType,
    sortOrder
  ) => {
    setTableArray(
      arrayToSort.sort((a, b) =>
        comparisonFunction(a, b, fieldToSort, dataType, sortOrder)
      )
    );
  };

  const filterTimeIntervals = (
    timeIntervals,
    selectedTimeRangeInFilter,
    stages
  ) => {
    let timeRangeSelected = !selectedTimeRangeInFilter.value.includes(
      DEFAULT_ALL_DAY.value
    );

    let intervalsArray = [];
    if (timeRangeSelected) {
      let from = selectedTimeRangeInFilter.value.split(" ")[0];
      let to = selectedTimeRangeInFilter.value.split(" ")[2];

      intervalsArray = timeIntervals.filter((timeInterval) =>
        istimeInRange(from, to, timeInterval)
      );
    } else {
      intervalsArray = timeIntervals;
    }
    const intervalStagesObject = {};
    intervalsArray = intervalsArray.map((interval) => {
      let timeIntervalObject = {};

      stages.forEach((stage) => {
        if (interval === stage.timeInterval) {
          let formattedDate = dateFormatter("localDate", stage.stageDate);
          if (
            intervalStagesObject[interval] &&
            intervalStagesObject[interval][formattedDate]
          ) {
            intervalStagesObject[interval] = {
              ...intervalStagesObject[interval],
              [formattedDate]: [
                ...intervalStagesObject[interval][formattedDate],
                `${stage.stageShortname} - ${stage.stageName} - [${formattedDate}] [${stage.stageTime}]`,
              ],
            };
          } else {
            intervalStagesObject[interval] = {
              ...intervalStagesObject[interval],
              [formattedDate]: [
                `${stage.stageShortname} - ${stage.stageName} - [${formattedDate}] [${stage.stageTime}]`,
              ],
            };
          }
          timeIntervalObject = {
            text: interval,
            ...stage,
          };
        } else {
          timeIntervalObject = { ...timeIntervalObject, text: interval };
        }
      });
      return timeIntervalObject;
    });
    setIntervalStagesObject(intervalStagesObject);
    setTimeIntervals(intervalsArray);
  };

  useEffect(() => {
    showFilterForAmendedOrderbyinvestor(true);
    formatDataForAmendOrderinvestorTable(amendedOrderByInvestorSummaryData);
  }, [amendedOrderByInvestorSummaryData]);

  useEffect(() => {
    filterTimeIntervals(intervals, selectedTimeRange, stages);
  }, [selectedTimeRange, stages]);

  const formatDataForModal = (arrayToProcess, orderId, timeInterval) => {
    const sortedArray = arrayToProcess.sort((a, b) =>
      Sorters.doubleSort("timeInterval", a, b, "asc", "investorLongName")
    );
    let arrayToReturn = sortedArray.filter((element) => {
      return (
        orderId === element.orderId && timeInterval === element.timeInterval
      );
    });
    return arrayToReturn;
  };

  const istimeInRange = (from, to, time) => {
    let isInRange = false;
    let fromHour = parseInt(from.split(":")[0]);
    let fromMin = parseInt(from.split(":")[1]);
    let toHour = parseInt(to.split(":")[0]);
    let toMin = parseInt(to.split(":")[1]);
    let elementHour = parseInt(time.split(":")[0]);
    let elementMin = parseInt(time.split(":")[1]);

    if (elementHour > fromHour) {
      if (elementHour < toHour) {
        isInRange = true;
      } else {
        if (elementHour === toHour) {
          if (elementMin <= toMin) {
            isInRange = true;
          } else {
            isInRange = false;
          }
        }
      }
    } else {
      if (elementHour === fromHour) {
        if (elementMin >= fromMin || elementMin === toMin) {
          isInRange = true;
        } else {
          isInRange = false;
        }
      }
    }
    return isInRange;
  };

  return (
    <>
      <BookEvolutionsOrderSummaryDetailView
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalID="book-evolution-t-r-m-1"
        modalSummary={modalData}
        className={"close-modal-icon"}
        orderAmountText={orderAmountText}
        orderIdText={orderIdText}
        dateText={dateText}
        limitSecurityText={limitSecurityText}
        investorNameText={investorNameText}
        statusText={statusText}
      />
      {!amendedOrderByInvestorSummaryData ||
      !amendedOrderByInvestorSummaryData.length ? (
        <TileContentInner>
          <EmptyContent>No Data Available</EmptyContent>
        </TileContentInner>
      ) : (
        <div className="react-bootstrap-table table-fix-head">
          <Table className={`table table-bordered`} id={"amended-order-table"}>
            <TableHead list={amendedOrderByInvestorSummaryData}>
              <TableRow>
                {TableHeaderslist.map((tableHeaderObject, index) => {
                  return (
                    <TableHeaderCell
                      rowSpan="2"
                      style={tableHeaderObject.style}
                      onClick={() => {
                        let sortOrder =
                          sortObject.sortColumn ===
                            tableHeaderObject.columnProperty &&
                          sortObject.sortOrder === "asc"
                            ? "desc"
                            : "asc";
                        setSortObject({
                          sortOrder,
                          sortColumn: tableHeaderObject.columnProperty,
                          dataType: tableHeaderObject.dataType,
                        });
                        sortArrayBasedOnProperty(
                          tableArray,
                          tableHeaderObject.columnProperty,
                          tableHeaderObject.dataType,
                          sortOrder
                        );
                      }}
                    >
                      {tableHeaderObject.labelText}
                      <br />
                      {index > 1 ? (
                        <>
                          (m)
                          <br />
                        </>
                      ) : null}

                      <div style={{ position: "relative", left: "0" }}>
                        {pickCaret(
                          sortObject.sortColumn ===
                            tableHeaderObject.columnProperty
                            ? sortObject.sortOrder
                            : "asc",
                          sortObject.sortColumn ===
                            tableHeaderObject.columnProperty
                        )}
                      </div>
                    </TableHeaderCell>
                  );
                })}
                <TableHeaderCell
                  style={{textAlign:"center", height:"45px"}}
                  colSpan={timeIntervals.length}
                >
                  Order by {timeIntervalText} (m)
                </TableHeaderCell>
              </TableRow>

              <TableRow>
                {timeIntervals.map((interval, index) => {
                  let selectedDateToFilter =
                    selectedDate.value === DEFAULT_ALL.value
                      ? getDateSummary[0].value
                      : selectedDate.value;

                  let stageExistingForDateAndTime = intervalStagesObject[
                    interval.text
                  ]
                    ? intervalStagesObject[interval.text][selectedDateToFilter]
                    : "";

                  return (
                    <TableHeaderCell
                      key={index}
                      title={
                        isShowStagesChecked
                          ? formatTooltipHoverText(stageExistingForDateAndTime)
                          : ""
                      }
                    >
                      {interval.text && interval.text.slice(0, -3) === "23:59"
                        ? "00:00"
                        : interval.text
                        ? interval.text.slice(0, -3)
                        : ""}
                      <div>
                        {isShowStagesChecked &&
                        interval.stageName &&
                        stageExistingForDateAndTime ? (
                          <p style={stageTextStyle}>
                            <Icon iconName="CaretDown" style={iconStyle} />
                            {stageExistingForDateAndTime &&
                            stageExistingForDateAndTime.length > 1
                              ? `${stageExistingForDateAndTime.length} stages`
                              : null}
                            {stageExistingForDateAndTime &&
                            stageExistingForDateAndTime.length === 1
                              ? stageExistingForDateAndTime[0].split("-")[0]
                              : null}
                          </p>
                        ) : null}
                      </div>
                    </TableHeaderCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableArray.map((order, index) => {
                const {
                  firstOrderAmount,
                  lastOrderAmount,
                  lastMinusPeak,
                  peakOrderAmount,
                  investorLongName,
                  orderId,
                  allocationAmount,
                  amounts,
                } = order;

                return (
                  <TableRow key={index}>
                    <TableColumnCell title = {investorLongName || ""} style = {{width: '250px'}}><NoWrapRow>{investorLongName || ""}</NoWrapRow></TableColumnCell>
                    <TableColumnCell>{orderId || ""}</TableColumnCell>
                    <TableColumnCell>{firstOrderAmount}</TableColumnCell>
                    <TableColumnCell>{lastOrderAmount}</TableColumnCell>

                    <TableColumnCell>{peakOrderAmount}</TableColumnCell>
                    <TableColumnCell
                      style={
                        parseInt(lastMinusPeak) === 0
                          ? {}
                          : { color: lastMinusPeak > 0 ? "green" : "red" }
                      }
                    >
                      {lastMinusPeak}
                    </TableColumnCell>
                    <TableColumnCell>{allocationAmount}</TableColumnCell>
                    {timeIntervals.length &&
                      timeIntervals.map((timeInterval) => {
                        let selectedDateToFilter =
                          selectedDate.value === DEFAULT_ALL.value
                            ? getDateSummary[0].value
                            : selectedDate.value;
                        let stageExistingForDateAndTime = intervalStagesObject[
                          timeInterval.text
                        ]
                          ? intervalStagesObject[timeInterval.text][
                              selectedDateToFilter
                            ]
                          : "";
                        return (
                          <TableColumnCell
                            onClick={() => {
                              if (amounts[timeInterval.text]) {
                                setModalData(
                                  formatDataForModal(
                                    amendedOrderByInvestorSummaryData,
                                    orderId,
                                    timeInterval.text
                                  ) || [""]
                                );
                                setIsModalOpen(true);
                              }
                            }}
                            style={
                              timeInterval.stageName &&
                              stageExistingForDateAndTime &&
                              isShowStagesChecked
                                ? timeIntervalStyleHighlighted
                                : timeIntervalStyle
                            }
                          >
                            <p
                              style={
                                amounts[timeInterval.text]
                                  ? { cursor: "pointer" }
                                  : {}
                              }
                            >
                              {amounts[timeInterval.text] || ""}
                            </p>
                          </TableColumnCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};

export default AmendedOrderInvestorTable;
