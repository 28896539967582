import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Link, browserHistory } from 'react-router';

import { featurePageIds } from 'constants/featurePages';

import { anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';
import { dateFormatters } from 'shared/functions/formatters';

import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import Loading from 'shared/components/Loading';
import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';

import { typography } from 'shared/styles/variables/theme';

import { getHomeTileStatus, getLatestFeedback } from 'selectors';

import {
    TileContainerOuter,
    TileHeader,
    TileContent,
    TileContentInner,
    DateContent,
    ChevronIcon,
    EmptyContent
} from './styles.js';

const FeedbackInvestor = styled.div`
    font-family: ${typography.family.bold};
    font-size: ${typography.sizes['15px']};
`;

const FeedbackInvestorContact = styled.div`
    font-size: ${typography.sizes['11px']};
`;

const FeedbackText = styled.div`
    margin-top: 10px;
    font-size: ${typography.sizes['13px']};
`;

const url = {
    pathname: featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE,
};

class LatestFeedback extends PureComponent {
    navigateEvent = (e) => {
        e.preventDefault();
        if (anchorEventHandling(e)) {
            browserHistory.push(url);
            this.props.linkChange();
        }
    }

    render() {
        return (
            <TileContainerOuter>
                {
                    this.props.isFetching ? (
                        <Loading
                            text={this.props.loadingText}
                            size="small"
                        />
                    ) : (
                        <React.Fragment>
                            <TileHeader>
                                <Link
                                    id='link-d-lts-feedback'
                                    to={url}
                                    onKeyPress={this.navigateEvent}
                                    onClick={this.navigateEvent}
                                    aria-label={this.props.linkAriaLabel}
                                >
                                    {this.props.linkText}
                                    <ChevronIcon />
                                </Link>
                            </TileHeader>
                            <TileContent>
                                <TileContentInner>
                                    {
                                        this.props.feedback ? (
                                            <React.Fragment>
                                                <FeedbackInvestor>
                                                    {this.props.feedback.investor}
                                                </FeedbackInvestor>
                                                <FeedbackInvestorContact>
                                                    {
                                                        [
                                                            this.props.feedback.investorContact,
                                                            this.props.feedback.investorType
                                                        ]
                                                            .filter(e => e)
                                                            .join(', ')
                                                    }
                                                </FeedbackInvestorContact>
                                                <DateContent>
                                                    {
                                                        dateFormatters.dayMonthYearZoneShort(this.props.feedback.recordedDateTime)
                                                    }
                                                </DateContent>
                                                <FeedbackText>
                                                    <ResponsiveEllipsis
                                                        style={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                        text={this.props.feedback.text || ''}
                                                        maxLine={5}
                                                    />
                                                </FeedbackText>
                                            </React.Fragment>
                                        ) : (
                                            <EmptyContent>
                                                {this.props.notAvailable}
                                            </EmptyContent>
                                        )
                                    }
                                </TileContentInner>
                            </TileContent>
                        </React.Fragment>
                    )
                }
            </TileContainerOuter>
        );
    }
}

export default compose(
    connect(
        state => ({
            isFetching: !getHomeTileStatus(state, 'homeFetchedFeedback'),
            feedback: getLatestFeedback(state),
        })
    ),
    withTranslate(
        translate => ({
            // link
            linkText: translate('home_page.latest_feedback', 'Latest feedback'),
            linkAriaLabel: translate('home_page.navigate_to_latest_feedback', 'Navigate to latest feedback'),
            // content
            notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
            loadingText: translate('home_page.loading_latest_feedback', 'Loading latest feedback'),
        })
    ),
    withAnalytics2(
        (ev, events) => ({
            linkChange: () => ev(events.LATEST_FEEDBACK)
        })
    )
)(LatestFeedback);