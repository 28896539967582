import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { SvgIcon } from 'shared/components/Icon';
import { Icon } from 'mk-pattern-library-react';

import withLanguage from 'translations/withLanguage';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

import TranslationText from 'translations/TranslationText';

import * as modalIds from 'constants/modalIds';
import { isModalOpen } from 'selectors'
import { openModal, closeModal } from 'actions/UtilityActions';

const CompanyName = styled.span`
  word-wrap: break-word;
  font-weight: 700;
`;

class FeedbackDetailHeader extends React.PureComponent {
  modalOpenClick = () => {
    if (!this.props.isInvestorFeedbackDetailModalOpen) {
      this.props.openModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(this.props.feedback.feedbackId))
    }
  }

  modalCloseClick = () => {
    this.props.closeModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(this.props.feedback.feedbackId))
  }

  render() {
    const { feedback } = this.props;
    return (
          <Row className="feedback-detail-header">
            <Col sm={8}>
              <div style={{ marginBottom: '0.5rem' }} className="text-univ-lt">
                <h3 style={{ margin: 0, fontSize: '1.250rem' }}>
                  <CompanyName>
                    {feedback.companyName}
                  </CompanyName>
                  {
                    feedback.keyInvestor && (
                      <Icon className="feedback-detail-header-key-investor" iconName="Star" style={{ color: '#4F595F', marginLeft: 10, fontSize: '1.000rem' }} />
                    )
                  }
                  </h3>
              </div>
            </Col>
            <Col sm={4} style={{ padding: '0.1rem 1rem 0 0' }} className="text-right">
              <TranslationText
                  dictionaryKey={mapThemeTypeOrSentimentToKey(feedback.sentiment)}
                  defaultText={feedback.sentiment}
              />
              &nbsp;
              <TranslationText
                  dictionaryKey={"investor_feedback.sentiment"}
                  defaultText={"Sentiment"}
              />
              <SvgIcon
                  icon="pop-up-window"
                  aria-hidden
                  style={{ position: 'relative', left: 6, top: -1, fill: 'rgb(79, 89, 95)' }}
              />
            </Col>
          </Row>

    )
  }
}
export default withLanguage(
  connect(
    (state, ownProps) => ({
        isInvestorFeedbackDetailModalOpen: isModalOpen(state, modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(ownProps.feedback.feedbackId)),
    }),
    dispatch => ({
        // modals
      openModal: modalId => dispatch(openModal(modalId)),
      closeModal: modalId => dispatch(closeModal(modalId)),
    })
  )(FeedbackDetailHeader)
);
