import { HSBC_RAG_GREEN, HSBC_RAG_RED, HSBC_RAG_AMBER} from '../constants/colors';

const sentimentColorProvider = sentiment => {
  switch (sentiment) {
    case 'Positive':
      return HSBC_RAG_GREEN;
    case 'Neutral':
      return HSBC_RAG_AMBER;
    case 'Negative':
      return HSBC_RAG_RED;
    default:
      return '#ffffff';
  }
};

export default sentimentColorProvider;
