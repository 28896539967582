import React, { useState } from 'react'
import {
  leftAlignedHeaderStyle,
  rightAlignedHeaderStyle,
  leftAlignedCellStyle,
  rightAlignedCellStyle,
} from '../../shared/table/styles';
import Table from '../TableContainer/Table'
import TableHead from '../TableContainer/TableHead'
import TableBody from '../TableContainer/TableBody'
import TableRow from '../TableContainer/TableRow'
import TableHeaderCell from '../TableContainer/TableHeaderCell'
import TableColumnCell from '../TableContainer/TableColumnCell'
import { dateFormatter } from '../../SharedTableComponents/formatters';
import { ValueFormatters } from 'mk-pattern-library-react';
const columnHeight = {
  height: '40px',
  verticalAlign: 'middle'
}

const borderBottom = {
  borderBottom: '1px solid rgb(216, 216, 216)'
}

const getCellWidthCell = (cellWidth, list, index) => {
  if (cellWidth && (list.length - 1 === index)) {
    return {
      width: `calc(${cellWidth}px - 9.2px)`
    }
  }
}

const dateColumnWidth = (headerStyle) => {
  if (headerStyle) {
    return {
      minWidth: '140px',
      width: '140px',
    }
  }
}

const timeEnteredColumnWidth = (headerStyle) => {
  if (headerStyle) {
    return {
      minWidth: '101px',
      width: '101px',
    }
  }
}

const AmendedSummaryTable = ({
  orderSummaryList,
  dateText,
  timeEntered,
}) => {

  const [tableStyle, setTableStyle] = useState('auto');
  const [cellWidth, setCellWidth] = useState('auto');
  const [headerStyle, setHeaderStyle] = useState(false);

  const setTheadRef = (data) => {
    const childNodes = data.current.rows[1].childNodes
    setTimeout(
      () => {
        if (childNodes.length) {
          const scrollHeight = childNodes[0].offsetParent && childNodes[0].offsetParent.children[1].scrollHeight
          const tbodyHeight = childNodes[0].offsetParent && childNodes[0].offsetParent.children[1].clientHeight
          if (scrollHeight === tbodyHeight) {
            setCellWidth(false)
          } else {
            setCellWidth(data.current.rows[1].childNodes[0].clientWidth)
          }
        }
      }, 100)
    if (childNodes.length <= 11) {
      setTableStyle('100%')
      setHeaderStyle(false)
    } else {
      setTableStyle('auto')
      setHeaderStyle(true)
    }
  }

  return (
    <div className="react-bootstrap-table custom-amended-summary-table">
      <Table
        className='Table standard-summary-table'
        id={'amended-order-table'}
      >
        <TableHead
          setTheadRef={(data) => setTheadRef(data)}
          style={{ width: `${tableStyle}` }}
          list={orderSummaryList}
        >
          <TableRow>
            <TableHeaderCell
              rowSpan='2'
              colSpan='1'
              style={{
                ...leftAlignedHeaderStyle,
                ...dateColumnWidth(headerStyle),
              }}
            >
              {dateText}
            </TableHeaderCell>

            <TableHeaderCell
              rowSpan='2'
              colSpan='1'
              style={{
                ...leftAlignedHeaderStyle,
                ...timeEnteredColumnWidth(headerStyle),
              }}
            >
              {timeEntered}
            </TableHeaderCell>
            {orderSummaryList.trancheOrders.map(
              (item, index) =>
                item.count > 0 && (
                  <TableHeaderCell
                    key={index}
                    colSpan={item.count}
                    style={{
                      ...rightAlignedHeaderStyle,
                      ...borderBottom,
                      ...columnHeight,
                    }}
                  >
                    {item.trancheName+ " (m)"}
                  </TableHeaderCell>
                )
            )}
          </TableRow>
          <TableRow>
            {
              orderSummaryList.amendedOrderIdList.map((id, index) => (
                <TableHeaderCell
                  key={index}
                  style={{ ...rightAlignedHeaderStyle, ...columnHeight }}
                >
                  Order ID - {id.orderId}
                </TableHeaderCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody
          style={{ maxHeight: `calc(100vh - 535px)`, height: "100%" }}
        >
          {orderSummaryList.orderSummary.map((order, index) => {
            return (
              <TableRow key={index}>
                <TableColumnCell
                  style={{
                    ...leftAlignedCellStyle,
                    ...columnHeight,
                    ...dateColumnWidth(headerStyle),
                  }}
                >
                  {order.dateEntered
                    ? dateFormatter('localDate', order.dateEntered)
                    : ''}
                </TableColumnCell>
                <TableColumnCell
                  style={{
                    ...leftAlignedCellStyle,
                    ...columnHeight,
                    ...timeEnteredColumnWidth(headerStyle),
                  }}
                >
                  {order.timeEntered}
                </TableColumnCell>
                {
                  orderSummaryList.amendedOrderIdList.map((id, index) => (
                    <TableColumnCell
                      key={index}
                      style={{
                        ...rightAlignedCellStyle,
                        ...columnHeight,
                        ...getCellWidthCell(
                          cellWidth,
                          orderSummaryList.amendedOrderIdList,
                          index
                        ),
                      }}
                    >
                      {ValueFormatters.commaDecimalFormatter(order[id.orderId])}
                    </TableColumnCell>
                  ))
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default AmendedSummaryTable;