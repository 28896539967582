import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    isDCMDealType,
    isECMDealType,
    isDCMOrderbookContentAvailable,
    isECMOrderbookContentAvailable
} from 'selectors';

import PageContentNotAvailable from 'components/PageContentNotAvailable';

import ECMOrderSummaryContainer from './ecm/OrderSummary/OrderSummary.container';
import DCMOrderSummaryContainer from './dcm/OrderSummary/OrderSummary.container';

class OrderSummaryContainer extends PureComponent {
    render() {
        if (!this.props.isDCMAvailable && !this.props.isECMAvailable) {
            return <PageContentNotAvailable />
        }

        if (this.props.isECM) {
            return <ECMOrderSummaryContainer />;
        }

        if (this.props.isDCM) {
            return <DCMOrderSummaryContainer />;
        }

        return null;
    }
}

const EnhancedOrderSummary = connect(
    state => ({
        isDCM: isDCMDealType(state),
        isECM: isECMDealType(state),
        isDCMAvailable: isDCMOrderbookContentAvailable(state),
        isECMAvailable: isECMOrderbookContentAvailable(state),
        isFetching: state.utility.fetching,
    }),
)(OrderSummaryContainer);

export default EnhancedOrderSummary;
