import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import _, { get, keyBy } from 'lodash';
// import sha1 from 'sha1';
import sha256 from 'sha256';
// TODO
import { SiteConstants, Sorters } from 'mk-pattern-library-react';

import * as chartTypeIds from 'constants/chartTypes';
import { tabIds, investorFeedbackChartTabs } from 'constants/tabIds';
import { selectAvailableNavigationFeatures } from 'util/navigationFeatures';
import { ALL_TRANCHES_RECORD } from 'constants/orders';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';
// reducer selectors
const selectRoadshowReducer = state => state.roadShowData;
const selectUtilityReducer = state => state.utility;
const selectLoginReducer = state => state.login;
const selectOrderbookReducer = state => state.orderBookData;
const selectMyDocsReducer = state => state.myDocsData;
const selectInvestorFeedbackReducer = state => state.investorFeedbackData;
const selectLanguageReducer = state => state.language;
export const selectIsAmended = state => state.orderBookData.isAmended;
//
// Language Selectors
//
export const getSelectedLanguage = state => selectLanguageReducer(state).selectedLanguage;
export const getAvailableLanguages = state => selectLanguageReducer(state).availableLanguages;

//
// Utility Selectors
//
export const getPollings = state => selectUtilityReducer(state).polling;
export const getDeals = state => selectUtilityReducer(state).allDeals;
export const getCurrentDeal = state => selectUtilityReducer(state).dealsData;
export const getCurrentDealId = state => selectUtilityReducer(state).dealsData.dealId;
export const getIssuerName = state => selectUtilityReducer(state).dealsData.orderbookIssuerName;
export const getAllNavigationFeatures = state => selectUtilityReducer(state).navigationFeatures;
export const getPollingFrequency = state => selectUtilityReducer(state).pollingFrequency;
export const isGeneralFetchPending = state => selectUtilityReducer(state).fetching;
export const isBaseFetchCompleted = state => selectUtilityReducer(state).baseFetchCompleted;
export const isModalOpen = (state, modalId) => !!selectUtilityReducer(state).modals[modalId];
export const getSelectedTab = (state, tabsId) => selectUtilityReducer(state).tabs[tabsId];
export const getChartAnimationState = (state, chart) => selectUtilityReducer(state).animations[chart];

export const getHomeTileStatus = (state, tile) => selectUtilityReducer(state)[tile];

export const isAnyModalOpened = createSelector(
    [state => selectUtilityReducer(state).modals],
    modals => !!Object.keys(modals).length,
);

export const getCurrentDealInfo = createSelector(
    [getDeals, getCurrentDealId],
    (deals, dealId) => deals.find(x => x.dealId === dealId) || {},
);

export const isDCMDealType = createSelector(
    getCurrentDealInfo,
    deal => (deal.dealType || '').toLowerCase() === 'dcm',
);

export const isECMDealType = createSelector(
    getCurrentDealInfo,
    deal => (deal.dealType || '').toLowerCase() === 'ecm',
);

export const getAllowedNavigationFeatures = createSelector(
    [
        state => selectUtilityReducer(state).allowedNavigationFeatures,
        state => getAllNavigationFeatures(state),
        state => selectUtilityReducer(state).allDeals.length === 0
    ],
    (allowedNavigationFeatures, allNavigationFeatures, noDeals) => {
        const allowedNavFeatures = [];

        if (!noDeals) {
            allowedNavigationFeatures.forEach(feature => allowedNavFeatures.push(feature));

            allowedNavFeatures.sort((a, b) => {
                if (a.url === '/') {
                    return -1;
                } else if (b.url === '/') {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            
            Array.prototype.push.apply(allowedNavFeatures, allNavigationFeatures.map(feature => ({
                ...feature,
                visibleInHeroMenu: true,
                visibleInMainMenu: feature.title === "MyDeal",
            })));
        }

        return allowedNavFeatures;
    },
);

export const getDealTypeFromDeals = (state, dealId) => {
    const deals = getDeals(state);
    for (let i = 0; i < deals.length; i++) {
        if (deals[i].dealId === dealId) {
            return deals[i].dealType;
        }
    }
    return null;
}

export const isFeatureAvailable = (state, dealFeature) => {
    const available = getAllowedNavigationFeatures(state)
    const castedToDealFeatures = selectAvailableNavigationFeatures(available);

    return !!castedToDealFeatures[dealFeature];
}

export const isHomeTileFetched = (state, dealFeature) => {
    const pollings = getPollings(state);
    return pollings[dealFeature].homeFetched;
}

//
// Investor Engagement Selectors (disabled feature)
//
// const getECMInvestorEngagement  = state => selectInvestorEngagementReducer(state).ECMInvestorsInvestorEngagement;
// const getInvestorEngagement     = state => selectInvestorEngagementReducer(state).investorEngagement;
//
// export const getInvestorEngagementData = createSelector(
//     [getInvestorEngagement],
//     (engagement) => engagement,
// );
//
// export const getECMInvestorEngagementData = createSelector(
//     [getECMInvestorEngagement],
//     (engagement) => engagement,
// );

//
// Orderbook Selectors
//
export const getDemandByTypeData = (state, type) => selectOrderbookReducer(state)[type];
const getShareDemandData = state => selectOrderbookReducer(state).shareDemandData;

export const getTranches = state => selectOrderbookReducer(state).tranches;
export const getInvestorNames = state => selectOrderbookReducer(state).investorNames;
export const getTrancheLists = state => selectOrderbookReducer(state).trancheLists;
export const getOrderSummaryData = state => selectOrderbookReducer(state).orderSummaryData[0];
export const getAmendedOrderSummaryData = state => selectOrderbookReducer(state).amendedOrderSummaryData;
export const getBookEvolutionData = state => selectOrderbookReducer(state).bookEvolutionSummary[0];
export const getBookEvolutionDataForAmendedOrderByInvestor = state => selectOrderbookReducer(state).bookEvolutionSummaryForAmendedOrderByInvestorTable[0];

export const getCurrentTrancheId = state => selectOrderbookReducer(state).currentTrancheId;
export const getOrders = state => selectOrderbookReducer(state).orderData;
export const getSelectedInvestor = state => selectOrderbookReducer(state).selectedInvestor;
export const getSelectedTranche = state => selectOrderbookReducer(state).selectedTranche;
export const getSelectedInterval = state => selectOrderbookReducer(state).selectedInterval;
export const getSelectedInvestorType = state => selectOrderbookReducer(state).selectedInvestorType;
export const getSelectedTimeRange = state => selectOrderbookReducer(state).selectedTimeRange;
export const getSelectedDate = state => selectOrderbookReducer(state).selectedDate;
export const getOrderSummary = state => selectOrderbookReducer(state).orderSummaryData[0]
export const getSelectedOrderBookTab = state => selectOrderbookReducer(state).currentActiveOrderSummaryTab;
export const getStages = state => selectOrderbookReducer(state).stages;
export const getIsSetStagesModalOpen = state => selectOrderbookReducer(state).isSetStagesModalOpen;

export const getTrancheLoader = state => selectOrderbookReducer(state).trancheLoader;

export const isDCMOrderbookContentAvailable = createSelector(
    [getTranches],
    tranches => (tranches[0] || {}).trancheId
);

export const isECMOrderbookContentAvailable = createSelector(
    [getOrders],
    orders => (orders[0] || {}).trancheId
);

export const areECMOrdersFetched = state => selectOrderbookReducer(state).ordersReceived && selectOrderbookReducer(state).shareDemandReceived;
export const areDCMOrdersFetched = state => selectOrderbookReducer(state).ordersReceived && selectOrderbookReducer(state).tranchesReceived;

export const getECMLatestOrders = createSelector(
    [getOrders],
    orders => orders
        .sort((a, b) => Sorters.doubleSort('investorLongName', a, b, 'desc',  'externalSourceLastModified'))
)

export const getDCMLatestOrders = createSelector(
    [getOrders],
    orders => orders
        .sort((a, b) => Sorters.doubleSort('investorLongName', a, b, 'desc',  'externalSourceLastModified'))
)

export const getFullOrderBookSort = state => selectOrderbookReducer(state).fullOrderBookSort;
export const getAllTranchesFullOrderBookSort = state => selectOrderbookReducer(state).allTranchesFullOrderBookSort;

export const getFullOrderBookTable = createSelector(
    [getOrders, getShareDemandData, getFullOrderBookSort],
    (orderData, shareDemandData, sort) => {
        let tableData = [];
        if (orderData) {
            for (let item of orderData) {
                let tableRecord = {};
                // common fields across table views
                tableRecord.investorLongName = item.investorLongName;
                tableRecord.investorCountry = item.investorCountry;
                tableRecord.investorType = item.investorType;
                // allocation
                tableRecord.targetDemandAmount = item.shareDemandSummary.demandAtTargetSharePrice.demandAmount;
                tableRecord.targetAllocationAmount = item.allocationAtTargetPrice.allocationAmount;
                tableRecord.targetAllocationPercentage = item.allocationAtTargetPrice.allocationVDemandPercentage;
                // order summary
                tableRecord.demandAtBottom = item.shareDemandSummary.demandAtBottomSharePrice.demandAmount;
                tableRecord.demandAtMidpoint = item.shareDemandSummary.demandAtMidSharePrice.demandAmount;
                tableRecord.demandAtTop = item.shareDemandSummary.demandAtTopSharePrice.demandAmount;
                tableRecord.currentLimit = item.currentLimit;
                // demand detail
                // loop through share price array and find corresponding amounts.
                // add corresponding amount or null with share price as key
                for (let share of shareDemandData) {
                    const demandAmount = item.shareDemand.find(x => x.demandSharePrice === share.sharePrice);
                    tableRecord[share.sharePrice.toString().replace('.', '_')] = demandAmount ? demandAmount.demandAmount : null;
                }
                // engagement
                tableRecord.topTierInvestor = item.topTierInvestor;
                tableRecord.earlyLookParticipantInvestor = item.earlyLookParticipantInvestor;
                tableRecord.preDealEducationInvestor = item.preDealEducationInvestor;
                tableRecord.physicalRoadshow = item.investorRoadshowAttendance;
                tableRecord.hasComments = item.investorFeedbackLeft;
                tableRecord.externalSourceCreated = item.externalSourceCreated;
                tableRecord.externalSourceLastModified = item.externalSourceLastModified;

                tableData.push(tableRecord)
            }

            tableData.sort((a, b) => {
                if(sort.direction === "Ascending") {
                    return a[sort.column] > b[sort.column] ? 1 : -1;
                } else {
                    return a[sort.column] < b[sort.column] ? 1 : -1;
                }
            });

            
        }
        return tableData;
    }
);


export const getDCMFullOrderBookTable = createSelector(
    [getOrders, getFullOrderBookSort, getCurrentTrancheId],
    (orderData, sort, currentTrancheId) => {
        let tableData = [];
        if (orderData && currentTrancheId !== ALL_TRANCHES_RECORD.id) {
            for (let item of orderData) {
                item.allocation[0] = item.allocation[0] || {}
                let tableRecord = {};

                // common fields acocss table views
                tableRecord.investorLongName = item.investorLongName;
                tableRecord.softAmount = item.softAmount;
                tableRecord.firmAmount = item.firmAmount;
                tableRecord.externalSourceCreated = item.externalSourceCreated;
                tableRecord.externalSourceLastModified = item.externalSourceLastModified;

                // orderDetail
                tableRecord.spread = item.spread;
                tableRecord.limitSecurity = item.limitSecurity;
                tableRecord.hedge = item.hedge;
                tableRecord.allocationAmount = item.allocation[0].allocationAmount;
                tableRecord.allocationPercent = item.allocation[0].allocationVDemandPercentage;

                // investorType
                tableRecord.investorRegion = item.investorRegion;
                tableRecord.investorCountry = item.investorCountry;
                tableRecord.investorType = item.investorType;

                // engagementLevel
                tableRecord.keyInvestor = item.keyInvestor;
                tableRecord.historicalInvestor = item.historicalInvestor;
                tableRecord.netRoadshow = item.netRoadshow;
                tableRecord.physicalRoadshow = item.physicalRoadshow;
                
                tableData.push(tableRecord)
            }
            tableData.sort((a, b) => {
                if(sort.direction === "Ascending") {
                    return a[sort.column] > b[sort.column] ? 1 : -1;
                } else {
                    return a[sort.column] < b[sort.column] ? 1 : -1;
                }
            });
        }
        return tableData;
    }
);

const getFullOrderBookTableData = (orderData, sort, currentTrancheId, amended) => {
    let tableData = [];
    if (orderData && currentTrancheId === ALL_TRANCHES_RECORD.id) {
        for (let item of orderData) {
            if ((!amended && item.status === 'Active') || (amended)) {
                let tableRecord = {};
                // common fields acocss table views
                tableRecord.orderId = item.orderId;
                tableRecord.issuerName = item.issuerName;
                tableRecord.investorLongName = item.investorLongName.trim();
                tableRecord.investorType = item.investorType;
                tableRecord.investorCountry = item.investorCountry;
                tableRecord.currency = item.currency;
                tableRecord.orderAmount = item.orderAmount;
                tableRecord.limitSecurity = item.limitSecurity;
                tableRecord.trancheName = item.trancheName;
                tableRecord.externalSourceLastModified = item.externalSourceLastModified;
                amended && (tableRecord.isLatest = item.amendedAmount);
                amended && (tableRecord.amendedAmount = item.amendedAmount);
                tableRecord.status = item.status;
                tableData.push(tableRecord)
            }
        }
        tableData.sort((a, b) => {
            if (sort.direction === "Ascending") {
                if (typeof a[sort.column] === 'string') {
                    return a[sort.column].toLowerCase() > b[sort.column].toLowerCase() ? 1 : -1;
                } else return a[sort.column] > b[sort.column] ? 1 : -1;

            } else {
                if (typeof a[sort.column] === 'string') {
                    return a[sort.column].toLowerCase() < b[sort.column].toLowerCase() ? 1 : -1;
                } else return a[sort.column] < b[sort.column] ? 1 : -1;
            }
        });

        if (sort.column === "amendedAmount") {
            const tempTableData = JSON.parse(JSON.stringify(tableData));
            const updatedTableGroups = [];
            const uniqueOrderIDList = [...new Map(tempTableData.map((item) => [item['orderId'], item])).values()];
            uniqueOrderIDList.forEach((item) => {
                const newData = tempTableData.filter((order) => order.orderId === item.orderId);
                newData.length && updatedTableGroups.push(newData)
            })
            tableData = [].concat.apply([], updatedTableGroups);
        }
    }
    return tableData;
}

export const getDCMAllTranchesFullOrderBookTable = createSelector(
    [getOrders, getAllTranchesFullOrderBookSort, getCurrentTrancheId, selectIsAmended],
    (orderData, sort, currentTrancheId, amended) => {
        return getFullOrderBookTableData(orderData, sort, currentTrancheId, amended)
    }
);

export const getDemandDataForSummary = createSelector(
    [getDemandByTypeData],
    (demandData) => {
        let tableData = [];
        let chartData = [];
        if (demandData) {
            if (demandData[0] && "demand" in demandData[0]) {
                demandData = demandData[0].demand;
            }
            if ("demand" in demandData) {
                demandData = demandData.demand;
            }
            for (let item of demandData) {
                if (item) {
                    tableData.push({
                        dataItem: item.categoryName,
                        totalOrders: item.demand,
                        percent: item.percentage,
                        orders: item.totalNumberOfOrders,
                        otherValues: item.otherValues,
                    })
                    chartData.push({
                        name: item.categoryName,
                        value: Math.abs(item.demand)
                    });
                }
            }
        }
        return {
            tableData,
            chartData
        }
    }
);


export const getDemandShareDataForSummary = createSelector(
    [getDemandByTypeData],
    (demandData) => {
        let tableData = [];
        let chartData = [];
        if (demandData && demandData[0]) {
            demandData = demandData[0].demandDetail;

            for (let item of demandData) {
                tableData.push({
                    dataItem: item.categoryName,
                    totalOrders: item.demand,
                    percent: item.percentage
                })
                chartData.push({
                    name: item.categoryName,
                    value: item.demand
                });
            }
        }
        return {
            tableData,
            chartData
        }
    }
);

export const getTrancheTitles = createSelector(
    [getTranches],
    (tranches) => {
        return tranches.map(item => ({
            title: item.trancheNameShort,
            status: item.orderbookStatusDetail.currentStatus.status,
            id: item.trancheId,
        }))
    }
);


export const getCurrentTranche = createSelector(
    [getTranches, getCurrentTrancheId],
    (tranches, trancheId) => {
        let trancheLookup = tranches.find(x => x.trancheId === trancheId);
        const currentTrancheData = trancheLookup ? trancheLookup : tranches[0];
        return currentTrancheData;
    }
);

export const getLowestSharePrice = createSelector(
    getCurrentDeal,
    dealsData => {
        return (
            dealsData.dealTotalDemandSummary &&
            dealsData.dealTotalDemandSummary.demandAtBottomSharePrice &&
            dealsData.dealTotalDemandSummary.demandAtBottomSharePrice.sharePrice
        ) || null;
    }
);

export const getShareDemand = createSelector(
    [getShareDemandData],
    (ShareDemands) => {
        return ShareDemands.sort((a, b) => {
            if (a.sharePrice < b.sharePrice) {
                return -1
            }
            else if (a.sharePrice > b.sharePrice) {
                return 1
            }
            else {
                return 0
            }
        })
    }
);

export const getTrancheIdByDealType = createSelector(
    [isDCMDealType, getCurrentTrancheId, selectUtilityReducer], (isDCM, currentTrancheId, utilityReducer) => {
        if (isDCM) {
            if (currentTrancheId) {
                return getCurrentTrancheId;
            }
            return 'Empty Data';
        }

        // ECM
        const tranche = (utilityReducer.dealsData.tranche && utilityReducer.dealsData.tranche.length && utilityReducer.dealsData.tranche[0]) || {}
        const { trancheId } = tranche;

        if (trancheId) {
            return trancheId;
        }

        return 'Empty Data';
    }

)

//
// Roadshow Selectors
//
const selectTeamEventDates = state => selectRoadshowReducer(state).teamsEventDates;
export const getRoadShowEventDates = state => selectRoadshowReducer(state).eventDates;
export const getRoadShowFullSchedule = state => selectRoadshowReducer(state).events;
export const getRoadShowSummaryOnline = state => selectRoadshowReducer(state).onlineRoadshow;
export const getRoadShowSummaryCoordinators = state => selectRoadshowReducer(state).coordinators;
export const getRoadShowContacts = state => selectRoadshowReducer(state).contacts;
export const getRoadShowSelectedDay = state => selectRoadshowReducer(state).selectedDay;
export const getSelectedTeam = state => selectRoadshowReducer(state).selectedTeam;
export const getTeams = state => selectRoadshowReducer(state).teams;
export const getMultiTeamMode = state => selectRoadshowReducer(state).multiTeamMode;
export const getDailyEventId = state => selectRoadshowReducer(state).dailyEventId;
export const getRoadshowDataArrivedFlag = state => selectRoadshowReducer(state).summaryArrived && selectRoadshowReducer(state).eventsArrived;

export const getTeamsDetails = createSelector(
    [
        selectTeamEventDates 
    ],
    (eventDates) => keyBy(eventDates, e => e.teamName)
);

export const getEventsForSelectedDay = createSelector(
    [
        getRoadShowFullSchedule,
        getRoadShowSelectedDay,
        getSelectedTeam,
        getMultiTeamMode,
    ],
    (fullSchedule, selectedDay, selectedTeam, multiteamMode) => {
        let events = [];

        if (fullSchedule.length) {
            if (multiteamMode) {
                events = fullSchedule.filter(ev => ev.teams.indexOf(selectedTeam) >= 0);
            } else {
                events = fullSchedule;
            }

            if (selectedDay) {
                events = events.filter(event => moment(event.startDateLocal).utc().isSame(moment(selectedDay.date).utc(), 'day'));
            }
        }

        return events;
    },
);

//
// Auth Selectors
//
const getLoggedIn = state => selectLoginReducer(state).isAuthenticated;

export const isLoggedInError = state => selectLoginReducer(state).error;
export const getBeatingStatus = state => selectLoginReducer(state).beating;
export const getUniqueName = state => selectLoginReducer(state).uniqueName;
export const isInternalUser = state => selectLoginReducer(state).isInternalUser;
export const isISVUser = state => selectLoginReducer(state).isISVUser;
export const isIsvCsmUser = state => selectLoginReducer(state).isIsvCsmUser;
export const isIsvRRTeamUser = state => selectLoginReducer(state).isIsvRRTeamUser;
export const getSUniqueName = createSelector(
    getUniqueName,
    uniqueName => {
        const hash = sha256(uniqueName);
        const shortHash = hash.substring(30, 40);
        return shortHash;
    }
);

export const getLoggedInStatus = createSelector(
    [getLoggedIn],
    (loggedIn) => loggedIn,
);

export const selectLoginFormInvalidFields = (state) =>
  _.keys(_.pickBy(state.login.invalidFields, field => field === true));

//
// Investor Feedback Selectors
//
const getInvestorFeedbackSentiments = state => selectInvestorFeedbackReducer(state).feedbackSentiments;
const getInvestorFeedbackDealFeedback = state => selectInvestorFeedbackReducer(state).dealFeedback;
const getInvestorFeedbackDealThemes = state => selectInvestorFeedbackReducer(state).dealThemes;
const getInvestorFeedbackSummaryByThemeType = (state, summaryThemeStore) => selectInvestorFeedbackReducer(state)[summaryThemeStore];
const getInvestorFeedbackHighlightsByThemeType = (state, highlightsThemeStore) => selectInvestorFeedbackReducer(state)[highlightsThemeStore];
const getInvestorFeedbackChartDataByChartType = (state, chartDataStore) => selectInvestorFeedbackReducer(state)[chartDataStore];

export const getInvestorFeedbackThemeTypes = state => selectInvestorFeedbackReducer(state).feedbackThemeTypes;
export const getInvestorFeedbackChartTypes = state => selectInvestorFeedbackReducer(state).chartTypes;
export const getSelectedChartId = state => selectInvestorFeedbackReducer(state).selectedChartId;
export const getSelectedChartTab = state => selectInvestorFeedbackReducer(state).selectedChartTab;
export const getSelectedChartSubTab = state => selectInvestorFeedbackReducer(state).selectedChartSubTab;
export const getSelectedChartSubTabTitle = state => selectInvestorFeedbackReducer(state).selectedChartSubTabTitle;
export const areChartTypesFetched = state => selectInvestorFeedbackReducer(state).chartTypesFetched;

export const getChartTypeDetails = (state, chartTypeId) => {
    const chartTypes = getInvestorFeedbackChartTypes(state);
    return _.find(chartTypes, chartData => chartData.chartType.chartTypeId === chartTypeId) || {};
};

export const selectActualChart = state => {
    const selectedChartTab = getSelectedTab(state, tabIds.INVESTOR_FEEDBACK_CHART_TABS);

    let chartTypeDetails;
    if (selectedChartTab === investorFeedbackChartTabs.THEME_DISTRIBUTION) {
        chartTypeDetails = getChartTypeDetails(state, +chartTypeIds.THEME_DISTRIBUTION_CHART_TYPE);
    } else if (selectedChartTab === investorFeedbackChartTabs.SENTIMENT) {
        chartTypeDetails = getChartTypeDetails(state, +chartTypeIds.SENTIMENT_CHART_TYPE);
    } else if (selectedChartTab === investorFeedbackChartTabs.PRICE_DEMAND) {
        chartTypeDetails = getChartTypeDetails(state, +chartTypeIds.PRICE_DEMENT_CHART_TYPE);
    }

    // if there is a data => still available ( + visible prop )
    const isEmpty = _.isEqual(chartTypeDetails, {});
    if (!isEmpty) {
        return chartTypeDetails;
    }

    // no charts available now
    return null;
};

export const selectFirstAvailableChart = createSelector(
    [getInvestorFeedbackChartTypes],
    chartTypes => {
        if (chartTypes.length) {
            return chartTypes[0];
        }

        return null;
    }
);

export const selectFirstAvailableChartTab = createSelector(
    [getInvestorFeedbackChartTypes],
    chartTypes => {
        if (chartTypes.length) {
            if(chartTypes[0].chartType.chartTypeId === +chartTypeIds.THEME_DISTRIBUTION_CHART_TYPE)
                return investorFeedbackChartTabs.THEME_DISTRIBUTION;
            if(chartTypes[0].chartType.chartTypeId === +chartTypeIds.SENTIMENT_CHART_TYPE)
                return investorFeedbackChartTabs.SENTIMENT;
            if(chartTypes[0].chartType.chartTypeId === +chartTypeIds.PRICE_DEMENT_CHART_TYPE)
                return investorFeedbackChartTabs.PRICE_DEMAND;
        }

        return null;
    }
);

export const getSentimentsAndThemeTypesFetchedStatus = createSelector(
    [selectInvestorFeedbackReducer],
    invFeedback => invFeedback.sentimentsFetched && invFeedback.themeTypesFetched,
);

export const getInvestorFeedbackHighlightsByTheme = createSelector(
    [getInvestorFeedbackHighlightsByThemeType],
    (highlightData) => highlightData || [],
);

export const getInvestorFeedbackChartDataByChart = createSelector(
    [getInvestorFeedbackChartDataByChartType],
    (chartData) => (chartData && chartData.chartData) || [],
);

export const getInvestorFeedbackSummaryChartData = createSelector(
    [getInvestorFeedbackSummaryByThemeType], (summaryData) => {
        let chartSeries = [];
        let chartKeys = [];
        let currentMonth;
        if (get(summaryData, '[0].summary')) {
            let summaryDataItems = summaryData[0].summary;
            summaryDataItems.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            for (let item of summaryDataItems) {
                let barItem = {};
                barItem.name = moment.utc(item.date).format("D");

                const countSum = item.series.map(item => item.count).reduce((a, b) => a + b);
                barItem.topText = countSum;
                for (let bar of item.series) {
                    barItem[bar.themeId] = (bar.count / countSum) * 100;
                }
                const monthText = moment.utc(item.date).format("MMM");
                if (moment.utc(item.date).format("MMM") !== currentMonth) {
                    barItem.bottomText = monthText;
                    currentMonth = monthText;
                }
                chartSeries.push(barItem);
            }
        }
        if (get(summaryData, '[0].themes')) {
            const themeItems = summaryData[0].themes;
            const colors = SiteConstants.secondaryChartColors;
            for (let item of themeItems) {
                const keyItem = {
                    key: item.themeId,
                    value: item.themeName,
                    color: colors[chartKeys.length % colors.length]
                };
                chartKeys.push(keyItem);
            }
        }
        return {
            chartSeries,
            chartKeys
        }

    }
);

export const getFeedbackCardData = createSelector(
    [
        getInvestorFeedbackDealFeedback,
        getInvestorFeedbackDealThemes,
        getInvestorFeedbackSentiments,
        getTranches,
    ], (feedbackItems, dealThemes, sentiments, tranches) => {
        const feedbackCardItems = [];

        const sentimentDescriptionFromId = (sentimentId) => {
             const sentiment = sentiments.find(item => item.sentimentId === sentimentId);
             return sentiment ? sentiment.sentimentDescription : '';
        };

        if (feedbackItems) {
            for (let i = 0; i < feedbackItems.length; i += 1) {
                const item = feedbackItems[i];
                const feedback = {};

                feedback.feedbackId = item.feedbackId;
                feedback.companyName = item.investor;
                feedback.investorType = item.investorType; // Data gap
                feedback.investorName = item.investorContact;
                feedback.created = item.recordedDateTime;
                feedback.banksRecorded = item.bank;
                feedback.cityRecorded = item.city;
                feedback.themes = {
                    positive: item.themesRaw.filter(theme => theme.themeTypeName === 'Positive').map(t => t.themeName),
                    negative: item.themesRaw.filter(theme => theme.themeTypeName === 'Concern').map(t => t.themeName),
                    valuation: item.themesRaw.filter(theme => theme.themeTypeName === 'Valuation').map(t => t.themeName),
                };
                feedback.sentiment = sentimentDescriptionFromId(item.sentimentId);
                feedback.comment = item.text;
                feedback.minIndicationOfInterest = item.minIndicationOfInterest;
                feedback.maxIndicationOfInterest = item.maxIndicationOfInterest;
                feedback.valuation = item.valuation;
                feedback.keyInvestor = item.keyInvestor;
                feedback.lastModified = item.lastModified;
                // do we need them ?              
                feedback.positiveHighlight = item.positiveHighlight;
                feedback.negativeHighlight = item.negativeHighlight;
                feedback.valuationHighlight = item.valuationHighlight;
                feedback.feedbackTranchePriceDemand = item.feedbackTranchePriceDemand && item.feedbackTranchePriceDemand.length ? item.feedbackTranchePriceDemand.map(tranche => {
                    const tranchesValue = tranches && tranches.filter(t => t.trancheConfigId === tranche.trancheConfigId)
                    const trancheDescription = tranchesValue && tranchesValue.length ? tranchesValue[0].trancheDescription : undefined;
                    return {
                        ...tranche,
                        trancheDescription,
                    }
                }) : undefined;
                //
                feedbackCardItems.push(feedback);
            }
        }
        return feedbackCardItems;
    },
);

export const getCurrentFeedbackChartTypeName = createSelector(
    [getSelectedChartId, getInvestorFeedbackChartTypes], (chartId, chartTypesList) => {
        if (chartId) {
            const { chartType } = _.find(chartTypesList, c => c.chartId === chartId);
            return chartType.chartTypeName;

        } else {
            return 'Empty';
        }
    }
)

export const getLatestFeedback = createSelector(
    [getInvestorFeedbackDealFeedback],
    dealFeedback => dealFeedback
        .sort((a, b) => Sorters.doubleSort('investorLongName', a, b, 'desc',  'recordedDateTime'))[0] || {}
)

//
// MyDocs Selectors
//
export const getMyDocsFetchedStatus = state => selectMyDocsReducer(state).fetchedDocs && selectMyDocsReducer(state).fetchedSummary;
export const getDocumentsFetchedStatus = state => selectMyDocsReducer(state).fetchedDocs;
export const getMyDocsSummaryData = state => selectMyDocsReducer(state).myDocsSummary;
export const getMyDocsData = state => selectMyDocsReducer(state).myDocs;
export const getMyDocsActiveCategoryId = state => selectMyDocsReducer(state).activeCategoryId;
export const getMyDocsReceivedDate = state => selectMyDocsReducer(state).dateReceived;

export const getActualCategoryTitle = createSelector(
    [getMyDocsSummaryData, getMyDocsActiveCategoryId], (summary, activeCategoryId) => {
        if (!activeCategoryId) {
            return 'All Documents'
        }

        return _.find(summary, category => category.documentCategoryId === activeCategoryId).documentCategoryName;
    }
)

export const getAllNotArchivedDocumentsCount = createSelector(
    [getMyDocsData],
    myDocs => myDocs.reduce((total, document) => {
        if (document.archived) {
            return total;
        }

        return ++total;
    }, 0)
);

export const getMyDocsForActiveCategory = createSelector(
    [getMyDocsActiveCategoryId, getMyDocsData],
    (activeCategoryId, myDocs) => {
        return activeCategoryId
            // category is set
            ? myDocs.filter(doc => doc.categoryId === activeCategoryId)
            // null -> all documents
            : myDocs;
    }
);

export const getLastDocuments = createSelector(
    [getMyDocsData],
    myDocs => myDocs
        .sort((a, b) => Sorters.doubleSort('title', a, b, 'desc',  'lastModified'))
);

const getSiteTextState = state => state.login.siteText;

// NOTE: Temporarily hardcoded the site text to en-gb
export const makeGetSiteTextSelector = (siteTextTypeName) =>
    createSelector(
        [getSiteTextState, getSelectedLanguage],
        (siteText, langCode) => {
            return siteText && siteText[siteTextTypeName] && siteText[siteTextTypeName][/*langCode*/'en-gb']
        }
    )
export const getSiteTextValue = (value) => {
        return value === 'privacynotice' ? SITE_TEXT_TYPES.PRIVACY : SITE_TEXT_TYPES.C_POLICY
}
export const getTermsAndConditions = state => state.login.termsAndConditions;

export const getTermsAndConditionsText = createSelector(
    [getSelectedLanguage, getTermsAndConditions],
    (langCode, termsAndConditions) => {
        if (termsAndConditions[langCode.toLowerCase()]) {
            return termsAndConditions[langCode.toLowerCase()].termsAndConditionsText;
        }

        return null;
    }
);