import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import DialogActions from '@mui/material/DialogActions';
import { PrimaryButton, SecondaryButton } from 'mk-pattern-library-react';
import { defaultBtnStyle, primaryBtnStyle } from 'Isv/styles/buttonStyle';

const ConfirmationContainer = styled.div`
  font-size: 16px;
  color: rgba(51, 51, 51, 0.6);
  padding: 24px;
  box-sizing: border-box;
  overflow-y: hidden;
  border-top: none;
  border-bottom: none;
  max-height: 429px;
  button {
      outline: none !important;
  }
`

const Header = styled.div`
  margin: 0;
`;

const DialoguePara = styled.p`
  margin-top: 15px;
  line-height: 20px;
`

class ConfirmationDialog extends Component {    
    render() {
        const { isCancelButtonNotRequired, dialogActionStyle, dialogActionButtonStyle } = this.props;       
         return (
            <Dialog
                open={this.props.open}
            >
                {this.props.open &&
                <ConfirmationContainer>
                    <Header>{this.props.header}</Header>
                    <DialoguePara>{this.props.subHeader}</DialoguePara>
                    <DialogActions style={dialogActionStyle && dialogActionStyle}>
                         <PrimaryButton
                             size="normal"
                             style={{ ...primaryBtnStyle, ...dialogActionButtonStyle }}
                             onClick={this.props.onConfirmClick}
                         >
                             {this.props.confirmButtonText}
                         </PrimaryButton>

                         {!isCancelButtonNotRequired &&
                             <SecondaryButton
                                 size="normal"
                                 style={defaultBtnStyle}
                                 onClick={this.props.onDeclineClick}
                             >
                                 {this.props.declineButtonText}
                             </SecondaryButton>
                         }
                    </DialogActions>
                </ConfirmationContainer>
                }
            </Dialog>
        );
    }
}

export default ConfirmationDialog;