/* @flow */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import CoordinatorComments from '../CoordinatorComments/CoordinatorComments';

import TranslationText from 'translations/TranslationText';

const LogisticsDetails = (props) => {
    const { event, driver, eventType } = props,
        spacingSm = { marginBottom: 5 },
        spacingLg = { marginBottom: 8 },
        dealType = props.dealType;

    // Planes and Trains
    const travelWithIcon = (location1, location2, carrier) => (
        <Row className="event-place text-univ-lt text-medium">
            {
                location1 && (
                    <Col style={{ paddingRight: 30 }} sm={5}>
                        <div className="text-small">
                            <TranslationText
                                dictionaryKey={"roadshow.depart"}
                                defaultText={"Depart"}
                            />
                            &nbsp;
                            {location1.city}
                        </div>
                        <div style={spacingLg} className="text-bold text-univ-bd">
                            {ValueFormatters.dateFormatter('zoneTime', event.startDateLocal || '--')}
                            <span style={{ fontWeight: 400 }} className="text-univ-lt"> {event.startTimeZone}</span>
                        </div>
                        <div style={spacingLg}>{location1.place}</div>
                        <div className="text-small ">{location1.roomNumber}</div>
                        <div className="text-small ">{location1.addressLine1}</div>
                        <div className="text-small ">{location1.addressLine2}</div>
                        <div className="text-small ">{location1.addressLine3}</div>
                        <div className="text-small ">{location1.addressLine4}</div>
                        <div className="text-small ">{location1.telephone}</div>
                        {/* <div className="text-small ">Terminal --</div> */}
                    </Col>
                )
            }

            {               
                location1 && (
                    <Col sm={2} style={{ padding: 0 }} className="text-center">
                        <div className="text-small ">{(eventType.eventTypeId === 8 || eventType.eventTypeId === 9) ? 'Flight' : 'Train'}</div>
                        <div>{event.travelNumber}</div>
                        <div className="text-small ">{carrier}</div>
                    </Col>
                )
            }

            {
                location2 && (
                    <Col style={{ paddingLeft: 40 }} sm={5}>
                        <div className="text-small">
                            <TranslationText
                                dictionaryKey={"roadshow.arrive"}
                                defaultText={"Arrive"}
                            />
                            &nbsp;
                            {location2.city}
                        </div>
                        <div style={spacingLg} className="text-bold text-univ-bd">
                            {ValueFormatters.dateFormatter('zoneTime', event.endDateLocal || '--')}
                            <span style={{ fontWeight: 400 }} className="text-univ-lt"> {event.endTimeZone}</span>
                        </div>
                        <div style={spacingLg}>{location2.place}</div>
                        <div className="text-small ">{location1.roomNumber}</div>
                        <div className="text-small ">{location2.addressLine1}</div>
                        <div className="text-small ">{location2.addressLine2}</div>
                        <div className="text-small ">{location2.addressLine3}</div>
                        <div className="text-small ">{location2.addressLine4}</div>
                        <div className="text-small ">{location2.telephone}</div>
                        {/* <div className="text-small ">Terminal --</div> */}

                    </Col>
                )
            }
            {
                event.notes && (<CoordinatorComments comments={event.notes} />)
            }
        </Row>
    )

    // Car
    const travelCar = (location1, location2, driver, carrier) => (
        <Row className="event-place text-univ-lt text-medium">
            {location1 && (<Col sm={6}>
                <div className="text-small">
                    <TranslationText
                        dictionaryKey={"roadshow.pickup_location"}
                        defaultText={"Pickup Location"}
                    />
                </div>
                <div style={spacingSm}>{location1.place}</div>
                <div className="text-small ">{location1.roomNumber}</div>
                <div className="text-small ">{location1.addressLine1}</div>
                <div className="text-small ">{location1.addressLine2}</div>
                <div className="text-small ">{location1.addressLine3}</div>
                <div className="text-small ">{location1.addressLine4}</div>

                {dealType && dealType === "DCM" && (
                    <div className="text-small ">
                        {location1.city && (location1.city + ', ')}
                        {location1.country}
                    </div>
                )}
            </Col>
            )}
            {location2 && (<Col sm={6}>
                <div className="text-small">
                    <TranslationText
                        dictionaryKey={"roadshow.drop_off_location"}
                        defaultText={"Drop off Location"}
                    />
                </div>
                <div style={spacingSm}>{location2.place}</div>
                <div className="text-small ">{location2.roomNumber}</div>
                <div className="text-small ">{location2.addressLine1}</div>
                <div className="text-small ">{location2.addressLine2}</div>
                <div className="text-small ">{location2.addressLine3}</div>
                <div className="text-small ">{location2.addressLine4}</div>

                {dealType && dealType === "DCM" && (
                    <div className="text-small ">
                        {location2.city && (location2.city + ', ')}
                        {location2.country}
                    </div>
                )}
            </Col>)}

            <Col sm={12}>
                <div className="event-details-divider" style={{ marginBottom: 8, marginTop: 8 }}></div>
            </Col>

            {driver && (
                <Col sm={6}>
                    <div style={spacingSm} className="text-small">
                        <TranslationText
                            dictionaryKey={"roadshow.driver"}
                            defaultText={"Driver"}
                        />
                    </div>
                    <div>{driver.name}</div>
                    <div>{driver.mobileTelephone && `Tel: ${driver.mobileTelephone}`}</div>
                </Col>
            )}
            {carrier && (
                <Col sm={6}>
                    <div style={spacingSm} className="text-small">
                        <TranslationText
                            dictionaryKey={"roadshow.car_company"}
                            defaultText={"Car Company"}
                        />
                    </div>
                    <div>{carrier}</div>
                    {/* <div>{driver.directTelephone && `Tel: ${driver.directTelephone}`}</div> */}
                </Col>
            )}
            {event.notes &&
                (<CoordinatorComments comments={event.notes} />)
            }
        </Row>
    )

    // Hotel
    const travelHotel = (location1) => (
        <Row className="event-place text-univ-lt text-medium">
            {location1 && (<Col sm={12}>
                <div style={spacingSm}>{location1.place}</div>
                <div className="event-detail-address">
                    <div className="text-small ">{location1.roomNumber}</div>
                    <div className="text-small ">{location1.addressLine1}</div>
                    <div className="text-small ">{location1.addressLine2}</div>
                    <div className="text-small ">{location1.addressLine3}</div>
                    <div className="text-small ">{location1.addressLine4}</div>
                    {dealType && dealType === "DCM" && (
                        <div className="text-small ">
                            {location1.city && (location1.city + ', ')}
                            {location1.country}
                        </div>
                    )}

                    {location1.telephone && (
                        <div className="text-small ">{location1.telephone}</div>
                    )}
                </div>
            </Col>
            )}


            {event.notes &&
                (<CoordinatorComments comments={event.notes} />)
            }
        </Row>
    )

    // This is used for unknown events that contain 2 locations in data
    const travelUnknown = (location1, location2) => (
        <Row className="event-place text-univ-lt text-medium">
            {location1 && (<Col style={{ paddingRight: 30 }} sm={6}>
                <div className="text-small">
                    <TranslationText
                        dictionaryKey={"roadshow.depart"}
                        defaultText={"Depart"}
                    />
                    &nbsp;
                    {location1.city}
                </div>
                <div style={spacingLg} className="text-bold text-univ-bd">
                    {ValueFormatters.dateFormatter('zoneTime', event.startDateLocal || '--')}
                    <span style={{ fontWeight: 400 }} className="text-univ-lt"> {event.startTimeZone}</span>
                </div>
                <div style={spacingLg}>{location1.place}</div>
                <div className="text-small ">{location1.roomNumber}</div>
                <div className="text-small ">{location1.addressLine1}</div>
                <div className="text-small ">{location1.addressLine2}</div>
                <div className="text-small ">{location1.addressLine3}</div>
                <div className="text-small ">{location1.addressLine4}</div>

                {dealType && dealType === "DCM" && (
                    <div className="text-small ">
                        {location1.city && (location1.city + ', ')}
                        {location1.country}
                    </div>
                )}
            </Col>)}
            {location2 && (<Col style={{ paddingLeft: location1 ? 40 : 15 }} sm={location1 ? 6 : 12}>
                <div className="text-small">
                    <TranslationText
                        dictionaryKey={"roadshow.arrive"}
                        defaultText={"Arrive"}
                    />
                    &nbsp;
                    {location2.city}
                </div>
                <div style={spacingLg} className="text-bold text-univ-bd">
                    {ValueFormatters.dateFormatter('zoneTime', event.endDateLocal || '--')}
                    <span style={{ fontWeight: 400 }} className="text-univ-lt"> {event.endTimeZone}</span>
                </div>
                <div style={spacingLg}>{location2.place}</div>
                <div className="text-small ">{location2.roomNumber}</div>
                <div className="text-small ">{location2.addressLine1}</div>
                <div className="text-small ">{location2.addressLine2}</div>
                <div className="text-small ">{location2.addressLine3}</div>
                <div className="text-small ">{location2.addressLine4}</div>

                {dealType && dealType === "DCM" && (
                    <div className="text-small ">
                        {location2.city && (location2.city + ', ')}
                        {location2.country}
                    </div>
                )}

            </Col>)}

            {event.notes &&
                (<CoordinatorComments comments={event.notes} />)
            }
        </Row>
    )

    let logisticsSections = [];

    switch (eventType.eventTypeId) {
        case 0:
            logisticsSections.push(
                travelUnknown(event.location1, event.location2)
            )
            break
        case 8:
        case 10:
            logisticsSections.push(
                travelWithIcon(event.location1, event.location2, event.carrier)
            )
            break
        case 9:
            // Multi-leg - how do we get more than one flight section out of the data?
            logisticsSections.push(
                travelWithIcon(event.location1, event.location2, event.carrier),
                travelWithIcon(event.location1, event.location2, event.carrier)
            )
            break
        case 11:
            logisticsSections.push(
                travelCar(event.location1, event.location2, driver, event.carrier)
            )
            break
        case 12:
            logisticsSections.push(
                travelHotel(event.location1)
            )
            break
        default :
          console.log(`Invalid eventTypeId in LogisticsDetails ${eventType.eventTypeId}`)
    }





    return (
        <Row>
            <Col sm={12}>
                {logisticsSections.map((section, ind) => { return <div key={ind}>{section}</div> })}
            </Col>
        </Row>
    )

}

export default LogisticsDetails
