import React from 'react';
import { Router, Route, IndexRoute, browserHistory, IndexRedirect } from 'react-router';

import './shared/styles/application.scss';

import dealFeatures from 'constants/dealFeatures';
import isvNavLinks from 'Isv/constants/isvNavLinks';
import { featurePageIds } from 'constants/featurePages';

// Roadshow
import Roadshow from './Roadshow/Roadshow.routing';
import DocumentMode from './Roadshow/DocumentMode/DocumentMode.container';
import DailySchedule from './Roadshow/DailySchedule/DailySchedule.container';
// Orders
import OrderBook from './OrderBook/OrderBook.routing';
import FullOrderBook from './OrderBook/FullOrderBook.container';
import OrderSummary from './OrderBook/OrderSummary.container';
import AmendedSummary from './OrderBook/dcm/AmendedSummary/AmendedSummary.container';
import BookEvolution from './OrderBook/dcm/BookEvolution/BookEvolution.container';
// Landing Page
// import HomeContainer from './Home/HomeContainer';
import LandingContainer from './Landing';
import DealInformationContainer from './Isv/components/DealInformation/DealInformation';
import ReferenceInformationContainer from './Isv/components/ReferenceInformation/ReferenceInformation';
import EcbToReturnContainer from './Isv/components/EcbToReturn/EcbToReturn';
// My Docs
import MyDocs from './MyDocs/MyDocs.routing';
import DocumentsContainer from './MyDocs/Documents/Documents.container';
// Investor Feedback
import InvestorFeedback from './InvestorFeedback/InvestorFeedback.routing';
import DetailsContainer from './InvestorFeedback/Details/Details.container';
import SummaryContainer from './InvestorFeedback/Summary/Summary.container';
// Pricing
import Pricing from './Pricing/Pricing.routing';
import PricingDocuments from './Pricing/PricingDocuments.container';
// Settins
import Settings from './Settings/Settings';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';
import App from './components/App';
import DataPrivacy from './components/DataPrivacy';

const privacyUrl = {
  pathname: featurePageIds.DATAPRIVACY_NOTICE_PAGE_EXTERNAL,
};
const cookieUrl = {
  pathname: featurePageIds.COOKIE_NOTICE_PAGE_EXTERNAL,
};

const Root = () =>
  <Router history={browserHistory}>
      <Route path="/privacynotice" component={()=><DataPrivacy siteTextType={SITE_TEXT_TYPES.PRIVACY} url={privacyUrl}></DataPrivacy>}></Route>
      <Route path="/cookienotice" component={()=><DataPrivacy siteTextType={SITE_TEXT_TYPES.C_POLICY} url={cookieUrl}></DataPrivacy>}></Route>
      <Route path={`${dealFeatures.SETTINGS}/:routeValue`} component={DataPrivacy} />
      <Route path="/" component={App}>
        <IndexRoute component={LandingContainer} />
      <Route path={dealFeatures.SETTINGS} component={Settings} />

      <Route path={isvNavLinks.DEAL_INFORMATION} component={DealInformationContainer} />
      <Route path={isvNavLinks.REFERENCE_INFORMATION} component={ReferenceInformationContainer} />
      <Route path={isvNavLinks.ECB_TO_RETURN} component={EcbToReturnContainer} />

      <Route path={dealFeatures.ORDERBOOK} component={OrderBook}>
        <IndexRedirect to={featurePageIds.ORDERBOOK_SUMMARY_PAGE} />
        <Route
          path="summary"
          component={OrderSummary}
        />
        <Route
          path="full"
          component={FullOrderBook}
        />
        <Route
          path="amended"
          component={AmendedSummary}
        />
        <Route
          path="evolution"
          component={BookEvolution}
        />
      </Route>

      <Route path={dealFeatures.ROADSHOW} component={Roadshow}>
        <IndexRedirect to={featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE} />
        <Route
          path="dailySchedule"
          component={DailySchedule}
        />
        <Route
          path="documentMode"
          component={DocumentMode}
        />
      </Route>

      <Route path={dealFeatures.MY_DOCS} component={MyDocs}>
        <IndexRoute component={DocumentsContainer} />
      </Route>

      <Route path={dealFeatures.PRICING} component={Pricing}>
        <IndexRoute component={PricingDocuments} />
      </Route>

      <Route path={dealFeatures.INVESTOR_FEEDBACK} component={InvestorFeedback}>
        <IndexRedirect to={featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE} />
        <Route
          path="summary"
          component={SummaryContainer}
        />
        <Route
          path="details"
          component={DetailsContainer}
        />
      </Route>
    </Route>
  </Router>

export default Root;
