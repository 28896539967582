import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import ChartTableContainer from 'shared/components/ChartTableContainer';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';
import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

import {
    getInvestorFeedbackChartDataByChart,
    getSelectedChartSubTab
} from 'selectors';
import { selectChartSubTab } from 'actions/InvestorFeedbackActions';

import Buttons from './shared/Buttons';
import NoContent from './shared/NoContent';

import SentimentTables from './tables/SentimentTables';
import SentimentChart from './charts/SentimentChart';

const ChartWrapper = styled.div`
    padding: 0 40px;
`;

const legendKeyStyle = {
  paddingBottom: '10px',
};

class SentimentTabContent extends PureComponent {
    buttonClicked = (index, name) => {
        this.props.selectChartSubTab(index, name);
    }

    render() {
       const renderChart = (this.props.chartData[this.props.activeIndex] && (this.props.chartData[this.props.activeIndex].series.some(series => series.data.length > 0))) || false;

        if (renderChart) {
            return (
                <React.Fragment>
                    <Buttons
                      style={{marginRight: 250}}
                        activeIndex={this.props.activeIndex}
                        chartNames={this.props.chartNames}
                        buttonClicked={this.buttonClicked}
                    />
                    <h3 style={ legendKeyStyle }>
                      <TranslationText
                        dictionaryKey={"investor_feedback.sentiment_chart_title"}
                        replace={[
                          ["<sentiment_type>", translationString(mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]), this.props.language, mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]))]
                        ]}
                        defaultText={`Number of Investors For Theme where Investor Sentiment is ${translationString(mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]), this.props.language, mapThemeTypeOrSentimentToKey(this.props.chartNames[this.props.activeIndex]))}`}
                      />

                    </h3>
                    <ChartWrapper>
                      <ChartTableContainer
                        table={
                          <SentimentTables
                            key={this.props.chartNames[this.props.activeIndex]}
                            series={this.props.chartData[this.props.activeIndex].series}
                            sentimentType={this.props.chartNames[this.props.activeIndex]}
                          />}
                        buttonStyle={{
                          top: '61px',
                          right: '37px'
                        }}
                      >
                        <SentimentChart
                          chartData={this.props.chartData[this.props.activeIndex]}
                          keyTitle={this.props.chartNames[this.props.activeIndex]}
                        />
                      </ChartTableContainer>
                    </ChartWrapper>
                </React.Fragment>
            )
        }

        return <NoContent />;
    }
}

export default compose(
    withLanguage,
    connect(
        (state, ownProps) => {
            const chartData = getInvestorFeedbackChartDataByChart(state, `feedbackChartData${ownProps.chartDetails.chartId}`);
            return ({
                // sub tab handling
                activeIndex: getSelectedChartSubTab(state),
                // data
                chartData,
                chartNames: chartData.map(c => c.pageTitle),
            })
        },
        dispatch => ({
            selectChartSubTab: (index, title) => dispatch(selectChartSubTab(index, title)),
        }),
    ),
)(SentimentTabContent);
