export const simulateResize = () => {
    setTimeout(() => {
        if (typeof(Event) === 'function') {
            // modern
            window.dispatchEvent(new Event('resize'));
        } else {
            // IE
            const ev = window.document.createEvent('UIEvents');
            ev.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(ev);
        }
    }, 1);
}