import { call, put, select, cancel, fork, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { browserHistory } from 'react-router';

import dealFeatures from 'constants/dealFeatures';

import Types, { UtilityTypes } from 'actions/Types';
import IsvTypes, { IsvUtilityTypes } from '../../Isv/actions/IsvTypes';
import {
    dealPurge,
    requestDeal,
    startEventNotificationSaga,
    stopEventNotificationSaga,
    requestDealInfo,
    handleHomeFeature,
    baseFetchCompleted,
    checkFeature
} from 'actions/UtilityActions';
import {
    requestIsvDeal,
    apiFailure,
    acceptLetter
} from '../../Isv/actions/IsvUtilityActions';
import { create } from '../../services/api';

import { updateSelectedDay, setRoadshowTeam } from 'actions/RoadShowActions';

import { getUniqueName, getCurrentDealId } from 'selectors';

import { selectTeamFromLocalStorage } from 'util/teamSelection';
import { isInThatFeaturePage, selectAvailableNavigationFeatures, checkAllowedUiFeatures } from 'util/navigationFeatures';

import { featurePageIds } from 'constants/featurePages';

var count = 0;

function * triggerCheckFeatureIfOnFeaturePage(dealFeature, previousDealId) {
    const areWeOnThatFeaturePage = isInThatFeaturePage(dealFeature);
    if (areWeOnThatFeaturePage && previousDealId) {
        //
        // previous deal Id -> switching btw deals 
        // if null then its first deal setup (not needed to be handled)
        // we have a flag for base setup which covers feature checks 
        //
        yield put(checkFeature(dealFeature));
    }
}

export default (api) => {
    function* handleRequest(req, endPoint, params, success) {
        try {
            const response = yield call(req, endPoint, params);
            if (response.ok) {
                yield put(success(response.data));
            } else {
                yield put(apiFailure('Bad Response'));
            }
        } catch (error) {
            yield put(apiFailure('Bad call: ' + error));
        }
    }

    function* worker({ dealId, redirect }) {
        const previousDealId = yield select(getCurrentDealId);
        
        if (redirect) {
            browserHistory.push(featurePageIds.LANDING_PAGE);
        }

        yield put(dealPurge());

        yield put(requestDealInfo(dealId, true));
        yield put(requestDeal(dealId, true));

        yield put(stopEventNotificationSaga());

        const { 
            dealInfo:{
                allowedNavigationFeatures
            } 
        } = yield take(UtilityTypes.API_GET_DEAL_INFO_RESPONSE);

        const allowedUiFeatures = checkAllowedUiFeatures(allowedNavigationFeatures, 'visibleInMainMenu');

        // tricky solution for mounting cmp before base data arrival
        yield put(baseFetchCompleted(dealId));

        const allowedDealFeatures = selectAvailableNavigationFeatures(allowedUiFeatures);

        const redirectWhenOnNotAllowedPage = dealFeature => {
            const areWeOnThatFeaturePage = isInThatFeaturePage(dealFeature);
            if (areWeOnThatFeaturePage) {
                browserHistory.push(featurePageIds.LANDING_PAGE);
            }
        }

        if (allowedDealFeatures[dealFeatures.ROADSHOW]) {
            // cleanup
            yield put(updateSelectedDay(null));

            // team selection
            const uniqueName = yield select(getUniqueName);
            const team = selectTeamFromLocalStorage(dealId, uniqueName);

            if (team) {
                yield put(setRoadshowTeam(team));
            } else {
                yield put(setRoadshowTeam(null));
            }

            yield put(handleHomeFeature(dealFeatures.ROADSHOW, dealId));
            yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.ROADSHOW, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.ROADSHOW);
        }

        if (allowedDealFeatures[dealFeatures.INVESTOR_FEEDBACK]) {
            yield put(handleHomeFeature(dealFeatures.INVESTOR_FEEDBACK, dealId));
            yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.INVESTOR_FEEDBACK, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.INVESTOR_FEEDBACK);
        }

        if (allowedDealFeatures[dealFeatures.MY_DOCS]) {
            yield put(handleHomeFeature(dealFeatures.MY_DOCS, dealId));
            yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.MY_DOCS, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.MY_DOCS);
        }

        if (allowedDealFeatures[dealFeatures.ORDERBOOK]) {
           yield put(handleHomeFeature(dealFeatures.ORDERBOOK, dealId));
           yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.ORDERBOOK, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.ORDERBOOK);
        }

        if (allowedDealFeatures[dealFeatures.PRICING]) {
            yield put(handleHomeFeature(dealFeatures.PRICING, dealId));
            yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.PRICING, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.PRICING);
        }

        if (allowedDealFeatures[dealFeatures.INVESTORS]) {
            yield put(handleHomeFeature(dealFeatures.INVESTORS, dealId));
            yield triggerCheckFeatureIfOnFeaturePage(dealFeatures.INVESTORS, previousDealId);
        } else {
            redirectWhenOnNotAllowedPage(dealFeatures.INVESTORS);
        }

        yield put(startEventNotificationSaga());
    }

    function* isvWorker({ action, dealId, redirect }) {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        count = count + 1;
        if (redirect) {
            browserHistory.push(featurePageIds.LANDING_PAGE);
        }
        if (count === 1) {
            const requestIsvDealAction = requestIsvDeal(dealId, false);
            yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess);
            count = 0;
        }
    }

    let letterCounter =0;
    function* isvWorkerDeal({ action, dealId, redirect }) {
        if(letterCounter === 0)
        {
        const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
        const acceptOneWayLetter = acceptLetter(dealId, false);
        yield handleRequest(api.putData().req, acceptOneWayLetter.endPoint, {}, acceptOneWayLetter.onSuccess)
        const requestIsvDealAction = requestIsvDeal(dealId, false);
        yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
        letterCounter++;    
    }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.DEAL_SETUP,
        ], worker);

        const isvwatcherTasks = yield fork(takeEvery, [
            IsvUtilityTypes.API_REQUEST_ISV_DEAL_CLICK,
            IsvUtilityTypes.SETUP_ISV_DEAL
        ], isvWorker);

        const isvwatcherTasksDeal = yield fork(takeEvery, [
            IsvUtilityTypes.API_ACCEPT_LETTER_CLICK
        ], isvWorkerDeal)

        /* eslint-disable-next-line */
        yield fork(takeLatest, [Types.KILL_ACTIVE_SAGA_WORKERS], cancelWorkerSaga, watcherTasks);
        yield fork(takeLatest, [IsvTypes.KILL_ACTIVE_SAGA_WORKERS], cancelWorkerSaga, isvwatcherTasks);
        yield fork(takeLatest, [IsvTypes.KILL_ACTIVE_SAGA_WORKERS], cancelWorkerSaga, isvwatcherTasksDeal);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
        isvWorker
    };
};
