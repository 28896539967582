/*
 *
 * API creator v2 which handle cancel tokens
 * providing possibility to cancel not needed polling actions
 *
 */
import apisauce from 'apisauce';
import axios from 'axios';

export const create = baseURL => {
    const api = apisauce.create({
        baseURL,
        headers: {
            Accept: 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        },
        timeout: 600000,
    });

    const createCancelTokenSource = () => axios.CancelToken.source();
    const getBearerToken = () => window.sessionStorage.getItem('_mydeal_');

    const getData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };

    const getFileData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.get(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
                responseType: 'blob',
            }),
            cancelReq: source.cancel,
        };
    };

    const postFileData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters, onProgress) => api.post(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
                onUploadProgress: onProgress
            }),
            cancelReq: source.cancel,
        };
    };

    const deleteData = () => {
        const source = createCancelTokenSource();
        return {
            req: (endPoint, parameters) => api.delete(endPoint, parameters, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
                cancelToken: source.token,
            }),
            cancelReq: source.cancel,
        };
    };

    return {
        getData,
        getFileData,
        postFileData,
        deleteData,
        api,
    };
};

export default {
    create,
};
