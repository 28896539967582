/*
 *  (?)
 */
const CONFIGURATION = 'Configuration';
/*
 * Any changes to new Deals being permissioned. Currently removal of deal access is not required and not considered priority for this release
 */
const DEAL_ACCESS = 'DealAccess';
/*
 * Any changes within the deal including Deal Navigation Feature toggle  changes
 */
const DEAL = 'Deal';

export default {
    CONFIGURATION,
    DEAL_ACCESS,
    DEAL,
};
