import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import { compose } from 'recompose';
import { Link } from 'react-router';
import MDSpinner from 'react-md-spinner';

import { PrimaryButton, SecondarySlateButton } from 'mk-pattern-library-react';

import LanguagePicker from './LanguagePicker/LanguagePicker';
import TranslationText from 'translations/TranslationText';

import logo from '../mk-pattern-library-react/images/HSBC_Logo.svg';

import { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

import { events } from 'constants/analytics';
import withAnalytics from 'shared/hoc/withAnalytics';

import { selectLoginFormInvalidFields } from 'selectors';

import { featurePageIds } from 'constants/featurePages';

import {
  loginFormChanged,
  loginFormSubmit,
  setInvalidFields,
} from 'actions/LoginActions';

import { typography } from 'shared/styles/variables/theme';

import {
  Logo,
  LoginContainer,
  InnerContainer,
  SecurityKeyInfo,
  LoginForm,
  Header,
  LoginFormFields,
  StaffIdContainer,
  PContainer,
  ButtonContainer,
  StyledIntInput,
  List
} from './styles/Login';

const EnhancedSecondaryButton = styled(SecondarySlateButton)`
  min-width: 170px;
  width: auto !important;
`;

const LanguagePickerContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 10px;
`;

const loginBtnStyle = {
    fontSize: typography.sizes['16px'],
    padding: '14px 20px',
    color: '#FFFFFF',
    border: 'none',
    width: 170,
};

export const TandCsContainer = styled.div`
  position: relative;
  top: 63%;
  height: 0;
  float: right;
`;

class InternalLogin extends Component {
  constructor(props) {
    super(props);

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onStaffIdChange = this.onStaffIdChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);

    this.userNameField = React.createRef();
    this.passwordField = React.createRef();
  }

  componentDidMount() {
    this.props.viewChange();
    this.props.setInvalidFields({
      userNameField: true,
      passwordField: true,
    });
    this.userNameField.current.focus();
  }

  onSubmitClick() {
    this.props.loginFormSubmit(this.userNameField.current.value, this.passwordField.current.value);
    this.props.loginSubmit();
  }

  onStaffIdChange(e) {
    this.props.loginFormChanged('userNameField', this.userNameField.current.value);
  }

  onPasswordChange(e) {
    this.props.loginFormChanged('passwordField', this.passwordField.current.value);
  }

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitClick();
    }
  }

  render() {
    return (
      <LoginContainer role="main" className="internal-login">
        <Navbar style={{ height: 120.5 }}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to={'/'}><Logo src={logo} alt="HSBC" />MyDeal</Link>
            </Navbar.Brand>
            <LanguagePickerContainer>
              <LanguagePicker />
            </LanguagePickerContainer>
          </Navbar.Header>
        </Navbar>
        <InnerContainer>
          <SecurityKeyInfo>
            <Header>
              <h2>Support Information</h2>
            </Header>
            <List>
              <li>
                Please contact MyDeal Support via <a href="mailto:mydealsupport@hsbc.com">email</a>.
              </li>
            </List>
          </SecurityKeyInfo>
          <LoginForm>
            <Header>
              <h2>
                <TranslationText
                  dictionaryKey={"login_screen.log_on_to_hsbc_mydeal"}
                  defaultText={"Logon to HSBC MyDeal"}
                />
              </h2>
            </Header>
            <div style={{color: 'red'}}>
            { this.props.authenticationResponse
              ? this.props.authenticationResponse
              : null
            }
            </div>
            <LoginFormFields>
              <StaffIdContainer>
                <label htmlFor="username">
                  <TranslationText
                    dictionaryKey={"login_screen.username"}
                    defaultText={"Username"}
                  />
                </label>
                <StyledIntInput
                  id="username"
                  name="username"
                  ref={this.userNameField}
                  type="text"
                  autoComplete="false"
                  onChange={this.onStaffIdChange}
                  onKeyDown={this.onKeyDown}
                />
              </StaffIdContainer>
              <PContainer>
                <label htmlFor="password">
                  <TranslationText
                    dictionaryKey={"login_screen.password"}
                    defaultText={"Password"}
                  />
                </label>
                <StyledIntInput
                  id="password"
                  name="password"
                  ref={this.passwordField}
                  type="password"
                  autoComplete="false"
                  onChange={this.onPasswordChange}
                  onKeyDown={this.onKeyDown}
                />
              </PContainer>
            </LoginFormFields>
            <ButtonContainer>
              <EnhancedSecondaryButton
                size="normal"
                type="button"
                style={loginBtnStyle}
                onClick={this.props.attemptSSO}
              >
                {
                  this.props.isPendingAuth
                    ? <MDSpinner size={20} singleColor={'#D8D8D8'} style={{ marginRight: 10, bottom: 2}} /> : null
                }
                {translationString("login_screen.single_sign_on", this.props.language, 'Single Sign On')}
              </EnhancedSecondaryButton>
              <PrimaryButton
                size="normal"
                type="submit"
                loading={'true'}
                onClick={this.onSubmitClick}
                style={loginBtnStyle}
              >
                {
                  this.props.pendingAuthentication
                    ? <MDSpinner size={20} singleColor={'#D8D8D8'} style={{ marginRight: 10, bottom: 2}} /> : null
                }
                {translationString("login_screen.log_on", this.props.language, 'Log on')}
              </PrimaryButton>
            </ButtonContainer>
          </LoginForm>
        </InnerContainer>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  invalid: selectLoginFormInvalidFields(state).length > 0,
  attemptSSO: props.attemptSSO,
  unauthorised: state.login.error,
  isPendingAuth: state.login.isPendingAuth, // SSO
  pendingAuthentication: state.login.pendingAuthentication, // Form
  authenticationResponse: state.login.authenticationResponse,
  pristine: state.login.pristine,
});

const mapDispatchToProps = dispatch => ({
  loginFormChanged: (fieldName, fieldValue) => {
    dispatch(loginFormChanged(fieldName, fieldValue));
  },
  loginFormSubmit: (userNameField, passwordField) => {
    dispatch(loginFormSubmit(userNameField, passwordField));
  },
  setInvalidFields: (fields) => {
    dispatch(setInvalidFields(fields));
  }
});

export default compose(
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.INTERNAL_LOGIN_PAGE),
    loginSubmit: () => ev(events.LOGIN_BUTTON),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withLanguage,
)(InternalLogin);
