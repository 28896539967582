import React from 'react';
import { Grid } from 'react-bootstrap';
import NavMain from '../NavMain/NavMain';

import Scrollable from 'shared/components/Scrollable';

import '../../styles/application.scss';

const CONTAINER_STYLE = {
  position: 'absolute',
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
}

const FLUID_STYLE = {
  padding: 0,
  minHeight: 'calc(100% - 100px)',
}

class AppContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: window.innerHeight,
    };

    this.updateHeight = this.updateHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateHeight);
    window.addEventListener("orientationChange", this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
    window.removeEventListener("orientationChange", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  render() {
    const { props } = this;
    return (
      <Scrollable scrollHeight={this.state.height}>
        <div onClick={props.detectUserInteraction} style={ CONTAINER_STYLE }>
            <NavMain
              logout={props.logout}
              links={props.links}
              user={props.user}
              company={props.company}
              project={props.project}
              popoverElement={props.popoverElement}
            />
            <Grid
              role="main"
              fluid={props.fluid}
              className="mydeal-app-content"
              style={props.fluid ? FLUID_STYLE : null}
            >
              {props.children}
            </Grid>
        </div>
      </Scrollable>
    );
  }
}

export default AppContainer;

AppContainer.defaultProps = {
  links: [{ url: '/', title: 'MyDeal' }],
};
