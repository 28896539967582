import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import PopupSystem from 'shared/components/PopupSystem';
import Popup, { Divider } from 'shared/components/Popup';
import Scrollable from 'shared/components/Scrollable';
import { Checkbox } from 'mk-pattern-library-react';

import TranslationText from 'translations/TranslationText';
import { getSelectedLanguage, getTermsAndConditionsText } from 'selectors';

import { acceptTermsAndConditions, onTermsCheckedChange, requestTermsAndConditions  } from 'actions/LoginActions'

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    ${Divider} {
        display: block;
        margin: 1rem 0;
    }
`;

const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
`;

const actionBarControlsStyle = {
  margin: '10px 10px 25px 11px'
};

const AcceptTermsCheckbox = props =>
  <Fragment>
    <Checkbox label={
      <TranslationText
        dictionaryKey="login.i_agree_to_the_terms"
        defaultText="I agree to the terms and conditions"
      />}
      onCheckedChange={props.onCheckedChange}
      checked={props.checked}
    />
  </Fragment>

class TermsAndConditionsModal extends React.PureComponent {

  componentDidMount = () => {
    this.props.requestTermsAndConditions(this.props.langCode);
  }

  componentWillUnmount = () => {
    this.props.onTermsCheckedChange(false);
  }

  acceptTermsAndConditions = () => {
    this.props.acceptTermsAndConditions(true, this.props.termsAndConditionsId);
  }

  cancelTermsAndConditions = () => {
    this.props.acceptTermsAndConditions(false);
  }

  onTermsCheckedChange = checked => {
    this.props.onTermsCheckedChange(checked);
  }

  render() {
    return (
      <PopupSystem
        centered
        backgroundLayer
        modalID={'terms-modal'}
        isModalOpen={true}
        closeIcon
        closeModal={this.cancelTermsAndConditions}

        content={
          <Popup
              static
              width={1250}
              primaryButton
              primaryButtonContent="Accept"
              primaryButtonAction={this.acceptTermsAndConditions}
              primaryButtonKey="login.accept"
              primaryButtonDisabled={!this.props.acceptTermsChecked}
              secondaryButton
              secondaryButtonContent="Decline"
              secondaryButtonAction={this.cancelTermsAndConditions}
              secondaryButtonKey="login.decline"
              actionBarControls={
                <AcceptTermsCheckbox
                  onCheckedChange={this.onTermsCheckedChange}
                  checked={this.props.acceptTermsChecked}
                />
              }
              actionBarControlsStyle={actionBarControlsStyle}
          >
              <ModalContent>
                  <Scrollable
                      scrollHeight={ window.innerHeight * .5 }
                  >
                      <ScrollableContent>
                        <div dangerouslySetInnerHTML={{__html: this.props.termsAndConditionsText}} />
                      </ScrollableContent>
                  </Scrollable>
              </ModalContent>
          </Popup>
        }
      />
    )
  }
}

export default connect(
  state => ({
    acceptTermsChecked: state.login.acceptTermsChecked,
    langCode: getSelectedLanguage(state),
    termsAndConditionsText: getTermsAndConditionsText(state),
    termsAndConditionsId: state.login.termsAndConditions[getSelectedLanguage(state).toLowerCase()] && state.login.termsAndConditions[getSelectedLanguage(state).toLowerCase()].termsAndConditionsId,
  }),
  dispatch => ({
    requestTermsAndConditions: langCode => {
      dispatch(requestTermsAndConditions(langCode))
    },
    onTermsCheckedChange: checked => {
      dispatch(onTermsCheckedChange(checked));
    },
    acceptTermsAndConditions: (accepted, termsAndConditionsId) => {
      dispatch(acceptTermsAndConditions(accepted, termsAndConditionsId ));
    },
  })

)(TermsAndConditionsModal);
