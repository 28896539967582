import { buffers, channel } from 'redux-saga'
import { cancel, fork, takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import IsvTypes from 'Isv/actions/IsvTypes';
import { create } from 'Isv/services/isvApi';
import { setUploadProgress, handleOpenUploadOverlay, getECB2ReturnDataForCurrentDeal  } from 'Isv/actions/IsvDocumentsAction';
import { requestIsvDeal, apiFailure, dataFetching } from 'Isv/actions/IsvUtilityActions';
import { setSnackBar } from 'Isv/actions/SnackBarAction'
import { getIsvCurrentDealId } from "Isv/selectors";

const downloadFileChannel = channel(buffers.expanding())

export default (api, dispatch, getState) => {

    function* worker(action) {

        function* handleRequest(req, endPoint, params, success) {
            try {
                const response = yield call(req, endPoint, params);
                response.ok ? yield put(success(response.data)) : yield put(apiFailure('Bad Response'));
            } catch (error) {
                yield put(apiFailure('Bad call: ' + error));
            }
        }

        switch (action.type) {
            case IsvTypes.API_REQUEST_DOWNLOAD_ALL_DOCUMENTS:
            case IsvTypes.API_REQUEST_DOWNLOAD_DOCUMENT: {
                yield put(dataFetching(true))
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.getFileData();
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    let blobType;
                    let isTitleWithExtensionUpdated = false;
                    const { fileName, title } = action.params;
                    let titleWithExtension = title;
                    const extension = fileName.split('.').pop().toLocaleLowerCase();
                    if(extension !== null && extension !== '')
                    {
                        blobType = extension;
                        titleWithExtension = `${title}.${extension}`;
                        isTitleWithExtensionUpdated = true;
                    }
                    else{
                            blobType = '';
                    }
                    const blobData = new Blob([response.data], { type: blobType });

                    if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
                        window.navigator.msSaveOrOpenBlob(blobData, titleWithExtension);
                    } else {
                        const wind = window.URL || window.webkitURL;
                        const objectUrl = wind.createObjectURL(blobData);

                        let anchor;

                        if(isTitleWithExtensionUpdated)
                        {
                            anchor = document.createElement('a');
                            anchor.href = objectUrl;
                            anchor.download = titleWithExtension;
                                document.getElementsByTagName('body')[0].appendChild(anchor);
                                anchor.click();
                                document.getElementsByTagName('body')[0].removeChild(anchor);
                        }
                        setTimeout(() => { wind.revokeObjectURL(objectUrl); }, 0);
                    }
                }
                yield put(dataFetching(false))
                break
            }

            case IsvTypes.API_REQUEST_UPLOAD_DOCUMENTS: {
                const currentDealId = yield select(getIsvCurrentDealId);
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.postFileData();

                function* onProgress(progressEvent) {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)
                    yield put(dispatch(setUploadProgress(percent)))
                }
                const response = !action.isTitleAlreadyExist ? yield call(apiType.req, action.endPoint, action.data, (progress) => downloadFileChannel.put(onProgress(progress).next())):null
                if (response && response.ok) {
                    yield put(handleOpenUploadOverlay(false, null, null, false, false));
                    if (action.isEcbDocument) {
                        yield put(dispatch(getECB2ReturnDataForCurrentDeal(currentDealId)));     
                    } else {
                        const requestIsvDealAction = requestIsvDeal(currentDealId, false);
                        yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
                    }
                }
                else {
                    yield put(setSnackBar(action.isTitleAlreadyExist? 'This Title already exists in Deal Information table, please use the upload icon in the Upload column next to the Upload Date to upload this file or enter a different Title.': response.data))
                    yield put(handleOpenUploadOverlay(false, null, null, false, false))
                }
                break
            }

            case IsvTypes.API_REQUEST_DELETE_SUPPORTING_DOCUMENTS: {
                const currentDealId = yield select(getIsvCurrentDealId);
                const api = create(process.env.REACT_APP_ISV_DEFAULT_API);
                const apiType = api.deleteData();

                const response = yield call(apiType.req, action.endPoint)
                if (response.ok) {
                    const requestIsvDealAction = requestIsvDeal(currentDealId, false);
                    yield handleRequest(api.getData().req, requestIsvDealAction.endPoint, {}, requestIsvDealAction.onSuccess)
                } else {
                    yield put(setSnackBar(response.data))
                }
                break;
            }

            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            IsvTypes.API_REQUEST_DOWNLOAD_DOCUMENT,
            IsvTypes.API_REQUEST_UPLOAD_DOCUMENTS,
            IsvTypes.API_REQUEST_DELETE_SUPPORTING_DOCUMENTS,
            IsvTypes.API_REQUEST_DOWNLOAD_ALL_DOCUMENTS,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, IsvTypes.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
