import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { SvgIcon } from 'shared/components/Icon';
import { Icon } from "mk-pattern-library-react";
import { typography } from 'shared/styles/variables/theme';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { extendedColorsForLineChart } from 'mk-pattern-library-react/utils/visualisationColors';
import { dateFormatter } from 'OrderBook/SharedTableComponents/formatters';
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import {
    getIsvCurrentDealId,
    isIsvExternalUser,
    selectLatestCommentsDataForCurrentComment,
    selectIsCommentPopUpOpen,
    selectIsvRejectButtonLabel,
} from "Isv/selectors";
import {
    handlePostDocumentComment,
    getLatestCommentsByCommentId,
    handleGetLatestCommentsByCommentsId,
    handleCommentsPopup,
    handleSubmitDealInformation,
} from 'Isv/actions/IsvDocumentsAction';
import { PrimaryButton } from 'mk-pattern-library-react';
import { primaryBtnStyle } from 'Isv/styles/buttonStyle';
import {
    NEXT_ACTION_OWNER,
    REJECTED_STATUS
  } from 'Isv/constants/isvDocumentReviewStatus'

const CommentsWrapper = styled.div`
    position: relative;
`

const CommentsInnerWrapper = styled.div`
    padding: 20px 20px 30px;
    position: relative;
`

const CloseIcon = styled.span``

const CommentsHeader = styled.div`
    color: #333333;
    font-size: ${typography.sizes['20px']};
    margin-bottom: 20px;
`

const CommentsContainer = styled.div`
    max-height: 210px;
    overflow-y: auto;
    margin-bottom: 10px;
`

const TextFieldContainer = styled.form`
    .MuiFormControl-root {
        width: 100%;
        label {
            background: #fff;
            width: 24px;
            padding-left: 3px;
            top: -4px;
            height: 17px;
        }
        .MuiInputBase-root {
            padding-top: 0;
            input {
                padding-top: 6px !important;
            }
        }
        #comment-to-user {
            font-size: 14px;
            padding: 0;
        }
        .MuiAutocomplete-endAdornment {
            button {
                margin-top: -2px;
            }
        }
        fieldset {
            legend { display: none }
        }
        &.comments-text-field {
            width: 100%;
            margin-top: 10px;
            input { padding: 8px; font-size: ${typography.sizes['14px']} }
            fieldset {
                top: 0;
            }
        }
    }

    button {
        margin-top: 20px;
        display: block;
        margin-left: auto;
    }
`

const LatestComment = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`

const LatestCommentValue = styled.div`
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const CommentValue = styled.span`
    margin-left: 10px;
    border: 2px solid ${(props) => props.color};
    border-radius: 5px;
    padding: 3px 10px 15px;
    position: relative;
    min-width: 130px;
`

const CommentDateWrapper = styled.span`
    position: absolute;
    right: 4px;
    font-size: 8px;
    bottom: 4px;
`

const closeIconStyle = {
    position: 'absolute',
    right: '15px',
    top: '15px',
    cursor: 'pointer'
}

const DealInformationCommentBoxContainer = ({
    dealId,
    login,
    isIsvExternalUser,
    isIsvCsmUser,
    isIsvRRTeamUser,
    title,
    selectedDealData,
    handlePostDocumentComment,
    lastComment,
    getLatestCommentsByCommentId,
    commentData,
    handleGetLatestCommentsByCommentsId,
    isRejectButtonClick,
    onCloseDialog,
    isAdditionalComment,
    isCommentsPopupOpen,
    handleCommentsPopup,
    rejectLabel,
    onRejectCommentPosted,
    selectedLastCommentId,
    handleSubmitDealInformation,
    rejectToggleClicked,
    fromEcb2Return,
    commentRow
}) => {

    const userRoles = [
        {
            label: 'Please select an option',
            value: '',
        },
        {
            label: isIsvCsmUser || isIsvRRTeamUser ? 'Client' : 'CSM',
            value: isIsvCsmUser || isIsvRRTeamUser ? 'client' : 'csm',
        },
        {
            label: isIsvRRTeamUser ? 'CSM' : 'RR',
            value: isIsvRRTeamUser ? 'csm' : 'rr',
        }
    ];

    const arrowDropdownIconStyle = {
        transform: 'rotate(90deg)',
        marginTop: '2px',
        width: '24px',
        height: '20px',
    }

    const [comment, setComment] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [userSelection, setUserSelection] = useState(userRoles[0]);
    const [latestComment, setLatestComment] = useState(null)

    useEffect(() => {
        if (isIsvCsmUser) {
          setUserSelection(userRoles[0]);
        }
    }, [rejectToggleClicked]);
    
    useEffect(() => {
        if (lastComment) {
            let latestComment = JSON.parse(lastComment.commentText);
            if (isIsvExternalUser && latestComment) {
                latestComment.Text = latestComment.Text.replace('To Client: ', '');
              }
            setLatestComment(latestComment)
        }
    
    }, [lastComment]);

    useEffect(() => {
        if(isRejectButtonClick){ 
            setIsOpen(true);
        }
        else {
            handleCloseDialog();
        }
    },[isRejectButtonClick])

    const handleEditComments = () => {
        setIsOpen(true);
        handleGetLatestCommentsByCommentsId(null);
        lastComment && lastComment.commentId && getLatestCommentsByCommentId(lastComment.commentId, isIsvExternalUser ? true : null, fromEcb2Return)
    }

    const getuserKey = (role) => {
        switch (true) {
            case role === NEXT_ACTION_OWNER.CSM: return 1;
            case role === NEXT_ACTION_OWNER.RR_TEAM: return 2;
            default: return 3
        }
    }

    const getFullNameInitals = (comment) => {
        let getUserName = comment.UserName;
        if (isIsvExternalUser) {
            if (comment.From === NEXT_ACTION_OWNER.CSM || (fromEcb2Return && comment.From === NEXT_ACTION_OWNER.RR_TEAM)) getUserName = NEXT_ACTION_OWNER.HSBC
        } else getUserName = comment.From === NEXT_ACTION_OWNER.CLIENT ? comment.UserName : comment.From;
        const name = getUserName.split(' ');
        const initials = name.length > 1 ? (getUserName === NEXT_ACTION_OWNER.RR_TEAM ? name[0] : name.shift().charAt(0) + name.pop().charAt(0)) : comment.From === NEXT_ACTION_OWNER.CLIENT ? name.pop().charAt(0) : name[0];
        const color = extendedColorsForLineChart(getuserKey(comment.From), comment.From);
        return (
            <LatestComment>
                <span style={{
                    background: color,
                    padding: '5px',
                    borderRadius: '50%',
                    color: '#fff',
                    maxWidth: '35px',
                    width: '100%',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px'
                }}>
                    {initials.toUpperCase()}
                </span>
                <CommentValue color={color}>
                    {comment.Text}
                    <CommentDateWrapper>{dateFormatter('dayMonthYearZoneShort', comment.Cdate)}</CommentDateWrapper>
                </CommentValue>
            </LatestComment>
        )
    }

    const handleCommentSubmit = () => {
        const isClientVisible = (fromEcb2Return && isIsvRRTeamUser && isRejectButtonClick) || 
        (userSelection.value === "client" || isIsvExternalUser || 
        (rejectLabel && rejectLabel !== REJECTED_STATUS.REJECTED_BY_RR) || 
        ( isIsvCsmUser && rejectToggleClicked && rejectToggleClicked === 'reject')
        ) ? true : false;
        const postData = {
            commentId: isRejectButtonClick ? selectedLastCommentId : (lastComment && lastComment.commentId) || 0,
            dealId: selectedDealData.dealId,
            title: title,
            comment: {
                text: `${userSelection.value !== '' ? 'To ' + userSelection.label + ': ' : ''}${comment}`,
                dateTime: new Date(),
                userName: login.userName,
                isClientVisible: isClientVisible,
                IsECBReturnVisible: fromEcb2Return ? true : false,
                from: isIsvCsmUser ? NEXT_ACTION_OWNER.CSM : isIsvRRTeamUser ? NEXT_ACTION_OWNER.RR_TEAM : NEXT_ACTION_OWNER.CLIENT
            },
            isClientScreen: isIsvExternalUser ? true : null,
        }
        handlePostDocumentComment(postData);
        if (isRejectButtonClick) {
            onRejectCommentPosted();
        }
        handleCloseDialog();
        setComment('');
    }

    const handleCloseDialog = () => {
       if(isRejectButtonClick) onCloseDialog(comment)
        setIsOpen(false);
        handleCommentsPopup(false);
        setComment('');
    }

    const checkButtonStatus = () => {
        const isUserSelected = rejectToggleClicked !== "reject" ? userSelection.value === '' : false;
        const isInputValid = comment.trim().length <= 0;
        return (isIsvCsmUser && !rejectLabel && (isUserSelected ? true : isInputValid)) || isInputValid;
    }

    const getCommentsData = (commentData) => {
      let data = JSON.parse(commentData.commentText);
      data = data.filter(
        (item) => item.IsECBReturnVisible === (fromEcb2Return ? true : false)
      );
      if (isIsvExternalUser) {
        data.forEach((item) => {
          item.Text = item.Text.replace('To Client: ', '');
        });
      }
      return data.reverse();
    };

    const handleCommentInput = (e) => {
      let isValid = false;
      isValid = /^[ A-Za-z0-9-_+,.()]*$/.test(e.target.value);
      isValid && setComment(e.target.value);
    };

    return (
        <CommentsWrapper>
            <LatestCommentValue>{latestComment ? latestComment.Text : isAdditionalComment && isAdditionalComment === 'true' ? 'Additional Comment...' : !isIsvExternalUser ? 'Add Comment...' : 'New Comment...'}</LatestCommentValue>
            <span onClick={handleEditComments}>
                <SvgIcon
                    className={isAdditionalComment ? 'edit-additional-comment' : `edit-individual-comment-${commentRow}`}
                    color='#000'
                    icon='pencil-edit'
                    size='large'
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '-20px',
                        transform: 'translate(-50%,-50%)',
                        top: '50%',
                        zIndex: 1,
                        marginTop: '6px'
                    }}
                    aria-hidden
                />
            </span>
            
            <Dialog
                open={isOpen || isCommentsPopupOpen}
                title='Comments Box'
                fullWidth={true}
                maxWidth={'xs'}
                onBackdropClick={() => handleCloseDialog()}
            >
                <CommentsInnerWrapper>
                    <CloseIcon onClick={() => handleCloseDialog()}>
                        <Icon
                            iconName='closeX'
                            style={closeIconStyle}
                        />
                    </CloseIcon>
                    <CommentsHeader>Comments</CommentsHeader>
                    <CommentsContainer>
                        {commentData && getCommentsData(commentData).map((commentValue, index) => {
                            return <React.Fragment key={index}>{getFullNameInitals(commentValue)}</React.Fragment>
                        })}
                    </CommentsContainer>
                    <TextFieldContainer>
                        {
                            (((isIsvCsmUser && !rejectLabel && rejectToggleClicked !== "reject")) || (fromEcb2Return && isIsvRRTeamUser && !isRejectButtonClick)) &&
                            <Autocomplete
                                autoComplete={false}
                                disablePortal
                                id="comment-to-user"
                                options={userRoles}
                                disableClearable
                                defaultValue={userSelection}
                                popupIcon={<SvgIcon icon="chevron-right" style={arrowDropdownIconStyle} aria-hidden />}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="To" />}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                onChange={(e, value) => setUserSelection(value)}
                            />
                        }
                        <TextField
                            className="comments-text-field"
                            id='field-comment'
                            name='comment'
                            onChange={(e) => handleCommentInput(e)}
                            value={comment}
                            inputProps={{ maxLength: 1000 }}
                        />
                        <PrimaryButton
                            size='normal'
                            id='submit-comment-button'
                            disabled={checkButtonStatus()}
                            style={primaryBtnStyle}
                            onClick={handleCommentSubmit}
                        >
                            Submit
                        </PrimaryButton>
                    </TextFieldContainer>
                </CommentsInnerWrapper>
            </Dialog>
        </CommentsWrapper>
    )
}

export default compose(
    connect(
        (state) => ({
            dealId: getIsvCurrentDealId(state),
            login: state.login,
            isIsvExternalUser: isIsvExternalUser(state),
            isIsvCsmUser: isIsvCsmUser(state),
            isIsvRRTeamUser: isIsvRRTeamUser(state),
            commentData: selectLatestCommentsDataForCurrentComment(state),
            isCommentsPopupOpen: selectIsCommentPopUpOpen(state),
            rejectLabel: selectIsvRejectButtonLabel(state),
        }),
        (dispatch) => ({
            handlePostDocumentComment: (data) => {
                dispatch(handlePostDocumentComment(data));
            },
            getLatestCommentsByCommentId: (commentId, isClientScreen, isEcbReturn) => {
                dispatch(getLatestCommentsByCommentId(commentId, isClientScreen, isEcbReturn));
            },
            handleGetLatestCommentsByCommentsId: (data) => {
                dispatch(handleGetLatestCommentsByCommentsId(data))
            },
            handleCommentsPopup: (isOpen) => {
                dispatch(handleCommentsPopup(isOpen))
            },
            handleSubmitDealInformation: (dealId, status) => {
                dispatch(handleSubmitDealInformation(dealId, status))
              }
        })
    )
)(DealInformationCommentBoxContainer);
