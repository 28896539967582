import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
import styled from 'styled-components';
import _ from "lodash";

import { updateCurrentTranche } from 'actions/OrderBookActions';
import { getTrancheTitles, getCurrentTranche, getAmendedOrderSummaryData, getInvestorNames, getTrancheLists, getSelectedInvestor, getSelectedTranche } from 'selectors';

import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import withTranslate from 'shared/hoc/withTranslate';

import OrderbookHeader from '../../shared/summary/OrderbookHeader';
// analytics
import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';
import { ALL_INVESTORS, ALL_TRANCHES } from 'constants/orders';

// tabs
import { tabIds, allTranchesAmendedSummarySubTabs } from 'constants/tabIds';
import HorizontalTab from 'shared/accessibility/components/HorizontalTabs/HorizontalTab';
import HorizontalTabs from 'shared/accessibility/components/HorizontalTabs';
// import OrderSummaryLineChart from '../OrderSummary/OrderSummaryLineChart';
import AmendedSummaryTable from "./AmendedSummaryTable";
import OrderSummaryDropdownList from "../OrderSummary/OrderSummaryDropdownList"
import { TileContentInner, EmptyContent } from 'Landing/dcm/Tiles/styles.js';
import { dateFormatter } from '../../SharedTableComponents/formatters';
import { Sorters } from 'mk-pattern-library-react';
import Loading from 'shared/components/Loading';

const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

const DropDownPicker = styled.div`
    margin: 30px 0px
`;
// const CumulativeTextWrapper = styled.div`
//   position: absolute;
//   top: 10px;
//   color: #000;
//   right: 10px;
//   font-weight: 600;
// `;
const AmendedOrderSummaryContainer = (props) => {

  const [amendedOrderSummaryData, setAmendedOrderSummaryData] = useState([]);
  const [amendedInvestorList, setAmendedInvestorList] = useState([])
  const [orderSummary, setOrderSummary] = useState([])
  const [trancheOrders, setTrancheOrders] = useState([])
  const [amendedOrderIdList, setAmendedOrderIdList] = useState([])

  useEffect(() => {
    props.viewChange();
  }, [])

  useEffect(() => {
    getUpdatedStructuredOrders();
  }, [props.amendedOrderSummaryData])

  useEffect(() => {
    getFilteredStructuredOrders()
  }, [amendedInvestorList.length, props.selectedInvestor, props.selectedTranche, amendedOrderSummaryData])

  const handleTrancheNavClick = (selectedTranche) => {
    props.updateCurrentTranche(selectedTranche.id);
  }

  const getUpdatedStructuredOrders = () => {
    const amendedResultData = JSON.parse(JSON.stringify(props.amendedOrderSummaryData));

    // To Destructure the API response
    const structuredIndividualOrders = amendedResultData
      .map((item) => item.individualOrders
        .map((order) => order.orders)
        .reduce((acc, val) => acc.concat(val), []))
      .reduce((acc, val) => acc.concat(val), []);

    // Trimming the Extra Spaces for Investor Names
    structuredIndividualOrders.forEach((item) => {
      item.investorLongName = item.investorLongName.trim();
    })

    // Getting All Unique Amended Investor Name List from Destructure API Response
    const amendedInvestorList = [...new Map(structuredIndividualOrders.map((item) =>
      [item["investorLongName"], item])).values()]
      .sort((a, b) => a.investorLongName.trim() < b.investorLongName.trim() ? -1 : 1)
      .map((item) => {
        return {
          key: item.investorID,
          value: item.investorLongName,
          label: item.investorLongName,
        }
      })

    setAmendedOrderSummaryData(structuredIndividualOrders)
    setAmendedInvestorList(amendedInvestorList)
  }

  const getFilteredStructuredOrders = () => {
    const orderSummary = [];
    const sortedOrderSummary = [];
    let filteredData = [];
    let filteredTrancheData = [];

    const isIntialInvestor = _.isEqual(props.selectedInvestor, ALL_INVESTORS); // Check Wheather Default Investor Name is Selected
    const isIntialTranche = _.isEqual(props.selectedTranche, ALL_TRANCHES); // Check Wheather Default Tranche Name is Selected

    // Filter the Data as per Investor Names
    filteredData = amendedOrderSummaryData.filter((order) => order.investorLongName === (isIntialInvestor ? amendedInvestorList[0].value : props.selectedInvestor.value))

    // Filter the Data as per Tranche Names
    !isIntialTranche ? filteredTrancheData = filteredData.filter((order) => order.trancheName === props.selectedTranche.value) : filteredTrancheData = filteredData;

    // Formatting the Existing Received Date to Date-Month
    filteredTrancheData.forEach((item) => {
      item[item.orderId] = item.orderAmount;
      item.dateTimeInterval = dateFormatter('dateMonth', item.externalSourceLastModified) + ' ' + item.timeEntered
    })

    // Sort the Orders Ascending (Date)
    filteredTrancheData.sort((a, b) => Sorters.doubleSort('dateEntered', a, b, 'asc', 'externalSourceLastModified'))

    const sameData = JSON.parse(JSON.stringify(filteredTrancheData));

    // If next order has same date then display only time entry, remove the date.
    filteredTrancheData.forEach((order, index) => {
      if (filteredTrancheData.length > 1) {
        if (index !== filteredTrancheData.length - 1) {
          order = getFilterdData(sameData, filteredTrancheData, index, index + 1);
        } else {
          order = getFilterdData(sameData, filteredTrancheData, index, index - 1);
        }
        orderSummary.push(order);
      }
      else orderSummary.push(order);
    })

    const orderIdList = orderSummary.map((item) => {
      return {
        orderId: item.orderId,
        trancheName: item.trancheName,
      }
    });

    // Separate all Unique IDs from order list for Table Header Columns
    const amendedOrderIdList = [...new Map(orderIdList.map((item) => [item["orderId"], item])).values()].sort((a, b) => a.trancheName < b.trancheName ? -1 : 1);

    orderSummary.forEach((item) => {
      const obj = {}
      amendedOrderIdList.forEach((subItem) => {
        obj[subItem.orderId] = subItem.orderId === item.orderId ? item.orderAmount : null;
        obj['dateEntered'] = item.dateEntered;
        obj['timeEntered'] = item.timeEntered;
        obj['dateTimeInterval'] = item.dateTimeInterval;
      });
      sortedOrderSummary.push(obj)
    });

    const updatedOrderSummary = getMappedAmendedOrderList(sortedOrderSummary, amendedOrderIdList)

    // Get the Column Count for Each Order
    const trancheOrders = props.trancheNameList.map((order) => {
      return {
        count: getColumnCount(amendedOrderIdList, order.value),
        trancheName: order.value,
      }
    }).sort((a, b) => a.trancheName < b.trancheName ? -1 : 1)

    setOrderSummary(updatedOrderSummary)
    setTrancheOrders(trancheOrders)
    setAmendedOrderIdList(amendedOrderIdList)
  }

  const getMappedAmendedOrderList = (arr, amendedOrderIdList) => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(el => el.dateEntered === val.dateEntered && el.timeEntered === val.timeEntered);
      if (index !== -1) {
        amendedOrderIdList.forEach((id, i) => {
          acc[index][id.orderId] = val[id.orderId] ? val[id.orderId] : acc[index][id.orderId]
        });
      } else {
        acc.push(val);
      };
      return acc;
    }, []);
  }

  const getFilterdData = (data, structuredIndividualOrders, index, nextIndex) => {
    if (nextIndex !== 0 && index < nextIndex) {
      if ((structuredIndividualOrders[index].dateEntered === structuredIndividualOrders[nextIndex].dateEntered) &&
        (structuredIndividualOrders[index].dateTimeInterval !== structuredIndividualOrders[nextIndex].dateTimeInterval)) {
        data[nextIndex].dateEntered = "";
        data[nextIndex].dateTimeInterval = data[nextIndex].timeEntered;
      }
    }
    return data[index];
  }

  const getColumnCount = (orderIdList, trancheName) => {
    return orderIdList.filter((item) => item.trancheName === trancheName).length
  }

  const active = { id: props.currentTrancheId }
  const currentTrancheData = props.currentTrancheData;

  const isAmendedOrderSummaryEnable = orderSummary && orderSummary.length;

  const trancheTitles = props.trancheTitles.map(t => ({
    ...t,
    btnAriaLabel: translationString(
      'orders.open_tranche_<tranche_name>_<status>',
      props.language,
      `Open tranche ${t.title} ${t.status || ''}`,
      [
        ['<tranche_name>', t.title],
        ['<status>', t.status],
      ]
    )
  }));

  return (
    <div>
      {
        currentTrancheData ? (
          <div>
            <OrderbookHeader amendedSummary={true} dealType='dcm' headerData={currentTrancheData} dateReceived={props.dateReceived} trancheTitles={trancheTitles} trancheClick={handleTrancheNavClick} trancheActive={active} />
            <>
              {
                props.loading ? (
                  <LoadingContainer>
                    <LoadingWrapper>
                      <Loading hasBackground={true} />
                    </LoadingWrapper>
                  </LoadingContainer>
              ):(
            <Row>
              <Col md={12} style={{ marginBottom: '20px' }}>
                {
                  props.orderData.length && amendedInvestorList.length ?
                    <DropDownPicker>
                      <OrderSummaryDropdownList
                        dealId={props.dealId}
                        allInvestorLists={amendedInvestorList}
                        investorNameText={props.investorNameText}
                        orderSummaryData={props.amendedOrderSummaryData}
                        trancheNameText={props.trancheNameText}
                        allTranchelists={[ALL_TRANCHES, ...props.trancheNameList]}
                        isAmenededOrderSummary={true}
                      />
                    </DropDownPicker> : null
                }
                <div className="tabbed-container relative">
                  <HorizontalTabs tabsId={tabIds.ALL_TRANCHES_AMENDED_SUMMARY_SUB_TABS}>
                    {/* <HorizontalTab
                      tabId={allTranchesAmendedSummarySubTabs.VIEW_CHART}
                      title={props.viewChart}>
                      {
                        isAmendedOrderSummaryEnable ? (
                          <React.Fragment>
                            <CumulativeTextWrapper>
                              Note: Time interval is not to scale.
                          </CumulativeTextWrapper>
                            <OrderSummaryLineChart
                              lineChartSummary={orderSummary}
                              lineChartList={amendedOrderIdList}
                              trancheOrders={trancheOrders}
                              notAvailable={props.notAvailable}
                              xAxisLabel='Date & Time (GMT)'
                              yAxisLabel='Order Amount (m)'
                              xDy={415}
                              chartLabel="amendedOrderSummary"
                              tooltipLabel={props.timeEntered}
                              dataKey={'dateTimeInterval'}
                            />
                          </React.Fragment>
                        ) : (
                            <TileContentInner>
                              <EmptyContent>
                                {props.notAvailable}
                              </EmptyContent>
                            </TileContentInner>
                          )
                      }
                    </HorizontalTab> */}
                    <HorizontalTab
                      tabId={allTranchesAmendedSummarySubTabs.VIEW_TABLE}
                      title={props.viewTable}
                    >
                      {
                        isAmendedOrderSummaryEnable ? (
                          <AmendedSummaryTable
                            orderSummaryList={{ orderSummary, trancheOrders, amendedOrderIdList }}
                            trancheLists={props.trancheLists}
                            dateText={props.dateText}
                            timeEntered={props.timeEntered}
                          />
                        ) : (
                            <TileContentInner>
                              <EmptyContent>
                                {props.notAvailable}
                              </EmptyContent>
                            </TileContentInner>
                          )
                      }
                    </HorizontalTab>
                  </HorizontalTabs>
                </div>
              </Col>
            </Row>
            )
            }
          </>
          </div>
        ) : (
            <div>
              <TranslationText
                dictionaryKey={"others.content_not_yet_available"}
                defaultText={"Content Not Yet Available"}
              />
            </div>
          )
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentTrancheData: getCurrentTranche(state),
  trancheTitles: getTrancheTitles(state),
  orderData: state.orderBookData.orderData,
  currentTrancheId: state.orderBookData.currentTrancheId,
  dealId: state.utility.dealsData.dealId,
  dateReceived: state.orderBookData.dateReceived,
  amendedOrderSummaryData: getAmendedOrderSummaryData(state),
  investorNameList: getInvestorNames(state),
  trancheNameList: getTrancheLists(state),
  selectedInvestor: getSelectedInvestor(state),
  selectedTranche: getSelectedTranche(state),
  loading: state.orderBookData.dataFetching,
});

const mapDispatchToProps = dispatch => ({
  updateCurrentTranche: (trancheId, loader) => dispatch(updateCurrentTranche(trancheId, 'DCM')),
});

export default compose(
  withLanguage,
  connect(mapStateToProps, mapDispatchToProps),
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.ORDERBOOK_AMENDED_PAGE),
  })),
  withTranslate((translate, props) => ({
    viewChart: translate('orders.view_chart', 'View Chart'),
    viewTable: translate('orders.view_table', 'View Table'),
    notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
    investorNameText: translate('orders.investor_name', 'Investor Name'),
    trancheNameText: translate('orders.tranche_name', 'Tranche Name'),
    dateText: translate('orders.date', 'Date'),
    timeEntered: translate('orders.time_entered', 'Time Entered'),
  })),
)(AmendedOrderSummaryContainer)
