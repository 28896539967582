import { fork, takeEvery, select } from 'redux-saga/effects';
import { AnalyticsTypes } from 'actions/Types';

import {
    globalTealiumArgs,
    trackViewFields,
    views,
} from 'constants/analytics';
import { featurePageIds, overlayIds } from 'constants/featurePages';
import {
    getSelectedLanguage,
    getLoggedInStatus,
    getSUniqueName,
    getActualCategoryTitle,
    getCurrentFeedbackChartTypeName,
    getCurrentDealId,
    isInternalUser,
} from 'selectors';

const eventTypes = {
    TRACK_EVENT: 'trackEvent',
    TRACK_VIEW: 'trackView',
};

//
// TMS actions
//
const trackEvent = (data) => {
  window.TMS && window.TMS.trackEvent(data);
};

const trackView = (data) => {
  window.TMS && window.TMS.trackView(Object.assign({}, globalTealiumArgs, data));
};

export default (api, dispatch, getState) => {
    function* worker(action) {
        let eventType;
        let eventDetails = {};

        const state = yield select();
        const language = getSelectedLanguage(state);

        switch (action.type) {
            //
            // "View" 
            //
            case AnalyticsTypes.VIEW_CHANGE: {
                const { viewId } = action;
                eventType = eventTypes.TRACK_VIEW;
                eventDetails = {
                    [trackViewFields.PAGE_LANGUAGE]: language,
                };

                const isAuthenticated = yield select(getLoggedInStatus);
                if (
                    (viewId === featurePageIds.INTERNAL_LOGIN_PAGE || viewId === featurePageIds.EXTERNAL_LOGIN_PAGE) &&
                    !isAuthenticated
                ) {
                    if (window.__MyDealEnv === 'INTERNAL') {
                        eventDetails = Object.assign(eventDetails, views['INTERNAL_LOGIN_PAGE']);
                    } else { // EXTERNAL
                        eventDetails = Object.assign(eventDetails, views['EXTERNAL_LOGIN_PAGE']);
                    }
                } else {
                    const dealId = yield select(getCurrentDealId);
                    eventDetails[trackViewFields.TRANSACTION_ID] = dealId;

                    const isInternal = isInternalUser(state);
                    eventDetails[trackViewFields.CUSTOMER_GROUP] = isInternal ? 'internal' : 'external';

                    const sCustomerId = yield select(getSUniqueName);
                    eventDetails[trackViewFields.CUSTOMER_ID] = sCustomerId;

                    switch (viewId) {
                        case featurePageIds.LANDING_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.LANDING_PAGE]);
                            
                            break;
                        }
                        case featurePageIds.MY_DOCS_DOCUMENTS_PAGE: {
                            const actualTabTitle = yield select(getActualCategoryTitle);
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.MY_DOCS_DOCUMENTS_PAGE](actualTabTitle));

                            break;
                        }
                        case featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE: {
                            const actualTabName = yield select(getCurrentFeedbackChartTypeName);
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE](actualTabName));

                            break
                        }
                        case featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE]);
                            break;
                        }
                        case featurePageIds.ORDERBOOK_SUMMARY_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.ORDERBOOK_SUMMARY_PAGE]);
                            break;
                        }
                        case featurePageIds.ORDERBOOK_FULL_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.ORDERBOOK_FULL_PAGE]);

                            break;
                        }
                        case featurePageIds.ORDERBOOK_AMENDED_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.ORDERBOOK_AMENDED_PAGE]);
                           
                            break;
                        }
                        case featurePageIds.PRICING_DOCUMENTS_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.PRICING_DOCUMENTS_PAGE]);
                            break;
                        }
                        case featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE]);
                            break;
                        }
                        case featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE: {
                            eventDetails = Object.assign(eventDetails, views[featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE]);
                            break;
                        }
                        case overlayIds.SESSION_EXPIRY_OVERLAY: {
                            eventDetails = Object.assign(eventDetails, views[overlayIds.SESSION_EXPIRY_OVERLAY]);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    //
                    // If in the future urls will contain params pls create switch(true){ case regexp.test(url) ...} below
                    // but add flag to switch above that any case was matched ( in default block )
                    //
                }

                break;
            }
            //
            // "Event" 
            //
            case AnalyticsTypes.EVENT_TRIGGERED: {
                eventType = eventTypes.TRACK_EVENT;
                eventDetails = { ...action.eventDetails };

                break;
            }
            default: {
                break;
            }
        }

        switch (eventType) {
            case eventTypes.TRACK_EVENT: {
                trackEvent(eventDetails);
                break;
            }
            case eventTypes.TRACK_VIEW: {
                trackView(eventDetails);
                break;
            }
            default: {
                break;
            }
        }
    }

    function* watcher() {
        yield fork(takeEvery, '*', worker);
    }

    return {
        watcher,
        worker,
    };
};
