import { connect } from 'react-redux';

import { openModal, closeModal } from 'actions/UtilityActions';
import { isModalOpen } from 'selectors';

function withModals(mapStateToModals, mapModalActions) {
    return Cmp => connect(
        mapStateToModals && ((state, ownProps) => mapStateToModals(
            isModalOpen.bind(this, state),
            ownProps,
        )),
        mapModalActions && ((dispatch, ownProps) => mapModalActions(
            modalId => dispatch(closeModal(modalId)),
            modalId => dispatch(openModal(modalId)),
            ownProps,
        )),
    )(Cmp);
}

export default withModals;
