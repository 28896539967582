import { connect } from 'react-redux';
import { compose } from 'recompose';

import withTranslate from 'shared/hoc/withTranslate';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';

import { tabIds, roadshowContactTabs } from 'constants/tabIds';
import { changeTab } from 'actions/UtilityActions';

import * as modalIds from 'constants/modalIds';
import withModals from 'shared/hoc/withModals';

import { getRoadShowContacts } from 'selectors';

import CombinedContacts from './index';

export default compose(
    withTranslate(
        translate => ({
            btnText: translate('roadshow.all_contacts', 'All Contacts'),
        })
    ),
    connect(
        state => {
            const areAllContactsAvailable = getRoadShowContacts(state).length > 0;
            return ({
                isBtnDisabled: !areAllContactsAvailable,
                visibleTab: roadshowContactTabs.ALL_CONTACTS,
            })
        },
        dispatch => ({
            changeTab: () => dispatch(changeTab(tabIds.ROADSHOW_CONTACT_TABS, roadshowContactTabs.ALL_CONTACTS))  
        })
    ),
    withModals(
        selection => ({
            modalId: modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_2,
            isModalOpen: selection(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_2),
        }),
        (close, open, props) => ({
            openModal: () => {
                props.changeTab(); // to default
                open(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_2);
            },
            closeModal: () => close(modalIds.ROADSHOW_COMBINED_CONTACTS_MODAL_ID_2),
        }),
    ),
    withAnalytics2(
        (ev, events) => ({
            adobeOpenModalEvent: () => ev(events.ROADSHOW_CONTACTS_BUTTON),
        }),
    ),
)(CombinedContacts);