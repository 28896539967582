import styled from 'styled-components';

import { typography } from 'shared/styles/variables/theme'
//
// Feature Headings -> Investor Feedback, Pricing, ...etc
//
export const FeatureHeading = styled.h1`
    line-height: 20px;
    margin: 0;

    ${({ top }) => top !== undefined ? `margin-top: ${top}px;` : ''}
    ${({ bottom }) => bottom !== undefined ? `margin-bottom: ${bottom}px;` : ''}
    ${({ left }) => left !== undefined ? `margin-left: ${left}px;` : ''}
    ${({ right }) => right !== undefined ? `margin-right: ${right}px;` : ''}

    ${({ invisible }) => invisible !== undefined ? 'visibility: hidden; width: 0; height: 0; overflow: hidden;' : ''}
`;
//
// Feature Headings description
//
export const HeadingDescription = styled.div`
    display: block;
    font-size: ${typography.sizes['18px']};
    margin: 0;

    ${({ top }) => top !== undefined ? `margin-top: ${top}px;` : ''}
    ${({ bottom }) => bottom !== undefined ? `margin-bottom: ${bottom}px;` : ''}
    ${({ left }) => left !== undefined ? `margin-left: ${left}px;` : ''}
    ${({ right }) => right !== undefined ? `margin-right: ${right}px;` : ''}

    ${({ invisible }) => invisible !== undefined ? 'visibility: hidden; width: 0; height: 0; overflow: hidden;' : ''}
`;

export const FeatureCarouselHeading = styled.h3`
    margin-top: 0;
`;