import { createReducer } from 'reduxsauce';
import Types, { UtilityTypes } from 'actions/Types';

export const INITIAL_STATE = {
    dataReceived: Date.now(),
    //
    feedbackThemeTypes: [],
    themeTypesFetched: false,
    //
    dealThemes: [],
    //
    feedbackSentiments: [],
    sentimentsFetched: false,
    //
    dealFeedback: [],
    chartTypes: [],
    chartTypesFetched: false,
    //
    selectedChartId: null,
    selectedChartTab: 0,
    selectedChartSubTab: 0,
    selectedChartSubTabTitle: null,
};

const receiveFeedbackThemeTypes = (state, action) => Object.assign({}, state, {
    feedbackThemeTypes: action.feedbackThemeTypes.data,
    themeTypesFetched: true,
    dateReceived: Date.now()
})

const receiveFeedbackSentiments = (state, action) => Object.assign({}, state, {
    feedbackSentiments: action.feedbackSentiments.data,
    sentimentsFetched: true,
    dateReceived: Date.now()
})

const receiveFeedbackDealThemes = (state, action) => Object.assign({}, state, {
    dealThemes: action.dealThemes.data,
    dateReceived: Date.now()
})

const receiveFeedbackForDeal = (state, action) => {
    let nullData = !(action.dealFeedback.data || action.dealFeedback.data[0])
    return Object.assign({}, state, {
        dealFeedback: nullData ? [] : action.dealFeedback.data[0].feedback,
        fullFeedbackDoc: nullData ? null : action.dealFeedback.data[0].feedbackDocumentId,
        dateReceived: Date.now()
    });
}

const receiveFeedbackSummaryForDealAndTheme = (state, action) => Object.assign({}, state, {
    dealAndThemeSummary: action.dealAndThemeSummary.data,
    [action.responseDetails]: action.dealAndThemeSummary.data,
    dateReceived: Date.now(),
});

const receiveFeedbackChartTypes = (state, action) => Object.assign({}, state, {
    chartTypes: action.chartTypes.data,
    chartTypesFetched: true,
    dateReceived: Date.now(),
});

const receiveFeedbackChartData = (state, action) => {
    const nullData = (action.chartData == null) || (action.chartData.length === 0);

    return Object.assign({}, state, {
        chartData: action.chartData.data,
        [action.responseDetails]: nullData ? null : action.chartData.data[0],
        dateReceived: Date.now()
    });
}

const receiveFeedbackHighlightsForDealAndTheme = (state, action) => Object.assign({}, state, {
    [action.responseDetails]: action.dealAndSentimentSummary.data,
    dateReceived: Date.now()
});

const setCurrentChartId = (state, { chartId }) => Object.assign({}, state, {
    selectedChartId: chartId,
})

const selectChartTab = (state, { chartIndex }) => Object.assign({}, state, {
    selectedChartTab: chartIndex,
})

const selectChartSubTab = (state, { chartIndex, chartTitle }) => Object.assign({}, state, {
    selectedChartSubTab: chartIndex, 
    selectedChartSubTabTitle: chartTitle,
})

// map our types to our handlers
const ACTION_HANDLERS = {
    [Types.API_RECEIVE_FEEDBACK_THEME_TYPES]: receiveFeedbackThemeTypes,
    [Types.API_RECEIVE_FEEDBACK_SENTIMENTS]: receiveFeedbackSentiments,
    [Types.API_RECEIVE_DEAL_THEMES]: receiveFeedbackDealThemes,
    [Types.API_RECEIVE_FEEDBACK_FOR_DEAL]: receiveFeedbackForDeal,
    [Types.API_RECEIVE_FEEDBACK_FOR_DEAL_AND_THEME]: receiveFeedbackSummaryForDealAndTheme,
    [Types.API_RECEIVE_FEEDBACK_HIGHLIGHTS_FOR_DEAL_AND_THEMETYPE]: receiveFeedbackHighlightsForDealAndTheme,
    [Types.API_RECEIVE_FEEDBACK_CHART_TYPES]: receiveFeedbackChartTypes,
    [Types.API_RECEIVE_FEEDBACK_CHART_DATA]: receiveFeedbackChartData,
    [Types.SELECT_CHART_ID]: setCurrentChartId,
    [Types.SELECT_CHART_TAB]: selectChartTab,
    [Types.SELECT_CHART_SUB_TAB]: selectChartSubTab,
    // v1.7
    // Deal Cleanup (omit static data)
    [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE, {
        // theme types
        feedbackThemeTypes: state.feedbackThemeTypes,
        themeTypesFetched: state.themeTypesFetched,
        // sentiments
        feedbackSentiments: state.feedbackSentiments,
        sentimentsFetched: state.sentimentsFetched,
    }),
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)