import React, { useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from "lodash";
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import { ValueFormatters, Flag } from 'mk-pattern-library-react';
import { resetOrderBookDropDowns, filterOrderSummary } from 'actions/OrderBookActions';
import TranslationText from 'translations/TranslationText';
import ButtonGroup from 'shared/components/Buttons/ButtonGroup';
import { ALL_TRANCHES_RECORD, ALL_INVESTORS, ALL_TRANCHES, DEFAULT_TIME_INTERVAL } from 'constants/orders';
import { getSelectedInvestor, getSelectedTranche, getSelectedInterval } from 'selectors';
import { connect } from 'react-redux';

const OrderBookDcmHeader = ({
    headerDisplayData,
    dateReceived,
    allTranchesButtonGroup,
    defaultButtonGroup,
    trancheActive,
    fullOrderbook,
    resetOrderBookDropDowns,
    selectedInvestor,
    selectedTranche,
    selectedInterval,
    filterOrderSummary,
    dealId,
}) => {

    const usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    const prevActiveTranche = usePrevious(trancheActive);

    useEffect(() => {
        if (prevActiveTranche && trancheActive.id === ALL_TRANCHES_RECORD.id && prevActiveTranche.id !== ALL_TRANCHES_RECORD.id) {
            resetOrderBookDropDowns();
            if (!(_.isEqual(selectedInvestor, ALL_INVESTORS)) || !(_.isEqual(selectedTranche, ALL_TRANCHES)) || !(_.isEqual(selectedInterval, DEFAULT_TIME_INTERVAL))) {
                filterOrderSummary(dealId, DEFAULT_TIME_INTERVAL.key, ALL_INVESTORS.key, ALL_TRANCHES.key)
            }
        }
    }, [prevActiveTranche])

    return (
        <Row>
            <Col sm={trancheActive.id === ALL_TRANCHES_RECORD.id ? 4 : 6} style={{ paddingTop: 10 }}>
                <FeatureHeading className="text-xxlarge text-univ-bd">{headerDisplayData.mainName} <Flag>{`${headerDisplayData.flagStatus}`}</Flag></FeatureHeading>
                {
                    (fullOrderbook || trancheActive.id !== ALL_TRANCHES_RECORD.id) &&
                    <HeadingDescription top={2}>{headerDisplayData.description}</HeadingDescription>
                }
            </Col>
            <Col sm={trancheActive.id === ALL_TRANCHES_RECORD.id ? 8 : 6}>
                <div style={{ marginTop: '25px', textAlign: 'right' }}>
                    <p className="text-timestamp" style={{ marginBottom: '10px', lineHeight: 1 }}>

                        {ValueFormatters.dateFormatter('dayMonth', headerDisplayData.currentStatusDate) === '' ? '' : ` ${ValueFormatters.dateFormatter('dayMonth', headerDisplayData.currentStatusDate)}`}

                        <TranslationText
                            dictionaryKey={"common.data_as_of_<date_time>"}
                            replace={[
                                ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', dateReceived)]
                            ]}
                            defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', dateReceived)}`}
                        />
                    </p>
                    <div style={{ marginBottom: 15, marginTop: 0, position: 'relative', bottom: '-3px' }} className="text-large">
                        <ButtonGroup
                            size="small"
                            buttons={trancheActive.id === ALL_TRANCHES_RECORD.id ? allTranchesButtonGroup : defaultButtonGroup}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
};

export default connect(
    state => ({
        dealId: state.utility.dealsData.dealId,
        selectedInvestor: getSelectedInvestor(state),
        selectedTranche: getSelectedTranche(state),
        selectedInterval: getSelectedInterval(state),
    }),
    dispatch => ({
        resetOrderBookDropDowns: () => dispatch(resetOrderBookDropDowns()),
        filterOrderSummary: (dealId, interval, investorId, trancheId, loader) => dispatch(filterOrderSummary(dealId, investorId, trancheId, interval, loader)),
    })
)(OrderBookDcmHeader);