import React, {  } from "react";
import styled from "styled-components";
import { SvgIcon } from "shared/components/Icon";
import {
  STATIC_MINUTES,
  DEFAULT_TIME_INTERVAL,
} from "constants/orders";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import { Icon } from "mk-pattern-library-react";
import Popup from "shared/components/Popup";
import PopupSystem from "shared/components/PopupSystem";
import { typography } from "shared/styles/variables/theme";

const styles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const trancheContainerStyles = {
  ...styles,
  flexDirection: "column",
  alignItems: "start",
};

const TranchesDropDownContainer = styled.div`
  ${trancheContainerStyles},
  span {
    margin: 0 0 10px;
  }
`;

const closeIconStyle = {
  right: "15px",
  top: "15px",
  cursor: "pointer",
};

const arrowDropdownIconStyle = {
  transform: "rotate(90deg)",
  marginTop: "2px",
  width: "18px",
  height: "18px",
};

function SpecificTimePopup({
  isModalOpen,
  setIsModalOpen,
  hour,
  min,
  setHour,
  setMin,
  onDropdownChange,
  selectedTranche,
  selectedInvestorType,
  selectedDate,
}) {
  const isValidHour = (hourValue) => {
    return hourValue && hourValue < 24 && hourValue >= 0;
  };

  const disabledStyle = () => {
    if (!isValidHour(hour)) {
      return {
        pointerEvents: "none",
        color: "#ccc",
      };
    } else {
      return {};
    }
  };
  return (
    <div>
      {" "}
      <PopupSystem
        modalID={"1"}
        isModalOpen={isModalOpen}
        centered
        backgroundLayer
        zIndex={3001}
        closeModal={() => setIsModalOpen(false)}
        clickableElement={null}
        content={
          <Popup
            static
            width={300}
            dividerStyle={{
              margin: "35px 0px 25px",
              borderBottom: "2px solid #585858",
            }}
            header={"Enter Specific Time"}
            headerKey="orders.enter_specific_time"
            secondaryButton
            secondaryButtonStyle={{
              fontSize: typography.sizes["16px"],
              padding: "14px 20px",
              ...disabledStyle(),
            }}
            secondaryButtonAction={() => {
              let hourToDisplay = hour.length === 1 ? `0${hour}` : hour;
              let minToDisplay =
                min.value.length === 1 ? `0${min.value}` : min.value;
              onDropdownChange(
                undefined,
                selectedTranche,
                selectedDate,
                DEFAULT_TIME_INTERVAL,
                {
                  key: 1,
                  value: `${hour}:${min.value} to 23:59`,
                  label: `${hourToDisplay}:${minToDisplay} to 00:00`,
                  disableTimeInterval: true,
                },
                selectedInvestorType,
                false
              );
              setIsModalOpen(false);
            }}
            secondaryButtonKey="login_screen.ok"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setIsModalOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Icon iconName="closeX" style={closeIconStyle} />
            </IconButton>
            <p style={{ marginTop: "10px" }}>Hour :</p>
            <TextField
              size="medium"
              helperText={!isValidHour(hour) ? "Invalid hour" : ""}
              type="number"
              InputProps={{ inputProps: { min: 0, max: 23 } }}
              value={hour}
              error={!isValidHour(hour)}
              onChange={(e) => setHour(e.target.value)}
            />
            <TranchesDropDownContainer>
              <span style={{ marginTop: "10px" }}>
                {"Minute"}:
              </span>
              <Autocomplete
                disablePortal
                id="order-intervals"
                options={STATIC_MINUTES}
                disableClearable
                popupIcon={
                  <SvgIcon
                    icon="chevron-right"
                    style={arrowDropdownIconStyle}
                    aria-hidden
                  />
                }
                value={min.value}
                onChange={(e, value) => setMin(value)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) => option.value === value}
              />
            </TranchesDropDownContainer>
          </Popup>
        }
      />
    </div>
  );
}

export default SpecificTimePopup;
