import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as modalIds from '../../constants/isvModalIds';
import { events } from '../../../constants/analytics';
import withAnalytics from '../../../shared/hoc/withAnalytics';

import styled from 'styled-components';

import { 
  getIsvDeals,
  getIsvCurrentDeal,
  getIsvUniqueProductType,
  getIsvCurrentDealId 
} from '../../selectors';

import Dropdown from '../../../shared/accessibility/components/Dropdown';
import ISVDropdown from '../../../shared/accessibility/components/ISVDropdown';

const DropdownItemContainer = styled.div`
  padding: 5px 10px;
  text-align: left;
  line-height: 20px;
`;

const DropdownTemplateContainer = styled.div`
  padding: 0 10px;
  text-align: left;
  line-height: 1.3;
`;

const ProjectName = styled.div`
  padding-bottom: 3px;
`;

const ClientName = styled.div`
  font-size: 11px;
`;

const Lrn = styled.div`
  font-size: 11px;
`;

const DropdownProductListItem = props =>
  <DropdownItemContainer>
    <ProjectName>{props.productTypeName}</ProjectName>
 </DropdownItemContainer>

 const DropdownProductTemplate = props =>
 <DropdownItemContainer>
   <ProjectName>{props.content}</ProjectName>
</DropdownItemContainer>

const DropdownListItem = props =>
  <DropdownItemContainer>
    <ProjectName>{props.content}</ProjectName>
    
    
  </DropdownItemContainer>

const DropdownTemplate = props =>
  <DropdownTemplateContainer>
    <ProjectName>{props.content}</ProjectName>
    <ClientName>{props.content2}</ClientName>
    <Lrn>{props.content3}</Lrn>
  </DropdownTemplateContainer>

class IsvDealSwitcher extends React.PureComponent {
  dropdownOpened = () => {
    this.props.dealSwitchEvent();
  }

  render() {
   const dealId=this.props.isvDealData && this.props.isvDealData.dealId
   const selectedDeal = this.props.allDeals.length > 0 && this.props.allDeals.filter(deal => deal.dealId===dealId)[0];
   const selectedProduct = this.props.allDealsProductType.map((value)=>value.productTypeName)

  return (
    <>
    <Dropdown
        dropdownId={modalIds.ISV_PRODUCT_MODAL_ID}
        dropdownText='Product Type'
        items={this.props.allDealsProductType}
        label={'Product Type'}
        reversed
        dropdownOpened={this.dropdownOpened}
        renderOption={item => <DropdownProductListItem {...item} />}
        clickableContent={<DropdownProductTemplate content={selectedProduct}  />}
        isMasthead
      />
      <ISVDropdown
        dropdownId={modalIds.DEAL_SWITCHER_ISV_MODAL_ID}
        dropdownText='Select Deal'
        items={this.props.allDeals}
        label={'Deal Switcher'}
        reversed
        dropdownOpened={this.dropdownOpened}
        renderOption={item => <DropdownListItem {...item} />}
        clickableContent={<DropdownTemplate content={selectedDeal ? selectedDeal.content: 'Select Deal'} />}
        isMasthead
      />
      </>
    )
  }
}

export default compose(
  connect(
    (state, props) => ({
      isvDealData:getIsvCurrentDeal(state),
      allDeals: getIsvDeals(state)
        .map(({ dealId, projectName, clientname,lrnReference}) => ({
          dealId,
          // dropdown adjustment
          selected: dealId === getIsvCurrentDealId(state),
          content: projectName,
          content2: clientname===null ?'':clientname,
          content3:lrnReference ===null ? '':lrnReference,
          label: projectName,
          click: () => props.onSwitchDeal(dealId),
        })),
        allDealsProductType: getIsvUniqueProductType(state)
        .map(({productType}) => ({
          productTypeName:productType.productTypeName,
          click: () => {},
        })),
    }),
  ),
  withAnalytics(ev => ({
      dealSwitchEvent: () => ev(events.DEAL_SWITCH),
  })),
)(IsvDealSwitcher);
