import { createReducer } from "reduxsauce";
import IsvTypes from "Isv/actions/IsvTypes";

const INITIAL_STATE = {
  isDashboardBannerOpen: true,
  isDealInformationBannerOpen:true,
  isReferenceInformationBannerOpen:true,
  isConfirmationDialogueOpen: false,
  latestIsvCurrentDealData: null,
  deleteDealDocumentId: null,
};

const closeInformationBanner = (state, action) => {
  return {
    ...state,
    [action.label]: false,
  };
};

const handleConfirmationDialogue = (state, action) => {
  return {
    ...state,
    isConfirmationDialogueOpen: action.value,
    deleteDealDocumentId: action.deleteDealDocumentId
  }
}
const handleDashboardData = (state, action) => {
  return {
    ...state,
    isMaturityDatePickerOpen: false,
    latestIsvCurrentDealData: action.data
  }
}

const ACTION_HANDLERS = {
  [IsvTypes.APPLY_CLOSE_INFORMATION_BANNER]: closeInformationBanner,
  [IsvTypes.APPLY_HANDLE_CONFIRMATION_DIALOUGE]: handleConfirmationDialogue,
  [IsvTypes.API_RECEIVE_DASHBOARD_DATA]: handleDashboardData
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
