/* @flow */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CoordinatorComments from '../CoordinatorComments/CoordinatorComments';

// import '../../styles/roadShow.scss';

const MeetingsDetails = (props) => {
    const event = props.event,
        spacingLg = { marginBottom: 8 },
        spacingXl = { marginBottom: 20 },
        eventLocation = event.location1 || event.location2 || {},

        dealType = props.dealType;

    const meetingLocation = (
        <div>
            {/* {event.booked == false &&
                <Row className="diagonal-hash" style={{ paddingTop: "2px", borderTop: "1px solid #d8d8d8", borderBottom: "1px solid #d8d8d8", height: "30px", margin: " 0 2px" }}>
                    <Col sm={12} style={{
                        color: "#9B9B9B", fontFamily: "UniversNextforHSBCW02-Md", fontSize: "12px",
                        lineHeight: "14px", marginTop: "5px", letterSpacing: "1.2px"
                    }}>
                        Unconfirmed Event
                    </Col>
                </Row>
            } */}

            <Row style={spacingXl} className={`${eventLocation.place || eventLocation.addressLine1 || eventLocation.addressLine2 || eventLocation.addressLine3 || eventLocation.addressLine4 || eventLocation.city || eventLocation.country || eventLocation.telephone || event.notes ? 'event-place' : ''} text-medium text-univ-lt`}>
                {eventLocation &&
                    (<Col sm={12}>
                        <div style={spacingLg}>{eventLocation.place}</div>
                        <div className="event-detail-address">
                            <div className="text-small ">{eventLocation.roomNumber}</div>
                            <div className="text-small ">{eventLocation.addressLine1}</div>
                            <div className="text-small ">{eventLocation.addressLine2}</div>
                            <div className="text-small ">{eventLocation.addressLine3}</div>
                            <div className="text-small ">{eventLocation.addressLine4}</div>
                            {dealType && dealType === "DCM" && (
                                <div className="text-small ">
                                    {eventLocation.city && (eventLocation.city + ', ')}
                                    {eventLocation.country}
                                </div>
                            )}

                            {eventLocation.telephone && (
                                <div className="text-small ">{eventLocation.telephone}</div>
                            )}
                        </div>
                    </Col>)
                }



                {event.notes &&
                    (<CoordinatorComments comments={event.notes} />)
                }
            </Row>

        </div>

    )
    let meetingSections = [];

    meetingSections.push(
        meetingLocation
    )

    return (
        <Row>
            <Col sm={12}>
                {meetingSections.map((section, ind) => { return <div key={ind}>{section}</div> })}
            </Col>
        </Row>
    )

}

export default MeetingsDetails
