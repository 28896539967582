import { connect } from 'react-redux';

import { events } from 'constants/analytics';
import { featurePageIds } from 'constants/featurePages';
import { eventTriggered, viewChange } from 'actions/AnalyticsActions';

function withAnalytics(settings) {
    return Cmp => connect(
        null,
        dispatch => (
            settings(
                eventDetails => dispatch(eventTriggered(eventDetails)),
                viewId => dispatch(viewChange(viewId)),
            )
        )
    )(Cmp);
}

function empty() { return ({}); }

export function withAnalytics2(
    e = empty,
    v = empty
) {
    return Cmp => connect(
        null,
        (dispatch, ownProps) => ({
            ...e(
                eventDetails => dispatch(eventTriggered(eventDetails)),
                events,
                ownProps,
            ),
            ...v(
                viewId => dispatch(viewChange(viewId)),
                featurePageIds,
                ownProps,
            )
        })
    )(Cmp);
}

export default withAnalytics;
