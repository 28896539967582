import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getRoadShowSummaryOnline } from 'selectors';

import TranslationText from 'translations/TranslationText';

import { buttonKeyHandler } from 'util/onKeyPress';

const clickCopy = (id) => {
    var $input = document.getElementById(id);
    $input.select();
    document.execCommand('copy');
};

const hiddenInput = {
    position: 'absolute',
    left: '-9999px',
};

class OnlineRoadshowModal extends React.PureComponent {
    openInBrowser = () => {
        const link = document.createElement('a');
        link.href = this.props.roadShowSummaryOnline.url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        const {
            psCode,
            url
        } = this.props.roadShowSummaryOnline;
        
        if (!this.props.roadShowSummaryOnline || !url) {
            return (
                <div style={{ textAlign: 'left', paddingBottom: 20 }}>
                    <TranslationText
                        dictionaryKey={''}
                        defaultText={'No Online Roadshow available'}
                    />
                </div>
            )
        }

        return (
            <div style={{ padding: 20 }} >
                <div className="popover-spacing-bottom-sm">{url}</div>
                <div className="popover-spacing-bottom-sm">
                    <TranslationText
                        dictionaryKey={'roadshow.pass-code'}
                        defaultText={'Pass-Code'}
                    />
                    &nbsp;:&nbsp;
                    {psCode}
                </div>
                <div>
                    <span
                        role="button"
                        className="text-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => { clickCopy('link-input') }}
                        onKeyDown={e => buttonKeyHandler(e, () => clickCopy('link-input'))}
                        tabIndex={0}
                    >
                        <TranslationText
                            dictionaryKey={'roadshow.copy_link'}
                            defaultText={'Copy Link'}
                        />
                    </span>
                    <input
                        aria-hidden
                        tabIndex={-1}
                        readOnly
                        style={hiddenInput}
                        id="link-input"
                        value={url}
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <span
                        role="button"
                        tabIndex={0}
                        className="text-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => { clickCopy('pass-input') }}
                        onKeyDown={e => buttonKeyHandler(e, () => clickCopy('pass-input'))}
                    >
                        <TranslationText
                            dictionaryKey={'roadshow.copy_pass-code'}
                            defaultText={'Copy Pass-code'}
                        />
                    </span>
                    <input
                        aria-hidden
                        tabIndex={-1}
                        readOnly
                        style={hiddenInput}
                        id="pass-input"
                        value={psCode}
                    />
                </div>
            </div>
        )
    }
}

export default compose(
    connect(
        state => ({
            roadShowSummaryOnline: getRoadShowSummaryOnline(state),
        }),
    )
)(OnlineRoadshowModal);
