import { cancel, fork, takeEvery, takeLatest, put, select, take, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import Types, { UtilityTypes } from 'actions/Types';
import { requestDeal } from 'actions/UtilityActions';
import {
    requestOrders,
    requestTranches,
    requestEcmOrders,
    requestShareDemand,
    requestDemandSummaryByType,
    requestDemandSummaryByShareAndType,
    requestOrdersForAllTranches,
    requestInvestorNames,
    requestOrderSummary,
    requestTranchLists,
    requestUpdateOrderSummary,
    requestUpdateAmendedOrderSummary,
    requestUpdateBookEvolutionSummary,
    requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable,
    dataFetching,
    requestUpdateFullOrderBook,
    changeLoderStatus,
    requestUpdateStages,
    requestStages
} from 'actions/OrderBookActions';
import { tileModuleFetched } from 'actions/UtilityActions';
import {
    isDCMDealType,
    getCurrentDealId,
    getLowestSharePrice,
    getCurrentTrancheId,
    getSelectedOrderBookTab,
    getSelectedInvestor,
    getSelectedTranche,
    getSelectedInterval
} from 'selectors';
import dealFeatures from 'constants/dealFeatures';
import * as tiles from 'constants/homeTiles';
import { ALL_TRANCHES_RECORD, ALL_TRANCHES, ALL_INVESTORS, DEFAULT_TIME_INTERVAL, ORDER_SUMMARY_TABS } from 'constants/orders';

export default (api, dispatch, getState) => {
    const bookEvolutionRequestAPI = {};
    function* worker(action) {
        switch (action.type) {
            case UtilityTypes.EFFECT_REQUEST_FEATURE_DATA: {
                const { dealFeature, dealId } = action;
                const isDCM = yield select(isDCMDealType);

                if (dealFeature !== dealFeatures.ORDERBOOK) {
                    return;
                }

                yield put(requestDeal(dealId, true));

                if (isDCM) {
                    const activeTab = yield select(getSelectedOrderBookTab);
                    const currentTrancheId = yield select(getCurrentTrancheId);
                    const selectedInterval = yield select(getSelectedInterval);
                    const selectedInvestor = yield select(getSelectedInvestor);
                    const selectedTranche = yield select(getSelectedTranche);
                    // If Current Active Tranche is Book Evolution: Auto Refresh
                    if (currentTrancheId === ALL_TRANCHES_RECORD.id) {
                        yield put(dataFetching(true))
                        switch (activeTab) {
                            case ORDER_SUMMARY_TABS.ORDERBOOKSUMMARY:
                                yield put(requestOrderSummary(dealId, selectedInterval.key, selectedInvestor.key, selectedTranche.key, true));
                                break;
                            //IF Active Order Tab is Amended Order Summary
                            case ORDER_SUMMARY_TABS.AMENDEDORDERSUMMARY:
                                const amendedResponse = yield call(bookEvolutionRequestAPI.apiType, bookEvolutionRequestAPI.endPoint);
                                amendedResponse.ok && (yield put(requestUpdateAmendedOrderSummary(amendedResponse.data)));
                                break;
                            // IF Active Order Tab is Order Evolution
                            case ORDER_SUMMARY_TABS.ORDEREVOLUTION:
                                const orderEvolutionResponse = yield call(bookEvolutionRequestAPI.apiType, bookEvolutionRequestAPI.endPoint);
                                if(orderEvolutionResponse.ok) {
                                    yield put(requestUpdateBookEvolutionSummary(orderEvolutionResponse.data))
                                    yield put(requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable(orderEvolutionResponse.data))
                                }
                                break;
                            // IF Active Order Tab is Full Orderbook
                            case ORDER_SUMMARY_TABS.FULLORDERBOOK:
                                yield put(requestOrdersForAllTranches(dealId, true));
                                break;
                            default: return null
                        }
                        yield delay(2000)
                        yield put(dataFetching(false))
                    } else {
                        yield put(changeLoderStatus(null))
                        yield put(requestTranches(dealId, true));
                    } 
                } else {
                    // wait for data (otherwise lowest share price will be not up to date)
                    yield take(UtilityTypes.API_GET_DEAL_RESPONSE);
                    yield put(requestEcmOrders(dealId, true));
                }
                break;
            }
            case Types.API_RECEIVE_TRANCHES: {
                const currentTrancheId = yield select(getCurrentTrancheId);
                if (currentTrancheId) {
                    // full orderbook
                    yield put(requestOrders(currentTrancheId, true));
                } else {
                    // manual set that empty data arrived (that upper function disables fetch for tile)
                    yield put(tileModuleFetched(tiles.HOME_ORDERS_TILE));
                }
                break;
            }

            case Types.API_RECEIVE_ORDERS: {
                const isDCM = yield select(isDCMDealType);
                const dealId = yield select(getCurrentDealId);
                if (!isDCM) {
                    const lowestSharePrice = yield select(getLowestSharePrice);

                    // full orderbook
                    yield put(requestShareDemand(dealId, true));

                    // summary
                    yield put(requestDemandSummaryByShareAndType('investorCountry', dealId, lowestSharePrice, 7, true));
                    yield put(requestDemandSummaryByShareAndType('investorType', dealId, lowestSharePrice, 6, true));
                    yield put(requestDemandSummaryByShareAndType('externalSourceLastModified', dealId, lowestSharePrice, 15, true));
                } else {
                    const currentTrancheId = yield select(getCurrentTrancheId);
                    if (currentTrancheId && currentTrancheId !== ALL_TRANCHES_RECORD.id) {
                        // summary
                        yield put(requestDemandSummaryByType('investorCountry', dealId, currentTrancheId, 6, true));
                        yield put(requestDemandSummaryByType('investorRegion', dealId, currentTrancheId, 6, true));
                        yield put(requestDemandSummaryByType('investorType', dealId, currentTrancheId, 6, true));
                    } else {
                        // manual set that empty data arrived (that upper function disables fetch for tile)
                        yield put(tileModuleFetched(tiles.HOME_ORDERS_TILE));
                    }
                }

                break;
            }

            case Types.UPDATE_CURRENT_TRANCHE: {
                const { trancheId } = action;
                const dealId = yield select(getCurrentDealId);

                if (trancheId && trancheId !== ALL_TRANCHES_RECORD.id) {
                    yield put(requestOrders(trancheId, true));
                }
                else if (trancheId && trancheId === ALL_TRANCHES_RECORD.id) {
                    yield put(dataFetching(true))
                    yield put(requestOrdersForAllTranches(dealId, true))
                    yield put(requestInvestorNames(dealId, true));
                    yield put(requestTranchLists(dealId, true));
                    yield put(requestOrderSummary(dealId, DEFAULT_TIME_INTERVAL.key, ALL_INVESTORS.key, ALL_TRANCHES.key, true));
                    yield delay(2000)
                    yield put(dataFetching(false))
                }

                break;
            }

            case Types.UPDATE_FULL_ORDERBOOK: {
                const apiType = api.getData();
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateFullOrderBook(response.data));
                }
                break;
            }

            case Types.UPDATE_ORDER_SUMMARY: {
                const apiType = api.getData();
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateOrderSummary(response.data, action.isFilter))
                }
                break;
            }

            case Types.UPDATE_BOOK_EVOLUTION_SUMMARY: {
                yield put(dataFetching(true))
                const apiType = api.getData();
                bookEvolutionRequestAPI['apiType'] = apiType.req
                bookEvolutionRequestAPI['endPoint'] = action.endPoint
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateBookEvolutionSummary(response.data, action.isFilter));
                }
                yield put(dataFetching(false))
                break;
            }
            case Types.UPDATE_BOOK_EVOLUTION_SUMMARY_FOR_AMENDED_ORDER_BY_INVESTOR: {
                yield put(dataFetching(true))
                const apiType = api.getData();
                bookEvolutionRequestAPI['apiType'] = apiType.req
                bookEvolutionRequestAPI['endPoint'] = action.endPoint
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateBookEvolutionSummaryForAmendedOrderByInvestorTable(response.data, action.isFilter));
                }
                yield put(dataFetching(false))
                break;
            }

            case Types.UPDATE_AMENDED_ORDER_SUMMARY: {
                yield put(dataFetching(true))
                const apiType = api.getData();
                bookEvolutionRequestAPI['apiType'] = apiType.req
                bookEvolutionRequestAPI['endPoint'] = action.endPoint
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateAmendedOrderSummary(response.data));
                }
                yield put(dataFetching(false))
                break;
            }

            case Types.API_RECEIVE_STAGES: {
                const apiType = api.getData();
                const response = yield call(apiType.req, action.endPoint);
                if (response.ok) {
                    yield put(requestUpdateStages(response.data));
                }
                break;
            }

            case Types.API_UPDATE_STAGES: {
                const apiType = api.postData();
                const response = yield call(apiType.req, action.endPoint, action.params);
                if (response.ok) {
                    const stagesApi = requestStages(action.dealId);
                    const apiType = api.postData();
                    const response = yield call(apiType.req, stagesApi.endPoint);
                    if (response.ok) {
                        yield put(requestUpdateStages(response.data));
                    }                
            }
                break;
            }

            default: {
                break;
            }
        }
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.EFFECT_REQUEST_FEATURE_DATA,
            Types.API_RECEIVE_TRANCHES,
            Types.API_RECEIVE_ORDERS,
            Types.UPDATE_CURRENT_TRANCHE,
            Types.UPDATE_ORDER_SUMMARY,
            Types.UPDATE_FULL_ORDERBOOK,
            Types.UPDATE_BOOK_EVOLUTION_SUMMARY,
            Types.UPDATE_BOOK_EVOLUTION_SUMMARY_FOR_AMENDED_ORDER_BY_INVESTOR,
            Types.API_RECEIVE_STAGES,
            Types.API_UPDATE_STAGES,
            Types.UPDATE_AMENDED_ORDER_SUMMARY,
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};
