import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import responsiveness from '../mixins/responsiveness';

export const ContentAdjuster = styled.div`
    width: calc(100vw - 40px);
    max-width: 1240px;

    margin: 0 auto;
    padding: 0;

    ${responsiveness.largeScreen(`
        width: 1240px;
    `)}

    ${responsiveness.mediumScreen(`
        width: 1240px;
    `)}
`;

export const FeatureContainer = styled.div`
    position: relative;
    padding: 20px 0;
    background-color: #FFF;
`;

export const HeaderContainer = styled(Row)`
    min-height: 77px;
`;
