/* @flow */
import React from 'react';

import { Col } from 'react-bootstrap';
import TranslationText from 'translations/TranslationText';

function createComment(commentText) {
    return commentText.split('\n').reduce((commentBlock, commentLine)=>{
        return commentBlock += `<br />${commentLine}`;
    })
}

const CoordinatorComments = (props) => {
    const comments = props.comments;
    return (
        <Col sm={12} className="text-normal">
            <div className="event-details-divider" style={{ marginBottom: 8, marginTop : 8 }}></div>
            <div className="text-small text-univ-lt" style={{ marginBottom: 5 }}>
                <TranslationText
                    dictionaryKey={"roadshow.coordinator_comments"}
                    defaultText={"Coordinator Comments"}
                />
            </div>
            <div className="text-univ-rg" dangerouslySetInnerHTML={{__html : createComment(comments)}}></div>
        </Col>
    )
}

export default CoordinatorComments