import { uniq } from 'lodash';
import { featurePageIds, overlayIds } from 'constants/featurePages';

export const globalTealiumArgs = {
  page_channel: 'web',
  site_region: 'global',
  site_subregion: 'global',
  site_country: 'global',
  site_entity: 'HSBC Bank Plc',
  site_brand: 'HSBC',
  page_language: 'en',
  page_business_line: 'GBM',
  page_customer_group: 'Business',
};

//
// Track View (handled in Analytics worker)
//
export const trackViewFields = {
    PAGE_NAME: 'page_name',
    PAGE_LANGUAGE: 'page_language',
    PAGE_TYPE: 'page_type',
    PAGE_CATEGORY: 'page_category',
    CUSTOMER_ID: 'customer_id',
    TRANSACTION_ID: 'transaction_id',
    CUSTOMER_GROUP: 'customer_group',
};

export const views = {
    INTERNAL_LOGIN_PAGE: {
        [trackViewFields.PAGE_NAME]: 'mydeal:authentication:logon',
        [trackViewFields.PAGE_TYPE]: 'verification',
        [trackViewFields.PAGE_CATEGORY]: 'authentication',
    },
    EXTERNAL_LOGIN_PAGE: {
        [trackViewFields.PAGE_NAME]: 'mydeal:authentication:logon',
        [trackViewFields.PAGE_TYPE]: 'verification',
        [trackViewFields.PAGE_CATEGORY]: 'authentication',
    },
    EXTERNAL_TOTP_LOGIN_PAGE: {
        [trackViewFields.PAGE_NAME]: 'mydeal:authentication:logon',
        [trackViewFields.PAGE_TYPE]: 'verification',
        [trackViewFields.PAGE_CATEGORY]: 'authentication',
    },
    EXTERNAL_PN_LOGIN_PAGE: {
        [trackViewFields.PAGE_NAME]: 'mydeal:authentication:logon',
        [trackViewFields.PAGE_TYPE]: 'verification',
        [trackViewFields.PAGE_CATEGORY]: 'authentication',
    },
    [featurePageIds.LANDING_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:landing',
        [trackViewFields.PAGE_TYPE]: 'landing',
    },
    [featurePageIds.MY_DOCS_DOCUMENTS_PAGE]: actualTabTitle => ({
        [trackViewFields.PAGE_NAME]: `mydeal:documents:${actualTabTitle} documents`,
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'documents',
    }),
    [featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE]: actualTabName => ({
        [trackViewFields.PAGE_NAME]: `mydeal:investor feedback:${actualTabName}`,
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'investor feedback',
    }),
    [featurePageIds.ORDERBOOK_SUMMARY_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:orderbook:summary',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'orderbook',
    },
    [featurePageIds.ORDERBOOK_FULL_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:orderbook:detail',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'orderbook',
    },
    [featurePageIds.ORDERBOOK_AMENDED_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:orderbook:amended',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'orderbook',
    },
    [featurePageIds.ORDERBOOK_EVOLUTION_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:orderbook:evolution',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'orderbook',
    },
    [featurePageIds.PRICING_DOCUMENTS_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:pricing:document',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'pricing',
    },
    [featurePageIds.ROADSHOW_DAILY_SCHEDULE_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:roadshow:daily schedule',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'roadshow',
    },
    [overlayIds.SESSION_EXPIRY_OVERLAY]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:session expiry overlay',
        [trackViewFields.PAGE_TYPE]: 'other',
    },
    //
    //
    // Added by @MD
    //
    //
    [featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:investor feedback:details',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'investor feedback',
    },
    [featurePageIds.ROADSHOW_DOCUMENT_MODE_PAGE]: {
        [trackViewFields.PAGE_NAME]: 'mydeal:roadshow:document mode',
        [trackViewFields.PAGE_TYPE]: 'other',
        [trackViewFields.PAGE_CATEGORY]: 'roadshow',
    },
};

//
// Track Event
//
const trackEventCategories = {
    CONTENT: 'content',
};

const trackEventActions = {
    BUTTON_CLICK: 'button click',
    ONSITE: 'onsite',
    TAB: 'tab'
};

const formatEvent = (category, action, content) => ({
    event_category: category,
    event_action: action,
    event_content: content,
});

export const events = {
    // ET-0001
    LOGIN_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'login'),
    // ET-0002
    NAVIGATION_MY_DEAL_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'mydeal'),
    NAVIGATION_PRICING_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'pricing'),
    NAVIGATION_INVESTORS_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'investors'),
    NAVIGATION_ROADSHOW_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow'),
    NAVIGATION_ORDERS_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders'),
    NAVIGATION_MY_DOCS_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'documents'),
    NAVIGATION_INVESTOR_FEEDBACK_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'investor feedback'),
    // ET-0003
    DEAL_SWITCH: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'switch deal'),
    LOGOUT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'logout'),
    // ET-0004
    EXPLORE_PRICING: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    EXPLORE_INVESTORS: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    EXPLORE_ROADSHOW: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    EXPLORE_ORDERS: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    EXPLORE_MY_DOCS: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    EXPLORE_INVESTOR_FEEDBACK: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'explore'),
    LATEST_ORDERS: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'latest orders'),
    LATEST_FEEDBACK: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'latest feedback'),
    LATEST_DOCUMENTS: formatEvent(trackEventCategories.CONTENT, trackEventActions.ONSITE , 'documents'),
    // ET-0005
    MY_DOCS_LEFT_NAV: categoryName => formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, `documents left hand nav : ${categoryName}`),
    // ET-0006 (sorting popup removed)
    // MY_DOCS_SORT_DOCUMENTS: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'documents : sort documents'),
    // ET-0007 (TODO - in pricing / roadshow doc mod / documents we have this action - should be splitted on 3 separate events - now is as one general)
    MY_DOCS_OPEN_DOCUMENT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'documents : open document'),
    // PRICING_OPEN_DOCUMENT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'pricing : open document'),
    // ROADSHOW_DOC_MODE_OPEN_DOCUMENT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, ''),
    // ET-0008
    INVESTOR_FEEDBACK_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'investor feedback'),
    INVESTOR_FULL_FEEDBACK_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'full feedback'),
    // ET-0009 (Sentimet chart is missing in excel)
    THEME_DISTRIBUTION_TAB_BUTTON: tab => formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, tab),
    // ET-0010
    INVESTOR_FEEDBACK_SUMMARY_TAB: tabTitle => formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB , tabTitle),
    // ET-0011
    ORDERS_SUMMARY_CAROUSEL_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : order range'),
    // ET-0012
    ORDERS_SUMMARY_FULL_ORDERBOOK_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : full order book'),
    // ET-0013
        // dcm
    ORDERS_SUMMARY_DEMAND_TAB_BY_REGION: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : demand by region'),
    ORDERS_SUMMARY_DEMAND_TAB_BY_INVESTOR_TYPE: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : demand by investor type'),
    ORDERS_SUMMARY_DEMAND_TAB_BY_COUNTRY: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : demand by country'),
        // ecm
    ORDERS_SUMMARY_DEMAND_TAB_BY_DAY: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : demand by day'),
    // ET-0014
    ORDERS_SUMMARY_TOP10_TAB_ORDERS: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : top 10 orders'),
    ORDERS_SUMMARY_TOP10_TAB_NEW_OR_CHANGED: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'orders : top 10 new or changed'),
    // ET-0015
    FULL_ORDERBOOK_SUMMARY_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : order summary'),
    ORDERBOOK_AMENDED_SUMMARY_BOTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : amended summary'),
    BOOK_EVOLUTION_SUMMARY_BOTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : book evolution'),
    // ET-0016
        // dcm
    FULL_ORDERBOOK_TAB_ORDER_DETAIL: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : order detail'),
    FULL_ORDERBOOK_TAB_INVESTOR_TYPE: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : investor type'),
    FULL_ORDERBOOK_TAB_ENGAGEMENT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : engagement'),
        // ecm
    FULL_ORDERBOOK_TAB_DEMAND_DETAIL: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : demand detail'),
    FULL_ORDERBOOK_TAB_ORDER_OVERVIEW: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : order overview'),
    FULL_ORDERBOOK_TAB_ALLOCATION: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'orders : allocation'),
    // ET-0017
    //
    FULL_ORDERBOOK_SORT: (col, sortType) => formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, `orders : sort by ${col} ${sortType}`),
    //
    // ET-0018
    ROADSHOW_CAROUSEL_DATE_CHANGE: formatEvent(trackEventCategories.CONTENT, trackEventActions.TAB, 'roadshow : change date'),
    // ET-0019
    ROADSHOW_FULL_SCHEDULE_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow : full schedule'),
    ROADSHOW_CONTACTS_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow : roadshow contacts'),
    ROADSHOW_ONLINE_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow : online roadshow'),
    // ET-0020
    ROADSHOW_EVENT_DETAIL: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow : event detail'),
    // ET-0021
    SESSION_EXPIRY_LOGOUT: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'session expiry : logout'),
    SESSION_EXPIRY_CONTINUE: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'session expiry : continue'),
    //
    //
    // Added by @MD
    //
    //
    MULTI_LANGUAGE: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'multi language'),
    INVESTOR_FEEDBACK_SUMMARY_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'investor feedback : summary'),
    INVESTOR_FEEDBACK_SORT_FEEDBACK_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'investor feedback : sort feedback'),
    ROADSHOW_MULTITEAM_BUTTON: formatEvent(trackEventCategories.CONTENT, trackEventActions.BUTTON_CLICK, 'roadshow : multi team'),
};

if (process.env.NODE_ENV === 'development') {
    const keys = Object.keys(events);
    if (uniq(keys).length !== keys.length) {
        throw new Error('Analytic Events contains duplicated keys');
    }
}
