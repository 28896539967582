import { cancel, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import Types, { UtilityTypes } from 'actions/Types';
import dealFeatures from 'constants/dealFeatures';
import {
    requestMyDocs,
    requestMyDocsSummary,
} from 'actions/MyDocsActions';

import { handleBatchedRequests } from '../Requests';

export default (api, dispatch, getState) => {
    function* worker(action) {
        const { dealFeature, dealId } = action;

        if (dealFeature !== dealFeatures.MY_DOCS) {
            return;
        }
        
        yield handleBatchedRequests(
            api,
            requestMyDocsSummary(dealId, true),
            requestMyDocs(dealId, true)
        );
    }

    function* watcher() {
        const watcherTasks = yield fork(takeEvery, [
            UtilityTypes.EFFECT_REQUEST_FEATURE_DATA
        ], worker);

        /* eslint-disable-next-line */
        yield fork(takeLatest, Types.KILL_ACTIVE_SAGA_WORKERS, cancelWorkerSaga, watcherTasks);
    }

    function* cancelWorkerSaga(task) {
        yield cancel(task);
        yield fork(watcher);
    }

    return {
        watcher,
        worker,
    };
};