import React, { useCallback } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { uploadFileDocument } from 'Isv/actions/IsvDocumentsAction';
import { selectUploadedFile, selectUploadedTitle } from "Isv/selectors";
import { SecondaryButton } from 'mk-pattern-library-react';
import { defaultBtnStyle } from 'Isv/styles/buttonStyle';
import { Wrapper, Heading, DropzoneInner, DropzoneHeading, DropzoneSubheading, DropzoneWrapper } from './styles';

const DocumentFileUploader = (props) => {

    const prepareContent = (props) => {
        if (props.file) {
            return {
                heading: props.file.name,
                subheading: 'will be uploaded',
                button: 'Change file',
            };
        }
        return {
            heading: 'Drag and drop here',
            subheading: 'to upload your file',
            button: 'Browse File',
        };
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles && acceptedFiles.forEach((file) => {
            props.uploadFileDocument(file, props.title)
        })
    }, [])

    const content = prepareContent(props);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/msword': [],
        }
    })

    return (
        <Wrapper>
            <Heading>Browse or drag and drop to upload file.</Heading>
            <DropzoneInner>
                <DropzoneWrapper {...getRootProps()}>
                    <DropzoneHeading>{content.heading}</DropzoneHeading>
                    <DropzoneSubheading>{content.subheading}</DropzoneSubheading>
                    <p>(jpeg, jpg, png, gif, pdf, doc, docx, xls, xlsx files only. File size does not exceed 7MB)</p>
                    <input {...getInputProps()} />

                    <SecondaryButton
                        size="normal"
                        id="upload-document-button"
                        style={defaultBtnStyle}
                        onClick={onDrop}
                    >
                        {content.button}
                    </SecondaryButton>
                </DropzoneWrapper>
            </DropzoneInner>
        </Wrapper>
    )
}
export default compose(
    connect(
        (state) => ({
            file: selectUploadedFile(state),
            title: selectUploadedTitle(state),
        }),
        (dispatch) => ({
            uploadFileDocument: (file, title) => {
                dispatch(uploadFileDocument(file, title))
            }
        })
    )
)(DocumentFileUploader);
