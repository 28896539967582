import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import { getMyDocsData } from 'selectors';

import TranslationText from 'translations/TranslationText';

import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';
import { DateAsOf } from 'shared/styles/components/date';

import DocumentsList from 'MyDocs/Documents/DocumentList/DocumentList';

class PricingDocuments extends PureComponent {
  render() {
    return (
      <FeatureContainer>
        <HeaderContainer>
          <Col sm={6}>
            <FeatureHeading className="text-xxlarge text-univ-bd">
              <TranslationText
                dictionaryKey={"pricing.pricing_document"}
                defaultText={"Pricing Document"}
              />
            </FeatureHeading>
            <HeadingDescription top={5}>
              <TranslationText
                dictionaryKey={"pricing.the_latest_pricing_document"}
                defaultText={"The latest pricing document"}
              />
            </HeadingDescription>
          </Col>
          <Col sm={6}>
            <DateAsOf className="text-timestamp text-small">
              <TranslationText
                dictionaryKey={"common.data_as_of_<date_time>"}
                replace={[
                  ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)]
                ]}
                defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)}`}
              />
            </DateAsOf>
          </Col>
        </HeaderContainer>
        <Row
          id="pricing-body"
          style={{ padding: '0 0 0 15px' }}
        >
            <Col sm={12} md={12} lg={12} style={{ backgroundColor: '#fff', padding: 0 }}>
                <DocumentsList data={this.props.myDocs} />
            </Col>
        </Row>
      </FeatureContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  myDocs: getMyDocsData(state)
    .filter(doc => doc.documentVersionId === state.pricingData.pricingDocumentId),
  dateReceived: state.myDocsData.dateReceived,
});

export default connect(mapStateToProps)(PricingDocuments);
