import { put, call, fork, takeEvery } from 'redux-saga/effects';
import Types from 'actions/Types';

import { requestSiteText } from 'actions/UtilityActions';
import { setSiteText } from 'actions/LoginActions';

export default (api, dispatch, getState) => {
    function* worker({ langCode, siteTextType }) {
        const siteTextState = getState().login.siteText;
        
        if(siteTextState[siteTextType.name]) {
            return;
        }

        const apiType = api.getHtml();
        const action = requestSiteText(langCode, siteTextType.path)

        const response = yield call(apiType.req, action.endPoint);

        // NOTE: Temporarily hardcoded this to en-gb, for dynamic we can change it to langCode
        yield put(setSiteText('en-gb', siteTextType, response.data));
    }

    function* watcher() {
         yield fork(takeEvery, Types.OPEN_SITE_TEXT, worker);
    }

    return {
        watcher,
        worker,
    };
};
