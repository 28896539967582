import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import PopupSystem from 'shared/components/PopupSystem';
import Popup, { Divider } from 'shared/components/Popup';
import Scrollable from 'shared/components/Scrollable';

import { getSelectedLanguage, isModalOpen, makeGetSiteTextSelector } from 'selectors'
import { openModal, closeModal } from 'actions/UtilityActions';
import { openSiteText } from 'actions/LoginActions';
import { SvgIcon } from 'shared/components/Icon';
import HsbcLink from 'shared/accessibility/components/Link';

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    ${Divider} {
        display: block;
        margin: 1rem 0;
    }
`;

const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
`;

const ModalIcon = props => (
  <SvgIcon
      icon="pop-up-window"
      aria-hidden
      style={{ fill: 'rgb(0, 0, 0)', marginRight: 10 }}
  />
);

class SiteTextModal extends Component {

  openModalClick = () => {
    this.props.openModal(this.props.modalId, this.props.langCode, this.props.siteTextType)
  }

  closeModalClick = () => {
    this.props.closeModal(this.props.modalId);
  }

  render() {
    return (
      <PopupSystem
        centered
        backgroundLayer
        modalID={this.props.modalId}
        isModalOpen={this.props.isModalOpen}
        closeModal={this.closeModalClick}
        primaryButton
        primaryButtonContent="Close"
        primaryButtonAction={this.closeModalClick}
        primaryButtonKey="common.close"
        closeIcon
        clickableElement={                  
            <HsbcLink
              id={`${this.props.modalId}-btn`}        
              title={this.props.btnText}
              disabled={this.props.isBtnDisabled}
              onClick={this.openModalClick}
              aria-label={this.props['aria-label']}
              role="link"
              aria-haspopup>
                <ModalIcon />{this.props.linkText || 'Not configured'}
            </HsbcLink>
        }
        content={
          <Popup
              static
              width={this.props.width || 1250}
              primaryButton
              primaryButtonContent="Close"
              primaryButtonAction={this.closeModalClick}
              primaryButtonKey="common.close"
          >
              <ModalContent>
                  <Scrollable
                      scrollHeight={ this.props.width * .31 || window.innerHeight * .5 }
                  >
                      <ScrollableContent>
                        <div dangerouslySetInnerHTML={{__html: this.props.siteText}} />
                      </ScrollableContent>
                  </Scrollable>
              </ModalContent>
          </Popup>
        }
      />
    )
  }
}

const makeMapStateToProps = (state, ownProps) => {
  const getSiteText = makeGetSiteTextSelector(ownProps.siteTextType.name);
  const mapStateToProps = (state, ownProps) => ({
      langCode: getSelectedLanguage(state),
      siteTextType: ownProps.siteTextType,
      isModalOpen: isModalOpen(state, ownProps.modalId),
      siteText: getSiteText(state),
      'aria-label': ownProps['aria-label'],
  });

  return mapStateToProps;
}

export default connect(
  makeMapStateToProps,
  dispatch => ({
    openModal: (modalId, langCode, siteTextType) => {
      dispatch(openSiteText(langCode, siteTextType));
      dispatch(openModal(modalId));
    },
    closeModal: modalId => dispatch(closeModal(modalId)),
  })
)(SiteTextModal);
