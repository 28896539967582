import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Link, browserHistory } from 'react-router';
import get from 'lodash/get';

import { featurePageIds } from 'constants/featurePages';

import { getHomeTileStatus, getDCMLatestOrders, getTranches } from 'selectors';

import { anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';
import { dateFormatters, numberFormatters } from 'shared/functions/formatters';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import { Col, Row } from 'shared/components/Grid';
import Loading from 'shared/components/Loading';
import ResponsiveEllipsis from 'shared/components/ResponsiveEllipsis';

import { typography, backgrounds } from 'shared/styles/variables/theme';

import {
    TileContainerOuter,
    TileHeader,
    TileContent,
    TileContentInner,
    DateContent,
    ChevronIcon,
    EmptyContent
} from './../styles.js';

const InvestorName = styled.h4`
    margin-top: 0;
    line-height: 1;
    font-family: ${typography.family.light};
`;

const Divider = styled.div`
    border-bottom: 1px solid ${backgrounds.gray};
`;

const Description = styled.div`
    margin-top: 5px;
    font-size: ${typography.sizes['12px']};
`;
const Value = styled.div`
    font-size: ${typography.sizes['14px']};
`;

const url = {
    pathname: featurePageIds.ORDERBOOK_SUMMARY_PAGE,
};

class LatestTranche extends PureComponent {
    navigateEvent = (e) => {
        e.preventDefault();
        if (anchorEventHandling(e)) {
            browserHistory.push(url);
            this.props.linkChange();
        }
    }

    render() {
        return (
            <TileContainerOuter>
                {
                    this.props.isFetching ? (
                        <Loading
                            text={this.props.loadingText}
                            size="small"
                        />
                    ) : (
                        <React.Fragment>
                            <TileHeader>
                                <Link
                                    id='link-dcm-lts-order'
                                    to={url}
                                    onKeyPress={this.navigateEvent}
                                    onClick={this.navigateEvent}
                                    aria-label={this.props.linkAriaLabel}
                                >
                                    {this.props.linkText}
                                    <ChevronIcon />
                                </Link>
                            </TileHeader>
                            <TileContent>
                                <TileContentInner>
                                    {
                                        this.props.order ? (
                                            <div>
                                                <InvestorName title={this.props.order.investorLongName}>
                                                    <ResponsiveEllipsis
                                                        maxLine={2}
                                                        text={this.props.order.investorLongName}
                                                    />
                                                </InvestorName>
                                                <Divider />
                                                <Row>
                                                    <Col size={6}>
                                                        <Description>{this.props.trancheText}</Description>
                                                        <Value title={this.props.order.trancheNameShort}>
                                                            <ResponsiveEllipsis
                                                                maxLine={2}
                                                                text={this.props.order.trancheNameShort}
                                                            />
                                                        </Value>
                                                    </Col>
                                                    <Col size={6}>
                                                        <Description>{this.props.totalOrderText} (m)</Description>
                                                        <Value>
                                                            {
                                                                numberFormatters.million(this.props.order.firmAmount)
                                                            }
                                                        </Value>
                                                    </Col>
                                                </Row>
                                                <DateContent>
                                                    {
                                                        dateFormatters.dateTime(this.props.order.externalSourceLastModified, this.props.timeZone)
                                                    }
                                                </DateContent>
                                            </div>
                                        ) : (
                                            <EmptyContent>
                                                {this.props.notAvailable}
                                            </EmptyContent>
                                        )
                                    }
                                </TileContentInner>
                            </TileContent>
                        </React.Fragment>
                    )
                }
            </TileContainerOuter>
        );
    }
}

export default compose(
    connect(
        state => ({
            isFetching: !getHomeTileStatus(state, 'homeFetchedOrders'),
            order: getDCMLatestOrders(state)
                .slice(0, 1)
                .map(order => ({
                    ...order,
                    trancheNameShort: get(getTranches(state), '[0].trancheNameShort'),
                }))[0],
            timeZone: dateFormatters.timeAbbr(),
        }),
    ),
    withTranslate(
        translate => ({
            // link
            linkText: translate('home_page.latest_orders', 'Latest orders'),
            linkAriaLabel: translate('home_page.navigate_to_latest_orders', 'Navigate to latest orders'),
            // tranche
            trancheText: translate('home_page.tranche', 'Tranche'),
            totalOrderText: translate('home_page.total_order', 'Total Order'),
            // content
            notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
            loadingText: translate('home_page.loading_latest_order', 'Loading latest order'),
        })
    ),
    withAnalytics2(
        (ev, events) => ({
            linkChange: () => ev(events.LATEST_ORDERS),
        })
    )
)(LatestTranche);
