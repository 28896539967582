import React, { PureComponent } from 'react';
import { ButtonGroup } from 'react-bootstrap';

import { HsbcButton } from 'mk-pattern-library-react';

const sizes = {
    large: 'not-defined',
    default: 36,
    small: 28,
}

class ButtonGroupBase extends PureComponent {
    render() {
        const size = sizes[this.props.size || 'default'];
        return (
            <ButtonGroup>
                {
                    this.props.buttons.map(btn => (
                        <HsbcButton
                            id={btn.id || 'btn'}
                            aria-pressed={btn.active}
                            aria-label={btn.label || btn.text}
                            className={"btn-segment" + (btn.active ? " active" : "")}
                            style={{ height: size }}
                            onClick={btn.action}
                        >
                            {btn.text}
                        </HsbcButton>
                    ))
                }
            </ButtonGroup>
        )
    }
}

export default ButtonGroupBase;
