import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { SiteConstants, NiceScale } from 'mk-pattern-library-react';

import TranslationText, { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';

import { typography } from 'shared/styles/variables/theme';

const ColumnLabel = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;

  width: 100%;
  text-align: center;

  color: #333;
  font-size: ${typography.sizes['12px']};
  font-family: ${typography.family.bold};

  transform: translateY(-50%);
  -webkit-transform: translateY(-50);

  padding-left: 6px;
`;

const HSBC_RAG_COLORS = SiteConstants.HSBC_RAG_COLORS;

const FeedbackChartSentiment = (props) => {
    const { chartData } = props;
    const portraitMode = window.innerWidth < 1024;
    const chartHeight = portraitMode ? 255 : 145;

    chartData.series = chartData.series.slice(0, 2);
    let maxNum = 0;
    let maxRows = 0;
    chartData.series.forEach((series) => {
        maxRows = series.data.length > maxRows ? series.data.length : maxRows;
        series.data.forEach((dataSeries) => {
            maxNum = dataSeries.value > maxNum ? dataSeries.value : maxNum;
        });
    });

    const rowHeight = chartHeight / maxRows;
    let niceScale = {};
    niceScale = maxNum <= 1
        ? niceScale = { ticks: [0, 1, 2] }
        : (new NiceScale(0, maxNum, props.maxYTicks || 6).niceScale());

    return (
      <Row style={{ fontSize: typography.sizes['12px'], background: 'white' }} className="feedback-chart-sentiment" aria-hidden>
        <Col style={{ padding: 0 }} sm={12}>

          {
            chartData.series.map((series, seriesIndex) => {
              let highlightColor;

              switch (seriesIndex) {
                case 0:
                  highlightColor = HSBC_RAG_COLORS.HSBC_RAG_GREEN;
                  break;
                case 1:
                  highlightColor = HSBC_RAG_COLORS.HSBC_RAG_RED;
                  break;
                case 2:
                  highlightColor = HSBC_RAG_COLORS.HSBC_RAG_AMBER;
                  break;
                default:
                  highlightColor = HSBC_RAG_COLORS.HSBC_RAG_GREEN;
              }

              return (
                <Row
                  key={series.seriesTitle}
                  className="feedback-chart-sentiment-series"
                  style={{
                    borderTop: '1px solid #D8D8D8',
                    borderBottom: seriesIndex === chartData.series.length - 1 ? '1px solid #D8D8D8' : 'none',
                    borderLeft: `solid 8px ${highlightColor}`,
                    position: 'relative',
             
                  }}>
                  <Col xs={4} style={{ position: 'relative', paddingRight: 0, paddingLeft: 20, paddingBottom: 20, borderRight: '1px solid #D8D8D8' }}>

                    <div
                      className="feedback-chart-sentiment-series-title"
                      style={{ paddingBottom: 10, paddingTop: 20, fontFamily: typography.family.bold }}
                      
                    >
                      {translationString(mapThemeTypeOrSentimentToKey(series.seriesTitle), props.language, series.seriesTitle)} {translationString("investor_feedback.themes", props.language, "Themes")}
                    </div>
                    <div style={{ height: chartHeight - ((maxRows - series.length - 1) * rowHeight) }}>
                      {
                          series.data.map((dataSeries, index) => {
                            const lastIndex = index === series.data.length - 1;

                            return (
                              <div
                                key={dataSeries.label}
                                style={{
                                  position: 'relative',
                                  borderBottom: lastIndex ? 'none' : '1px solid #D8D8D8',
                                  height: rowHeight,
                                  fontFamily: typography.family.normal,
                                }}>
                                <div style={{
                                  height: '100%',
                                  lineHeight: `${rowHeight}px`,
                                  verticalAlign: 'middle',
                                }}>
                                  {dataSeries.label}
                                </div>
                              </div>
                            );
                          },
                        )
                      }
                    </div>
                  </Col>
                  <Col
                    xs={7}
                    style={{
                      position: 'relative',
                      top: 47,
                      height: chartHeight - ((maxRows - series.length - 1) * rowHeight),
                      paddingLeft: 0,
                    }}
                  >
                    {
                      niceScale.ticks.map((tick, index) => (
                        <div
                          key={`${tick}-${index}`}
                          style={{
                            position: 'absolute',
                            left: `${(100 / (niceScale.ticks.length - 1)) * index}%`,
                            top: -47,
                            bottom: -20,
                            transform: 'translateX(-50%)',
                            WebkitTransform: 'translateX(-50%)',
                            width: 1,
                            borderRight: `solid 1px ${index === 0 ? 'transparent' : '#D8D8D8'}`,
                          }}
                        />
                      ))
                  }
                    <div>
                      {
                        series.data.map((dataSeries) => {
                          const barStyle = {
                            height: rowHeight,
                            width: `${(dataSeries.value / (niceScale.ticks[niceScale.ticks.length - 1])) * 100}%`,
                            backgroundColor: highlightColor,
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            WebkitTransform: 'translateY(-50%)',
                            borderTop: `solid ${0.1 * rowHeight}px #fff`,
                            borderBottom: `solid ${0.1 * rowHeight}px #fff`,
                          };

                        return (
                          <div
                            key={dataSeries.label}
                            className="feedback-chart-sentiment-series-bar"
                            style={{
                                height: rowHeight,
                                position: 'relative',
                            }}
                          >
                            <div style={barStyle} />
                          </div>
                        );
                      },
                    )
                  }
                    </div>
                  </Col>
                </Row>
              );
            },
          )
        }

          <Row
            style={{
              position: 'relative',
              height: 60,
              // borderBottom: '1px solid #D8D8D8',
            }}
          >
            <Col xs={4} />
            <Col xs={7} style={{ position: 'relative' }}>
              {
                niceScale.ticks.map((tick, index) => (
                  <div
                    key={`${tick}-${index}`}
                    style={{
                    position: 'absolute',
                    left: `${(100 / (niceScale.ticks.length - 1)) * index}%`,
                    top: 5,
                    transform: 'translateX(-50%)',
                    WebkitTransform: 'translateX(-50%)',
                    color: '#333',
                    fontFamily: typography.family.normal,
                    fontSize: typography.sizes['12px'],
                  }}>
                    <div />
                    {
                      Math.floor(tick) === tick ? tick : ''
                    }
                  </div>
                ))
              }
            </Col>
            <Col xs={4}/>
            <Col
                xs={8}
                style={{
                  position: 'relative',
                }}
              >
                <ColumnLabel>
                  <TranslationText
                    dictionaryKey={'investor_feedback.number_of_responses'}
                    defaultText={'Number of Responses'}
                  />
                </ColumnLabel>
              </Col>
          </Row>
        </Col>
      </Row>
    );
};

export default withLanguage(FeedbackChartSentiment);
