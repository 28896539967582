import IsvTypes, { IsvUtilityTypes } from './IsvTypes';

const apiFailure = (message) => ({
  type: IsvTypes.API_FAILURE,
  message,
});

const apiRequest = (isFetching) => ({
  type: IsvTypes.API_REQUEST,
  isFetching,
});

const receiveIsvDeal = dealsData => ({
  type: IsvUtilityTypes.API_GET_ISV_DEAL_RESPONSE,
  dealsData,
});

const requestIsvDeal = (dealId, loader) => ({
  type: IsvUtilityTypes.API_GET_ISV_DEAL_REQUEST,
  endPoint: `/Deals/GetDealByID?id=${dealId}`,
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveIsvDeal,
  loader,
});

const requestIsvDealClick = dealId => ({
  type: IsvUtilityTypes.API_REQUEST_ISV_DEAL_CLICK,
  dealId
});

const receiveIsvDeals = allDeals => ({
  type: IsvUtilityTypes.API_GET_ISV_DEALS_RESPONSE,
  allDeals,
});

const requestIsvDeals = (loader) => ({
  type: IsvUtilityTypes.API_GET_ISV_DEALS_REQUEST,
  endPoint: '/Deals/GetDeals',
  params: {},
  ajaxType: 'GET',
  onSuccess: receiveIsvDeals,
  loader,
});

const setupIsvDeal = (dealId, redirect) => ({
  type: IsvUtilityTypes.SETUP_ISV_DEAL,
  dealId,
  redirect,
});

const openOneWayLetter = (modalId) => ({
  type: IsvUtilityTypes.OPEN_ONE_WAY_LETTER,
  modalId,
});

const acceptOneWayLetter = (modalId) => ({
  type: IsvUtilityTypes.ACCEPT_ONE_WAY_LETTER,
  modalId,
});

const isvSwitchDeal = (dealId) =>({
  type: IsvUtilityTypes.ISV_SWITCH_DEAL,
  dealId
});

const receiveAcceptLetter=(status)=>({
  type: IsvUtilityTypes.API_ACCEPT_LETTER_RESPONSE,
  status,
});

const acceptLetter = dealId => ({
  type: IsvUtilityTypes.API_ACCEPT_LETTER,
  endPoint: `/Deals/AcceptOneWayLetter?dealId=${dealId}`,
  ajaxType: 'PUT',
});

const acceptLetterClick = dealId => ({
  type: IsvUtilityTypes.API_ACCEPT_LETTER_CLICK,
  dealId
})

const isvBaseFetchCompleted = dealId => ({
  type: IsvUtilityTypes.ISV_BASE_FETCH_COMPLETED,
  dealId
});

const dataFetching = (dataFetching) => ({
  type: IsvUtilityTypes.DATAFETCHING,
  dataFetching,
});

export {
    apiRequest,
    apiFailure,
    requestIsvDeal,
    requestIsvDeals,
    setupIsvDeal,
    openOneWayLetter,
    acceptOneWayLetter,
    isvSwitchDeal,
    acceptLetter,
    receiveAcceptLetter,
    requestIsvDealClick,
    acceptLetterClick,
    isvBaseFetchCompleted,
    receiveIsvDeal,
    dataFetching
  };
