import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PrimaryButton } from 'mk-pattern-library-react';

import withTranslate from 'shared/hoc/withTranslate';
import TranslationText from 'translations/TranslationText';
import { translationString } from 'translations/TranslationText';
import { selectLoginFormInvalidFields } from 'selectors';
import LanguagePicker from './LanguagePicker/LanguagePicker';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MDSpinner from 'react-md-spinner';

import {
  loginFormChanged,
  setInvalidFields,
  setAuthType,
  loginFormExtSubmit,
} from 'actions/LoginActions';

import { featurePageIds } from 'constants/featurePages';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import { Navbar } from 'react-bootstrap';
import logo from '../mk-pattern-library-react/images/HSBC_Logo.svg';
import { Link } from 'react-router';

import { typography } from 'shared/styles/variables/theme'

import { SvgIcon } from 'shared/components/Icon';
import TermsAndConditionsLink from './TermsAndConditionsLink';
import SiteTextModal from './SiteTextModal';

import { EULA_MODAL_ID, CONTACT_US_MODAL_ID } from 'constants/modalIds';
import SITE_TEXT_TYPES from 'constants/siteTextTypes';

import { AUTH_TYPES } from 'constants/laasConfig';
import { components, outlines } from 'shared/styles/variables/theme';
import {
  Logo,
  LoginContainer,
  InnerContainer,
  SecurityKeyInfo,
  HeaderSection,
  LoginForm,
  Header,
  LoginExtFormFields,
  UserNameContainer,
  ButtonContainer,
  StyledExtInput,
  ExtFooterLinks,
  Left,
  Right,
  ExtOptions,
  OptionOne,
  OptionTwo
} from './styles/Login';


const loginBtnStyle = {
    fontSize: typography.sizes['16px'],
    padding: '14px 20px',
    color: '#FFFFFF',
    border: 'none',
    width: 140
};

const radioStyle = {
  margin:'0px'
}

const radioGroupStyle = {
  display:'inline-block', 
  marginTop:'15px',
}
const contactVirtualSvgStyle = {
  height:40,
  width:40,
  marginLeft:'20px'
}
const securityPasswordSvgStyle = {
  height:40,
  width:40,
  marginLeft:'20px'
}
const LanguagePickerContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 10px;
`;

const ModalIcon = props => (
  <SvgIcon
      icon="pop-up-window"
      aria-hidden
      style={{ fill: 'rgb(0, 0, 0)', marginRight: 10 ,marginLeft: "-11px"}}
  />
);

const StyledLink = styled.a`
    color:black
    ${components.link['--default']}

    &:focus {
        ${components.link['--state']['--focus']}
        outline: ${outlines.dark};
    }

    &:hover {
        ${components.link['--state']['--hover']}
        text-decoration: underline;
    }

    &.disabled {
        ${components.link['--state']['--disabled']}
    }
`;

const url = {
  pathname: featurePageIds.DATAPRIVACY_NOTICE_PAGE_EXTERNAL,
};
const cookieurl = {
  pathname: featurePageIds.COOKIE_NOTICE_PAGE_EXTERNAL,
};

class ExternalLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authTypeField : AUTH_TYPES.MOBILE_APPROVE
    }

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onAuthTypeChange = this.onAuthTypeChange.bind(this);

    this.userNameField = React.createRef();
  }

  componentDidMount() {
    this.props.viewChange();
    this.props.setInvalidFields({
      userNameField: true
    });
    this.userNameField.current.focus();
    this.props.setAuthType('');
  }

  componentWillUnmount() {
    const transmitContainer = document.querySelector('#transmitContainer');
    if(transmitContainer) {
      transmitContainer.parentNode.removeChild(transmitContainer);
    }
  }

  onSubmitClick() {
    this.props.loginFormExtSubmit(this.userNameField.current.value, this.state.authTypeField);
  }

  onUserNameChange(e) {
    this.props.loginFormChanged('userNameField', this.userNameField.current.value);
  }

  onAuthTypeChange(e) {
    this.setState({authTypeField : e.target.value})
  }

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitClick();
    }
  }

  render() {
    return (
      <LoginContainer className="internal-login">
        <Navbar style={{ height: 120.5 }}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to={'/'}><Logo src={logo} alt="HSBC"/>MyDeal</Link>
            </Navbar.Brand>
            <LanguagePickerContainer>
              <LanguagePicker />
            </LanguagePickerContainer>
          </Navbar.Header>
        </Navbar>
        <InnerContainer>
          <SecurityKeyInfo>
            <HeaderSection>
              <h2>
                <TranslationText
                  dictionaryKey={"login_screen.select_auth_type_header"}
                  defaultText={"How do you want to authenticate on your mobile device?"}
                />
              </h2>
            </HeaderSection>
            <ExtOptions>
              <OptionOne>
                <h5>
                  <TranslationText
                    dictionaryKey={"login_sceen.push_notification"}
                    defaultText={"Send a notification to my mobile"}
                  />
                </h5>
                <p>
                  <TranslationText
                    dictionaryKey={"login_sceen.pn_descriptive_msg"}
                    defaultText={"Authenticate faster by receiving a message on your mobile device"}
                  />
                </p>
              </OptionOne>
              <OptionTwo>
                <h5>
                  <TranslationText
                    dictionaryKey={"login_sceen.generate_security_code"}
                    defaultText={"Generate a security code on my mobile"}
                  />
                </h5>
                <p>
                  <TranslationText
                    dictionaryKey={"login_sceen.sc_descriptive_msg"}
                    defaultText={"Get a code from the MyDeal mobile app to enter on the web screen"}
                  />
                </p>
              </OptionTwo>
            </ExtOptions>  
            <ExtFooterLinks>
              <TermsAndConditionsLink />
              <span>&nbsp;&nbsp;&nbsp;</span>
              <SiteTextModal
                modalId={CONTACT_US_MODAL_ID}
                siteTextType={SITE_TEXT_TYPES.CONTACT_US}
                linkText={this.props.contactUsText}
                tabIndex={0}
                width={630}
                aria-label={
                    translationString(
                        'settings.open_contact_us',
                        this.props.language,
                        'Open contact us'
                    )
                }
              >
              </SiteTextModal>
              <SiteTextModal
                modalId={EULA_MODAL_ID}
                siteTextType={SITE_TEXT_TYPES.EULA}
                linkText={this.props.eulaText}
                tabIndex={0}
                aria-label={
                    translationString(
                        'settings.open_eula',
                        this.props.language,
                        'Open end user license agreement'
                    )
                }>
              </SiteTextModal>
              <StyledLink style={{display:"inline-block",marginLeft: '10px'}}>
              <ModalIcon />
               <Link  to={url} target="_blank"  style={{color:"black"}} >{this.props.dataPrivacyPolicyText}</Link>
              </StyledLink>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <StyledLink>
              <ModalIcon />
               <Link to={cookieurl} target="_blank"  style={{color:"black"}} >{this.props.cookiePolicyText}</Link>
              </StyledLink>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </ExtFooterLinks>
          </SecurityKeyInfo>
          <LoginForm>
            <Header>
              <h2>
                <TranslationText
                  dictionaryKey={"login_screen.log_on_to_hsbc_mydeal"}
                  defaultText={"Log on to HSBC MyDeal"}
                />
              </h2>
            </Header>
            <LoginExtFormFields>
              <UserNameContainer>
                <div>
                  <label for="userName">
                    <TranslationText
                      dictionaryKey={"login_screen.username"}
                      defaultText={"Username"}
                    />
                  </label>
                </div>
                <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                <StyledExtInput
                  id="userName"
                  name="userName"
                  ref={this.userNameField}
                  type="text"
                  autoComplete="false"
                  onChange={this.onUserNameChange}
                  onKeyDown={this.onKeyDown}
                />
                <RadioGroup 
                  name="authTypeField"
                  value={this.state.authTypeField}
                  style={radioGroupStyle}
                  onChange={this.onAuthTypeChange} 
                >
                  <Left>
                      <SvgIcon
                        icon="contact-virtual"
                        aria-hidden
                        style={contactVirtualSvgStyle}
                      />
                    <p>
                      <TranslationText
                        dictionaryKey={"login_sceen.push_notification"}
                        defaultText={"Send a notification to my mobile"}
                      />
                    </p>
                    <FormControlLabel
                      style={radioStyle}
                      value={AUTH_TYPES.MOBILE_APPROVE}
                      control={<Radio color="default" />}
                    />
                  </Left>
                  <Right>
                      <SvgIcon
                        icon="security-password"
                        aria-hidden
                        style={securityPasswordSvgStyle}
                        
                      />
                    <p>
                      <TranslationText
                        dictionaryKey={"login_sceen.generate_security_code"}
                        defaultText={"Generate a security code on my mobile"}
                      />
                    </p>
                    <FormControlLabel
                      style={radioStyle}
                      value={AUTH_TYPES.TOTP}
                      control={<Radio color="default" />}
                    />
                  </Right>
                </RadioGroup>  
              </UserNameContainer>
            </LoginExtFormFields>
            <ButtonContainer>
              <PrimaryButton
                size="normal"
                loading={true}
                onClick={this.onSubmitClick}
                disabled={this.props.invalid || this.props.pendingAuthentication}
                style={loginBtnStyle}
              >
                {
                  this.props.pendingAuthentication
                    ? <MDSpinner size={20} singleColor={'#D8D8D8'} style={{ marginRight: 10, bottom: 2}} /> : null
                }
                {translationString("login_screen.continue", this.props.language, 'Continue')}
              </PrimaryButton>
            </ButtonContainer>
          </LoginForm>
        </InnerContainer>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  invalid: selectLoginFormInvalidFields(state).length > 0,
  unauthorised: state.login.error,
  pendingAuthentication: state.login.pendingAuthentication,
  pristine: state.login.pristine,
});

const mapDispatchToProps = dispatch => ({
  loginFormChanged: (fieldName, fieldValue) => {
    dispatch(loginFormChanged(fieldName, fieldValue))
  },
  loginFormExtSubmit:(userNameField, authTypeField) => {
    dispatch(loginFormExtSubmit(userNameField, authTypeField))
  },
  setInvalidFields: (fields) => {
    dispatch(setInvalidFields(fields));
  },
  setAuthType: (aT) => {
    dispatch(setAuthType(aT));
  },
});

export default compose(
  withAnalytics((ev, view) => ({
    viewChange: () => view(featurePageIds.EXTERNAL_LOGIN_PAGE),
    loginSubmit: () => ev(events.LOGIN_BUTTON),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate((translate, props) => ({
    containerRegionAriaLabel: translate('settings.settings', 'Settings'),
    termsAndConditionsText: translate('settings.terms_and_conditions', 'Terms and Conditions'),
    contactUsText: translate('settings.contact_us', 'Contact Us'),
    dataPrivacyPolicyText: translate('settings.privacy_notice', 'Privacy Notice'),
    eulaText: translate('settings.eula', 'End User License Agreement'),
    cookiePolicyText: translate('settings.cookie_notice', 'Cookie Notice'),
    faqText: translate('settings.faq', 'Frequently Asked Questions'),
}))
)(ExternalLogin);