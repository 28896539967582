import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, browserHistory } from 'react-router';
import get from 'lodash/get';

import { featurePageIds } from 'constants/featurePages';

import { getHomeTileStatus, getDCMLatestOrders } from 'selectors';

import { anchorEventHandling } from 'shared/accessibility/functions/keyboardEvents';
import { dateFormatters, numberFormatters } from 'shared/functions/formatters';
import { withAnalytics2 } from 'shared/hoc/withAnalytics';
import withTranslate from 'shared/hoc/withTranslate';

import TranslationText from 'translations/TranslationText';
import Loading from 'shared/components/Loading';

import { typography } from 'shared/styles/variables/theme';

import Table, {
    firstHeaderAlignedLeftStyle,
    lastHeaderAlignedRightStyle,
    lastRowAlignedRightStyle
} from 'shared/components/Table';

import {
    TileContainerOuter,
    TileHeader,
    TileContent,
    TileContentInner,
    ChevronIcon,
    EmptyContent
} from './../styles.js';

const url = {
    pathname: featurePageIds.ORDERBOOK_SUMMARY_PAGE,
};

const Columns = props => ([{
  dataField: 'investor',
  text: props.investorText,
  attrs: {
    role: "rowheader",
  },
  headerStyle: {
    ...firstHeaderAlignedLeftStyle,
    padding: '8px 2px 8px 8px',
    fontSize: typography.sizes['12px'],
    borderTop: 'none',
  },
  formatter: (val, data, index) => (
    <span title={val.trim()}>{val}</span>
  ),
  style: {
    padding: '0 10px 0 8px',
    fontSize: typography.sizes['12px'],
    height: 30,
  },
  classes: "standard-table-cell",
}, {
  dataField: 'modified',
  headerFormatter: (column, colIndex, components) => (
    <React.Fragment>
        <TranslationText
            dictionaryKey={"home_page.modified"}
            defaultText={"Modified"}
        />
        <br />({props.timeZone})
    </React.Fragment>
  ),
  headerStyle: {
    ...lastHeaderAlignedRightStyle,
    fontSize: typography.sizes['12px'],
    borderTop: 'none',
    width: '80px',
  },
  style: {
    ...lastRowAlignedRightStyle,
    textAlign: 'right',
    paddingRight: 10,
    fontSize: typography.sizes['12px'],
    height: 30,
  },
}]);

class LatestOrders extends PureComponent {
    navigateEvent = (e) => {
        e.preventDefault();
        if (anchorEventHandling(e)) {
            browserHistory.push(url);
            this.props.linkChange();
        }
    }

    render() {
        return (
            <TileContainerOuter>
                {
                    this.props.isFetching ? (
                        <Loading
                            text={this.props.loadingText}
                            size="small"
                        />
                    ) : (
                        <React.Fragment>
                            <TileHeader>
                                <Link
                                    id='link-d-d-lts-orders'
                                    to={url}
                                    onKeyPress={this.navigateEvent}
                                    onClick={this.navigateEvent}
                                    aria-label={this.props.linkAriaLabel}
                                >
                                    {this.props.linkText}
                                    <ChevronIcon />
                                </Link>
                            </TileHeader>
                            <TileContent>
                                {
                                    this.props.orders.length ? (
                                        <Table
                                            classes='fixed'
                                            caption={this.props.tableCaption}
                                            keyField='investor'
                                            data={this.props.orders}
                                            columns={Columns(this.props)}
                                        />
                                    ) : (
                                        <TileContentInner>
                                            <EmptyContent>
                                                {this.props.notAvailable}
                                            </EmptyContent>
                                        </TileContentInner>
                                    )
                                }
                            </TileContent>
                        </React.Fragment>
                    )
                }
            </TileContainerOuter>
        );
    }
}

export default compose(
    connect(
        state => ({
            isFetching: !getHomeTileStatus(state, 'homeFetchedOrders'),
            orders: getDCMLatestOrders(state)
                .slice(0, 4)
                .map(item => ({
                    investor: item.investorLongName,
                    amount: numberFormatters.million(
                        get(item, 'shareDemandSummary.demandAtBottomSharePrice.demandAmount')
                    ),
                    modified: dateFormatters.dayMonth(item.externalSourceLastModified),
                })),
            timeZone: dateFormatters.timeAbbr(),
        }),
    ),
    withTranslate(
        translate => ({
            // link
            linkText: translate('home_page.latest_orders', 'Latest orders'),
            linkAriaLabel: translate('home_page.navigate_to_latest_orders', 'Navigate to latest orders'),
            // table
            investorText: translate("home_page.investor", "Investor"),
            tableCaption: translate("home_page.caption_last_4_orders_table", "Table showing the latest 4 orders"),
            // content
            notAvailable: translate('others.content_not_yet_available', 'Content Not Yet Available'),
            loadingText: translate('home_page.loading_latest_orders', 'Loading latest orders'),
        })
    ),
    withAnalytics2(
        (ev, events) => ({
            linkChange: () => ev(events.LATEST_ORDERS),
        })
    )
)(LatestOrders);
