import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import OrderBookData from './OrderBookReducer';
import RoadShowData from './RoadShowReducer';
import MyDocsData from './MyDocsReducer';
import pricingData from './PricingReducer';
import InvestorFeedbackData from './InvestorFeedbackReducer';

import languageReducer from './LanguageReducer';

import Login from './LoginReducer';
import Utility from './UtilityReducer';
import IsvUtility from 'Isv/reducers/IsvUtilityReducer';
import IsvInformationBanner from 'Isv/reducers/IsvInformationBannerReducer';
import isvDocuments from 'Isv/reducers/IsvDocumentsReducer';
import isvSnackBar from 'Isv/reducers/IsvSnackBarReducer';


const appReducer = combineReducers({
    orderBookData: OrderBookData,
    roadShowData: RoadShowData,
    myDocsData: MyDocsData,
    investorFeedbackData: InvestorFeedbackData,
    login: Login,
    utility: Utility,
    isvUtility: IsvUtility,
    routing: routerReducer,
    pricingData,
    //
    language: languageReducer,
    isvInformationBanner: IsvInformationBanner,
    isvDocuments: isvDocuments,
    isvSnackBar: isvSnackBar,
});

const rootReducer = (state, action) => {

  if (action.type === 'LOGOFF' || action.type === 'LOGOUT') {
    const { language } = state;
    state = {
      language,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
