import React from 'react';
import { browserHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { ValueFormatters  } from 'mk-pattern-library-react';
import ButtonGroup from 'shared/components/Buttons/ButtonGroup';

import withTranslate from 'shared/hoc/withTranslate';
import withLanguage from 'translations/withLanguage';
import TranslationText from 'translations/TranslationText';

import { mapChartTypeTitleToKeys } from 'util/translationMappings';

import * as chartTypeIds from 'constants/chartTypes';
import { featurePageIds } from 'constants/featurePages';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import { requestDoc } from 'actions/MyDocsActions';
import {
    selectChartSubTab,
    selectChartId,
    requestFeedbackChartData
} from 'actions/InvestorFeedbackActions';
import {
    getCurrentDealId,
    getChartTypeDetails
} from 'selectors';

import HsbcLink from 'shared/accessibility/components/Link';
import { ImgIcon } from 'shared/components/Icon';
import flexbox from 'shared/styles/mixins/flexbox';
import { FeatureContainer, HeaderContainer } from 'shared/styles/components/containers';
import { FeatureHeading, HeadingDescription } from 'shared/styles/components/headings';

// tabs
import { tabIds, investorFeedbackChartTabs } from 'constants/tabIds';
import HorizontalTab from 'shared/accessibility/components/HorizontalTabs/HorizontalTab';
import HorizontalTabs from 'shared/accessibility/components/HorizontalTabs';
// tabs content
import ThemeDistributionTabContent from './tabsContent/ThemeDistributionTabContent';
import SentimentTabContent from './tabsContent/SentimentTabContent';
import PriceDemandTabContent from './tabsContent/PriceDemandTabContent';

const ButtonsContainer = styled.div`
  ${flexbox('row', 'center', 'flex-end')}
`;

const HSBCLinkContainer = styled.div`
  ${flexbox('row', 'center', 'flex-start')}
    margin: 15px 0 15px 0;
`;

const HsbcLinkContent = styled.div`
    display: inline-block;
    height: 20px;
`;

const PADDING_LEFT_0PX = {
  paddingLeft: 0,
};

const TabContainer = styled.div`
    padding: 20px;
`;

const documentIconStyle = {
    height: '18px',
    width: '18px',
    position: 'relative',
    display: "inline-block",
    float: "left",
    color: '#4D6474',
    fontSize: 20,
    marginRight: 8,
};

class InvestorFeedbackSummary extends React.PureComponent {
    componentDidMount() {
        this.props.viewChange();
    }

    isChartTypeAvailable = chartTypeId => {
        return this.props.chartTypes.some(chart => chart.chartType.chartTypeId === chartTypeId && chart.visible);
    }

    handleDocumentClick = (docId, docName) => {
        this.props.requestDoc(docId, docName);
    }

    render() {
        return (
            <FeatureContainer>
                <HeaderContainer>
                    <Col sm={6}>
                        <FeatureHeading className="text-xxlarge text-univ-bd">
                            <TranslationText
                                dictionaryKey={"investor_feedback.investor_feedback_summary"}
                                defaultText={'Investor Feedback Summary'}
                            />
                        </FeatureHeading>
                        <HeadingDescription top={5}>
                        <TranslationText
                            dictionaryKey={"investor_feedback.view_feedback_collected_from_investors"}
                            defaultText={"View feedback collected from investors"}
                        />
                        </HeadingDescription>
                        <HSBCLinkContainer>
                            <HsbcLink
                                id='link-summary-fb'
                                title={this.props.fullFeedbackDocument}
                                disabled={!this.props.fullFeedbackDoc}
                                onClick={() => {
                                    this.handleDocumentClick(this.props.fullFeedbackDoc, 'Full Feedback');
                                    this.props.fullFeedbackButtonEvent();
                                }}
                            >
                                <HsbcLinkContent>
                                    <ImgIcon
                                        style={documentIconStyle}
                                        icon="DocumentDownload"
                                        aria-hidden
                                    />
                                    {this.props.fullFeedbackDocument}
                                </HsbcLinkContent>
                            </HsbcLink>
                        </HSBCLinkContainer>
                    </Col>
                    <Col sm={6} style={PADDING_LEFT_0PX}>
                        <div style={{ textAlign: 'right' }}>
                        <p className="text-timestamp text-small" style={{ marginBottom: 4 }}>
                            <TranslationText
                            dictionaryKey={"common.data_as_of_<date_time>"}
                            replace={[
                                ["<date time>", ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)]
                            ]}
                            defaultText={`Data as of ${ValueFormatters.dateFormatter('dayMonthYearZoneShort', this.props.dateReceived)}`}
                            />
                        </p>
                        </div>
                        <ButtonsContainer>
                            <ButtonGroup
                                size="small"
                                buttons={[
                                {
                                    id:'btn-ifd-summary',
                                    active: true,
                                    text: this.props.stickyToggleSummary,
                                    action: () => {
                                        browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE);
                                        this.props.invFeedbackSummaryEvent();
                                    },
                                },
                                {
                                    id:'btn-ifd-detail',
                                    active: false,
                                    text: this.props.stickyToggleInvestorFeedback,
                                    action: () => {
                                        browserHistory.push(featurePageIds.INVESTOR_FEEDBACK_DETAILS_PAGE);
                                        this.props.invFeedbackButtonEvent();
                                    },
                                }
                                ]}
                            />
                        </ButtonsContainer>
                        
                    </Col>
                </HeaderContainer>

                <Row>
                    <Col sm={12}>
                        {
                            this.props.themeTypes.length && (
                                <HorizontalTabs tabsId={tabIds.INVESTOR_FEEDBACK_CHART_TABS}>
                                    <HorizontalTab
                                        tabId={investorFeedbackChartTabs.THEME_DISTRIBUTION}
                                        title={this.props.tab1Title}
                                        onTabClicked={() => {
                                            this.props.selectFirstSubTab(this.props.tab1Title);
                                            this.props.requestChartData(this.props.themeDistChartTypeDetails.chartId, this.props.dealId);
                                            this.props.tabSwitchEvent('theme distribution')
                                        }}
                                        visible={this.isChartTypeAvailable(+chartTypeIds.THEME_DISTRIBUTION_CHART_TYPE)}
                                    >
                                        <TabContainer>
                                            <ThemeDistributionTabContent
                                                chartDetails={this.props.themeDistChartTypeDetails}
                                            />
                                        </TabContainer>
                                    </HorizontalTab>
                                    <HorizontalTab
                                        tabId={investorFeedbackChartTabs.SENTIMENT}
                                        title={this.props.tab2Title}
                                        onTabClicked={() => {
                                            this.props.selectFirstSubTab(this.props.tab2Title);
                                            this.props.requestChartData(this.props.sentimentChartTypeDetails.chartId, this.props.dealId);
                                            this.props.tabSwitchEvent('sentiment')
                                        }}
                                        visible={this.isChartTypeAvailable(+chartTypeIds.SENTIMENT_CHART_TYPE)}
                                    >
                                        <TabContainer>
                                            <SentimentTabContent
                                                chartDetails={this.props.sentimentChartTypeDetails}
                                            />
                                        </TabContainer>
                                    </HorizontalTab>
                                    <HorizontalTab
                                        tabId={investorFeedbackChartTabs.PRICE_DEMAND}
                                        title={this.props.tab3Title}
                                        onTabClicked={() => {
                                            this.props.selectFirstSubTab(this.props.tab3Title);
                                            this.props.requestChartData(this.props.priceDemChartTypeDetails.chartId, this.props.dealId);
                                            this.props.tabSwitchEvent('price demand')
                                        }}
                                        visible={this.isChartTypeAvailable(+chartTypeIds.PRICE_DEMENT_CHART_TYPE)}
                                    >
                                        <TabContainer>
                                            <PriceDemandTabContent
                                                chartDetails={this.props.priceDemChartTypeDetails}
                                            />
                                        </TabContainer>
                                    </HorizontalTab>
                                </HorizontalTabs>
                            )
                        }
                    </Col>
                </Row>
            </FeatureContainer>
        );
    }
}

export default compose(
    connect(
        state => ({
            dateReceived: state.investorFeedbackData.dataReceived,
            // flag for data display
            themeTypes: state.investorFeedbackData.feedbackThemeTypes,
            chartTypes: state.investorFeedbackData.chartTypes,
            fullFeedbackDoc: state.investorFeedbackData.fullFeedbackDoc,
            // smart data fetching
            dealId: getCurrentDealId(state),
            // tabs
            themeDistChartTypeDetails: getChartTypeDetails(state, +chartTypeIds.THEME_DISTRIBUTION_CHART_TYPE),
            priceDemChartTypeDetails: getChartTypeDetails(state, +chartTypeIds.PRICE_DEMENT_CHART_TYPE),
            sentimentChartTypeDetails: getChartTypeDetails(state, +chartTypeIds.SENTIMENT_CHART_TYPE),
        }),
        (dispatch, ownProps) => ({
            requestDoc: (docId, docName) => dispatch(requestDoc(docId, docName)),
            requestChartData: (chartId, dealId) => {
                dispatch(selectChartId(chartId));
                dispatch(requestFeedbackChartData(dealId, chartId, false));
            },
            selectFirstSubTab: (index, tabTitle) => dispatch(selectChartSubTab(0, tabTitle)),
        })
    ),
    withTranslate((translate, props) => ({
        fullFeedbackDocument: translate('investor_feedback.full_feedback_document', 'Full Feedback Document'),
        tab1Title: translate(
            mapChartTypeTitleToKeys(props.themeDistChartTypeDetails.chartTitle),
            props.themeDistChartTypeDetails.chartTitle
        ),
        tab2Title: translate(
            mapChartTypeTitleToKeys(props.sentimentChartTypeDetails.chartTitle),
            props.sentimentChartTypeDetails.chartTitle
        ),
        tab3Title: translate(
            mapChartTypeTitleToKeys(props.priceDemChartTypeDetails.chartTitle),
            props.priceDemChartTypeDetails.chartTitle
        ),
        stickyToggleSummary: translate('investor_feedback.summary', 'Summary'),
        stickyToggleInvestorFeedback: translate('investor_feedback.details', 'Details'),
    })),
    withAnalytics((ev, view) => ({
        viewChange: () => view(featurePageIds.INVESTOR_FEEDBACK_SUMMARY_PAGE),
        // btns
        invFeedbackButtonEvent: () => ev(events.INVESTOR_FEEDBACK_BUTTON),
        invFeedbackSummaryEvent: () => ev(events.INVESTOR_FEEDBACK_SUMMARY_BUTTON),
        fullFeedbackButtonEvent: () => ev(events.INVESTOR_FULL_FEEDBACK_BUTTON),
        // tabs
        tabSwitchEvent: title => ev(events.INVESTOR_FEEDBACK_SUMMARY_TAB(title)),
    })),
    withLanguage,
)(InvestorFeedbackSummary);
