import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { convertNumbersToFullKey, convertNumbersToFullName } from 'shared/functions/converters';
import ChartTableContainer from 'shared/components/ChartTableContainer';
import withTranslate from 'shared/hoc/withTranslate';

import {
    getInvestorFeedbackChartDataByChart,
    getSelectedChartSubTab
} from 'selectors';
import { selectChartSubTab } from 'actions/InvestorFeedbackActions';

import Dropdown from 'shared/accessibility/components/Dropdown';

import NoContent from './shared/NoContent';

import PriceDemandChart from './charts/PriceDemandChart';
import PriceDemandTable from './tables/PriceDemandTable';

const legendKeyStyle = {
  paddingBottom: '10px',
};

const isData = chartData => {
    const cumulativeDemandData = chartData.series.filter(series => series.seriesTitle === "Cumulative Demand")[0].data;
    return cumulativeDemandData[cumulativeDemandData.length - 1].value > 0
}

class PriceDemandTabContent extends PureComponent {
    buttonClicked = (index, name) => {
        this.props.selectChartSubTab(index, name);
    }

    render() {
        return (
            <React.Fragment>
                { this.props.chartNames.length > 0 &&
                    <Dropdown
                        dropdownId={'Test-id-for-tranches'}
                        dropdownText={this.props.chartNames[this.props.activeIndex]}
                        items={this.props.chartNames
                            .map((name, i) => ({
                                selected: i === this.props.activeIndex,
                                content: name,
                                label: name,
                                click: () => this.buttonClicked(i, name),
                            }))
                        }
                        label={'Tranches'}
                    />
                }
                {
                    this.props.chartData[this.props.activeIndex] && isData(this.props.chartData[this.props.activeIndex]) ? (
                        <React.Fragment>
                            <h3 style={ legendKeyStyle }>
                            {this.props.chartTitle()}
                            </h3>
                            <ChartTableContainer
                                table={
                                    <PriceDemandTable
                                        key={this.props.chartNames[this.props.activeIndex]}
                                        series={this.props.chartData[this.props.activeIndex]}
                                    />
                                }
                                buttonStyle={{
                                top: '61px',
                                right: '37px'
                                }}
                            >
                            <PriceDemandChart data={this.props.chartData[this.props.activeIndex]} />
                            </ChartTableContainer>
                        </React.Fragment>
                    )
                    : <NoContent />
                }
            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        (state, ownProps) => {
            const chartData = getInvestorFeedbackChartDataByChart(state, `feedbackChartData${ownProps.chartDetails.chartId}`);
            return ({
                // sub tab handling
                activeIndex: getSelectedChartSubTab(state),
                // data
                chartData,
                chartNames: chartData.map(c => c.pageTitle),
            })
        },
        dispatch => ({
            selectChartSubTab: (index, title) => dispatch(selectChartSubTab(index, title)),
        }),
    ),
    withTranslate((translate, props) => ({
        chartTitle: () => {
            const trancheCurrency = props.chartData[props.activeIndex].series.find(s => s.seriesTitle === 'Tranche Currency').data.map(d => d.value)[0];
            const demandUnit = props.chartData[props.activeIndex].series.find(s => s.seriesTitle === 'Demand Unit').data.map(d => d.value)[0];
            const trancheName = props.chartNames && props.chartNames[props.activeIndex];

            return translate(
                'investor_feedback.pricedemand_chart_title',
                `Cumulative Demand By Price Point in ${convertNumbersToFullName(demandUnit)} ${trancheCurrency} for ${trancheName}`,
                [
                    ['<unit>', translate(convertNumbersToFullKey(demandUnit), demandUnit)],
                    ['<currency>', trancheCurrency],
                    ['<tranche_name>', trancheName],
                ],
            );
        }
    })),
)(PriceDemandTabContent);
