import React from 'react';

import { palette } from 'shared/styles/variables/theme'

const SvgIcon = ({
    children,
    title,
    viewBoxMinWidth = 0,
    viewBoxMinHeight = 0,
    viewBoxWidth = 16,
    viewBoxHeight = 16,
    width = '16',
    height = '16',
    isCheckedIndicator = true,
    isDisabled = false,
    ...otherProps
  }) => {
    const attributes = {};

    if (isCheckedIndicator) {
      attributes['aria-checked'] = !isDisabled;
    }

    return (
      <svg
        viewBox={`${viewBoxMinWidth} ${viewBoxMinHeight} 18 18`}
        width={width}
        height={height}
        { ...otherProps }
        { ...attributes }
      >
        {children}
      </svg>
    )
  }

const getColor = props => {
  if(props.color) {
    return props.color;
  } else if(props.isCheckedIndicator) {
    return props.isDisabled ? palette.uncheckedIcon : palette.checkedIcon
  }

  return '#000';
}

export const StatusIcon = props =>
  <SvgIcon aria-hidden { ...props } focusable="false">
    <polygon fill={getColor(props)} points="5.916,15.942 0.106,10.134 1.378,8.86 5.916,13.397 16.622,2.693 17.894,3.967 	"/>
  </SvgIcon>

export const RoadshowIcon = props =>
  <SvgIcon { ...props } aria-hidden focusable="false">
    <path fill={getColor(props)} d="M15,1V0h-1.2v1H4.2V0H3v1H0v17l14.2,0v0l3.8-3.8V1H15z M14.2,16.303V14.2h2.103L14.2,16.303z M16.8,13H13v3.8H1.2V6h15.6 V13z M16.8,4.8H1.2V2.2H3v1.3h1.2V2.2h9.6v1.3H15V2.2h1.8V4.8z M8.8,10.029V15H10V7.971L7.003,9.667l0.591,1.045L8.8,10.029z"/>
  </SvgIcon>

export const PricingIcon = props =>
  <SvgIcon { ...props } aria-hidden focusable="false">
    <path fill={getColor(props)} d="M15.606,0.545l-7.21,7.211L5.629,4.988L2.4,8.217v1.697l3.229-3.228l2.767,2.768l7.21-7.211L18,4.635V2.939L15.606,0.545z M9.046,10.666L2.4,13.486v1.304l6.646-2.822l4.302,1.825L18,9.142V7.446l-4.928,4.928L9.046,10.666z M1.2,0H0v16.8V18h1.2H18v-1.2 H1.2V0z"/>
  </SvgIcon>

export const FeedbackIcon = props =>
  <SvgIcon { ...props } aria-hidden focusable="false">
    <g fill={getColor(props)}>
      <rect x="3" y="10.4" width="5" height="1.2" />
      <path d="M9.176,5.8C8.761,5.256,8.584,4.483,8.584,3.769c0-0.603,0.145-2.569,2.007-2.569c1.849,0,1.993,1.967,1.993,2.569 c0,0.486-0.11,1.122-0.384,1.663v1.791c1.072-0.711,1.584-2.111,1.584-3.453C13.784,1.479,12.531,0,10.591,0 C8.643,0,7.384,1.479,7.384,3.769c0,0.707,0.134,1.414,0.404,2.031H9.176z"/>
      <path d="M16.31,10.844c-0.183-0.453-0.437-0.849-0.755-1.178c-0.529-0.544-1.166-0.992-1.893-1.331L13.286,8.16l-0.297,0.289 c-0.24,0.234-0.508,0.413-0.789,0.566v1.322c0.469-0.178,0.908-0.419,1.301-0.739c0.451,0.249,0.851,0.552,1.192,0.903 c0.21,0.216,0.38,0.482,0.504,0.791l1.014,2.508H12.2V15h5.79L16.31,10.844z"/>
      <path d="M0,18l3.009-3.008H11V7H0V18z M1.2,8.2h8.6v5.592H2.512L1.2,15.103V8.2z" />
    </g>
  </SvgIcon>

export const OrdersIcon = props =>
  <SvgIcon { ...props } aria-hidden focusable="false">
    <path fill={getColor(props)} d="M13.8,9V0H8.4v9h-3V5H0v13h18V9H13.8z M9.6,1.199h3v15.602h-3V1.199z M8.4,10.199v6.602h-3 v-6.602H8.4z M1.2,6.199h3v10.602h-3V6.199z M16.8,16.801h-3v-6.602h3V16.801z"/>
  </SvgIcon>

export const DocumentsIcon = props =>
  <SvgIcon { ...props } aria-hidden focusable="false">
  	<g fill={getColor(props)}>
  		<polygon points="15,3.518 15,1.822 13.178,0 3,0 3,1.199 12.681,1.199 		"/>
  		<path d="M12.2,2.4L2,2.4V18h14V6.2L12.2,2.4L12.2,2.4z M14.8,16.8H3.2V3.6H11v3.8h3.8V16.8z M14.303,6.2H12.2V4.097L14.303,6.2z"
  			/>
  	</g>
  </SvgIcon>
