import React, { PureComponent } from 'react'
import styled from 'styled-components';

import TranslationText from 'translations/TranslationText';

const ContentWrapper = styled.div`
    padding-top: 20px;
`;

class NoContent extends PureComponent {
    render() {
        return (
            <ContentWrapper>
                <TranslationText
                    ictionaryKey={"others.content_not_yet_available"}
                    defaultText={"Content Not Yet Available"}
                />
            </ContentWrapper> 
        )
    }
}

export default NoContent;
