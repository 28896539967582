import React, { PureComponent } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import { withAnalytics2 } from "shared/hoc/withAnalytics";
import responsiveness from "shared/styles/mixins/responsiveness";
import { typography, fontColors } from "shared/styles/variables/theme";
import { FontIcon } from "shared/components/Icon";
import {
    isIsvExternalUser,
    getIsvCurrentDeal,
    selectInformationBannerReducer,
    getLatestIsvCurrentDeal
} from "Isv/selectors";
import { applyIsvCloseBanner } from "Isv/actions/IsvInformationBannerAction";
import landingImageStepOne from "Isv/images/welcome-step-1.png";
import landingImageStepTwo from "Isv/images/welcome-step-2.png";
import landingImageStepThree from "Isv/images/welcome-step-3.jpg";
import IsvInformationBanner from "Isv/components/InformationBanner/InformationBanner";
import { isIsvCsmUser, isIsvRRTeamUser } from "selectors";
import DashboardTable from "./DashboardTable";
import _ from "lodash";
import { ISV_DOCUMENT_REVIEW_STATUS } from 'Isv/constants/isvDocumentReviewStatus'

const CarousalWrapper = styled.div`
  margin-left: auto;
  margin-right: 0;
  height: 100%;
`;

const LandingContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
`;

const LandingH1 = styled.h1`
  margin: 0;
  padding-top: 20px !important;
  font-size: ${typography.sizes["30px"]};
  color: ${fontColors.black};
  text-align: left;
`;

const LandingPara = styled.p`
  margin: 0;
  padding-top: 20px  !important;
  font-size: ${typography.sizes["16px"]};
  color: ${fontColors.black};
  text-align: left;
`;

const CarouselContainer = styled.div`
  width: 50%;
  button {
    cursor: default;
    &:hover {
      opacity: 1 !important;
    }
  }
  background: #ededed;
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 300px;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.3) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: url(${(props) => props.src});
  ${responsiveness.largeScreen(`
       background-image: url(${(props) => props.src});
    `)}
  ${responsiveness.mediumScreen(`
        background-image: url(${(props) => props.src});
    `)}
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  height: 100%;
  width: 50%;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 15px;
`;

const IndexContainer = styled.span`
  position: absolute;
  right: 110px;
  bottom: 15px;
`;

const CustomIcon = styled(FontIcon)`
  display: inline-block;
  font-size: 14px;
  transition: transform 0.3s linear;
  &.left-caret {
    transform: rotate(0deg) !important;
  }
  &.right-caret {
    transform: rotate(180deg) !important;
  }
  background: #fff;
  padding: 13px;
  border-radius: 2px;
`;

const LandingMessageContainer = styled.div`
  padding: 25px 25px 0 20px !important;
`;

const items = [
    {
        name: "Welcome to HSBC MyDeal",
        description:
            "HSBC MyDeal can be used to monitor and manage your India Remittance Agency agreements digitally",
        image: landingImageStepOne,
    },
    {
        name: "HSBC MyDeal Dashboard",
        description:
            "HSBC MyDeal dashboard provides key information relating to your Remittance Agency agreement. Here you will also be able to track the current status of your agreement.",
        image: landingImageStepTwo,
    },
    {
        name: "HSBC MyDeal Deal Information",
        description:
            "HSBC MyDeal Deal Information module provides all documents relating to your Remittance Agency agreement. Here you will be able to download templates to be completed, upload information as well as supporting documents, add comments to your documents and submit your documents for review.",
        image: landingImageStepThree,
    },
];

const Item = (props) => {
    return (
        <LandingMessageContainer>
            <LandingH1>{props.item.name}</LandingH1>
            <LandingPara>{props.item.description}</LandingPara>
        </LandingMessageContainer>
    );
};

const ButtonIcon = (props) => {
    return (
        <IconContainer primary={props.primary} onClick={props.onClick}>
            <CustomIcon
                aria-hidden
                style={iconStyle(props.type)}
                icon="Chevron"
                className={props.className}
            />
        </IconContainer>
    );
};

const iconStyle = (type) => {
    return {
        transform: type === "next" ? "rotate(90deg)" : "rotate(-90deg)",
        top: "0",
        position: "relative",
        color: "#898989",
        cursor: "pointer",
        left: type === "next" ? '0' : '-37px'
    };
};

class Landing extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
        };
    }

    componentDidMount() {
        this.props.viewChange();
    }

    onChange = async (index) => {
        const { currentIndex } = this.state;
        let newIndex;
        if (currentIndex === 0 && currentIndex + index < 0) {
            newIndex = items.length - 1;
        } else if (currentIndex === items.length - 1 && currentIndex + index > items.length - 1) {
            newIndex = 0;
        } else newIndex = currentIndex + index
        await this.setState({ currentIndex: newIndex });
    };

    getData = () => {
        let data = null
        const { selectedDealData, latestSelectedDealData } = this.props;
        data = (selectedDealData != null && latestSelectedDealData != null && selectedDealData.dealId === latestSelectedDealData.dealId) ?
            _.isEqual(selectedDealData, latestSelectedDealData) ? selectedDealData : latestSelectedDealData : selectedDealData
        return data;
    }

    render() {
        const { currentIndex } = this.state;
        const {
            isDashboardBannerOpen,
            handleCloseBanner,
            isIsvCsmUser,
            isIsvExternalUser,
            selectedDealData,
            isIsvRRTeamUser
        } = this.props;
        let showECB2ReturnBannerForClient = selectedDealData && selectedDealData.status &&
          selectedDealData.status.ecB2ReturnStatus && selectedDealData.status.statusName === ISV_DOCUMENT_REVIEW_STATUS.COMPLETED;           
        return (
            <>
                <LandingContainer>
                    <CarouselContainer>
                        <CarousalWrapper>
                            <ButtonIcon
                                className='right-caret'
                                onClick={() => this.onChange(-1, 'slide-left')}
                                type='prev'
                                primary
                            />
                            <Item item={items[currentIndex]} />
                            <ButtonIcon
                                className='left-caret'
                                onClick={() => this.onChange(1, 'slide-right')}
                                type='next'
                            />
                        </CarousalWrapper>
                        <IndexContainer>
                            {currentIndex + 1} / {items.length}
                        </IndexContainer>
                    </CarouselContainer>
                    <ImageContainer src={items[currentIndex].image} alt="" />
                </LandingContainer>
                {
                    (
                        (!this.props.isIsvExternalUser && this.props.selectedDealData && this.props.selectedDealData.dealId) ||
                        (this.props.selectedDealData && this.props.selectedDealData.dealId && this.props.selectedDealData.acceptedBy && this.props.selectedDealData.startDate)) ? (
                            <>
                                {isDashboardBannerOpen && (
                                    <IsvInformationBanner
                                        bannerHeading={isIsvExternalUser && showECB2ReturnBannerForClient ? "ECB 2 Return Status." : "Deal Information."}
                                        bannerText={
                                            isIsvCsmUser
                                              ? "Please select Maturity Date and enter LRN Reference where applicable. "
                                              : isIsvExternalUser ? showECB2ReturnBannerForClient ? "Only current ECB 2 Return status is displayed below.  For status of historic ECB 2 Returns, please refer to ECB 2 Return tab."
                                              : "You can monitor the status of your deal in the dashboard view."
                                              : isIsvRRTeamUser
                                              ? "Please enter LRN Reference where applicable."
                                              : ""
                                          }
                                        handleCloseBanner={handleCloseBanner}
                                        onCloseOverlay="isDashboardBannerOpen"
                                    />
                                )}
                                {Object.keys(selectedDealData).length > 1 &&
                                    <DashboardTable
                                        data={selectedDealData}
                                        isIsvCsmUser={this.props.isIsvCsmUser}
                                        isIsvRRTeamUser={this.props.isIsvRRTeamUser}
                                    />}
                            </>
                        ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDashboardBannerOpen: selectInformationBannerReducer(state),
        isIsvCsmUser: isIsvCsmUser(state),
        selectedDealData: getIsvCurrentDeal(state),
        isIsvExternalUser: isIsvExternalUser(state),
        latestSelectedDealData: getLatestIsvCurrentDeal(state),
        isIsvRRTeamUser: isIsvRRTeamUser(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    handleCloseBanner: (label) => {
        dispatch(applyIsvCloseBanner(label));
    },
});
export default compose(
    withAnalytics2((viewChange, viewIds) => ({
        viewChange: () => viewChange(viewIds.LANDING_PAGE),
    })),
    connect(mapStateToProps, mapDispatchToProps)
)(Landing);
