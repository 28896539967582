import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import PopupSystem from 'shared/components/PopupSystem';
import Popup, { Divider } from 'shared/components/Popup';
import { closeOrderSummaryDetailView } from 'actions/OrderBookActions';
import withTranslate from 'shared/hoc/withTranslate';
import { dateFormatter } from '../../SharedTableComponents/formatters';
import {
    leftAlignedCellStyle,
    rightAlignedCellStyle,
    leftAlignedHeaderStyle,
} from '../../shared/table/styles';
import { tableEllipsis } from 'shared/styles/mixins/ellipsis';
import ScrollableTable from 'shared/components/ScrollableTable';
import { ValueFormatters } from 'mk-pattern-library-react';
const ScrollbleContainer = styled.div`
    div:nth-of-type(1) {
        max-height: 380px;
    }
`;

const ModalAlignCenter = styled.div`
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    ${Divider} {
        display: block;
        margin: 1rem 0;
    }
`;

class OrderSummaryModal extends Component {

    Columns = (props) => {
        const columns = [
            {
                attrs: {
                    role: "rowheader",
                },
                dataField: 'orderId',
                text: props.orderIdText,
                style: leftAlignedCellStyle,
                headerStyle: leftAlignedHeaderStyle,
                columnFlex: 0.60,
            },
            {
                dataField: 'investorLongName',
                text: props.investorNameText,
                headerStyle: leftAlignedHeaderStyle,
                style: leftAlignedCellStyle,
                columnFlex: 1.50,
            },
            {
                dataField: 'investorType',
                text: props.investorTypeText,
                headerStyle: leftAlignedHeaderStyle,
                style: leftAlignedCellStyle,
                columnFlex: 1,
            },
            {
                dataField: 'investorCountry',
                text: props.investorCountryText,
                headerStyle: leftAlignedHeaderStyle,
                style: leftAlignedCellStyle,
                columnFlex: 0.95,
            },
            {
                dataField: 'currency',
                text: props.currencyText,
                headerStyle: leftAlignedHeaderStyle,
                style: rightAlignedCellStyle,
                columnFlex: 0.60,
            },
            {
                dataField: 'orderAmount',
                text: props.orderAmountText,
                headerStyle: leftAlignedHeaderStyle,
                style: rightAlignedCellStyle,
                columnFlex: 0.75,
                formatter: ValueFormatters.commaDecimalFormatter,
            },
            {
                dataField: 'limitSecurity',
                text: props.limitSecurityText,
                headerStyle: leftAlignedHeaderStyle,
                style: leftAlignedCellStyle,
                columnFlex: 1,
            },
            {
                dataField: 'trancheName',
                text: props.trancheNameText,
                headerStyle: leftAlignedHeaderStyle,
                style: {
                    ...leftAlignedCellStyle,
                    ...tableEllipsis
                },
                columnFlex: 1,
            },
            {
                dataField: 'externalSourceLastModified',
                text: props.dateText,
                headerClasses: '',
                headerStyle: leftAlignedHeaderStyle,
                style: leftAlignedCellStyle,
                columnFlex: 0.80,
                formatter: (value) => (
                    dateFormatter('localTime', value)
                ),
            }
        ];

        const amendedOrderColumn = {
            dataField: 'amendedAmount',
            text: props.amendedAmountText,
            headerStyle: leftAlignedHeaderStyle,
            style: rightAlignedCellStyle,
            columnFlex: 0.65,
            formatter: ValueFormatters.commaDecimalFormatter,
        }

        if (this.props.bookEvolution) {
            columns.splice(6, 0, amendedOrderColumn)
        }

        return columns;
    }

    closeModalClick = () => {
        this.props.closeOrderSummaryDetailView(this.props.modalId);
    }

    render() {
        const { isModalOpen, modalSummary } = this.props;
        return (
            <PopupSystem
                centered
                backgroundLayer
                modalID={this.props.modalID}
                className={this.props.className}
                isModalOpen={isModalOpen}
                closeModal={this.closeModalClick}
                primaryButton
                primaryButtonContent="Close"
                primaryButtonAction={this.closeModalClick}
                primaryButtonKey="others.close"
                closeIcon
                content={
                    <ModalAlignCenter>
                        <Popup
                            static
                            width={this.props.width || 1250}
                            primaryButton
                            primaryButtonContent="Close"
                            primaryButtonAction={this.closeModalClick}
                            primaryButtonKey="others.close"
                        >
                            <div className="scrollable-container-wrapper">
                                <ScrollbleContainer>
                                    <ModalContent>
                                        <ScrollableTable
                                            classes="mydeal-table fixed-header top-header bottom-footer order-summary-details"
                                            data={modalSummary}
                                            bordered={false}
                                            keyField='Order Summary Details'
                                            columns={this.Columns(this.props)}
                                        />
                                    </ModalContent>
                                </ScrollbleContainer>
                            </div>
                        </Popup>
                    </ModalAlignCenter>
                }
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isModalOpen: state.orderBookData.isModalOpen,
    modalSummary: state.orderBookData.modalSummary,
})

const mapDispatchToProps = dispatch => ({
    closeOrderSummaryDetailView: () => dispatch(closeOrderSummaryDetailView())
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslate((translate) => ({
        orderIdText: translate('orders.orderId', 'Order Id'),
        issuerNameText: translate('orders.issuer_name', 'Issuer Name'),
        investorNameText: translate('orders.investor_name', 'Investor Name'),
        investorTypeText: translate('orders.investor_types', 'Investor Type'),
        investorCountryText: translate('orders.investor_country', 'Investor Country'),
        currencyText: translate('orders.currency', 'Currency'),
        orderAmountText: translate('orders.order_amount', 'Order Amount') + ' (m)',
        limitSecurityText: translate('orders.limit_security', 'Limit Security'),
        trancheNameText: translate('orders.tranche_name', 'Tranche Name'),
        dateText: translate('orders.date_time_entered', 'Date and Time entered'),
        amendedAmountText: translate('orders.amended_amount', 'Amended Amount') + ' (m)',
    })),
)(OrderSummaryModal);
