export const palette = [
  {
    colorHex: '#008580',
    description: 'violet',
  },
  {
    colorHex: '#004F4B',
    description: 'amber',
  },
  {
    colorHex: '#E9A115',
    description: 'indigo',
  },
  {
    colorHex: '#9E6D0E',
    description: 'emerald',
  },
  {
    colorHex: '#716196',
    description: 'aquamarine',
  },
  {
    colorHex: '#1C1A49',
    description: 'teal',
  },
  {
    colorHex: '#588942',
    description: 'violet2',
  },
  {
    colorHex: '#142E01',
    description: 'amber2',
  },
  {
    colorHex: '#547DA7',
    description: 'indigo2',
  },
  {
    colorHex: '#002951',
    description: 'emerald2',
  },
  {
    colorHex: '#E54D58',
    description: 'aquamarine2',
  },
  {
    colorHex: '#6F0008',
    description: 'teal2',
  },
  {
    colorHex: '#1087EF',
    description: 'teal2',
  },
  {
    colorHex: '#0C62AE',
    description: 'teal2',
  },
  {
    colorHex: '#B57DA4',
    description: 'teal2',
  },
];

//
// Chart colors
//
const primaryColorOne = '#00716C';
const primaryColorTwo = '#E9A115';
const primaryColorThree = '#4E3A7C';
const primaryColorFour = '#588942';
const primaryColorFive = '#003466';
const primaryColorSix = '#6F0008';
const primaryColorSeven = '#1087EF';

const primaryAcrossOne = '#008580';
const secondaryAcrossOne = '#004F4B';

const primaryAcrossTwo = '#E9A115';
const secondaryAcrossTwo = '#9E6D0E';

const primaryAcrossThree = '#716196';
const secondaryAcrossThree = '#1C1A49';

const primaryAcrossFour = '#588942';
const secondaryAcrossFour = '#142E01';

const primaryAcrossFive = '#547DA7';
const secondaryAcrossFive = '#002951';

const primaryAcrossSix = '#E54D58';
const secondaryAcrossSix = '#6F0008';

const primaryAcrossSeven = '#1087EF';
const secondaryAcrossSeven = '#0C62AE';

const primaryAcrossEight = '#B57DA4';

export const primaryColorOther = '#D7D8D6';

// export const otherColor = '#eeeeee';

export const colors = (index, key) => {
  if (key && key.toLowerCase().trim() === 'other') {
    return primaryColorOther;
  }
  switch (index) {
    case 0: return primaryColorOne;
    case 1: return primaryColorTwo;
    case 2: return primaryColorThree;
    case 3: return primaryColorFour;
    case 4: return primaryColorFive;
    case 5: return primaryColorSix;
    case 6: return primaryColorSeven;
    default: return primaryColorOther;
  }
};

export const extendedColors = (index, key) => {
  if (key && key.toLowerCase().trim() === 'other') {
    return primaryColorOther;
  }
  switch (index) {
    case 0: return primaryAcrossOne;
    case 1: return secondaryAcrossOne;
    case 2: return primaryAcrossTwo;
    case 3: return secondaryAcrossTwo;
    case 4: return primaryAcrossThree;
    case 5: return secondaryAcrossThree;
    case 6: return primaryAcrossFour;
    case 7: return secondaryAcrossFour;
    case 8: return primaryAcrossFive;
    case 9: return secondaryAcrossFive;
    case 10: return primaryAcrossSix;
    case 11: return secondaryAcrossSix;
    case 12: return primaryAcrossSeven;
    case 13: return secondaryAcrossSeven;
    case 14: return primaryAcrossEight;
    default: return primaryColorOther;
  }
};

export const extendedColorsForLineChart = (index, key) => {
  if (key && key.toLowerCase().trim() === 'other') {
    return primaryColorOther;
  }
  switch (index) {
    case 0: return primaryAcrossOne;
    case 1: return primaryAcrossTwo;
    case 2: return primaryAcrossThree;
    case 3: return primaryAcrossFour;
    case 4: return primaryAcrossFive;
    case 5: return primaryAcrossSix;
    case 6: return primaryAcrossSeven;
    case 7: return primaryAcrossEight;
    case 8: return secondaryAcrossOne;
    case 9: return secondaryAcrossTwo;
    case 10: return secondaryAcrossThree;
    case 11: return secondaryAcrossFour;
    case 12: return secondaryAcrossFive;
    case 13: return secondaryAcrossSix;
    case 14: return secondaryAcrossSeven;
    default: return primaryColorOther;
  }
};

export const asSmallArray = [
  primaryColorOne,
  primaryColorTwo,
  primaryColorThree,
  primaryColorFour,
  primaryColorFive,
  primaryColorSix,
  primaryColorSeven,
];
export const asArray = [
  primaryAcrossOne,
  secondaryAcrossOne,
  primaryAcrossTwo,
  secondaryAcrossTwo,
  primaryAcrossThree,
  secondaryAcrossThree,
  primaryAcrossFour,
  secondaryAcrossFour,
  primaryAcrossFive,
  secondaryAcrossFive,
  primaryAcrossSix,
  secondaryAcrossSix,
  primaryAcrossSeven,
  secondaryAcrossSeven,
  primaryAcrossEight,
  primaryColorOther,
];