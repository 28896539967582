import { createReducer } from 'reduxsauce';
import moment from 'moment-timezone';
import { find } from 'lodash';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import Types, { UtilityTypes } from 'actions/Types';
import { isResponseNull } from 'util/dataChecks';

import { ALL_TEAMS_SELECTED, DEFAULT_ALL_TEAMS_EVENTS } from 'constants/multiteam';

export const INITIAL_STATE = {
  summaryArrived: false,
  eventsArrived: false,
  //
  roadshowId: 0,
  selectedDay: null,
  // Summary
  eventDates: [],
  onlineRoadshow: {},
  coordinators: [],
  // Full Schedule
  events: [],
  contacts: [],
  // Document Mode
  documentOnlyMode: false,
  fullScheduleDocumentId: 0,
  // MultiTeam 
  multiTeamMode: false,
  teams: [],
  teamsEventDates: [],
  selectedTeam: null,
  //
  dailyEventId: null,
};

const updateSelectedDay = (state, action) => Object.assign({}, state, {
  selectedDay: action.day,
});

const mapEventDates = eventDatesSource => {
  const now = moment().utc();
  return eventDatesSource
    .map((summaryDate, ind) => ({
      ...summaryDate,
      id: ind,
      status: summaryDate.titleCity,
      // details: `${summaryDate.meetingCount} meetings`,
      title: ValueFormatters.dateFormatter('roadshow-nav', summaryDate.date),
      view: ValueFormatters.dateFormatter('roadshow', summaryDate.date),
      pastDay: (moment(summaryDate.date).utc()).isBefore(now, 'day'),
    }));
};

const handleMultiTeam = (multiTeamMode, teamsEventDates, selectedTeam) => {
  let eventDates = [];

  const multiTeam = {
    multiTeamMode,
    selectedTeam,
    teams: [...teamsEventDates].map(obj => obj.teamName).filter(name => name !== DEFAULT_ALL_TEAMS_EVENTS).sort(),
    teamsEventDates,
  };

  const team = find(teamsEventDates, { teamName: multiTeam.selectedTeam });

  if (!multiTeamMode || selectedTeam === ALL_TEAMS_SELECTED) {
    multiTeam.selectedTeam = ALL_TEAMS_SELECTED;

    const allTeams = find(teamsEventDates, { teamName: DEFAULT_ALL_TEAMS_EVENTS });
    eventDates = mapEventDates(allTeams.eventDates);
  } else if (!team) {
    multiTeam.selectedTeam = null;

    const allTeams = find(teamsEventDates, { teamName: DEFAULT_ALL_TEAMS_EVENTS });
    eventDates = mapEventDates(allTeams.eventDates);
  } else {
    eventDates = mapEventDates(team.eventDates);
  }

  return { multiTeam, eventDates };
};

const changeRoadshowTeam = (state, { team }) => {
  const { eventDates, multiTeam } = handleMultiTeam(state.multiTeamMode, state.teamsEventDates, team);

  const newState = {
    ...state,
    eventDates, 
    ...multiTeam,
  };

  // if selectedDay is null set default
  if (!state.selectedDay) {
    // check if today is in the range of the roadshow
    const activeDay = eventDates.find(x => moment().utc().isSame(moment(x.date).utc(), 'day'));
    // if today is in the range of the roadshow, set default day to today--otherwise set to first day of roadshow
    newState.selectedDay = activeDay || eventDates[0];
  } else {
    // select same day for diff team
    const activeDay = eventDates.find(x => moment(state.selectedDay.date).utc().isSame(moment(x.date).utc(), 'day'));
    newState.selectedDay = activeDay || eventDates[0];
  }

  return newState;
};

const setRoadshowTeam = (state, { team }) => ({ ...state, selectedTeam: team });

const receiveRoadShowSummary = (state, action) => {
  const isNull = isResponseNull(action.RoadshowData);
  
  if (!isNull) {
    const data = action.RoadshowData[0];
    let selectedDay = state.selectedDay;

    // prepare dates for currently selected team
    const { eventDates, multiTeam } = handleMultiTeam(data.multiTeamMode, data.teams, state.selectedTeam);

    // if selectedDay is null set default
    if (!state.selectedDay) {
      // check if today is in the range of the roadshow
      const activeDay = eventDates.find(x => moment().utc().isSame(moment(x.date).utc(), 'day'));
      // if today is in the range of the roadshow, set default day to today--otherwise set to first day of roadshow
      selectedDay = activeDay || eventDates[0];
    }

    return Object.assign({}, state, {
      summaryArrived: true,
      //
      dateReceived: Date.now(),
      selectedDay,
      // summary
      eventDates,
      onlineRoadshow: data.onLineRoadShow,
      coordinators: data.coordinators,
      // doc mode
      documentOnlyMode: data.documentOnlyMode,
      // multiteam
      ...multiTeam,
    })
  }

  return Object.assign({}, state, {
    summaryArrived: true,
    //
    dateReceived: Date.now(),
    // summary
    eventDates: INITIAL_STATE.eventDates,
    onlineRoadshow: INITIAL_STATE.onlineRoadshow,
    coordinators: INITIAL_STATE.coordinators,
    // doc mode
    documentOnlyMode: INITIAL_STATE.documentOnlyMode,
    // multiteam roadshow
    multiTeamMode: false,
    selectedTeam: state.selectedTeam,
    teams: [],
    teamsEventDates: [],
  });
};

const receiveRoadShowEvents = (state, action) => {
  const isNull = isResponseNull(action.roadShowEventsData);

  if (!isNull) {
    const { events, roadShowId, fullScheduleDocumentId } = action.roadShowEventsData[0];
    return Object.assign({}, state, {
      eventsArrived: true,
      fullScheduleDocumentId,
      events,
      roadshowId: roadShowId,
      dateReceived: Date.now(),
    })
  }

  return Object.assign({}, state, {
    eventsArrived: true,
    roadshowId: null,
    fullScheduleDocumentId: null,
    events: [],
    dateReceived: Date.now(),
  });
};

const receiveRoadShowContacts = (state, action) => {
  const isNull = isResponseNull(action.roadShowContactsData);

  if (!isNull) {
      return Object.assign({}, state, {
        contacts: action.roadShowContactsData,
        dateReceived: Date.now(),
      });
  }

  return Object.assign({}, state, {
    contacts: [],
    dateReceived: Date.now(),
  });
};

const setRoadshowDailyEvent = (state, { eventId }) => ({...state, dailyEventId: eventId })

const ACTION_HANDLERS = {
  [Types.ROADSHOW_UPDATE_SELECTED_DAY]: updateSelectedDay,
  [Types.API_RECEIVE_ROADSHOW_SUMMARY]: receiveRoadShowSummary,
  [Types.API_RECEIVE_ROADSHOW_EVENTS]: receiveRoadShowEvents,
  [Types.API_RECEIVE_ROADSHOW_CONTACTS]: receiveRoadShowContacts,
  [Types.ROADSHOW_CHANGE_TEAM]: changeRoadshowTeam,
  [Types.ROADSHOW_EFFECT_SET_TEAM]: setRoadshowTeam,
  [Types.SET_ROADSHOW_DAILY_EVENT]: setRoadshowDailyEvent,
  // Deal Cleanup
  [UtilityTypes.DEAL_PURGE]: state => Object.assign({}, INITIAL_STATE),
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
