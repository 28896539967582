import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import FeedbackDetailHeader from './FeedbackDetailHeader';
import FeedbackDetailFeedback from './FeedbackDetailFeedback';
import PopupSystem from 'shared/components/PopupSystem';
import FeedbackModal from 'InvestorFeedback/Details/FeedbackModal';

import { connect } from 'react-redux';
import { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

import { openModal, closeModal } from 'actions/UtilityActions';
import { isModalOpen } from 'selectors'

import * as modalIds from 'constants/modalIds';

class FeedbackDetail extends Component {

  modalOpenClick = () => {
    if (!this.props.isInvestorFeedbackDetailModalOpen) {
      this.props.openModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(this.props.feedback.feedbackId))
    }
  }

  modalCloseClick = () => {
    this.props.closeModal(modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(this.props.feedback.feedbackId))
  }

  render() {
    const { feedback, isDCM } = this.props;

    return (

        <PopupSystem
          centered
          backgroundLayer
          modalID={modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(feedback.feedbackId)}
          isModalOpen={this.props.isInvestorFeedbackDetailModalOpen}
          closeIcon
          closeModal={this.modalCloseClick}
          clickableElementStyle={{ display: 'block' }}
          clickableElement={
            <div
              id={`${modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(feedback.feedbackId)}-btn`}
              style={{ cursor: 'pointer' }}
              onClick={this.modalOpenClick}
              aria-haspopup
              aria-label={
                translationString(
                  'investor_feedback.open_feedback_<feedback_name>',
                  this.props.language,
                  `Open feedback ${feedback.companyName}`,
                  [
                    ['<feedback_name>', feedback.companyName]
                  ]
                )
              }>
              <FeedbackDetailHeader feedback={feedback} />
                <Row>
                  <Col sm={12}>
                    <div className="investor-feedback-divider" />
                  </Col>
                </Row>
              <FeedbackDetailFeedback feedback={feedback} isDCM={isDCM} />
            </div>
          }
          content={
              <FeedbackModal
                details={feedback}
                closeModal={this.modalCloseClick}
              />
          }
        />
      );
    }
};

export default withLanguage(
  connect(
    (state, ownProps) => ({
        isInvestorFeedbackDetailModalOpen: isModalOpen(state, modalIds.INVESTOR_FEEDBACK_DETAIL_MODAL_ID(ownProps.feedback.feedbackId)),
    }),
    dispatch => ({
        // modals
      openModal: modalId => dispatch(openModal(modalId)),
      closeModal: modalId => dispatch(closeModal(modalId)),
    })
  )(FeedbackDetail)
);
