import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { compose } from 'recompose';
// TODO
import { HsbcButton } from 'mk-pattern-library-react';

import { getCurrentTranche, getShareDemand, getFullOrderBookTable } from 'selectors';
import TranslationText from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';

import { changeFullOrderBookSort } from 'actions/OrderBookActions';


import { featurePageIds } from 'constants/featurePages';
import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import OrderbookHeader from '../../shared/summary/OrderbookHeader';
import FullOrderBookTable from './FullOrderBookTable';

class ECMFullOrderBookContainer extends Component {

    constructor(props) {
        super(props);
        const dealsData = props.dealsData || {}
        this.state = {
            activeView: dealsData.inAllocationPhase ? "allocation" : "orderSummary",
            // active: {}
        };
        this.handleViewToggle = this.handleViewToggle.bind(this);
        this.createCells = this.createCells.bind(this);
    }

    componentDidMount() {
        this.props.viewChange();
    }

    handleViewToggle(view) {
        this.setState({
            activeView: view
        });
    }

    createCells() {
        const $cells = document.getElementsByClassName('footer-cell-value');
        const $cellDivs = Array.prototype.map.call($cells, (cell) => {
            const rect = cell.getClientRects()[0];
            const cellDivStyle = {
                top: 0,
                position: 'absolute',
                left: rect.left,
                width: `${rect.right - rect.left}px`,
                textAlign: cell.style.textAlign

            }
            return (
                <div style={cellDivStyle}>
                    hello
                </div>
            )
        });
        return $cellDivs

    }

    render() {
        const currentTrancheData = this.props.currentTrancheData;
        // const trancheDescription = `${dealsData.dealCurrency}m ${dealsData.dealTotalDemandCoverage} / ${dealsData.dealSizeFinal} / ${dealsData.dealCurrency} ${dealsData.dealDemandLowestSharePrice}-${dealsData.dealDemandHighestSharePrice}`;
        const dealsData = this.props.dealsData
        return (
            <div
              style={{ backgroundColor: "#fff" }}
            >
                <OrderbookHeader fullOrderbook={true} dealType='ecm' headerData={dealsData} dateReceived={this.props.dateReceived} />
                <ButtonGroup style={{marginBottom: 90 }}>
                    {dealsData.inAllocationPhase && (
                    <HsbcButton
                        id='btn-ecm-fo-a'
                        size="regular"
                        className={"btn-segment" + (this.state.activeView === "allocation" ? " active" : "")}
                        onClick={() => {
                            this.handleViewToggle("allocation");
                            this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_ALLOCATION)
                        }}
                    >
                        <TranslationText
                            dictionaryKey={"orders.allocation"}
                            defaultText={"Allocation"}
                        />
                    </HsbcButton>
                    )}
                    <HsbcButton
                        id='btn-ecm-fo-os'
                        size="regular"
                        className={"btn-segment" + (this.state.activeView === "orderSummary" ? " active" : "")}
                        onClick={() => {
                            this.handleViewToggle("orderSummary");
                            this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_ORDER_OVERVIEW)
                        }}
                    >
                        <TranslationText
                            dictionaryKey={"orders.order_overview"}
                            defaultText={"Order Overview"}
                        />
                    </HsbcButton>
                    <HsbcButton
                        id='btn-ecm-fo-dd'
                        size="regular"
                        className={"btn-segment" + (this.state.activeView === "demandDetail" ? " active" : "")}
                        onClick={() => {
                            this.handleViewToggle("demandDetail")
                            this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_DEMAND_DETAIL)
                        }}
                    >
                        <TranslationText
                            dictionaryKey={"orders.demand_detail"}
                            defaultText={"Demand Detail"}
                        />
                    </HsbcButton>
                     <HsbcButton
                        id='btn-ecm-fo-e'
                        size="regular"
                        className={"btn-segment" + (this.state.activeView === "engagement" ? " active" : "")}
                        onClick={() => {
                            this.handleViewToggle("engagement")
                            this.props.buttonChangeEvent(events.FULL_ORDERBOOK_TAB_ENGAGEMENT)
                        }}
                    >
                        <TranslationText
                            dictionaryKey={"orders.engagement"}
                            defaultText={"Engagement"}
                        />
                    </HsbcButton>
                </ButtonGroup>
                <FullOrderBookTable
                    key={this.state.activeView}
                    data={this.props.fullOrderBookTable}
                    viewType={this.state.activeView}
                    currentTrancheData={currentTrancheData}
                    dealsData={dealsData}
                    shareDemand={this.props.shareDemand}
                    onSort={this.props.changeFullOrderBookSort}
                />
            </div>
        )

    }
}
const mapStateToProps = (state) => ({
    orderData: state.orderBookData.orderData,
    currentTrancheData: getCurrentTranche(state),
    fullOrderBookTable: getFullOrderBookTable(state),
    titles: state.orderBookData.tranches,
    dateReceived: state.orderBookData.dateReceived,
    dealsData: state.utility.dealsData,
    shareDemand: getShareDemand(state),
    dealId: state.utility.dealsData.dealId,
});

const mapDispatchToProps = dispatch => ({
    changeFullOrderBookSort: (column, direction) => dispatch(changeFullOrderBookSort(column, direction)),
});

export default compose(
    withAnalytics((ev, view) => ({
        viewChange: () => view(featurePageIds.ORDERBOOK_FULL_PAGE),
        buttonChangeEvent: eventDetails => ev(eventDetails),
    })),
    withLanguage,
    connect(mapStateToProps, mapDispatchToProps)
)(ECMFullOrderBookContainer);
