import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from "styled-components";
import { typography } from "shared/styles/variables/theme";
import { Icon } from 'mk-pattern-library-react';
import { leftAlignedHeaderStyle, leftAlignedCellStyle, centerAlignedCellStyle } from 'OrderBook/shared/table/styles';
import Table from 'OrderBook/dcm/TableContainer/Table';
import TableHead from 'OrderBook/dcm/TableContainer/TableHead';
import TableBody from 'OrderBook/dcm/TableContainer/TableBody';
import TableRow from 'OrderBook/dcm/TableContainer/TableRow';
import TableHeaderCell from 'OrderBook/dcm/TableContainer/TableHeaderCell';
import TableColumnCell from 'OrderBook/dcm/TableContainer/TableColumnCell';
import { dateFormatter } from 'OrderBook/SharedTableComponents/formatters';
import { isIsvExternalUser, getIsvCurrentDealId, selectedReferenceInformationDataForCurrentDeal } from "Isv/selectors";
import { isIsvRRTeamUser, isIsvCsmUser } from "selectors";
import { setTitleColumnWidth, getReferenceDataForCurrentDeal, handleReferenceInformationDataForCurrentDeal } from 'Isv/actions/IsvDocumentsAction';

const tableHeader = {
  color: "#333333",
  fontWeight: "600",
  fontSize: `${typography.sizes["12px"]}`,
  height: "50px",
  verticalAlign: "middle",
};

const fontSizeDefault = {
  fontSize: `${typography.sizes["12px"]}`,
  height: `50px`,
};

const deleteCellwidth = {
  width: '3%'
}

const commentsHeader = {
  width: "15%",
};

const uploadCellwidth = {
  width: "8%",
};

const reUploadCellwidth = {
  width: "4%",
};

const iconStyles = {
  width: 22,
  position: 'relative',
  display: 'inline-block',
  color: 'rgb(77, 100, 116)',
  cursor: 'pointer',
}

const IconContainer = styled.span`
  display: inline-block;
  width: 30px;
`

const LinkTableRowColumn = styled.span`
  color: #305a85;
`;

const InformationTitleColumn = styled.span`
  cursor: ${(props) => props.cursor ? 'pointer' : 'default'};
`;

const FileNameText = styled.div`
   color: black;
   font-size:10px;
`;

const ReferenceInformationTable = ({ 
  dealId,
  setTitleColumnWidth,
  viewDocument,
  handleDeleteConfirmationDialogue,
  selectedReferenceData,
  getReferenceDataForCurrentDeal,
  isIsvRRTeamUser,
  handleReferenceInformationDataForCurrentDeal,
  handleOpenUploadOverlay
}) => {
  const [referenceInformationData, setReferenceInformationData] = useState(null);

  const titleTableColumnWidth = useRef()
  
  useEffect(() => {
    handleReferenceInformationDataForCurrentDeal(null);
    getReferenceDataForCurrentDeal(dealId, isIsvRRTeamUser);
  },[])

  useEffect(() => {
      setReferenceInformationData(selectedReferenceData);
      handleReferenceInformationDataForCurrentDeal(selectedReferenceData);
    
      if (titleTableColumnWidth) {
        const titleColumnWidth = titleTableColumnWidth.current.childNodes[0].children[0].childNodes[0].childNodes[0].offsetWidth;
        setTitleColumnWidth(titleColumnWidth);
    }
  }, [selectedReferenceData])

  const viewSelectedDocument = (refDocument, docRefId) => {
    viewDocument(docRefId, refDocument.title, refDocument.fileName);
  }

  return (
    <div className="react-bootstrap-table" style={{ maxHeight:`calc(100vh - 280px)`, overflow:"auto"}} ref={titleTableColumnWidth}>
      <Table className="Table standard-summary-table">
        <TableHead style={{ width: `100%`, zIndex:999, position:"sticky", top:0 }}>
          <TableRow>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader,
              }}
            >
              Title
            </TableHeaderCell>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...commentsHeader,
              }}
            >
              Uploaded By
            </TableHeaderCell>
            <TableHeaderCell
              style={{
                ...leftAlignedHeaderStyle,
                ...tableHeader,
                ...uploadCellwidth,
              }}
            >
              Upload Date
            </TableHeaderCell>
            <TableHeaderCell
                style={{
                  ...centerAlignedCellStyle,
                  ...tableHeader,
                  ...reUploadCellwidth,
                }}
              >
                Upload
              </TableHeaderCell>
            <TableHeaderCell
              style={{
                ...centerAlignedCellStyle,
                ...tableHeader,
                ...deleteCellwidth,
              }}
            >
              Delete
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          referenceInformationData &&
          referenceInformationData.length > 0 &&
          referenceInformationData.map((doc, index) => 
          {
            return (
              <TableRow key={index}>
                <TableColumnCell
                  style={{
                    ...leftAlignedCellStyle,
                    ...fontSizeDefault,
                  }}
                >
                  <LinkTableRowColumn id={`link-doc`}>
                    <InformationTitleColumn
                      onClick={() => viewSelectedDocument(doc, doc.docRefId)}
                    >
                        {`${doc.title}`}
                      </InformationTitleColumn>
                      {doc.fileName && <FileNameText>{doc.fileName}</FileNameText>}
                  </LinkTableRowColumn>
                </TableColumnCell>
                <TableColumnCell
                  style={{
                    ...leftAlignedCellStyle,
                    ...fontSizeDefault,
                  }}
                >
                    {doc.uploadedBy}
                </TableColumnCell>
                <TableColumnCell
                  style={{
                    ...leftAlignedCellStyle,
                    ...fontSizeDefault,
                  }}
                >
                  {dateFormatter("dayMonthYearGMTtoISTZone", doc.createdDate)}
                </TableColumnCell>
                <TableColumnCell
                    style={{
                      ...centerAlignedCellStyle,
                      ...fontSizeDefault,
                    }}
                  >
                    <IconContainer onClick={() => handleOpenUploadOverlay(true, doc, doc.title, false, true)}>
                      <Icon
                        style={{
                          ...iconStyles,
                          opacity:  1,
                          cursor: 'pointer'
                        }}
                        iconName="Upload"
                      />
                    </IconContainer>
                </TableColumnCell>
                <TableColumnCell
                        style={{
                          ...centerAlignedCellStyle,
                          ...fontSizeDefault
                        }}
                      >
                        {
                            <IconContainer onClick= {() => handleDeleteConfirmationDialogue(true, doc.docRefId)}>
                              <Icon
                                style={{
                                  ...iconStyles,
                                  width: 12,
                                  marginRight: 0,
                                  cursor: 'pointer',
                                  visibility: '',
                                  opacity: 1,
                                }}
                                iconName="closeX" />
                            </IconContainer>
                        }
                </TableColumnCell>
              </TableRow>
            );
          }) 
          }
        </TableBody>
      </Table>
    </div>
  );
};

export default compose(
  connect(
    (state) => ({
      isIsvExternalUser: isIsvExternalUser(state),
      isIsvCsmUser: isIsvCsmUser(state),
      isIsvRRTeamUser: isIsvRRTeamUser(state),
      dealId: getIsvCurrentDealId(state),
      selectedReferenceData: selectedReferenceInformationDataForCurrentDeal(state),
    }),
    (dispatch) => ({
      setTitleColumnWidth: (width) => {
        dispatch(setTitleColumnWidth(width))
      },
      getReferenceDataForCurrentDeal: (dealId, isIsvRRTeamUser) => {
        dispatch(getReferenceDataForCurrentDeal(dealId, isIsvRRTeamUser))
      },
      handleReferenceInformationDataForCurrentDeal: (data) => {
        dispatch(handleReferenceInformationDataForCurrentDeal(data))
      }
    })
  )
)(ReferenceInformationTable);