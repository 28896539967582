import { createReducer } from 'reduxsauce';

import * as storageKeys from 'constants/storageKeys';
import Types from 'actions/Types';

require('array.prototype.find').shim();

export const INITIAL_STATE = {
    isAuthenticated: false,
    checkingPulse: false,
    pulseMessage: '',
    pulseExpire: 120,
    sessionExpired: false,
    fetching: false,
    initializing: false,
    beating: false,
    pristine: true,
    invalidFields: {
      userNameField: true,
    },
    pendingAuthentication: false,
    authenticationResponse: null,
    showTermsAndConditions: false,
   
    acceptTermsChecked: false,
    isPendingAuth: false,
    siteText: {},
    devices: [],
    termsAndConditions: {},
    authType: null,
    userName: '',
    showLoginModal: false,

    isIsvCsmUser: false,
    isISVUser: false 
};

const attemptAuthentication = (state, action) => Object.assign({}, state, {
    name: action.name,
    isPendingAuth: false,
});

const setAuthType = (state, action) => ({
  ...state,
  authType: action.authType
});

const setLoginError = (state, action) => ({
  ...state,
  error: action.e,
});

const setIsIsvUser = (state, action) => ({
  ...state,
  isISVUser: action.e,
});

const setUsername = (state, action) => ({
  ...state,
  userName: action.userName
});

const setLoginModal = (state, action) => ({
  ...state,
  showLoginModal: action.showLoginModal
});

const setInvalidFields = (state, action) => ({
  ...state,
  invalidFields: action.fields
})

const logintFormExtSubmit = (state, action) => ({
  ...state,
  userName: action.userNameField,
})

const setAuthentication = (state, action) => Object.assign({}, state, {
    error: false,
    name: action.name,
    isAuthenticated: action.status,
    userName: action.context.given_name,
    uniqueName: action.context.unique_name,
    isInternalUser: action.context.isInternalUser,
    isISVUser: action.context.isISVUser,
    isIsvCsmUser: Array.isArray(action.context.role) ? action.context.role.some(function(r) { return r === 'ISVDealTeam' || r === 'ISVDealAdmin' }) : false,
    isIsvRRTeamUser: Array.isArray(action.context.role) ? action.context.role.some(function(r) { return r === 'ISVDealRRTeam' }) : false,
    // access_token: action.context.access_token,
    // token_type: action.context.token_type,
    // expires_in: action.context.expires_in,
    //
    beating: true
});

const loginFailure = (state, action) => Object.assign({}, state, {
    error: true,
    loginStatus: "failure",
    //
    beating: false,
});

const logout = (state, { preventSSO }) => Object.assign({}, state, {
    isAuthenticated: false,
    fetching: false,
    name: null,
    userName: null,
    userType: null,
    project: null,
    company: null,
    dealId: null,
    preventSSO,
    //
    beating: false,
    isISVUser: false
});

const checkPulse = (state, action) => Object.assign({}, state, {
    checkingPulse: action.checkingPulse,
    pulseExpire: action.pulseExpire,
    beating: false,
});

const logOff = (state, action) => Object.assign({}, state, {
    checkingPulse: false,
    sessionExpired: true,
});

const renewToken = (state, action) => Object.assign({}, state, {
    checkingPulse: false,
    //
    beating: false,
});

const renewTokenReceive = (state, action) => {
    if (action.access_token) {
        window.sessionStorage.setItem(storageKeys.TOKEN, action.access_token); // clear out tokens, exp from sessionStorage
    }

    // if empty then no problem - will display proper modal
    window.sessionStorage.setItem(storageKeys.EXPIRATION, Date.now() + (action.expires_in * 1000));

    return Object.assign({}, state, {
        pulseMessage: action.message,
        // access_token: action.access_token,
        // token_type: action.token_type,
        // expires_in: Date.now() + (action.expires_in * 1000),
        // beating: false,
        beating: true,
    });
};

const loginFormInvalid = (state, action) => ({
  ...state,
  pristine: false,
  invalidFields: {
    ...state.invalidFields,
    [action.fieldName] : true,
  }
});

const loginFormValid = (state, action) => ({
  ...state,
  pristine: false,
  invalidFields: {
    ...state.invalidFields,
    [action.fieldName]: false,
  }
});

const initializing = (state, action) => ({
  ...state,
  initializing: action.initializing,
});

const setPendingAuthentication = (state, action) => ({
  ...state,
  pendingAuthentication: action.pending,
});

const setAuthenticationResponse = (state, action) => ({
  ...state,
  authenticationResponse: action.message,
});

const showTermsAndConditions = (state, action) => ({
  ...state,
  showTermsAndConditions: true,
})

const acceptTermsAndConditions = (state, action) => ({
  ...state,
  showTermsAndConditions: false,
});

const onTermsCheckedChange = (state, action) => ({
  ...state,
  acceptTermsChecked: action.checked,
})

const receiveTermsAndConditions = (state, action) => ({
  ...state,
  termsAndConditions: {
    [action.data.locale]: action.data
  }
})

const attemptSSO = state => ({
  ...state,
  isPendingAuth: true,
});

const setSiteText = (state, action) => ({
  ...state, 
  siteText: {
    ...state.siteText,
    [action.siteTextType.name]: {
      ...state.siteText.name, 
      [action.langCode]: action.text
    }
  }
});

const setManageDevices = (state, action) => ({
  ...state,
  devices: action.devices,
});

const setDeviceDeactivationMessages = (state, action) => ({
  ...state,
  deviceDeactivationMessage: action.response,
});

const clearDeviceDeactivationMessages = state => ({
  ...state,
  deviceDeactivationMessage: undefined
})

const ACTION_HANDLERS = {
    [Types.INITIALIZING]: initializing,
    [Types.ATTEMPT_AUTHENTICATION]: attemptAuthentication,
    [Types.SET_AUTH_TYPE]: setAuthType,
    [Types.SET_LOGIN_ERROR]: setLoginError,
    [Types.SET_IS_ISV_USER]: setIsIsvUser,
    [Types.SET_USERNAME]: setUsername,
    [Types.SET_LOGIN_MODAL]:setLoginModal,
    [Types.SET_INVALID_FIELDS]: setInvalidFields,
    [Types.LOGIN_FORM_EXT_SUBMIT]: logintFormExtSubmit,
    [Types.SET_AUTHENTICATION]: setAuthentication,
    [Types.LOGIN_FAILURE]: loginFailure,
    [Types.LOGOUT]: logout,
    [Types.CHECK_PULSE]: checkPulse,
    [Types.LOGOFF]: logOff,
    [Types.RENEW_TOKEN]: renewToken,
    [Types.RENEW_TOKEN_RECEIVE]: renewTokenReceive,
    [Types.LOGIN_FORM_INVALID]: loginFormInvalid,
    [Types.LOGIN_FORM_VALID]: loginFormValid,
    [Types.SET_PENDING_AUTHENTICATION]: setPendingAuthentication,
    [Types.SET_AUTHENTICATION_RESPONSE]: setAuthenticationResponse,
    [Types.SHOW_TERMS_AND_CONDITIONS]: showTermsAndConditions,
    [Types.ACCEPT_TERMS_CONDITIONS]: acceptTermsAndConditions,
    [Types.ON_TERMS_CHECKED_CHANGE]: onTermsCheckedChange,
    [Types.RECEIVE_TERMS_AND_CONDITIONS]: receiveTermsAndConditions,
    [Types.API_ATTEMPT_SSO]: attemptSSO,
    [Types.SET_SITE_TEXT]: setSiteText,
    [Types.SET_MANAGE_DEVICES]: setManageDevices,
    [Types.SET_DEVICE_DEACTIVATE_MESSAGES]: setDeviceDeactivationMessages,
    [Types.CLEAR_DEVICE_DEACTIVATE_MESSAGES]: clearDeviceDeactivationMessages,
    
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
