import React, { PureComponent } from 'react';

import TranslationText from 'translations/TranslationText';
import { Col } from 'shared/components/Grid';

import { beautifyPriceDemand } from '../util';

class SingleTranche extends PureComponent {
    render() {
        const { feedbackTranchePriceDemand } = this.props;

        // it's not defined
        if (!feedbackTranchePriceDemand) return null;
        // it's multiple tranche
        if (feedbackTranchePriceDemand.length > 1) return null;

        return (
            <React.Fragment>
                <Col size={2}>
                    <h2 style={{ margin: 0 }} className="text-univ-bd text-normal">
                        <TranslationText
                            dictionaryKey={"investor_feedback.valuation"}
                            defaultText={"Valuation"}
                        />
                    </h2>
                    <div>
                        {feedbackTranchePriceDemand[0].priceDemand.valuation}
                    </div>
                </Col>
                <Col size={2}>
                    <h2 style={{ margin: 0 }} className="text-univ-bd text-normal">
                        <TranslationText
                            dictionaryKey={"investor_feedback.ioi"}
                            defaultText={"IoI"}
                        />
                    </h2>
                    <div>
                        {
                            beautifyPriceDemand(feedbackTranchePriceDemand[0])
                        }
                    </div>
                </Col>
            </React.Fragment>
        )
    }
}

export default SingleTranche;
