import Types from 'actions/Types';

import dealFeatures from 'constants/dealFeatures';

const updateSelectedDay = (day) => ({
    type: Types.ROADSHOW_UPDATE_SELECTED_DAY,
    day,
});

const changeRoadshowTeam = team => ({
    type: Types.ROADSHOW_CHANGE_TEAM,
    team,
});

const setRoadshowTeam = team => ({
    type: Types.ROADSHOW_EFFECT_SET_TEAM,
    team,
});

const receiveRoadShowSummary = ({ data }) => ({
    type: Types.API_RECEIVE_ROADSHOW_SUMMARY,
    RoadshowData: data,
});
const requestRoadShowSummary = (dealId, loader) => ({
    type: Types.API_REQUEST_ROADSHOW_SUMMARY,
    endPoint: `/v1/roadshow/${dealId}/summary`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveRoadShowSummary,
    dealId,
    loader,
    //
    feature: dealFeatures.ROADSHOW,
});

const receiveRoadShowEvents = ({ data }) => ({
    type: Types.API_RECEIVE_ROADSHOW_EVENTS,
    roadShowEventsData: data,
});
const requestRoadShowEvents = (dealId, loader) => ({
    type: Types.API_REQUEST_ROADSHOW_EVENTS,
    endPoint: `/v1/roadshow/${dealId}`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveRoadShowEvents,
    dealId,
    loader,
    //
    feature: dealFeatures.ROADSHOW,
});

const receiveRoadShowContacts = ({ data }) => ({
    type: Types.API_RECEIVE_ROADSHOW_CONTACTS,
    roadShowContactsData: data,
});
const requestRoadShowContacts = (dealId, loader) => ({
    type: Types.API_REQUEST_ROADSHOW_CONTACTS,
    endPoint: `/v1/roadshow/${dealId}/contacts`,
    params: {},
    ajaxType: 'GET',
    onSuccess: receiveRoadShowContacts,
    loader,
    //
    feature: dealFeatures.ROADSHOW,
});

const setRoadShowDailyEvent = eventId => ({
    type: Types.SET_ROADSHOW_DAILY_EVENT,
    eventId,
})

export {
    updateSelectedDay,
    receiveRoadShowSummary,
    requestRoadShowSummary,
    receiveRoadShowEvents,
    requestRoadShowEvents,
    receiveRoadShowContacts,
    requestRoadShowContacts,
    changeRoadshowTeam,
    setRoadshowTeam,
    setRoadShowDailyEvent,
};
