import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getSelectedLanguage,
  makeGetSiteTextSelector,
  getSiteTextValue 
} from "selectors";
import { openSiteText } from "actions/LoginActions";
import { Navbar } from "react-bootstrap";
import logo from "mk-pattern-library-react/images/HSBC_Logo.svg";
import {
  Logo,
} from './styles/Login';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from "./PrivacyPolicy";

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  margin: 20px auto;
`;
const ContentStyle = {
 marginLeft:"-177px"
};

class DataPrivacy extends Component {

  componentDidMount() {
    this.props.openSiteText(this.props.langCode, this.props.siteTextType ? this.props.siteTextType : getSiteTextValue(this.props.routeParams.routeValue));
    // this.props.navigateEvent(this.props.url ? this.props.url : this.props.location )
  }
  

  render() {
    return (
      <>
         <Navbar style={{ height: 73.5 }}>
          <Navbar.Header>
            <Navbar.Brand>
              <Logo src={logo} alt="HSBC"/>MyDeal
            </Navbar.Brand>
          </Navbar.Header>
        </Navbar>
        <InnerContainer>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta http-equiv="Content-Style-Type" content="text/css" />
          <meta name="viewport" content="initial-scale=1.0" />
          <link rel="stylesheet" type="text/css" href="/terms.css" />
          <link rel="stylesheet" type="text/css" href="/cpolicy.css" />
          {
            (window.location.pathname === '/cookienotice' || window.location.pathname === '/settings/cookienotice') && 
            <>
              <div style={ContentStyle}>
                <CookiePolicy />
              </div>
            </>
          }
          { 
            (window.location.pathname === '/privacynotice' || window.location.pathname === '/settings/privacynotice') &&
            <>
              <div style={ContentStyle}>
                <PrivacyPolicy />              
              </div>
              {/* <div
                style={ContentStyle}
                dangerouslySetInnerHTML={{ __html: this.props.siteText }}
              /> */}
            </>
          }  
        </InnerContainer>
      </>
    );
  }
}

const makeMapStateToProps = (state, ownProps) => {
    const getSiteText = makeGetSiteTextSelector(ownProps.siteTextType ? ownProps.siteTextType.name : getSiteTextValue(ownProps.routeParams.routeValue).name);
    const mapStateToProps = (state, ownProps) => ({
    langCode: getSelectedLanguage(state),
    siteTextType: ownProps.siteTextType ? ownProps.siteTextType : getSiteTextValue(ownProps.routeParams.routeValue),
    siteText: getSiteText(state),
    "aria-label": ownProps["aria-label"],
     });
   
    return mapStateToProps;
   };

export default connect(makeMapStateToProps, (dispatch) => ({
  openSiteText: (langCode, siteTextType) => {
    dispatch(openSiteText(langCode, siteTextType));
  },
  // navigateEvent:(url)=>{
  //   browserHistory.push(url);
  //   dispatch(eventTriggered(mapLinkToAnalyticEvent(url)));
  // },
}))(DataPrivacy);

