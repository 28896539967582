import React from 'react';
import styled from 'styled-components';

import { FontIcon } from 'shared/components/Icon';

import { backgrounds, typography, components, fontColors } from 'shared/styles/variables/theme';

import { landingDimensions } from '../Landing.styles';

export const TileContainerOuter = styled.div`
    padding: 0px;
    width: ${landingDimensions.TILE_WIDTH}px;
    min-width: ${landingDimensions.TILE_WIDTH}px;
    max-width: ${landingDimensions.TILE_WIDTH}px;
    height: ${landingDimensions.TILE_HEIGHT}px;
    background: ${backgrounds.white};
    border: 1px solid ${backgrounds.gray};
    opacity: 0.9;
`;

export const TileContentInner = styled.div`
    padding: 12px;
`;

export const TileHeader = styled.h3`
    margin: 0px;
    padding: 12px;
    font-size: ${typography.sizes['13px']};
    line-height: 1;
    border-bottom: 1px solid ${backgrounds.gray};

    a {
        ${components.link['--default']}

        font-family: ${typography.family.normal};

        &:focus {
            ${components.link['--state']['--focus']}
        }

        &:hover {
            ${components.link['--state']['--hover']}

            span::before {
                text-decoration: none;
            }
        }
    }
`;

export const TileContent = styled.div``;

export const DateContent = styled.div`
    color: ${fontColors.darkGrey};
    font-size: ${typography.sizes['11px']};
`;

const linkContainerIconStyle = {
    verticalAlign: 'middle',
    marginLeft: 7,
    color: '#DB0011',
    fontSize: 10,
    display: 'inline-block',
    marginTop: -2,
};

export const ChevronIcon = React.memo(props =>
    <FontIcon
        style={linkContainerIconStyle}
        icon="Chevron"
        aria-hidden
    />
)

export const EmptyContent = styled.div`
    font-size: ${typography.sizes['12px']};
    margin-top: 2px;
`;
