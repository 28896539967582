import React from 'react';
import Table from 'shared/components/Table';
import { translationString } from 'translations/TranslationText';
import withLanguage from 'translations/withLanguage';
import { mapThemeTypeOrSentimentToKey } from 'util/translationMappings';


import  {
    firstHeaderAlignedLeftStyle,
    firstRowAlignedLeftStyle,
    otherHeaderAlignedRightStyle,
    otherRowAlignedRight
} from 'shared/components/Table';

const halfWidth = {
  minWidth: '50%',
  maxWidth: '50%',
  width: '50%',
}

const Columns = (props, label) => {
  const columns = [{
    dataField: 'label',
    text: `${translationString(label, props.language, 'Th')} ${translationString(`investor_feedback.themes`, props.language, 'Themes')}`,
    attrs: {
      role: 'rowheader',
    },
    headerStyle: {
      ...firstHeaderAlignedLeftStyle,
      ...halfWidth,
    },
    style: {
      ...firstRowAlignedLeftStyle,
      ...halfWidth,
    }
  }, {
    dataField: 'value',
    text: translationString(`orders.total`, props.language, 'Total'),
    style: {
      ...otherRowAlignedRight,
      ...halfWidth,
      textAlign: 'center',
    },
    headerStyle: {
      ...otherHeaderAlignedRightStyle,
      ...halfWidth,
      textAlign: 'center',
    }
  }];

  return columns;
}

const SentimentTables = props =>
  props.series && props.series.map(series =>
    <Table
      classes='fixed standard'
      data={series.data}
      bordered={true}
      keyField={'label'}
      columns={Columns(props, mapThemeTypeOrSentimentToKey(series.seriesTitle))}
      caption={`Total count of ${translationString(mapThemeTypeOrSentimentToKey(series.seriesTitle), props.language, '@ ThemeType @')} theme types for feedback with a ${translationString(mapThemeTypeOrSentimentToKey(props.sentimentType), props.language, '@ Sentiment Type @')} sentiment`}
    />
  );

export default withLanguage(SentimentTables);
