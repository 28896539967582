import React, { Component } from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { sortBy, some } from 'lodash';
import moment from 'moment-timezone';
import { compose } from 'recompose';
// TODO
import { ValueFormatters } from 'mk-pattern-library-react';

import { ALL_TEAMS_SELECTED } from 'constants/multiteam';
import { getTeams, getMultiTeamMode, getSelectedTeam, getTeamsDetails } from 'selectors';
import { roadShowEventTypes } from 'util/siteConstants';
import TranslationText from 'translations/TranslationText';
import withTranslate from 'shared/hoc/withTranslate';
import { buttonKeyHandler } from 'util/onKeyPress';

class RoadShowDayView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            interval: null,
        }
        this.findNextEvent = this.findNextEvent.bind(this);
        this.handleDayViewCellClick = this.handleDayViewCellClick.bind(this);
    }

    handleDayViewCellClick(selectedEvent) {
        this.props.handleDayViewUpdate(selectedEvent);

        const elem = document.getElementById('event-detail-container')
        if (elem) elem.focus();
    }

    findNextEvent(events, now) {
        for (let i = 0; i < events.length; i++) {
            //if the event is today and in the future
            if ((moment().utc().isSame(moment(events[i].startDateUtc).utc(), 'day')) && moment(events[i].startDateUtc).utc().isAfter(now)) {
                return events[i];
            }
        }
    }

    findNextEvents(events) {
      return events.reduce((nextEvents, event) => {
        if(!moment(event.startDateUtc).isSame(moment().utc(), 'day') || moment(event.startDateUtc).isBefore(moment().utc())) {
          return nextEvents;
        }

        if(!nextEvents.length || moment(event.startDateUtc).isBefore(moment(nextEvents[0].startDateUtc))) {
          return [event];
        }

        if(moment(event.startDateUtc).isSame(moment(nextEvents[0].startDateUtc))) {
          nextEvents.push({
            eventId: event.eventId,
            startDateUtc: event.startDateUtc,
          });
        }

        return nextEvents
      }, []);
    }

    findEventsStartingInNextHour(events, now) {
      return events.reduce((imminentEvents, event) => {
        if(moment(event.startDateUtc).isBefore(moment.utc())) {
          return imminentEvents;
        }

        if(moment(event.startDateUtc).diff(moment().utc(), 'minutes') < 60) {
          imminentEvents.push({
            eventId: event.eventId,
            startDateUtc: event.startDateUtc,
          });
        }

        return imminentEvents
      }, []);
    }

    getEventLabel(nextEvents, imminentEvents, event) {
      if(imminentEvents.length > 0) {
        if(some(imminentEvents, ie => ie.eventId === event.eventId)) {
          const minuteDiff = moment(event.startDateUtc).utc().diff(moment().utc(), 'minutes');

            if (minuteDiff > 1) {
                return (
                    <TranslationText
                        dictionaryKey={"roadshow.starting_in_<x>_mins"}
                        replace={[
                            ["<x>", minuteDiff]
                        ]}
                        defaultText={"Next Event"}
                    />
                )
            } else {
                return (
                    <TranslationText
                        dictionaryKey={"roadshow.starting_in_<x>_min"}
                        replace={[
                            ["<x>", minuteDiff]
                        ]}
                        defaultText={"Next Event"}
                    />
                )
            }
        }
      } else if(some(nextEvents, ie => ie.eventId === event.eventId)) {
        return (
            <TranslationText
                dictionaryKey={"roadshow.next_event"}
                defaultText={"Next Event"}
            />
        )
      }

      return "";
    }

    componentDidMount() {
        this.interval = setInterval(this.forceUpdate.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const events = sortBy(this.props.dayEvents, ['startDateUtc', 'startDateLocal']);
        const hasEvents = (events.length);
        if (!hasEvents) {
            return (
                <div
                    role="region"
                    tabIndex={0}
                    aria-label={this.props.containerRegionAriaLabel}
                    className="no-events-indicator"
                >
                    <TranslationText
                        dictionaryKey={"roadshow.no_mydeal_events_this_day"}
                        defaultText={"No MyDeal events this day."}
                    />
                </div>
            )
        }

        const nextEvents = this.findNextEvents(events);
        const imminentEvents = this.findEventsStartingInNextHour(events);

        // need 1st row to also have top border
        const eventCells = events.map((event, index) => {
            const eventType = roadShowEventTypes.has(event.eventTypeId) ? roadShowEventTypes.get(event.eventTypeId) : roadShowEventTypes.get(0);
            let xNum;

            if (event.eventTypeId === 3) {

                let investors = [];
                if (event.persons && event.persons.length) {
                    event.persons.forEach((person) => {
                        switch (person.personTypeId) {
                            case 2:
                                investors.push(person);
                                break
                            default:
                                break
                        }
                    });
                }

                let investorsByFirm = {};
                if (investors) {
                    investors.forEach((investor) => {
                        if (!investorsByFirm[investor.company]) {
                            investorsByFirm[investor.company] = true;
                        }
                        xNum = Object.keys(investorsByFirm).length
                    });
                }
            }

            // Add class to denote past event
            const cellClass = "day-view-cell " + (moment(event.startDateUtc) < moment().utc() ? "past-event " : "") +
                (nextEvents.some(ne => ne.eventId === event.eventId) ? "day-view-next-event " : "")


            let titleClass = (event.booked === null || event.booked === true ? "text-univ-bd " : " ");

            const teams = event.teams && event.teams
                .filter(teamName => this.props.teams.indexOf(teamName) >= 0)
                .map(teamName => ({ teamName, color: ValueFormatters.findTeamColor(this.props.teams, teamName) }));

            const eventLabel = this.getEventLabel(nextEvents, imminentEvents, event);
            return (
                <ListGroupItem
                    id={event.eventId}
                    key={event.eventId}
                    aria-selected={this.props.activeEvent.eventId === event.eventId}
                    tabIndex={0}
                    style={{marginBottom: 0, borderLeft: 'none', borderRight: 'none' }}
                    onClick={this.handleDayViewCellClick.bind(this, event)}
                    onKeyDown={e => buttonKeyHandler(e, () => this.handleDayViewCellClick(event))}
                    active={(this.props.activeEvent ? (this.props.activeEvent.eventId === event.eventId) : false)}
                    className={cellClass}
                >
                    <Row className="text-univ-lt">
                        {
                            (eventLabel !== "") && (
                                <div className="next-event-indicator text-eleven" >
                                    <div className="next-event-indicator-caret" />
                                    <span className="next-event-indicator-text">
                                        {eventLabel}
                                    </span>
                                </div>
                            )
                        }

                        <Col sm={2} style={{ paddingLeft: 10 }}>
                            <Row>
                                <Col sm={12} className="text-medium" style={{ paddingRight: "0px" }}>
                                    {event.eventTypeId !== 12 && ValueFormatters.dateFormatter('zoneTime', moment(event.startDateLocal))}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="text-small">
                                    {event.eventTypeId !== 12 && event.startTimeZone}
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={10} style={{ paddingLeft: 0 }}>
                            <div style={{ float: "left", minHeight: 1, width: 15, position: 'relative', left: -10, top:2 }}>
                                {eventType.icon}
                            </div>
                            <div style={{ overflow: 'hidden' }}>
                                <Row>
                                    <Col sm={12} style={{ lineHeight: '23px', minHeight:23 }} className={"cell-title text-large " + titleClass + ((event.eventTypeId < 8) ? "day-view-event-title" : "")}>
                                        {event.title}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="text-normal day-view-type text-univ-rg" style={{ padding: 0 }}>
                                        <Col sm={event.teams ? 6 : 12} className="text-normal day-view-type text-univ-rg">
                                            {eventType.title(event.host, xNum)}
                                            {
                                                (event.eventTypeId >= 8 && event.eventTypeId < 12) && (event.endDateLocal) && (
                                                    <React.Fragment>
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <TranslationText
                                                            dictionaryKey={"roadshow.arrive"}
                                                            defaultText={"Arrive"}
                                                        />
                                                        &nbsp;
                                                        {
                                                            ValueFormatters.dateFormatter('zoneTime', event.endDateLocal)
                                                        }
                                                        &nbsp;
                                                        {
                                                            event.endTimeZone
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                        </Col>
                                        {
                                            event.teams && (
                                                <Col sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    {
                                                        teams.map(({ teamName, color }) => (
                                                            <React.Fragment key={teamName}>
                                                                <div style={{ background: color, width: 3, height: 16, margin: '0 5px 0 10px' }} />
                                                                {
                                                                    this.props.teamDetails[teamName].roadshowTeamCode
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Col>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        })

        return (
            <ListGroup
                role="region"
                tabIndex={0}
                aria-label={this.props.containerRegionAriaLabel}
                className="day-view-container"
                style={{ padding: 0, marginBottom: 0, backgroundColor: 'white' }}
            >
                {eventCells}
            </ListGroup>
        )
    }
};

export default compose(
    connect(
        state => ({
            teams: getTeams(state),
            multiteamMode: getMultiTeamMode(state),
            allTeamsSelected: getSelectedTeam(state) !== ALL_TEAMS_SELECTED,
            teamDetails: getTeamsDetails(state),
        }),
    ),
    withTranslate(translate => ({
        containerRegionAriaLabel: translate('', 'Day view list'),
    }))
)(RoadShowDayView);
