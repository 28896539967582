import React from 'react';
import withLanguage from './withLanguage';
import languages from './languages.json';

const SHOW_UNFOUND_LANG_KEYS = window.__SHOW_UNFOUND_LANG_KEYS === 'true';

const LANGUAGE_KEY_UNDEFINED = (key) => `@@@ ${key} does not exist @@@`;
const TRANSLATION_UNDEFINED =  (key) =>`@@@ ${key} not translated @@@`;

class TranslationText extends React.Component {
  render() {
    const { defaultText, dictionaryKey, replace, language, style } = this.props;

    const languageDictionary = languages[language];

    if (languageDictionary[dictionaryKey] || languageDictionary[dictionaryKey] === '') {
        let text = languageDictionary[dictionaryKey];

        if (!text) {
            if (SHOW_UNFOUND_LANG_KEYS) {
                return <span style={style}>{TRANSLATION_UNDEFINED(dictionaryKey)}</span>;
            }

            return <span style={style}>{defaultText}</span>
        }

        if (replace && replace.length) {
            replace.forEach(([ key, value ]) => {
                text = text.replace(key, value);
            })
        }

        return <span style={style}>{text}</span>;
    }

    if (SHOW_UNFOUND_LANG_KEYS) {
        return <span style={style}>{LANGUAGE_KEY_UNDEFINED(dictionaryKey)}</span>;
    }

    return <span style={style}>{defaultText}</span>
  }
}

export const translationString = (key, language, defaultText, replace = []) => {
    if (!languages || !languages[language]) {
        return defaultText;
    }

    const languageDictionary = languages[language];

    if (languageDictionary[key] || languageDictionary[key] === '') {
        let text = languageDictionary[key];

        if(!text) {
            if (SHOW_UNFOUND_LANG_KEYS) {
                return TRANSLATION_UNDEFINED(key);
            }

            return defaultText;
        }

        if (replace && replace.length) {
            replace.forEach(([ key, value ]) => {
                text = text.replace(key, value);
            })
        }

        return text;
    }

    if (SHOW_UNFOUND_LANG_KEYS) {
        return LANGUAGE_KEY_UNDEFINED(key);
    }

    return defaultText;
}


export default withLanguage(TranslationText);
