import React from 'react';
import SortIcons from 'shared/components/SortIcon';

const handleKeyDown = (e, b, c) => {
  if (e.key === "Enter" || e.key === " ") {
    e.stopPropagation();
    e.target.click();
  }
}

export default (column, index, components) =>
  <span role='button' tabIndex='0' onKeyDown={handleKeyDown}>
    {column.text} <SortIcons />
  </span>
