import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ValueFormatters } from 'mk-pattern-library-react';

import * as modalIds from 'constants/modalIds';
import { ALL_TEAMS_SELECTED } from 'constants/multiteam';

import withAnalytics from 'shared/hoc/withAnalytics';
import { events } from 'constants/analytics';

import Dropdown, { ItemContent } from 'shared/accessibility/components/Dropdown';
import withTranslate from 'shared/hoc/withTranslate';

import { setRoadshowTeam, changeRoadshowTeam } from 'actions/RoadShowActions';
import { getMultiTeamMode, getSelectedTeam, getTeams, getTeamsDetails } from 'selectors';

import { ellipsis } from 'shared/styles/mixins/ellipsis';

const EnhancedItemContent = styled(ItemContent)`
    padding-left: 0px;
`;

const provideBtnContent = ({ selected, allTeams, chooseTeam, teamDetails }) => {
	if (!selected) {
		return chooseTeam;
    }

	if (selected === ALL_TEAMS_SELECTED) {
		return allTeams;
	}

	return `${selected} (${teamDetails[selected].roadshowTeamCode})`;
};

class MultiTeamDropdown extends React.PureComponent {
    dropdownOpened = () => {
        this.props.roadshowMultiTeamButtonEvent();
    }

    render() {
        if (!this.props.multiple) return;

        const teams = this.props.teams.map((team, i) => {
            const isSelected = this.props.selected === team || this.props.selected === ALL_TEAMS_SELECTED;
            return ({
                selected: isSelected,
                content: `${team} (${this.props.teamDetails[team].roadshowTeamCode})`,
                label: isSelected
                    ? this.props.teamSelected(this.props.teamDetails[team])
                    : this.props.teamNotSelected(this.props.teamDetails[team]),
                click: () => this.props.changeRoadshowTeam(team),
                color: ValueFormatters.teamColor(i),
            })
        }).concat({
            selected: this.props.selected === ALL_TEAMS_SELECTED,
            content: this.props.allTeams,
            label: this.props.selected === ALL_TEAMS_SELECTED
                ? this.props.teamSelected({ teamName: this.props.allTeams, roadshowTeamCode: 'ALL' })
                : this.props.teamNotSelected({ teamName: this.props.allTeams, roadshowTeamCode: 'ALL' }),
            click: this.props.displayAllTeams,
            color: ValueFormatters.teamColor(-1),
        });

        return (
            <Dropdown
                dropdownId={modalIds.ROADSHOW_MULTITEAM_MODAL_ID}
                dropdownText={provideBtnContent(this.props)}
                items={teams}
                label={this.props.btnAriaLabel}
                dropdownOpened={this.dropdownOpened}
                renderOptionContent={item => (
                    <React.Fragment>
                        <div style={{
                            position: 'absolute',
                            top: 4,
                            right: 5,
                            background: item.color,
                            width: 7,
                            height: 'calc(100% - 8px)'
                        }} />
                        <EnhancedItemContent>
                            {item.content}
                        </EnhancedItemContent>
                    </React.Fragment>
                )}
                clickableContent={
                    <React.Fragment>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: ValueFormatters.teamColor(this.props.selected === ALL_TEAMS_SELECTED ? -1 : this.props.teams.indexOf(this.props.selected)),
                            width: 7,
                            height: '100%',
                        }} />
                        <span style={{ display: 'inline-block', width: 'calc(100% - 14px)', textAlign: 'left', ...ellipsis, lineHeight: 1.3, margin: '-4px 0 -4px 4px' }}>
                            {provideBtnContent(this.props)}
                        </span>
                    </React.Fragment>
                }
            />
        )
    }
}

export default compose(
    withAnalytics(
        ev => ({
            roadshowMultiTeamButtonEvent: () => ev(events.ROADSHOW_MULTITEAM_BUTTON),
        }),
    ),
    withTranslate(translate => ({
        chooseTeam: translate('roadshow.please_choose_your_team','Please choose your team...'),
        allTeams: translate('roadshow.all_teams', 'All teams'),
        btnAriaLabel: translate('roadshow.open_multiteam_select', 'Open MultiTeam Select'),
        teamSelected: team => translate(
            'roadshow.filtering_by_<team_name>_team',
            `Filtering by ${team.teamName} (${team.roadshowTeamCode}) team`,
            [
                ['<team_name>', `${team.teamName} (${team.roadshowTeamCode})`],
            ],
        ),
        teamNotSelected: team => translate(
            'roadshow.select_to_filter_events_by_<team_name>_team',
            `Select to filter events by ${team.teamName} (${team.roadshowTeamCode}) team`,
            [
                ['<team_name>', `${team.teamName} (${team.roadshowTeamCode})`]
            ]
        )
    })),
    connect(
		state => ({
            selected: getSelectedTeam(state),
            multiple: getMultiTeamMode(state),
            teams: getTeams(state),
            teamDetails: getTeamsDetails(state),
        }),
        dispatch => ({
            changeRoadshowTeam: team => {
				dispatch(setRoadshowTeam(team));
				dispatch(changeRoadshowTeam(team));
			},
			displayAllTeams: () => {
				dispatch(setRoadshowTeam(ALL_TEAMS_SELECTED));
				dispatch(changeRoadshowTeam(ALL_TEAMS_SELECTED));
			},
        }),
        // (stateProps, dispatchProps, ownProps) => ({
        //     ...ownProps, ...stateProps, ...dispatchProps,

		// })
	)
)(MultiTeamDropdown);
