import { translationString } from 'translations/TranslationText';

const mapPriceSensitivityToKey = sensitivity => {
  switch (true) {
    case /Outright Yield/.test(sensitivity): {
      return {
        title: 'Outright Yield',
        key: 'investor_feedback.outright_yield',
      }
    }
    case /Spread vs Government/.test(sensitivity): {
      return {
        title: 'Spread vs Government',
        key: 'investor_feedback.spread_vs_government',
      };
    }
    case /Spread vs Mid Swap/.test(sensitivity): {
      return {
        title: 'Spread vs Mid Swap',
        key: 'investor_feedback.spread_vs_mid_swap',
      };
    }
    default: {
      return {
        title: '',
        key: '',
      };
    }
  }
}

export default (originalText, language) => {
  const { title, key } = mapPriceSensitivityToKey(originalText);

  const result = originalText.replace(title, translationString(key, language, title))
  return result;
}
