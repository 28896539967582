import IsvTypes from "./IsvTypes";

export const viewDocument = (clientFileId, title, fileName, documentId) => {
  let urlText = `Document/DownloadFile?clientFileId=${clientFileId}`;
  if (!clientFileId) {
    urlText = `Document/DownloadFile?documentId=${documentId}`;
  }
  return {
    type: IsvTypes.API_REQUEST_DOWNLOAD_DOCUMENT,
    endPoint: urlText,
    params: {
      documentId,
      title,
      fileName,
    },
    ajaxType: 'GET',
  };
};

export const handleOpenUploadOverlay = (isOpen, document, title, isEcbClientDocUploaded, isReferenceDocUploaded) => ({
  type: IsvTypes.APPLY_HANDLE_UPLOAD_OVERLAY,
  isOpen,
  document,
  title,
  isEcbClientDocUploaded,
  isReferenceDocUploaded
})

export const uploadFileDocument = (file, title) => ({
  type: IsvTypes.APPLY_HANDLE_FILE_UPLOAD,
  file,
  title
})

export const startUploadingFile = () => ({
  type: IsvTypes.START_UPLOADING
})

export const setUploadProgress = progress => ({
  type: IsvTypes.UPLOAD_PROGRESS,
  progress,
})

export const uploadPostDocument = (data, isTitleAlreadyExist, isEcbDocument, document) => ({
  type: IsvTypes.API_REQUEST_UPLOAD_DOCUMENTS,
  endPoint: '/Document/UploadDocument',
  data,
  isTitleAlreadyExist,
  isEcbDocument,
  document,
  ajaxType: 'POST',
})

export const handleDocumentApproveReject = (data) => {
  return {
    type: IsvTypes.API_APPROVE_REJECT_REQUEST_STATUS,
    endPoint: '/Document/UpdateDocumentStatus',
    params: {
      clientFileId: data.clientFileId,
      isCsmApproved: data.isCsmApproved,
      isRrApproved: data.isRrApproved,
      ecbReturnStatus: data.ecbReturnStatus,
      isRrFinalStatus: data.isRrFinalStatus,
      fromEcb2Return: data.fromEcb2Return || false,
    },
    ajaxType: 'PUT',
    selectedDealData: data.selectedDealData,
    isIsvCsmUser: data.isIsvCsmUser,
    fromEcb2Return: data.fromEcb2Return,
    dealId: data.dealId,
  }
}

export const deleteSupportingDocument = (id) => ({
  type: IsvTypes.API_REQUEST_DELETE_SUPPORTING_DOCUMENTS,
  endPoint: `/Document/DeleteDocument?clientFileId=${id}`,
  id,
  ajaxType: 'DELETE',
})

export const handleSubmitDealInformation = (dealId, status) => ({
  type: IsvTypes.API_REQUEST_SUBMIT_INFO,
  endPoint: `/Deals/SubmitInfo`,
  params:{
    dealId:dealId,
    status:status
  },
  dealId:dealId,
  ajaxType: 'PUT',
})

export const handleECBSubmit = (dealId, status) => ({
    type: IsvTypes.API_REQUEST_CLIENT_ECB_SUBMIT,
    endPoint: `/Ecb/SubmitEcb2Returns`,
    params:{
      dealId:dealId,
      status:status
    },
    dealId:dealId,
    ajaxType: 'PUT',
})

export const handeFinalSubmission = (dealId, isFinalSubmission) => ({
  type: IsvTypes.API_REQUEST_SUBMIT_FINAL_SUBMISSION,
  endPoint: `/Deals/FinalSubmission?dealId=${dealId}&IsFinalSubmission=${isFinalSubmission}`,
  dealId,
  ajaxType: 'PUT',
})

export const handleClientDocuments = (data) => ({
  type: IsvTypes.HANDLE_CLIENT_DOCUMENT_DATA,
  data
})

export const handlePostDocumentComment = (data) => {
  return {
    type: IsvTypes.API_REQUEST_SUBMIT_DOCUEMENT_COMMENT,
    endPoint: "/Comments/AddComments",
    params: {
      commentId: data.commentId,
      dealId: data.dealId,
      title: data.title,
      commentText: {
        text: data.comment.text,
        cdate: data.comment.dateTime,
        userName: data.comment.userName,
        isClientVisible: data.comment.isClientVisible,
        IsECBReturnVisible: data.comment.IsECBReturnVisible,
        from: data.comment.from,
      },
      isClientScreen: data.isClientScreen,
    },
    ajaxType: "PUT",
  };
};

export const getLatestCommentsByDealId = (dealId, isClientScreen, isEcbReturn) => ({
  type: IsvTypes.API_REQUEST_LATEST_COMMENTS_FOR_CURRENT_DEAL,
  endPoint: "/Comments/GetLatestCommentsByDealId",
  params: {
    dealId,
    isClientScreen,
    isEcbReturn
  },
  ajaxType: "GET",
});

export const handleGetLatestCommentsByDealIdResponse = (data) => ({
  type: IsvTypes.API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_DEAL,
  data,
});

export const getLatestCommentsByCommentId = (commentId, isClientScreen, isEcbReturn) => ({
  type: IsvTypes.API_REQUEST_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT,
  endPoint: `/Comments/GetComments`,
  params: {
    commentId,
    isClientScreen,
    isEcbReturn,
  },
  ajaxType: "GET",
})

export const handleGetLatestCommentsByCommentsId = (data) => ({
  type: IsvTypes.API_RECEIVE_LATEST_COMMENTS_DATA_FOR_CURRENT_COMMENT,
  data
})

export const handleCommentsPopup = (isOpen, isAdditionalComment, rejectLabel) => ({
  type: IsvTypes.HANDLE_COMMENTS_POPUP,
  isOpen,
  isAdditionalComment,
  rejectLabel,
})

export const downloadAllDocuments = (dealId, referenceId) => ({
  type: IsvTypes.API_REQUEST_DOWNLOAD_ALL_DOCUMENTS,
  endPoint: `Document/GetBundleFiles/${dealId}`,
  params: {
    dealId,
    title : referenceId,
    fileName: 'zip'
  },
  ajaxType: 'GET',
})

export const setTitleColumnWidth = width => ({
  type: IsvTypes.HANDLE_TITLE_COLUMN_WIDTH,
  width,
})

export const getECB2ReturnDataForCurrentDeal = (dealId) => ({
  type: IsvTypes.API_REQUEST_ECB2_RETURN_DATA_FOR_CURRENT_DEAL,
  endPoint: "/Ecb/GetEcb2Returns",
  params: {
    dealId,
  },
  ajaxType: "GET",
});

export const handleECB2ReturnDataForCurrentDeal = (data) => ({
  type: IsvTypes.API_RECEIVE_ECB2_RETURN_DATA_FOR_CURRENT_DEAL,
  data
})

export const handleECBStatus = (status) => ({
  type: IsvTypes.HANDLE_ECB_STATUS,
  status
})

export const handleReferenceInformationStatus = (status) => ({
  type: IsvTypes.HANDLE_REFERENCE_INFORMATION_STATUS,
  status
})

export const getReferenceDataForCurrentDeal = (dealId, isRrUser) => ({
  type: IsvTypes.API_REQUEST_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL,
  endPoint: "/ReferenceDocument/GetRefDocuments",
  params: {
    dealId,
    isRrUser
  },
  ajaxType: "GET",
});

export const handleReferenceInformationDataForCurrentDeal = (data) => ({
  type: IsvTypes.API_RECEIVE_REFERENCE_INFORMATION_DATA_FOR_CURRENT_DEAL,
  data
})

export const uploadRefPostDocument = (data, isTitleAlreadyExist, isRefDocumentUploaded, document, isRrUser) => ({
  type: IsvTypes.API_REQUEST_UPLOAD_REFERENCE_DOCUMENT,
  endPoint: '/ReferenceDocument/UploadRefDocument',
  data,
  isTitleAlreadyExist,
  isRefDocumentUploaded,
  document,
  isRrUser,
  ajaxType: 'POST'
})

export const deleteReferenceDocument = (id) => ({
  type: IsvTypes.API_REQUEST_DELETE_REFERENCE_DOCUMENTS,
  endPoint: `/ReferenceDocument/ArchieveRefDocument?docRefId=${id}`,
  id,
  ajaxType: 'POST',
})

export const viewReferenceDocument = (docRefId, title, fileName) => {
  let urlText = `ReferenceDocument/DownloadRefDocument?docRefId=${docRefId}`;
  return {
    type: IsvTypes.API_REQUEST_DOWNLOAD_DOCUMENT,
    endPoint: urlText,
    params: {
      docRefId,
      title,
      fileName,
    },
    ajaxType: 'GET',
  };
};
